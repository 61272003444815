import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as THREE from 'three';
import {
  Canvas,
  factoryThreedanimationcontrol,
  GlobalThreedControls,
  threedanimation, threedanimationcontrol
} from '../videocreator.model';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
// import { VRMLLoader } from 'three/examples/jsm/loaders/VRMLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
//import { gsap } from 'assets/js/all';
import * as gsap from 'src/assets/js/all';
import { TimelineMax } from 'src/assets/js/all';
// import { Observable } from 'rxjs';
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox';
import { SelectionHelper } from './SelectionHelper';
import { EventEmitter } from '@angular/core';
import { Box3, BufferGeometry, SpotLight, Color, DoubleSide, GridHelper, ExtrudeGeometry, Group, Material, Matrix4, Mesh, MeshPhongMaterial, Shape, MeshStandardMaterial, AnimationMixer } from 'three';
import { BufferGeometryUtils } from './BufferGeometryUtils.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SVGLoader, SVGResult } from 'three/examples/jsm/loaders/SVGLoader'
// import CSG from './CSG';
import { ViewerworkerService } from './viewerworker.service';
import { UploadgltfService } from './uploadgltf.service';
import { EffectsService } from './effects.service';
import { RotateHelperService } from './RotateHelper';
import { MovePathHelperService } from './movepathhelper';
// import { Gradient } from '@svgdotjs/svg.js';
// import { R } from '@angular/cdk/keycodes';
// import { Geometry } from 'pixi.js';
// const { parse, stringify } = require('svgson');

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { BASE_URL } from 'src/app/shared/base.api';
import { objectBag } from '@svgdotjs/svg.js';
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { parse } from 'svgson';
// import { EffectComposer  } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/jsm/postprocessing/Renderpass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { BlendFunction, EffectComposer, EffectPass, RenderPass, SelectiveBloomEffect } from "postprocessing";
import { WebGLRenderer } from "three";

@Component({
  selector: 'app-threed',
  templateUrl: './threed.component.html',
  styleUrls: ['./threed.component.scss']
})
export class ThreedComponent implements OnInit, OnDestroy {
  @Input('option') option: any; //get id for image gallery
  @Input('account') account: any;
  @Input('selectedelement') selectedelement: any;
  @Input('canvas') canvas: Canvas;
  @Input('globalThreedControls') globalThreedControls: GlobalThreedControls;
  @Input('primairytimeline') primairytimeline: GSAPTimeline;
  @Input() systembusy: Boolean;
  @Input('animationarray') animationarray: any; // set type?? 
  @Input('selectanimationindex') selectanimationindex: number;

  @Output() emitterSystemBusy: EventEmitter<any> = new EventEmitter();
  @Output() emitterScreenshot: EventEmitter<any> = new EventEmitter();
  @Output() emitterGlobalControls: EventEmitter<GlobalThreedControls> = new EventEmitter();
  @Output() emitterElement: EventEmitter<any> = new EventEmitter();
  @Output() emitterCanvas: EventEmitter<Canvas> = new EventEmitter();
  @Output() emitterPrimairytimeline: EventEmitter<GSAPTimeline> = new EventEmitter();


  renderer = new THREE.WebGLRenderer({
    alpha: true,
    // antialias: true,
    preserveDrawingBuffer: true
  });

  // renderer = new WebGLRenderer({
  //   // powerPreference: "high-performance",
  //   alpha: true,
  //   // antialias: true,
  //   preserveDrawingBuffer: true
  // });

  camera = new THREE.PerspectiveCamera(50, 1, 0.1, 5000);
  scene = new THREE.Scene();
  threedobject: HTMLElement;
  selectionBox: SelectionBox;
  helper: SelectionHelper;
  dragcontrols: DragControls;
  enableSelection = false;
  allSelected: any;
  // mesh: THREE.Group;
  position: DOMRect;
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  intersects: THREE.Intersection[];
  selectedgroup: THREE.Group;
  light: THREE.DirectionalLight;
  selectedthreedpart: THREE.Object3D;
  selectedcolor: any;
  selectedtexture: any;
  unioncount: number;
  boxfinal: THREE.Box3;
  unionreadycount: any;
  renderorder: number = 1;
  id: number;
  threedarray = [];
  mixer: any;


  moveForward = false;
  moveBackward = false;
  moveLeft = false;
  moveRight = false;
  canJump = false;
  moveUp = false;
  moveDown = false;

  prevTime = performance.now();
  velocity = new THREE.Vector3();
  direction = new THREE.Vector3();
  controls: any;
  plane: any;
  stars = [];
  smokeParticles: any[];
  smokegroup: THREE.Group;
  bulbs: any;
  composer: EffectComposer;
  effect: SelectiveBloomEffect;
  illuminated: any;
  visible: any;
  selectedthreedpartElement: any;
  effectPass: EffectPass;
  bloom: boolean;


  constructor(
    private movePathHelperService: MovePathHelperService,
    private rotateHelper: RotateHelperService,
    private boxeffects: EffectsService,
    private uploadgltf: UploadgltfService,
    private workerService: ViewerworkerService,
    //public http: HttpClient,
  ) { }

  async ngOnInit() {

    for (let i = 0; i < this.animationarray.length; i++) {
      if (this.animationarray[i].type === 'threed') {
        this.threedarray.push(this.animationarray[i]);
      }
    }

    if (this.threedarray.length > 0) {
      await this.loadEnviroment();
      for (let y = 0; y < this.threedarray.length; y++) {
        this.loadSTL(this.threedarray[y]);
      }
    }
  }

  ngOnDestroy(): void {
    cancelAnimationFrame(this.id); // stop frame 
  }

  async loadEnviroment() {
    // console.log(this.selectedelement)
    // const loaderImage = new THREE.ImageLoader();
    // Necessary for camera/plane rotation
    let w = parseInt(this.canvas.width);
    let h = parseInt(this.canvas.height);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(50, w / h, 0.1, 5000);
    this.camera.aspect = w / h;



    this.renderer.setSize(w, h);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(0xff0000, 0); //renderer.setClearColor('white', 1);
    //Create a DirectionalLight and turn on shadows for the light
    //this.renderer.outputEncoding = THREE.GammaEncoding
    // renderer.gammaFactor = 2.2;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    this.setLight();

    this.mixer = new THREE.AnimationMixer(this.scene);

    this.intersects = [];

    this.threedobject = document.getElementById('canvasthreed');
    if (this.threedobject.innerHTML) {
      this.threedobject.innerHTML = '';
    }

    if (this.canvas.grid) {
      let grid = await this.createGrid();
      this.scene.add(grid)
    }

    if (this.canvas.cloud) {
      this.addSmoke()
    }

    if (this.canvas.helper) {
      const axesHelper = new THREE.AxesHelper(5);
      this.scene.add(axesHelper);
    }

    if (this.canvas.walkcontrol) {
      // controls = new FirstPersonControls(this.camera, this.threedobject);
      this.controls = new PointerLockControls(this.camera, this.threedobject);

      // console.log(this.controls)

      this.threedobject.addEventListener('click', () => {
        //  console.log('lock', this.controls)
        if (this.controls.isLocked) {
          this.controls.unlock();
        } else {
          this.controls.lock();
        }
      });
      this.scene.add(this.controls.getObject());
      document.addEventListener('keydown', (e) => this.onKeyDown(e));
      document.addEventListener('keyup', (e) => this.onKeyUp(e));
      // controls.movementSpeed = 70;
      // controls.lookSpeed = 0.5;
      // controls.noFly = true;
      // controls.lookVertical = false;
      // this.camera.updateProjectionMatrix();
      // controls.handleResize();
    } else {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.addEventListener('change', (event: any) => {
        if (this.canvas.cameracontrol) {
          //console.log(event)
          this.canvas.camerapositionx = event.target.object.position.x;
          this.canvas.camerapositiony = event.target.object.position.y;
          this.canvas.camerapositionz = event.target.object.position.z;
          this.canvas.camerarotationx = event.target.object.rotation.x;
          this.canvas.camerarotationy = event.target.object.rotation.y;
          this.canvas.camerarotationz = event.target.object.rotation.z;
        }
      });
    }



    this.camera.rotation.x = this.canvas.camerarotationx;
    this.camera.rotation.y = this.canvas.camerarotationy;
    this.camera.rotation.z = this.canvas.camerarotationz;
    this.camera.position.x = this.canvas.camerapositionx;
    this.camera.position.y = this.canvas.camerapositiony;
    this.camera.position.z = this.canvas.camerapositionz;

    this.threedobject.appendChild(this.renderer.domElement);

    this.emitterSystemBusy.emit(false);

    let selected
    if (this.selectedelement) {
      selected = document.getElementById(this.selectedelement.uuid);
    }
    const clock = new THREE.Clock();

    this.composer = new EffectComposer(this.renderer);

    this.effect = new SelectiveBloomEffect(this.scene, this.camera, {
      blendFunction: BlendFunction.ADD,
      mipmapBlur: true,
      luminanceThreshold: 0.7,
      luminanceSmoothing: 0.3,
      intensity: 3.0
    });

    this.effectPass = new EffectPass(this.camera, this.effect);
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));


    if (this.canvas.plane !== 'none') {
      let plane = await this.createPlane();
      if (plane) {
        this.scene.add(plane)
      }
    }
    // Draw scene animate is running constantly! so changes will happen on the fly! 
    let animate = () => {
      this.id = requestAnimationFrame(animate); // cancel on destroy
      const time = performance.now();
      // this.mixer.update(clock.getDelta())
      // update position
      this.position = this.threedobject.getBoundingClientRect();

      //controls.update(clock.getDelta()); // fly controls

      // set controls move / rotate horizontal / rotate vertical / camera controls / resize
      // MESH CONTROLS
      if (this.globalThreedControls.rotatecontrolx || this.globalThreedControls.rotatecontroly || this.globalThreedControls.rotatecontrolz) {
        // this.rotateHelper.render()
        this.controls.enabled = false;
      }

      if (this.globalThreedControls.dragcontrol && !this.globalThreedControls.dragcontrolgroup && this.scene.children.length > 0) {
        this.setRaycaster(selected);
        this.controls.enabled = false;
      }

      // set controls depending mesh controls 
      if (this.canvas.cameracontrol || this.canvas.walkcontrol) {
        this.controls.enabled = true;
      } else {
        this.controls.enabled = false;
      }

      if (this.canvas.walkcontrol) {
        if (this.controls.moveRight) {
          this.setControls(this.controls, this.raycaster, time);
        }
      }

      // keep last to deactive on the fly otherwise the controls above will switch it on again
      if (this.selectedelement) {
        if (this.selectedelement.type !== 'threed') {
          this.controls.enabled = false;
        }
      }

      if (this.canvas.plane === 'water') {
        if (this.plane && this.primairytimeline.isActive()) {
          this.plane.material.uniforms['time'].value += 1.0 / 60.0;
        }
      }

      if (this.canvas.cloud && this.smokeParticles) {

        let delta = clock.getDelta()
        let targetQuaternion = this.camera.quaternion;

        if (this.primairytimeline.isActive()) {
          for (let i = 0; i < this.smokeParticles.length; i++) {
            let targetmesh: Mesh = this.smokeParticles[i];
            targetmesh.rotation.z += delta * 0.2;

          }
        }


        //var step = rotationSpeed * delta;
        for (let y = 0; y < this.smokegroup.children.length; y++) {
          let childgroup = this.smokegroup.children[y];
          if (!childgroup.quaternion.equals(targetQuaternion)) {
            childgroup.quaternion.rotateTowards(targetQuaternion, 1);
          }
        }

      }

      if (!this.primairytimeline.isActive()) {
        this.stopVideo();
      } else {
        this.playVideo();
      }

      if (this.canvas.plane === 'stars' && this.primairytimeline.isActive()) {
        this.animateStars()
      }

      if (this.canvas.pretheme === 'particles' && this.primairytimeline.isActive()) {
        this.boxeffects.animateParticles();
        // this.primairytimeline.call(this.boxeffects.animateParticles, [group], 0);
      }
      if (this.canvas.pretheme === 'linebox' && this.primairytimeline.isActive()) {
        this.boxeffects.animateLineBox();
        //this.primairytimeline.call(this.boxeffects.animateLineBox, [group], 0);
      }

      // if (this.canvas.pretheme === 'lightbulbs') {
      this.composer.render(); //lightbulbs
      // } else {
      //   this.renderer.render(this.scene, this.camera);
      // }

      if (!this.controls.moveRight) {
        this.controls.update();
      }

      this.prevTime = time;



    };
    animate();
  }



  // mesh/element etc.. 
  async loadSTL(element: threedanimation) {
    // console.log(element)
    this.emitterSystemBusy.emit(true);
    const loaderMTL = new MTLLoader();

    let color;
    if (element.color) {
      color = new THREE.Color(element.color);
    } else {
      color = undefined;//new THREE.Color('#545c61')
    }

    let material = this.createMaterial(element, color, THREE.DoubleSide);


    loaderMTL.load(element.materialsrcfile, async (materialobject: MTLLoader.MaterialCreator) => {
      //materialobject.preload();

      if (element.materialsrcfile) {
        material = undefined
      }

      // //materialobject.
      let ext;
      if (element.src) {
        ext = element.src.substr(element.src.lastIndexOf('.') + 1);
      }

      if (ext === 'glb' || ext == 'gltf') {
        const loaderGLTF = new GLTFLoader();
        if (element.materialsrcfile) {
          //loaderGLTF.setMaterials(materialobject);
        }
        //console.log(element.blobsrc)
        loaderGLTF.load(element.blobsrc, (gltf: any) => {
          //          console.log(gltf)
          // material = gltf.material;
          if (gltf.scene) {

            // let group = new Group();
            // group.add(gltf.scene);

            // for (let i = 0; i < gltf.scene.children.length; i++) {
            //   const element = gltf.scene.children[i];
            //   group.add(element);
            // }

            // just add entire scene best solutions so far.. 
            this.scene.add(gltf.scene);
            this.loadGroupSettings(element, gltf.scene, true, gltf.animations);
          } else {
            console.error('missing scene for import')
          }

          //this.loadGroupSettings(gltf.scenes[0], controls, material) // more control but ignores materials
          // }
        },
        );
      }
      if (element.videourl) {
        //create video element:
        const video = document.createElement('video');
        video.src = element.videourl;
        video.hidden = true;
        video.crossOrigin = 'anonymous';
        let id = element.id + 'threevideo';;
        video.id = id;

        //this.primairytimeline.isActive()
        //this.primairytimeline.call(this.playVideo, [id], 0);

        // stop all but render? 

        this.threedobject.appendChild(video)

        //Create your video texture:
        const videoTexture = new THREE.VideoTexture(video);
        videoTexture.needsUpdate = true;
        const videoMaterial = new THREE.MeshBasicMaterial({
          map: videoTexture,
          side: THREE.DoubleSide,
          toneMapped: false,
        });
        videoMaterial.needsUpdate = true;

        //Create screen
        const screen = new THREE.PlaneGeometry(element.imagewidth, element.imageheight);
        const videoScreen = new THREE.Mesh(screen, videoMaterial);
        this.scene.add(videoScreen);

        this.loadGroupSettings(element, videoScreen, material);
      }

      if (element.imageurl) {

        //console.log(element.imageurl, element.imageheight, element.imagewidth)

        var loader = new THREE.TextureLoader();
        var texture = loader.load(element.imageurl);

        let geometry = new THREE.PlaneGeometry(element.imageheight, element.imagewidth);
        let materialplane = new THREE.MeshBasicMaterial({ map: texture, opacity: 1, transparent: true, side: THREE.DoubleSide });

        let mesh = new THREE.Mesh(geometry, materialplane);

        this.scene.add(mesh);
        this.loadGroupSettings(element, mesh, material);
      }

      if (ext === 'stl') {
        const loaderSTL = new STLLoader()
        if (element.materialsrcfile) {
          //loaderGLTF.setMaterials(materialobject);
        }
        //console.log(element.blobsrc)
        loaderSTL.load(element.blobsrc, (geometry: any) => {
          console.log(geometry);

          const mesh = new THREE.Mesh(geometry, material);
          this.scene.add(mesh);

          this.loadGroupSettings(element, mesh, material);

          // for (let i = 0; i < stl.children.length; i++) {
          //   if (stl.children[i].type === 'Mesh' || stl.children[i].type === 'Group') {
          //     this.scene.add(stl.children[i])
          //   }
          // }
          //this.scene.add(fbx.children);

        });
      }

      if (ext === 'fbx') {
        const loaderFBX = new FBXLoader();
        if (element.materialsrcfile) {
          //loaderGLTF.setMaterials(materialobject);
        }
        //console.log(element.blobsrc)
        loaderFBX.load(element.blobsrc, (fbx: any) => {
          console.log(fbx);

          for (let i = 0; i < fbx.children.length; i++) {
            if (fbx.children[i].type === 'Mesh' || fbx.children[i].type === 'Group') {
              this.scene.add(fbx.children[i])
            }
          }

          //this.scene.add(fbx.children);
        },
        );
      }

      if (ext === 'obj' || ext == 'object') {
        const loaderObj = new OBJLoader();
        if (element.materialsrcfile) {
          loaderObj.setMaterials(materialobject);
        }
        //console.log(materialobject)
        loaderObj.load(element.blobsrc, (object: any) => {
          //console.log(object);

          // standard object no scene 
          this.scene.add(object);
          //this.loadSettings(controls);
          this.loadGroupSettings(element, object, material)
          // object including scene

        });
      }

      if (element.svg && !element.src) {
        //console.log('load svg', element)
        const loaderSVG: SVGLoader = new SVGLoader();
        let object = loaderSVG.parse(element.svg);
        let group: Group = new Group();
        //console.log(object)
        group = await this.createSVGGroup(element, object.paths, group); //, baseshape

        const aabb = new THREE.Box3();
        aabb.setFromObject(group);

        //let min = aabb.min;
        let max = aabb.max;

        group.position.set(max.x, max.y, max.z);

        element.positionx = max.x * -1;
        element.positionz = max.z * -1;
        element.positiony = max.y * -1;


        // keep svg for now upload function not necessary 
        // this.uploadgltf.upload(this.scene, element, element.svgurl, this.account.companyId, this.option.id); // upload gltf object
        // create three.js object 
        // upload to fileserver
        // await this.loadSettings(controls);
        // await this.loadGroupSettings(element, group, null);


        this.scene.add(group);
        this.loadGroupSettings(element, group, true, null);

      }

      console.log(this.canvas.pretheme)
      if (this.canvas.pretheme) {

        if (this.canvas.pretheme === 'particles') {
          this.boxeffects.initParticles(this.scene, element);
          //  this.scene.add(group);

          // await this.loadGroupSettings(element, group, null, true);
          // this.primairytimeline.call(this.boxeffects.animateParticles, [group], 0);
          //await this.loadSettings(controls);
        }

        if (this.canvas.pretheme === 'linebox') {
          this.boxeffects.initLineBox(this.scene, element);
          //this.scene.add(group);

          // await this.loadGroupSettings(element, group, null, true);
          // //await this.loadSettings(controls);
          // this.primairytimeline.call(this.boxeffects.animateLineBox, [group], 0);
        }

        if (this.canvas.pretheme === 'lightbulbs') {
          this.bulbs = this.boxeffects.initLightBublbs(this.scene, this.canvas.planecolor, this.primairytimeline, this.effect);
        }

      }

      this.emitterSystemBusy.emit(false);
    }, (ev) => {
      //console.log(ev) 
    }, (error) => { console.log(error) });

  }


  async loadGroupSettings(element: threedanimation, group, material, clips?) {
    // dontchangeposition?
    // create customization for every element

    this.flattenGroup(group, true);


    if (element.partpropertiesarray.length === 0) {
      for (let i = 0; i < group.children.length; i++) {
        let object = group.children[i];
        if (element.illuminated && this.effect) {
          this.bloom = true;
          this.effect.selection.toggle(object);
        }
        let id = element.id + '-' + i;
        object.userData.nameId = id;
        // if (object.type === "Group"){
        //   console.log(object)
        //   for (let y = 0; y < object.children.length; y++) {
        //     let objectchild = object.children[y];
        //     element.partpropertiesarray.push(
        //       {
        //         name: objectchild.name,
        //         uuid: objectchild.uuid,
        //         position: undefined,
        //         color: undefined,
        //         texture: undefined,
        //         visible: true
        //       });
        //   }
        // } else {
        element.partpropertiesarray.push(
          {
            name: object.name,
            nameId: id,
            uuid: object.uuid,
            position: undefined,
            color: undefined,
            texture: undefined,
            visible: true,
            illuminated: false
          });
      }
      // };
    } else {
      // update UUID as it renews every render
      for (let i = 0; i < group.children.length; i++) {
        let object = group.children[i];
        if (element.illuminated && this.effect) {
          this.bloom = true;
          this.effect.selection.toggle(object);
        }
        element.partpropertiesarray[i].uuid = object.uuid;


        let id = element.id + '-' + i;
        element.partpropertiesarray[i].nameId = id;
        object.userData.nameId = id;
      }
    }


    element.components = [];
    // if (!this.canvas.pretheme) {
    this.setPropertiesPartsGroup(element, group, material);


    element.uuid = group.uuid; // set ID so we can find back the correct group on selection DONT WORK UUID CHANGES EVERY RENDER

    if (this.globalThreedControls.rotatecontrolx || this.globalThreedControls.rotatecontroly || this.globalThreedControls.rotatecontrolz) {
      this.rotateHelper.setRotateListener(group, element, this.globalThreedControls.rotatecontrolx, this.globalThreedControls.rotatecontroly, this.globalThreedControls.rotatecontrolz);
    }

    if (element.scale !== 1) {
      group.scale.set(element.scale, element.scale, element.scale);
    }

    if (element.shadows) {
      group.castShadow = true;
      group.receiveShadow = true;
    }

    // if (dontchangeposition) {
    // } else {
    group.rotation.x = element.rotationx;
    group.rotation.y = element.rotationy;
    group.rotation.z = element.rotationz;

    // if (element.positionx !== 0){group.postion.x = element.positionx}
    // if (element.positiony !== 0){group.postion.y = element.positiony}
    // if (element.positionz !== 0){group.postion.z = element.positionz}

    group.position.set(element.positionx, element.positiony, element.positionz);
    // if (element.positionx !== 0){group.postion.setX(element.positionx)}
    // if (element.positiony !== 0){group.postion.setY(element.positiony)}
    // if (element.positionz !== 0){group.postion.setZ(element.positionz)}
    // this.centerObject(group);
    // }
    // reset rotation

    if (this.globalThreedControls.dragcontrol && !element.dragcontrolgroup) {
      this.threedobject.addEventListener('pointerdown', this.onclickIntersect, false);
      this.dragcontrols = new DragControls(group.children, this.camera, this.renderer.domElement);
      this.dragcontrols.addEventListener('dragend', async (e) => {
        element = await this.dragendsingle(e, element, group)

      });
    }

    // load animations
    for (let i = 0; i < element.threedanimations.length; i++) {
      let animation = element.threedanimations[i];
      await this.animateThreed(animation, group, element, i);
    }

    if (clips && clips.length > 0) {
      //console.log(clips)
      this.addthreedanimation(clips)
    }

    // this.camera.lookAt(0, 0, 0);
    if (this.globalThreedControls.dragcontrolgroup) {
      if (this.selectedelement === element) {
        this.addDragSelection(element);
      }

    }
    this.position = this.threedobject.getBoundingClientRect();
    if (this.globalThreedControls.dragcontrol || this.globalThreedControls.dragcontrolgroup) {
      // set raycaster 
      this.threedobject.addEventListener('mousemove', this.onMouseMove, false);
    }

    // check if bloom/illumanate effect
    if (this.canvas.pretheme === 'lightbulbs' || this.bloom) {
      this.composer.addPass(this.effectPass);
    }
  }



  flattenGroup(group, recursive) { // group can be the entire scene
    group.updateMatrixWorld();
    let again;

    do {
      again = false;
      group.children.slice().reverse().forEach(function (parent) {
        parent.children.slice().reverse().forEach(function (child) {
          again = true;
          parent.remove(child);
          child.applyMatrix4(parent.matrix);
          group.add(child);
        });

      });

    } while (again && recursive);

    group.children.slice().reverse().forEach(function (child) {

      if (child.type == 'Group') {
        if (child.children.length == 0) {
          group.remove(child);
        } else {
          console.log("group still has children");
        }
      }
    });
  }

  addthreedanimation(clips) {
    console.log(clips)
    for (let a = 0; a < clips.length; a++) {
      let clip = clips[a];
      //this.primairytimeline.call(this.playClip, [this.mixer, clip], 0);
      //this.primairytimeline.eventCallback("onStart", this.playClip, [this.mixer, clip]);
      //this.primairytimeline.eventCallback("onComplete", this.stopClip, [this.mixer, clip]);
      // this.primairytimeline.eventCallback("onUpdate", this.updateClip, null );

      this.playClip(this.mixer, clip)
    }
  }

  updateClip(e) {
    console.log(e)
  }

  playClip(mixer: AnimationMixer, clip) {
    //console.log('play clip', mixer, clip);
    //mixer.clipAction(clip).setLoop();
    mixer.clipAction(clip).play();
  }

  stopClip(mixer: AnimationMixer, clip) {
    mixer.clipAction(clip).stop();
  }

  async createPlane() {

    // add plane to the scene

    let plane;

    if (this.canvas.plane === 'flat') {

      if (this.canvas.planetexture) {
        let texture = new THREE.TextureLoader().load(this.canvas.planetexture)
      }


      plane = new THREE.Mesh(
        new THREE.PlaneGeometry(1024, 1024, 256, 256),
        new THREE.MeshStandardMaterial({
          color: this.canvas.planecolor,
          side: THREE.DoubleSide
        }));
    }

    if (this.canvas.plane === 'rough') {

      const displacement = new THREE.TextureLoader().load(
        'assets/alps.png'
      )

      const texture = new THREE.TextureLoader().load('assets/alpscolor.png')

      plane = new THREE.Mesh(
        new THREE.PlaneGeometry(1024, 1024, 256, 256),
        new THREE.MeshStandardMaterial({
          color: this.canvas.planecolor,
          displacementMap: displacement,
          displacementScale: 50,
          side: THREE.DoubleSide,
          map: texture
        })
      );
      plane.position.y = -50;
    }

    if (this.canvas.plane === 'mountain') {
      const displacement = new THREE.TextureLoader().load(
        'assets/mountain2.png'
      )
      //const texture = new THREE.TextureLoader().load('assets/alpscolor.png')
      plane = new THREE.Mesh(
        new THREE.PlaneGeometry(1024, 1024, 256, 256),
        new THREE.MeshStandardMaterial({
          color: this.canvas.planecolor,
          displacementMap: displacement,
          displacementScale: 400,
          side: THREE.DoubleSide,
          //map: texture
        })
      );

      plane.position.y = -400;
    }

    if (this.canvas.plane === 'stars') {
      for (var z = -1000; z < 1000; z += 20) {

        // Make a sphere (exactly the same as before). 
        var geometry = new THREE.SphereGeometry(0.5, 32, 32)
        var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        var sphere = new THREE.Mesh(geometry, material)

        // This time we give the sphere random x and y positions between -500 and 500
        sphere.position.x = Math.random() * 1000 - 500;
        sphere.position.y = Math.random() * 1000 - 500;

        // Then set the z position to where it is in the loop (distance of camera)
        sphere.position.z = z;

        // scale it up a bit
        sphere.scale.x = sphere.scale.y = 2;

        //add the sphere to the scene
        this.scene.add(sphere);

        //finally push it to the stars array 
        this.stars.push(sphere);
      }
    }

    if (this.canvas.plane == 'moon') {
      const textureURL = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/17271/lroc_color_poles_1k.jpg";
      const displacementURL = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/17271/ldem_3_8bit.jpg";
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(textureURL);
      const displacementMap = textureLoader.load(displacementURL);
      const materialMoon = new THREE.MeshPhongMaterial(
        {
          color: 0xffffff,
          map: texture,
          displacementMap: displacementMap,
          displacementScale: 0.06,
          bumpMap: displacementMap,
          bumpScale: 0.04,
          reflectivity: 0,
          shininess: 0
        }

      );
      const moon = new THREE.Mesh(geometry, materialMoon);
      this.scene.add(moon)
    }

    if (this.canvas.plane === 'water') {
      const waterGeometry = new THREE.PlaneGeometry(10000, 10000);

      plane = new Water(
        waterGeometry,
        {
          textureWidth: 512,
          textureHeight: 512,
          waterNormals: new THREE.TextureLoader().load('assets/waternormals.jpg', (texture) => {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }),
          sunDirection: new THREE.Vector3(),
          sunColor: 0xffffff,
          waterColor: this.canvas.planecolor,
          distortionScale: 4,
          fog: this.scene.fog !== undefined
        }
      );

      const sky = new Sky();
      sky.scale.setScalar(10000);

      this.scene.add(sky);

      const skyUniforms = sky.material.uniforms;
      sky.material.opacity = 0.5;

      skyUniforms['turbidity'].value = 10; //10
      skyUniforms['rayleigh'].value = 2;
      skyUniforms['mieCoefficient'].value = 0.005;
      skyUniforms['mieDirectionalG'].value = 0.8;

      const parameters = {
        elevation: this.canvas.sunheight,
        azimuth: this.canvas.sunlocation
      };

      let sun = new THREE.Vector3();
      const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
      const theta = THREE.MathUtils.degToRad(parameters.azimuth);
      sun.setFromSphericalCoords(1, phi, theta);
      sky.material.uniforms['sunPosition'].value.copy(sun);
      plane.material.uniforms['sunDirection'].value.copy(sun).normalize();
      console.log(sky)

    }

    if (plane) {
      plane.castShadow = true;
      plane.rotation.x = Math.PI / 2;
      plane.quaternion.setFromEuler(new THREE.Euler(- Math.PI / 2, 0, 0));

      if (this.canvas.shadows) {
        plane.receiveShadow = true; // the plane will receive a shadow
      }
      this.plane = plane;

      if (this.effect) {
        this.effect.selection.toggle(this.plane);
      }




      return plane
    } else {
      return
    }

  }

  async createGrid() {
    const size = 5000;
    const divisions = 100;

    const gridHelper = new GridHelper(size, divisions, new Color(this.canvas.gridcolor), new Color(this.canvas.gridcolor));
    gridHelper.position.set(0, -100, 0)
    return gridHelper;
  }


  setPropertiesPartsGroup(element: threedanimation, group, material) {
    // set positions, materials and colors


    let children = group.children;
    //    console.log(element.partpropertiesarray.length, children.length)

    for (let i = 0; i < children.length; i++) {
      let child = children[i]; // as THREE.Mesh;
      const childelement = element.partpropertiesarray[i];
      //console.log(child.uuid)
      // set or reset ids --> svg needs reset as id 
      // if (material) {
      //    child.material = material.clone();
      // } else {
      //   // remove linked objects 
      //   const material = child.material.clone();
      //   child.material = material;
      // }
      //  console.log(child);
      //  console.log(childelement);
      if (child.material) {

        let color = child.material.color;
        let emissive = child.material.emissive;

        if (childelement.color) {
          color = childelement.color;
        }

        if (element.illuminated) {
          emissive = color;
        }

        if (childelement.illuminated && this.effect) {
          this.bloom = true;
          emissive = color;
          this.effect.selection.toggle(child); // toggle true/false
        }


        // always use meshstandardmaterial to use all effects

        //  if (child.material.type !== "MeshStandardMaterial") {
        let materialnew = new THREE.MeshStandardMaterial({
          emissive: emissive,
          emissiveIntensity: child.material.emissiveIntensity,
          color: color,
          wireframe: element.wireframe,
          side: THREE.DoubleSide
        });

        child.material = materialnew;
        // } else {
        //   let material = child.material.clone();
        //   child.material = material;
        //   child.emissive = emissive;
        //   child.material.color = color;
        // }
      }

      if (element.shadows) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
      if (child['material']) {
        if (child['material'].map) { // if has texture
          child['material'].map.anisotropy = 16;
        }
      }


      if (childelement && childelement.texture) {
        //const texture = new THREE.TextureLoader().load(childelement.texture);
        //child['material'].map = texture;

        let texture = new THREE.TextureLoader().load(childelement.texture, () => {
          this.cover(texture, 1);
        });
        //this.selectedthreedpart['material'].map = texture;
        // this.selectedthreedpart['material'] = new THREE.MeshStandardMaterial({
        //     side: THREE.DoubleSide,
        //     map: texture
        //   })

        child['material'] = new THREE.MeshStandardMaterial({
          side: THREE.DoubleSide,
          map: texture
        })
      }
      if (child.name) { // dont use uuid as it changes every render
        element.components.push({ name: child.name, visible: true })
      } else {
        element.components.push({ name: 'part-' + i, visible: true })
      }

      // set visibili
      child.visible = childelement.visible


      if (childelement && element.explode) {
        let xl1 = element.explodedepthx / 2;
        let yl1 = element.explodedepthy / 2;
        let zl1 = element.explodedepthz / 2;
        let x = 0, y = 0, z = 0;

        x = this.RandomInt((child.position.x - xl1), (child.position.x + xl1));
        y = this.RandomInt((child.position.y - yl1), (child.position.y + yl1));
        z = this.RandomInt((child.position.z - zl1), (child.position.z + zl1));

        child.position.set(x, y, z);
      }

      // set position and componsate very any scaling otherwise 
      if (childelement && childelement.position) {
        //console.log('set position', childelement.position)
        child.position.set(
          (childelement.position.x / element.scale),
          (childelement.position.y / element.scale),
          (childelement.position.z / element.scale));
      }

    }

  }

  resetColors() {
    for (let index = 0; index < this.scene.children.length; index++) {
      const object = this.scene.children[index];

      if (object.type === 'Object3D' && object['material']) {
        object['material'].emissive.set(0x000000);
      }

      if (object.children.length > 0) {
        let children = [];
        children = children.concat(object.children);

        // run through all child components
        let i = 0;
        while (i < children.length) {
          const objectchild = children[i];
          if (objectchild['material'] && objectchild['material'].emissive) {
            objectchild['material'].emissive.set(0x000000);
          }
          if (objectchild.children.length > 0) {
            children = children.concat(objectchild.children);
          }
          i++;
        }

      }
    }
  }

  setRaycaster(group) {
    // check if drag control group is activated
    // if (!element.dragcontrolgroup) {
    this.resetColors();
    // update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera(this.mouse, this.camera);
    // calculate objects intersecting the picking ray
    this.intersects = this.raycaster.intersectObjects(this.scene.children, true);
    // for (let i = 0; i < this.intersects.length; i++) {
    //   this.intersects[i].object['material'].emissive.set(0xff0000); //.color.set(0xff0000);
    // }
    //}
    if (this.intersects.length > 0) {
      if (this.intersects[0].object['material'].emissive) {



        this.intersects[0].object['material'].emissive.set(0xff0000); //.color.set(0xff0000);
      }
    }

  }

  onclickIntersect = (event) => {

    console.log('mouse click', this.intersects);
    if (this.intersects.length > 0) {

      this.selectedthreedpart = this.intersects[0].object;

      for (let index = 0; index < this.selectedelement.partpropertiesarray.length; index++) {
        const childelement = this.selectedelement.partpropertiesarray[index];
        if (childelement.name == this.selectedthreedpart.name) {
          this.selectedthreedpartElement = childelement;
        }
      }

      let color = new THREE.Color();
      color.set(this.selectedthreedpart['material'].color)
      this.selectedcolor = '#' + color.getHexString();


      // if (this.selectedthreedpart['material'].map) {
      //   this.selectedtexture = this.selectedthreedpart['material'].map.image.currentSrc;
      // }
    }
  }


  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  async animateThreed(threedanimation: threedanimationcontrol, mesh: THREE.Object3D, element: threedanimation, i): Promise<any> {
    //console.log(mesh, element, this.primairytimeline)
    if (threedanimation.type === 'rotate') {
      this.setRotate(threedanimation, mesh)
    }
    if (threedanimation.type === 'rotatecamera') {
      this.setRotateCamera(threedanimation, mesh, element);
    }
    if (threedanimation.type === 'explode') {
      this.setExplode(threedanimation, mesh)
    }
    if (threedanimation.type === 'scale') {
      this.setScale(threedanimation, mesh)
    }
    if (threedanimation.type === 'changeposition') {
      this.setPosition(threedanimation, mesh)
    }
    if (threedanimation.type === 'motionpath') {
      this.primairytimeline = await this.movePathHelperService.setMotionPath(threedanimation, mesh, this.primairytimeline, element, i, this.canvas.width, this.canvas.height) as any;
    }

    // if (threedanimation.type === 'clipanimation'){
    //   // Play all animations
    //   for (let a = 0; a < threedanimation.clips.length; a++) {
    //     let clip = threedanimation.clips[a];
    //      this.primairytimeline.call(this.playClip, [this.mixer, clip], 0);
    //     //this.primairytimeline.eventCallback("onStart", this.playClip, [this.mixer, clip]);
    //     this.primairytimeline.eventCallback("onComplete", this.stopClip, [this.mixer, clip]);
    //    // this.primairytimeline.eventCallback("onUpdate", this.updateClip );
    //   }
    // }
    return
  }

  setScale(element, mesh) {
    // let tl = new TimelineMax();
    let aniset = {
      duration: element.duration,
      ease: element.easetype,
      repeat: element.repeat,
      z: element.scalez,
      y: element.scaley,
      x: element.scalex,
      yoyo: element.yoyo,
    }

    if (element.fromto === 'from') {
      this.primairytimeline.from(mesh.scale, aniset, element.starttime);
    }
    if (element.fromto === 'to') {
      this.primairytimeline.to(mesh.scale, aniset, element.starttime);
    }
    return;
  }

  setExplode(element, mesh) {
    //let tl = new TimelineMax();
    for (let i1 = 0; i1 < mesh.children.length; i1++) {
      let child = mesh.children[i1];

      let xl1 = element.explodedepthx / 2;
      let yl1 = element.explodedepthy / 2;
      let zl1 = element.explodedepthz / 2;

      let anisetto = {
        duration: element.duration,
        ease: element.easetype,
        repeat: element.repeat,
        yoyo: element.yoyo,
        x: this.RandomInt((child.position.x - xl1), (child.position.x + xl1)),
        y: this.RandomInt((child.position.y - yl1), (child.position.y + yl1)),
        z: this.RandomInt((child.position.z - zl1), (child.position.z + zl1))
      }

      if (element.fromto === 'to') {
        this.primairytimeline.to(child.position, anisetto, element.start_time);
      }

      if (element.fromto === 'from') {
        this.primairytimeline.from(child.position, anisetto, element.start_time);
      }

      //this.setRotate(element, mesh);
    }
    return;
  }

  async setRotateCamera(animationcontrol: threedanimationcontrol, mesh: THREE.Object3D, element: threedanimation): Promise<any> {

    //let tl = new TimelineMax();
    let pos = { angle: 0 }
    // calculate sites from pythagoras from side z and x - minus the center of the object
    let center;

    if (element.partpropertiesarray.length > 0) {
      center = await this.getPostionMesh(element);
      console.log('pos', center);
    } else {
      center = await this.getCenterPoint(mesh)
    }

    // actual position 

    const radius = Math.sqrt(Math.pow((this.canvas.camerapositionz - center.z), 2) + Math.pow((this.canvas.camerapositionx - center.x), 2));


    let aniset = {
      duration: animationcontrol.duration,
      ease: animationcontrol.easetype,
      repeat: animationcontrol.repeat,
      yoyo: animationcontrol.yoyo,
      angle: animationcontrol.rotationx,
      onUpdate: () => {
        let x = radius * Math.cos(pos.angle * (Math.PI / 180)); //1° × π/180 degrees to tang
        let z = radius * Math.sin(pos.angle * (Math.PI / 180));
        this.camera.position.x = x;
        this.camera.position.z = z;
        this.camera.lookAt(mesh.position.x, mesh.position.y, mesh.position.z);
      }
    }
    if (animationcontrol.fromto === 'from') {
      this.primairytimeline.from(pos, aniset, animationcontrol.start_time);
    }
    if (animationcontrol.fromto === 'to') {
      this.primairytimeline.to(pos, aniset, animationcontrol.start_time);
    }
    return
  }

  centerObject(object) {
    let objBbox = new THREE.Box3().setFromObject(object);

    // Geometry vertices centering to world axis

    let bboxCenter = objBbox.getCenter(new THREE.Vector3()).clone();
    bboxCenter.multiplyScalar(-1);

    object.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.geometry.translate(bboxCenter.x, bboxCenter.y, bboxCenter.z);
      }
    });

    objBbox.setFromObject(object); // U
  }

  setRotate(element, mesh, dim?) {
    console.log(mesh)
    //let tl = new TimelineMax();
    // if (mesh.children.length > 0){
    // for (let i = 0; i < mesh.children.length; i++){
    //   this.setRotateObject(element, mesh.children[i], dim);
    // }
    // } else {
    this.setRotateObject(element, mesh, dim)
    //  }

    return //this.primairytimeline;
  }

  setRotateObject(element, mesh, dim) {
    let aniset = {
      duration: element.duration,
      ease: element.easetype,
      repeat: element.repeat,
      z: (Math.PI / 180) * element.rotationz,
      y: (Math.PI / 180) * element.rotationy,
      x: (Math.PI / 180) * element.rotationx,
      yoyo: element.yoyo,
    }

    if (element.fromto === 'from') {
      this.primairytimeline.from(mesh.rotation, aniset, element.starttime);
    }
    if (element.fromto === 'to') {
      this.primairytimeline.to(mesh.rotation, aniset, element.starttime);
    }
  }

  setPosition(element, mesh: THREE.Object3D) {
    //let tl = new TimelineMax();
    let aniset = {
      duration: element.duration,
      ease: element.easetype,
      repeat: element.repeat,
      z: element.positionz,
      y: element.positiony,
      x: element.positionx,
      yoyo: element.yoyo,
    }

    if (element.fromto === 'from') {
      this.primairytimeline.from(mesh.position, aniset, element.starttime);
    }
    if (element.fromto === 'to') {
      this.primairytimeline.to(mesh.position, aniset, element.starttime);
    }
    return;
  }

  async getCenterPoint(mesh: THREE.Object3D): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let bbox = new THREE.Box3().setFromObject(mesh);
      let middle = { x: (bbox.max.x - bbox.min.x) / 2, z: (bbox.max.z - bbox.min.z) / 2, y: (bbox.max.y - bbox.min.y) / 2 };
      resolve(middle);
    });
  }

  async getPostionMesh(element): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let pos = element.partpropertiesarray[0].position;
      let minX = pos.x, minY = pos.y, minZ = pos.z, maxX = pos.x, maxY = pos.y, maxZ = pos.z;

      for (let i = 0; i < element.partpropertiesarray.length; i++) {
        element.partpropertiesarray[i].position.x;
        let pos2 = element.partpropertiesarray[i].position;
        if (pos2.x > maxX) { maxX = pos2.x }
        if (pos2.y > maxY) { maxY = pos2.y }
        if (pos2.z > maxY) { maxZ = pos2.z }
        if (pos2.x > maxX) { minX = pos2.x }
        if (pos2.y > maxY) { minY = pos2.y }
        if (pos2.z > maxY) { minZ = pos2.z }
      }

      let middle = { x: (maxX + minX) / 2, z: (maxZ + minZ) / 2, y: (maxY + maxZ) / 2 };
      resolve(middle);
    });

  }

  /* 
  1. set create seperate eventlisteners 
  2. drag select and desable eventlistener drag 
  3. enable drag group
  4. drag on drag end destroy
  */
  addDragSelection(element) {
    this.dragcontrols = new DragControls([], this.camera, this.renderer.domElement);
    this.selectionBox = new SelectionBox(this.camera, this.scene);
    this.helper = new SelectionHelper(this.selectionBox, this.renderer, 'selectBox');

    // remove document.
    this.threedobject.addEventListener('pointerdown', this.pointdown);
    this.threedobject.addEventListener('pointermove', this.pointermove);
    this.threedobject.addEventListener('pointerup', this.pointerup.bind(null, element));
  }


  pointermove = (event) => {
    //console.log(event)
    if (this.helper.isDown) {
      this.resetColors();
      this.selectionBox.endPoint.set(
        ((event.clientX - this.position.left) / this.position.width) * 2 - 1,
        - ((event.clientY - this.position.top) / this.position.height) * 2 + 1,
        0.5);

      this.allSelected = this.selectionBox.select();
      //console.log(this.allSelected)
      for (let index = 0; index < this.allSelected.length; index++) {
        const object = this.allSelected[index];


        for (let y = 0; y < this.selectedelement.partpropertiesarray.length; y++) {
          let childelement = this.selectedelement.partpropertiesarray[y];
          // check name 
          if (childelement.uuid == object.uuid) {
            if (object.material.emissive) {
              object.material.emissive.set(0xff0000);
            }
          }
        }

      }
    }
  }

  pointdown = (event) => {
    //    console.log(this.helper)
    this.allSelected = this.selectionBox.select();
    if (!this.dragcontrols.enabled) {
      this.resetColors();
    }

    this.selectionBox.startPoint.set(
      ((event.clientX - this.position.left) / this.position.width) * 2 - 1,
      - ((event.clientY - this.position.top) / this.position.height) * 2 + 1,
      0.5);
  }

  pointerup = (element, event) => {
    //console.log(event)
    this.selectionBox.endPoint.set(
      ((event.clientX - this.position.left) / this.position.width) * 2 - 1,
      - ((event.clientY - this.position.top) / this.position.height) * 2 + 1,
      0.5);

    this.allSelected = this.selectionBox.select();

    if (this.allSelected.length > 0) {
      //console.log(this.allSelected);
      this.selectedgroup = new THREE.Group();
      this.scene.add(this.selectedgroup);

      // for (let index = 0; index < this.allSelected.length; index++) {
      //   const object = this.allSelected[index];
      //   this.selectedgroup.attach(object);
      // }

      for (let index = 0; index < this.allSelected.length; index++) {
        const object = this.allSelected[index];

        for (let y = 0; y < this.selectedelement.partpropertiesarray.length; y++) {
          let childelement = this.selectedelement.partpropertiesarray[y];
          // check name 
          if (childelement.uuid == object.uuid) {
            this.selectedgroup.attach(object);
          }
        }

      }

      const draggableObjects = this.dragcontrols.getObjects();
      // console.log(draggableObjects)

      draggableObjects.length = 0;
      this.dragcontrols.transformGroup = true;


      draggableObjects.push(this.selectedgroup);


      this.dragcontrols.addEventListener('dragend', this.dragend.bind(null, element));
      this.dragcontrols.addEventListener('dragstart', this.dragstart);
    }
  };

  dragstart = (event: any) => {
    this.helper.isDown = false;
  }

  dragend = async (element: threedanimation, event: any) => {
    let objects = this.dragcontrols.getObjects()[0].children; //event.object.children;

    for (let i = 0; i < objects.length; i++) {
      let object = objects[i];
      //      console.log(object.userData.nameId)
      //let indexOfid = -1; // check if exists -1 is non excisting
      for (let y = 0; y < element.partpropertiesarray.length; y++) {
        let childelement = element.partpropertiesarray[y];
        // check name 
        if (childelement.nameId === object.userData.nameId) {
          // check realworld location original including the transform from the group
          var vector = new THREE.Vector3();
          vector.setFromMatrixPosition(object.matrixWorld);
          childelement.position = vector;
        }
      }

    }
  }

  dragendsingle = async (event, element, group): Promise<any> => {
    let object = event.object;
    // console.log(element.partpropertiesarray);
    console.log(object);

    let indexOfid = element.partpropertiesarray.findIndex(w => w.nameId === object.userData.nameId);
    if (indexOfid === -1) {
      console.log('no item found')
      // element.partpropertiesarray.push({ name: object.name, position: object.position, color: undefined, texture: undefined })
    } else {
      element.partpropertiesarray[indexOfid].position = object.position;
    }

    return element;
  }

  onMouseMove = (event) => {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    this.mouse.x = ((event.clientX - this.position.left) / this.position.width) * 2 - 1;
    this.mouse.y = - ((event.clientY - this.position.top) / this.position.height) * 2 + 1;
  }

  cover(texture, aspect) {
    let imageAspect = texture.image.width / texture.image.height;
    if (aspect < imageAspect) {
      texture.matrix.setUvTransform(0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5);
    } else {
      texture.matrix.setUvTransform(0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5);
    }

  }

  setImage(element, event) {
    let childindex = -1;
    //const texture = new THREE.TextureLoader().load(event);
    let texture = new THREE.TextureLoader().load(event, () => {
      this.cover(texture, 1); //1.8 / 2.0
    });
    //this.selectedthreedpart['material'].map = texture;

    this.selectedthreedpart['material'] = new THREE.MeshStandardMaterial({
      side: THREE.DoubleSide,
      map: texture
    })

    for (let index = 0; index < this.selectedelement.partpropertiesarray.length; index++) {
      const childelement = this.selectedelement.partpropertiesarray[index];
      if (childelement.name == this.selectedthreedpart.name) {
        childindex = index;
      }
    }
    if (childindex == -1) {
      this.selectedelement.partpropertiesarray.push({
        //uuid: this.selectedthreedpart.uuid,
        name: this.selectedthreedpart.name,
        position: undefined,
        color: this.selectedcolor,
        texture: event,
        visible: true
      })
    } else {
      this.selectedelement.partpropertiesarray[childindex].texture = event;
    }

    console.log(this.selectedthreedpart)
  }

  async onchangethreedcolorpart(illuminate?) {
    // change hex to three.js color

    let color = new THREE.Color();
    color.set(this.selectedcolor);
    this.selectedthreedpart['material'].color = color;

    let childindex = -1;
    for (let index = 0; index < this.selectedelement.partpropertiesarray.length; index++) {
      const childelement = this.selectedelement.partpropertiesarray[index];
      if (childelement.name == this.selectedthreedpart.name) {
        childindex = index;
      }
    }
    // remove as new index is created on load??
    if (childindex == -1) {
      this.selectedelement.partpropertiesarray.push({
        //uuid: this.selectedthreedpart.uuid,
        name: this.selectedthreedpart.name,
        position: undefined,
        color: this.selectedcolor,
        texture: undefined,
        visible: true,
      })
    } else {
      this.selectedelement.partpropertiesarray[childindex].color = this.selectedcolor;
      if (illuminate) {
        this.selectedelement.partpropertiesarray[childindex].illuminated = true;
      }

      //   this.selectedelement.partpropertiesarray[childindex].visible = this.visible;
    }
  }

  async setBaseShape(svg) {
    let svgshape = await parse(svg);
    let baseshape = this.setBasePath(svgshape);
    return baseshape
  }

  // finding the basicshape from clippath
  async setBasePath(svgobject: any): Promise<any> {
    //console.log(svgobject);
    const baselength = svgobject.children[0].children.length;
    //console.log(baselength);
    const setbaseL = baselength - 4;
    //console.log(svgobject.children[0].children[setbaseL])

    // check for base clipPath return if found proceed if not
    for (let i = 0; i < baselength; i++) {
      if (svgobject.children[0].children[i].name === 'clipPath') {
        let attri = svgobject.children[0].children[i].children[0].attributes.d;
        //console.log(attri);
        return this.createBasePath(attri);
      }
    }
    // if (svgobject.children[0].children[setbaseL].name === 'clipPath') {
    //     let attri = svgobject.children[0].children[setbaseL].children[0].attributes.d;
    //     //console.log(attri);
    //     return attri;
    // }

    // else {
    // run backward with long array as last items usually have clippath
    if (baselength < 20) {
      for (let i = 0; i > baselength; i++) {
        //console.log(svgobject.children[0].children[i].name);
        if (svgobject.children[0].children[i].name === 'clipPath') {
          return this.createBasePath(svgobject.children[0].children[i].children[0].attributes.d);
        }
      }
    }
    else {
      for (let i = (baselength - 1); i > (baselength - 20); i--) {
        //console.log(svgobject.children[0].children[i].name);
        if (svgobject.children[0].children[i].name === 'clipPath') {
          return this.createBasePath(svgobject.children[0].children[i].children[0].attributes.d);
        }
      }
    }

    for (let i = 0; i > baselength; i++) {
      if (svgobject.children[0].children[i].name === 'rect') {
        const w = svgobject.children[0].children[i].children[0].attributes.width;
        const h = svgobject.children[0].children[i].children[0].attributes.hight;
        return this.createBaseRect(w, h);
      }
    }
    // if (svgobject.attributes.width && svgobject.attributes.height) {
    //   return this.createBaseRectMM(svgobject.attributes.width, svgobject.attributes.height);
    // }
    return null;
    // }
  }

  createBaseRect(w: string, h: string) {
    let rect = `<rect width="${w}" height="${h}" fill="none" stroke-width="0" transform="scale(1,-1)"/>`;
    return rect;
  }

  createBasePath(d: string) {
    let path = `<path transform="scale(1,-1)" d="${d}" fill="none" stroke-width="0" />`;
    return path;
  }


  // !! clippaths and paths is same object?!?!
  async createSVGGroup(element, paths: any, group: any): Promise<any> {
    //console.log(paths);
    //this.exdepth = 150; //* ((1 - this.multiplyScalar) * 2);
    let side = DoubleSide;
    let sortcolorfills = {};
    let sortcolorstrokes = {};


    // _______Create and sort SHAPES________
    for (let i = 0; i < paths.length; i++) {

      const path = paths[i];
      const fillColor = path.userData.style.fill;
      const strokeColor = path.userData.style.stroke;
      //let opacity, transparent;

      if (fillColor !== undefined && fillColor !== 'none') {
        if (sortcolorfills[fillColor]) {
          sortcolorfills[fillColor].push(path);
        } else {
          sortcolorfills[fillColor] = [path];
        }
      }

      if (strokeColor !== undefined && strokeColor !== 'none') {

        for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
          const subPath = path.subPaths[j];
          const geometry = SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style);

          if (geometry) {
            geometry.applyMatrix4(new Matrix4().makeScale(1, -1, 1))

            if (sortcolorstrokes[strokeColor]) {
              sortcolorstrokes[strokeColor].push(geometry);
            } else {
              sortcolorstrokes[strokeColor] = [geometry];
            }
          }

        }
      }
    }

    //console.log(sortcolorstrokes, sortcolorfills)

    // _______Add Fill________ disabled check again later
    for (let key in sortcolorfills) {
      this.renderorder = this.renderorder + 0.1;
      let value = sortcolorfills[key];
      if (key !== '#000') {
        for (let i = 0; i < value.length; i++) {
          let path = value[i];
          //console.log(path)
          const shapes = SVGLoader.createShapes(path); // Cannot read properties of undefined (reading 'isCW')
          //const shapes = path.toShapes(); // ccw function not helping

          for (let j = 0; j < shapes.length; j++) {

            const shape: Shape = shapes[j];
            const geometry = new ExtrudeGeometry(shape, {
              depth: element.exdepth, // 1
              bevelEnabled: element.bevel,
              steps: 2,
              bevelThickness: 1,
              bevelSize: 1,
              bevelOffset: 0,
              bevelSegments: 1
            });

            geometry.applyMatrix4(new Matrix4().makeScale(1, -1, 1)); // reverse position also

            const meshfinal = new Mesh(geometry);
            let materialfill;

            meshfinal.renderOrder = this.renderorder;
            meshfinal.geometry.computeBoundingBox();

            let min = meshfinal.geometry.boundingBox.min;
            let max = meshfinal.geometry.boundingBox.max;

            group.add(meshfinal);

            // create gradient
            if (key.indexOf('url(') !== -1) {
              materialfill = await this.createGradient(element, key, geometry, side, min, max); // path color is id 
              if (!materialfill) { materialfill = await this.createMaterial(element, key, side); }
            } else {
              materialfill = this.createMaterial(element, path.color, side);
            }
            meshfinal.material = materialfill;

            meshfinal.position.z = this.renderorder;

          }
        }
      }
    }

    // check if name exist otherwise rename
    for (let z = 0; z < group.children.length; z++) {
      let child = group.children[z];
      //console.log(child)
      if (element.components.length > 0 && element.components[z]['name']) {
        child.name = element.components[z]['name']
      } else {
        let r = Math.random().toString(36).substring(7); // add random sring
        let newid = 'enydea' + z + r;
        child.name = newid;
      }

    }

    return group
  }


  async createGradient(element, pathcolor: string, mesh, side, min, max) {
    let id = pathcolor.replace('url(', '').replace(')', '').replace('"', '').replace('#', '').replace('"', '');
    let gradient = document.getElementById(id);
    console.log('gradient threed');

    let material;

    if (gradient) {

      let color1, color2;
      let colortags = gradient.getElementsByTagName('stop');
      //console.log(colortags)
      if (colortags.length > 1) {
        color1 = colortags[0].getAttribute('stop-color');
        color2 = colortags[1].getAttribute('stop-color');
      } else {
        return null
      }

      //console.log(color1, color2, min, max)
      // Fill gradients SVG§  
      material = new THREE.ShaderMaterial({
        uniforms: {
          color1: {
            value: new THREE.Color(color1)
          },
          color2: {
            value: new THREE.Color(color2)
          },
          bboxMin: {
            value: min
          },
          bboxMax: {
            value: max
          }
        },
        vertexShader: `
        uniform vec3 bboxMin;
        uniform vec3 bboxMax;
        varying vec2 vUv;
    
        void main() {
          vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
        fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
        varying vec2 vUv;
        
        void main() {
          gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
        }
      `,
        side: THREE.DoubleSide,
        wireframe: element.wireframe,
        opacity: element.opacity,
        transparent: element.transparent,
      });
    } else {
      console.log(id)
    }
    return material
  }

  createMaterial(element, color, side) {

    if (!element.materialtype) {

      let material = new THREE.MeshStandardMaterial({
        emissive: color,
        emissiveIntensity: 1,
        color: color,
        wireframe: element.wireframe,
      });

      // let material = new THREE.MeshPhysicalMaterial({
      //   color: new THREE.Color(color),
      //   side: THREE.DoubleSide,
      //   wireframe: element.wireframe,
      //   emissive: 0x0,
      //   // emissive: new THREE.Color('#000000'),
      //   // opacity: element.opacity,
      //   //transparent: element.transparent,
      //   // roughness: element.roughness, // 0-1
      //   // metalness: element.shininess,
      //   // clearcoat: 0.05, 
      //   // clearcoatRoughness: 0.05,
      //   //flatShading: true,
      //   //dithering: true
      //   //shininess: element.shininess,
      //   // specular: 0x353232,
      //   //flatShading: true,
      //   // depthWrite: false
      // });
      return material;
    } else {

      let material = new MeshPhongMaterial({
        color: color,
        side: side,
        wireframe: element.wireframe,
        emissive: 0x0,
        opacity: element.opacity,
        // transparent: element.transparent,
        shininess: element.shininess,
        // specular: 0x353232,
        //flatShading: true,
        // depthWrite: false
      });
      return material;
    }


  }


  deleteTexture() {
    this.selectedtexture = undefined;
  }


  setLight() {
    // this.light = new THREE.SpotLight(0xffffff, 1);
    this.light = new THREE.DirectionalLight(0xffffff, 1);

    this.light.position.set(-100, 320, 320);
    this.light.castShadow = true;


    // this.light.shadow.mapSize.width = 4096; // default
    // this.light.shadow.mapSize.height = 4096; // default
    // this.light.shadow.camera.near = 1; // default
    // this.light.shadow.camera.far = 1500; // default

    this.light.shadow.camera.top = 600;
    this.light.shadow.camera.bottom = - 550;
    this.light.shadow.camera.right = 700;
    this.light.shadow.camera.left = - 700;
    this.light.shadow.mapSize.set(4096, 4096); // 4096, 4096
    this.light.shadow.bias = -0.0009; //-0.001;
    this.light.shadow.camera.near = 0.1; // default
    this.light.shadow.camera.far = 1120; // default

    this.light.intensity = this.canvas.light;

    if (this.canvas.light1) {
      this.scene.add(this.light);
    }

    //Create a DirectionalLight and turn on shadows for the light
    this.renderer.shadowMap.enabled = true;

    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);  // 0xffffff = white
    // let hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 1); blue and red?? 

    if (this.canvas.light2) {
      this.scene.add(hemiLight);
    }


    const parameters = {
      elevation: this.canvas.sunheight,
      azimuth: this.canvas.sunlocation
    };

    let sun = new THREE.Vector3();
    const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
    const theta = THREE.MathUtils.degToRad(parameters.azimuth);
    sun.setFromSphericalCoords(1, phi, theta);
    this.light.position.set(sun.x, sun.y, sun.z);

    if (this.canvas.sky) {
      const sky = new Sky();
      //this.effect.selection.toggle(sky);
      sky.scale.setScalar(10000);
      this.scene.add(sky);

      const skyUniforms = sky.material.uniforms;

      skyUniforms['turbidity'].value = 10;
      skyUniforms['rayleigh'].value = 2;
      skyUniforms['mieCoefficient'].value = 0.005;
      skyUniforms['mieDirectionalG'].value = 0.8;
      sky.material.uniforms['sunPosition'].value.copy(sun);
      //plane.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();
    }

    //  let lightHelper = new THREE.SpotLightHelper(this.light);
    //  this.scene.add(lightHelper);

    // ++++++++++ very usefull for setting up lights and shadow add as option? +++++++++++++++++=
    //  let shadowCameraHelper = new THREE.CameraHelper(this.light.shadow.camera);
    //  this.scene.add(shadowCameraHelper);

    // const helper = new THREE.DirectionalLightHelper(this.light, 5);
    // this.scene.add(helper);
  }



  checkDragCamera(type) {
    //  switch on or off and if on set other options off to prevent interverance

    switch (type) {
      case 'drag':
        if (this.globalThreedControls.dragcontrol) {
          this.globalThreedControls.dragcontrol = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = true;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontrolx = false;
          this.globalThreedControls.rotatecontrolz = false;
          this.globalThreedControls.rotatecontroly = false;
        }
        break;

      case 'draggroup':
        if (this.globalThreedControls.dragcontrolgroup) {
          this.globalThreedControls.dragcontrolgroup = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = true;
          this.globalThreedControls.rotatecontrolx = false;
          this.globalThreedControls.rotatecontrolz = false;
          this.globalThreedControls.rotatecontroly = false;
        }
        break;

      case 'rotatex':
        if (this.globalThreedControls.rotatecontrolx) {
          this.globalThreedControls.rotatecontrolx = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontrolx = true;
        }
        break;

      case 'rotatey':
        if (this.globalThreedControls.rotatecontroly) {
          this.globalThreedControls.rotatecontroly = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontroly = true;
        }
        break;

      case 'rotatez':
        if (this.globalThreedControls.rotatecontrolz) {
          this.globalThreedControls.rotatecontrolz = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontrolz = true;
        }
        break;

      case 'camera':
        if (this.canvas.cameracontrol) {
          this.canvas.cameracontrol = false;
        } else {
          this.canvas.cameracontrol = true;
          this.canvas.walkcontrol = false;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontrolx = false;
          this.globalThreedControls.rotatecontrolz = false;
          this.globalThreedControls.rotatecontroly = false;
        }
        break;

      case 'walkcontrol':
        if (this.canvas.walkcontrol) {
          this.canvas.walkcontrol = false;
        } else {
          this.canvas.cameracontrol = false;
          this.canvas.walkcontrol = true;
          this.globalThreedControls.dragcontrol = false;
          this.globalThreedControls.dragcontrolgroup = false;
          this.globalThreedControls.rotatecontrolx = false;
          this.globalThreedControls.rotatecontrolz = false;
          this.globalThreedControls.rotatecontroly = false;
        }
        break;

      default:
        //  this.canvas.cameracontrol = true;
        break;
    }



    this.emitterGlobalControls.emit(this.globalThreedControls);
    this.emitterCanvas.emit(this.canvas);
    // this.canvas.cameracontrol = false;

    // if (this.canvas.cameracontrol === true && type === 'drag') {

    // }
    // if (element.dragcontrol === true && type === 'camera') {
    //   this.globalThreedControls.dragcontrol = false;
    // }
    // if (type === 'rotate') {
    //  this.globalThreedControls.rotatecontrol = true;
    // }
  }



  // set Z and X to path pixel
  fixCameraZX() {
    let distance = 500
    this.camera.position.x = 0;
    this.camera.position.y = distance;
    this.camera.position.z = 0;
    this.scaleMotionPath();
  }

  // set height to path pixel
  fixCameraY() {
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 100;
  }

  fixCameraX() {
    this.camera.position.x = 100;
    this.camera.position.y = 0;
    this.camera.position.z = 0;
  }

  zoomIn() {
    this.camera.position.y = this.camera.position.y - 30;
    this.scaleMotionPath();
  }

  zoomOut() {
    this.camera.position.y = this.camera.position.y + 30;
    this.scaleMotionPath();
  }

  scaleMotionPath() {
    // scale-transform div container svg 
    let id = this.selectedelement.id + '-' + this.selectanimationindex;
    //console.log(id);
    let divsvg = document.getElementById(id);
    let scale = parseInt(this.canvas.width) / this.getHeightEnvo(this.camera.position.y);
    divsvg.style.transform = 'scale(' + scale + ')'
  }

  getHeightEnvo(depth) {
    const cameraOffset = this.camera.position.z;
    if (depth < cameraOffset) depth -= cameraOffset;
    else depth += cameraOffset;

    // vertical fov in radians
    const vFOV = this.camera.fov * Math.PI / 180;

    // Math.abs to ensure the result is always positive
    let height = 2 * Math.tan(vFOV / 2) * Math.abs(depth);
    //console.log(height * this.camera.aspect);

    return height * this.camera.aspect

  }

  // const visibleHeightAtZDepth = ( depth, camera ) => {
  //   // compensate for cameras not positioned at z=0
  //   const cameraOffset = camera.position.z;
  //   if ( depth < cameraOffset ) depth -= cameraOffset;
  //   else depth += cameraOffset;

  //   // vertical fov in radians
  //   const vFOV = camera.fov * Math.PI / 180; 

  //   // Math.abs to ensure the result is always positive
  //   return 2 * Math.tan( vFOV / 2 ) * Math.abs( depth );
  // };

  // const visibleWidthAtZDepth = ( depth, camera ) => {
  //   const height = visibleHeightAtZDepth( depth, camera );
  //   return height * camera.aspect;
  // };

  setControls(controls, raycaster, time) {
    //console.log(this.controls)

    if (this.controls.isLocked === true) {

      // raycaster.ray.origin.copy(controls.getObject().position);
      // raycaster.ray.origin.y -= 10;
      // const intersections = raycaster.intersectObjects(this.scene.children, false);
      //  const onObject = intersections.length > 0;

      const delta = (time - this.prevTime) / 1000;

      this.velocity.x -= this.velocity.x * 10.0 * delta;
      this.velocity.z -= this.velocity.z * 10.0 * delta;

      this.velocity.y -= this.velocity.y * 10.0 * delta;

      // this.velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

      this.direction.z = Number(this.moveForward) - Number(this.moveBackward);
      this.direction.x = Number(this.moveRight) - Number(this.moveLeft);
      this.direction.y = Number(this.moveUp) - Number(this.moveDown);
      this.direction.normalize(); // this ensures consistent movements in all directions

      if (this.moveForward || this.moveBackward) this.velocity.z -= this.direction.z * 300.0 * delta;
      if (this.moveLeft || this.moveRight) this.velocity.x -= this.direction.x * 300.0 * delta;
      if (this.moveUp || this.moveDown) { this.velocity.y -= this.direction.y * 40.0 * delta; }
      // if (onObject === true) {
      //   this.velocity.y = Math.max(0, this.velocity.y);
      //   this.canJump = true;
      // }

      this.controls.moveRight(- this.velocity.x * delta);
      this.controls.moveForward(- this.velocity.z * delta);



      this.controls.getObject().position.y += (- this.velocity.y * delta)

      // this.controls.getObject().position.y += (this.velocity.y * delta); // new behavior

      // if (this.controls.getObject().position.y < 10) {
      //   this.velocity.y = 0;
      //   this.controls.getObject().position.y = 10;
      //   this.canJump = true;
      // }


    }
  }



  onKeyDown = function (event) {
    //      console.log(event)
    switch (event.code) {

      case 'ArrowUp':
      case 'KeyW':
        this.moveForward = true;
        break;

      case 'ArrowLeft':
      case 'KeyA':
        this.moveLeft = true;
        break;

      case 'ArrowDown':
      case 'KeyS':
        this.moveBackward = true;
        break;

      case 'ArrowRight':
      case 'KeyD':
        this.moveRight = true;
        break;

      case 'Space':
        if (this.canJump === true) this.velocity.y += 350;
        this.canJump = false;
        break;

      case 'KeyQ':
        this.moveUp = true;
        break;

      case 'KeyE':
        this.moveDown = true;
        break;
    }

  };

  onKeyUp = function (event) {

    switch (event.code) {

      case 'ArrowUp':
      case 'KeyW':
        this.moveForward = false;
        break;

      case 'ArrowLeft':
      case 'KeyA':
        this.moveLeft = false;
        break;

      case 'ArrowDown':
      case 'KeyS':
        this.moveBackward = false;
        break;

      case 'ArrowRight':
      case 'KeyD':
        this.moveRight = false;
        break;

      case 'KeyQ':
        this.moveUp = false;
        break;

      case 'KeyE':
        this.moveDown = false;
        break;
    }

  };


  record() {
    if (this.controls.getObject().position) {

    }
  }

  animateStars() {
    for (var i = 0; i < this.stars.length; i++) {

      let star = this.stars[i];

      // and move it forward dependent on the mouseY position. 
      star.position.z += i / 10;

      // if the particle is too close move it to the back
      if (star.position.z > 1000) star.position.z -= 2000;

    }
  }


  addSmoke() {
    const textureLoader = new THREE.TextureLoader();
    this.smokeParticles = []; // for rotating
    this.smokegroup = new Group(); // for positioning

    textureLoader.load('assets/clouds.png', texture => {
      const smokeMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
        map: texture,
        transparent: true
      });
      smokeMaterial.map.minFilter = THREE.LinearFilter;
      const smokeGeometry = new THREE.PlaneGeometry(300, 300);

      let limit = 300;


      // create meshes to rotate
      for (let i = 0; i < limit; i++) {
        let smokeMesh = new THREE.Mesh(smokeGeometry, smokeMaterial);
        this.smokeParticles.push(smokeMesh);
        let smokegroup2 = new Group();

        // create groups to position
        smokegroup2.add(smokeMesh)
        smokegroup2.position.set(Math.random() * 1024 - 500, Math.random() * 500 - 250, Math.random() * 1024 - 500);
        smokegroup2.rotation.z = Math.random() * 360;
        this.smokegroup.add(smokegroup2)
      }

      //console.log(this.smokegroup)
      this.scene.add(this.smokegroup);

    });
  }


  playVideo(id?) {
    // let video = document.getElementById(id) as HTMLVideoElement;
    // video.play();
    if (this.threedobject) {
      let videos = this.threedobject.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i] as HTMLVideoElement;
        video.play();
      }
    }
  }

  stopVideo() {
    if (this.threedobject) {
      let videos = this.threedobject.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i] as HTMLVideoElement;
        video.pause();
      }
    }
  }

}



