/* tslint:disable */
import {
  Relations
} from '../index';

declare var Object: any;
export interface WebsitetrackerInterface {
  "date": Date;
  "lastupdate": Date;
  "ip"?: string;
  "url"?: string;
  "companyname"?: string;
  "hostname"?: string;
  "city"?: string;
  "region"?: string;
  "country"?: string;
  "loc"?: string;
  "org"?: string;
  "postal"?: string;
  "street"?: string;
  "lat"?: string;
  "lon"?: string;
  "ispname"?: string;
  "isp"?: boolean;
  "TRid"?: string;
  "enydea_source"?: string;
  "enydea_medium"?: string;
  "enydea_campaign"?: string;
  "enydea_term"?: string;
  "enydea_content"?: string;
  "blacklisted"?: boolean;
  "mobile"?: boolean;
  "proxy"?: boolean;
  "hosting"?: boolean;
  "as"?: string;
  "description"?: string;
  "imgurl"?: string;
  "website"?: string;
  "os"?: string;
  "browser"?: string;
  "device"?: string;
  "id"?: any;
  "relationsId"?: any;
  relations?: Relations;
}

export class Websitetracker implements WebsitetrackerInterface {
  "date": Date;
  "lastupdate": Date;
  "ip": string;
  "url": string;
  "companyname": string;
  "hostname": string;
  "city": string;
  "region": string;
  "country": string;
  "loc": string;
  "org": string;
  "postal": string;
  "street": string;
  "lat": string;
  "lon": string;
  "ispname": string;
  "isp": boolean;
  "TRid": string;
  "enydea_source": string;
  "enydea_medium": string;
  "enydea_campaign": string;
  "enydea_term": string;
  "enydea_content": string;
  "blacklisted": boolean;
  "mobile": boolean;
  "proxy": boolean;
  "hosting": boolean;
  "as": string;
  "description": string;
  "imgurl": string;
  "website": string;
  "os": string;
  "browser": string;
  "device": string;
  "id": any;
  "relationsId": any;
  relations: Relations;
  constructor(data?: WebsitetrackerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Websitetracker`.
   */
  public static getModelName() {
    return "Websitetracker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Websitetracker for dynamic purposes.
  **/
  public static factory(data: WebsitetrackerInterface): Websitetracker{
    return new Websitetracker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Websitetracker',
      plural: 'Websitetrackers',
      path: 'Websitetrackers',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "lastupdate": {
          name: 'lastupdate',
          type: 'Date'
        },
        "ip": {
          name: 'ip',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "companyname": {
          name: 'companyname',
          type: 'string'
        },
        "hostname": {
          name: 'hostname',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "region": {
          name: 'region',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "loc": {
          name: 'loc',
          type: 'string'
        },
        "org": {
          name: 'org',
          type: 'string'
        },
        "postal": {
          name: 'postal',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "lat": {
          name: 'lat',
          type: 'string'
        },
        "lon": {
          name: 'lon',
          type: 'string'
        },
        "ispname": {
          name: 'ispname',
          type: 'string'
        },
        "isp": {
          name: 'isp',
          type: 'boolean',
          default: false
        },
        "TRid": {
          name: 'TRid',
          type: 'string'
        },
        "enydea_source": {
          name: 'enydea_source',
          type: 'string'
        },
        "enydea_medium": {
          name: 'enydea_medium',
          type: 'string'
        },
        "enydea_campaign": {
          name: 'enydea_campaign',
          type: 'string'
        },
        "enydea_term": {
          name: 'enydea_term',
          type: 'string'
        },
        "enydea_content": {
          name: 'enydea_content',
          type: 'string'
        },
        "blacklisted": {
          name: 'blacklisted',
          type: 'boolean'
        },
        "mobile": {
          name: 'mobile',
          type: 'boolean'
        },
        "proxy": {
          name: 'proxy',
          type: 'boolean',
          default: false
        },
        "hosting": {
          name: 'hosting',
          type: 'boolean',
          default: false
        },
        "as": {
          name: 'as',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "imgurl": {
          name: 'imgurl',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "os": {
          name: 'os',
          type: 'string'
        },
        "browser": {
          name: 'browser',
          type: 'string'
        },
        "device": {
          name: 'device',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "relationsId": {
          name: 'relationsId',
          type: 'any'
        },
      },
      relations: {
        relations: {
          name: 'relations',
          type: 'Relations',
          model: 'Relations',
          relationType: 'belongsTo',
                  keyFrom: 'relationsId',
          keyTo: 'id'
        },
      }
    }
  }
}
