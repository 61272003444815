<div fxLayout="column" style="max-height: 600px; margin: 10px; padding: 10px; overflow: scroll;">
    <h1 i18n>Video/Audio/Background Settings</h1>
    <br>
    <div>
        <app-background *ngIf="!canvas.videourl || !canvas['background-image']" [account]="Account" [option]="option"
            (imgurl)="setBackground($event)" (seteditvideo)="loadVideoGallery($event)">
        </app-background>
        <mat-icon *ngIf="canvas.imageurl" (click)="deleteBackgroundImage()" class="iconsize-10">
            delete
        </mat-icon>
        <label *ngIf="canvas.imageurl">{{ canvas.imageurl | fileName }}</label>
        <!-- <label *ngIf="canvas['background-image']">{{ canvas['background-image'] | fileName }}</label> -->
    </div>
    <br>

    <div>
        <app-videoupload *ngIf="!canvas.videourl" [account]="Account" [option]="option" (imgurl)="setVideoBG($event)">
        </app-videoupload>
        <mat-icon *ngIf="canvas.videourl" i18n-matTooltip matTooltip="delete video" (click)="canvas.videourl = ''" class="iconsize-10">
            delete
        </mat-icon>
        <label *ngIf="canvas.videourl">{{ canvas.videourl | fileName }}</label>
    </div>
    <br>
    <div>
        <app-audioupload *ngIf="!canvas.audio" [account]="Account" [option]="option" (imgurl)="setAudioCanvas($event)">
        </app-audioupload>
        <mat-icon *ngIf="canvas.audio" i18n-matTooltip matTooltip="delete audio" (click)="canvas.audio = ''" class="iconsize-10">
            delete
        </mat-icon>
        <label *ngIf="canvas.audio">{{ canvas.audio | fileName }}</label>
    </div>

    <br>

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header i18n>
              Background Color
            </mat-expansion-panel-header>
            <div>
                <span [(ngModel)]="canvas['background-color']" 
                     [cpToggle]="true" cpDialogDisplay="inline" name="BGname" ngDefaultControl
                    [(colorPicker)]="canvas['background-color']"  
                    [cpPresetLabel]="'Company colors'" *ngIf="company"
                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                </span>
                <button mat-raised-button (click)="canvas['background-color'] = ''" i18n>Clear</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <br>
    <div fxLayoutGap="20px" fxLayout="row">
        <mat-form-field appearance="outline" >
            <mat-select [(ngModel)]="canvas.bgstyle" i18n-placeholder placeholder="Background color effect">
                <mat-option value="" i18n>None</mat-option>
                <mat-option value="stripes" i18n>Stripes</mat-option>
                <mat-option value="gradient" i18n>Gradient</mat-option>
                <mat-option value="radial-gradient" i18n>Radial gradient</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-select [(ngModel)]="canvas.weather" i18n-placeholder placeholder="Select weather">
                <mat-option value="none" i18n>None</mat-option>
                <mat-option value="rain" i18n>Rain</mat-option>
                <mat-option value="snow" i18n>Snow</mat-option>
                <mat-option value="celebrations" i18n>Party</mat-option>
                <mat-option value="leaves" i18n>Leaves</mat-option>
                <mat-option value="sun" i18n>Sun</mat-option>
                <mat-option value="clouds" i18n>Clouds</mat-option>
                <mat-option value="stars i18n">Stars</mat-option>
                <mat-option value="flies" i18n>Flies</mat-option>
                <mat-option value="butterfly" i18n>Butterfly</mat-option>
                <mat-option value="water" i18n>Water</mat-option>
                <mat-option value="pond" i18n>Pond</mat-option>
                <mat-option value="shockwave" i18n>Shockwave</mat-option>
                <mat-option value="glitch" i18n>Glitch</mat-option>
                <mat-option value="matrix" i18n>Matrix</mat-option>
                <mat-option value="oldfilm" i18n>Old Film</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <br>
    <div fxLayout="row">
        <mat-slide-toggle fxFlex *ngIf="canvas.videourl" [(ngModel)]="canvas.loop" i18n>Loop</mat-slide-toggle>
        <mat-slide-toggle fxFlex i18n-matTooltip matTooltip="Show animation outside the box" [(ngModel)]="canvas.overflow" i18n>Overflow</mat-slide-toggle>
        <mat-slide-toggle fxFlex i18n-matTooltip matTooltip="Only for embedded code" [(ngModel)]="canvas.hovereffect" i18n>Hover
            effect</mat-slide-toggle>
        <mat-slide-toggle fxFlex i18n-matTooltip matTooltip="Adjust to page size (Embedded Code only)" [(ngModel)]="canvas.zoom" i18n>Size
            adjustment
            effect</mat-slide-toggle>
    </div>
    <br>
    <div *ngIf="canvas.bgstyle" fxLayoutGap="20px" fxLayout="row">

        <div *ngIf="canvas.bgstyle === 'stripes'">
            <mat-form-field appearance="outline" >
                <input (change)="onchangecanvas()" [(ngModel)]="canvas.stripewidth" matInput type="number">
                <mat-hint i18n>Stripe width</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" >
                <input (change)="onchangecanvas()" [(ngModel)]="canvas.direction" matInput type="number">
                <mat-hint i18n>Stripe direction deg</mat-hint>
            </mat-form-field>
        </div>

        <div *ngIf="canvas.bgstyle === 'gradient' || canvas.bgstyle === 'radial-gradient'">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title i18n>
                            Gradient color
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field appearance="outline"  *ngIf="canvas.bgstyle === 'radial-gradient'" i18n-placeholder placeholder="Select direction">
                        <mat-select [(ngModel)]="canvas.positiongradient" (selectionChange)="onchangecanvas()">
                            <mat-option value="circle at top right," i18n>top right</mat-option>
                            <mat-option value="circle at top left," i18n>top left</mat-option>
                            <mat-option value="circle at bottom right," i18n>bottom right</mat-option>
                            <mat-option value="circle at bottom left," i18n>bottom left</mat-option>
                            <mat-option value="" i18n>center</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" >
                        <input (change)="onchangecanvas()" [(ngModel)]="canvas.direction" matInput type="number">
                        <mat-hint i18n>Gradient direction deg</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" >
                        <input (change)="onchangecanvas()" [(ngModel)]="canvas.percentagecolor1" matInput type="number"
                            min="0" max="100">
                        <mat-hint i18n>color 1 deg</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" >
                        <input (change)="onchangecanvas()" [(ngModel)]="canvas.percentagecolor2" matInput type="number"
                            min="0" max="100">
                        <mat-hint i18n>color 2 deg</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" >
                        <input (change)="onchangecanvas()" [(ngModel)]="canvas.percentagecolor3" matInput type="number"
                            min="0" max="100">
                        <mat-hint i18n>color 3 deg</mat-hint>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutGap="20px">
                        <label i18n>Color 1</label>
                        <span style="width: 60px; height: 20px;" [style.background]="canvas.color1"
                            cpDialogDisplay="inline" [(colorPicker)]="canvas.color1"
                            (colorPickerChange)="onchangecanvas()" [cpPresetLabel]="'Company colors'" *ngIf="company"
                            [cpToggle]="true"
                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                        </span>
                        <label i18n>Color 2</label>
                        <span style="width: 60px; height: 20px;" [style.background]="canvas.color2"
                            cpDialogDisplay="inline" [(colorPicker)]="canvas.color2"
                            (colorPickerChange)="onchangecanvas()" [cpPresetLabel]="'Company colors'" *ngIf="company"
                            [cpToggle]="true"
                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                        </span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>


        </div>
    </div>

    <br>
    <div fxLayout="row">
        <button mat-raised-button (click)="save()" i18n>Save</button>
        <button mat-raised-button (click)="close()" i18n>Cancel</button>
    </div>
</div>