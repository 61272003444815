<div>

    <div *ngIf="!edit" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <h2 i18n>Lead {{Websitetracker.companyname}}</h2>
            <mat-icon (click)="close()" i18n>close</mat-icon>
          </div>
   
        <div fxLayout="row">
            <div fxFlex="50%" fxLayoutAlign="start start" fxLayout="column">
                <div style="width: 100%" class="websitedata">
                    <h3 i18n>Info</h3>

                    <div fxLayout="row">
                        <label fxFlex="50%" i18n>City:</label>
                        <label fxFlex="50%">{{Websitetracker.city}}</label>
                    </div>

                    <div fxLayout="row">
                        <label fxFlex="50%" i18n>Region:</label>
                        <label fxFlex="50%">{{Websitetracker.region}}</label>
                    </div>

                    <!-- <label>{{Websitetracker.isp}}</label> -->
                    <div fxLayout="row">
                        <label fxFlex="50%" i18n>Country:</label>
                        <label fxFlex="50%">{{Websitetracker.country}}</label>
                    </div>

                    <div fxLayout="row">
                        <label fxFlex="50%" i18n>Address:</label>
                        <label fxFlex="50%">{{Websitetracker.street}}</label>
                    </div>

                    <div fxLayout="row">
                        <label fxFlex="50%" i18n>Description:</label>
                        <label fxFlex="50%">{{Websitetracker.description}}</label>
                    </div>

                    <img *ngIf="Websitetracker.imgurl" src={{Websitetracker.imgurl}} alt="company image">
                </div>
            </div>
            <div fxFlex="50%" fxLayoutAlign="start start" fxLayout="column">
                <h3 i18n>Visits</h3>
                <div *ngFor="let visit of WebsitetrackerFullList" fxLayout="row" class="websitedata" fxLayoutGap="20px">
                    <div>
                        <label>{{ visit.url | slice: 0:50 }}</label>
                    </div>
                    <div>
                        <label>{{ visit.date | date: "short" }}</label>
                    </div>
                </div>
            </div>

        </div>
        <br>
        <div *ngIf="Websitetracker.lat && Websitetracker.lat !== 'undefined'" fxFlex="100%">
            <label i18n>Location</label>
            <agm-map [latitude]="Websitetracker.lat" [longitude]="Websitetracker.lon" [styles]="mapStyle"
                style="height:200px; width: 350px;">
                <agm-marker [latitude]="Websitetracker.lat" [longitude]="Websitetracker.lon"></agm-marker>
            </agm-map>
        </div>
        <br>
        <div fxLayout="row" fxLayoutGap="100px">
            <button mat-raised-button (click)="toggleEdit()" i18n>Edit & Save</button>
            <!-- <button mat-raised-button (click)="save()">Save</button> -->
            <!-- <button mat-raised-button (click)="close()">Close</button> -->

        </div>


    </div>

    <div *ngIf="edit" fxLayout="column">
        <h2 i18n>Lead {{Websitetracker.companyname}}</h2>
        <div fxLayout="column">
            <h2 i18n>Save as Relation</h2>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field appearance="outline" fxFlex="50%">
                    <mat-label i18n>Company Name</mat-label>
                    <input matInput i18n-placeholder placeholder="Company Name" [(ngModel)]="Websitetracker.companyname">
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline" fxFlex="50%">
                    <mat-label i18n>City</mat-label>
                    <input matInput i18n-placeholder placeholder="City" [(ngModel)]="Websitetracker.city">
                </mat-form-field>
                <br>

            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field appearance="outline" fxFlex="50%">
                    <mat-label i18n>Country</mat-label>
                    <input matInput i18n-placeholder placeholder="Country" [(ngModel)]="Websitetracker.country">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50%">
                    <mat-label i18n>Address</mat-label>
                    <input matInput i18n-placeholder placeholder="Street" [(ngModel)]="Websitetracker.street">
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label i18n>Description</mat-label>
                <input matInput i18n-placeholder placeholder="description" [(ngModel)]="Websitetracker.description">
            </mat-form-field>


        </div>

        <br>
        <div fxFlex="4%" *ngIf="Account.companyId === '5a2a4e6b5c2a7a06c443533d'">
            <button mat-raised-button (click)="markisp(Websitetracker)">Mark isp</button>
        </div>
        <br>
        <div fxLayout="row" fxLayoutGap="20px">
            <button mat-raised-button (click)="save()" i18n>Save</button>
            <button mat-raised-button (click)="close()" i18n>Close</button>
        </div>

    </div>
</div>