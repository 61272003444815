import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Account,
  Company,
  LoopBackAuth,
  Publications,
  PublicationsApi,
  Relations,
  TranslationApi,
  ContainersecureApi,
} from '../../../shared/sdk';
import { SpeechRecognitionService } from '../../../shared/speechservice/speechservice';
import { sourcelang, targetlang } from 'src/app/translations/languages';
import { FileUploader } from 'ng2-file-upload';
import { BASE_URL } from 'src/app/shared/base.api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppserviceService } from 'src/app/appservice.service';
import { GetColorName } from './colornaming';
import { TensorflowService } from '../../videocreator/supportfunctions/tensorflow.service';


const loadImage = (url) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = url;
});

@Component({
  selector: 'app-imageaitool',
  templateUrl: './imageaitool.component.html',
  styleUrls: ['./imageaitool.component.scss']
})
export class ImageaitoolComponent implements OnInit {
  @Input() Account: Account;
  @Input() selectedPublications: Publications;
  @Input() option: Relations;
  @Input() company: Company;

  public imageSelected: EventEmitter<string> = new EventEmitter();

  public recordactive = false;

  public searchstring: string;
  public resultstring: string;
  public maxlength: number = 40
  public temp: number = 0
  public waiting = false;

  public searchstringtranslate: string;
  public resultstringtranslate: string;
  public maxlengthtranslate: number = 4000
  public sourceLang: any = 'EN';
  public targetLang: any = 'NL';

  public sourcelanguages = sourcelang;
  public targetlanguages = targetlang;
  public selectedImage: any;

  public selectedmoodlight: any;

  public moodlights = [
    "Volumetric light",
    "Cinematic lighting",
    "Aggresive",
    "Friendly",
    "Confused",
    "Afraid",
    "Official",
    'Mysterious', 
    'Eerie',
    "Happy",
    "Insane",
    "Epic",
    "Gothic",
    "Organic",
    "Geometric",
    "Ornate",
    "Lavish",
    "Warm Lighting",
    "Cold lighting",
    "Flash Photography",
    "High-key light",
    "Low-key light",
    "Backlight",
    "Studio lighting",
    "Kodachrome",
    "Daguerrotype",
    "Camera obscura",
    "Colour splash",
    'Uplight',
    "none"
  ]

  public selectedview: any;

  public types = [
    'Ultrarealistic',
    'Photorealism',
    'Octane render',
    'Ultra-detailed',
    '8K, HD,'
  ]

  public views = [
    "Rule of thirds",
    "Close up",
    "Head shot",
    "Extreme close up",
    "Medium shot",
    "Long shot",
    "Extreme long shot",
    "Overhead view",
    "Low angle",
    "Aerial view",
    "Tilted frame",
    "Over the sholder shot",
    'f/4',
    'blur',
    'f/5.6',
    'f/10',
    'ISO 400',
    "none"
  ]

  public styles = [
    "Photo",
    "Digital Art",
    'Pencil drawing',
    "Digital Illustration",
    "Digital art illustration painting",
    "Fantasy",
    "Realistic",
    "3D render",
    "3D art",
    "Abstract",
    "Texture",
    "Street Art",
    "Game Screenshot",
    "Pixar",
    "Vintage Disney",
    "Sketch",
    "Cartoon",
    "Illustration",
    "Photo",
    "Graphic Design",
    "Motion Graphics",
    "Rococo",
    "Romanticism",
    "Realism",
    "Impressionism",
    "Post-Impressionism",
    "Expressionism",
    "Fauvism",
    "Cubism",
    "Futurism",
    "Industrialpunk",
    "Dadaism",
    "Surrealism",
    "Abstract Expressionism",
    "Pop Art",
    "Op Art",
    "Minimalism",
    "Conceptual Art",
    "Photorealism",
    "Neo-Expressionism",
    "Neo-Pop Art",
    "Hyperrealism",
    "Graffiti Art",
    "Street Art",
    "Outsider Art",
    "Feminist Art",
    "Baroque",
    "Environmental Art",
    "Land Art",
    "Body Art",
    "Performance Art",
    "New Media Art",
    "Installations Art",
    "Video Art",
    "Documentary Photography",
    "Fine Art Photography",
    "Street Photography",
    "Portrait Photography",
    "Landscape Photography",
    "Still Life Photography",
    "Art Deco",
    "Arts and Crafts",
    "Bauhaus",
    "Art Nouveau",
    "Ukiyo-e",
    "Haida Art",
    "African Art",
    "Indigenous Art.",
    "Renaissance",
    "none",
  ];

  public sizes = [
    '256x256', '512x512', '1024x1024'
  ]
  public landscape: any;
  public landscapes = [
    'Space',
    'Castle', 'Battlefield', 'On the road', 'Route 66', 'Post apocalyptic', 'Dystopia', 'Office', 'Landscape', 'In a corn field', 
    'Under the ocean', 'Beach', 'City', 'Small Town', 'Skyscraper', 'Red light district', 'Modern factory', 'Old factory', 'Football field',
    'Golf course', 'Ice skating', 'Inside a car', 'Las Vegas', 'Paris', 'London', 'Farmland', 'Office',
    'Tropical', 'Woodland', 'Forest', 'Prairie', 'Desert', 'English Garden', 'Japanese Garden', 'Tuscan', 'French garden', 'Spanish garden', 'none'
  ]
  public colorset: string;

  busyconverting: boolean;
  oldxliff: string;
  allowedMimeType = ['application/vnd.openxmlformats', 'officedocument.presentationml.presentatio', 'application/x-xliff+xml',
    'officedocument.wordprocessingml.document', 'application/vnd.openxmlformats', 'text/plain', 'text/xlif', 'text/xml', 'text/html'];
  maxFileSize = 5 * 1024 * 1024;
  public uploaderSec: FileUploader = new FileUploader({
    url: BASE_URL + "/api/translations/xlifftranslate",
    allowedMimeType: this.allowedMimeType,
    maxFileSize: this.maxFileSize,
  });

  errorMessage: string;
  imagesize = '512x512';
  variationcount: number = 2;
  pictures = [];
  selectedStyle: any;
  error: any;
  baseimage: any;
  maskimage: any;
  setmaskimage: any;
  imagedescription: any;
  selectedimagetype: any;
  //xliff: boolean;

  constructor(
    private tensorflowService: TensorflowService,
    public ContainersecureApi: ContainersecureApi,
    public dialogRef: MatDialogRef<ImageaitoolComponent>,
    public appservice: AppserviceService,
    public snackBar: MatSnackBar,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    public translationApi: TranslationApi,
    @Inject(MAT_DIALOG_DATA) public dialogaccount: any,
    private speechRecognitionService: SpeechRecognitionService,
    public PublicationsApi: PublicationsApi
  ) { }

  async ngOnInit() {
    if (!this.Account) {
      this.Account = this.dialogaccount.account;
      this.company = this.dialogaccount.company;
      this.option = this.dialogaccount.option;
      this.baseimage = this.dialogaccount.baseimage;
      this.setmaskimage = this.dialogaccount.maskimage;
      if (this.dialogaccount.variation) {
        this.makeVariation();
      }
      if (this.baseimage) {
        setTimeout(() => {
          this.ImageDetection();
        }, 500);

      }
    }


    if (this.setmaskimage) {
      //console.log('set mask')
      this.maskimage = await this.RemoveSelection(this.setmaskimage, this.dialogaccount.w, this.dialogaccount.h);
    }
    //console.log(this.Account, this.dialogaccount)
    this.uploaderSec.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploaderSec.onAfterAddingAll = (fileItems) => this.afterAddFile(fileItems);
  }

  afterAddFile(item) {
    this.dialogRef.close(this.selectImage)

  }

  makeVariation() {
    this.error = undefined;
    console.log('get Images')
    this.waiting = true;
    this.busyconverting = true;
    // this.temp = 0;
    // this.maxlength = 2048;


    let id = this.Account.standardrelation;
    if (this.appservice.option.id) {
      id = this.appservice.option.id;
    }

    this.PublicationsApi.aiVariationPicture(id, this.Account.companyId, this.Account.id,
      this.variationcount, this.imagesize, { baseimage: this.baseimage })
      .subscribe(async (results: Array<any>) => {
        this.pictures = [];
        for (let i = 0; i < results.length; i++) {
          const element = results[i];
          let picurl = await this.getFile(element.filename, element.id)
          this.pictures.push({ source: picurl });
        }
        this.waiting = false
        this.busyconverting = false;
      }, error => {
        this.error = error.message;
        this.waiting = false;
        this.busyconverting = false;
      })
  }

  async ImageDetection() {
    this.busyconverting = true;
    this.imagedescription = '';
    let img = document.getElementById('baseimage');
    let data = await this.tensorflowService.classifyImage(
      img
    );

    for (let y = 0; y < data.length; y++) {
      this.imagedescription = this.imagedescription + ' ' + data[y].className;
    }

    this.searchstring =  this.imagedescription
    console.log(data);
    this.busyconverting = false;
  }


  saveAllToFile() {

  }

  selectImage() {
    this.dialogRef.close(this.selectedImage);
  }

  onWhenAddingFileFailed(item, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }

  async getImages() {
    this.error = undefined;
    console.log('get Images')
    this.waiting = true;
    this.busyconverting = true;
    // this.temp = 0;
    // this.maxlength = 2048;


    let id = this.Account.standardrelation;
    if (this.appservice.option.id) {
      id = this.appservice.option.id;
    }
    let imagedescription = this.searchstring;

    if (!this.searchstring){this.waiting=false; this.openSnackbar('Missing description'); return}

    if (this.landscape && this.landscape !== 'none') {
      imagedescription = imagedescription + ' ' + this.landscape.join(', ');
    }

    if (this.colorset && this.colorset !== 'none') {
      let colorname = GetColorName(this.colorset)
      imagedescription = imagedescription + ' use color ' + colorname;
    }

    if (this.selectedmoodlight && this.selectedmoodlight !== 'none') {
      imagedescription = imagedescription + ' ' + this.selectedmoodlight.join(', ');
    }

    if (this.selectedview && this.selectedview !== 'none') {
      imagedescription = imagedescription + ' ' + this.selectedview.join(', ');
    }

    if (this.selectedStyle && this.selectedStyle !== 'none') {
      imagedescription = imagedescription + ' ' + this.selectedStyle.join(', ') + ' style'
    }

    if(this.selectedimagetype && this.selectedimagetype !== 'none'){
      console.log(this.selectedimagetype)
      imagedescription = imagedescription + ' ' + this.selectedimagetype.join(', ');
    }

    console.log(imagedescription);
   // return
    // console.log(imagedescription)

    if (!this.baseimage) {
      //console.log(this.temp)
      this.PublicationsApi.aiPicture(id, this.Account.companyId, this.Account.id, imagedescription,
        this.variationcount, this.imagesize)
        .subscribe(async (results: Array<any>) => {
          this.pictures = [];
          for (let i = 0; i < results.length; i++) {
            const element = results[i];
            let picurl = await this.getFile(element.filename, element.id)
            this.pictures.push({ source: picurl });
          }
          this.waiting = false
          this.busyconverting = false;
        }, error => {
          this.error = error.message;
          this.waiting = false;
          this.busyconverting = false;
        })
    } else {
      this.PublicationsApi.aiEditPicture(id, this.Account.companyId, this.Account.id, imagedescription,
        this.variationcount, this.imagesize, 'test', 'test', { baseimage: this.baseimage, maskimage: this.maskimage })
        .subscribe(async (results: Array<any>) => {
          this.pictures = [];
          for (let i = 0; i < results.length; i++) {
            const element = results[i];
            let picurl = await this.getFile(element.filename, element.id);
            //console.log(picurl)
            this.pictures.push({ source: picurl });
          }

          this.waiting = false;
          this.busyconverting = false;
        }, error => {
          this.error = error.message;
          this.waiting = false;
          this.busyconverting = false;
        })
    }
  }

  async getFile(name, id) {
    return new Promise(async (fullfill, reject) => {
      // get stream and encode base64 to pdf
      this.ContainersecureApi.getFilesByFilename(this.Account.companyId, id, name)
        .subscribe(res => {
          console.log(res)
          const byteCharacters = atob(res.$data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });
          const url = window.URL.createObjectURL(blob);
          fullfill(url);
        })
    });
  }

  onSelectImage(url) {
    this.selectedImage = url;
  }


  copy(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  activateSpeech(): void {
    //load in service speech service
    // connected class to recordactive
    //
    //see manual --> https://hassantariqblog.wordpress.com/2016/12/04/angular2-web-speech-api-speech-recognition-in-angular2/
    if (this.recordactive === false) {
      this.recordactive = true;
      this.speechRecognitionService.record()
        .subscribe(
          //listener
          (value) => {

            this.searchstring = value;

          },
          //errror
          (err) => {
            //console.log(err.error, "--restarting service--");
            this.activateSpeech();
          },
          //completion
          () => {
            //console.log("--complete--");
            //this.activateSpeech();
          });
    } else {
      this.speechRecognitionService.speechRecognition.stop()
      this.speechRecognitionService.DestroySpeechObject();
      this.recordactive = false;
    }

  }

  activateSpeechTranslate() {
    if (this.recordactive === false) {
      this.recordactive = true;
      this.speechRecognitionService.record()
        .subscribe(
          (value) => {
            this.searchstringtranslate = value;
          },
          (err) => {
            console.error(err)
            this.activateSpeechTranslate();
          })
    } else {
      this.speechRecognitionService.speechRecognition.stop()
      this.speechRecognitionService.DestroySpeechObject();
      this.recordactive = false;
    }

  }


  //set variable and upload + save reference in Publications
  // async setuploadSec() {
  //   console.log(this.Account)
  //   if (!this.targetLang) { this.openSnackbar('Missing Target Language') }
  //   this.busyconverting = true;
  //   // let filename = this.uploaderSec.queue[0].file.name;
  //   // filename = filename.replace(/ /g, '-');
  //   let secUrl = BASE_URL + "/api/translations/translatefile/" +
  //     this.Account.standardrelation +
  //     "?accountId=" + this.Account.id +
  //     "&companyId=" + this.Account.companyId +
  //     "&sourcelang=" + this.sourceLang +
  //     "&targetlang=" + this.targetLang +
  //     "&oldxliff=" + this.oldxliff

  //   this.uploaderSec.setOptions({
  //     url: secUrl,
  //     headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
  //   });

  //   let name = this.uploaderSec.queue[0].file.name
  //   let ext = name.split('.').pop();
  //   let newext = this.targetLang.toLowerCase() + '.' + ext;
  //   let newname = name.replace(ext, newext);

  //   this.uploaderSec.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
  //     if (status === 200) {
  //       // console.log(response)
  //       this.busyconverting = false;
  //       this.downloadToFile(response, newname);

  //       this.uploaderSec.clearQueue();

  //       this.uploaderSec.setOptions({
  //         url: secUrl,
  //         headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
  //       });
  //     }
  //   }

  //   this.uploaderSec.uploadAll();
  //   //this.downloadToFile(this.getTestString(), newname);


  // }

  downloadToFile(res, filename) {
    // let blob: Blob = this.dataURItoBlob(res);
    let file = JSON.parse(res);
    //console.log(file)
    const byteCharacters = atob(file.$data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    let urlCreator = window.URL;
    let url = urlCreator.createObjectURL(blob);
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.openSnackbar('Translation downloaded');
    this.dialogRef.close();
  }

  openSnackbar(message) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: 'snackbar-class'
    });
  }



  async RemoveSelection(imgstring, w, h): Promise<string> {
    var c = document.createElement('canvas');
    var ctx = c.getContext("2d");

    var img = await loadImage(imgstring) as HTMLImageElement;
    img.src = imgstring;

    //await new Promise(resolve => img.onload = () => { resolve });
    ctx.drawImage(img, 0, 0);
    //console.log(imgstring, w, h);

    //var w = img.width, h = img.height;

    c.width = w;
    c.height = h;


    ctx.drawImage(img, 0, 0, w, h);
    var imageData = ctx.getImageData(0, 0, w, h);
    var pixel = imageData.data;

    //console.log(pixel)
    // ]#rgb(229,43,80)
    var r = 0, g = 1, b = 2, a = 3;
    for (var p = 0; p < pixel.length; p += 4) {
      if (
        pixel[p + r] == 229 &&
        pixel[p + g] == 43 &&
        pixel[p + b] == 80
      ) {
        pixel[p + a] = 0;
      } // if white then change alpha to 0
    }

    ctx.putImageData(imageData, 0, 0);
    let res = c.toDataURL('image/png');
    // console.log(res)
    return res

  }


}




