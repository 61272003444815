

import { BASE_URL, API_VERSION } from '../base.api';
export const templatescreenshots = [
    BASE_URL  + '/api/Containers/animationtemplates/download/castle.jpg',
]

// create json format for template 
// JSON contains 

// castle.json = {
//     name: string,
//     canvas: [canvas],
//     animationarray: [],
//     counter: number,
// }
// azure create templates folder 

export const templates = [
    BASE_URL  + '/api/Containers/animationtemplates/download/castle.json',
]


//   BASE_URL  + '/api/Containers/standardbackgroundimages/download/forest.jpg',