<div class="addtobutton">
  <button mat-fab color="primary" matTooltip="Create new Publication" (click)="newItem()">
    <mat-icon>add</mat-icon>
  </button>
</div>
<div class="listshowcontainer" fxShow fxHide.gt-xs>
  <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
  <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div fxLayout fxLayout="row" fxLayoutWrap="wrap" fxFlexAlign="start" class="card-left-menu"
  (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
  <div class="card-left-menu" fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
    [class.listview-xs-show]="listviewxsshow">

    <h4>Search</h4>
    <mat-form-field appearance="outline">
      <input autocomplete="false" matInput #searchTerm id="seach-box" (keyup.enter)="getPublications(searchTerm.value)"
        placeholder="Name/Address" />
    </mat-form-field>

    <div fxFlex fxLayout="column">
      <button a mat-raised-button color="primary" (click)="getPublications(searchTerm.value)">
        <mat-icon>search</mat-icon>Search Result
      </button>
      <button a mat-raised-button (click)="getPublications()">
        Show All
      </button>
      <div *ngFor="let Publications of Publications" (click)="onSelect(Publications)">
        <span [class.selected]="Publications === selectedPublications" class="listbox">{{ Publications.title }}</span>
      </div>
    </div>

    <mat-select placeholder="Max number of results" [(ngModel)]="limitresult" name="limitresult">
      <mat-option *ngFor="let number of numbers" [value]="number.value">
        {{ number.viewValue }}
      </mat-option>
    </mat-select>

    <div fxLayout="column" style="width: 100%">
      <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
        <div fxFlexstyle="width: 50%">
          <button (click)="getbackpage()" [disabled]="skip === 0">
            <mat-icon (click)="getbackpage()">navigate_before</mat-icon>
          </button>
        </div>
        <div fxFlexstyle="width: 50%">
          <button (click)="getnextpage()" [disabled]="totalpublicationcount < limitresult + skip">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <label>{{skip}} / {{limitresult + skip}}</label>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex><label i18n>Total</label></div>
        <div fxFlex><label>{{selectedcount}}/{{totalpublicationcount}}</label></div>
      </div>
    </div>
  </div>
 
  <div ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
    <mat-card *ngIf="selectedPublications" on-focusout="savePublication()">

      <div fxLayoutAlign="space-between center">
        <mat-form-field style="width: 95%;" appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title" [(ngModel)]="selectedPublications.title">
        </mat-form-field>
  
        <button mat-icon-button [matMenuTriggerFor]="menumarketing" class="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menumarketing="matMenu">
          <button mat-menu-item (click)="openDialogDelete()">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item (click)="newItem()">
            <mat-icon>note_add</mat-icon>
            <span>New</span>
          </button>
        </mat-menu>
      </div>

      <div *ngIf="selectedPublications.futuredimages" class="picturecontainer" fxLayout="row wrap"
        fxLayoutAlign="space-between start">
        <div *ngFor="let picture of selectedPublications.futuredimages">
          <img *ngIf="picture.url" src="{{picture.url}}" class="generatedpicture" (click)="onSelectImage(picture.url)">
          <!-- <img *ngIf="!picture.url" src="{{picture}}" class="generatedpicture" (click)="onSelectImage(picture)"> -->
        </div>
      </div>

      <div *ngIf="files" class="picturecontainer" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div *ngFor="let picture of files" fxLayout="column">
          <img [src]="picture.source | safe: 'resourceUrl'" class="generatedpicture" (click)="onSelectImage(picture.url)">
          <button mat-raised-button (click)="OnSaveImage(picture.source)">Save Image</button>
        </div>
      </div>

      <mat-form-field style="width: 95%;" appearance="outline" *ngIf="selectedPublications.text">
        <textarea matTooltip="insert text" #inputpreview [(ngModel)]="selectedPublications.text" name="Text" type="text"
          matInput i18n-placeholder placeholder="Text" style="height: 400px;"></textarea>
        <!-- <mat-icon matTooltip="select Emoji" (click)="onshowemoji(i)" matSuffix>
            insert_emoticon</mat-icon> -->
        <mat-hint align="end">{{inputpreview.value?.length || 0}}</mat-hint>
      </mat-form-field>
      <br>
      <app-aitool [Account]="Account" [selectedPublications]="selectedPublications" [option]="option"
        [company]="company"></app-aitool>

      <br>
      <button a mat-raised-button (click)="savePublication()">
        Save
      </button>

    </mat-card>
  </div>

  <!-- <div ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
    <mat-card *ngIf="selectedPublications">
      <mat-card-content on-focusout="savePublication()" fxLayout="column">
        <mat-toolbar class="titlebox">
          <h2>
            {{ selectedPublications.title | uppercase | slice: 0:30 }}
          </h2>
          <button mat-icon-button [matMenuTriggerFor]="menumarketing" class="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menumarketing="matMenu">
            <button mat-menu-item (click)="openDialogDelete()">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="newItem()">
              <mat-icon>note_add</mat-icon>
              <span>New</span>
            </button>
          </mat-menu>
        </mat-toolbar>
        <div fxLayout="row">
          <div fxLayout="column" class="selectpublication" *ngIf="selectedPublications">

            <div fxLayout="row" fxLayout.xs="column">
              <mat-form-field appearance="outline" class="form-full-width">
                <input autocomplete="false" matInput [(ngModel)]="selectedPublications.title" placeholder="Item Title"
                  class="InputField" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <mat-form-field appearance="outline" class="form-full-width">
                <input autocomplete="false" matInput [(ngModel)]="selectedPublications.description"
                  placeholder="Description" class="InputField" />
              </mat-form-field>
            </div>

            <div *ngIf="changenow" fxLayout="row wrap" fxLayoutGap="25px">
           
  <div *ngFor="let block of selectedPublications.template; let i = index;" [style.height]="block.style.height"
    [style.width]="block.style.width" fxLayout="column" [ngStyle]="block.style" style="position: relative;">
    <mat-icon class="blockedit" (click)="toggleBlockEdit(block)">edit</mat-icon>
    <div *ngIf="block.type === 'text' && block.edit">
      <ckeditor id="{{block.id}}" [(ngModel)]="block.content" [config]="Ckconfig">
      </ckeditor>
    </div>
    <div *ngIf="block.type === 'text' && !block.edit" [innerHTML]="block.content | safeHtml"></div>
    <div *ngIf="block.type === 'image'" style="height: 100%; width: 100%;" id="{{block.id}}">
      <app-fileupload *ngIf="!block.content" [account]="Account" [option]="option"
        (imgurl)="onSelectImageBlock($event, i)">
      </app-fileupload>
      <img *ngIf="block.content" src={{block.content}} style="max-width:100%; height:auto">

    </div>
    <div *ngIf="block.type === 'video'" style="height: 100%; width: 100%;" id="{{block.id}}">
      <app-videoupload *ngIf="!block.content" [account]="Account" [option]="option" (imgurl)="setVideoBlock($event, i)">
      </app-videoupload>
      <video *ngIf="block.content" preload="auto" [src]="block.content | safe: 'url'" style="height: 100%; width: 100%;"
        controls>
      </video>

    </div>
    <div *ngIf="block.type === 'animation'" id="{{block.id}}">

      <div *ngIf="block.content" [innerHTML]="block.content | safeHtml"></div>
      <mat-form-field appearance="outline" *ngIf="!block.content">
        <mat-label>Select animation</mat-label>
        <mat-select [(ngModel)]="editablevideo" (selectionChange)="loadVideo(block)">
          <mat-option *ngFor="let editvideo of editablevideos" [value]="editvideo">
            {{editvideo.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="block.type === 'emptyspace'" style="height: 100%; width: 100%;" id="{{block.id}}">
    </div>
    <div fxLayout="row" *ngIf="block.edit" class="editbox">
      <mat-select [(ngModel)]="block.style.margin" placeholder="margin" matTooltip="Margin"
        (selectionChange)="detectChange()">
        <mat-option value="">0</mat-option>
        <mat-option value="1px">1</mat-option>
        <mat-option value="2px">2</mat-option>
        <mat-option value="5px">5</mat-option>
        <mat-option value="10px">10</mat-option>
        <mat-option value="20px">20</mat-option>
      </mat-select>
      <mat-select [(ngModel)]="block.style.padding" placeholder="padding" matTooltip="Padding"
        (selectionChange)="detectChange()">
        <mat-option value="">0</mat-option>
        <mat-option value="1px">1</mat-option>
        <mat-option value="2px">2</mat-option>
        <mat-option value="5px">5</mat-option>
        <mat-option value="10px">10</mat-option>
        <mat-option value="20px">20</mat-option>
      </mat-select>
      <input autocomplete="false" style="min-width: 60px;" placeholder="Background color"
        [(ngModel)]="block.style['background-color']" style="width: 60px; height: 30px; z-index: 10"
        [style.background]="block.style['background-color']" [cpToggle]="false"
        [(colorPicker)]="block.style['background-color']" [cpPosition]="'auto'" (colorPickerChange)="detectChange()"
        [cpCancelButton]="true" [cpPresetLabel]="'Company colors'" *ngIf="company"
        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
      <mat-select [(ngModel)]="block.style['box-shadow']" placeholder="Shadow" matTooltip="Shadow"
        (selectionChange)="detectChange()">
        <mat-option value=" 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)">Yes</mat-option>
        <mat-option value="">No</mat-option>
      </mat-select>
      <mat-select [(ngModel)]="block.style['border-radius']" placeholder="Round Corners" matTooltip="Round Corners"
        (selectionChange)="detectChange()">
        <mat-option value="5px">small</mat-option>
        <mat-option value="20px">medium</mat-option>
        <mat-option value="50%">large</mat-option>
        <mat-option value="">No</mat-option>
      </mat-select>
    </div>
    <div fxLayout="row" *ngIf="block.edit" class="editbox">
      <mat-select [(ngModel)]="block.style.width" placeholder="size width" matTooltip="Width"
        (selectionChange)="detectChange()">
        <mat-option value="100%">1</mat-option>
        <mat-option value="47%">1/2</mat-option>
        <mat-option value="30%">1/3</mat-option>
        <mat-option value="22%">1/4</mat-option>
        <mat-option value="19%">1/5</mat-option>
        <mat-option value="16%">1/6</mat-option>
        <mat-option value="60%">2/3</mat-option>
        <mat-option value="66%">3/4</mat-option>
      </mat-select>
      <mat-select [(ngModel)]="block.style.height" placeholder="size height" matTooltip="Height"
        (selectionChange)="detectChange()">

        <mat-option value="100px">100px</mat-option>
        <mat-option value="200px">200px</mat-option>
        <mat-option value="300px">300px</mat-option>
        <mat-option value="400px">400px</mat-option>
        <mat-option value="500px">500px</mat-option>
        <mat-option value="800px">800px</mat-option>
        <mat-option value="1000px">1000px</mat-option>
        <mat-option value="1200px">1200px</mat-option>
        <mat-option value="2000px">2000px</mat-option>
      </mat-select>

      <mat-icon matTooltip="up" (click)="moveSectionUp(i)" class="iconsize-14 sectionlabelbuttons" matSuffix>
        arrow_drop_up</mat-icon>
      <mat-icon matTooltip="down" (click)="moveSectionDown(i)" class="iconsize-14 sectionlabelbuttons" matSuffix>
        arrow_drop_down</mat-icon>
      <mat-icon class="iconsize-14" (click)="deleteBlockItem(i)" matTooltip="Delete block">delete</mat-icon>
    </div>
  </div>
</div>

<div fxLayout="row">
  <speed-dial-fab matTooltip="Add new item" class="speed-dial-container" [reverse-column-direction]="true"
    (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons">
  </speed-dial-fab>
</div>

<br>
<div fxLayout="row" fxLayout.xs="column">
  <mat-accordion style="width: 100%">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>date_range</mat-icon>Set Date
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <label>Date/Start Date</label>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <mat-datepicker matTooltip="Send day or the day a recurrent event should start" #picker5
              class="form-half-width"></mat-datepicker>
            <input autocomplete="false" [matDatepicker]="myDatepicker5" [(ngModel)]=selectedPublications.date
              placeholder="(start/send) date">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker5"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker5></mat-datepicker>
          </div>
          <div fxFlex>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Timezone" [(ngModel)]="selectedPublications.timezone">
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                  {{ timezone.timezone }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex>
            <mat-form-field appearance="outline">
              <input autocomplete="false" matInput [(ngModel)]=selectedPublications.timeinterval placeholder="Time"
                class="InputField" type="time">
            </mat-form-field>
          </div>
        </div>
      </div>
      <label>You can only schedule Wordpress websites otherwise you will only schedule related posts/channels</label>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<br>
<div fxLayout="row" fxLayout.xs="column">
  <mat-accordion style="width: 100%">
    <mat-expansion-panel>
      <mat-expansion-panel-header (click)="convertToHTML()">
        <mat-panel-title>
          <mat-icon>text_fields</mat-icon> Preview
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHTML]="selectedPublications.text | safeHtml"></div>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>forum</mat-icon> Dynamic Content
        </mat-panel-title>
      </mat-expansion-panel-header>
      Set parameters for sharing dynamic content
      <mat-form-field appearance="outline" class="form-full-width">
        <input autocomplete="false" matInput [(ngModel)]="selectedPublications.keywords" placeholder="Keywords"
          class="InputField" />
      </mat-form-field>

      The article should be mostly: <br>
      <mat-checkbox>Positive</mat-checkbox><br>
      <mat-checkbox>Negative</mat-checkbox><br>
      <mat-checkbox>Informative</mat-checkbox><br>

      <br>

      <br>
      <mat-select placeholder="Lanuage" [(ngModel)]="selectedPublications.language" name="Language">
        <mat-option *ngFor="let language of languages" [value]="language.language">
          {{ language.country }} / {{ language.language }}
        </mat-option>
      </mat-select>
      <br>
      <mat-select placeholder="Timeframe" [(ngModel)]="selectedPublications.timeframe" name="timeframe">
        <mat-option *ngFor="let timeframe of timeframes" [value]="timeframe.name">
          {{ timeframe.name }}
        </mat-option>
      </mat-select>

      <button mat-button (click)="createDynaContent()">Run</button>
      <br>
      <mat-progress-bar *ngIf="searchdynatext" mode="query"></mat-progress-bar>

      <br>
      <div *ngIf="dynatext">
        <div *ngFor="let dynatext of dynatext">
          <mat-card>
            <h2 i18n>{{dynatext.title}}</h2>
            <a href="{{dynatext.url}}" target="_blank">{{dynatext.url}}</a>
            <br>
            <div *ngFor="let tone of dynatext.tone" fxLayout="row">
              <mat-icon *ngIf="tone.tone_name === 'Joy'">mood</mat-icon>
              <mat-icon *ngIf="tone.tone_name === 'Tentative'">rate_review</mat-icon>
              <mat-icon *ngIf="tone.tone_name === 'Sadness'">sentiment_very_dissatisfied</mat-icon>
              <mat-icon *ngIf="tone.tone_name === 'Analytical'">school</mat-icon>

              <mat-icon *ngIf="tone.tone_name === 'Fear'">mood_bad</mat-icon>
              <mat-icon *ngIf="tone.tone_name === 'Anger'">sports_mma</mat-icon>
              <mat-icon *ngIf="tone.tone_name === 'Confident'">sentiment_satisfied</mat-icon>
              <label fxFLex>&nbsp;{{tone.tone_name}}:&nbsp; </label>
              <label fxFlex>&nbsp;{{tone.score | percent}} </label>
              <br>
            </div><br>
            <h4 i18n>Summary</h4>
            <label>{{dynatext.abstract }}</label><br>

            <h4 i18n>Keywords</h4>
            <label>{{dynatext.keywords}}</label><br>
          </mat-card>
          <br>
          <button a mat-raised-button style="margin: 10px 0px 10px 0px;" (click)="setTextPublication(dynatext)" i18n>
            Use text
          </button>
          <br>
        </div>
      </div>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>
          <mat-icon>insert_photo</mat-icon> Add media
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <div *ngIf="selectedPublications.pictureurl">
          <img src="{{ selectedPublications.pictureurl }}" class="picture" />
          <button class="deletepicture" mat-mini-fab color="primary" (click)="selectedPublications.pictureurl = ''">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <app-fileupload *ngIf="!selectedPublications.pictureurl" [account]="Account" [option]="option"
          (imgurl)="onSelectImage($event)">
        </app-fileupload>
      </div>

      <div>
        <div *ngIf="selectedPublications.videourl && !selectedPublications.pictureurl" style="max-width: 600px;">

          <video style="max-width: 600px;" preload="auto" controls style="max-width: 600px;"
            [src]="selectedPublications.videourl" type="video/mp4">
          </video>

          <mat-icon (click)="selectedPublications.videourl = ''" class="icon-label" class="iconsize-12">
            delete</mat-icon>
        </div>
        <app-videoupload *ngIf="!selectedPublications.videourl && !selectedPublications.pictureurl" [account]="Account"
          [option]="option" (imgurl)="setVideo($event)">
        </app-videoupload>
      </div>
    </mat-expansion-panel>
    <br>
    <mat-expansion-panel (click)="getChannels()" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>share</mat-icon> Share on Social Media / Add Channels
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-raised-button (click)="autoGenerateChannels()"
        matTooltip="Add Channels for every Social media Account">Auto Generate</button>
      <br>
      <div fxLayout="row"></div>
      <mat-form-field appearance="outline">
        <mat-label>Select Channel</mat-label>
        <mat-select [(ngModel)]="channelaccountSel" (selectionChange)="createChannelSel($event)">
          <mat-option *ngFor="let chnacc of channelAccount" [value]="chnacc">
            {{chnacc.name}} - {{chnacc.type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <speed-dial-fab matTooltip="Add new item" class="speed-dial-container" [reverse-column-direction]="true"
        (fabClick)="onSpeedDialFabClickedSocialMedia($event)" [buttons]="speedDialFabButtonsSocialMedia">
      </speed-dial-fab>
      <div *ngIf="selectedPublicationsChannels">
        <div *ngFor="let chan of selectedPublicationsChannels; let y = index;">
          <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
            <label>{{chan.title}}</label>
            <label>{{chan.type}}</label>
            <mat-icon matTooltip="view/edit post" class="iconsize-10" (click)="gotToChannel(chan)">create
            </mat-icon>
            <mat-icon matTooltip="delete post" class="iconsize-10" (click)="deleteChannel(y)">delete
            </mat-icon>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<br>
<div fxLayout="row" class="form-full-width">
  <mat-icon (click)="openUrl(selectedPublications.url)">public</mat-icon>
  <mat-form-field appearance="outline" fxFlex=90%>
    <input autocomplete="false" matInput name="URL" type="url" [(ngModel)]="selectedPublications.url"
      placeholder="url" />
  </mat-form-field>
</div>
<br>
<div fxLayout="row" fxLayout.xs="column">
  <button a mat-raised-button (click)="savePublication()">
    Save
  </button>
</div>

<br>
<label>Publish</label>
<br>
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Wordpress
    </mat-expansion-panel-header>

    <div fxLayout="row">
      <div fxLayout="column" class="form-full-width">
        <mat-form-field appearance="outline">
          <input autocomplete="false" matInput name="Email" type="url" [(ngModel)]="this.selectedPublications.website"
            placeholder="url" name="url" />
        </mat-form-field>
      </div>
    </div>
    <br>
    <div fxLayout="row">
      <div fxLayout="column" class="form-full-width">
        <mat-form-field appearance="outline">
          <input autocomplete="false" matInput name="Email" type="email"
            [(ngModel)]="this.selectedPublications.username" placeholder="username/email" name="email" />
        </mat-form-field>
      </div>
    </div>
    <br>
    <div fxLayout="row">
      <div fxLayout="column" class="form-full-width">
        <mat-form-field appearance="outline">
          <input autocomplete="false" matInput placeholder="Enter your password" name="password" [(ngModel)]="password"
            [type]="!hide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="!hide = hide">{{hide ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row">
      <div *ngIf="!selectedPublications.cmsid">
        <button a mat-raised-button (click)="postToWordPress()">
          Publish
        </button>
      </div>
      <div *ngIf="selectedPublications.cmsid">
        <button a mat-raised-button (click)="updateWordpressPost()">
          Update
        </button>
      </div>
      <button *ngIf="!selectedPublications.cmsid" a mat-raised-button
        (click)="postToWordPress(selectedPublications.date)">
        Schedule
      </button>
    </div>

  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Joomla
    </mat-expansion-panel-header>
    Coming soon
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Download as HTML
    </mat-expansion-panel-header>
    <button (click)="downloadAsHTML()" mat-raised-button>Download</button>
    <button (click)="copyHTMLtoClipboard()" mat-raised-button>Copy</button>
  </mat-expansion-panel>
</mat-accordion>
<br>

</div>
</div>
</mat-card-content>
</mat-card>
</div> -->
</div>