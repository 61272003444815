import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DialogsService } from './dialogsservice/dialogs.service';



@Injectable()
export class PwaService {
    //public yesorno: boolean = false;
    public promptEvent;

    constructor(
        private swUpdate: SwUpdate,
        public dialogsService: DialogsService,) {

        this.swUpdate.available.subscribe(event => {
           this.askUserToUpdate();
        });

        window.addEventListener('beforeinstallprompt', event => {
            this.promptEvent = event;
        });
    }

    askUserToUpdate() {
        this.dialogsService
            .confirm('update app', 'Do you wan to Update to the latest version of enydea app?')
            .subscribe(res => {
                if (res){
                    window.location.reload();
                }
            });
    }



}