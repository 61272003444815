import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  Relations, RelationsApi,  CompanyApi, Company, Account,
  Files, FilesApi, ContainerApi, LoopBackAuth, Container
} from '../../shared/sdk';
import {BASE_URL } from '../../shared/base.api';
import { FileUploader } from 'ng2-file-upload';
import { Observable, BehaviorSubject } from 'rxjs';
import { UploadResult } from '../../marketing/xlsx-file-upload/xlsx-file-upload.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-uploadcrm',
  templateUrl: './uploadcrm.component.html',
  styleUrls: ['./uploadcrm.component.scss']
})
export class UploadcrmComponent implements OnInit {

  @Input() Account: Account;
  @Input() Company: Company;

  public headers = [];
  public addcontact = false;

  // relations headers
  public relationHeaders = {
    relationnameheader: '',
    address1header: '',
    address2header: '',
    zipcodeheader: '',
    cityheader: '',
    stateheader: '',
    countryheader: '',
    industryheader: '',
    websiteheader: '',
    phoneheader: '',
    statusheader: '',
    ridheader: '',
    successheader: '',
    const1header: '',
    const2header: '',
    const3header: '',
    const4header: '',
    firstnameheader: '',
    lastnameheader: '',
    emailheader: '',
    officephoneheader: '',
    cellphoneheader: '',
    socialmediaheader: '',
    imheader: '',
    imtypeheader: '',
    linkedinheader: '',
    twitterheader: '',
    facebookheader: '',
    instagramheader: '',
    pinterestheader: '',
    functionheader: '',
    salutationheader: '',
    directmanagerheader: '',
    likeheader: ''
  }

  // contactperson headers
  public contactpersonHeaders = {
    firstnameheader: '',
    lastnameheader: '',
    emailheader: '',
    officephoneheader: '',
    cellphoneheader: '',
    socialmediaheader: '',
    imheader: '',
    imtypeheader: '',
    linkedinheader: '',
    twitterheader: '',
    facebookheader: '',
    instagramheader: '',
    pinterestheader: '',
    functionheader: '',
    salutationheader: '',
    directmanagerheader: '',
    likeheader: '',
    ridheader: '',
  }

  // Calls headers
  public callsHeaders = {
    titleheader: '',
    attendeeheader: '',
    calltypeheader: '',
    calldateheader: '',
    taskheader: '',
    noteheader: '',
    followupheader: '',
    followupdoneheader: '',
    callbackheader: '',
    doneheader: '',
    contentheader: '',
    goodcallheader: '',
    ridheader: '',
  }

  allowedMimeType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  maxFileSize = 10 * 1024 * 1024;
  public newFiles: Files = new Files();
  public uploader = new FileUploader({
    allowedMimeType: this.allowedMimeType,
    // headers: [{name:'Accept', value:'application/json'}],
    autoUpload: false,
    //maxFileSize: this.maxFileSize,
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public uploaderContent: BehaviorSubject<string> = new BehaviorSubject('Drop File Here');
  public uploadlist;

  constructor(
    public snackBar: MatSnackBar,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    public RelationsApi: RelationsApi,
    public CompanyApi: CompanyApi,
    public filesApi: FilesApi,
    public ContainerApi: ContainerApi
  ) { }

  ngOnInit(): void {

  }

  resetAll(){
    this.headers = [];
    this.addcontact = false;
  
    // relations headers
    this.relationHeaders = {
      relationnameheader: '',
      address1header: '',
      address2header: '',
      zipcodeheader: '',
      cityheader: '',
      stateheader: '',
      countryheader: '',
      industryheader: '',
      websiteheader: '',
      phoneheader: '',
      statusheader: '',
      ridheader: '',
      successheader: '',
      const1header: '',
      const2header: '',
      const3header: '',
      const4header: '',
      firstnameheader: '',
      lastnameheader: '',
      emailheader: '',
      officephoneheader: '',
      cellphoneheader: '',
      socialmediaheader: '',
      imheader: '',
      imtypeheader: '',
      linkedinheader: '',
      twitterheader: '',
      facebookheader: '',
      instagramheader: '',
      pinterestheader: '',
      functionheader: '',
      salutationheader: '',
      directmanagerheader: '',
      likeheader: ''
    }
  
    // contactperson headers
    this.contactpersonHeaders = {
      firstnameheader: '',
      lastnameheader: '',
      emailheader: '',
      officephoneheader: '',
      cellphoneheader: '',
      socialmediaheader: '',
      imheader: '',
      imtypeheader: '',
      linkedinheader: '',
      twitterheader: '',
      facebookheader: '',
      instagramheader: '',
      pinterestheader: '',
      functionheader: '',
      salutationheader: '',
      directmanagerheader: '',
      likeheader: '',
      ridheader: '',
    }
  
    // Calls headers
    this.callsHeaders = {
      titleheader: '',
      attendeeheader: '',
      calltypeheader: '',
      calldateheader: '',
      taskheader: '',
      noteheader: '',
      followupheader: '',
      followupdoneheader: '',
      callbackheader: '',
      doneheader: '',
      contentheader: '',
      goodcallheader: '',
      ridheader: '',
    }
    this.uploader.clearQueue();
    this.uploadlist = undefined;
  }

  setUploader() {
    this.uploader.options.url = '';
    this.uploader.options.headers = [{ name: 'Authorization', value: this.auth.getAccessTokenId() }];
  }


  uploadRelations(): void {
    // check if container exists
    this.ContainerApi.createContainer({ name: this.Company.id }).subscribe(res => { }, err => {console.log(err)});
    console.log(this.Account, this.addcontact)
    this.uploader.queue[0].file.name = this.uploader.queue[0].file.name.replace(/ /g, '-');
    let filename = this.uploader.queue[0].file.name;
    this.newFiles.name = filename,
      this.newFiles.url = BASE_URL + "/api/Containers/" + this.Company.id + "/download/" + filename,
      this.newFiles.createdate = new Date(),
      this.newFiles.type = "secure",
      this.newFiles.companyId = this.Account.companyId,
      this.newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
    let url = BASE_URL + "/api/Containers/" + this.Company.id + "/upload";
    this.uploader.setOptions({
      url: url,
      headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
    });
    this.uploader.uploadAll();
    this.CompanyApi.createFiles(this.Company.id, this.newFiles)
      .subscribe(res => {
        this.CompanyApi.uploadRelations(this.newFiles.url, this.Account.companyId, this.relationHeaders, this.Account.id, this.addcontact)
          .subscribe((res) => {
            console.log(res);
            this.resetAll();
            this.openSnackBar(res);
          });
      });

  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: "snackbar-class"
    });
  }

  uploadContactpersons(): void {
    if (this.contactpersonHeaders.firstnameheader !== '') {
      this.ContainerApi.createContainer({ name: this.Company.id }).subscribe(res => { });
      this.uploader.queue[0].file.name = this.uploader.queue[0].file.name.replace(/ /g, '-');
      let filename = this.uploader.queue[0].file.name;
      this.newFiles.name = filename,
        this.newFiles.url = BASE_URL + "/api/Containers/" + this.Company.id + "/download/" + filename,
        this.newFiles.createdate = new Date(),
        this.newFiles.type = "secure",
        this.newFiles.companyId = this.Account.companyId,
        this.newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
      let url = BASE_URL + "/api/Containers/" + this.Company.id + "/upload";
      this.uploader.setOptions({
        url: url,
        headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
      });
      this.uploader.uploadAll();
      this.CompanyApi.createFiles(this.Company.id, this.newFiles)
        .subscribe(res => {
          this.CompanyApi.uploadContactpersons(this.newFiles.url, this.Account.companyId, this.contactpersonHeaders, this.Account.id)
            .subscribe((res) => {
              console.log(res);
              this.openSnackBar(res);
            });
        });
    } else { this.openSnackBar('First name missing') }
  }

  uploadCalls(): void {
    if (this.callsHeaders.contentheader !== '') {
      this.ContainerApi.createContainer({ name: this.Company.id }).subscribe(res => { });
      this.uploader.queue[0].file.name = this.uploader.queue[0].file.name.replace(/ /g, '-');
      let filename = this.uploader.queue[0].file.name;
      this.newFiles.name = filename,
        this.newFiles.url = BASE_URL + "/api/Containers/" + this.Company.id + "/download/" + filename,
        this.newFiles.createdate = new Date(),
        this.newFiles.type = "secure",
        this.newFiles.companyId = this.Account.companyId,
        this.newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
      let url = BASE_URL + "/api/Containers/" + this.Company.id + "/upload";
      this.uploader.setOptions({
        url: url,
        headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
      });
      this.uploader.uploadAll();
      this.CompanyApi.createFiles(this.Company.id, this.newFiles)
        .subscribe(res => {
          this.CompanyApi.uploadCalls(this.newFiles.url, this.Account.companyId, this.callsHeaders, this.Account.id)
            .subscribe((res) => {
              console.log(res);
              this.openSnackBar(res);

            });
        });
    } else this.openSnackBar('Content missing')
  }

  //file upload 1
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  //file upload 2
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  uploaderFileSet(event) {
    console.log('file event', event);
    this.uploader.addToQueue(event);
  }

  // convert files
  public xlsxUploaded(result: UploadResult) {
    //console.log(result);
    this.headers = [];
    this.uploaderContent.next(JSON.stringify(result.payload[0][0]));
    this.uploadlist = result.payload[0];
    let i = 0;
    do {
      this.headers.push(Object.keys(this.uploadlist[0])[i]);
      //console.log(Object.keys(this.uploadlist[0])[i]);
      i++;
    }
    while (Object.keys(this.uploadlist[0])[i] !== undefined);
    this.headers.push('');

  }

}
