
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ContainerApi, Files, Relations, RelationsApi, Company, Account, FilesApi } from '../sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { templates } from './stocktemplates';
import { BASE_URL, API_VERSION } from '../base.api'

export interface DialogData {
  img;
  selected;
  storedtemplate;
  fromfile;
}

@Component({
  selector: 'app-fromfile',
  templateUrl: './fromfile.component.html',
  styleUrls: ['./fromfile.component.scss']
})
export class FromfileComponent implements OnInit {
  public Files: Files[];
  public newFiles: Files = new Files();
  public showdropbox = true;
  public showgallery = false;
  public selectedfromfile;
  public fromfiles = [];
  public images = [];
  public stored = [];

  @Input('option') option: Relations; //get id for image gallery
  @Input('account') account: Account;
  @Output() seteditvideo = new EventEmitter();
  @Output() templateurl = new EventEmitter(); //send url img back
 // @Input('stored') stored = [];
  @Input('files') files: Files;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public ContainerApi: ContainerApi,
    public relationsApi: RelationsApi,
    public fileApi: FilesApi
  ) { }

  ngOnInit() {

  }

  onOpenGallery() {
    this.fromfiles = [];
    this.images = [];
    this.stored = [];
    // , {fields: {name: true, id: true, url: true, screenshot: true,}}
    this.relationsApi.getFiles(this.option.id, {fields: {template: false}
      //{id: true, name: true, relationsId: true, companyId: true, url: true, type: true}
    }).subscribe((files: Files[]) => {
      this.Files = files,
        this.Files.forEach((file, index) => {
          // console.log(file, index);
          let ext = file.name.split('.').pop();
          if (file.type === 'video-scene'){
            // const modalImage = file.url;
            // const thumb = file.thumb
            // this.fromfile.push({image: modalImage, thumb: thumb})
            this.stored.push(file)
          } else if (ext === 'gif' || ext === "jpeg" || ext === "jpg" || ext === "bmp" || ext === "png") {
            const modalImage = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + file.name;
            this.images.push({url: modalImage})
          }

          // if (file.counter && file.type === 'video'){
          //   this.stored.push(file)
          // }
        })

    //console.log(this.stored)
    const dialogRef = this.dialog.open(dialogfromfilegallerycomponent, {
      width: '600px',
      data: { img: this.fromfiles, selected: this.selectedfromfile, storedtemplate: this.stored, fromfile: this.images }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
       
      if (result){
        if (result.id){
          this.setVideo(result.id)
        }
        else {
          // set template 
          this.setTemplate(result.url)
        } 
      }
    });
  });
  }

  setVideo(id: string){
    console.log(id);
    this.showdropbox = false;
    this.showgallery = false;
    this.seteditvideo.emit(id);
  }

  setTemplate(url) {
    this.showdropbox = false;
    this.showgallery = false;
    this.templateurl.emit(url);
  }
}

@Component({
  selector: 'dialog-fromfilegallery',
  templateUrl: 'dialog-fromfilegallery.html',
  styleUrls: ['./fromfile.component.scss']
})

export class dialogfromfilegallerycomponent implements OnInit {

  //public fileVideo = StockVideo;
  //public existingIcons = [];
  public templatesSet =  templates;
  public templates = [];
  public templatesArray = [];
  public templatesSlice = 12;
  public templatesSliceMin = 1;

  // public fromfileSet = templates ;
  // public stockbg = [];
  // public stockbgArray = [];
  // public stockbgSlice = 11;
  // public stockbgSliceMin = 0;

  public storedset = this.data.storedtemplate;
  public stored = [];
  public storedArray = [];
  public storedSlice = 12;
  public storedSliceMin = 1;

  // //public fromfileset = this.data.fromfile;
  // public fromfile = [];
  // public fromfileArray = [];
  // public fromfileSlice = 11;
  // public fromfileSliceMin = 0;

  public URL = 'http://localhost:3000/api/containers/tmp/upload';

  constructor(
    public dialogRef: MatDialogRef<dialogfromfilegallerycomponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.storedArray = [];
    this.templatesArray = [];

    // this.fromfileSet.forEach((element, index) => {
    //   const url = element;
    //   var filename = url.replace(/^.*[\\\/]/, '')
    //   if(index < 12){this.stockbg.push({ url: url, name: filename });}
    //   this.stockbgArray.push({ url: url, name: filename });
    // });
    this.templatesSet.forEach((element, index) => {
      const url = element.url;
      var filename = url.replace(/^.*[\\\/]/, '')
      if(index < 12){this.templates.push({ url: url, name: filename });}
      this.templatesArray.push({ url: url, name: filename });
    }); 
    this.storedset.forEach((element, index) => {
   // !!! using the entire file object
      if(index < 12){this.stored.push(element)}
      this.storedArray.push(element);
    });
    // this.data.fromfile.forEach((element, index) => {
    //   const url = element.screenshot;
    //   if(index < 12){this.stored.push({ url: url, name: element.name, id: element.id });}
    //   this.storedArray.push({ url: url, name: element.name, id: element.id });
    // });
    // console.log(this.storedArray,  this.storedset, this.data.fromfile)
  }

  next(galtype) {
    switch (galtype) {
      case 'templates': {
        if (this.templatesSlice < this.templatesArray.length -1 ) {
          this.templatesSlice = this.templatesSlice + 13;
          this.templatesSliceMin = this.templatesSliceMin + 13;
          this.templates = this.templatesArray.slice(this.templatesSliceMin-1, this.templatesSlice)
        }
      }

      case 'stored': {
        if (this.storedSlice < this.storedArray.length -1) {
          this.storedSlice = this.storedSlice + 13;
          this.storedSliceMin = this.storedSliceMin + 13
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }

    }
  }

  before(galtype) {
    switch (galtype) {
      case 'templates': {
        if (this.templatesSliceMin > 0) {
          this.templatesSlice = this.templatesSlice - 13;
          this.templatesSliceMin = this.templatesSliceMin - 13
          this.templates = this.templatesArray.slice(this.templatesSliceMin-1, this.templatesSlice)

        }
      }
      case 'stored': {
        if (this.storedSliceMin > 0) {
          this.storedSlice = this.storedSlice - 13;
          this.storedSliceMin = this.storedSliceMin - 13
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }
    }
  }

  onNoClick(): void {
    this.data.selected = '';
    this.dialogRef.close();
  }

  selectedimage(img): void {
    this.data.selected = img;
    //console.log(img)
  }

}