import { Component, OnInit, Input, SimpleChange, SimpleChanges, AfterContentInit } from '@angular/core';
import {
  Relations, RelationsApi, CompanyApi, Company, Account,
  Files, FilesApi, ContainerApi
} from '../../shared/sdk';
import { BASE_URL, API_VERSION } from '../../shared/base.api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ViewChild, ElementRef } from '@angular/core';
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
//import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Subscription } from 'rxjs';
import { fonts } from '../../shared/listsgeneral/fonts';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogsService } from './../../dialogsservice/dialogs.service';
import PathEditor from 'src/assets/js/utils/PathEditor';
import { SVG } from '@svgdotjs/svg.js';
import { DialogGetname } from '../../dialogsservice/dialog.getname'
import { MatDialog } from '@angular/material/dialog';
import { Draggable, MorphSVGPlugin, MotionPathPlugin } from 'src/assets/js/all';
import { gsap } from 'src/assets/js/all';
import { timeInterval } from 'rxjs/operators';
gsap.registerPlugin(MorphSVGPlugin, MotionPathPlugin);
import domtoimage from 'dom-to-image-improved';
import { ImageaitoolComponent } from './imageaitool/imageaitool.component';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export class image {
  id: string;
  type: 'image';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  opacity: number;
  grey: boolean;
  blur: boolean;
}

export class vector {
  id: string;
  type: 'vector';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
}

export class shape {
  id: string;
  type: 'shape';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    'background-color': string;
    opacity: 1;
    'border-radius': string;
    class: string;
    'box-shadow': string;
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  shape: string;
}

export class svg {
  id: string;
  type: 'svg';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  svg: string;
}

export class text {
  id: string;
  content: string;
  type: 'text';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    'font-size': string;
    'font-style': string;
    'font-weight': string;
    'font-family': string;
  }
  posx: number;
  posy: number;
  setpos: object;
}

export class chart {
  id: string;
  charttype: string;
  src: string;
  label: Label[] = [];
  data: ChartDataSets[];
  //options: 
  colors: Color[] = [
    { // grey
      backgroundColor: '#232222',
      borderColor: '#232222',
      pointBackgroundColor: '#232222',
      pointBorderColor: '#fff'
    }
  ]
  legend: true;
  type: 'chart';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
  }
  posx: number;
  posy: number;
  setpos: object;
  lineChartOptions: ChartOptions = {
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'blue',
          }
        }
      ],
      yAxes: [
        {
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'blue',
          }
        }
      ]
    }
  }
}

@Component({
  selector: 'app-imagecreator',
  templateUrl: './imagecreator.component.html',
  styleUrls: ['./imagecreator.component.scss']
})



export class ImagecreatorComponent implements OnInit {


  @ViewChild('imagebgcontainer', { static: false }) pic: ElementRef;
  @ViewChild('myCanvas') myCanvas: ElementRef;
  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;

  private MorphSVGPlugin = MorphSVGPlugin;
  public selectedvideoformat: string;
  public zoomfactor = 1;
  public editmode = true;
  public Fonts = fonts;
  public listviewxsshow = false;
  public showprogressbar = false;
  public uploader: FileUploader;
  public newFiles: Files = new Files();
  public images = [];
  public changenow = true;
  public shiftX = 0;
  public shiftY = 0;
  public aspectRatio = true;
  public imagename = '';
  public editableimage: Files;
  public editableimages: Files[];
  public context: CanvasRenderingContext2D;
  public canvas = {
    top: '',
    left: '',
    width: '1024px',
    height: '1024px',
    bgwidth: '100%',
    bgheight: '100%',
    'background-color': '#ffffff',
    position: 'relative',
    'background-image': '',
    'background-size': 'cover',
    bgimg: '',
    clippath: '',
    cliparray: [],
    blur: false,
    grey: false,
    cropimages: false,
    keepratio: true
  }
  public imgzindex = -1;
  //public cropimages = false;
  public closewhiteboard = false;
  public whiteboard = false;
  public whiteboardcolor = "#000";
  public whiteboardstokewidth = 2;
  public whiteboardsmoothing = 8;
  public shapedraw = '';
  public moveitem = false;
  public selectedimage: image;
  public showemoji = false;
  public newz = 1;
  public imageloaded = false;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  watcher: Subscription;
  activeMediaQuery;
  public pathEditor: PathEditor;
  public standardpath = 'square';

  public snaptogridwidth = 20;
  public snaptogridheight = 20;
  public snaptogrid = false;
  draggableObject: any;
  edittext: boolean;
  AIwhiteboard: boolean;
  createnew: boolean = false;
  busy: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogsService: DialogsService,
    public media: MediaObserver,
    private relationsApi: RelationsApi,
    private filesApi: FilesApi,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    // detect screen type
    // this.watcher = media.media$.subscribe((change: MediaChange) => {
    //   this.activeMediaQuery = change;
    // });
  }

  ngOnInit() { }

  createVariation() {
    this.dialogsService
      .confirm('Create picture Variation', 'Are you sure you want to do this (cost may apply)?')
      .subscribe(res => {
        if (res) {


          domtoimage.toPng(document.getElementById('myBounds')).then(base64String => {


            if (this.Account) {
              const dialogRef = this.dialog.open(ImageaitoolComponent, {
                width: '800px',
                data: {
                  account: this.Account, company: this.company, option: this.option,
                  baseimage: base64String,
                  w: parseInt(this.canvas.width),
                  h: parseInt(this.canvas.height),
                  variation: true
                }
              });

              dialogRef.afterClosed().subscribe(async (bloburl) => {
                if (bloburl) {
                  let newurl = await this.uploadToBack(bloburl)
                  this.canvas.bgimg = newurl;
                  //this.addNewImage(result)
                }
              });
            }
          });
        }
      });
  }


  addNewAIImage(base64string?, maskbase64string?) {
    if (this.Account) {
      const dialogRef = this.dialog.open(ImageaitoolComponent, {
        width: '800px',
        data: {
          account: this.Account, company: this.company, option: this.option,
          baseimage: base64string,
          maskimage: maskbase64string,
          w: parseInt(this.canvas.width),
          h: parseInt(this.canvas.height)
        }
      });

      dialogRef.afterClosed().subscribe(async (bloburl) => {
        if (bloburl) {
          // this.canvas.bgimg = result;
          let newurl = await this.uploadToBack(bloburl)
          this.addNewImage(newurl);

        }
      });
    }
  }

  startWithNewAIImage() {
    if (this.Account) {
      const dialogRef = this.dialog.open(ImageaitoolComponent, {
        width: '800px',
        data: {
          account: this.Account, company: this.company, option: this.option,
          w: parseInt(this.canvas.width),
          h: parseInt(this.canvas.height)
        }
      });

      dialogRef.afterClosed().subscribe(async (bloburl) => {
        if (bloburl) {
          let newurl = await this.uploadToBack(bloburl)
          this.canvas.bgimg = newurl;
          // this.addNewImage(result)
        }
      });
    }
  }

  editWithAI() {
    this.AIwhiteboard = true;
    this.shapedraw = '';
    this.startNewWhiteboard();
  }

  async savewhiteboardAI() {
    this.busy = true;

    //let name = Math.random().toString(36).substring(7) + '.png';
    await new Promise(resolve => setTimeout(resolve, 400));
    window.scrollTo(0, 0); /* !important somehow domtoimage won't work  */

    domtoimage.toPng(document.getElementById('myBounds')).then(async maskbase64string => {
      var base64str = maskbase64string.split('base64,')[1];
      var decoded = atob(base64str);
      //console.log("FileSize: " + decoded.length / 1e+6);

      if (decoded.length > 4000000) {
        this.snackBar.open('file too large: ' + (decoded.length / 1e+6) + 'MB max size is 4MB')
        return
      }

      this.AIwhiteboard = false;
      await new Promise(resolve => setTimeout(resolve, 400));

      domtoimage.toPng(document.getElementById('myBounds')).then(base64String => {

        //console.log(base64String)
        const previewstring = '<div style="width: 400px; height: 400px;"><img style="width: 400px;" src="' + base64String + '" /></div>';
        const previewhtml = [];
        previewhtml.push(this.sanitizer.bypassSecurityTrustHtml(previewstring));
        this.editmode = true;
        this.detectchange();
        this.addNewAIImage(base64String, maskbase64string);

        this.busy = false;

      });
    });

  }

  // Edit existing files form the quick open menus
  getEditFile() {
    this.relationsApi.getFiles(this.option.id, { where: { template: { "neq": null }, type: 'image' } })
      .subscribe((files: Files[]) => {
        this.editableimages = files;
        // console.log('received files', this.editableimages);
      });
  }

  setPosition(idel) {
    //console.log(idel, 'position set')
    //if animation is move --> path determines position
    let elm = document.getElementById(idel.id);
    //console.log(elm)
    gsap.set(elm, { x: idel.posx, y: idel.posy, rotation: idel.rotation });
  }

  onChangeShape(element) {
    element.style['border-radius'] = '';
    element.style.class = '';

    if (element.shape === 'round') {
      element.style['border-radius'] = '50%';
    }
    // if (element.shape === 'square') {
    //   element.style['border-radius'] = '0%';
    // }

    // if (element.shape === 'triangle') {
    //   element.style.class = 'triangle'
    // }

    if (element.shape === 'heart') {
      element.style.class = 'heart'
      element.style['background-color'] = 'rgba(0, 0, 0, 0)';
      element.style.width = element.style.height;
    }

    if (element.shape === 'star') {
      element.style.class = 'star-six'
      element.style['background-color'] = 'rgba(0, 0, 0, 0)';
    }
    this.detectchange();
  }

  async detectchange() {
    this.changenow = false;
    setTimeout(() => { this.changenow = true }, 10);
    await new Promise(resolve => setTimeout(resolve, 100));


    if (this.zoomfactor !== 1) {
      // let element = document.getElementById('containernormal');
      // let rect = element.getBoundingClientRect();
      let scale = this.zoomfactor - 1;
      let w = parseInt(this.canvas.width) / 2;
      let h = parseInt(this.canvas.height) / 2;
      this.canvas.left = w * scale + 'px';
      this.canvas.top = h * scale + 'px';

      if (this.canvas.clippath) {
        let doc = document.getElementById('croppath');
      }
    }

    if (this.zoomfactor === 1) {
      this.canvas.left = '0px';
      this.canvas.top = '0px';
    }

    for (let i = 0; i < this.images.length; i++) {
      const elm = this.images[i];
      this.setPosition(elm);

    }
  }

  async setOriginalSize(elm) {

    let imghtml = document.getElementById(elm.id) as HTMLImageElement;
    await new Promise(resolve => setTimeout(resolve, 100));

    if (imghtml) {
      let imghtmltag = imghtml.getElementsByTagName('img')[0];
      console.log(imghtmltag)
      if (!imghtmltag.complete && imghtmltag.naturalHeight == 0) {
        await new Promise((resolve) => { imghtmltag.onload = resolve; });
      }

      elm.style.width = imghtmltag.naturalWidth + 'px';
      elm.style.height = imghtmltag.naturalWidth + 'px';
    }
  }

  setEditText() {
    if (this.edittext === true) {
      this.edittext = false;
    } else {
      this.edittext = true;
    }

    this.detectchange();
    //console.log('set edit text');
  }

  addcell(i, i1): void {
    this.images[i].data[i1].data.push(0);
  }

  addLabel(i): void {
    this.images[i].label.push("new label");
  }

  addgraph(i): void {
    this.images[i].data.push({ data: [0, 0, 0], labels: 'new label' });
    this.images[i].colors.push(
      { // grey
        backgroundColor: '#232222',
        borderColor: '#232222',
        pointBackgroundColor: '#232222',
        pointBorderColor: '#fff'
      }
    )
  }

  deletegraph(i): void {
    let del = this.images[i].data.length - 1;
    this.images[i].data.splice(del, 1);
    this.detectchange();
  }

  detectchangerowcell(i, i1, i2, cell): void {
    this.images[i].data[i1].data[i2] = cell;
    this.detectchange();
  }

  detectchangerowlabel(i, i1, labelnew): void {
    this.images[i].data[i1].label = labelnew;
    this.detectchange();
  }

  detectchangeLabel(i, i1, label): void {
    this.images[i].label[i1] = label;
    this.detectchange();
  }

  detectchangetype(i, type): void {
    this.images[i].charttype = type;
    this.detectchange();
  }

  deletelabel(i, i1) {
    let del = this.images[i].label.length - 1;
    this.images[i].label.splice(del, 1);
    this.detectchange();
  }

  deletecell(i, i1) {
    let del = this.images[i].data[i1].data.length - 1;
    this.images[i].data[i1].data.splice(del, 1);
    this.detectchange();
  }


  addNewWhiteboard(): void {
    this.shapedraw = '';
    if (this.whiteboard === false) {
      this.whiteboard = true;
    }
  }

  addNewImage(src?): void {

    this.newz = this.newz + 1;
    let img: image = {
      id: this.getID(),
      opacity: 1,
      type: 'image',
      style: {
        'z-index': this.newz,
        width: "",
        height: "",
        position: 'absolute',
        //transform : 'translate(10px, 10px)'
      },
      src: '',
      posx: 50,
      posy: 50,
      setpos: { 'x': 50, 'y': 50 },
      grey: false,
      blur: false
    }
    if (src) {
      img.src = src;
    }
    this.images.push(img);
    this.detectchange();
  }

  addNewVector(): void {
    this.newz = this.newz + 1;
    let img: vector = {
      id: this.getID(),
      type: 'vector',
      style: {
        'z-index': this.newz,
        width: "auto",
        height: "auto",
        position: 'absolute',
        //transform : 'translate(10px, 10px)'
      },
      src: '',
      posx: 50,
      posy: 50,
      setpos: { 'x': 50, 'y': 50 }
    }
    this.images.push(img);
    this.detectchange();
  }

  addNewShape(): void {

    this.newz = this.newz + 1;
    let img: shape = {
      id: this.getID(),
      type: 'shape',
      style: {
        'z-index': this.newz,
        width: "200px",
        height: "200px",
        position: 'absolute',
        'background-color': '#000000',
        opacity: 1,
        'border-radius': '0%',
        class: '',
        'box-shadow': ''
      },
      src: '',
      posx: 50,
      posy: 50,
      setpos: { 'x': 0, 'y': 0 },
      shape: ''
    }
    this.images.push(img);
  }

  addNewText(): void {
    this.newz = this.newz + 1;

    let txt: text = {
      id: this.getID(),
      type: 'text',
      style: {
        'z-index': this.newz,
        width: "300px",
        height: "100px",
        position: 'absolute',
        'font-size': '20px',
        'font-family': 'Open Sans',
        'font-style': '',
        'font-weight': '',
        //transform : 'translate(10px, 10px)'
      },
      content: 'write here',
      posx: 20,
      posy: 50,
      setpos: { 'x': 20, 'y': 50 }
    }
    this.images.push(txt);
  }

  addNewChart(): void {

    this.newz = this.newz + 1;
    let colorset: Color[] = [
      { // grey
        backgroundColor: '',
        borderColor: '#232222',
        pointBackgroundColor: '#232222',
        pointBorderColor: '#fff'
      },
      { // grey
        backgroundColor: '',
        borderColor: '#232222',
        pointBackgroundColor: '#232222',
        pointBorderColor: '#fff'
      }
    ];
    let lineChartOptions: ChartOptions = {

      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0,0,0,0.3)',
            },
            ticks: {
              fontColor: 'black',
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(0,0,0,0.3)',
            },
            ticks: {
              fontColor: 'black',
            }
          }
        ]
      }
    };

    let chart: chart = {
      id: this.getID(),
      src: '',
      charttype: 'line',
      label: ['January', 'February', 'March'],
      data: [
        { data: [65, 59, 40], label: 'Series A' },
        { data: [28, 27, 90], label: 'Series B' }
      ],
      //options: 
      colors: colorset,
      legend: true,
      type: 'chart',
      style: {
        'z-index': this.newz,
        width: '400px',
        height: '400px',
        position: 'absolute'
      },
      posx: 20,
      posy: 50,
      setpos: { 'x': 20, 'y': 50 },
      lineChartOptions: lineChartOptions

    }
    this.images.push(chart);
    //console.log(chart);
  }

  onLoad() {
    //console.log((this.pic.nativeElement as HTMLImageElement).naturalWidth);
    if (this.imageloaded) {
      this.canvas.width = (this.pic.nativeElement as HTMLImageElement).naturalWidth + 'px';
      this.canvas.height = (this.pic.nativeElement as HTMLImageElement).naturalHeight + 'px';
      // this.canvas.bgwidth = (this.pic.nativeElement as HTMLImageElement).naturalWidth + 'px';
      // this.canvas.bgheight = (this.pic.nativeElement as HTMLImageElement).naturalHeight + 'px';
      this.imageloaded = false;
      this.detectchange();
    }
  }

  setMainImage(event) {
    this.canvas.bgimg = event;
    this.imageloaded = true;
    this.detectchange();
  }

  setMainSVG(event) {
    this.canvas.bgimg = event;
    this.imageloaded = true;
    this.detectchange();
  }

  setVector(event, i): void {
    this.images[i].src = event;
  }

  setImage(event, i): void {
    this.images[i].src = event;
    this.setOriginalSize(this.images[i])
  }

  onMoving(event, i) {
    this.images[i].posy = event.y;
    this.images[i].posx = event.x;
  }

  onResizing(e, i) {
    this.images[i].style.width = e.size.width + 'px';
    this.images[i].style.height = e.size.height + 'px';
  }

  //BG image:
  onpicturesizechange(e: any, side: string) {
    let w = parseInt(this.canvas.width);
    let h = parseInt(this.canvas.height);
    let newnum = parseInt(e.target.value)
    if (this.canvas.keepratio) {
      if (side == 'width') {
        let ratio = h / w;
        this.canvas.width = e.target.value;
        this.canvas.height = Math.round(newnum * ratio) + 'px';
      }

      if (side == 'height') {
        let ratio = w / h;
        this.canvas.width = Math.round(newnum * ratio) + 'px';
        this.canvas.height = e.target.value;
      }
    } else {
      if (side == 'width') {
        this.canvas.width = e.target.value;
      }
      if (side == 'height') {
        this.canvas.height = e.target.value;
      }
    }
    this.detectchange();
  }

  async OnSaveImage() {
    // hide resize handles
    this.editmode = false;
    this.detectchange();
    this.draggableObject = undefined;
    this.selectedimage = undefined;

    //   if (this.imagename === undefined) {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'new-image' }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.imagename = res;
      this.saveImage()
    });
  }



  saveImage() {
    // check if name is set and check name duplicates 
    let name = this.imagename + '.png';
    if (!this.imagename) {
      name = Math.random().toString(36).substring(7) + '.png';
    }

    this.relationsApi.getFiles(this.option.id, { where: { name: name } })
      .subscribe(async (res) => {
        if (res.length > 0) {
          name = this.imagename + res.length + '.png'
        }

        await new Promise(resolve => setTimeout(resolve, 400));
        window.scrollTo(0, 0); /* !important somehow domtoimage won't work  */

        domtoimage.toPng(document.getElementById('myBounds')).then(canvas => {
          const previewstring = '<div style="width: 400px; height: 400px;"><img style="width: 400px;" src="' + canvas + '" /></div>';
          const previewhtml = [];
          previewhtml.push(this.sanitizer.bypassSecurityTrustHtml(previewstring));
          this.editmode = true;
          this.detectchange();
          this.dialogsService
            .confirm('Preview', 'Save Image and download?', previewhtml[0])
            .subscribe((res) => {
              if (res) {
                this.downloadAndUploadToServer(canvas, name);
              }
            });
        });
      });
  }

  async uploadToBack(bloburl): Promise<string> {
    return new Promise(async (fullfill, reject) => {

      let blob = await fetch(bloburl).then(r => r.blob());
      let name = Math.random().toString(36).substring(7) + '-AI.png';
      let date: number = new Date().getTime();
      let file = new File([blob], name, { type: "image/png", lastModified: date });
      let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
      this.uploader = new FileUploader({ url: urluse });
      let fileItem = new FileItem(this.uploader, file, {});
      this.uploader.queue.push(fileItem);
      fileItem.upload();
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        // set download url or actual url for publishing
        let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
        imgurl = imgurl.replace(/ /g, '-'),
          // define the file settings
          this.newFiles.name = name,
          this.newFiles.url = imgurl,
          this.newFiles.createdate = new Date(),
          this.newFiles.type = 'image',
          this.newFiles.companyId = this.Account.companyId;
        this.newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
        this.relationsApi.createFiles(this.option.id, this.newFiles)
          .subscribe(res => {
            this.snackBar.open("Image saved!", undefined, {
              duration: 2000,
              panelClass: 'snackbar-class'
            });
            fullfill(imgurl);
            //console.log(res);
          });
      }
    });
  }

  downloadAndUploadToServer(canvas, name) {
    let blob: Blob = this.dataURItoBlob(canvas);
    let date: number = new Date().getTime();
    let file = new File([blob], name, { type: "image/jpeg", lastModified: date });
    let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    this.uploader = new FileUploader({ url: urluse });
    let fileItem = new FileItem(this.uploader, file, {});
    this.uploader.queue.push(fileItem);
    fileItem.upload();
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      // set download url or actual url for publishing
      let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
      imgurl = imgurl.replace(/ /g, '-'),
        // define the file settings
        this.newFiles.name = name,
        this.newFiles.url = imgurl,
        this.newFiles.createdate = new Date(),
        this.newFiles.type = 'image',
        this.newFiles.companyId = this.Account.companyId;
      this.newFiles.template = this.images;
      this.newFiles.canvas = [this.canvas];

      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      element.setAttribute('href', seturl);
      element.setAttribute('download', name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);


      this.relationsApi.createFiles(this.option.id, this.newFiles)
        .subscribe(res => {
          this.snackBar.open("Image saved!", undefined, {
            duration: 2000,
            panelClass: 'snackbar-class'
          });
          //console.log(res);
        });
    };
  }

  convertToSVG() {
    domtoimage.toSvg(document.getElementById('myBounds'))
      .then(function (dataUrl) {
        //console.log(dataUrl);
      });
  }


  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  saveImageData(): void {
    let i = 0;

    this.images.forEach((img, index) => {
      if (img.type === 'chart') {
        //img.data = undefined;
        img.data.forEach(dataelement => {
          dataelement._meta = undefined;
        });
        ++i;
        if (i === this.images.length) {
          //console.log(this.images)
          this.uploadFinalImages(this.images)
        }
      } else {
        ++i;
        if (i === this.images.length) {
          //console.log(this.images)
          this.uploadFinalImages(this.images)
        }
      }
    });
  }

  uploadFinalImages(imgup) {
    this.filesApi.createimage(
      this.option.id, this.Account.companyId, this.imagename, this.canvas, imgup)
      .subscribe(res => {
        this.showprogressbar = false;
        // console.log(res);
        this.snackBar.open(res, undefined, {
          duration: 2000,
          panelClass: 'snackbar-class'
        });
      })
  }



  resetImage(): void {
    this.dialogsService
      .confirm('Reset Image', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          this.createnew = false;
          this.images = [];
          this.canvas = {
            width: '600px',
            height: '1000px',
            'background-color': '#ffffff',
            position: 'relative',
            'background-image': '',
            'background-size': 'cover',
            bgimg: '',
            top: '',
            left: '',
            bgwidth: '100%,',
            bgheight: '100%',
            clippath: '',
            cliparray: [],
            blur: false,
            grey: false,
            cropimages: false,
            keepratio: true
          }
        }
      });
  }

  changeimageormat() {
    let setvideo = this.selectedvideoformat.split(' x ');
    this.canvas.width = setvideo[0];
    this.canvas.height = setvideo[1];
    this.detectchange();
  }



  deleteitem(i) {
    this.images.splice(i, 1);
  }

  drop(e) {
    //console.log(e);
    this.swapElement(this.images, e.currentIndex, e.previousIndex);
    this.images.forEach((img, i) => {
      img.style['z-index'] = i + 1;
    })

    this.detectchange()
  }

  swapElement(array, indexA, indexB) {
    var tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
  }

  setemoji(event, i) {

    this.images[i].content = this.images[i].content + event.emoji.native;
    this.onshowemoji(i);
  }

  onshowemoji(i) {
    if (this.showemoji) { this.showemoji = false } else {
      this.showemoji = true;
    }
  }

  loadEditableImage() {
    //console.log(this.editableimage.template)
    this.images = this.editableimage.template;
    this.canvas = this.editableimage.canvas[0];
    //console.log(this.images, this.canvas);
    this.detectchange();
  }

  setbold(img) {
    if (img.style['font-weight'] === 'bold') {
      img.style['font-weight'] = '';
    } else {
      img.style['font-weight'] = 'bold';
    }
    this.detectchange();
  }

  setitalic(img) {
    if (img.style['font-style'] === 'italic') {
      img.style['font-style'] = '';
    } else {
      img.style['font-style'] = 'italic';
    }
    this.detectchange();
  }

  swiperight(e?) {
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  startNewWhiteboard(): void {
    console.log('start whiteboard')
    //this.selectedelement = '';
    //this.whiteboard = true;
    window.scrollTo(0, 0);

    setTimeout(() => {
      //  https://stackoverflow.com/questions/40324313/svg-smooth-freehand-drawing
      let bufferSize;
      let svgElement = document.getElementById("svgElement");
      let rect = svgElement.getBoundingClientRect();
      let path = null;
      let strPath;
      let buffer = []; // Contains the last positions of the mouse cursor
      let firstpoint;
      let firstpointcircle;
      let w = parseInt(this.canvas.width); //.replace('px', '');
      let h = parseInt(this.canvas.height); //.replace('px', '');
      let newview = '0 0 ' + w + ' ' + h;
      svgElement.setAttribute('viewBox', newview);

      svgElement.addEventListener("mousedown", (e) => {
        //bs = document.getElementById("cmbBufferSize") as unknown;
        bufferSize = this.whiteboardsmoothing;
        if (this.AIwhiteboard) { bufferSize = 1 }
        path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        if (this.AIwhiteboard) {
          // path.setAttribute("fill", '#e52b50');
          path.setAttribute("fill", "none");
          path.setAttribute("stroke", '#e52b50');
          path.setAttribute("stroke-width", "30");
          path.setAttribute("stroke-linecap", "round")
        } else {
          path.setAttribute("fill", "none");
          path.setAttribute("stroke", this.whiteboardcolor);
          path.setAttribute("stroke-width", this.whiteboardstokewidth);
        }

        buffer = [];
        let pt = getMousePosition(e);
        // console.log(e)
        appendToBuffer(pt);


        firstpoint = { x: pt.x, y: pt.y };

        switch (this.shapedraw) {
          case 'circle':
            path.setAttribute("fill", '#e52b50');
            strPath = 'M ' + rect.top + ' ' + rect.left +
              'm -' + 0 + ', 0' +
              'a' + 0 + ',' + 0 + ' 0 1,0' + 0 * 2 + ',0' +
              'a' + 0 + ',' + 0 + ' 0 1,0 -' + 0 * 2 + ',0'
            break;
          case 'rectangle':
            path.setAttribute("fill", '#e52b50');
            strPath = 'M ' + pt.x + ' ' + pt.y + ' H ' + pt.x + ' V ' + pt.y + ' H ' + pt.x + ' Z';
            //strPath = 'M ' + pt.x + ' ' + pt.y + ' H ' + pt.x + ' V ' + pt.y + ' H ' + 'Z';
            break;
          default:
            strPath = "M" + pt.x + " " + pt.y;
        }

        //strPath = "M" + pt.x + " " + pt.y;
        path.setAttribute("d", strPath);
        svgElement.appendChild(path);
      });

      // add scroll event to track update rect for new scroll location
      document.getElementById('imagecontainer').addEventListener("scroll", (e) => {
        console.log('scroll');
        rect = svgElement.getBoundingClientRect();
      });

      svgElement.addEventListener("mousemove", (e) => {
        if (path) {
          switch (this.shapedraw) {
            case 'circle':
              updateSvgPathCircle(e);
              break;
            case 'rectangle':
              updateSvgPathRect(e);
              break;
            default:
              appendToBuffer(getMousePosition(e));
              updateSvgPath();
          }
        }
      });

      svgElement.addEventListener("mouseup", function () {
        if (path) {
          path = null;
        }
        if (firstpoint) {
          firstpoint = null;
        }
      });

      let getMousePosition = (e) => {
        return {
          x: e.pageX - rect.left,
          y: e.pageY - rect.top
        }

      };

      let appendToBuffer = function (pt) {
        buffer.push(pt);
        while (buffer.length > bufferSize) {
          buffer.shift();
        }
      };

      let updateSvgPathCircle = function (e) {
        let pl = svgElement.getElementsByTagName('path').length;
        let ptl = getMousePosition(e);
        ptl.x = ptl.x / 1.5;
        ptl.y = ptl.y / 1.5;
        strPath = 'M ' + firstpoint.x + ' ' + firstpoint.y +
          'm ' + ptl.x / (-2) + ', 0' +
          'a' + ptl.x / 2 + ',' + ptl.x / 2 + ' 0 1,0 ' + ptl.x + ',0' +
          'a' + ptl.x / 2 + ',' + ptl.x / 2 + ' 0 1,0 ' + ptl.x * (-1) + ',0'
        path.setAttribute("d", strPath);
        path.setAttribute("id", pl + 1);
      }

      let updateSvgPathRect = function (e) {
        let pl = svgElement.getElementsByTagName('path').length;
        let ptl = getMousePosition(e);
        strPath = 'M ' + firstpoint.x + ' ' + firstpoint.y + ' H ' + ptl.x + ' V ' + ptl.y + ' H ' + firstpoint.x + ' Z';
        path.setAttribute("d", strPath);
        path.setAttribute("id", pl + 1);
      }

      // Calculate the average point, starting at offset in the buffer
      let getAveragePoint = function (offset) {

        let len = buffer.length;
        if (len % 2 === 1 || len >= bufferSize) {
          let totalX = 0;
          let totalY = 0;
          let pt, i;
          let count = 0;
          for (i = offset; i < len; i++) {
            count++;
            pt = buffer[i];
            totalX += pt.x;
            totalY += pt.y;
          }
          return {
            x: totalX / count,
            y: totalY / count
          }
        }
        return null;
      };

      let updateSvgPath = function () {
        let pt = getAveragePoint(0);
        let pl = svgElement.getElementsByTagName('path').length;
        console.log(pl)
        if (pt) {
          // Get the smoothed part of the path that will not change
          strPath += " L" + pt.x + " " + pt.y;

          // Get the last part of the path (close to the current mouse position)
          // This part will change if the mouse moves again
          let tmpPath = "";
          for (let offset = 2; offset < buffer.length; offset += 2) {
            pt = getAveragePoint(offset);
            tmpPath += " L" + pt.x + " " + pt.y;
          }

          // Set the complete current path coordinates
          path.setAttribute("d", strPath + tmpPath);
          path.setAttribute("id", pl + 1);
        }
      };
    }, 300) // mininmum needed for dom to process

  }

  deletewhiteboard(AI?) {
    let svgElement = document.getElementById("svgElement");
    let new_element = svgElement.cloneNode(true);
    svgElement.parentNode.replaceChild(new_element, svgElement);
    this.whiteboard = false;
    if (AI) {
      this.AIwhiteboard = false;
    }
  }

  async savewhiteboard() {
    let svgElement = document.getElementById("svgElement");
    if (this.closewhiteboard) {
      let path = svgElement.getElementsByTagName('path');
      for (let i = 0; i < path.length; i++) {
        let d = path[i].getAttribute('d');
        let newd = d + ' z';
        path[i].setAttribute('d', newd);
      }
    }
    // convert to proper type
    let svg = svgElement as unknown;
    let svg2 = svg as SVGAElement;
    let rect = svg2.getBBox();
    let height = rect.height + 'px';
    let width = rect.width + 'px';
    let newsvg = svgElement.outerHTML;

    this.newz = this.newz + 1;

    let img: svg = {
      id: this.getID(),
      type: 'svg',
      style: {
        'z-index': this.newz,
        width: '100%',
        height: '100%',
        position: 'absolute',
        //transform : 'translate(10px, 10px)'
      },
      src: '',
      posx: 0,
      posy: 0,
      setpos: { 'x': 0, 'y': 0 },
      svg: newsvg
    }
    this.images.push(img);
    //this.deleteWhitespaceSVG(img);
    this.deletewhiteboard();
    this.removePathEditor();

  }

  getID() {
    let newelnr;
    let r = Math.random().toString(36).substring(7); // add random sring
    newelnr = r + '-el';
    return newelnr;
  }


  deleteWhitespaceSVG(img): void {
    // setTimeout(() => {
    let element = document.getElementById('svgElementParent');
    let svg = element.getElementsByTagName("svg")[0];
    let bbox = svg.getBBox();
    let viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
    svg.setAttribute("viewBox", viewBox);
    img.svg = svg.outerHTML;
    //}, 300);
    this.deletewhiteboard();
    this.removePathEditor();
  }


  startDraw(): void {
    //console.log(this.shapedraw);
    // this.whiteboard = true;
    this.zoomfactor = 1;
    if (this.shapedraw === 'line') {
      this.addNewLine();
    }
    if (this.shapedraw === 'figure') {
      this.addNewFigure();
    } else {
      this.startNewWhiteboard();
    }
  }

  addNewLine(): void {
    let docset = document.getElementById('svgElement');
    let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    let strPath = 'M 50,50 L 100,100 ';
    path.setAttribute("d", strPath);
    path.setAttribute("id", 'svgElementPath');
    docset.appendChild(path);
    let pathset = document.getElementById('svgElementPath');
    this.pathEditor = PathEditor.create(pathset);
  }

  addNewFigure(): void {
    //this.cancelDragSelect();
    let docset = document.getElementById('svgElement');
    let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    let strPath = 'M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457 ';
    path.setAttribute("d", strPath);
    path.setAttribute("id", 'svgElementPath');
    docset.appendChild(path);
    let pathset = document.getElementById('svgElementPath');
    this.pathEditor = PathEditor.create(pathset);
  }


  async startDraggable() {
    let svgElement = document.getElementById("svgElement");

    let new_element = svgElement.cloneNode(true);
    svgElement.parentNode.replaceChild(new_element, svgElement);
    let svg = SVG(svgElement) as any;
    svg.draggable();

    // or by path??
    let paths = svgElement.getElementsByTagName('path');
    //console.log(paths);
    for (let i = 0; i < paths.length; i++) {
      let groupie = await document.getElementById(paths[i].id);
      if (groupie !== null) {
        let path = SVG(groupie) as any;
        path.draggable();
      }
    }

  }

  removePathEditor() {
    const patheditor = document.getElementsByClassName('path-editor'); // path-editor
    const patheditorsel = document.getElementsByClassName('path-editor-selection'); // path-editor
    const elements = document.getElementsByClassName('copy-motion-path');
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].parentNode.removeChild(elements[i]);
      }
    }

    if (patheditor.length > 0) {
      for (let i = 0; i < patheditor.length; i++) {
        patheditor[i].parentNode.removeChild(patheditor[i]);
      }
    }

    if (patheditorsel.length > 0) {
      for (let i = 0; i < patheditorsel.length; i++) {
        patheditorsel[i].parentNode.removeChild(patheditorsel[i]);
      }
    }
  }

  async imageCropPath() {
    this.imgzindex = 1000;
    //this.canvas.clippath = 'url(#cropclip)'
    //this.canvas.clippath = '';
    this.canvas.cropimages = true;

    setTimeout(() => {
      let pathset = document.getElementById('croppath');
      let docset = document.getElementById('myBounds');
      let svg = document.getElementById('croppathset');
      let h = docset.getBoundingClientRect().height;
      let w = docset.getBoundingClientRect().width;
      let viewbox = '0 0 ' + w + ' ' + h;
      svg.setAttribute('viewBox', viewbox);
      // if not clippath present set to current size box
      if (!this.canvas.clippath) {
        this.resetImageCropPath();
      } else {
        this.pathEditor = PathEditor.create(pathset);
      }
    }, 500);
  }

  async resetImageCropPath() {
    let pathset = document.getElementById('croppath');
    let docset = document.getElementById('myBounds');
    //let svg = document.getElementById(this.selectedelement.id + 'crop');
    let h = docset.getBoundingClientRect().height;
    let w = docset.getBoundingClientRect().width;
    switch (this.standardpath) {
      case 'linear': {
        let editpath = 'M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457   ';
        this.canvas.clippath = editpath;
        //pathset.setAttribute('d', editpath);
        this.pathEditor = PathEditor.create(pathset);
        break
      }
      case 'circle': {
        //this.canvas.clippath = '';
        let circw = (w - 10) / 2;
        let circh = (h - 10) / 2;
        let newsvgpath = '<ellipse cx="' + circw + '" cy="' + circw + '" rx="' + circh + '" ry="' + circh + '" id="croppath" style="opacity: 0;" />';
        pathset.outerHTML = newsvgpath;
        await MorphSVGPlugin.convertToPath("ellipse");
        pathset = document.getElementById('croppath') as any;
        setTimeout(() => {
          this.canvas.clippath = pathset["d"];
        }, 200);
        //pathset.setAttribute('d', editpath);
        this.pathEditor = PathEditor.create(pathset);
        break
      }
      case 'square': {
        let editpath = 'M 10,10 L' + (w - 10) + ',10 L' + (w - 10) + ',' + (h - 10) + ' L10,' + (h - 10) + ' z';;
        this.canvas.clippath = editpath;
        pathset.setAttribute('d', editpath);
        this.pathEditor = PathEditor.create(pathset);
        break
      }
    }
  }

  async imageSaveCropPath() {
    if (this.canvas.cropimages) {
      let pathset = document.getElementById('croppath');
      //console.log(pathset)
      let rawpath = await MotionPathPlugin.getRawPath(pathset);
      let stringpath;
      let style = pathset.getAttribute('transform');
      if (style) {
        style = style.replace('matrix(', '');
        style = style.replace('matrix(', '');
        style = style.replace(')', '');
        style = style.replace(/,/g, ' ');
        let newmatrix = style.split(' ').map(Number);
        let testpath2 = await MotionPathPlugin.transformRawPath(rawpath, newmatrix[0], newmatrix[1], newmatrix[2], newmatrix[3], newmatrix[4], newmatrix[5]);
        stringpath = await MotionPathPlugin.rawPathToString(testpath2);
      } else {
        stringpath = await MotionPathPlugin.rawPathToString(rawpath);
      }
      this.canvas.clippath = stringpath;
      //this.canvas.clippath = 'url(#cropclip)'
      //this.canvas.cropimages = false;
      this.imgzindex = -1;
      this.detectchange();
    }
  }

  imageRemoveCrop() {
    this.imgzindex = -1
    this.canvas.clippath = '';
    this.canvas.cropimages = false;
    this.detectchange();
  }

  async copyElement(i, element) {
    this.newz = this.newz + 1;
    const curel = element;
    let newElement = JSON.parse(JSON.stringify(curel));
    // redo all ids
    //let newelnr = this.images.length + 'el';
    newElement.id = this.getID();
    this.newz = this.newz + 1;
    newElement.style['z-index'] = this.newz;
    newElement.groupmember = false;
    this.images.push(newElement);
  }

  setDraggable(event, idel) {
    // Draggable does not recognise ts angular so changes are direct dom js related
    // drag selectionbox should be off and vectorcombinator should be off

    this.setMovable(event, idel);
    // if (this.dragselectvectpath === false && this.vectorcombiedit === false && !idel.groupmember && !this.cropimages) {
    //   this.setMovable(event, idel);
    // } else { this.disableDraggable(); }

    // if (!this.dragselectvectpath && this.vectorcombiedit && idel.groupmember) {
    //   this.setMovable(event, idel);
    // }

  }

  setMovable(event, idel) {
    let element = document.getElementById(idel.id);
    //let inertia = false;
    let snapw = this.snaptogridwidth;
    let snaph = this.snaptogridheight;
    let dragfunc;
    let throwfunc;

    let snap = {
      x: function (value) {
        return Math.round(value / snapw) * snapw;
      },
      y: function (value) {
        return Math.round(value / snaph) * snaph;
      }
    }

    if (!this.snaptogrid) {
      snap = undefined;
      dragfunc = this.setMoveableItem;
      throwfunc = undefined;
    } else {
      dragfunc = undefined;
      throwfunc = this.setMoveableItem;
    }

    if (event.target.id === idel.id + 'rotatehandle') {
      this.setRotate(event, idel)
    } else {
      this.draggableObject = new Draggable(element, {
        type: "x,y",
        snap: snap,
        onThrowCompleteParams: [idel, snapw, snaph],
        onThrowComplete: throwfunc,
        onDragEnd: dragfunc,
        onDragEndParams: [idel, snapw, snaph],
        inertia: this.snaptogrid,
        edgeResistance: 0.95,
        //liveSnap: this.snaptogrid,
        //snap: snap,
        //bounds: '#myBounds',
      });
    }
  }

  setRotate(event, idel) {
    let el = document.getElementById(idel.id) as unknown
    let element = el as Draggable;
    Draggable.create(element, {
      type: "rotation",
      onDragEndParams: [idel],
      onDragEnd:
        function (idl) {
          idl.rotation = this.rotation;
          this.disable();
        }
    })
  }

  onSelectElement(event, element): void {
    if (element !== this.selectedimage) {
      //this.edittext = false;
      this.selectedimage = element;
    }

  }

  private setMoveableItem = async (idel) => {
    //console.log('setMoveableItem', idel, this.draggableObject.y)
    let newy = this.draggableObject.y - idel.posy;
    let newx = this.draggableObject.x - idel.posx;
    idel.posy = this.draggableObject.y;
    idel.posx = this.draggableObject.x;

    if (this.draggableObject) {
      this.draggableObject.disable();
    }

  }

  previewSVG(svg: SVGElement, parent): SVGElement {
    svg.setAttribute('width', '30');
    svg.setAttribute('height', '30');
    return svg;
  }

  previewSVGBig(svg: SVGElement, parent): SVGElement {
    svg.setAttribute('width', '100');
    svg.setAttribute('height', '100');
    return svg;
  }



}
