<!-- <div style="height: 500px; width: 500px;" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)"></div> -->
<div class="listshowcontainer" fxShow fxHide.gt-xs>
  <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
  <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div fxLayout="column" fxLayoutWrap="wrap" fxLayoutAlign="space-around">

  <div class="topbanner">
    <div class="selectrelation" fxFlex fxShow.xs="false" fxLayout="row">
      <mat-form-field appearance="outline">
        <mat-label i18n color="accent" *ngIf="selectedRelation">{{selectedRelation.relationname}}</mat-label>
        <input type="text" i18n-placeholder placeholder="Select Relation" aria-label="relation" matInput
          name="selectrelation" (keyup)="searchGoQuick($event.srcElement.value)" [matAutocomplete]="auto"
          [formControl]="myControlRelations">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnRelation">
          <mat-option *ngFor="let option of filteredRelations; let i = index" [value]="option"
            (blur)="onSelect(option)">
            {{ option.relationname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <!-- Relations -->
  <mat-tab-group class="relations" [(selectedIndex)]="selectedTab" (swipeleft)="swipeleft($event)"
    (swiperight)="swiperight($event)">
    <mat-tab label="Relations">
      <ng-template mat-tab-label>
        <mat-icon>contacts</mat-icon>
        <div i18n fxShow.xs="false">Relations</div>
      </ng-template>

      <div class="addtobutton">
        <button mat-fab color="primary" i18n-matTooltip matTooltip="Create new Relation"
          (click)="openDialogNewRelation()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="tabcontainer" fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
        <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
          [class.listview-xs-show]="listviewxsshow" class="card-left-menu">
          <h4 i18n>Search</h4>
          <mat-form-field appearance="outline">
            <mat-label>Name/Address</mat-label>
            <input matInput [(ngModel)]=searchterm (keyup.enter)="searchGo()" i18n-placeholder
              placeholder="Name/Address" />
          </mat-form-field>
          <br>
          <mat-accordion *ngIf="Company">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Search Options
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-select [(ngModel)]="selectstatus" i18n-placeholder placeholder="Status">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let statustype of Company.relationstatus" [value]="statustype">
                  {{ statustype }}
                </mat-option>
              </mat-select>
              <br>
              <mat-select [(ngModel)]="selecttype" i18n-placeholder placeholder="Type">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of Company.types" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
              <br>
              <mat-select *ngIf="Company.const1name" [(ngModel)]="selectconst1" [placeholder]="Company.const1name">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of Company.const1" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
              <br>
              <mat-select *ngIf="Company.const2name" [(ngModel)]="selectconst2" [placeholder]="Company.const2name">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of Company.const2" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
              <br>
              <mat-select *ngIf="Company.const3name" [(ngModel)]="selectconst3" [placeholder]="Company.const3name">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of Company.const3" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
              <br>
              <mat-select *ngIf="Company.const4name" [(ngModel)]="selectconst4" [placeholder]="Company.const4name">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of Company.const4" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
              <mat-checkbox [(ngModel)]="selectmarketingrelation" [disabled]="!Account.companyadmin">
                Marketing Relation
              </mat-checkbox>
              <br>
              <mat-form-field appearance="outline">
                <mat-label>Order by</mat-label>
                <mat-select [(ngModel)]='orderbyselect'>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let order of orderby" [value]="order.value">
                    {{order.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field appearance="outline">
                <mat-label>Relation owner</mat-label>
                <mat-select [(ngModel)]="searchrelationowner" i18n-placeholder placeholder="Type">
                  <mat-option *ngFor="let member of Team" [value]="member.accountId">
                    {{ member.username }}
                  </mat-option>
                  <mat-option [value]="">None</mat-option>
                </mat-select>
              </mat-form-field>
              <br>
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput google-place [(ngModel)]="searchcountry" (onSelect)="setSearchCountry($event)"
                  maxlength="100" i18n-placeholder placeholder="Country">
              </mat-form-field>
              <br>
              <button mat-button (click)="resetFilters()">Reset Filters</button>
              <br>
              <button mat-button (click)="getRelationsEmptyCalls()">Find uncontacted Relations</button>
              <div *ngIf="searching">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <br>
          <button a mat-raised-button color="primary" (click)="searchGoBox()">
            <mat-icon>search</mat-icon>
            <label i18n>Search</label>
          </button>
          <button a mat-raised-button (click)="clearSearch()" i18n>Show All</button>

          <div class="listview">
            <div *ngFor="let Relation of Relations; let i = index" (click)="onSelect(Relation)">
              <span [class.selected]="Relation === selectedRelation" class="listbox">
                {{Relation.relationname}}</span>
            </div>
          </div>
          <div fxLayout="column" style="width: 100%">
            <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
              <div fxFlexstyle="width: 50%">
                <button (click)="getRelationsbackpage()" [disabled]="skip === 0">
                  <mat-icon (click)="getRelationsbackpage()">navigate_before</mat-icon>
                </button>
              </div>
              <div fxFlexstyle="width: 50%">
                <button (click)="getRelationsnextpage()" [disabled]="selectedcount < limit + skip">
                  <mat-icon>navigate_next</mat-icon>
                </button>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <label>{{skip}} / {{limit + skip}}</label>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex><label i18n>Total</label></div>
              <div fxFlex><label>{{selectedcount}}/{{totalrelationcount}}</label></div>
            </div>
          </div>
          <br>
          <mat-accordion *ngIf="Company" style="min-width: 250px; z-index: 10;">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  To Selection
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div style="border: 1px solid grey; border-radius: 5px; padding: 5px;">
                <label i18n>Add to mailing list</label>
                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="listname" i18n-placeholder placeholder="Listname" />
                </mat-form-field>


                <mat-form-field appearance="outline">
                  <mat-label i18n>Set mailinglist domain</mat-label>
                  <mat-select [(ngModel)]="domain" i18n-placeholder placeholder="Type">
                    <mat-option *ngFor="let rel of domains" [value]="rel">
                      {{ rel.relationname }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-checkbox [(ngModel)]="mailingdefaultcontact" i18n>Default Contact only</mat-checkbox>
                <!-- <mat-checkbox [(ngModel)]="mailinggeneralemail">General email only</mat-checkbox> -->
                <!-- <mat-checkbox [(ngModel)]="mailingallcontacts">All contacts</mat-checkbox> -->
                <button a mat-raised-button (click)="createMailingList()" i18n>Create Mailinglist</button>
              </div>
              <br>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label i18n>Change Relation Owner</mat-label>
                  <mat-select [(ngModel)]="changerelationownerto" i18n-placeholder placeholder="Type">
                    <mat-option *ngFor="let member of Team" [value]="member">
                      {{ member.username }}
                    </mat-option>
                  </mat-select>
                  <mat-icon (click)="setNewOwner()" matSuffix i18n-matTooltip matTooltip="Change Owner">done</mat-icon>
                </mat-form-field>

              </div>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label i18n>Change Status</mat-label>
                  <mat-select [(ngModel)]="changerelationstatus" i18n-placeholder placeholder="Status">
                    <mat-option *ngFor="let statustype of Company.relationstatus" [value]="statustype">
                      {{ statustype }}
                    </mat-option>
                  </mat-select>
                  <mat-icon (click)="setNewStatus()" matSuffix i18n-matTooltip
                    matTooltip="Change Status">done</mat-icon>
                </mat-form-field>
              </div>

            </mat-expansion-panel>
          </mat-accordion>

        </div>
        <div ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" tabindex="-1"
          on-focusout="saveRelation()" [class.container-xs-show]="listviewxsshow" *ngIf="selectedRelation">
          <mat-card>
            <mat-card-content>
              <div fxLayout="column">
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxFlex>
                    <mat-toolbar class="titlebox" fxLayout="row">
                      <h2 fxFlex="90%">{{selectedRelation.relationname | uppercase}}</h2>
                      <button fxFlex="10%" mat-icon-button [matMenuTriggerFor]="menurelation" class="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menurelation="matMenu">
                        <button mat-menu-item (click)="openDialogDelete()">
                          <mat-icon>delete</mat-icon>
                          <span i18n>Delete</span>
                        </button>
                        <button mat-menu-item (click)="openDialogNewRelation()">
                          <mat-icon>note_add</mat-icon>
                          <span i18n>New</span>
                        </button>
                        <button mat-menu-item (click)="openDialogDeleteAllCalls()">
                          <mat-icon>delete</mat-icon>
                          <span i18n>Delete all calls</span>
                        </button>
                      </mat-menu>
                    </mat-toolbar>
                  </div>
                </div>
                <br>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Name</mat-label>
                    <input matInput google-place (onSelect)="setAddress($event)" #relationname id="relationname"
                      [(ngModel)]=selectedRelation.relationname i18n-placeholder placeholder="Name" maxlength="100">
                  </mat-form-field>
                  <mat-accordion *ngIf="Calls && Calls.length > 0" fxFlex="48%">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <label i18n>Total Calls: {{Calls.length}}</label>

                      </mat-expansion-panel-header>
                      <label i18n>Last call: </label>
                      <br>
                      <!-- <div *ngIf="Calls[Calls.length -1].date" [innerHTML]="Calls[Calls.length -1].date "></div> -->
                      <div style="max-height: 400px; overflow: scroll;">

                        <div *ngIf="Calls[Calls.length -1].content" [innerHTML]="Calls[Calls.length -1].content"></div>
                        <div *ngIf="Calls[Calls.length -1].html" style="max-width: 600px"
                          [innerHTML]="Calls[Calls.length -1].html  | safeHtml"></div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Address</mat-label>
                    <input matInput [(ngModel)]=selectedRelation.address2 i18n-placeholder placeholder="Address"
                      maxlength="100">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Address</mat-label>
                    <input matInput [(ngModel)]=selectedRelation.address1 i18n-placeholder placeholder="Address"
                      maxlength="100">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Zipcode</mat-label>
                    <input matInput [(ngModel)]="selectedRelation.zipcode" i18n-placeholder placeholder="Zipcode"
                      maxlength="10">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>City</mat-label>
                    <input matInput google-place (onSelect)="setCity($event)" [(ngModel)]="selectedRelation.city"
                      maxlength="100" i18n-placeholder placeholder="City">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>State/Province</mat-label>
                    <input matInput [(ngModel)]=selectedRelation.stateprovince i18n-placeholder
                      placeholder="State/Province" maxlength="100">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Country</mat-label>
                    <input matInput google-place (onSelect)="setCountry($event)" [(ngModel)]=selectedRelation.country
                      maxlength="100" i18n-placeholder placeholder="Country">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <div fxFlex="48%" fxLayout="column" style="margin-bottom: 5px;">
                    <label style="margin-bottom: 10px;" i18n>Contacts</label>
                    <div *ngFor="let contactperson of Contactpersons; let i = index">
                      <div fxFlex fxLayout="row" fxLayoutGap="5px">
                        <div fxFlex="30%" fxLayoutGap="2px">
                          <label style="overflow: hidden;">{{contactperson.firstname}}</label>
                          <label style="overflow: hidden;">{{contactperson.lastname}}</label>
                        </div>
                        <label fxFlex="40%" style="overflow: hidden;">{{contactperson.email}}</label>
                        <mat-icon *ngIf="contactperson.email" fxFlex="10%" class="iconsize-12" i18n-matTooltip
                          matTooltip="send Email" (click)="sendMessageToContactperson(contactperson)">send
                        </mat-icon>
                        <mat-icon *ngIf="contactperson.email" xFlex="10%" class="iconsize-12" i18n-matTooltip
                          matTooltip="send Email from template"
                          (click)="sendMessageFromTemplateToContactperson(contactperson)">message
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Website</mat-label>
                    <input matInput [(ngModel)]=selectedRelation.website i18n-placeholder placeholder="Website"
                      maxlength="100" (change)="selectedRelation.screenshotwebsite = ''">
                    <mat-icon matSuffix i18n-matTooltip matTooltip="visit website" (click)="openUrl()">public</mat-icon>
                  </mat-form-field>
                </div>
                <br>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>General phonenumber</mat-label>
                    <input matInput [(ngModel)]="selectedRelation.generalphone" i18n-placeholder placeholder="Phone"
                      maxlength="50">
                    <mat-icon matSuffix i18n-matTooltip matTooltip="Place call"
                      (click)="openUrlPhone(selectedRelation.generalphone)">
                      phone</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>VAT</mat-label>
                    <input matInput [(ngModel)]="selectedRelation.vatnumber" i18n-placeholder placeholder="VAT number"
                      maxlength="20">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" *ngIf="industries" fxLayoutAlign="space-between">
                  <mat-form-field appearance="outline" fxFlex>
                    <mat-label i18n>Industry</mat-label>
                    <mat-select [formControl]="industryselect" multiple (selectionChange)="setIndustries($event)"
                      [compareWith]="compareFnIndustries">
                      <mat-select-trigger>
                        {{selectedindustries ? selectedindustries[0]?.industry : ''}}
                        <span *ngIf="selectedindustries?.length > 1">
                          (+{{selectedindustries.length - 1}}
                          {{selectedindustries?.length === 2 ? 'other' : 'others'}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let industry of industries" [value]="industry">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                          <label>{{industry.industry}}</label> <label>{{industry.description}}</label>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- <button mat-raised-button (click)="getLinkedinAccount(selectedRelation.relationname)">Get Linkedin Acccount</button> -->

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">

                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Description</mat-label>
                    <textarea matInput matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                      [(ngModel)]="selectedRelation.description" maxlength="200"></textarea>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="49%">
                    <mat-label i18n>Relation owner</mat-label>
                    <mat-select [(ngModel)]="relationowner" i18n-placeholder placeholder="Type"
                      (selectionChange)="setOwner($event)">
                      <mat-option *ngFor="let member of Team" [value]="member">
                        {{ member.username }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <br>
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxFlex="100%">
                    <!-- this creates a google map on the page with the given lat/lng from -->
                    <!-- the component as the initial center of the map: -->
                    <agm-map [latitude]="lat" [longitude]="lng" *ngIf="selectedRelation.city" [styles]="mapStyle">
                      <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map>
                  </div>
                  <!-- <div fxFlex *ngIf="!selectedRelation.screenshotwebsite && selectedRelation.website">
                    <iframe class="websiteiframe" id="screenshotframe" frameborder="0" (onload)="createScreenshot()" 
                    [src]="selectedRelation.website | safe: 'resourceUrl'">
                    </iframe>
                  </div> -->
                  <!-- <div fxFlex *ngIf="selectedRelation.screenshotwebsite && selectedRelation.website">
                    <img src="selectedRelation.website">
                  </div> -->
                </div>
                <br>
                <div fxLayout="row" fxLayoutGap="20px">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Notes</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" maxlength="500"
                      [(ngModel)]="selectedRelation.notes"></textarea>
                  </mat-form-field>
                  <div fxLayout="column" fxFlex="48%">
                    <mat-checkbox i18n [checked]="selectedRelation.marketingrelation" (click)="setMarketingRelation()"
                      [disabled]="!Account.companyadmin">
                      Marketing Relation
                    </mat-checkbox>
                    <mat-checkbox i18n [(ngModel)]="selectedRelation.b2c">
                      Consumer/Private Person/B2C
                    </mat-checkbox>
                  </div>
                </div>
                <br>
                <mat-accordion *ngIf="Company">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Details
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                      <mat-form-field appearance="outline" fxFlex="48%">
                        <mat-label i18n>Status</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.status i18n-placeholder placeholder="Status">
                          <mat-option *ngFor="let statustype of Company.relationstatus" [value]="statustype">
                            {{ statustype }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex="48%">
                        <mat-label i18n>Type</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.type i18n-placeholder placeholder="Type">
                          <mat-option *ngFor="let type of Company.types" [value]="type">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <br>
                    <div fxLayout="row" fxLayout.xs="column" *ngIf="Company.const1name"
                      fxLayoutAlign="space-between start">
                      <mat-form-field appearance="outline" fxFlex="48%">
                        <mat-label>{{Company.const1name}}</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.const1 [placeholder]="Company.const1name">
                          <mat-option *ngFor="let const1 of Company.const1" [value]="const1">
                            {{ const1 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" *ngIf="Company.const2name" fxFlex="48%">
                        <mat-label>{{Company.const2name}}</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.const2 [placeholder]="Company.const2name">
                          <mat-option *ngFor="let const2 of Company.const2" [value]="const2">
                            {{ const2 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <br>
                    <div fxLayout="row" fxLayout.xs="column" *ngIf="Company.const3name"
                      fxLayoutAlign="space-between start">
                      <mat-form-field appearance="outline" fxFlex="48%">
                        <mat-label>{{Company.const3name}}</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.const3 [placeholder]="Company.const3name">
                          <mat-option *ngFor="let const3 of Company.const3" [value]="const3">
                            {{ const3 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline" *ngIf="Company.const4name" fxFlex="48%">
                        <mat-label>{{Company.const4name}}</mat-label>
                        <mat-select [(ngModel)]=selectedRelation.const4 [placeholder]="Company.const4name">
                          <mat-option *ngFor="let const4 of Company.const4" [value]="const4">
                            {{ const4 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                    <div fxLayoutGap="15px">
                      <label i18n>Last modified:</label>
                      <label>{{selectedRelation.updatedate | date:'medium'}}</label>
                      <label i18n>by:</label>
                      <label>{{ this.publisher }}</label>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <br>
                <div *ngIf="selectedRelation.marketingrelation" fxLayout="row" fxLayout.xs="column">
                  <div>
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title i18n>
                            Primairy Color
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <span [style.background]="selectedRelation.companyprimairycolor" [cpToggle]="true"
                          [cpDialogDisplay]="'inline'" [cpCancelButton]="true"
                          [(colorPicker)]="selectedRelation.companyprimairycolor"></span>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div>
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title i18n>
                            Secondary Color
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <span [style.background]="selectedRelation.companysecondarycolor" [cpToggle]="true"
                          [cpDialogDisplay]="'inline'" [cpCancelButton]="true"
                          [(colorPicker)]="selectedRelation.companysecondarycolor"></span>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div *ngIf="selectedRelation.marketingrelation" fxLayout="row" fxLayout.xs="column">
                  <div fxFlex>
                    <mat-form-field appearance="outline">
                      <mat-label i18n>Select font</mat-label>
                      <mat-select [(ngModel)]="selectedRelation.companyfont">
                        <mat-option *ngFor="let font of Fonts" [value]="font.value">
                          {{font.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <!-- Calls Tab_______________________________________________________ -->

    <mat-tab label="Calls">
      <ng-template mat-tab-label>
        <mat-icon>perm_phone_msg</mat-icon>
        <div i18n fxShow.xs="false">Calls/Messages</div>
      </ng-template>
      <div class="listshowcontainer" fxShow fxHide.gt-xs>
        <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
      </div>
      <div class="addtobutton">
        <button mat-fab color="primary" i18n-matTooltip matTooltip="Create new Call" (click)="openDialogNewCall()">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="send Message" (click)="createNewMessage()">
          <mat-icon>message</mat-icon>
        </button>
        <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="send Message from template"
          (click)="SendMessageFromTemplate()">
          <mat-icon>mail</mat-icon>
        </button>
        <div *ngIf="selectedCall">
          <button mat-mini-fab *ngIf="selectedCall.readytosend" color="primary" i18n-matTooltip
            matTooltip="send Message" (click)="sendNewMessage()">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
      <div class="tabcontainer" fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">

        <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
          [class.listview-xs-show]="listviewxsshow" class="card-left-menu">
          <h4 i18n>Search</h4>
          <mat-form-field appearance="outline">
            <input matInput #searchTerm id="seach-box" (keyup.enter)="searchCallGo(searchTerm.value)" i18n-placeholder
              placeholder="Call title/content" />
          </mat-form-field>
          <!-- <p>{{this.errorMessage}}</p> -->
          <button a mat-raised-button color="primary" (click)="searchCallGo(searchTerm.value)">
            <mat-icon>search</mat-icon>Search
          </button>
          <button a mat-raised-button (click)="getCalls()" i18n>Show All Calls</button>

          <div class="listview">
            <div *ngFor="let Call of Calls; let i = index" (click)="onSelectCall(Call, i)">
              <!-- <span class="box">{{Call.title | slice:0:20}} | {{Call.date | date}}</span> -->
              <span class="listbox" [class.selected]="Call === selectedCall">
                <div fxLayout="row">
                  <label fxFlex="60%">{{Call.title | slice:0:20}}</label>
                  <label fxFlex="30%">{{Call.date | date}}</label>
                  <mat-icon class="iconsize-10" fxFlex="10%" *ngIf="Call.calltype === 'E-mail'">email</mat-icon>
                  <mat-icon class="iconsize-10" fxFlex="10%" *ngIf="Call.calltype === 'PhoneCall'">call</mat-icon>
                  <mat-icon class="iconsize-10" fxFlex="10%" *ngIf="Call.calltype === 'Meeting'">groups</mat-icon>
                  <mat-icon class="iconsize-10" fxFlex="10%" *ngIf="Call.calltype === 'ConferenceCall'">
                    connect_without_contact</mat-icon>
                  <mat-icon svgIcon="enydea_linkedin" class="iconsize-10" fxFlex="10%"
                    *ngIf="Call.calltype === 'LinkedinMessage'"></mat-icon>
                </div>
              </span>
            </div>
          </div>
          <div fxLayout="column" style="width: 100%">
            <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
              <div fxFlexstyle="width: 50%">
                <mat-icon (click)="getCallsbackpage()">navigate_before</mat-icon>
              </div>
              <div fxFlexstyle="width: 50%">
                <mat-icon (click)="getCallsnextpage()">navigate_next</mat-icon>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <label>{{skipCalls}} / {{limitCalls}}</label>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
              <div fxFlex><label i18n>Total</label></div>
              <div fxFlex><label>{{totalCallcount}}</label></div>
            </div>
          </div>
        </div>

        <!-- [class.container-xs-show]="listviewxsshow === true || selectedCall === false" -->
        <div *ngIf="selectedCall && !selectedCall.readytosend" on-focusout="saveCall()" ngClass.gt-xs="container-normal"
          ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
          <mat-card>
            <mat-card-content fxLayout="column">
              <div fxFlex="row" fxLayout.xs="column">
                <div>
                  <mat-toolbar>
                    <h2>{{selectedCall.title | uppercase | slice:0:25 }}</h2>

                    <button mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menucall="matMenu">
                      <button mat-menu-item (click)="openDialogDeleteCall()">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                      <button mat-menu-item (click)="openDialogNewCall()">
                        <mat-icon>note_add</mat-icon>
                        <span>New</span>
                      </button>
                      <button mat-menu-item (click)="sendMailTo()">
                        <mat-icon>send</mat-icon>
                        <span>Reply</span>
                      </button>
                    </mat-menu>
                  </mat-toolbar>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column" style="width: 100%;">
                <mat-form-field appearance="outline" fxFlex="100%">
                  <mat-label i18n>Title</mat-label>
                  <input matInput [(ngModel)]="selectedCall.title" i18n-placeholder placeholder="Title">
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutGap="20px" *ngIf="selectedCall.calltype === 'E-mail'">
                <div fxLayoutGap="20px" fxLayout="row">
                  <label i18n>To: </label>
                  <div *ngFor="let to of selectedCall.mailto" fxLayoutGap="20px" fxLayout="row">{{to.email}}</div>
                </div>
                <div fxLayoutGap="20px" fxLayout="row" *ngIf="selectedCall.mailfrom">
                  <label i18n>From: </label>
                  <div fxLayoutGap="20px" fxLayout="row">{{selectedCall.mailfrom.email}}</div>
                </div>
                <div fxLayoutGap="20px" fxLayout="row" *ngIf="selectedCall.mailcc">
                  <label>CC: </label>
                  <div *ngFor="let cc of selectedCall.mailcc" fxLayoutGap="20px" fxLayout="row">{{cc.email}}</div>
                </div>
                <div fxLayoutGap="20px" fxLayout="row" *ngIf="selectedCall.mailbcc">
                  <label>BCC: </label>
                  <div *ngFor="let bcc of selectedCall.mailbcc" fxLayoutGap="20px" fxLayout="row">{{bcc.email}}</div>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" *ngIf="selectedCall.calltype !== 'E-mail'"
                fxLayoutAlign="space-between start">
                <mat-form-field appearance="outline" fxFlex="48">
                  <mat-label i18n>Attendee</mat-label>
                  <input type="text" #attendeeInput (keyup.enter)="addAttendent(attendeeInput.value)" i18n-placeholder
                    placeholder="Select Attendee" matInput [formControl]="myControlAttendee" [matAutocomplete]="autoAt"
                    name="attendents">
                  <mat-autocomplete #autoAt="matAutocomplete" [displayWith]="displayFn">
                    <!-- error changing view from one call to another -->
                    <div *ngIf="filteredOptions">
                      <mat-option *ngFor="let atten of filteredOptions | async" (click)="addAttendent(atten)"
                        [value]="atten">
                        {{ atten.firstname }} {{ atten.lastname }}
                      </mat-option>
                    </div>
                  </mat-autocomplete>
                </mat-form-field>
                <div fxFlex="48" *ngIf="attendeelist.length > 0">
                  <div *ngFor="let attendee of attendeelist; let i = index">
                    {{attendee.attendent}}
                    <mat-icon color="primary" (click)="deleteAttendent(i)" class="iconsize-12">delete</mat-icon>
                  </div>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                <mat-form-field appearance="outline" fxFlex="48%">
                  <mat-label i18n>Call Type</mat-label>
                  <mat-select [(ngModel)]=selectedCall.calltype i18n-placeholder placeholder="calltype">
                    <mat-option *ngFor="let calltype of calltype" [value]="calltype.value">
                      {{ calltype.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="48%">
                  <mat-label i18n>Call Date</mat-label>
                  <mat-datepicker #picker1></mat-datepicker>
                  <input matInput [matDatepicker]="myDatepicker1" [(ngModel)]=selectedCall.date i18n-placeholder
                    placeholder="Call Date">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker1></mat-datepicker>
                </mat-form-field>
              </div>
              <br>
              <mat-accordion style="width: 100%">
                <mat-expansion-panel fxLayout="column">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label i18n>Tasks</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxFlex="50%">
                    <mat-form-field appearance="outline" style="width: 99%">
                      <input matInput [(ngModel)]=task i18n-placeholder placeholder="task" (keyup.enter)="addTask()">
                    </mat-form-field>
                    <ul *ngFor="let tasklist of tasklist; let i = index" fxLayout="row">
                      <label fxFlex="90%">{{tasklist.task}}</label>
                      <mat-icon fxFlex="10%" (click)="deleteTask(i)" class="iconsize-10">delete</mat-icon>
                    </ul>
                  </div>
                  <div fxFlex="50%">
                    <mat-form-field appearance="outline" style="width: 99%">
                      <input matInput [(ngModel)]="note" i18n-placeholder placeholder="note" (keyup.enter)="addNote()">
                    </mat-form-field>
                    <ul *ngFor="let notes of notes; let i = index" fxLayout="row">
                      <label fxFlex="90%">{{notes.note}}</label>
                      <mat-icon fxFlex="10%" (click)="deleteNote(i)" class="iconsize-10">delete</mat-icon>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <br>
              <mat-accordion style="width: 100%">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label i18n>Follow up</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-checkbox i18n [(ngModel)]=selectedCall.followup fxFlex="33">
                      Follow Up
                    </mat-checkbox>

                    <mat-form-field appearance="outline" fxFlex="33">
                      <mat-label>Choose a date</mat-label>
                      <input matInput [matDatepicker]="myDatepicker" [(ngModel)]=selectedCall.callbackdate
                        i18n-placeholder placeholder="Callback Date">
                      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #myDatepicker></mat-datepicker>
                    </mat-form-field>

                    <!-- <div fxFlex="33">
                      <mat-datepicker #picker></mat-datepicker>
                      <input [matDatepicker]="myDatepicker" [(ngModel)]=selectedCall.callbackdate
                        i18n-placeholder placeholder="Callback Date">
                      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #myDatepicker></mat-datepicker>
                    </div> -->
                    <mat-checkbox i18n [(ngModel)]=selectedCall.followupdone fxFlex="33">
                      Done
                    </mat-checkbox>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
              <br>
              <div fxLayout="column" fxLayout.xs="column">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <label i18n>Attachments</label>
                    </mat-expansion-panel-header>
                    <div *ngFor="let file of selectedAttachments">

                      <div fxLayoutGap="20px" fxLayout="row" fxLayoutGap="20px">
                        <label>{{file.filename}}</label>
                        <mat-icon (click)="downloadAttachment(file)">file_download</mat-icon>
                        <mat-icon (click)="dialogDeleteFileSec(file)" class="iconsize-8">delete</mat-icon>
                      </div>
                    </div>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title i18n>
                          Upload Attachment
                        </mat-panel-title>
                      </mat-expansion-panel-header>


                      <div class="left-upload-block">
                        <h3 i18n>Select files</h3>
                        <div i18n ng2FileDrop [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}"
                          (fileOver)="fileOverAnother($event)" [uploader]="uploaderSec" class="drop-zone">
                          Drop Zone
                        </div>
                        <button mat-button (click)="fileInput.click()" i18n>Choose file</button>
                        <span
                          style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                          <input type="file" #fileInput ng2FileSelect [uploader]="uploaderSec" />
                        </span>
                      </div>
                      <div class="righ-upload-block">
                        <h3 i18n>Upload queue</h3>
                        <p i18n>Queue length: {{ uploaderSec?.queue?.length }}</p>
                        <div fxLayout="column">
                          <thead>
                            <div fxLayout="row" fxLayout.xs="column">
                              <div fxLayout="row" fxLayout.xs="column" width="50%">Name</div>
                              <div i18n>Size</div>
                              <div i18n>Progress</div>
                              <div i18n>Status</div>
                              <div i18n>Actions</div>
                            </div>
                          </thead>
                          <tbody>
                            <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploaderSec.queue">
                              <div fxFlex>
                                <strong>{{ item?.file?.name }}</strong>
                              </div>
                              <div fxFlex nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB
                              </div>
                              <div fxFlex>
                                <div style="margin-bottom: 0;">
                                  <mat-progress-bar color="primary" mode="determinate" [value]="item.progress">
                                  </mat-progress-bar>
                                </div>
                              </div>

                              <div fxFlex>
                                <span *ngIf="item.isSuccess">
                                  <mat-icon>done</mat-icon>
                                </span>
                                <span *ngIf="item.isCancel">
                                  <mat-icon>cancel</mat-icon>
                                </span>
                                <span *ngIf="item.isError">
                                  <mat-icon>remove_circle</mat-icon>
                                </span>
                              </div>
                              <div fxFlexnowrap>
                                <button mat-button (click)="setuploadSec(item?.file?.name, selectedCall.id)"
                                  [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                  <mat-icon>file_upload</mat-icon>Upload
                                </button>
                                <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                  <mat-icon>cancel</mat-icon> Cancel
                                </button>
                                <button mat-button (click)="item.remove()">
                                  <mat-icon class="iconsize-10">remove_circle</mat-icon>
                                  <label i18n>Remove</label>
                                </button>
                              </div>
                            </div>
                          </tbody>
                        </div>

                        <div>
                          <div>
                            <label i18n> Queue progress:</label>
                            <div class="progress">
                              <div>
                                <mat-progress-bar color="primary" mode="determinate" [value]="uploaderSec.progress">
                                </mat-progress-bar>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </mat-expansion-panel>
                  </mat-expansion-panel>
                </mat-accordion>
                <!-- <label>Attachments</label>
                <div *ngFor="let file of attachmentFiles">
                  <label (click)="download(file)">{{file.name}}</label>
                </div> -->
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <button a mat-raised-button *ngIf="selectedCall.readytosend" (click)="SendMessageDialog()">Edit</button>
              </div>

              <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field *ngIf="!selectedCall.html" fxFlex="100%">
                  <textarea matInput #content [(ngModel)]=selectedCall.content style="min-height: 300px;"
                    i18n-placeholder placeholder="Content" i18n>Call Report</textarea>
                  <mat-hint align="end">{{content.value.length}}</mat-hint>
                </mat-form-field>
              </div>

              <div fxLayout="row" *ngIf="selectedCall.html">
                <div *ngIf="!selectedHTML" style="max-width: 600px" [innerHTML]="selectedCall.html  | safeHtml"></div>
                <div *ngIf="selectedHTML" style="max-width: 600px" [innerHTML]="selectedHTML  | safeHtml"></div>
              </div>
              <br>
              <div *ngIf="!selectedCall.html">
                <a mat-mini-fab i18n-matTooltip matTooltip="Speech to Text recording" color="primary"
                  (click)="activateSpeech()" [class.speechbutton]="recordactive">
                  <mat-icon>record_voice_over</mat-icon>
                </a>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <button a mat-raised-button (click)="saveCall()" i18n>Save</button>
                <button a mat-raised-button (click)="replyNewMessage()" i18n>Reply</button>
              </div>
            </mat-card-content>
          </mat-card>
          <br>
          <mat-accordion *ngIf="Link && selectedCall.calltype === 'E-mail'">
            <mat-expansion-panel>
              <mat-expansion-panel-header i18n>
                Mail Tracker
              </mat-expansion-panel-header>
              <div *ngFor="let link of Link; let i = index;">
                <div *ngIf="link.linktracker.length > 0">
                  <label>{{link.link}}</label>
                  <mat-divider>
                  </mat-divider>
                  <div style="font-size: 8pt; color: grey;" *ngFor="let tracker of link.linktracker; let y = index;"
                    fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                    <label fxFlex="25%">{{tracker.city}}</label>
                    <label fxFlex="25%">{{tracker.companyname}}</label>
                    <label fxFlex="25%">{{tracker.country}}</label>
                    <label fxFlex="25%">{{tracker.date | date: "medium"}}</label>
                  </div>
                  <mat-divider>
                  </mat-divider>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="showprogressbarbusy" class="showprogressbardiv">
          <div style="position: absolute; left: 50%; top: 50%;">
            <mat-spinner mode="indeterminate"></mat-spinner>
            <br>
            <h1 i18n>Sending... </h1>
          </div>
        </div>

        <div class="relationcontainer" *ngIf="selectedCall && selectedCall.readytosend" on-focusout="saveCall()"
          ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
          <mat-card>
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column">

                <mat-toolbar>
                  <h2>{{selectedCall.title | uppercase | slice:0:30 }}</h2>

                  <button mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menucall="matMenu">
                    <button mat-menu-item (click)="openDialogDeleteCall()">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                    <button mat-menu-item (click)="openDialogNewCall()">
                      <mat-icon>note_add</mat-icon>
                      <span>New</span>
                    </button>
                    <button mat-menu-item (click)="printQuote()">
                      <mat-icon>print</mat-icon>
                      <span>Reply</span>
                    </button>
                  </mat-menu>
                </mat-toolbar>

              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <input matInput [(ngModel)]="selectedCall.title " i18n-placeholder placeholder="Subject"
                      fxFlex="100%">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <label i18n-matTooltip matTooltip="Auto selected Email depending on set options" i18n>From:
                  {{sendfromaccount}}</label>
                <mat-icon *ngIf="emailaccounttype ==='office365'" i18n-matTooltip
                  matTooltip="send form current Office 365 account" class="iconsize-10"
                  svgIcon="enydea_office365"></mat-icon>
                <mat-icon *ngIf="emailaccounttype ==='popimap'" i18n-matTooltip
                  matTooltip="send from IMAP or mail account" class="iconsize-10">email</mat-icon>
                <mat-icon *ngIf="emailaccounttype ==='mailing'" i18n-matTooltip matTooltip="send as Mailing email"
                  class="iconsize-10">
                  dns</mat-icon>
                <mat-icon *ngIf="emailaccounttype ==='none'" i18n-matTooltip
                  matTooltip="No email set add Office 365 or Mailing domain under settings">error</mat-icon>
              </div>
              <br>
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header i18n>
                    Attachments
                  </mat-expansion-panel-header>
                  <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                    [uploader]="attachmentUploader" class="dropzone" fxLayoutAlign="center center">
                    <label i18n>Drop Files here</label>
                  </div>
                  <button mat-button (click)="fileInput.click()">Choose file</button>
                  <span
                    style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                    <input type="file" #fileInput ng2FileSelect [uploader]="attachmentUploader" />
                  </span>
                  <br>
                  <div fxLayout="column" *ngFor="let item of attachmentUploader.queue">
                    <div fxLayout="row" fxLayoutGap="10px">
                      <label fxFlex="48%">{{ item?.file?.name }}</label>
                      <label fxFlex="40%">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</label>
                      <!-- <mat-progress-bar fxFlex="20%" mode="determinate" [value]="item.progress"></mat-progress-bar>
                      <mat-icon fxFlex="10%" *ngIf="item.isSuccess">done</mat-icon>
                      <mat-icon fxFlex="10%" *ngIf="item.isCancel">cancel</mat-icon>
                      <mat-icon fxFlex="10%" *ngIf="item.isError">error</mat-icon> -->
                    </div>
                  </div>
                  <br>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <!-- <button mat-raised-button (click)="upload()"
                      [disabled]="!attachmentUploader.getNotUploadedItems().length">Upload All</button> -->
                    <button mat-raised-button (click)="attachmentUploader.cancelAll()"
                      [disabled]="!attachmentUploader.queue.length" i18n>Cancel</button>
                    <button mat-raised-button (click)="attachmentUploader.clearQueue()"
                      [disabled]="!attachmentUploader.queue.length" i18n>Clear</button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>To</mat-label>
                    <mat-select [(ngModel)]="emailtosendto" (selectionChange)="setNameValues()">
                      <mat-option *ngFor="let contactperson of Contactpersons" [value]="contactperson">
                        {{contactperson.email}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                <mat-form-field appearance="outline" fxFlex>
                  <mat-label i18n>To</mat-label>
                  <mat-select [formControl]="mailto" multiple [(ngModel)]="emailtosendto"
                    (selectionChange)="setNameValues()" [compareWith]="compareFnContacts">
                    <mat-select-trigger>
                      {{emailtosendto ? emailtosendto[0]?.email : ''}}
                      <span *ngIf="emailtosendto?.length > 1">
                        (+{{emailtosendto.length - 1}} {{emailtosendto?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let contactperson of Contactpersons" [value]="contactperson">
                      {{contactperson.email}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <div fxFlex>{{emailtosendto.firstname}} {{emailtosendto.lastname}</div> -->
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                <mat-form-field appearance="outline" fxFlex="48">
                  <mat-label>CC</mat-label>
                  <mat-select [formControl]="mailcc" multiple [(ngModel)]="emailtosendcc"
                    [compareWith]="compareFnContacts">
                    <mat-select-trigger>
                      {{emailtosendcc ? emailtosendcc[0].email : ''}}
                      <span *ngIf="emailtosendcc?.length > 1">
                        (+{{emailtosendcc.length - 1}} {{emailtosendcc?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let contactperson of Contactpersons" [value]="contactperson">
                      {{contactperson.email}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="48">
                  <mat-label>BCC</mat-label>
                  <mat-select [formControl]="mailbcc" multiple [(ngModel)]="emailtosendbcc"
                    [compareWith]="compareFnContacts">
                    <mat-select-trigger>
                      {{emailtosendbcc ? emailtosendbcc[0].email : ''}}
                      <span *ngIf="emailtosendbcc?.length > 1">
                        (+{{emailtosendbcc.length - 1}} {{emailtosendbcc?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let contactperson of Contactpersons" [value]="contactperson">
                      {{contactperson.email}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <!-- <div fxFlex>
                  <a mat-mini-fab i18n-matTooltip matTooltip="Speech to Text recording" color="primary" (click)="activateSpeech()"
                    [class.speechbutton]="recordactive">
                    <mat-icon>record_voice_over</mat-icon>
                  </a>
                </div> -->
              </div>

              <div fxLayout="row" fxLayout.xs="column">
                <mat-checkbox [(ngModel)]=selectedCall.followup fxFlex="48%" i18n>
                  Follow Up
                </mat-checkbox>
                <mat-form-field appearance="outline" fxFlex="48%">
                  <mat-datepicker #picker></mat-datepicker>
                  <input matInput [matDatepicker]="myDatepicker" [(ngModel)]=selectedCall.callbackdate i18n-placeholder
                    placeholder="Callback Date">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
              <br>
              <mat-divider [vertical]="true"></mat-divider>
              <br>
              <div fxLayout="row" fxLayout.xs="column" *ngIf="!selectedCall.html">
                <div fxFlex>
                  <div style="border-style: solid; border-width: 1px; border-color: lightgray; padding: 5px;">
                    <mat-form-field appearance="outline">
                      <textarea matInput #content [(ngModel)]="selectedCall.content" i18n-placeholder
                        placeholder="content" i18n>Call Report</textarea>
                      <mat-hint align="end">{{content.value.length}}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedCall.html && !selectedHTML" [innerHTML]="selectedCall.html  | safeHtml"></div>
              <div *ngIf="selectedHTML" [innerHTML]="selectedCall.html  | safeHtml"></div>
              <div fxLayout="row" fxLayout.xs="column">
                <button a mat-raised-button (click)="SendMessageDialog()" i18n>Edit</button>
                <button a mat-raised-button (click)="sendNewMessage()" i18n>Send</button>
                <button a mat-raised-button (click)="saveCall()" i18n>Save</button>
              </div>

            </div>
            <div>

            </div>
          </mat-card>
        </div>
      </div>

    </mat-tab>


    <!-- Contactperson ___________________________________ -->

    <mat-tab label="Contact Person" *ngIf="!selectedRelation?.b2c">
      <ng-template mat-tab-label>
        <mat-icon>contacts</mat-icon>
        <div fxShow.xs="false" i18n>Contact Person</div>
      </ng-template>
      <div class="listshowcontainer" fxShow fxHide.gt-xs>
        <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
      </div>
      <div class="addtobutton">
        <button mat-fab color="primary" i18n-matTooltip matTooltip="Create contactperson"
          (click)="openDialogNewContactperson()">
          <mat-icon>add</mat-icon>
        </button>
        <!-- <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="New Template" (click)="CreateNewMail()"><mat-icon>add</mat-icon></button> -->
      </div>
      <!-- <button mat-raised-button (click)="deleteAllRelations()">Delete all relations</button> -->
      <div class="tabcontainer" fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
        <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
          [class.listview-xs-show]="listviewxsshow" class="card-left-menu">
          <!-- <mat-form-field appearance="outline">
            <input matInput [(ngModel)]=searchterm (keyup.enter)="searchGo()" i18n-placeholder placeholder="Name/Email" />
          </mat-form-field>
          <br>
          <button a mat-raised-button (click)="getAllContactpersons()">All Contactpersons</button>
          <br> -->
          <h3 i18n>Contactpersons</h3>
          <div *ngFor="let Contactperson of Contactpersons; let i = index"
            (click)="onSelectContactperson(Contactperson, i)"
            [class.selectedcontact]="Contactperson === selectedContactperson">
            <!-- <span *ngIf="!Contactperson.level" class="box">{{Contactperson.firstname}} {{Contactperson.lastname}}
              <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span> -->
            <span class="listbox" *ngIf="Contactperson.level === 'very good'"
              style="background-color: rgba(144, 238, 144, 0.541);">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="Contactperson.level === 'good'"
              style="background-color: rgba(0, 255, 255, 0.527)">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="Contactperson.level === 'indifferent'"
              style="background-color: rgba(173, 216, 230, 0.568);">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="Contactperson.level === 'bad'"
              style="background-color: rgba(255, 68, 0, 0.548);">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="!Contactperson.level">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="Contactperson.level === 'unknown'">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
            <span class="listbox" *ngIf="Contactperson.level === 'no longer available'"
              style="background-color: rgba(150, 150, 150, 0.548); text-decoration: line-through">{{Contactperson.firstname}}
              {{Contactperson.lastname}} <mat-icon *ngIf="Contactperson.default" class="iconsize-12">check</mat-icon>
            </span>
          </div>
          <br>

          <button mat-raised-button (click)="getSuggestedContacts()" i18n>Search Contacts Online</button>
          <mat-form-field appearance="outline">
            <input matInput i18n-placeholder placeholder="Title/Function" [(ngModel)]="preferedtitle">
          </mat-form-field>

          <div *ngIf="searchingonline">
            <mat-progress-bar mode="indeterminate">
            </mat-progress-bar>
          </div>

          <br>
          <div *ngFor="let suggestion of suggestedcontacts let i = index" fxLayout="row" style="margin: 3px;">
            <span class="boxnewcontacts" (click)="openSocialMedia(suggestion.url)">
              <label *ngIf="suggestion.title" i18n-matTooltip matTooltip="visit page">{{suggestion.title |
                slice:0:100}}</label>
              <label *ngIf="!suggestion.title" i18n-matTooltip matTooltip="visit page">{{suggestion.snippet |
                slice:0:100}}</label>
              <!-- <mat-icon class="iconsize-10" svgIcon="enydea_linkedin" *ngIf="suggestion.type === 'linkedin'"></mat-icon> -->
            </span>
            <mat-icon class="iconsize-12" (click)="createNewFromSuggested(suggestion)" i18n>save</mat-icon>
          </div>

        </div>
        <div *ngIf="selectedContactperson" on-focusout="saveContactperson()" ngClass.gt-xs="container-normal"
          ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
          <mat-card class="standarddivclass">
            <mat-card-content>

              <div fxLayout="column" class="form-full-width" cellspacing="0">
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxFlex>
                    <mat-toolbar class="titlebox">
                      <div>
                        <h2>{{selectedContactperson.firstname | uppercase}} {{selectedContactperson.lastname |
                          uppercase}}</h2>
                      </div>

                      <button mat-icon-button [matMenuTriggerFor]=menucontactperson class="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menucontactperson="matMenu">
                        <button mat-menu-item (click)="openDialogDeleteContactperson()">
                          <mat-icon>delete</mat-icon>
                          <span i18n>Delete</span>
                        </button>
                        <button mat-menu-item (click)="openDialogNewContactperson()">
                          <mat-icon>note_add</mat-icon>
                          <span i18n>New</span>
                        </button>
                      </mat-menu>
                    </mat-toolbar>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                  <mat-form-field appearance="outline" fxFlex="39%">
                    <mat-label i18n>Firstname</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.firstname i18n-placeholder placeholder="Firstname"
                      #contactpersonfirstname>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="39%">
                    <mat-label i18n>Surname</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.lastname i18n-placeholder placeholder="Lastname">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="19%">
                    <mat-label i18n>Salutation</mat-label>
                    <mat-select [(ngModel)]="selectedContactperson.salutation">
                      <mat-label i18n>Salutation</mat-label>
                      <mat-option value="Mr.">Mr.</mat-option>
                      <mat-option value="Mrs.">Mrs.</mat-option>
                      <mat-option value="Dr.">Dr.</mat-option>
                      <mat-option value="Ms.">Ms.</mat-option>
                      <mat-option value="Mr/Mrs">Mr/Mrs</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Relation status</mat-label>
                    <mat-select [(ngModel)]="selectedContactperson.level">
                      <mat-option value="very good" style="background-color: rgba(144, 238, 144, 0.541);" i18n>Very good
                      </mat-option>
                      <mat-option value="good" style="background-color: rgba(0, 255, 255, 0.527)" i18n>Good</mat-option>
                      <mat-option value="indifferent" style="background-color: rgba(173, 216, 230, 0.568);" i18n>
                        Indifferent</mat-option>
                      <mat-option value="bad" style="background-color: rgba(255, 68, 0, 0.548);" i18n>Bad</mat-option>
                      <mat-option value="unknown" i18n>unknown</mat-option>
                      <mat-option value="no longer available" style="background-color: rgba(150, 150, 150, 0.548);"
                        i18n>
                        No longer available</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-checkbox fxFlex="48%" [(ngModel)]="selectedContactperson.default" i18n>Default contact
                  </mat-checkbox>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Title</mat-label>
                    <input matInput [(ngModel)]="selectedContactperson.title" i18n-placeholder placeholder="Title"
                      type="text" name="title">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Manager</mat-label>
                    <input matInput [(ngModel)]="selectedContactperson.directmanager" i18n-placeholder
                      placeholder="Direct Manager">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Function</mat-label>
                    <input matInput [(ngModel)]="selectedContactperson.function" i18n-placeholder
                      placeholder="Function">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Department</mat-label>
                    <input matInput [(ngModel)]="selectedContactperson.department" i18n-placeholder
                      placeholder="Department" type="text" name="department">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                  <form [formGroup]="contactperson" fxFlex="48%">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label i18n>Email</mat-label>
                      <input matInput [(ngModel)]="selectedContactperson.email" i18n-placeholder placeholder="E-mail"
                        type="email" name="email" formControlName="contactpersonEmail">
                      <mat-hint *ngIf="contactpersonEmail.errors?.email" align="end" aria-live="polite" i18n>
                        Email address contains errors
                      </mat-hint>
                    </mat-form-field>
                  </form>
                  <div fxFlex *ngIf="selectedContactperson.email" fxFlex="48%">
                    <button mat-mini-fab i18n-matTooltip matTooltip="Send from Mailing program" color="primary"
                      (click)="createNewMessage(selectedContactperson)">
                      <mat-icon>send</mat-icon>
                    </button>
                    <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="send Message"
                      (click)="createNewMessage(selectedContactperson)">
                      <mat-icon>message</mat-icon>
                    </button>
                    <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="send Message from template"
                      (click)="SendMessageFromTemplate(selectedContactperson)">
                      <mat-icon>mail</mat-icon>
                    </button>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Office phone</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.officephone i18n-placeholder
                      placeholder="Office Phone" type="tel" name="officephone">
                    <mat-icon matSuffix (click)="openUrlPhone(selectedContactperson.officephone)">phone</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Cellphone</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.cellphone i18n-placeholder
                      placeholder="Cell phone" type="tel" name="cellphone">
                    <mat-icon matSuffix (click)="openUrlPhone(selectedContactperson.cellphone)">phone</mat-icon>
                  </mat-form-field>
                </div>
                <!-- <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field appearance="outline">
                      <input matInput [(ngModel)]=selectedContactperson.socialmediaaccount i18n-placeholder placeholder="Social Media"
                        >
                    </mat-form-field>
                  </div> -->
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Instand messenger</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.im i18n-placeholder placeholder="IM">
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="48%">
                    <mat-label i18n>Type</mat-label>
                    <input matInput [(ngModel)]=selectedContactperson.imtype i18n-placeholder placeholder="Im Type">
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <br>
          <mat-card>
            <mat-card-content>
              <div fxLayout="column" fxLayout.xs="column">

                <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"> -->
                <!-- <mat-icon (click)="openSocialMedia(selectedContactperson.linkedin)"
                    *ngIf="selectedContactperson.linkedin" svgIcon="enydea_linkedin"></mat-icon> -->
                <mat-form-field appearance="outline" fxFlex>
                  <mat-icon matPrefix i18n-matTooltip matTooltip="visit page"
                    (click)="openSocialMedia(selectedContactperson.linkedin)" *ngIf="selectedContactperson.linkedin"
                    svgIcon="enydea_linkedin"></mat-icon>
                  <input matInput [(ngModel)]="selectedContactperson.linkedin" i18n-placeholder placeholder="Linkedin">
                </mat-form-field>
                <!-- </div> -->


                <mat-form-field appearance="outline" fxFlex>
                  <mat-icon matPrefix i18n-matTooltip matTooltip="visit page"
                    (click)="openSocialMedia(selectedContactperson.twitter)" *ngIf="selectedContactperson.twitter"
                    svgIcon="enydea_twitter"></mat-icon>
                  <input matInput [(ngModel)]="selectedContactperson.twitter" i18n-placeholder placeholder="Twitter">
                </mat-form-field>


                <mat-form-field appearance="outline" fxFlex>
                  <mat-icon matPrefix i18n-matTooltip matTooltip="visit page"
                    (click)="openSocialMedia(selectedContactperson.facebook)" *ngIf="selectedContactperson.facebook"
                    svgIcon="enydea_facebook"></mat-icon>
                  <input matInput [(ngModel)]="selectedContactperson.facebook" i18n-placeholder placeholder="Facebook">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex>
                  <mat-icon matPrefix i18n-matTooltip matTooltip="visit page"
                    (click)="openSocialMedia(selectedContactperson.instagram)" *ngIf="selectedContactperson.instagram"
                    svgIcon="enydea_instagram"></mat-icon>
                  <input matInput [(ngModel)]="selectedContactperson.instagram" i18n-placeholder
                    placeholder="Instagram">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-icon matPrefix i18n-matTooltip matTooltip="visit page"
                    (click)="openSocialMedia(selectedContactperson.pinterest)" *ngIf="selectedContactperson.pinterest"
                    svgIcon="enydea_pinterest"></mat-icon>
                  <input matInput [(ngModel)]="selectedContactperson.pinterest" i18n-placeholder
                    placeholder="Pinterest">
                </mat-form-field>

                <br>
                <button mat-raised-button (click)="getSuggestedSocialMedia()" i18n>Search Social Media</button>
                <br>
                <div fxLayout="column" *ngIf="suggestedSocialMedia" style="margin: 20px;">
                  <div *ngFor="let suggestion of suggestedSocialMedia; let i = index" fxLayout="row" fxLayoutGap="10px"
                    style="margin: 20px 40px 20px 20px">
                    <mat-icon (click)="saveSocialMedia(suggestion)">save</mat-icon>
                    <mat-icon svgIcon="enydea_linkedin" *ngIf="suggestion.type === 'linkedin'"></mat-icon>
                    <mat-icon svgIcon="enydea_twitter" *ngIf="suggestion.type === 'twitter'"></mat-icon>
                    <mat-icon svgIcon="enydea_facebook" *ngIf="suggestion.type === 'facebook'"></mat-icon>
                    <mat-icon svgIcon="enydea_instagram" *ngIf="suggestion.type === 'instagram'"></mat-icon>
                    <mat-icon svgIcon="enydea_pinterest" *ngIf="suggestion.type === 'pinterest'"></mat-icon>

                    <label i18n-matTooltip matTooltip="visit Social Media page"
                      (click)="openSocialMedia(suggestion.url)" style="color: rgb(35, 35, 202);">{{suggestion.snippet |
                      slice:0:100}}</label>
                    <!-- <mat-icon >link</mat-icon> -->

                  </div>
                </div>

                <div fxLayout="row" fxLayout.xs="column">
                  <button a mat-raised-button (click)="saveContactperson()" i18n>Save</button>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>


      </div>
    </mat-tab>

    <mat-tab label="Opportunities">
      <ng-template mat-tab-label>
        <mat-icon matBadge={{opportunityCount}} matBadgeColor="warn">monetization_on</mat-icon>
        <div fxShow.xs="false" i18n>Opportunities</div>
      </ng-template>
      <div class="addtobutton">
        <button mat-fab color="primary" i18n-matTooltip matTooltip="Create new Opportunity"
          (click)="createNewOpportunity()">
          <mat-icon>add</mat-icon>
        </button>
        <!-- <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="New Template" (click)="CreateNewMail()"><mat-icon>add</mat-icon></button> -->
      </div>

      <div class="tabcontainer" fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
        <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
          [class.listview-xs-show]="listviewxsshow" class="card-left-menu">
          <h4 i18n>Search</h4>
          <mat-form-field appearance="outline">
            <mat-label>Name/Address</mat-label>
            <input matInput [(ngModel)]="searchOpportunity" (keyup.enter)="searchOpportunitiesGo()" i18n-placeholder
              placeholder="Name/Address" />
          </mat-form-field>
          <button a mat-raised-button color="primary" (click)="searchOpportunitiesGo()">
            <mat-icon>search</mat-icon>
            <label i18n>Search</label>
          </button>
          <button a mat-raised-button (click)="getOpportunities()" i18n>Show All</button>
          <br>
          <div class="listview">
            <div *ngFor="let Opportunity of Opportunities; let i = index" (click)="onSelectOpportunity(Opportunity)">
              <span [class.selected]="Opportunity === selectedOpportunity" class="listbox">
                {{Opportunity.title | slice:0:25}} |
                {{Opportunity.value | currency: Opportunity.currency:true:'3.2-2'}}</span>

            </div>
          </div>
        </div>
        <div *ngIf="selectedOpportunity" on-focusout="saveOpportunity()" ngClass.gt-xs="container-normal"
          ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
          <mat-card>
            <mat-card-content fxLayout="column">
              <div fxFlex="row" fxLayout.xs="column">
                <mat-toolbar class="titlebox">
                  <h2>{{selectedOpportunity.title | uppercase | slice:0:30 }}</h2>
                  <button mat-icon-button [matMenuTriggerFor]="menuOpportunity" class="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menuOpportunity="matMenu">
                    <button mat-menu-item (click)="openDialogDeleteOpportunity()">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>
                    <button mat-menu-item (click)="createNewOpportunity()">
                      <mat-icon>note_add</mat-icon>
                      <span>New</span>
                    </button>
                    <button mat-menu-item (click)="sendMailTo()">
                      <mat-icon>send</mat-icon>
                      <span>Reply</span>
                    </button>
                  </mat-menu>
                </mat-toolbar>

              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]=selectedOpportunity.title i18n-placeholder placeholder="Title">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                <mat-form-field fxFlex>
                  <input matInput [(ngModel)]=selectedOpportunity.value i18n-placeholder placeholder="value"
                    value="{{selectedOpportunity.value}}" type="number">
                </mat-form-field>
                <mat-form-field fxFlex>
                  <mat-select [(ngModel)]="selectedOpportunity.currency" i18n-placeholder placeholder="currency">
                    <mat-option value="EUR">Euro</mat-option>
                    <mat-option value="USD">US dollar</mat-option>
                    <mat-option value="CHF">Swiss France</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex>
                  <mat-select [(ngModel)]="selectedOpportunity.language" i18n-placeholder placeholder="language">
                    <mat-option value="en">English</mat-option>
                    <mat-option value="nl">Dutch</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <br>
              <div fxLayout="column">
                <div *ngFor="let item of selectedOpportunity.items; let i = index" fxLayoutGap="20px" fxLayout="row"
                  fxLayout.xs="column">
                  <mat-form-field fxFlex="48%">
                    <mat-label i18n>Description</mat-label>
                    <input matInput [(ngModel)]=item.description i18n-i18n-placeholder placeholder i18n-placeholder
                      placeholder="Description">
                  </mat-form-field>
                  <mat-form-field fxFlex="10%">
                    <mat-label i18n>Quantity</mat-label>
                    <input matInput (change)="onChangeItemOp(item)" i18n-i18n-placeholder placeholder
                      [(ngModel)]=item.quantity i18n-placeholder placeholder="Quantity" type="number">
                  </mat-form-field>
                  <mat-form-field fxFlex="10%">
                    <mat-label i18n>VAT</mat-label>
                    <input matInput (change)="onChangeItemOp(item)" [(ngModel)]=item.VAT i18n-i18n-placeholder
                      placeholder i18n-placeholder placeholder="VAT" type="number">
                  </mat-form-field>
                  <mat-form-field fxFlex="10%">
                    <mat-label i18n>Price</mat-label>
                    <input matInput (change)="onChangeItemOp(item)" [(ngModel)]=item.price i18n-i18n-placeholder
                      placeholder i18n-placeholder placeholder="Price" type="number">
                  </mat-form-field>
                  <mat-form-field fxFlex="10%">
                    <mat-label i18n>Total</mat-label>
                    <input matInput [(ngModel)]=item.itemtotal disabled i18n-i18n-placeholder placeholder
                      i18n-placeholder placeholder="Total" type="number">
                  </mat-form-field>
                  <mat-icon fxFlexx="10%" color="primary" (click)="deleteItemOp(i)" class="iconsize-12">delete
                  </mat-icon>
                </div>
              </div>
              <button mat-raised-button (click)="addItemOp()" i18n>Add item</button>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-select [(ngModel)]=selectedOpportunity.type i18n-i18n-placeholder placeholder i18n-placeholder
                    placeholder="Opportunity type">
                    <mat-option *ngFor="let Opportunitytype of Company.Opportunitytypes" [value]="Opportunitytype">
                      {{ Opportunitytype }}
                    </mat-option>
                  </mat-select>
                </div>
                <div fxFlex>
                  <mat-select [(ngModel)]=selectedOpportunity.status i18n-i18n-placeholder placeholder i18n-placeholder
                    placeholder="Opportunity status">
                    <mat-option *ngFor="let Opportunitystatus of Company.Opportunitystatus" [value]="Opportunitystatus">
                      {{ Opportunitystatus }}
                    </mat-option>
                  </mat-select>
                </div>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-datepicker #picker1></mat-datepicker>
                  <input matInput [matDatepicker]="myDatepicker1" [(ngModel)]=selectedOpportunity.date i18n-placeholder
                    placeholder="Opportunity Date" i18n-i18n-placeholder placeholder>
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker1></mat-datepicker>
                </mat-form-field>
              </div>
              <br>
              <mat-accordion style="width: 100%">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label i18n>Tasks</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="48">
                      <input matInput [(ngModel)]=task i18n-placeholder placeholder="task" i18n-i18n-placeholder
                        placeholder (keyup.enter)="addTaskOp()">
                    </mat-form-field>
                    <ul *ngFor="let tasklist of selectedOpportunity.tasks; let i = index" fxFlex="48">
                      <div fxFlex>{{tasklist.task}}</div>
                      <div fxFlex>
                        <mat-icon color="primary" (click)="deleteTaskOp(i)" class="iconsize-12">delete</mat-icon>
                      </div>
                    </ul>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="48">
                      <input matInput [(ngModel)]=note i18n-placeholder placeholder="note" i18n-i18n-placeholder
                        placeholder (keyup.enter)="addNoteOp()">
                    </mat-form-field>
                    <ul *ngFor="let notes of selectedOpportunity.notes; let i = index" fxFlex="48">
                      <div fxFlex>{{notes.note}}</div>
                      <div fxFlex>
                        <mat-icon color="primary" (click)="deleteNoteOp(i)" class="iconsize-12">delete</mat-icon>
                      </div>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <br>
              <mat-accordion style="width: 100%">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label i18n>Follow up</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                    <mat-checkbox fxFlex="33%" [(ngModel)]=selectedOpportunity.followup i18n>
                      Follow Up
                    </mat-checkbox>
                    <mat-form-field appearance="outline" fxFlex="33%">
                      <mat-datepicker #picker></mat-datepicker>
                      <input matInput [matDatepicker]="myDatepicker" [(ngModel)]=selectedOpportunity.callbackdate
                        i18n-placeholder placeholder="Opportunityback Date" i18n-i18n-placeholder placeholder>
                      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #myDatepicker></mat-datepicker>
                    </mat-form-field>
                    <mat-checkbox [(ngModel)]=selectedOpportunity.followupdone fxFlex="33%" i18n>
                      Done
                    </mat-checkbox>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
              <br>

              <div fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                  <label i18n>Decision makers: </label>
                  <div fxFlex *ngIf="selectedOpportunity.attendee">
                    <div *ngFor="let attendee of selectedOpportunity.attendee; let i = index;">
                      {{attendee.attendent}}
                      <mat-icon color="primary" (click)="deleteAttendentOp(i)" class="iconsize-12">delete</mat-icon>
                    </div>
                  </div>
                </div>

                <div fxFlex>
                  <mat-form-field appearance="outline">
                    <mat-label i18n>Decision Makers</mat-label>
                    <input type="text" #attendeeInputOp (keyup.enter)="addAttendentOp(attendeeInputOp.value)"
                      i18n-placeholder placeholder="Select decision makers" matInput [formControl]="myControlAttendeeOp"
                      [matAutocomplete]="autoDec" name="attendents">
                    <mat-autocomplete #autoDec="matAutocomplete" [displayWith]="displayFn">
                      <!-- error changing view from one Opportunity to another -->
                      <div *ngIf="filteredOptions">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                          (click)="addAttendentOp(attendeeInputOp.value)">
                          {{ option.firstname }} {{ option.lastname }}
                        </mat-option>
                      </div>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <div fxLayout="column" fxLayout.xs="column">
                <label i18n>Attachments</label>
                <div *ngFor="let file of attachmentFiles">
                  <div fxLayoutGap="20px" fxLayout="row">
                    <label>{{file.name}}</label>
                    <mat-icon (click)="download(file)">file_download</mat-icon>
                  </div>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column">
                <button a mat-raised-button (click)="saveOpportunity()" i18n>Save</button>
                <button a mat-raised-button (click)="printQuote()" i18n>Print</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

    </mat-tab>

    <mat-tab label="Files">
      <ng-template mat-tab-label>
        <mat-icon>insert_drive_file</mat-icon>
        <div fxShow.xs="false" i18n>Files</div>
      </ng-template>
      <div fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">

        <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
          [class.listview-xs-show]="listviewxsshow" class="card-left-menu" *ngIf="selectedRelation">
          <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="searchboxFiles" (keyup.enter)="getFiles()" i18n-placeholder
              placeholder="Name" />
          </mat-form-field>
          <button a mat-raised-button color="primary" (click)="getFiles()">
            <mat-icon>search</mat-icon>
            <label i18n>Search</label>
          </button>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'80px'">
                <mat-panel-title style="height: 80px;">
                  <mat-icon matBadge="{{seclist.length}}" matBadgeColor="primary">lock</mat-icon>
                  <label i18n>Secure Files</label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="filelist">
                <div *ngFor="let secFiles of seclist; let i = index" (click)="onDownload(secFiles)" class="files"
                  (click)="onselectfile(secFiles)" fxLayout="column">
                  <label style="font-size: 8pt">
                    {{ secFiles.name }}
                  </label>
                  <div>
                    <label>{{secFiles.size | formatFileSize}} </label>
                    <mat-icon (click)="dialogDeleteFileSec(secFiles)" color="primary" class="iconsize-12">delete
                    </mat-icon>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-accordion *ngIf="selectedRelation.marketingrelation">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'80px'">
                <mat-panel-title style="height: 80px;">
                  <mat-icon matBadge="{{imagelist.length}}" matBadgeColor="primary">photo</mat-icon>
                  <label i18n>Image files</label>
                  <mat-icon (click)="deleteSelectedImageList()" class="iconsize-10">delete</mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="filelist">
                <div *ngFor="let Filesimg of imagelist; let i = index" (click)="onDownload(Files)" class="files"
                  (click)="onselectfile(Filesimg)" fxLayout="column">
                  <label style="font-size: 8pt">
                    {{ Filesimg.name }}
                  </label>
                  <div>
                    <mat-checkbox [(ngModel)]="Filesimg.selected"></mat-checkbox>
                    <label>{{Filesimg.size | formatFileSize}} </label>
                    <mat-icon (click)="dialogDeleteFile(Filesimg)" color="primary" class="iconsize-12">delete</mat-icon>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="selectedRelation.marketingrelation">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'80px'">
                <mat-panel-title style="height: 80px;">
                  <mat-icon matBadge="{{videolist.length}}" matBadgeColor="primary">movie</mat-icon>
                  <label i18n>Video Files</label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="filelist">
                <div *ngFor="let Filesvid of videolist; let i = index" (click)="onDownload(Filesvid)" class="files"
                  (click)="onselectfile(Filesvid)" fxLayout="column">
                  <!-- href="{{ Filesvid.url }}"  -->
                  <label style="font-size: 8pt">
                    {{ Filesvid.name }}
                  </label>
                  <div>
                    <label style="font-size: 8pt">{{Filesvid.size | formatFileSize}}</label>
                    <mat-icon (click)="dialogDeleteFile(Filesvid)" color="primary" class="iconsize-12">delete</mat-icon>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="selectedRelation.marketingrelation">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'80px'">
                <mat-panel-title style="height: 80px;">
                  <mat-icon matBadge="{{misclist.length}}" matBadgeColor="primary">folder</mat-icon>
                  <label>Files</label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="filelist">
                <div *ngFor="let Files of misclist; let i = index" (click)="onDownload(Files)" class="files"
                  (click)="onselectfile(Files)" fxLayout="column">
                  <label style="font-size: 8pt">
                    {{ Files.name }}
                  </label>
                  <div>
                    <label>{{Files.size | formatFileSize}}</label>
                    <mat-icon (click)="dialogDeleteFile(Files)" color="primary" class="iconsize-12">delete</mat-icon>
                  </div>
                </div>
                <button mat-raised-button (click)="dialogDeleteAllFile(misclist)" color="primary">delete all</button>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="selectedRelation.marketingrelation && videotemplate">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'80px'">
                <mat-panel-title style="height: 80px;">
                  <mat-icon matBadge="{{videotemplate.length}}" matBadgeColor="primary">animation</mat-icon>
                  <label i18n>Scenes and Videos</label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="filelist">
                <div *ngFor="let Files of videotemplate; let i = index" (click)="onDownload(Files)" class="files"
                  (click)="onselectfile(Files)" fxLayout="column">
                  <label style="font-size: 8pt">
                    {{ Files.name }}
                  </label>
                  <div>
                    <label>{{Files.size | formatFileSize}}</label>
                    <mat-icon (click)="dialogDeleteFile(Files)" color="primary" class="iconsize-12">delete</mat-icon>
                  </div>
                </div>
                <button mat-raised-button (click)="dialogDeleteAllFile(videotemplate)" color="primary" i18n>
                  delete all</button>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>



        <div fxLayout="column" ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow"
          [class.container-xs-show]="listviewxsshow">
          <div fxFlex *ngIf="selectedfile">
            <mat-card>
              <mat-card-content *ngIf="selectedfile" fxLayout="column" fxLayoutGap="20px">
                <label i18n>File Details</label>
                <div fxLayout="row" fxLayoutGap="20px">
                  <label fxFlex="40%" i18n>Name: </label>
                  <label fxFlex="40%">{{selectedfile.name}}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <label fxFlex="40%" i18n>Creation Date: </label>
                  <label fxFlex="40%">{{selectedfile.createdate | date }}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <label fxFlex="40%" i18n>Type: </label>
                  <label fxFlex="40%">{{selectedfile.type}}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <label fxFlex="40%" i18n>Url: </label>
                  <label fxFlex="40%">{{selectedfile.url}}</label>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                  <label fxFlex="40%" i18n>Size: </label>
                  <label fxFlex="40%">{{selectedfile.size | formatFileSize}}</label>

                </div>
                <!-- selectedfile.type === 'marketing-video' ||  -->
                <div *ngIf="selectedfile.type === 'video' && !selectedfile.template" style="max-width: 600px;">
                  <!-- <video *ngIf="selectedfile.url" preload="auto" controls style="max-width: 600px;"
                    [src]="selectedfile.url"> -->
                  <app-videoplayer [url]="selectedfile.url" [options]="videooptions"></app-videoplayer>

                </div>

                <!-- || selectedfile.type !== 'vector'  -->
                <div *ngIf="selectedfile.type === 'image' && selectedfile.type !== 'secure'">
                  <img src={{selectedfile.url}} style="width: auto; height: auto; max-width: 500px;">
                </div>


                <div *ngIf="selectedfile.type === 'video-edit' && selectedfile.type !== 'secure'">
                  <app-videoplayer [url]="selectedfile.url" [options]="videooptions"></app-videoplayer>
                </div>

                <div *ngIf="selectedfile.template && selectedfile.type === 'video'">
                  <img src={{selectedfile.screenshot}} style="width: auto; height: auto; max-width: 500px;">
                </div>

                <!-- <div  style="width: 400px; height: 400px;" *ngIf="selectedfile.type === 'vector'" [inlineSVG]="selectedfile.url"></div> -->
              </mat-card-content>
              <button *ngIf="selectedfile.type !== 'secure'" mat-button (click)="download(selectedfile)">
                <mat-icon>file_download</mat-icon>
                <label i18n>Download</label>
              </button>

              <button *ngIf="selectedfile.type === 'secure'" mat-button (click)="downloadSec(selectedfile.name)">
                <mat-icon>file_download</mat-icon>
                <label i18n>Download</label>
              </button>


              <button *ngIf="selectedfile.type === 'secure'" mat-button (click)="dialogDeleteFileSec(selectedfile)">
                <mat-icon>delete</mat-icon>
                <label i18n>Delete</label>
              </button>

              <button *ngIf="selectedfile.type !== 'secure'" mat-button (click)="dialogDeleteFile(selectedfile)">
                <mat-icon>delete</mat-icon>
                <label i18n>Delete</label>
              </button>
            </mat-card>


          </div>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Upload Secure
                </mat-panel-title>
              </mat-expansion-panel-header>


              <div class="left-upload-block">
                <h3 i18n>Select files</h3>
                <div ng2FileDrop [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}"
                  (fileOver)="fileOverAnother($event)" [uploader]="uploaderSec" class="drop-zone">
                  Drop Zone
                </div>
                <button mat-button (click)="fileInput.click()" i18n>Choose file</button>
                <span
                  style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                  <input type="file" #fileInput ng2FileSelect [uploader]="uploaderSec" />
                </span>
              </div>
              <div class="righ-upload-block">
                <h3 i18n>Upload queue</h3>
                <p i18n>Queue length: {{ uploaderSec?.queue?.length }}</p>
                <div fxLayout="column">
                  <thead>
                    <div fxLayout="row" fxLayout.xs="column">
                      <div fxLayout="row" fxLayout.xs="column" width="50%">Name</div>
                      <div i18n>Size</div>
                      <div i18n>Progress</div>
                      <div i18n>Status</div>
                      <div i18n>Actions</div>
                    </div>
                  </thead>
                  <tbody>
                    <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploaderSec.queue">
                      <div fxFlex>
                        <strong>{{ item?.file?.name }}</strong>
                      </div>
                      <div fxFlex nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB
                      </div>
                      <div fxFlex>
                        <div style="margin-bottom: 0;">
                          <mat-progress-bar color="primary" mode="determinate" [value]="item.progress">
                          </mat-progress-bar>
                        </div>
                      </div>

                      <div fxFlex>
                        <span *ngIf="item.isSuccess">
                          <mat-icon>done</mat-icon>
                        </span>
                        <span *ngIf="item.isCancel">
                          <mat-icon>cancel</mat-icon>
                        </span>
                        <span *ngIf="item.isError">
                          <mat-icon>remove_circle</mat-icon>
                        </span>
                      </div>
                      <div fxFlexnowrap>
                        <button mat-button (click)="setuploadSec(item?.file?.name)"
                          [disabled]="item.isReady || item.isUploading || item.isSuccess">
                          <mat-icon>file_upload</mat-icon>
                          <label i18n>Upload</label>
                        </button>
                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                          <mat-icon>cancel</mat-icon>
                          <label i18n>Cancel</label>
                        </button>
                        <button mat-button (click)="item.remove()">
                          <mat-icon class="iconsize-10">remove_circle</mat-icon>
                          <label i18n>Remove</label>
                        </button>
                      </div>
                    </div>
                  </tbody>
                </div>

                <div>
                  <div>
                    <label i18n>Queue progress:</label>
                    <div class="progress">
                      <div>
                        <mat-progress-bar color="primary" mode="determinate" [value]="uploaderSec.progress">
                        </mat-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Upload new
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-select i18n-placeholder placeholder="Select File Type" [(ngModel)]="selectedfiletype" name="type">
                <mat-option *ngFor="let type of filetypes" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>

              <div class="left-upload-block">
                <h3 i18n>Select files</h3>


                <div ng2FileDrop [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}"
                  (fileOver)="fileOverAnother($event)" [uploader]="uploader" class="drop-zone" i18n>
                  Drop Zone
                </div>
                <button mat-button (click)="fileInput1.click()" i18n>Choose file</button>
                <span
                  style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                  <input type="file" #fileInput1 ng2FileSelect [uploader]="uploader" />
                </span>
              </div>

              <div class="righ-upload-block">

                <h3 i18n>Upload queue</h3>
                <p i18n>Queue length: {{ uploader?.queue?.length }}</p>



                <div fxLayout="column">
                  <thead>
                    <div fxLayout="row" fxLayout.xs="column">
                      <div fxLayout="row" fxLayout.xs="column" width="50%">Name</div>
                      <div i18n>Size</div>
                      <div i18n>Progress</div>
                      <div i18n>Status</div>
                      <div i18n>Actions</div>
                    </div>
                  </thead>
                  <tbody>
                    <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploader.queue">
                      <div fxFlex>
                        <strong>{{ item?.file?.name }}</strong>
                      </div>
                      <div fxFlex nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB
                      </div>
                      <div fxFlex>
                        <div style="margin-bottom: 0;">
                          <mat-progress-bar color="primary" mode="determinate" [value]="item.progress">
                          </mat-progress-bar>
                        </div>
                      </div>

                      <div fxFlex>
                        <span *ngIf="item.isSuccess">
                          <mat-icon>done</mat-icon>
                        </span>
                        <span *ngIf="item.isCancel">
                          <mat-icon>cancel</mat-icon>
                        </span>
                        <span *ngIf="item.isError">
                          <mat-icon>remove_circle</mat-icon>
                        </span>
                      </div>
                      <div fxFlexnowrap>
                        <button mat-button (click)="setupload(item?.file?.name)"
                          [disabled]="item.isReady || item.isUploading || item.isSuccess">
                          <mat-icon>file_upload</mat-icon>Upload
                        </button>
                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                          <mat-icon>cancel</mat-icon> Cancel
                        </button>
                        <button mat-button (click)="item.remove()">
                          <mat-icon class="iconsize-10">remove_circle</mat-icon> Remove
                        </button>
                      </div>
                    </div>
                  </tbody>
                </div>

                <div>
                  <div>
                    <label i18n>Queue progress:</label>

                    <div class="progress">
                      <div>
                        <mat-progress-bar color="primary" mode="determinate" [value]="uploader.progress">
                        </mat-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-tab>



    <mat-tab label="Follow ups/Automation">
      <ng-template mat-tab-label>
        <mat-icon>settings_applications</mat-icon>
        <div fxShow.xs="false" i18n>Automations</div>
      </ng-template>
      <app-crawler #crawler style="width: 100%;" [selectedRelation]="selectedRelation">
      </app-crawler>

    </mat-tab>




    <mat-tab label="Connected Accounts" *ngIf="selectedRelation && selectedRelation.marketingrelation">
      <ng-template mat-tab-label>
        <mat-icon>portrait</mat-icon>
        <div fxShow.xs="false" i18n>Connected Accounts</div>
      </ng-template>

      <app-connectedaccounts [selectedRelation]="selectedRelation" [Account]="Account"></app-connectedaccounts>

    </mat-tab>
    <!-- 
    <mat-tab label="Proposals">
      <ng-template mat-tab-label>
        <mat-icon>attach_money</mat-icon>
        <div fxShow.xs="false">Proposals</div>
      </ng-template>

    </mat-tab> -->
  </mat-tab-group>
</div>