<div fxLayout="column">
    <h1 i18n>General Settings</h1>
    <br>
    <mat-form-field appearance="outline"  style="width: 300px;">
        <input [(ngModel)]="name" type="text" matInput placeholder="Video Name" name="videoname">
    </mat-form-field>

    <!-- specialIsAlphaNumeric  -->

    <div fxLayout="row" fxLayoutGap="20px">
        <label i18n>Duration</label>
        <mat-slider i18n-matTooltip matTooltip="Timeline length" (input)="onSliderChange($event)" thumbLabel [displayWith]="formatLabel"
            #slider tickInterval="1" min="1" max="600" style="width: 300px;" [(ngModel)]="counter">
        </mat-slider>
        <!-- <label>{{counter}} &nbsp; sec</label> -->
        <mat-form-field appearance="outline"  style="width: 100px;">
            <input [(ngModel)]="counter" min="1" max="600" type="number" matInput name="counter">
            <mat-hint>sec</mat-hint>
        </mat-form-field>
    </div>

    <br>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="outline"  style="width: 100px;">
            <input [(ngModel)]="canvas.width" type="text" matInput name="canvaswidth" placeholder="width">
            <label matSuffix>px</label>
        </mat-form-field>


        <mat-form-field appearance="outline"  style="width: 100px;">
            <input [(ngModel)]="canvas.height" type="text" matInput name="canvasheight" placeholder="height">
            <label matSuffix>px</label>
        </mat-form-field>
    </div>

    <!-- [(ngModel)]="canvas.weather" -->
    <mat-form-field appearance="outline" >
        <mat-select [(ngModel)]="selectedvideoformat" placeholder="Select video size" 
        (selectionChange)=" changevideoformat()">
            <mat-option value="4096px x 2048px">4096 x 2048</mat-option>
            <mat-option value="1920px x 1080px">1920 x 1080</mat-option>
            <mat-option value="1280px x 720px">1280 x 720</mat-option>
            <mat-option value="1080px x 1080px">1080 x 1080</mat-option>
            <mat-option value="1024px x 576px">1024 x 576</mat-option>
            <mat-option value="768px x 432px">768 x 432</mat-option>
            <mat-option value="512px x 288px">512 x 288</mat-option>
            <mat-option value="256px x 144px">256 x 144</mat-option>

            <mat-option value="120px x 600px">120 x 600 sidebanner</mat-option>
            <mat-option value="160px x 600px">160 x 600 sidebanner</mat-option>
            <mat-option value="300px x 250px">300 x 250</mat-option>
            <mat-option value="728px x 90px">728 x 90 leaderboard</mat-option>
            <mat-option value="300px x 600px">300 x 600</mat-option>
            <mat-option value="970px x 250px">970 x 250</mat-option>

            <mat-option value="468px x 60px">468 x 60 fullbanner</mat-option>

            <mat-option value="1366px x 480px">1366 x 480 website banner</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <div fxLayout="row" fxLayoutGap="4px" style="margin-top: 30px;">
        <label i18n>Snap to grid</label>
        <mat-slide-toggle (change)="setGrid()" [(ngModel)]="snaptogrid"></mat-slide-toggle>
    </div>

    <div fxLayout="row" fxLayoutGap="4px" style="margin-top: 10px; max-width: 100px">
        <mat-form-field appearance="outline"  fxFlex="45%">
            <input (change)="setGrid()" [(ngModel)]="snaptogridwidth" matInput>
            <mat-hint i18n>Grid width</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline"  fxFlex="45%">
            <input (change)="setGrid()" [(ngModel)]="snaptogridheight" matInput>
            <mat-hint i18n>Grid height</mat-hint>
        </mat-form-field>
    </div>

    <br>
    <div fxLayout="row">
        <button mat-raised-button (click)="save()" i18n>Save</button>
        <button mat-raised-button (click)="close()" i18n>Cancel</button>
    </div>
</div>