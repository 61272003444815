/* tslint:disable */
import {
  Company,
  Account,
  Mailing
} from '../index';

declare var Object: any;
export interface LoggerInterface {
  "date": Date;
  "user"?: string;
  "company"?: string;
  "relation"?: string;
  "item"?: string;
  "code"?: string;
  "description"?: string;
  "read"?: boolean;
  "accountId"?: any;
  "url"?: string;
  "id"?: any;
  "companyrelationId"?: any;
  "mailingId"?: any;
  "mailinglistentriesId"?: any;
  "companyId"?: any;
  companyrelation?: Company;
  accountrelation?: Account;
  Mailing?: Mailing;
  mailinglistentries?: Mailing;
}

export class Logger implements LoggerInterface {
  "date": Date;
  "user": string;
  "company": string;
  "relation": string;
  "item": string;
  "code": string;
  "description": string;
  "read": boolean;
  "accountId": any;
  "url": string;
  "id": any;
  "companyrelationId": any;
  "mailingId": any;
  "mailinglistentriesId": any;
  "companyId": any;
  companyrelation: Company;
  accountrelation: Account;
  Mailing: Mailing;
  mailinglistentries: Mailing;
  constructor(data?: LoggerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Logger`.
   */
  public static getModelName() {
    return "Logger";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Logger for dynamic purposes.
  **/
  public static factory(data: LoggerInterface): Logger{
    return new Logger(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Logger',
      plural: 'Loggers',
      path: 'Loggers',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "user": {
          name: 'user',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'string'
        },
        "relation": {
          name: 'relation',
          type: 'string'
        },
        "item": {
          name: 'item',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "read": {
          name: 'read',
          type: 'boolean',
          default: false
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "companyrelationId": {
          name: 'companyrelationId',
          type: 'any'
        },
        "mailingId": {
          name: 'mailingId',
          type: 'any'
        },
        "mailinglistentriesId": {
          name: 'mailinglistentriesId',
          type: 'any'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
      },
      relations: {
        companyrelation: {
          name: 'companyrelation',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyrelationId',
          keyTo: 'id'
        },
        accountrelation: {
          name: 'accountrelation',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        Mailing: {
          name: 'Mailing',
          type: 'Mailing',
          model: 'Mailing',
          relationType: 'belongsTo',
                  keyFrom: 'mailingId',
          keyTo: 'id'
        },
        mailinglistentries: {
          name: 'mailinglistentries',
          type: 'Mailing',
          model: 'Mailing',
          relationType: 'belongsTo',
                  keyFrom: 'mailinglistentriesId',
          keyTo: 'id'
        },
      }
    }
  }
}
