<ckeditor *ngIf="!showckeditor2" #content [(ngModel)]="data" [config]="ckconfig" (fileUploadResponse)="onRequestCkEvent($event)">
</ckeditor>

<!-- *ngIf="data" -->

<div *ngIf="showckeditor2">
  <ckeditor (change)="onChangeText()" #content2 [(ngModel)]="dataset" [config]="ckconfig" (fileUploadResponse)="onRequestCkEvent($event)">
  </ckeditor>
</div>

<!-- [name]="editor1" -->

<div *ngIf="data" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>