import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PatheditorService {
  mousedown: void;
  mousemove: void;
  mouseup: void;
  fill = false;
  line = true;
  savedhistory = [''];
  currenthistory: number = 1;

  constructor() { }


  resetHistory() {
    this.savedhistory = [''];
    this.currenthistory = 1;
  }

  removeEventListeners() {
    // remove listeners by copy.. check for errors!!
    let svgElementold = document.getElementById("svgElement");
    var new_element = svgElementold.cloneNode(true);
    svgElementold.parentNode.replaceChild(new_element, svgElementold);
  }

  saveHistory() {
    console.log(this.currenthistory, this.savedhistory);
    if (this.currenthistory < this.savedhistory.length) {
      this.savedhistory.splice(this.currenthistory, this.savedhistory.length - 1)
    }

    let svgElement = document.getElementById("svgElement");
    this.savedhistory.push(JSON.stringify(svgElement.innerHTML));
    this.currenthistory = this.currenthistory + 1
  }

  reverseHistory() {
    if (this.currenthistory === 1) { return; }
    let svgElement = document.getElementById("svgElement");
    console.log(this.savedhistory, this.currenthistory)
    svgElement.innerHTML = JSON.parse(this.savedhistory[this.currenthistory - 1]);
    this.currenthistory = this.currenthistory - 1;
  }

  forwardHistory() {
    if (this.currenthistory > this.savedhistory.length - 1) { return; }
    let svgElement = document.getElementById("svgElement");
    console.log(this.savedhistory, this.currenthistory)
    svgElement.innerHTML = JSON.parse(this.savedhistory[this.currenthistory]);
    this.currenthistory = this.currenthistory + 1;
  }

  startNewWhiteboard(canvas, whiteboardsmoothing, shapedraw, whiteboardcolor, whiteboardstokewidth, whiteboardfillcolor): void {

    window.scrollTo(0, 0);

    setTimeout(() => {
      //  https://stackoverflow.com/questions/40324313/svg-smooth-freehand-drawing
      let bufferSize;
      let svgElement = document.getElementById("svgElement");
      let rect = svgElement.getBoundingClientRect();
      let path = null;
      let strPath;
      let buffer = []; // Contains the last positions of the mouse cursor
      let firstpoint;
      let firstpointcircle;
      let w = canvas.width.replace('px', '');
      let h = canvas.height.replace('px', '');
      let newview = '0 0 ' + w + ' ' + h;
      svgElement.setAttribute('viewBox', newview);
      this.saveHistory();

      this.mousedown = svgElement.addEventListener("mousedown", (e) => {

        //bs = document.getElementById("cmbBufferSize") as unknown;
        bufferSize = whiteboardsmoothing;
        path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        // lines properties
        if (this.line) {
          path.setAttribute("stroke", whiteboardcolor);
          path.setAttribute("stroke-width", whiteboardstokewidth);
        } else {
          path.setAttribute("stroke-width", 0);
        }

        // set fill properties
        // console.log(whiteboardfillecolor)
        if (this.fill) {
          path.setAttribute("fill", whiteboardfillcolor);
          //   path.setAttribute('fill-rule', 'evenodd');
        } else {
          path.setAttribute("fill", "none");
        }
        buffer = [];
        let pt = getMousePosition(e);
        // console.log(e)
        appendToBuffer(pt);


        firstpoint = { x: (pt.x), y: (pt.y) };

        switch (shapedraw) {
          case 'circle':
            strPath = 'M ' + rect.top + ' ' + rect.left +
              'm -' + 0 + ', 0' +
              'a' + 0 + ',' + 0 + ' 0 1,0' + 0 * 2 + ',0' +
              'a' + 0 + ',' + 0 + ' 0 1,0 -' + 0 * 2 + ',0'
            break;
          case 'rectangle':
            strPath = 'M ' + pt.x + ' ' + pt.y + ' H ' + pt.x + ' V ' + pt.y + ' H ' + pt.x + ' Z';
            //strPath = 'M ' + pt.x + ' ' + pt.y + ' H ' + pt.x + ' V ' + pt.y + ' H ' + 'Z';
            break;
          default:
            strPath = "M" + pt.x + " " + pt.y;
        }

        //strPath = "M" + pt.x + " " + pt.y;
        path.setAttribute("d", strPath);
        svgElement.appendChild(path);
      });

      this.mousemove = svgElement.addEventListener("mousemove", (e) => {
        if (path) {
          switch (shapedraw) {
            case 'circle':
              updateSvgPathCircle(e);
              break;
            case 'rectangle':
              updateSvgPathRect(e);
              break;
            default:
              appendToBuffer(getMousePosition(e));
              updateSvgPath();
          }
        }
      });

      this.mouseup = svgElement.addEventListener("mouseup", () => {
        this.saveHistory();
        if (path) {
          path = null;
        }
        if (firstpoint) {
          firstpoint = null;
        }
      });

      // add scroll event to track update rect for new scroll location
      document.getElementById('containernormal').addEventListener("scroll", (e) => {
        console.log('scroll');
        rect = svgElement.getBoundingClientRect();
      });

      let getMousePosition = function (e) {
        //var rect = e.target.getBoundingClientRect();
        return {
          x: e.pageX - rect.left,
          y: e.pageY - rect.top
        }
      };

      let appendToBuffer = (pt) => {
        buffer.push(pt);
        while (buffer.length > bufferSize) {
          buffer.shift();
        }
      };

      let updateSvgPathCircle = (e) => {
        let pl = svgElement.getElementsByTagName('path').length;
        let ptl = getMousePosition(e);
        ptl.x = ptl.x / 1.5;
        ptl.y = ptl.y / 1.5;
        strPath = 'M ' + firstpoint.x + ' ' + firstpoint.y +
          'm ' + ptl.x / (-2) + ', 0' +
          'a' + ptl.x / 2 + ',' + ptl.x / 2 + ' 0 1,0 ' + ptl.x + ',0' +
          'a' + ptl.x / 2 + ',' + ptl.x / 2 + ' 0 1,0 ' + ptl.x * (-1) + ',0'
        path.setAttribute("d", strPath);
        path.setAttribute("id", pl + 1);


      }

      let updateSvgPathRect = (e) => {
        let pl = svgElement.getElementsByTagName('path').length;
        let ptl = getMousePosition(e);
        strPath = 'M ' + firstpoint.x + ' ' + firstpoint.y + ' H ' + ptl.x + ' V ' + ptl.y + ' H ' + firstpoint.x + ' Z';
        path.setAttribute("d", strPath);
        path.setAttribute("id", pl + 1);


      }

      // Calculate the average point, starting at offset in the buffer
      let getAveragePoint = (offset) => {
        let len = buffer.length;
        if (len % 2 === 1 || len >= bufferSize) {
          let totalX = 0;
          let totalY = 0;
          let pt, i;
          let count = 0;
          for (i = offset; i < len; i++) {
            count++;
            pt = buffer[i];
            totalX += pt.x;
            totalY += pt.y;
          }
          return {
            x: totalX / count,
            y: totalY / count
          }
        }
        return null;
      };

      let updateSvgPath = () => {
        let pt = getAveragePoint(0);
        let pl = svgElement.getElementsByTagName('path').length;

        if (pt) {
          // Get the smoothed part of the path that will not change
          strPath += " L" + pt.x + " " + pt.y;

          // Get the last part of the path (close to the current mouse position)
          // This part will change if the mouse moves again
          let tmpPath = "";
          for (let offset = 2; offset < buffer.length; offset += 2) {
            pt = getAveragePoint(offset);
            tmpPath += " L" + pt.x + " " + pt.y;
          }

          // Set the complete current path coordinates
          path.setAttribute("d", strPath + tmpPath);
          path.setAttribute("id", pl + 1);
        }
      };
    }, 300) // mininmum needed for dom to process

  }

  mergePath() {
    // let newd = d + ' ' + d
  }

  substractPath() {

  }




}
