import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import { filter } from 'core-js/fn/array';
import { AuthService, User } from '../auth365.service';
import { Event } from './event';
import { Mail } from './event';
import { OfficeMail } from './mailclass';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { Guid } from 'guid-typescript';
import { MsalService } from '@azure/msal-angular';


export const OAuthSettings = {
  appId: 'fb72996b-0142-4316-b08c-cd6e4fbb9033',
  scopes: [
    "user.read",
    "calendars.ReadWrite",
    "mail.read",
    "mail.send",
    "mail.ReadWrite"
  ],
};

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  private graphClient: Client;
  authenticated: boolean;
  user: any;


  constructor(
    private msalService: MsalService,
    private authService: AuthService) {

    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          this.authenticated = true;
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {
    try {
      let result = await this.msalService.loginPopup().toPromise();
      console.log(result);
      // this.setLoginDisplay();
      this.authenticated = true;
      this.user = await this.getUser(result.accessToken);
      console.log(this.user);
      return
    } catch (error) {
      console.error(error)
    }


    // .subscribe(async result => {
    //   console.log(result);
    //   this.setLoginDisplay();
    //   this.authenticated = true;
    //   this.user = await this.getUser(result.accessToken);
    //   console.log(this.user)
    //   return
    // },
    //   (error) => {
    //     console.log(error)
    //     return
    //   });
    // let result = await this.msalService.loginPopup(OAuthSettings)
    //   .catch((reason) => {
    //     this.openSnackBar('Login failed' + JSON.stringify(reason, null, 2));
    //   });

    // if (result) {
    //   this.authenticated = true;
    //   // Temporary placeholder
    //   this.user = await this.getUser();
    // }
  }

  async getUser(tokennew?): Promise<User> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) { return null; }
      let graphUser = await this.getMe() as any;
      console.log(graphUser)
      // let graphUser = await graphClient.api('/me').get();
      //token = await this.getAccessToken();
      let user = new User();
      user.displayName = graphUser.displayName;
      // Prefer the mail property, but fall back to userPrincipalName
      user.email = graphUser.mail || graphUser.userPrincipalName;

      resolve(user);

      // let graphClient = Client.init({
      //   // Initialize the Graph client with an auth
      //   // provider that requests the token from the
      //   // auth service
      //   authProvider: async (done) => {
      //     let token: string;
      //     if (tokennew) {
      //       token = tokennew
      //     } else {
      //       token = await this.getAccessToken()
      //     }

      //     console.log(token)
      //     // .catch((reason) => {
      //     //   done(reason, null);
      //     // });

      //     if (token) {
      //       // Get the user from Graph (GET /me)
      //       let graphUser = await graphClient.api('/me').get();

      //       let user = new User();
      //       user.displayName = graphUser.displayName;
      //       // Prefer the mail property, but fall back to userPrincipalName
      //       user.email = graphUser.mail || graphUser.userPrincipalName;

      //       resolve(user);
      //       done(null, token);
      //     } else {
      //       done("Could not get an access token", null);
      //     }
      //   }
      // });

    });
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {

    try {
      let accounts = await this.msalService.instance.getAllAccounts();
      let result = await this.msalService.acquireTokenSilent({ scopes: OAuthSettings.scopes, account: accounts[0] }).toPromise();
      if (result) {
        return result.accessToken;
      }
      return null;
    } catch (error) {
      console.log(error);
      this.signIn();
    }

    // .catch((reason) => {
    //   console.error('Get token failed ' + JSON.stringify(reason, null, 2));
    //   this.signIn();
    // });
   // console.log(result)

  }

  async getMe(): Promise<User> {
    try {
      let result = await this.graphClient.api('/me').get();
      //console.log(result)
      return result;
    } catch (error) {
      console.log('Could not get events' + JSON.stringify(error, null, 2));
    }
  }

  // Sign out
  signOut(): void {
    this.msalService.logout();
    this.user = null;
    this.authenticated = false;
  }

  async getEvents(): Promise<Event[]> {
    try {
      let result = await this.graphClient
        .api('/me/events')
        .select('subject,organizer,start,end')
        .orderby('createdDateTime DESC')
        .get();

      return result.value;
    } catch (error) {
      console.log('Could not get events' + JSON.stringify(error, null, 2));
    }
  }

  async getMailsFiltered(id, expand?): Promise<any> {
    try {
      let result = await this.graphClient
        .api('/me/messages/' + id)
        //.filter(filter)
        .expand(expand)
        .get();
      return result
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  }

  async getMailFolders(): Promise<Array<Object>> {
    try {
      let result = await this.graphClient
        .api('me/mailFolders')
        .get();
      return result.value;
    } catch (error) {
      console.log(error)
    }
  }

  async getMails(skip, limit, value): Promise<OfficeMail[]> {
    try {
      let result = await this.graphClient
        .api('/me/messages')
        .expand('singleValueExtendedProperties')
        .top(20)
        .skip(skip)
        .count(true)
        .get();
      return result;
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  }

  async searchMails(skip, limit, value): Promise<OfficeMail[]> {
    try {
      let result = await this.graphClient
        .api('/me/messages')
        .expand('singleValueExtendedProperties')
        .search(value)
        .top(20)
        .count(true)
        .get();
      return result;
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  }

  async getMailCount(): Promise<number> {
    try {
      let result = await this.graphClient
        .api('/me/messages')
        .count(true)
        .get();
      return result['@odata'].count;
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  }

  async deleteMail(id) {
    try {
      this.graphClient.api('me/messages/' + id)
        .delete();
      return 'delete';
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  }



  async sendMail(subject, content, to, callId, cc?: Array<any>, bcc?: Array<any>, preview?): Promise<Object> {
    let guid = Guid.create();
    let sendMail = await this.prepareMail(subject, content, to, callId, guid, cc, bcc, preview);
    let mail = await this.graphClient
      .api('/me/messages')
      .post(sendMail);

    let result = await this.graphClient
      .api('/me/sendMail')
      .post({
        message: sendMail,
        saveToSentItems: "true"
      });

    let mailInfo = {
      id: mail.id,
      singlevalue: sendMail['singleValueExtendedProperties'][0].id
    }

    //console.log(mail)
    return mailInfo
  }

  async prepareMail(subject, content, to, callId, guid, cc?: Array<any>, bcc?: Array<any>, preview?): Promise<Object> {
    let toArray = [];
    let ccArray = [];
    let bccArray = [];

    to.forEach(element => {
      toArray.push({ emailAddress: { address: element } });
    });

    cc.forEach(element => {
      toArray.push({ emailAddress: { address: element } });
    });

    bcc.forEach(element => {
      toArray.push({ emailAddress: { address: element } });
    });


    const sendMail = {
      subject: subject,
      bodyPreview: preview,
      body: {
        contentType: "HTML",
        content: content
      },
      toRecipients: toArray,
      ccRecipients: ccArray,
      bccRecipients: bccArray,
      singleValueExtendedProperties: [
        {
          "id": "String {" + guid + "} Name callId",
          "value": callId
        }
      ]
    }
    console.log(sendMail)
    return sendMail
  }

  /* 
  1. create mail 
  2. create upload session for each file
  4. upload files for each file
  5. add attached files to mail 
  6. get updated mail
  7. send mail 
  */
  async sendMailWithAttachments(subject, content, attachments: FileUploader, to, callId, cc?: Array<any>, bcc?: Array<any>, preview?): Promise<Mail[]> {
    let guid = Guid.create();
    let sendMail = await this.prepareMail(subject, content, to, callId, guid, cc, bcc, preview);
    let client = await this.graphClient;
    let mail = await client.api('/me/messages').post(sendMail);

    for (let i = 0; i < attachments.queue.length; i++) {
      let attachment = attachments.queue[i];
      //console.log(attachment)
      if (attachment.file.size > 3000000) {
        await this.setAttachment(attachments, i, mail.id, attachment.file.size, attachment.file.name)
      } else {
        let reader = await this.readFile(attachment);
        const attach = {
          '@odata.type': "#microsoft.graph.fileAttachment",
          name: attachment.file.name,
          contentBytes: reader
        };
        await client.api(`/me/messages/${mail.id}/attachments`).post(attach);
      }
    }
    await this.graphClient.api(`/me/messages/${mail.id}/send`).post('');
    mail.id = guid;
    return mail;
  }

  async readFile(attachment) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        let objs = e.target.result as string;
        let objsar = objs.split(',');
        resolve(objsar[1])
      }
      fileReader.readAsDataURL(attachment._file);
    });
  }

  /* setup upload large attachment to Graph, ms expect PUT stream, bad doc from MS! (not multipart, POST) */
  async setAttachment(attachments: FileUploader, index, messageid, size, name) {
    return new Promise(async (resolve, reject) => {
      const uploadSession = {
        AttachmentItem: {
          attachmentType: "file",
          name: name,
          size: size // get file size in bytes
        }
      };
      let result = await this.graphClient.api(`/me/messages/${messageid}/attachments/createUploadSession`).post(uploadSession);
      let range = 'bytes 0-' + (size - 1) + '/' + size;
      attachments.queue[index].headers = [
        { name: 'Content-Range', value: range }
      ]

      attachments.queue[index].method = 'PUT';
      attachments.queue[index].url = result.uploadUrl;
      attachments.queue[index].withCredentials = false;
      attachments.queue[index].upload();
      attachments.queue[index].onComplete = (response: any, status: any, headers: any) => {
        console.log(status)
        if (status === 201 || status === 200) { resolve('done') } else {
          reject('failed')
        }
      };

    });
  }

  async getAttachment(messageid): Promise<Array<any>> {
    let AttachmentsList = [];
    let result = await this.graphClient.api(`/me/messages/${messageid}/attachments`).get();
    return result.value
  }

}