/* tslint:disable */
import {
  Chatrooms,
  Account
} from '../index';

declare var Object: any;
export interface ChatmessagesInterface {
  "message"?: string;
  "owner"?: string;
  "id"?: any;
  "chatroomsId"?: any;
  "accountId"?: any;
  chatrooms?: Chatrooms;
  account?: Account;
}

export class Chatmessages implements ChatmessagesInterface {
  "message": string;
  "owner": string;
  "id": any;
  "chatroomsId": any;
  "accountId": any;
  chatrooms: Chatrooms;
  account: Account;
  constructor(data?: ChatmessagesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chatmessages`.
   */
  public static getModelName() {
    return "Chatmessages";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Chatmessages for dynamic purposes.
  **/
  public static factory(data: ChatmessagesInterface): Chatmessages{
    return new Chatmessages(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Chatmessages',
      plural: 'Chatmessages',
      path: 'Chatmessages',
      idName: 'id',
      properties: {
        "message": {
          name: 'message',
          type: 'string'
        },
        "owner": {
          name: 'owner',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "chatroomsId": {
          name: 'chatroomsId',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
      },
      relations: {
        chatrooms: {
          name: 'chatrooms',
          type: 'Chatrooms',
          model: 'Chatrooms',
          relationType: 'belongsTo',
                  keyFrom: 'chatroomsId',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
