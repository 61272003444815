<ngx-guided-tour></ngx-guided-tour>

<div [class.theme-alternate]="isDarkTheme">

    <!-- <div *ngIf="!loginpage && router.url.indexOf('filemanager') === -1" fxLayoutAlign="center center">
        <div [class.menubuttonsmall]="isVisible === false " [class.menubuttonscroll]="isVisible === true">
            <mat-icon color="accent" (click)="sidenavToggle()" id="mainmenubutton" style="margin: 10px;"
                class="menubutton">menu</mat-icon>
        </div>
    </div> -->

    <div *ngIf="!loginpage && router.url.indexOf('filemanager') === -1" class="menubuttoncontainer"
        [class.menubuttonsmall]="isVisible === false" [class.menubuttonscroll]="isVisible === true">
        <mat-icon (click)="sidenavToggle()" id="mainmenubutton" class="menubutton" *ngIf="!sidenavopen"
            matTooltip="Open menu">
            menu</mat-icon>
    </div>

    <div class="messagebuttoncontainer" *ngIf="!loginpage">
        <mat-icon *ngIf="messagecount > 0" matBadge={{messagecount}} matBadgeColor="warn" class="messagebutton"
            (click)="openBottomSheet()" matTooltip="Messages">message</mat-icon>
        <mat-icon *ngIf="messagecount === 0" class="messagebutton" (click)="openBottomSheet()"
            matTooltip="Messages">message</mat-icon>
    </div>

    <div fxShow.xs="false" *ngIf="!loginpage && router.url.indexOf('filemanager') === -1" class="logobox">
        <div *ngIf="Company">
            <img *ngIf="!Company.companylogo" routerLink="/dashboard" src="assets/icon-512x512.png" class="logo">
            <img *ngIf="Company.companylogo" routerLink="/dashboard" src={{Company.companylogo}} class="logo">
        </div>
    </div>

    <div class="quickphrasebuttoncontainer" *ngIf="!loginpage && Account">
        <mat-icon matTooltip="Text and Translation Tool" class="messagebutton"
            (click)="openQuick_phrases()">quickreply</mat-icon>
    </div>

    <mat-sidenav-container>
        <mat-sidenav #sidenav [opened]="sidenavopen" mode="side" class="sidenav">
            <mat-icon class="closebutton" (click)="sidenavToggle()">close</mat-icon>
            
            <mat-nav-list class="sidenav-menu-links" style="padding-left: 20px;">
                <mat-list-item routerLink="/dashboard" routerLinkActive="active">
                    <mat-icon>insert_chart</mat-icon> &nbsp;
                    <label mat-list-item i18n> Dashboard</label>
                </mat-list-item>
                <mat-list-item routerLink="/relation" routerLinkActive="active">
                    <mat-icon>contact_phone</mat-icon> &nbsp;
                    <label mat-list-item i18n> RELATIONS</label>
                </mat-list-item>
                <mat-list-item routerLink="/imaging" routerLinkActive="active">
                    <mat-icon>movie</mat-icon> &nbsp;
                    <label mat-list-item i18n> VISUALIZATION</label>
                </mat-list-item>
                <mat-list-item routerLink="/marketing" routerLinkActive="active">
                    <mat-icon>share</mat-icon> &nbsp;
                    <label mat-list-item i18n> MARKETING</label>
                </mat-list-item>
                <mat-list-item routerLink="/marketingplanner" routerLinkActive="active">
                    <mat-icon>assignment</mat-icon> &nbsp;
                    <label mat-list-item i18n> MARKETING PLANNER</label>
                </mat-list-item>
                <!-- <mat-list-item routerLink="/translations" routerLinkActive="active">
                    <mat-icon>translate</mat-icon> &nbsp;
                    <label mat-list-item> TRANSLATIONS</label>
                </mat-list-item> -->
                <mat-list-item routerLink="/settings" routerLinkActive="active">
                    <mat-icon>settings</mat-icon> &nbsp;
                    <label mat-list-item i18n> SETTINGS</label>
                </mat-list-item>
            </mat-nav-list>
            <br>
            <button *ngIf="Pwa.promptEvent" mat-stroked-button color="accent" (click)="installPwa()">
                <mat-icon>install</mat-icon>
                <label i18n>Install</label>
            </button>

            <img *ngIf="!Account.avatarurl" src="assets/enydea-logo-allwhite-03.png" class="avatar">
            <img *ngIf="Account.avatarurl" src="{{Account.avatarurl}}" class="avatar">


            <div style="padding-left: 40px; margin-top: 40px; color: white;">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Language</mat-label>
                    <mat-select i18n-placeholder placeholder="Language" [(ngModel)]="selectedlanguage" name="Language"
                        (selectionChange)="selectOtherLanguage()">
                        <mat-option *ngFor="let language of languages" [value]="language.code">
                            {{language.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxLayout="column" *ngIf="Account" style="font-size: small;">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-icon class="iconsize-15">person</mat-icon>
                        <label>{{ Account.username }}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-icon class="iconsize-15">work</mat-icon>
                        <label>{{ Account.companyname }}</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-icon class="iconsize-15">schedule</mat-icon>
                        <label>{{ Account.lastlogin | date: "medium" }}</label>
                    </div>
                    <br>
                </div>
                <div fxLayoutGap="20px" fxLayout="row">
                    <mat-icon matTooltip="Toggle to dark theme" *ngIf="!isDarkTheme" (click)="changeTheme()">brightness_3</mat-icon>
                    <mat-icon matTooltip="Toggle to bright theme" *ngIf="isDarkTheme" (click)="changeTheme()">wb_sunny
                    </mat-icon>
                    <mat-icon matTooltip="Start Tour" (click)="startTour()">help</mat-icon>
                    <mat-icon matTooltip="Log out" (click)="logout()">exit_to_app</mat-icon>
                </div>
            </div>

        </mat-sidenav>



        <!-- onscroll="changemenu()" not used-->
        <mat-sidenav-content #idmaincontainer id="idmaincontainer" class="sidenav-content">
            <router-outlet></router-outlet>
        </mat-sidenav-content>

    </mat-sidenav-container>

</div>