import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-twofactorwindow',
  templateUrl: './twofactorwindow.component.html',
  styleUrls: ['./twofactorwindow.component.scss']
})
export class TwofactorwindowComponent implements OnInit {
  tokencode1: number;
  tokencode2: number;
  tokencode3: number;
  tokencode4: number;
  tokencode5: number;
  tokencode6: number;
  tokencode: number;

  constructor(
    public dialogRef: MatDialogRef<TwofactorwindowComponent>,
  ) { }

  ngOnInit(): void {
  }

  submit(){
    this.tokencode = this.tokencode1 + this.tokencode2 + this.tokencode3 + this.tokencode4 + this.tokencode5 + this.tokencode6; 
    this.dialogRef.close(this.tokencode)
  }

  public focusNext(i) {
    if (i === 6){
      this.submit();
    }

    let Id = 'token' + (i + 1);
    document.getElementById(Id).focus();
}

}
