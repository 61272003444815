/* tslint:disable */
import {
  Relations,
  Company
} from '../index';

declare var Object: any;
export interface TranslationInterface {
  "accountId"?: string;
  "companyId"?: any;
  "relationsId"?: any;
  "title"?: string;
  "date"?: Date;
  "tokens"?: number;
  "text"?: string;
  "translation"?: string;
  "sourcetext"?: string;
  "sourcelanguage"?: string;
  "targetlanguage"?: string;
  "id"?: any;
  "marketingId"?: any;
  marketing?: Relations;
  company?: Company;
}

export class Translation implements TranslationInterface {
  "accountId": string;
  "companyId": any;
  "relationsId": any;
  "title": string;
  "date": Date;
  "tokens": number;
  "text": string;
  "translation": string;
  "sourcetext": string;
  "sourcelanguage": string;
  "targetlanguage": string;
  "id": any;
  "marketingId": any;
  marketing: Relations;
  company: Company;
  constructor(data?: TranslationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Translation`.
   */
  public static getModelName() {
    return "Translation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Translation for dynamic purposes.
  **/
  public static factory(data: TranslationInterface): Translation{
    return new Translation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Translation',
      plural: 'Translations',
      path: 'Translations',
      idName: 'id',
      properties: {
        "accountId": {
          name: 'accountId',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
        "relationsId": {
          name: 'relationsId',
          type: 'any'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "tokens": {
          name: 'tokens',
          type: 'number'
        },
        "text": {
          name: 'text',
          type: 'string'
        },
        "translation": {
          name: 'translation',
          type: 'string'
        },
        "sourcetext": {
          name: 'sourcetext',
          type: 'string'
        },
        "sourcelanguage": {
          name: 'sourcelanguage',
          type: 'string'
        },
        "targetlanguage": {
          name: 'targetlanguage',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "marketingId": {
          name: 'marketingId',
          type: 'any'
        },
      },
      relations: {
        marketing: {
          name: 'marketing',
          type: 'Relations',
          model: 'Relations',
          relationType: 'belongsTo',
                  keyFrom: 'marketingId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
      }
    }
  }
}
