<mat-card  fxLayout="column" [style.width]="selectedChat.width + selectedChat.wform"
    [style.height]="selectedChat.height + selectedChat.hform" [style.color]="selectedChat.textcolor"
    [style.background-color]="selectedChat.color" class="chatbox" id="chatbox">
    <div *ngFor="let message of Chatmessages " id="chatconversation">
        <span *ngIf="message.accountId === Account.id" fxFlex="65%" class="listbox">{{ message.message }}</span>
        <span *ngIf="message.accountId !== Account.id" fxFlex="65%" fxFlexOffset="50%"
            class="boxguest">{{ message.message }}</span>
    </div>
    <mat-form-field appearance="outline">
        <input matInput i18n-placeholder placeholder="message" [(ngModel)]="message.message" (keyup.enter)="sendMessage()"
            ngDefaultControl autocomplete="off" name="message">
        <mat-icon matTooltip-i18n matTooltip="select Emoji" (click)="onshowemoji()" matSuffix>insert_emoticon</mat-icon>
        <div *ngIf="showemoji" style="width: 100%; 
          height: 100%; position: absolute; top: 8; left: 20; z-index: 20;">
            <emoji-mart (emojiClick)="setemoji($event)" set="apple" title="enydea"></emoji-mart>
        </div>
    </mat-form-field>

    <button mat-raised-button (click)="sendMessage()" i18n>send</button>
</mat-card>