<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">
    <mat-button-toggle matTooltip="Local stored audio" style="width: 33%" value="fromaccount">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Music" style="width: 33%" value="standardaudio">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Audio Effects" [checked]="true"  style="width: 33%" value="audioeffects">
      <mat-icon>iso</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="group.value == 'standardaudio'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let audio of stockaudio; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(audio.url)">
        <button a mat-raised-button (click)="selectedimage(audio.url)" [class.selected]="audio.url === data.selected">
          <mat-icon>sound</mat-icon> {{audio.name}}
        </button>
        <audio controls style="margin: 10px" preload="none">
          <source src={{audio.url}} type="audio/mp3">
        </audio>
        <!-- <mat-basic-audio-player [audioUrl]="audio.url" [title]="audio.name" [autoPlay]="false" muted="muted"
          [displayTitle]="false" [displayVolumeControls]="false"></mat-basic-audio-player> [class.selected]="audio.url === data.selected" -->
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'audioeffects'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let audio of stockaudioeffects; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(audio.url)">
        <button a mat-raised-button (click)="selectedimage(audio.url)" [class.selected]="audio.url === data.selected">
          <mat-icon>sound</mat-icon> {{audio.name}}
        </button>
        <audio controls style="margin: 10px" preload="none">
          <source src={{audio.url}} type="audio/mp3">
        </audio>
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'fromaccount'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let image of data.img; let i = index" fxFlex>

      <button a mat-raised-button (click)="selectedimage(image.url)" [class.selected]="image.url === data.selected">
        <mat-icon>audio</mat-icon> {{image.name}}
      </button>

    </div>
  </div>


  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>