<div fxLayout="column">
    <div fxLayout="column" class="topbanner">
        <div class="selectrelation" fxFlex fxShow.xs="false" fxLayout="row">

        </div>
    </div>

    <mat-tab-group>
        <mat-tab label="Relations">
            <ng-template mat-tab-label>
                <mat-icon>translate</mat-icon>
                <div fxShow.xs="false">Translations</div>
            </ng-template>


        </mat-tab>
        <mat-tab label="Relations">
            <ng-template mat-tab-label>
                <mat-icon>publish</mat-icon>
                <div fxShow.xs="false">Upload</div>
            </ng-template>

            <app-upload [Relations]="option"></app-upload>


        </mat-tab>
    </mat-tab-group>
</div>