<div style="margin: 10px">
    <br>
    <div fxLayout="row">
        <div fxLayout="column" class="form-full-width">
            <mat-form-field>
                <input matInput name="Email" type="url" [(ngModel)]="website" placeholder="url" />
            </mat-form-field>
        </div>
    </div>
    <br>
    <div fxLayout="row">
        <div fxLayout="column" class="form-full-width">
            <mat-form-field>
                <input matInput name="Email" type="email" [(ngModel)]="username" placeholder="username/email" />
            </mat-form-field>
        </div>
    </div>
    <br>
    <div fxLayout="row">
        <div fxLayout="column" class="form-full-width">
            <mat-form-field>
                <input matInput placeholder="Enter your password" name="password" [(ngModel)]="password"
                    [type]="!hide ? 'password' : 'text'">
                <mat-icon matSuffix (click)="!hide = hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <br>
    <div>
        <button (click)="getPosts()" mat-button>Get posts</button>
        <button (click)="getPages()" mat-button>Get pages</button>
        <mat-form-field *ngIf="translationSource">
            <mat-label>Load translation file</mat-label>
            <mat-select [(ngModel)]="setTrans" (selectionChange)="loadTranslation(setTrans.url)">
                <mat-option *ngFor="let trans of translations" [value]="trans">
                    {{trans.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-expansion-panel *ngIf="translationSource">
            <mat-expansion-panel-header>
                Upload Excel translation file
            </mat-expansion-panel-header>
            <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)">
                <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                    {{ uploaderContent | async }}
                </div>
            </xlsx-file-upload>
        </mat-expansion-panel>

    </div>
    <br>
    <div fxLayout="row" fxLayoutGap="25px">
        <mat-card fxFlex="49%" fxLayout="column">
            <h3>Content</h3>
            <div *ngIf="wpposts">
                <h4>Posts</h4>
                <div *ngFor="let post of wpposts; let i = index">
                    <label>{{post.title.rendered}}</label>
                    <mat-icon (click)="addTransQueuePost(post, i)">check</mat-icon>
                </div>
            </div>

            <div *ngIf="wppages">
                <h4>Pages</h4>
                <div *ngFor="let page of wppages; let i = index">
                    <label>{{page.title.rendered}}</label>
                    <mat-icon (click)="addTransQueuePage(page, i)">check</mat-icon>
                </div>
            </div>
        </mat-card>

        <mat-card fxFlex="49%" fxLayout="column">
            <h3>Translate</h3>
            <div *ngIf="wppostsTranslated">
                <h4>Posts</h4>
                <div *ngFor="let post of wppostsTranslated">
                    <label>{{post.title.rendered}}</label>
                </div>
            </div>

            <div *ngIf="wppagesTranslated">
                <h4>Pages</h4>
                <div *ngFor="let page of wppagesTranslated">
                    <label>{{page.title.rendered}}</label>
                </div>
            </div>
        </mat-card>

    </div>
</div>