import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  img;
  selected;
}

@Component({
  selector: 'app-dialog-image-editgallery',
  templateUrl: './dialog-image-editgallery.component.html',
  styleUrls: ['./dialog-image-editgallery.component.scss']
})
export class DialogImageEditgalleryComponent implements OnInit {

  public accountimages = [];
  public fromaccountsliceMin = 0;
  public fromaccountslice = 11;
  public fromaccountarray = [];

  constructor(
    public dialogRef: MatDialogRef<DialogImageEditgalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.data.img.forEach((image, i) => {
      //const isourl = BASE_URL + iso;
      if (i < 12) { this.accountimages.push(image) };
      this.fromaccountarray.push(image);
    });
    console.log(this.accountimages);
  }

  before() {
    if (this.fromaccountsliceMin > 0) {
      this.fromaccountslice = this.fromaccountslice - 12;
      this.fromaccountsliceMin = this.fromaccountsliceMin - 12;
      this.accountimages = this.fromaccountarray.slice(this.fromaccountsliceMin, this.fromaccountslice)
    }
  }

  next() {
        if (this.fromaccountslice < this.fromaccountarray.length) {
          this.fromaccountslice = this.fromaccountslice + 12;
          this.fromaccountsliceMin = this.fromaccountsliceMin + 12;
          this.accountimages = this.fromaccountarray.slice(this.fromaccountsliceMin, this.fromaccountslice)
        }
  }

  onNoClick(): void {
    this.data.selected = '';
    this.dialogRef.close();
  }

  selectedimage(img): void {
    this.data.selected = img;
  }

}
