import { Component, ElementRef, OnInit, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  LoopBackConfig,
  CompanyApi,
  Company,
  AccountApi,
  Account,
  LinkedinApi,
  Linkedin,
  Team,
  TeamApi,
  Relations,
  RelationsApi,
  ContainerApi,
  UnsortedcallsApi,
  Unsortedcalls,
  Emailhandler,
  EmailhandlerApi,
  ContainersecureApi,
  MailingApi,
  Logger,
  FilesApi,
  Files,
  Calls,
  Pushsubscriptions,
  Contactpersons
} from '../shared/sdk';
import { BASE_URL, API_VERSION, VAPID_PUBLIC_KEY } from '../shared/base.api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LinkedinService } from '../shared/socialservice';
import { DialogsService } from './../dialogsservice/dialogs.service';
import { DialogGetname } from './../dialogsservice/dialog.getname'

import { filter, map, startWith, takeUntil } from "rxjs/operators";
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fontoptions } from './google-fonts-list';
import { TextEditorDialog } from '../marketing/maileditor/texteditordialog.component';
import { fonts } from '../shared/listsgeneral/fonts';
import { AuthService, User } from './auth365.service';
import { GraphService } from './graph365/graph.service';
import { HttpClient } from '@angular/common/http';
import { OfficeMail } from './graph365/mailclass';
import { SwPush } from '@angular/service-worker';
import * as moment from 'moment';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

export interface unsortedCompanies {
  zipcode: string;
  phone: string;
  website: string;
  company: string,
  firstname: string,
  lastname: string,
  email: string,
  import: boolean,
  importrelated: boolean,
  address: string,
  city: string,
  stateprovince: string,
  country: string

}

export interface Subscription {
  paid: boolean,
  onetimepaid: boolean
  terms: string,
  plan: string,
  paymentid: string,
  customerid: string,
  date: Date,
  enddate: Date,
  nextinstallment: Date,
  amount: number,
  currency: string,
  trainingsupport: boolean,
  migrationsupport: boolean,
  additionalusers: number,
  emailcount: number,
  yearlyamount: number,
  onetime: number,
  accountId: string,
  emailusage: number,
  translationusage: number,
  marketingrelationaccounts: number
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsComponent implements OnInit {
  public resetPasswordToggle = false;
  public Fonts = fonts;
  public Relations: Relations[];
  public Account: Account = new Account();
  public Team: Team[];
  public TeamAccount: Account[];
  public TeamMemberlist: Team[];
  public newAccount: Account = new Account();
  public Company: Company = new Company();
  public Unsortedcalls: Unsortedcalls[];
  public adminAccounts: Account[];
  public Emailhandler: Emailhandler = new Emailhandler;
  nativeWindow: any;
  public code: any;
  public state: any;
  public sub: any;
  public Linkedin: Linkedin[];
  public selectedLinkedin: Linkedin;
  public selectedOption = false;
  public error;
  public result;
  public pageTitle = false;
  public bufferValue;
  public passwordsuccess = false;
  public toggleedituser = false;
  public currentdomain: string;
  public domainresponse: string;
  public logs: Logger[];
  public selectedCall: Unsortedcalls;
  public callindex: any;
  public UnsortedcallsId;
  public options = [];
  public option: Relations;
  public newRelation: Relations = new Relations();
  public files;
  public skip = 0;
  public limit = 20;
  public Unsortedcallslength: number;
  public subscription: Subscription;
  public marketingrelationcount: number;
  public Files: Files[];
  public OfficeMail: OfficeMail[];
  public officetoggle = false;
  public selectedOfficeMail: OfficeMail;
  public unsortedCompanies: unsortedCompanies[];
  public checkallmails = false;
  public selectedStored = false;
  public listviewxsshow = false;
  myControl = new UntypedFormControl();
  public searchunsortedcompanies = false;
  private readonly _destroying$ = new Subject<void>();

  calltype = [
    { value: 'PhoneCall', viewValue: 'Phone Call' },
    { value: 'E-mail', viewValue: 'E-mail' },
    { value: 'Meeting', viewValue: 'Meeting' },
    { value: 'ConferenceCall', viewValue: 'Conference Call' },
    { value: 'LinkedinMessage', viewValue: 'Linkedin Message' },
    { value: 'Other', viewValue: 'Other' }
  ];

  statustype = [
    { value: 'not attempted', viewValue: 'Not attempted' },
    { value: 'attempted', viewValue: 'Attempted' },
    { value: 'contacted', viewValue: 'Contacted' },
    { value: 'schedule call', viewValue: 'Schedule Call' },
    { value: 'schedule meeting', viewValue: 'Schedule Meeting' },
    { value: 'new opportunity', viewValue: 'New opportunity' },
    { value: 'additional contact', viewValue: 'Additional contact' },
    { value: 'disqualified', viewValue: 'Disqualified' }
  ];

  searchfilters = [
    { value: 'FLAGGED' },
    { value: 'UNSEEN' }
  ];

  // setup account stepper
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  oldpassword: string;
  newpassword: string;
  public selectedTab = 0;
  public fontlist: string[] = fontoptions;
  passwordstrenght = 0;
  registerbutton = false;
  //readonly VAPID_PUBLIC_KEY = 'BDywne_YekS17stTXpBtToiIC3uPSMyDcy1FylB1Staq7gUWZ55EYXqyDCG-NTerG_Jajy1g6sOkQTx5M_lZfp0';
  isEnabled = this.swPush.isEnabled;
  isGranted = Notification.permission === 'granted';
  autoAssignPending: boolean;
  officefolders = [];
  resetEmailAddress: any;
  twofactorqrcode: string;
  twofactortokencode: any;
  // Is a user logged in?
  get authenticated(): boolean {
    return this.graphService.authenticated;
  }
  // The user
  get user(): User {
    return this.graphService.user;
  }

  constructor(
    private graphService: GraphService,
    //private msalBroadcastService: MsalBroadcastService,
    private elementRef: ElementRef,
    private swPush: SwPush,
    public http: HttpClient,
    private FileApi: FilesApi,
    private authService: AuthService,
    private EmailhandlerApi: EmailhandlerApi,
    public mailingApi: MailingApi,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public AccountApi: AccountApi,
    public RelationsApi: RelationsApi,
    public UnsortedcallsApi: UnsortedcallsApi,
    public ContainerApi: ContainerApi,
    public ContainersecureApi: ContainersecureApi,
    public RelationApi: RelationsApi,
    public CompanyApi: CompanyApi,
    public TeamApi: TeamApi,
    public dialogsService: DialogsService,
    //public DialogGetname: DialogGetname,
    public _formBuilder: UntypedFormBuilder,
    public LinkedinApi: LinkedinApi,
    public activatedRoute: ActivatedRoute,
    public LinkedinService: LinkedinService,
    public route: ActivatedRoute,
    public router: Router,
    public accountApi: AccountApi) {

    // LoopBackConfig.setBaseURL(BASE_URL);
    // LoopBackConfig.setApiVersion(API_VERSION);
  }

  public setParameters() {
    let itemid = this.route.snapshot.queryParamMap.get("itemid");
    let tab = this.route.snapshot.queryParamMap.get("tab");
    // console.log(itemid, tab);
    if (tab === 'unsorted') {
      this.selectedTab = 1;
      this.AccountApi.findByIdUnsortedcalls(this.Account.id, itemid)
        .subscribe(res => { this.onSelectCall(res, null) });
    }
    if (tab === 'invoice') {
      this.selectedTab = 3;
      //this.
    }
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000,
      panelClass: "snackbar-class"
    });
  }

  getQRcode(){
    this.accountApi.getqrcode(this.Account.id).subscribe((qrstring: string) => {
      this.twofactorqrcode = qrstring;
    })  
  }


  checkTwoFactor(){
    this.accountApi.verifytwofactor(this.Account.id, this.twofactortokencode).subscribe((res: boolean) => {
      if (res){
        this.getAccountInfo();
      }
    }, error => {
      this.openSnackBar(error.message)
    })
  }

  resetTwoFactor(){
    this.Account.two_factor_enabled = false;
    this.saveAccount();
  }

  subscribeToNotification() {
    this.Account.pushnotifications = true;
    this.saveAccount();
    //const convertedVapidKey = this.urlBase64ToUint8Array(this.VAPID_PUBLIC_KEY); // !! not necessary for swPush??
    this.swPush.requestSubscription({
      serverPublicKey: VAPID_PUBLIC_KEY
    })
      .then((sub: PushSubscription) => {
        //console.log(sub);
        let browserinfo = navigator.userAgent;
        let pushsub = new Pushsubscriptions();
        pushsub.endpoint = sub.endpoint;
        // pushsub.expirationTime = sub.expirationTime;
        pushsub.browserinfo = browserinfo;
        pushsub.keys = {
          p256dh: sub.getKey('p256dh'),
          auth: sub.getKey('auth')
        }
        //pushsub.keys = sub.getKey();
        this.AccountApi.createPushsubscriptions(this.Account.id, sub).subscribe();
        this.swPush.notificationClicks.subscribe(
          ({ action, notification }) => { //
            console.log('Received notification: ', action);
            const url = notification.data.url;
            window.open(url, '_blank');
          });
      })
      .catch(err => { this.openSnackBar(err), console.error(err) }); //'Could not subscribe to notifications', 
  }

  swiperight(e?) {
    this.listviewxsshow = true;
    //console.log(e)
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
    //console.log(e)
  }

  turnOffNotification() {
    this.Account.pushnotifications = false;
    this.saveAccount();
    this.swPush.unsubscribe()
      .then(success => {
        this.openSnackBar('Unsubscribed to messages');
        this.AccountApi.deletePushsubscriptions(this.Account.id).subscribe();
      })
      .catch(err => {
        this.openSnackBar('Unsubscription failed')
      });
  }

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);


  // matcher = new MyErrorStateMatcher();
  filteredOptions: Observable<string[]>;
  myControlfont: UntypedFormControl = new UntypedFormControl();
  filteredfonts: Observable<string[]>;

  ngOnInit(): void {
    const auth = this.AccountApi.isAuthenticated()
    if (auth === false) { this.router.navigate(['login']) }

    this.getAccountInfo();
    //get Linkedin AccessToken Params
    this.sub = this.route.queryParams.subscribe(params => {
      this.code = params['code'],
        this.state = params['state']
      if (this.code !== undefined) { this.LinkedinService.getAccessToken(this.code, this.state) }
      else { this.LinkedinService.restoreCredentials() }
    });
    this.setFilters();

    // this.msalBroadcastService.msalSubject$
    // .pipe(
    //   filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    // )
    // .subscribe((result: EventMessage) => {
    //   console.log(result);
    // });

    // this.msalBroadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe(() => {
    //  // this.setLoginDisplay();
    // })

  }

  // ngOnDestroy(): void {
  //   this._destroying$.next(undefined);
  //   this._destroying$.complete();
  // }

  setFilters() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        //map(options => options && typeof options === 'object' ? options.relationname : options),
        map(relationname => relationname ? this.filter(relationname) : this.options.slice())
      );

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.filteredfonts = this.myControlfont.valueChanges.pipe(
      startWith(''),
      map(value => this._filterfont(value))
    );
  }

  private _filterfont(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.fontlist.filter(font => font.toLowerCase().includes(filterValue));
  }

  async signIn365(): Promise<void> {
    await this.graphService.signIn();
//    console.log(this.graphService.authenticated, this.user.email)

    if (this.graphService.authenticated && this.user.email) {

      this.Account["365account"] = this.user.email;
      this.saveAccount();
    } else {
      console.error(this.graphService.authenticated, this.user)
    }
    //this.authService.loginRedirect()
  }

  async signOut365() {
    this.Account["365account"] = '';
    await this.saveAccount();
    this.graphService.signOut();
  }

  logout(): void {
    if (this.Account.id == undefined) { this.router.navigate(['/login']) }
    else {
      this.accountApi.logout().subscribe(res =>
        this.router.navigate(['/login']));
    }
  }

  //current account info
  getAccountInfo(): void {
    this.accountApi.getCurrent().subscribe(async (Account: Account) => {
      this.Account = Account;
      if (this.Account["365account"]) {
        this.officetoggle = true;
        let folders = await this.graphService.getMailFolders();
        if (folders) {
          folders.forEach(fold => {
            fold['import'] = false;
            this.officefolders.push(fold);
          })
        } else {
          console.log('no folders selected')
        }

        this.checkFolderTracked();
        // console.log(this.officefolders);

      }
      // this.getPushSubscriptions();
      this.setParameters();
      this.getUnCalls();
      this.getLinkedin();
      this.getAdminAccountsoverview();
      this.emailHandler();
      this.getLogs();
      this.getSubscription();
    });
  }

  checkFolderTracked() {
    // console.log(this.Account.autoassigninbox, this.officefolders)
    if (this.Account.autoassigninbox.length > 0 && this.officefolders.length > 0) {


      for (let i = 0; i < this.officefolders.length; i++) {
        for (let y = 0; y < this.Account.autoassigninbox.length; y++) {

          if (this.Account.autoassigninbox[y].id === this.officefolders[i].id) {
            this.officefolders[i].checked = true;
          }
        }
      }
    }
  }

  saveFolderTracked() {
    this.Account.autoassigninbox = [];
    for (let i = 0; i < this.officefolders.length; i++) {
      if (this.officefolders[i].checked) {
        this.Account.autoassigninbox.push({ id: this.officefolders[i].id })
      }

    }
    this.saveAccount();
  }

  getSubscription() {
    if (this.Account.companyadmin) {
      this.AccountApi.getsub(this.Account.id)
        .subscribe((subscr: Subscription[]) => {
          if (subscr.length > 0) {
            this.subscription = subscr[0];
          }
        });
    }
  }

  getLogs() {
    this.accountApi.getLogger(this.Account.id)
      .subscribe((logs: Logger[]) => {
        this.logs = logs;
      })
  }

  deleteAvatar() {
    this.Account.avatarurl = '';
    this.saveAccount();
  }

  getAdminAccountsoverview(): void {

    this.CompanyApi.findById(this.Account.companyId).subscribe((company: Company) => {
      this.Company = company,
        this.currentdomain = company.companywebsite,
        this.getStandardRelation();
      this.getRelations();

      if (this.Account.companyadmin === true) {
        this.getTeams();
      }
      this.CompanyApi.countRelations(this.Account.companyId,
        { marketingrelation: true }).subscribe(res => { this.marketingrelationcount = res.count });
    });

  }

  getStandardRelation() {
    // console.log(this.Account.standardrelation)
    if (this.Account.standardrelation) {
      this.RelationsApi.findById(this.Account.standardrelation)
        .subscribe((relations: Relations) => {
          this.option = relations;
          //console.log(this.option)
        });
    }
  }

  setAvatar(url) {
    //console.log(url);
    this.Account.avatarurl = url;
    this.saveAccount();
  }

  setCompanyLogo(url) {
    this.Company.companylogo = url;
    this.saveCompany();
  }


  setAdminTeam() {
    this.CompanyApi.createTeam(this.Account.companyId,
      {
        "accountId": this.Account.id,
        // "companyId": "5a2a4e6b5c2a7a06c443533d",
        "teamName": "enydea",
        "username": "Admin",
        "standardrelation": this.Account.standardrelation
      }
    ).subscribe(res => console.log(res))
  }

  setCompanyAddress(addrObj) {
    // console.log(addrObj);
    if (addrObj.name !== undefined) {
      this.Company.companyname = addrObj.name;
    }
    if (addrObj.route !== undefined) {
      this.Company.address = addrObj.route + " " + addrObj.street_number;
    } else {
      this.Company.address = '';
    }
    if (addrObj.phone !== undefined) {
      this.Company.phonenumber = addrObj.phone;
    } else {
      this.Company.phonenumber = '';
    }
    if (addrObj.country !== undefined) {
      this.Company.country = addrObj.country;
    } else {
      this.Company.country = '';
    }
    if (addrObj.locality !== undefined) {
      this.Company.city = addrObj.locality;
    } else {
      this.Company.city = '';
    }
    if (addrObj.admin_area_l1 !== undefined) {
      this.Company.stateprov = addrObj.admin_area_l1;
    } else {
      this.Company.stateprov = '';
    }
    if (addrObj.postal_code !== undefined) {
      this.Company.zipcode = addrObj.postal_code;
    } else {
      this.Company.zipcode = '';
    }
    if (addrObj.website !== undefined) {
      this.Company.companywebsite = addrObj.website;
    } else {
      this.Company.companywebsite = '';
    }
    //this.showAddress();
  }

  // showAddress() {
  //   this.Company =
  //     this.newRelation.address1 + ', ' +
  //     this.newRelation.city + ', ' +
  //     this.newRelation.stateprovince + ', ' +
  //     this.newRelation.zipcode + ', ' +
  //     this.newRelation.country;
  //   //get geo location
  //   this.getAddress();
  // }

  changePassword(): void {
    this.passwordsuccess = true,
      this.accountApi.changePassword(this.oldpassword, this.newpassword)
        .subscribe(res => this.passwordsuccess = true, error => this.passwordsuccess = false);
  }

  getTeams() {
    // Loopback filter function does not work properly so check seperatly in foreach
    this.CompanyApi.getTeam(this.Account.companyId, { where: { accountId: { nlike: this.Account.id } } })
      .subscribe((Team: Team[]) => {
        this.Team = Team;
        this.Team.forEach((member, i) => {
          if (member.accountId === this.Account.id) {
            this.Team.splice(i, 1);
          }
        })
      });
  }

  resetTeamMemberPassword(Team, i) {
    this.AccountApi.resetPassword({ email: Team.Email })
  }

  deleteTeamMember(Team: Team, i): void {
    this.dialogsService
      .confirm('Delete Team member', 'Are you sure you want to do this? This Action cannnot be reversed!')
      .subscribe(res => {
        if (res === true) {
          this.CompanyApi.destroyByIdTeam(this.Account.companyId, Team.id).subscribe(res => {
            this.getTeams();
          });
        }
      });
  }

  public createTeamMember(): void {
    this.newAccount.companyadmin = false,
      this.newAccount.companyname = this.Account.companyname,
      this.newAccount.companyId = this.Account.companyId,
      this.accountApi.create(this.newAccount)
        .subscribe(res => {
          this.getTeams();
        }, error => { this.openSnackBar(error.message) });
  }

  //Wordpress Authenticate save
  wpauth(): void {

  }

  //linkedin AuthCode
  linkauth(): void {
    this.LinkedinService.linkedinAuthorize();
  }

  //get current linkedin member info
  linkme(): void {
    this.LinkedinService.linkedinMe();
  }

  public getLinkedin(): void {
    this.LinkedinApi.find().subscribe((Linkedin: Linkedin[]) => {
      this.Linkedin = Linkedin
    });
  }

  public onSelectLinkedin(Linkedin: Linkedin): void {
    this.selectedLinkedin = Linkedin;
  }

  public saveLinkedin(): void {
    this.LinkedinApi.onUpsert(this.selectedLinkedin).subscribe();
  }

  public openDialogDeleteAccount() {
    this.dialogsService
      .confirm('Delete Current Account', 'Are you sure you want to do this? This Action cannnot be reversed!')
      .subscribe(res => {
        this.selectedOption = res, this.deleteAccount(this.selectedOption);
      });
  }

  onStrengthChanged(e) {
    this.passwordstrenght = e;
    this.checkall();
  }

  checkall() {
    if (this.passwordstrenght > 60 && this.Account.companyname.length > 2 &&
      this.Account.email.length > 2 && this.Account.username.length > 2) {
      this.registerbutton = true;
    } else { this.registerbutton = false }

  }

  deleteAccount(selectedOption): void {
    if (selectedOption === true) {
      this.accountApi.deleteById(this.Account.id).subscribe(res => {
        this.error = res,
          this.router.navigate(['/login'])
      })
    }
  }

  public getUnCalls(): void {
    this.accountApi.countUnsortedcalls(this.Account.id)
      .subscribe(res => { this.Unsortedcallslength = res.count; });
    this.newRelation.relationname = undefined;
    this.newRelation.status = undefined;
    //this.option = undefined;
    this.selectedCall = undefined;
    this.accountApi.getUnsortedcalls(
      this.Account.id,
      {
        order: 'id DESC',
        // order: 'relationname ASC',
        limit: 20,
        skip: this.skip,
      }
    )
      .subscribe((unsortedcalls: Unsortedcalls[]) => this.Unsortedcalls = unsortedcalls);
  }

  saveCall(): void {
    this.accountApi.updateByIdUnsortedcalls(this.Account.id, this.selectedCall.id, this.selectedCall)
      .subscribe(Calls => this.Unsortedcalls[this.callindex] = this.selectedCall);
  }

  download(selectedfile) {
    this.http.get(selectedfile.url, { responseType: 'blob' }).subscribe(blob => {
      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      element.setAttribute('href', seturl);
      element.setAttribute('download', selectedfile.name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }

  createFromOffice(resid) {
    console.log('create from office')
    let officeMailCall = new Calls();
    let namecontact = this.selectedOfficeMail.sender.emailAddress.name;
    let email = this.selectedOfficeMail.sender.emailAddress.address;
    officeMailCall.html = this.selectedOfficeMail.body.content;
    officeMailCall.followup = this.selectedOfficeMail.followup;
    officeMailCall.title = this.selectedOfficeMail.subject;
    officeMailCall.attendee = [];
    officeMailCall.calltype = 'E-mail';
    officeMailCall.date = moment(this.selectedOfficeMail.sendDateTime).toDate();
    // officeMailCall.mailbcc = this.selectedOfficeMail.bccRecipients;
    // officeMailCall.mailcc = this.selectedOfficeMail.ccRecipients;
    if (this.selectedOfficeMail.sender.emailAddress.name) {
      namecontact = this.selectedOfficeMail.sender.emailAddress.name
      officeMailCall.attendee.push(this.selectedOfficeMail.sender.emailAddress.name)
    }
    this.selectedOfficeMail.ccRecipients.forEach(element => {
      officeMailCall.attendee.push(element.emailAddress.name);
    })
    this.RelationsApi.createCalls(resid, officeMailCall)
      .subscribe(async res => {
        //console.log(email.toLocaleLowerCase().trim(), this.Account["365account"].toLocaleLowerCase().trim())
        if (email.toLocaleLowerCase().trim() !== this.Account["365account"].toLocaleLowerCase().trim()) {
          await this.openDialogNewContactperson(resid, email, namecontact);
        }
        // this.selectedOfficeMail.toRecipients.forEach(recip => {
        //   if (recip.emailAddress.address.toLocaleLowerCase().trim() !== this.Account["365account"].toLocaleLowerCase().trim()) {
        //     this.openDialogNewContactperson(recip.emailAddress.address, email, namecontact);
        //   }
        // });

        for (let i = 0; i < this.selectedOfficeMail.toRecipients.length; i++) {
          let recip = this.selectedOfficeMail.toRecipients[i];
          if (recip.emailAddress) {
            if (recip.emailAddress.address.toLocaleLowerCase() !== this.Account["365account"].toLocaleLowerCase()) {
              let name = '';

              await this.openDialogNewContactperson(resid, recip.emailAddress.address, name);
            }
          }
        }

        for (let i = 0; i < this.selectedOfficeMail.toRecipients.length; i++) {
          let recip = this.selectedOfficeMail.ccRecipients[i];
          if (recip) {
            if (recip.emailAddress.address.toLocaleLowerCase() !== this.Account["365account"].toLocaleLowerCase()) {
              let name = '';
              if (recip.emailAddress.name) { name = recip.emailAddress.name }
              await this.openDialogNewContactperson(resid, recip.emailAddress.address, name);
            }
          }
        }

        // this.selectedOfficeMail.ccRecipients.forEach(element => {
        //   if (element.emailAddress.address.toLocaleLowerCase().trim() !== this.Account["365account"].toLocaleLowerCase().trim()) {
        //   this.openDialogNewContactperson(resid, element.emailAddress.address, element.emailAddress.name);
        //   }
        // })
      });
  }

  assignCall(): void {
    console.log('assign call')
    if (this.officetoggle) {
      this.createFromOffice(this.option.id)
    }
    else {
      this.UnsortedcallsId = this.selectedCall.id,
        this.selectedCall.id = undefined,
        this.RelationsApi.createCalls(this.option.id, this.selectedCall)
          .subscribe(res => {
            this.openDialogNewContactperson(this.option.id, this.selectedCall.email, this.selectedCall.attendee),
              // this.RelationsApi.createContactpersons(this.option.id, { email: this.selectedCall.email })
              //   .subscribe(res1 => {
              this.accountApi.destroyByIdUnsortedcalls(this.Account.id, this.UnsortedcallsId)
                .subscribe(res2 => this.getUnCalls());
          });
      //});
    }

  }

  createNewFromUnsorted(resid) {
    console.log('create new form unsorted')
    // send as par to dialog so process resumes
    if (this.selectedCall.email !== undefined) { this.openDialogNewContactperson(resid, this.selectedCall.email, this.selectedCall.attendee) }
    this.UnsortedcallsId = this.selectedCall.id,
      this.selectedCall.id = undefined, //err id already set => clear
      this.selectedCall.companyId = this.Account.companyId
    this.RelationsApi.createCalls(resid, this.selectedCall)
      .subscribe(res => {
        this.Unsortedcalls.splice(this.callindex),
          this.accountApi.destroyByIdUnsortedcalls(this.Account.id, this.UnsortedcallsId)
            .subscribe(res => this.getUnCalls());
      });
  }

  // create
  createNewAssign(): void {
    if (this.newRelation.relationname !== undefined) {
      this.CompanyApi.createRelations(this.Account.companyId, this.newRelation)
        .subscribe(res => {
          if (this.officetoggle) {
            this.createFromOffice(res.id)
          }
          else {
            this.createNewFromUnsorted(res.id)
          }
        });
    } else {
      this.dialogsService
        .confirm('New Relation Empty', 'Please fill in Relation details order details can be filled in under the Relations Menu')
        .subscribe(res => { })
    }
  }

  // create new Contactperson
  public async openDialogNewContactperson(id, email, name): Promise<any> {
    this.dialogsService
      .confirm('Create new Contactperson ' + email, 'Do you want to create a new Entry?')
      .subscribe(res => {
        console.log(res, id, email, name);
        if (res === true) {
          if (name === '') {

            const dialogRef = this.dialog.open(DialogGetname, {
              width: '500px',
              data: { name: email }
            });
            dialogRef.afterClosed().subscribe(result => {
              name = result;
              this.newContactperson(id, email, name);
            });

          } else {
            this.newContactperson(id, email, name);
          }

        }
        return;
      });
  }

  public newContactperson(id, email, name) {
    // split string name
    let temp;
    name = name.replace(',', '');
    name = name.replace('.', '');
    name = name.replace(';', '');
    if (name !== undefined) {
      if (typeof name === 'string') {
        temp = name.split(" ");
      }
      if (name.name) {
        temp = name.name.split(" "); // now you have 2 words in temp
      }
    } else {
      temp = ['unknown', 'unknown']; // if attendee is undefined
    }
    console.log(temp);

    let lastname = ''; // if has middle names etc. 
    for (let i = 1; i < temp.length; i++) {
      lastname = lastname + ' ' + temp[i];
    }

    this.RelationsApi.createContactpersons(id, {
      companyId: this.Account.companyId,
      email: email,
      firstname: temp[0],  // is your second word
      lastname: lastname// is your third word
    }).subscribe(
      res => {
        this.openSnackBar('Saved')
        if (!this.officetoggle) {
          this.Unsortedcalls.splice(this.callindex), this.getUnCalls()
        }
      });
  }

  openDialogDeleteCall() {
    this.dialogsService
      .confirm('Delete Call', 'Do you want to delete this Entry?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteCall(this.selectedOption)
      });
  }

  deleteCall(selectedOption): void {
    if (selectedOption == true) {
      this.Unsortedcalls.splice(this.callindex),
        this.accountApi.destroyByIdUnsortedcalls(this.Account.id, this.selectedCall.id)
          .subscribe(res => { this.getUnCalls() });
    }
  };


  openDialogDeleteAllCall() {
    this.dialogsService
      .confirm('Delete all Calls!', 'Do you want to delete all unsorted Calls!')
      .subscribe(res => {
        this.selectedOption = res, this.deleteAllCall(this.selectedOption)
      });
  }

  deleteAllCall(selectedOption) {
    if (selectedOption == true) {
      this.Unsortedcalls.forEach((item, index) => {
        this.accountApi.destroyByIdUnsortedcalls(this.Account.id, item.id)
          .subscribe(res => this.getUnCalls());
      })
    }
  }


  onSelectCall(Unsortedcalls: Unsortedcalls, i): void {
    this.selectedCall = Unsortedcalls;
    this.callindex = i;

    if (this.selectedCall.messageid) {
      this.CompanyApi.getFiles(this.Account.companyId, { where: { "messageid": this.selectedCall.messageid } })
        .subscribe((files: Files[]) => { this.Files = files; })
    }
  }

  getRelations(): void {
    this.CompanyApi.getRelations(this.Account.companyId)
      .subscribe((Relations: Relations[]) => { 
        this.Relations = Relations, this.getrelationsEntry() 
      });
  }

  getrelationsEntry(): void {
    this.options = [];
    for (let relation of this.Relations) {
      this.options.push(relation);
    }
  }

  filter(relationname: string) {
    if (relationname) {
      return this.options.filter(option =>
        option.relationname.toLowerCase().indexOf(relationname.toLowerCase()) === 0);
    }
  }

  displayFn(options): string {
    return options ? options.relationname : options;
  }

  searchCallGo(searchTerm): void {
    if (this.officetoggle) {
      this.getOfficeMail(searchTerm);
    } else {
      this.selectedCall = undefined;
      this.accountApi.getUnsortedcalls(this.Account.id, { where: { name: searchTerm } })
        .subscribe((Unsortedcalls: Unsortedcalls[]) => this.Unsortedcalls = Unsortedcalls);
    }
  }

  //set emailhandler for sync mailbox
  emailHandler(): void {
    this.AccountApi.getEmailhandler(this.Account.id)
      .subscribe((Emailhandler: Emailhandler) => {
        this.Emailhandler = Emailhandler
      }, error => { console.log('no email account set') });
  }

  createEmailHandler(): void {
    this.Emailhandler.companyId = this.Account.companyId
    if (this.Emailhandler.id == undefined) {
      this.accountApi.createEmailhandler(this.Account.id, this.Emailhandler)
        .subscribe(res => { this.result = res, console.log(this.result) });
    }
    else this.accountApi.updateByIdEmailhandler(this.Account.id, this.Emailhandler)
      .subscribe(res => this.error = res.message);

  }

  deleteEmailhandler(): void {
    this.accountApi.destroyByIdEmailhandler(this.Account.id, this.Emailhandler.id)
      .subscribe(res => { this.openSnackBar("Email Account deleted") });
  }

  toggleEditUser(): void {
    if (this.toggleedituser === true) { this.toggleedituser = false }
    else this.toggleedituser = true;
  }

  async saveAccount(): Promise<any> {
    await new Promise(resolve => setTimeout(resolve, 400));
    let res = await this.AccountApi.patchAttributes(this.Account.id, this.Account).toPromise();
    console.log(res);
    this.openSnackBar("Changes saved");
    return
    //     }, error => {
    //       this.openSnackBar(error.message),
    //         this.getAccountInfo();
    //         return
    //     });
    // }, 400);

  }

  deleteRelType(i) {
    console.log(i);
    this.Company.relationstatus.splice(i, 1);
    this.saveCompany();
  }

  deleteType(i) {
    console.log(i);
    this.Company.types.splice(i, 1);
    this.saveCompany();
  }

  deleteOpportunitytypes(i) {
    console.log(i);
    this.Company.Opportunitytypes.splice(i, 1);
    this.saveCompany();
  }


  deleteOpportunitystatus(i) {
    console.log(i);
    this.Company.Opportunitystatus.splice(i, 1);
    this.saveCompany();
  }

  deleteConst1(i) {
    console.log(i);
    this.Company.const1.splice(i, 1);
    this.saveCompany();
  }

  deleteConst2(i) {
    console.log(i);
    this.Company.const2.splice(i, 1);
    this.saveCompany();
  }

  deleteConst3(i) {
    console.log(i);
    this.Company.const3.splice(i, 1);
    this.saveCompany();
  }

  deleteConst4(i) {
    console.log(i);
    this.Company.const4.splice(i, 1);
    this.saveCompany();
  }

  saveRelType(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.relationstatus[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveType(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.types[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveOpportunitytypes(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.Opportunitytypes[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveOpportunitystatus(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.Opportunitystatus[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveConst1(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.const1[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveConst2(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.const2[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveConst3(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.const3[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveConst4(e, i) {
    console.log(e.srcElement.value, i);
    this.Company.const4[i] = e.srcElement.value;
    this.saveCompany();
  }

  saveCompany(): void {
    this.CompanyApi.upsert(this.Company).subscribe();
  }

  saveCompanyDomain(): void {
    this.CompanyApi.upsert(this.Company)
      .subscribe(res => this.openSnackBar("Changes saved"));
    this.mailingApi.deletedomain(this.Company.id, this.currentdomain)
      .subscribe(res => {
        this.setDomain();
      });
  }

  deleteDomain() {
    this.mailingApi.deletedomain(this.Company.id, this.currentdomain)
      .subscribe(res => console.log(res));
  }

  setDomain(): void {
    this.mailingApi.setdomain(this.Company.id, this.Company.companywebsite)
      .subscribe(res => console.log(res));
  }

  checkDomainSettings(): void {
    this.mailingApi.getdomaininfo(this.Company.id, this.Company.companywebsite)
      .subscribe(res => { this.domainresponse = res.sending_dns_records[1].value, console.log(res) });
  }

  billingCompanyCopy(): void {
    this.Company.billingaddress = this.Company.address;
    this.Company.billingcity = this.Company.city;
    this.Company.billingcountry = this.Company.country;
    this.Company.billingstateprov = this.Company.stateprov;
    this.Company.billingzipcode = this.Company.zipcode;
  }

  // setAddress(addrObj) {
  //   this.newRelation.relationname = addrObj.name;
  //   this.newRelation.address1 = addrObj.route + " " + addrObj.street_number;
  //   this.newRelation.generalphone = addrObj.phone;
  //   this.newRelation.country = addrObj.country;
  //   this.newRelation.city = addrObj.locality;
  //   this.newRelation.stateprovince = addrObj.admin_area_l1;
  //   this.newRelation.zipcode = addrObj.postal_code;
  // }

  setAddress(addrObj) {
    console.log(addrObj);
    if (addrObj.name !== undefined) {
      this.newRelation.relationname = addrObj.name;
    }
    if (addrObj.route !== undefined) {
      this.newRelation.address1 = addrObj.route + " " + addrObj.street_number;
    } else {
      this.newRelation.address1 = '';
    }
    if (addrObj.phone !== undefined) {
      this.newRelation.generalphone = addrObj.phone;
    } else {
      this.newRelation.generalphone = '';
    }
    if (addrObj.country !== undefined) {
      this.newRelation.country = addrObj.country;
    } else {
      this.newRelation.country = '';
    }
    if (addrObj.locality !== undefined) {
      this.newRelation.city = addrObj.locality;
    } else {
      this.newRelation.city = '';
    }
    if (addrObj.admin_area_l1 !== undefined) {
      this.newRelation.stateprovince = addrObj.admin_area_l1;
    } else {
      this.newRelation.stateprovince = '';
    }
    if (addrObj.postal_code !== undefined) {
      this.newRelation.zipcode = addrObj.postal_code;
    } else {
      this.newRelation.zipcode = '';
    }
    if (addrObj.website !== undefined) {
      this.newRelation.website = addrObj.website;
    } else {
      this.newRelation.website = '';
    }
    //this.showAddress();
  }


  getInvoices(): void {
    // get files detailss
    console.log(this.Account.companyId)
    this.ContainersecureApi.getFilesByContainer(this.Account.companyId).subscribe(res => {
      let response = res;
      this.files = response.Contents
      console.log(this.files);
    });
  }

  getFile(i): void {
    // get stream and encode base64 to pdf
    let itemName = this.files[i];
    console.log(itemName.Key);
    this.ContainersecureApi.getFilesByFilename(this.Account.companyId, itemName.Key)
      .subscribe(res => {
        //console.log(res);
        const byteCharacters = atob(res.$data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
      );
  }

  getlinkedinconnections(): void {
    this.LinkedinApi.linkedinconnections(this.selectedLinkedin.accesstoken).subscribe(res => {
      console.log(res);
    })
  }

  openeditorsignature(): void {
    const dialogRef = this.dialog.open(TextEditorDialog, {
      width: '800px',
      data: this.Account.signature // changingThisBreaksApplicationSecurity,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.length > 0) {
          this.Account.signature = result;
          this.saveAccount();
        };  // this.sanitizer.bypassSecurityTrustHtml(result);
      }
    });
  }

  getCallsnextpage(): void {
    if (this.limit < this.Unsortedcallslength) {
      this.limit = this.limit += 20;
      this.skip = this.skip += 20;
      if (this.officetoggle) {
        this.getOfficeMail();
      } else {
        this.getRelations();
      }
    }
  }

  getCallsbackpage(): void {
    if (this.skip > 0) {
      this.skip = this.skip -= 20;
      this.limit = this.limit -= 20,
        this.getRelations();
    }
  }

  deleteAllLogs() {
    this.CompanyApi.deleteLogger(this.Account.companyId).subscribe(res => {
      this.getLogs();
    });
  }

  setBCCTracker() {
    this.EmailhandlerApi.setMailgunHook(this.Account.companyId, this.Account.id, null, null).subscribe(res => {
      //this.Account.bccemail = this.Account.id + '@mail.enydea.com';
      this.Account.bccid = res.bccid;
      this.Account.bccemail = res.email;
      this.saveAccount();
      console.log(res.message);
      this.openSnackBar(res.message);
    });
  }

  removeBCCTracker() {
    this.EmailhandlerApi.removeMailgunHook(this.Account.bccid).subscribe(res => {
      this.Account.bccid = '';
      this.Account.bccemail = '';
      this.saveAccount();
      console.log(res);
      this.openSnackBar(res);
    });
  }

  /*
  1. check last update --> stored in profile 
  2. if not grap last 1000 
  3. check if email from is stored in contactperson
  4. check if email is new by ID
  5. save as email call 
  */
  public async autoAssign() {
    this.autoAssignPending = true;
    // go through 
    if (this.Account["365account"]) {
      let token = await this.graphService.getAccessToken();

      let date = '';
      // if (this.Account.lastupdate365account && !this.checkallmails) {
      //   date = this.Account.lastupdate365account;
      // } 

      let inboxes = [];
      for (let i = 0; i < this.officefolders.length; i++) {
        if (this.officefolders[i].import) {
          inboxes.push(this.officefolders[i]);
        };
      }
      this.UnsortedcallsApi.autoAssignMailOffice365(
        this.Account.id,
        this.Account.companyId,
        token,
        this.Account["365account"],
        date,
        inboxes
      ).subscribe(() => {
        this.Account.lastupdate365account = new Date();
        this.saveAccount();
        this.autoAssignPending = false;
      });
    }
  }

  public async autoAssignOne() {
    let token = await this.graphService.getAccessToken();
    if (this.selectedOfficeMail) {
      let callarray = [this.selectedOfficeMail]
      this.UnsortedcallsApi
        .sortmailOffice365(this.Account.id, this.Company.id, token, this.user.email, callarray)
        .subscribe(res => {
          console.log(res)
        })
    }
  }

  public async findCompanies() {

    this.searchunsortedcompanies = true;
    let token = await this.graphService.getAccessToken();
    if (!this.Account) { this.openSnackBar('Failed, Missing account info'), this.searchunsortedcompanies = false; return }
    this.UnsortedcallsApi.getUniqueRelations365(this.Account.id, this.Account.companyId, token, 0)
      .subscribe(async (companies: unsortedCompanies[]) => {
        this.unsortedCompanies = companies;

        for (let i = 1; i < 4; i++) {
          let newunsortcompany: unsortedCompanies[] = await this.UnsortedcallsApi.getUniqueRelations365(this.Account.id, this.Account.companyId, token, i).toPromise();
          this.unsortedCompanies = this.unsortedCompanies.concat(newunsortcompany);
          // console.log(newunsortcompany, this.unsortedCompanies);
          // console.log(i)
          if (i === 3) {
            this.searchunsortedcompanies = false;
          }
        }
      });
  }

  async importUnsortedCompanies() {
    let count = 0;
    for (let i = 0; i < this.unsortedCompanies.length; i++) {
      let company = this.unsortedCompanies[i];
      if (company.import) {
        count = count + 1;
        let newCompany = new Relations();
        newCompany.relationname = company.company;
        newCompany.address1 = company.address;
        newCompany.city = company.city;
        newCompany.stateprovince = company.stateprovince;
        newCompany.country = company.country;
        newCompany.website = company.website;
        newCompany.generalphone = company.phone;
        newCompany.zipcode = company.zipcode;
        newCompany.companyId = this.Company.id;

        let newContact = new Contactpersons();
        newContact.email = company.email;
        newContact.firstname = company.firstname;
        newContact.lastname = company.lastname;
        newContact.companyId = this.Company.id;

        this.CompanyApi.createRelations(this.Company.id, newCompany)
          .subscribe((relation: Relations) => {
            this.RelationApi.createContactpersons(relation.id, newContact).subscribe();
          })
      }
    }
    this.openSnackBar('Imported ' + count + ' new relation');
    let token = await this.graphService.getAccessToken();
    this.UnsortedcallsApi.autoAssignMailOffice365(
      this.Account.id,
      this.Company.id,
      token,
      this.Account["365account"],
      ''
    ).subscribe(() => { });
  }

  getOfficeMail(searchTerm?) {
    this.OfficeMail = [];
    if (!searchTerm) {
      this.graphService.getMails(this.skip, this.limit, searchTerm).then((res: any) => {
        //console.log(res);
        let mails: OfficeMail[] = res.value;
        this.OfficeMail = mails;
        this.Unsortedcallslength = res['@odata.count'];
      });
    } else {
      this.graphService.searchMails(this.skip, this.limit, searchTerm).then((res: any) => {
        //console.log(res);
        let mails: OfficeMail[] = res.value;
        this.OfficeMail = mails;
        this.Unsortedcallslength = res['@odata.count'];
      });
    }
  }

  toggleOfficeMail() {
    if (this.officetoggle === true) {
      //this.officetoggle = true;
      this.getOfficeMail()
    }
    else {
      //this.officetoggle = false;
      this.getUnCalls();
    }
  }

  onSelectOfficeMail(OfficeMail, i) {
    this.selectedStored = false;
    this.CompanyApi.countCalls(this.Company.id, { emailid: OfficeMail.internetMessageId }).subscribe(res => {
      if (res.count !== 0) {
        this.selectedStored = true
      }
    })
    this.selectedOfficeMail = OfficeMail;
  }

  openDialogDeleteOfficeMail() {
    this.dialogsService
      .confirm('Delete Call', 'Do you want to delete this Entry (this will also delete it in your Office 365)?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteOfficeMail(this.selectedOption)
      });
  }

  deleteOfficeMail(selectedOption: boolean): void {
    if (selectedOption == true) {
      this.graphService.deleteMail(this.selectedOfficeMail.id).then((res) => {
        this.selectedOfficeMail = undefined;
      });
    }
  }

  navigateInPage(pageid: number) {
    // compensate if admin for missing first two h2 headers
    if (!this.Account.companyadmin && pageid !== 0 && pageid !== 1) { pageid = pageid - 2 }
    let el = this.elementRef.nativeElement.querySelectorAll('h2')[pageid];
    let scrolldiv = document.getElementsByTagName("mat-sidenav-content")[0];
    let top = scrolldiv.scrollTop + el.getBoundingClientRect().top - 50;
    scrolldiv.scrollTo(0, top);
  }

  // getScrollParent(node) {
  //   if (node == null) {
  //     return null;
  //   }
  //   if (node.scrollHeight > node.clientHeight) {
  //     return node;
  //   } else {
  //     return this.getScrollParent(node.parentNode);
  //   }
  // }

  resetPassword() {
    this.accountApi.resetPassword({ email: this.resetEmailAddress }).subscribe();
    this.resetPasswordToggle = false;
    this.snackBar.open('Reset Email send', undefined, {
      duration: 4000,
      panelClass: 'snackbar-class'
    });
  }


  ExactAPI(){


  }

}
