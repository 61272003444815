import { Component, OnInit, Input } from '@angular/core';
import {
  Relations, RelationsApi, CompanyApi, Company, Account,
  Files, FilesApi, ContainerApi, Forms, FormsApi, Formentries, FormentriesApi, Calls
} from '../../../shared/sdk';
import { DialogsService } from './../../../dialogsservice/dialogs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormentrydetailsComponent } from './formentrydetails/formentrydetails.component';

@Component({
  selector: 'app-formentries',
  templateUrl: './formentries.component.html',
  styleUrls: ['./formentries.component.scss']
})
export class FormentriesComponent implements OnInit {
  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;
  @Input() selectedForm: Forms;

  public formEntries: Formentries[];

  constructor(
    //public previewDialog: previewService,
    public FormsApi: FormsApi,
    public snackBar: MatSnackBar,
    public RelationsApi: RelationsApi,
    public dialog: MatDialog,
    public dialogsService: DialogsService
  ) { }

  ngOnInit(): void {
    //this.getFormEntries();
  }

  getFormEntries() {
    this.FormsApi.getFormentries(this.selectedForm.id).subscribe((formentries: Formentries[]) => {
      this.formEntries = formentries;
    });
  }

  // dialog delete on yes activates deletePublications
  public deleteEntry(i) {
    this.dialogsService
      .confirm('Delete Entry', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.deleteForms(res, i);
      });
  }

  public openInDialog(entry) {
    console.log(entry);

    let message = '';
    entry.formcontent.forEach(element => {
      message = message + '<label class="mat-typography">' + element.text + ': ' + element.value + '</label><br>';
    });

    let date = new Date(entry.date);

    this.dialog.open(FormentrydetailsComponent, {
      width: '600px',
      data: {
        message: message,
        entry: entry,
        company: this.company

      },
    });


    // this.dialog.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });

    // this.dialogsService
    //   .confirm(date.toDateString(), null, message)
    //   .subscribe(res => {

    //   });
  }

  // delete Forms -> check container?
  deleteForms(selectedOption, i): void {
    if (selectedOption === true) {
      this.FormsApi.destroyByIdFormentries(this.selectedForm.id, this.formEntries[i].id)
        .subscribe(res => {
          this.getFormEntries();
        })
    }
  }

  replyToFormEntry(email: string, entry) {
    let message = '';
    entry.formcontent.forEach(element => {
      message = message + element.text + ': ' + element.value + ' \n';
    });
    this.createNewMessage(email, message);
  }


  createNewMessage(email, text): void {
    let call = new Calls();
    let signature = '';
    if (this.Account.signature) { signature = this.Account.signature; }


    let html = text;
    // '<p><span style="font-family:' + this.company.companyfont + '; font-size:' + this.company.companyfontsize + 'px;">Dear %recipient.firstname%, </span></p>' +
    //   '<br><p><span style="font-family:' + this.company.companyfont + '; font-size:' + this.company.companyfontsize + 'px;">&nbsp;</span></p>' + signature;

    let title = "Form Entry";
    this.sendMailTo(email, title, html)
    // this.RelationsApi.createCalls(relationId, {
    //   html: html,
    //   title: title,
    //   accountId: this.Account.id,
    //   companyId: this.Account.companyId,
    //   date: new Date()
    // }) //add companyId for statistics
    //   .subscribe(res => {
    //     //this.data = res,
    //     this.SendMessageDialog(contactperson);
    //   });
  }

  sendMailTo(email: string, title: string, html: string): void {
    let mail = "mailto:" + email + "?subject=" + title + "&body=" + html;
    window.open(mail, "_self");
  }


  createNewRelation() {

  }

  SendMessageDialog(contactperson?): void {
    // const dialogRef = this.dialog.open(TextEditorDialog, {
    //   width: '80%',
    //   height: '80%',
    //   data: this.selectedCall.html // changingThisBreaksApplicationSecurity,
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result !== undefined) {
    //     if (result.length > 0) {
    //       this.selectedCall.html = result;
    //       if (contactperson) {
    //         this.emailtosendto = [contactperson];
    //         this.selectedTab = 1;
    //         this.setNameValues();
    //       }
    //       this.saveCall();
    //       this.selectedCall.readytosend = true;
    //     };  //
    //   }
    // });
  }

}
