<h1 mat-dialog-title i18n>Input name</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label i18n>Name</mat-label>
        <input specialIsAlphaNumeric matInput [(ngModel)]="data.name"> 
        <!-- specialIsAlphaNumeric -->
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" i18n>Cancel</button>
    <button mat-button [mat-dialog-close]="data.name" cdkFocusInitial i18n>Ok</button>
</div>