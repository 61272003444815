

<form class="mat-typography">
<div *ngIf="errorstatus" class="linkmessage">
    <H4 i18n>LinkedIn Error</H4>
    <p>{{error}}</p>
</div>

<div *ngIf="messagestatus" class="linkmessage">
    <H3>{{message}}</H3>
</div>
</form>