import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogsService } from 'src/app/dialogsservice/dialogs.service';
import { Account, AccountApi } from 'src/app/shared/sdk';

interface pricing {
  monthly: number,
  yearly: number,
  onetime: number,
  total: number,
  trainingsupportprice: number,
  migrationsupportprice: number,
  go: number,
  enterprise: number,
  userprice: number,
  emailprice: number,
  marketingrelationaccounts: number,
  discount: number,
}

export interface Plansubscription {
  selectedcurrency: string;
  selectedplan: string;
  monthly: number;
  onetime: number;
  yearly: number;
  trainingsupport: boolean;
  migrationsupport: boolean;
  emailcount: number;
  additionalusers: number;
  additionalmarketingrelationaccounts: number;
  terms: string;
  discountcode: string;
}



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Input('account') Account: Account;
  @Output() emitterPlan: EventEmitter<Plansubscription> = new EventEmitter();
  @Output() emitterBack: EventEmitter<string> = new EventEmitter();

  public showmore = false;
  count: number;
  // extrausers = false;
  // extramarketingrelationaccounts = false
  autoTicks = false;
  showTicks = false;
  step = 1000;
  //addemails = false;
  training = 999;
  migration = 499;
  emailprice = 5;
  userprice = 10;
  marketingrelationprice = 100;
  standard = 49;
  go = 99;
  enterprise = 899;
  hide = false;
  // curex = 1.15;
  public error;
  detect: boolean = true
  baddiscount: boolean;
  gooddiscount: boolean;
  // discountcode: string;
  discountvalue: number;
  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 1;
  public selectedIndex = 0;
  public discount = false;

  public plan: Plansubscription = {
    selectedcurrency: 'EUR',
    selectedplan: 'go',
    monthly: 99,
    onetime: 0,
    yearly: 0,
    trainingsupport: false,
    migrationsupport: false,
    emailcount: 0,
    additionalusers: 0,
    additionalmarketingrelationaccounts: 0,
    terms: 'monthly',
    discountcode: undefined
  }

  constructor(
    public accountApi: AccountApi,
    public snackBar: MatSnackBar,
    public dialogsService: DialogsService,
  ) { }

  ngOnInit(): void {
    this.updateplan();
  }

  checkDiscount() {
    this.discountvalue = undefined
    this.baddiscount = undefined;
    this.gooddiscount = undefined;
    this.accountApi.checkDiscount(this.plan.discountcode).subscribe(res => {
      console.log(res)
      if (res) {
        this.gooddiscount = true;
        this.discountvalue = res;
        this.updateplan();
      }
    }, error => {
      console.log(error);
      this.baddiscount = true;
    })
  }

  updateplan(value?) {
    // console.log(value);
    // if (value){
    //   this.plan.selectedplan = value;
    // }

    if (this.plan.selectedplan === 'enterprise') {
      this.plan.terms = 'monthly'
    }

    this.accountApi.calculateplan(
      this.plan.selectedcurrency,
      this.plan.selectedplan,
      this.plan.terms,
      this.plan.trainingsupport,
      this.plan.migrationsupport,
      this.plan.emailcount,
      this.plan.additionalusers,
      this.plan.additionalmarketingrelationaccounts,
      this.plan.discountcode
    ).subscribe((amount: pricing) => {
      this.plan.monthly = amount.monthly;
      this.plan.yearly = amount.yearly;
      this.plan.onetime = amount.onetime;

      this.emailprice = amount.emailprice;
      this.userprice = amount.userprice;
      this.marketingrelationprice = amount.marketingrelationaccounts;
      this.go = amount.go;
      this.enterprise = amount.enterprise;
    });

  }


  snackbarMessage(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 4000,
      panelClass: 'snackbar-class'
    });
  }

  emitPlan() {
    // CHeck ALL values!


    if (!this.plan.selectedcurrency) {
      console.log('missing currency!');
      this.snackbarMessage('missing currency!'); return;
    }
    if (!this.plan.selectedplan) {
      console.log('missing selected plan!');
      this.snackbarMessage('missing selected plan!'); return;
    }
    if (!this.plan.terms) {
      console.log('missing this.terms!');
      this.snackbarMessage('missing terms!'); return;
    }
    //standard false 
    if (this.plan.trainingsupport === undefined) {
      console.log('missing this.trainingsupport!');
      this.snackbarMessage('missing trainingsupport!'); return;
    }
    if (this.plan.migrationsupport === undefined) {
      console.log('missing this.migrationsupport!');
      this.snackbarMessage('missing migrationsupport!'); return;
    }
    if (this.plan.emailcount === undefined) {
      console.log('missing  this.emailcount!');
      this.snackbarMessage('missing  emailcount!'); return;
    }
    if (this.plan.additionalusers === undefined) {
      console.log('missing this.additionalusers!');
      this.snackbarMessage('missing additionalusers!'); return;
    }
    if (this.plan.additionalmarketingrelationaccounts === undefined) {
      console.log('missing this.additionalmarketingrelationaccounts!');
      this.snackbarMessage('missing additional marketingrelationaccounts!'); return;
    }
    //if (this.discountcode){ this.plan.discountcode = this.discountcode}
    this.emitterPlan.emit(this.plan);
  }


  emitBack() {
    this.emitterBack.emit('login');
  }

}
