/* tslint:disable */
import {
  Mailinglist,
  Link
} from '../index';

declare var Object: any;
export interface MailinglistentriesInterface {
  "email": string;
  "name"?: string;
  "company"?: string;
  "firstname"?: string;
  "lastname"?: string;
  "signature"?: string;
  "companyId"?: string;
  "title"?: string;
  "website"?: string;
  "const1"?: string;
  "const2"?: string;
  "const3"?: string;
  "const4"?: string;
  "mailing"?: string;
  "unsubscribe"?: boolean;
  "mailingId"?: string;
  "id"?: any;
  "mailinglistId"?: any;
  mailinglist?: Mailinglist;
  link?: Link[];
}

export class Mailinglistentries implements MailinglistentriesInterface {
  "email": string;
  "name": string;
  "company": string;
  "firstname": string;
  "lastname": string;
  "signature": string;
  "companyId": string;
  "title": string;
  "website": string;
  "const1": string;
  "const2": string;
  "const3": string;
  "const4": string;
  "mailing": string;
  "unsubscribe": boolean;
  "mailingId": string;
  "id": any;
  "mailinglistId": any;
  mailinglist: Mailinglist;
  link: Link[];
  constructor(data?: MailinglistentriesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Mailinglistentries`.
   */
  public static getModelName() {
    return "Mailinglistentries";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Mailinglistentries for dynamic purposes.
  **/
  public static factory(data: MailinglistentriesInterface): Mailinglistentries{
    return new Mailinglistentries(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Mailinglistentries',
      plural: 'Mailinglistentries',
      path: 'Mailinglistentries',
      idName: 'id',
      properties: {
        "email": {
          name: 'email',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'string'
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "signature": {
          name: 'signature',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "const1": {
          name: 'const1',
          type: 'string'
        },
        "const2": {
          name: 'const2',
          type: 'string'
        },
        "const3": {
          name: 'const3',
          type: 'string'
        },
        "const4": {
          name: 'const4',
          type: 'string'
        },
        "mailing": {
          name: 'mailing',
          type: 'string'
        },
        "unsubscribe": {
          name: 'unsubscribe',
          type: 'boolean'
        },
        "mailingId": {
          name: 'mailingId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "mailinglistId": {
          name: 'mailinglistId',
          type: 'any'
        },
      },
      relations: {
        mailinglist: {
          name: 'mailinglist',
          type: 'Mailinglist',
          model: 'Mailinglist',
          relationType: 'belongsTo',
                  keyFrom: 'mailinglistId',
          keyTo: 'id'
        },
        link: {
          name: 'link',
          type: 'Link[]',
          model: 'Link',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'mailinglistentriesId'
        },
      }
    }
  }
}
