<speed-dial-fab matTooltip-i18n matTooltip="Add new message" class="speed-dial-container" [reverse-column-direction]="true"
  (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons">
</speed-dial-fab>
<div class="listshowcontainer" fxShow fxHide.gt-xs>
  <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
  <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
  <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
    [class.listview-xs-show]="listviewxsshow">

    <h4 i18n>Search</h4>
    <mat-form-field appearance="outline">
      <mat-label i18n>Search</mat-label>
      <input matInput #searchTerm id="seach-box" (keyup.enter)="searchChannels(searchTerm.value)"
        i18n-placeholder placeholder="Channel" />
    </mat-form-field>

    <button a mat-raised-button color="primary" (click)="searchChannels(searchTerm.value)">
      <mat-icon>search</mat-icon>Search Result
    </button>
    <button a mat-raised-button (click)="getChannels()">Show All</button>


    <div class="listbox channelbox" *ngFor="let Channel of Channels; let i = index" (click)="onSelectChannels(Channel)"
      [class.selected]="Channel === selectedChannel">
      <div fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <mat-icon *ngIf="Channel.type === 'facebook'" svgIcon="enydea_facebook" class="iconsize-16"></mat-icon>
          <mat-icon *ngIf="Channel.type === 'twitter'" svgIcon="enydea_twitter" class="iconsize-16"></mat-icon>
          <mat-icon *ngIf="Channel.type === 'linkedin'" svgIcon="enydea_linkedin" class="iconsize-16"></mat-icon>
          <mat-icon *ngIf="Channel.type === 'instagram'" svgIcon="enydea_instagram" class="iconsize-16"></mat-icon>
          <mat-icon *ngIf="Channel.type === 'pinterest'" svgIcon="enydea_pinterest" class="iconsize-16"></mat-icon>
          <span style="margin-left: 5px">{{Channels.title}}</span>

          <mat-icon *ngIf="Channel.primarychannel" class="iconsize-14">bookmark</mat-icon>
          <mat-icon *ngIf="Channel.recurrence" class="iconsize-14">autorenew</mat-icon>
          <mat-icon *ngIf="Channel.scheduled" class="iconsize-14">calendar_today</mat-icon>
          <mat-icon *ngIf="Channel.send" class="iconsize-14">done</mat-icon>
          <mat-icon *ngIf="!Channel.send && !Channel.scheduled && !Channel.recurrence && !Channel.primarychannel" class="iconsize-14">pending_actions</mat-icon>
        </div>
      </div>
    </div>

  </div>

  <!-- on-focusout="saveChannel()" (onblur)="saveChannel()" -->
  <div class="relationcontainer" *ngIf="selectedChannel" ngClass.gt-xs="container-normal" 
    ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow" >

    <!-- standard channel layout -->
    <mat-card>
      <mat-toolbar class="titlebox" fxLayout="row">
        <h2 fxFlex="90%">{{selectedChannel.title | uppercase}}</h2>
        <mat-menu fxFLex="10%" #menucall="matMenu">
          <button mat-menu-item (click)="openDialogDeleteChannel()">
            <mat-icon>delete</mat-icon>
            <span i18n>Delete</span>
          </button>
        </mat-menu>
        <button fxFLex="10%" mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-toolbar>

      <mat-form-field appearance="outline" class="form-full-width" *ngIf="!selectedChannel.send">
        <mat-label i18n>Title</mat-label>
        <input matInput [(ngModel)]="selectedChannel.title" [disabled]="selectedChannel.scheduled" i18n-placeholder placeholder="Title"
          class="InputField">
      </mat-form-field>

      <mat-accordion *ngIf="!selectedChannel.send">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title i18n>
              Set Date
            </mat-panel-title>
          </mat-expansion-panel-header>


          
          <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
             
                <mat-form-field appearance="outline" fxFlex="48%" class="form-full-width">
                  <mat-label i18n>Date/Start Date</mat-label>
                  <input matInput [matDatepicker]="myDatepicker5" i18n-placeholder placeholder="(start/send) date"
                    [(ngModel)]="selectedChannel.date">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker5"></mat-datepicker-toggle>
                  <mat-datepicker matTooltip-i18n matTooltip="Send day or the day a recurrent event should start" #myDatepicker5
                    class="form-half-width"></mat-datepicker>
                </mat-form-field>
      
                <mat-form-field appearance="outline" *ngIf="selectedChannel.recurrence" fxFlex="48%" class="form-full-width">
                  <mat-label matTooltip-i18n matTooltip="End date of recurrent event" i18n>End date</mat-label>
                  <input matInput [matDatepicker]="myDatepicker6" i18n-placeholder placeholder="(start/send) date"
                    [(ngModel)]="selectedChannel.endday">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker6"></mat-datepicker-toggle>
                  <mat-datepicker matTooltip-i18n matTooltip="Send day or the day a recurrent event should end" #myDatepicker6
                    class="form-half-width"></mat-datepicker>
                </mat-form-field>

            </div>

            <mat-checkbox fxFlex="48%" matInput [(ngModel)]=selectedChannel.recurrence i18n>
              Recurrence
            </mat-checkbox>
            <br>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="20px">
     
              <mat-form-field appearance="outline" fxFlex="48%" class="form-full-width"
                *ngIf="selectedChannel.recurrence">
                <mat-label>Send Every</mat-label>
                <mat-select matInput [(ngModel)]=selectedChannel.interval i18n-placeholder placeholder="Interval" class="InputField">
                  <mat-option matInput value="month" i18n>Every Month</mat-option>
                  <mat-option matInput value="week" i18n>Every Week</mat-option>
                  <mat-option matInput value="day" i18n>Every Day</mat-option>
                  <mat-option matInput value="year" i18n>Every Year</mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearance="outline" fxFlex="48%" class="form-full-width" *ngIf="selectedChannel.recurrence && selectedChannel.interval === 'week'">
                <mat-select matInput [(ngModel)]=selectedChannel.dayinterval i18n-placeholder placeholder="Day of the Week"
                  class="InputField">
                  <mat-option matInput value=1 i18n>Monday</mat-option>
                  <mat-option matInput value=2 i18n>Tuesday</mat-option>
                  <mat-option matInput value=3 i18n>Wednesday</mat-option>
                  <mat-option matInput value=4 i18n>Thursday</mat-option>
                  <mat-option matInput value=5 i18n>Friday</mat-option>
                  <mat-option matInput value=6 i18n>Saturday</mat-option>
                  <mat-option matInput value=7 i18n>Sunday</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="form-full-width" fxFlex="48%"  
              *ngIf="selectedChannel.recurrence && selectedChannel.interval === 'year' ">
                <mat-label>Month</mat-label>
                <mat-select matInput [(ngModel)]=selectedChannel.monthinterval i18n-placeholder placeholder="Month" class="InputField">
                  <mat-option matInput value=1>1</mat-option>
                  <mat-option matInput value=2>2</mat-option>
                  <mat-option matInput value=3>3</mat-option>
                  <mat-option matInput value=4>4</mat-option>
                  <mat-option matInput value=5>5</mat-option>
                  <mat-option matInput value=6>6</mat-option>
                  <mat-option matInput value=7>7</mat-option>
                  <mat-option matInput value=8>8</mat-option>
                  <mat-option matInput value=9>9</mat-option>
                  <mat-option matInput value=10>10</mat-option>
                  <mat-option matInput value=11>11</mat-option>
                  <mat-option matInput value=12>12</mat-option>
                </mat-select>
              </mat-form-field> 

              <mat-form-field appearance="outline" class="form-full-width" fxFlex="48%" 
              *ngIf="selectedChannel.recurrence && selectedChannel.interval === 'year' ">
                <mat-label>Day of the Month</mat-label>
                <mat-select matInput [(ngModel)]=selectedChannel.dayofmonth i18n-placeholder placeholder="Month" class="InputField">
                  <mat-option matInput value=1>1</mat-option>
                  <mat-option matInput value=2>2</mat-option>
                  <mat-option matInput value=3>3</mat-option>
                  <mat-option matInput value=4>4</mat-option>
                  <mat-option matInput value=5>5</mat-option>
                  <mat-option matInput value=6>6</mat-option>
                  <mat-option matInput value=7>7</mat-option>
                  <mat-option matInput value=8>8</mat-option>
                  <mat-option matInput value=9>9</mat-option>
                  <mat-option matInput value=10>10</mat-option>
                  <mat-option matInput value=11>11</mat-option>
                  <mat-option matInput value=12>12</mat-option>
                  <mat-option matInput value=13>13</mat-option>
                  <mat-option matInput value=14>14</mat-option>
                  <mat-option matInput value=15>15</mat-option>
                  <mat-option matInput value=16>16</mat-option>
                  <mat-option matInput value=17>17</mat-option>
                  <mat-option matInput value=18>18</mat-option>
                  <mat-option matInput value=19>19</mat-option>
                  <mat-option matInput value=20>20</mat-option>
                  <mat-option matInput value=21>21</mat-option>
                  <mat-option matInput value=22>22</mat-option>
                  <mat-option matInput value=23>23</mat-option>
                  <mat-option matInput value=24>24</mat-option>
                  <mat-option matInput value=25>25</mat-option>
                  <mat-option matInput value=26>26</mat-option>
                  <mat-option matInput value=27>27</mat-option>
                  <mat-option matInput value=28>28</mat-option>
                  <mat-option matInput value=29>29</mat-option>
                  <mat-option *ngIf="selectedChannel.monthinterval !== '2'" matInput value=30>30</mat-option>
                  <mat-option 
                  *ngIf="selectedChannel.monthinterval !== '2' || selectedChannel.monthinterval !== '4' || selectedChannel.monthinterval !== '6' || selectedChannel.monthinterval !== '9' || selectedChannel.monthinterval !== '11'" matInput value=31>31</mat-option>
                </mat-select>
              </mat-form-field> 

              <mat-form-field appearance="outline" class="form-full-width" fxFlex="48%"  
              *ngIf="selectedChannel.recurrence && selectedChannel.interval === 'month' ">
                <mat-label>Select the weekday of the month</mat-label>
                <mat-select matInput [(ngModel)]=selectedChannel.weekmonthinterval i18n-placeholder placeholder="Month" class="InputField">
                  <mat-option matInput value=1>1st</mat-option>
                  <mat-option matInput value=2>2nd</mat-option>
                  <mat-option matInput value=3>3rd</mat-option>
                  <mat-option matInput value=4>4th</mat-option>
                  <mat-option matInput value=5>Last</mat-option>
                </mat-select>
              </mat-form-field> 

              <mat-form-field appearance="outline" class="form-full-width" fxFlex="48%"  
              *ngIf="selectedChannel.recurrence && selectedChannel.interval === 'month' ">
                <mat-label>On the</mat-label>
                <mat-select matInput [(ngModel)]=selectedChannel.daymonthinterval i18n-placeholder placeholder="Month" class="InputField">
                  <mat-option matInput i18n value=1>Monday</mat-option>
                  <mat-option matInput i18n value=2>Tuesday</mat-option>
                  <mat-option matInput i18n value=3>Wednesday</mat-option>
                  <mat-option matInput i18n value=4>Thursday</mat-option>
                  <mat-option matInput i18n value=5>Friday</mat-option>
                  <mat-option matInput i18n value=6>Saturday</mat-option>
                  <mat-option matInput i18n value=7>Sunday</mat-option>
                </mat-select>
              </mat-form-field> 

            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
              <mat-form-field appearance="outline" fxFlex="48%">
                <mat-label>Timezone</mat-label>
                <mat-select i18n-placeholder placeholder="Timezone" [(ngModel)]="selectedChannel.timezone">
                  <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                    {{ timezone.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="48%">
                <mat-label>Time</mat-label>
                <input matInput [(ngModel)]=selectedChannel.timeinterval i18n-placeholder placeholder="Time" class="InputField"
                  type="time">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" *ngIf="selectedChannel.recurrence">
            

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <br>
      <mat-form-field appearance="outline" style="width:100%;" >
        <mat-label i18n>URL/Weblink (optional)</mat-label>
        <input style="width:100%;" matInput [(ngModel)]="selectedChannel.shareurl" i18n-placeholder placeholder="url"
        [disabled]="selectedChannel.send">
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-label i18n>Text</mat-label>
        <textarea style="width:100%; min-height: 100px;" matInput #channeltext [(ngModel)]="selectedChannel.text"
          i18n-placeholder placeholder="content" i18n [disabled]="selectedChannel.send">Text Version</textarea>
        <mat-hint align="end">{{channeltext.value.length}}</mat-hint>
      </mat-form-field>
      <br>
      <mat-icon matTooltip-i18n matTooltip="Insert Emoji" (click)="onshowemoji()" *ngIf="selectedChannel.send">
        insert_emoticon</mat-icon>
      <div fxLayout="row" fxLayout.xs="column" *ngIf="showemoji" >
        <div fxFlex>
          <div style="width: 100%;
                height: 100%;
                position: absolute;
                top: 8;
                left: 20;
                z-index: 20;">
            <emoji-mart (emojiClick)="setemoji($event)" set="apple" title="enydea">
            </emoji-mart>
          </div>
        </div>
      </div>
      <br>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-icon>videocam</mat-icon>
                <mat-icon>crop_original</mat-icon>
                <label i18n>Add media</label>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
              <label>Picture</label>
              <div fxFlex="48%" *ngIf="selectedChannel.pictureurl">
                <img [src]="selectedChannel.pictureurl" style="max-width: 400px;">
                <mat-icon (click)="selectedChannel.pictureurl = ''" class="icon-label" class="iconsize-12" *ngIf="!selectedChannel.send">
                  delete</mat-icon>
              </div>
              <div fxFlex="48%" *ngIf="!selectedChannel.send">
                <app-fileupload *ngIf="!selectedChannel.pictureurl" [account]="Account"
                  [option]="option" (imgurl)="setImage($event)" >
                </app-fileupload>
              </div>
            </div>

            <div style="border-bottom:1px dashed black; margin: 10px;"></div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
              <label>Video</label>
              <div fxFlex="48%"  *ngIf="selectedChannel.videourl">
                <video preload="auto" id="videoObject1" controls [src]="selectedChannel.videourl" type="video/mp4" style="max-width: 400px;">
                </video>
                <mat-icon (click)="selectedChannel.videourl = ''" class="icon-label" class="iconsize-12" *ngIf="!selectedChannel.send">
                  delete</mat-icon>
              </div>

              <div fxFlex="48%" *ngIf="!selectedChannel.send">
                <app-videoupload *ngIf="!selectedChannel.videourl" [account]="Account"
                  [option]="option" (imgurl)="setVideo($event)">
                </app-videoupload>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <br>

      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="space-between"
        *ngIf="selectedChannel.type === 'linkedin'">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select Linkedin Account" [(ngModel)]="selectedChannel.channelsendaccountid"
            (selectionChange)="getLinkedinCompany($event.source.value.accesstoken)" [disabled]="selectedChannel.send">
            <mat-option value="">None</mat-option> 
            <mat-option *ngFor="let linkedin of Linkedin" [value]="linkedin.id">
                {{ linkedin.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select companypage Account" [(ngModel)]="selectedChannel.companypage" [disabled]="selectedChannel.send">
              <mat-option value="">None</mat-option>
              <mat-option *ngFor="let companypage of companypage" [value]="companypage.URN">
                {{ companypage.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <button a mat-raised-button (click)="getLinkedinAccount()" *ngIf="selectedChannel.send">Check account</button>
        <br>
        <button a mat-raised-button *ngIf="!selectedChannel.send" 
          (click)="postToLinkedinCompanyPage()">Publish to Company Page</button>
      </div>

      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="space-between"
        *ngIf="selectedChannel.type === 'twitter'">
        <mat-form-field appearance="outline">
          <mat-select i18n-placeholder placeholder="Select Twitter Account" [(ngModel)]="twitteroption" [disabled]="selectedChannel.send">
            <mat-option value="">None</mat-option>
            <mat-option  *ngFor="let Twitter of Twitter" [value]="Twitter">
              {{ Twitter.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" *ngIf="selectedChannel.send">
          <mat-icon matBadge="{{selectedChannel.likes}}">sentiment_satisfied_alt</mat-icon>
          <label  i18n>Favorites</label>
          <mat-icon matBadge="{{selectedChannel.shared}}">cached</mat-icon>Retweets
        </div>
        <br>
        <button a mat-raised-button *ngIf="!selectedChannel.send" (click)="postToTwitter()" [disabled]="" >Tweet</button>
      </div>

      <div *ngIf="selectedChannel.type === 'facebook'" fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select Facebook Account" 
            [(ngModel)]="selectedChannel.channelsendaccountid" [disabled]="selectedChannel.send" 
            (selectionChange)="getFacebookCompany()">
            <mat-option value="">None</mat-option>
              <mat-option *ngFor="let facebook of Facebook" [value]="facebook.id">
                {{ facebook.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select companypage Account" 
            (selectionChange)="setFacebookCompanyToken()" [(ngModel)]="selectedChannel.companypage">
              <mat-option value="">None</mat-option>
              <mat-option *ngFor="let page of facebookPages" [value]="page.id">
                {{ page.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <mat-progress-bar *ngIf="waitingforfbpages" mode="indeterminate"> </mat-progress-bar>
        <button a mat-raised-button *ngIf="!selectedChannel.send" (click)="postToFacebookPage()" i18n>Post to
          page</button>
      </div>

      <div *ngIf="selectedChannel.type === 'instagram'" fxLayout="column">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select Facebook Account" 
            [(ngModel)]="selectedChannel.channelsendaccountid" [disabled]="selectedChannel.send" 
            (selectionChange)="getInstagramAccounts()">
            <mat-option value="">None</mat-option>
              <mat-option *ngFor="let facebook of Facebook" [value]="facebook.id">
                {{ facebook.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select companypage Account" 
            (selectionChange)="setFacebookCompanyToken()" [(ngModel)]="selectedChannel.companypage">
              <mat-option value="">None</mat-option>
              <mat-option *ngFor="let page of instagramAccounts" [value]="page.id">
                {{ page.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <mat-progress-bar *ngIf="waitingforfbpages" mode="indeterminate"> </mat-progress-bar>
        <button a mat-raised-button *ngIf="!selectedChannel.send" (click)="postToFacebookPage()" i18n>Post to
          page</button>
      </div>

      <div fxLayout="column" *ngIf="selectedChannel.type === 'pinterest'">
        <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="20px">
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select Pinterest Board" [disabled]="selectedChannel.send" 
            [(ngModel)]="selectedChannel.channelsendaccountid" (selectionChange)="getPinterestBoard()">
            <mat-option value="">None</mat-option>
            <mat-option  *ngFor="let Pinterest of Pinterest" 
                [value]="Pinterest.id">
                {{ Pinterest.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-select i18n-placeholder placeholder="Select pinterest board" [(ngModel)]="selectedChannel.board" [disabled]="selectedChannel.send">
              <mat-option value="">None</mat-option>
              <mat-option *ngFor="let pinterestboard of pinterestboard" [value]="pinterestboard.id" >
                {{ pinterestboard.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <mat-progress-bar *ngIf="waitingforfbpages" mode="indeterminate"></mat-progress-bar>
        <br>
        <button a mat-raised-button *ngIf="!selectedChannel.send" (click)="posttoPinterestBoard()" i18n>Pin</button>
      </div>

      <br>

      <div fxLayoutGap="20px">
        <button a mat-raised-button *ngIf="!selectedChannel.send" [disabled]="selectedChannel.scheduled"
          (click)="scheduleChannel()" i18n>Schedule</button>
        <button a mat-raised-button *ngIf="!selectedChannel.send" [disabled]="selectedChannel.scheduled"
          (click)="saveChannel()" i18n >Save</button>
        <button a mat-raised-button *ngIf="!selectedChannel.send"
          (click)="updateChannel()" i18n>Update</button>
      </div>

    </mat-card>


  </div>



</div>