<div>
    <div fxLayout="row" fxLayoutGap="20px" class="top-controls">
        <mat-form-field appearance="outline">
            <mat-label i18n>Name</mat-label>
            <input matInput i18n-placeholder placeholder="Name" [(ngModel)]="videospec.name">
        </mat-form-field>
        <br>

        <div>
            <app-audioupload *ngIf="!videospec.audiosource" [account]="Account" [option]="option"
                (imgurl)="setAudio($event)">
            </app-audioupload>
        </div>


        <button mat-icon-button [matMenuTriggerFor]="menueditvideos" class="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menueditvideos="matMenu">
            <button mat-menu-item (click)="openGallery()">
                <mat-icon>note_add</mat-icon>
                <span i18n>Load from File</span>
            </button>
            <button mat-menu-item (click)="saveAndConvert()">
                <mat-icon>video_library</mat-icon>
                <span i18n>Create Video</span>
            </button>
            <button mat-menu-item (click)="save()">
                <mat-icon>save</mat-icon>
                <span i18n>Save</span>
            </button>
            <button mat-menu-item (click)="saveAs()">
                <mat-icon>save</mat-icon>
                <span i18n>Save as</span>
            </button>
        </mat-menu>

    </div>

    <div class="maincontainer">

        <div *ngIf="videospec" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="videogallery">
            <!-- <div style="min-width:400px; height: 200px;"></div> -->
            <div *ngFor="let videosegment of videospec.segments; let i = index;" fxLayout="column">

                <div *ngIf="videosegment.videourl && videosegment === selectedvideosegment" [@fadeInOut]
                    class="segmentsclass" (click)="onselectedvideosegment(videosegment)">
                    <app-videoplayer [url]="videosegment.blobvideo" [options]="videooptions"
                        (finalduration)="setDuration($event, videosegment)"></app-videoplayer>
                    <div *ngIf="videosegment.videourl" fxLayout="row" fxLayoutGap="20px">
                        <mat-icon matTooltip="delete video" (click)="videosegment.videourl = ''" class="iconsize-10">
                            delete
                        </mat-icon>
                        <mat-icon matTooltip="delete segment" (click)="deleteSegment(i)" class="iconsize-10">
                            delete
                        </mat-icon>
                        <mat-icon matTooltip="Move  left" (click)="moveleft(i)" class="iconsize-16">
                            arrow_back_ios
                        </mat-icon>
                        <mat-icon matTooltip="Move right" (click)="moveright(i)" class="iconsize-16">
                            arrow_forward_ios
                        </mat-icon>
                        <mat-select i18n-placeholder placeholder="Fade effect" [(ngModel)]="videosegment.transitiontype" name="item">
                            <mat-option *ngFor="let effect of fadeEffects" [value]="effect.value">
                                {{effect.name}}
                            </mat-option>
                        </mat-select>
                        <mat-form-field>
                            <input matInput i18n-placeholder placeholder="start fade in seconds from start"
                                [(ngModel)]="videosegment.offset">
                        </mat-form-field>
                    </div>

                </div>
                <div *ngIf="videosegment.videourl && videosegment !== selectedvideosegment" class="previewsegment"
                    (click)="onselectedvideosegment(videosegment)" fxLayoutAlign="center center">
                    <app-videoplayer [url]="videosegment.blobvideo" [nocontrols]="true"
                        (finalduration)="setDuration($event, videosegment)"></app-videoplayer>
                </div>
                <div *ngIf="!videosegment.videourl && videosegment == selectedvideosegment" [@fadeInOut]
                    class="segmentsclass" (click)="onselectedvideosegment(videosegment)">
                    <app-videoupload *ngIf="!videosegment.videourl" [account]="Account" [option]="option"
                        (imgurl)="setVideo($event, videosegment)" [accordion]="true">
                    </app-videoupload>
                    <mat-progress-bar mode="determinate" color="blue" [value]="bufferValue">
                    </mat-progress-bar>

                </div>
                <div *ngIf="!videosegment.videourl && videosegment !== selectedvideosegment"
                    (click)="onselectedvideosegment(videosegment)">
                    <div class="emptyvideo" fxLayoutAlign="center center">
                        <label i18n>No video</label>
                    </div>
                </div>
            </div>
            <button mat-fab (click)="addSegment()">
                <mat-icon>add</mat-icon>
            </button>
            <div style="min-width:200px; height: 200px;"></div>
        </div>

        <div class="overallcontroll">

        </div>

    </div>
    <br>


</div>