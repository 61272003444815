import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { DialogsService } from 'src/app/dialogsservice/dialogs.service';
import { GeneralsettingsComponent } from 'src/app/imaging/videocreator/popupscreens/generalsettings/generalsettings.component';
import { Mailing, Relations, RelationsApi } from 'src/app/shared/sdk';
import { timezones, Timezones_List } from 'src/app/shared/timezones';

@Component({
  selector: 'app-editmailing',
  templateUrl: './editmailing.component.html',
  styleUrls: ['./editmailing.component.scss']
})
export class EditmailingComponent implements OnInit {
  toggletextview: boolean;
  htmlpreview: any;
  public minDate = new Date();
  public maxDate = new Date(2030, 0, 1);
  public timezones = Timezones_List;
  Mailing: Mailing[];
  CampaignMailing: Mailing;
  option: Relations;
  copyfrommailing: any;


  hours = ['' ,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,48]

  constructor(
    public RelationsApi: RelationsApi,
    private sanitizer: DomSanitizer,
    public dialogsService: DialogsService,
    public dialogRef: MatDialogRef<GeneralsettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { 
    this.CampaignMailing = data.mailing;
    this.option = data.option;

  }

  ngOnInit(): void {
    const day = moment().date()
    const month = moment().month()
    const year = moment().year()
    this.minDate = new Date(year, month, day);
    this.maxDate = new Date(2030, 0, 1);
    this.getMailing();
  }

  public toggleTextView(): void {
    if (this.toggletextview === true) {
      this.toggletextview = false
    }
    else { this.toggletextview = true }
  }

  public showMailPreview(): void {
    this.htmlpreview = this.sanitizer.bypassSecurityTrustHtml(this.CampaignMailing.html);
    this.dialogsService
      .confirm('', '', this.htmlpreview)
      .subscribe();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(){
    this.dialogRef.close(this.CampaignMailing);
  }


  getMailing(): void {
    // select only mailings that are asigned to campaign (marketingplannereventsids)
    this.RelationsApi.getMailing(this.option.id,
      {
        order: 'id DESC',
        where: {
          marketingplannereventsIds: {
            exists: false
          }
        },
        include: {
          relation: 'mailinglist'
        }
      })
      .subscribe((mailing: Mailing[]) => this.Mailing = mailing);
  }


    // copy from existing mails
    copyFromMailingCampaign(): void {
      this.CampaignMailing.html = this.copyfrommailing.html;
      this.CampaignMailing.subject = this.copyfrommailing.subject;
      this.CampaignMailing.to = this.copyfrommailing.to;
      this.CampaignMailing.from = this.copyfrommailing.from;
      this.CampaignMailing.title = this.copyfrommailing.title;
      // this.CampaignMailing.sectionStyle = this.copyfrommailing.sectionStyle;
      // this.CampaignMailing.templatearray = this.copyfrommailing.templatearray;
      // this.CampaignMailing.columnStyle = this.copyfrommailing.columnStyle;
      this.CampaignMailing.relationname = this.copyfrommailing.relationname,
        this.CampaignMailing.preview = this.copyfrommailing.preview,
        this.CampaignMailing.companyId = this.copyfrommailing.companyId
    }
  
}
