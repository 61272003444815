import {
  ElementRef, AfterViewInit, Component, OnDestroy
} from '@angular/core';
import {
  AccountApi,
  Account,
  Logger,
  LoggerApi,
  CompanyApi,
  RealTime,
  LoopBackConfig,
  UnsortedcallsApi,
  Company, Linkedin, LinkedinApi, Facebook, FacebookApi, Pinterest, Twitter, Pushsubscriptions, LoopBackAuth, FireLoopRef
} from './shared/sdk';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { PwaService } from './pwa.service';
import { SwUpdate } from '@angular/service-worker';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  API_VERSION,
  VAPID_PUBLIC_KEY,
  BASE_URL
} from './shared/base.api';
import { PLATFORM_ID } from '@angular/core';
import { AuthService, User } from './settings/auth365.service';
import { SwPush } from '@angular/service-worker';
import { Inject, Output, EventEmitter } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScrollTrigger } from 'src/assets/js/all';
import { gsap } from 'src/assets/js/all';
gsap.registerPlugin(ScrollTrigger);
import { GuidedTour, GuidedTourService, OrientationConfiguration, TourStep } from 'ngx-guided-tour';
import { TourService } from './shared/tour/tour.service'
import { DialogsService } from './dialogsservice/dialogs.service';
import { GraphService } from './settings/graph365/graph.service';
import { AitoolComponent } from './marketing/marketingpublications/aitool/aitool.component';
import { MatDialog } from '@angular/material/dialog';
import { AppserviceService } from './appservice.service';
import { indexOf } from 'core-js/core/array';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnDestroy {
  languages = [
    { code: 'en', label: 'English' },
    { code: 'nl', label: 'Nederlands' },
    { code: 'fr', label: 'Française' },
    { code: 'de', label: 'Deutsch' }
  ];
  public scrolleffect = false;
  public Account: Account = new Account();
  public position = false;
  public elementRef: ElementRef;
  public showmessages = false;
  public messagecount: number;
  public logger: Logger[];
  public Company: Company;
  public sidenavopen = false;
  public isDarkTheme: boolean = false;
  // listenFunc will hold the function returned by "renderer.listen"
  listenFunc: Function;
  // globalListenFunc will hold the function returned by "renderer.listenGlobal"
  globalListenFunc: Function;
  loginpage: boolean;
  selectedlanguage: string;



  constructor(
    private loggerApi: LoggerApi,
    public appservice: AppserviceService,
    public dialog: MatDialog,
    private graphService: GraphService,
    private auth: LoopBackAuth,
    public dialogsService: DialogsService,
    public TourService: TourService,
    public GuidedTourService: GuidedTourService,
    private UnsortedcallsApi: UnsortedcallsApi,
    private FacebookApi: FacebookApi,
    private LinkedinApi: LinkedinApi,
    public snackBar: MatSnackBar,
    private swPush: SwPush,
    private authService: AuthService,
    //@Inject(PLATFORM_ID) private platformId: string,

    private _bottomSheet: MatBottomSheet,
    private CompanyApi: CompanyApi,
    public Pwa: PwaService,
    public router: Router,
    public accountApi: AccountApi,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private swUpdate: SwUpdate) {
    this.setLanguage();


    this.addIcons();

    // LoopBackConfig.setBaseURL(BASE_URL);
    // LoopBackConfig.setApiVersion(API_VERSION);
    // LoopBackConfig.setSecureWebSockets(); // if necessary?? 

    if (this.accountApi.isAuthenticated()) {

      console.log(window.location.hostname)

      if (window.location.hostname !== 'localhost') {
        this.swPush.notificationClicks.subscribe(event => {
          console.log('Received notification: ', event);
          const url = event.notification.data.url;
          this.router.navigateByUrl(url);
        });

        this.swPush.notificationClicks.subscribe(
          ({ action, notification }) => { //x
            console.log('Received notification: ', action);
            const url = notification.data.url;
            window.open(url, '_blank');
          });

        this.checkPushSubscription();
      }
    }
    // console.log(this.router.routerState.snapshot.url)
    // if (this.router.routerState.snapshot.url.startsWith("/login")){
    //   this.loginpage = true;
    // }

    // hide side bar items
    this.router.events.subscribe((val) => {
      // see also 

      if (val instanceof NavigationStart) {
        // console.log(val.url) 
        if (val.url.startsWith("/login")) {
          this.loginpage = true;
        } else {
          this.loginpage = false;
        }
      }
    });

  }


  ngOnDestroy(): void {
    if (this.appservice.AcccountReference) {
      this.appservice.AcccountReference.dispose();
    }

    if (this.appservice.LoggerRef) {
      this.appservice.LoggerRef.dispose();
    }
  }




  checkSocialMediaAccounts() {
    // find all 
    this.CompanyApi.getRelations(this.Company.id, {
      where: {
        marketingrelation: { "eq": true }
      },
      fields: { marketingrelation: true, id: true, twitter: true, linkedin: true, facebook: true, pinterest: true, relationsId: true, companyId: true },
      include: ['linkedin', 'twitter', 'facebook', 'pinterest'],
    }).subscribe(res => {
      //console.log(res);
      let linkedin = [];
      let facebook = [];
      let twitter = [];
      let pinterest = [];
      let social = ['linkedin', 'facebook', 'twitter', 'pinterest']
      for (var y = 0; y < social.length; y++) {
        let value = social[y];
        for (var i = 0; i < res.length; i++) {
          if (res[i][value].length > 0) {
            let newvalue = res[i][value]
            if (y === 0) { linkedin.push(newvalue) }
            if (y === 1) { facebook.push(newvalue) }
            if (y === 2) { twitter.push(newvalue) }
            if (y === 3) { pinterest.push(newvalue) }
          }
        }
      }
      this.updateSocialMedia(linkedin, facebook, twitter, pinterest);

    });
  }

  public openSnackBar(message: string, action?: string) {
    let snackbar = this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: "snackbar-class"
    });

    snackbar.afterDismissed().subscribe(() => {
      console.log('The snack-bar was dismissed');
    });
  }

  scrollDownOrUp() {
    // let id = document.getElementById("idmaincontainer")
    let id = document.getElementsByClassName('sidenav-content')[0];
    ScrollTrigger.create({
      trigger: id,
      start: "top top",
      endTrigger: "#otherID",
      end: "bottom 50%+=100px",
      onToggle: self => console.log("toggled, isActive:", self.isActive),
      onUpdate: self => {
        //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
      }
      // goingUp$.subscribe(() => (this.isVisible = false));
      // goingDown$.subscribe(() => (this.isVisible = true));
    })
  }

  sidenavToggle() {
    if (this.sidenavopen === true) {
      this.sidenavopen = false
    } else {
      this.sidenavopen = true
    }
  }

  async checkPushSubscription() {
    if (this.Account.pushnotifications && !this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        serverPublicKey: VAPID_PUBLIC_KEY
      })
        .then((sub: PushSubscription) => {
          console.log(sub);
          let browserinfo = navigator.userAgent;
          let pushsub = new Pushsubscriptions();
          pushsub.endpoint = sub.endpoint;
          //pushsub.expirationTime = sub.expirationTime;
          pushsub.browserinfo = browserinfo;
          pushsub.keys = {
            p256dh: sub.getKey('p256dh'),
            auth: sub.getKey('auth')
          }
          //pushsub.keys = sub.getKey();
          this.accountApi.createPushsubscriptions(this.Account.id, sub).subscribe();
          this.swPush.notificationClicks.subscribe(
            (event) => { //{ action, notification }
              console.log('Received notification: ', event);
              const url = event.notification.data.url;
              window.open(url, '_blank');
            });
        })
        .catch(err => console.error('Could not subscribe to notifications', err));
      // this.accountApi.getPushsubscriptions(this.Account.id).subscribe(res => {
      //   console.log(this.swPush.isEnabled);
      //   console.log(res)
      // })
    }

  }

  updateSocialMedia(linkedin, facebook, twitter, pinterest) {
    //console.log(linkedin, facebook, twitter, pinterest)
    const today = moment();

    for (var y = 0; y < linkedin.length; y++) {
      let socialtype: Linkedin = linkedin[y][0];
      const secadd = parseInt(socialtype.tokenexpires);
      const enddate = moment(socialtype.updated_at).add(secadd, 's');
      const diff = enddate.diff(today);
      //console.log(diff);
      if (diff < (secadd / 3)) {

        this.dialogsService
          .confirm('you will need to verify your Linkedin Account Again', 'Update now?')
          .subscribe(res => {
            if (res === true) {
              this.redirectLinkedin(socialtype.id);
            }
            // this.Relations.splice(this.relationindex);
          });

        //this.openSnackBar('you will need to verify your Linkedin Account Again');

      }
      //if (socialtype.updated_at + socialtype.tokenexpires)
    }
    for (var y = 0; y < facebook.length; y++) {
      let socialtype: Facebook = facebook[y][0];
      const secadd = parseInt(socialtype.AccessTokenexpires);
      const enddate = moment(socialtype.updated_at).add(secadd, 's');
      const diff = enddate.diff(today);
      // console.log(diff);
      if (diff < (secadd / 3)) {
        this.openSnackBar('you will need to verify your Facebook Account Again');
        this.redirectFacebook(socialtype.id);
      }
    }
  }

  redirectLinkedin(id): void {
    var redirect;
    var domain = window.location.protocol + window.location.hostname; // + ":3000"; //set domain + protocol + 3000 for test purpose only
    this.LinkedinApi.linkedinauth(id, domain).subscribe(res => {
      redirect = res, window.location.href = redirect.request.uri.href;
    });
  };

  redirectFacebook(ids): void {
    // console.log(ids);
    var redirect;
    var domain = window.location.protocol + '//' + window.location.hostname; //+ ":3000"; //set domain + protocol + 3000 for test purpose only
    this.FacebookApi.sessionsconnect(ids, domain).subscribe(res => { redirect = res, window.location.href = redirect; });
  };

  openBottomSheet(): void {
    this._bottomSheet.open(BottomSheetLogOverview, {
      data: { logger: this.logger, account: this.Account },
      panelClass: 'bottom-sheet'
    });
  }

  public teststyle = {
    "background-color": "red"
  }



  getLogs(account) {
   // console.log('get logs', account)
    this.accountApi.getLogger(account.id,
      {
        order: 'id DESC',
        where: { read: false }
      }).subscribe((logger: Logger[]) => {
        { this.logger = logger };
        this.messagecount = this.logger.length;
      });

  }

  getAccount(): void {

    this.appservice.isCompanyAvailable.subscribe((ready) => {
      if (ready) {
        this.Account = this.appservice.Account;
        this.getCompany();
        this.isDarkTheme = this.Account.darktheme;
        // //this.getLogs(this.Account);
        // this.logger = this.appservice.logger;
        // this.messagecount = this.appservice.messagecount;
        this.getLogs(this.Account);
        this.appservice.realTime.IO.on('new log')
          .subscribe((msg: any) => { console.log('incoming log', msg); this.getLogs(this.Account); });

        //  console.log(this.logger, this.messagecount)

        if (this.Account.startwalkthrough) { this.startTour() } // start tour
      }
    });
  }

  noWalkthrough() {
    console.log('skip walkthrough')
    this.Account.startwalkthrough = false;
    this.saveAccount();
  }

  startTour() {
    console.log('start tour');
    this.TourService.Tour.skipCallback = () => {
      this.noWalkthrough();
    },
      this.GuidedTourService.startTour(this.TourService.Tour); // start tour
  }

  getCompany() {
    this.CompanyApi.findById(this.Account.companyId).subscribe((company: Company) => {
      this.Company = company;
      if (this.Account["365account"]) {
        this.autoAssign();
      }
      // wait for loading 
      setTimeout(() => {
        this.checkSocialMediaAccounts();
      }, 5000);

    })
  }

  public async autoAssign() {
    // go through 

    if (this.Account["365account"]) {
      let token = await this.graphService.getAccessToken();
      if (token) {
        let user = await this.graphService.getUser();
        console.log(user)
        if (user.email) {
          if (user.email !== this.Account["365account"]) {

            this.openSnackBar('New Email address please verify you are logged into the right account', 'logout');
            this.graphService.signOut();
          } else {
            this.getMails365();
          }
        }
      } else {
        await this.graphService.signIn();
        let user = await this.graphService.getUser();
        if (user && user.email) {
          if (user.email !== this.Account["365account"]) {
            this.openSnackBar('New Email address please verify you are logged into the right account');
            this.graphService.signOut();
          } else {
            this.getMails365();
          }
        }
      }
    }
  }

  private async getMails365() {
    try {
      let token = await this.graphService.getAccessToken();
      let date: any;
      if (this.Account.lastupdate365account) {
        date = this.Account.lastupdate365account;
      } else {
        date = '';
      }
      this.UnsortedcallsApi.autoAssignMailOffice365(
        this.Account.id,
        this.Company.id,
        token,
        this.Account["365account"],
        date,
        this.Account.autoassigninbox
      ).subscribe(() => {
        this.Account.lastupdate365account = new Date();
        this.saveAccount();
      });
    } catch (e) {
      console.log(e)
    }
  }

  private saveAccount() {
    this.accountApi.patchAttributes(this.Account.id, this.Account)
      .subscribe(res => { }, error => {
        console.log(error)
      });
  }

  public logout(): void {
    this.sidenavopen = false;
    this.isDarkTheme = false;
    if (this.Account.id == undefined) { this.router.navigate(['/login']) } // triggers anyway??
    else {
      //  this.accountApi.getCurrent().subscribe((Account: Account) => {
      this.accountApi.logout().subscribe(res => {
        this.auth.clear();
        this.Account = undefined;
        this.router.navigate(['/login']);
        location.reload();
      });
      // });
    }
  }

  public isVisible = false;

  ngAfterViewInit() {
    this.scrollDownOrUp();
    // let loc = window.location.pathname;
    // let locindex = loc.indexOf('/login')
    // if (locindex === -1) {
    //   this.getLogs();
    // }
    // console.log(window.location.pathname)
    if (window.location.pathname.startsWith("/login")) {
      this.loginpage = true;
    }
    this.getAccount();

  }

  installPwa(): void {
    this.Pwa.promptEvent.prompt();
  }

  changeTheme(): void {
    if (this.isDarkTheme) {
      this.isDarkTheme = false;
      this.Account.darktheme = false;
    } else {
      this.isDarkTheme = true;
      this.Account.darktheme = true;
    }
    this.saveAccount();
  }

  selectOtherLanguage() {
    let url = BASE_URL + '/' + this.selectedlanguage // + this.router.url;

    console.log(url)
    if (window.location.href !== url) {
      window.location.href = url;
    }
    // window.location.href = newurl;

    try {
      window.localStorage.setItem('enydea-language', this.selectedlanguage);
    } catch (error) {

    }

  }

  setLanguage() {
    try {
      this.selectedlanguage = localStorage.getItem('enydea-language')
    } catch {
      
    }
    let url = BASE_URL + '/' + this.selectedlanguage; // + this.router.url;

    let currenturl = window.location.href;
    // console.log(currenturl)
    // console.log(url)
    // console.log(currenturl.indexOf(url));

    if (currenturl.indexOf('returnpage') === -1) {
      if (window.location.hostname !== 'localhost' && this.selectedlanguage !== null) { // for testing
        if (currenturl.indexOf(url) === -1) {
          console.log('set url:')
          window.location.href = url;
        }

      }
    }
  }


  addIcons() {
    this.iconRegistry.addSvgIcon(
      'enydea_linkedin',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/linkedin.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_twitter',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/twitter.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_facebook',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/facebook.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_instagram',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/instagram.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_pinterest',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/pinterest.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_tumblr',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/assets/icons/tumblr.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_web',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/web.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_xing',
      //'./assets/icons/xing.svg');
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/xing.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_snapchat',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/snapchat.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_youtube',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/youtube.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_vimeo',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/vimeo.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_github',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/github.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_adwords',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/adwords.svg'));

    this.iconRegistry.addSvgIcon(
      'enydea_office365',
      this.sanitizer.bypassSecurityTrustResourceUrl(BASE_URL + '/api/Containers/standardsvg/download/office-365.svg'));
  }

  openQuick_phrases() {
    if (this.Account){
      const dialogRef = this.dialog.open(AitoolComponent, {
        width: '800px',
        data: this.Account
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
  
        }
      });
    }

  }

}



@Component({
  selector: 'bottom-sheet-Log-Overview',
  templateUrl: 'bottom-sheet-logoverview.html',
})
export class BottomSheetLogOverview {

  public logger: Logger[];
  public Account: Account;

  constructor(
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private CompanyApi: CompanyApi,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetLogOverview>) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {
    this.logger = this.data.logger;
    this.Account = this.data.account;
  }

  deleteLog(i): void {
    let deletedone = this.logger[i];
    this.logger.splice(i, 1);
    this.CompanyApi.destroyByIdLogger(this.Account.companyId, deletedone.id)
      .subscribe(res => { });
  }

  markRead(i): void {
    let readdone = this.logger[i];
    this.logger.splice(i, 1)
    readdone.read = true;
    this.CompanyApi.updateByIdLogger(this.Account.companyId, readdone.id,
      readdone)
      .subscribe(res => { });
  }

  navigateTo(logs, i) {

    //this.deleteLog(i)
    //console.log(logs)
    if (logs.code === 'E2U') {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/settings'], { queryParams: { tab: 'unsorted', itemid: logs.item } })
      )
    }
    if (logs.code === 'E2A') {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/relation'],
          { queryParams: { tab: 'calls', itemid: logs.item, relation: logs.relation } })
      );
    }
    if (logs.code === 'FLR' || logs.code === undefined) {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/relation'],
          { queryParams: { tab: 'files', itemid: logs.item, relation: logs.relation } })
      );
    }
    if (logs.code === 'MCS') {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/marketing'],
          { queryParams: { tab: 'mailing', itemid: logs.item, relation: logs.relation } })
      );
    }
    if (logs.code === 'MNS') {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/marketing'],
          { queryParams: { tab: 'mailing', itemid: logs.item, relation: logs.relation } })
      );
    }
    if (logs.code === 'IFM') {
      let itemid = logs.id;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/marketing'],
          { queryParams: { tab: 'form', itemid: logs.item, relation: logs.relation } })
      );
    }

    if (logs.code === 'ICM') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        var url = new URL(logs.url);
        const urlParams = new URLSearchParams(url.search);
        const roomid = urlParams.get('roomid');
        const chatid = urlParams.get('chatid');
        this.router.navigate(['/marketing'],
          { queryParams: { tab: 'chat', itemid: logs.item, relationid: logs.relation, roomid: roomid, chatid: chatid } })

      })
    }


  }



}