<div fxLayoutAlign="center center" style="height: 100%; width: 100%;">
  <div fxLayout="column">
    <h1> {{response}}</h1>
    <br>
    <button *ngIf="response" mat-raised-button (click)="returntopage()" i18n>return</button>
  </div>


  <div *ngIf="this.access_token" class="logincontainer">
    <section>
      <h1 i18n>Reset Password</h1>
    </section>
    <div fxLayout="row">
      <div fxLayout="column">
        <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent.color">
          <mat-label>Password</mat-label>
          <mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
          <input matInput #password [type]="toggleVisbility.type" placeholder="Password" [(ngModel)]="Account.password">
          <mat-hint aria-live="polite">
            {{password.value.length}} / {{passwordComponent.max}}
          </mat-hint>
        </mat-form-field>
        <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
          [password]="password.value">
        </mat-password-strength>
        <mat-password-strength-info [passwordComponent]="passwordComponent" [enableScoreInfo]="true">
        </mat-password-strength-info>
      </div>
    </div>
    <br>
    <button (click)="saveAccount()" a mat-raised-button class="button" i18n>Save Changes</button>
  </div>
</div>
