<div class="loginlanguage">
  <mat-select placeholder="Language" [(ngModel)]="selectedlanguage" name="Language"
    (selectionChange)="selectOtherLanguage()">
    <mat-option *ngFor="let language of languages" [value]="language.code">
      {{language.label}}
    </mat-option>
  </mat-select>
</div>

<div *ngIf="showconfirmation">
  <mat-card fxLayout="row" fxLayoutGap="20px">
    <mat-icon style="color:green" i18n>email</mat-icon>
    <label style="color:green" i18n>Payment approved, please check your mailbox to activate your account and login.
      Check your spambox if you have not received anything.
    </label>
    <!-- <label *ngIf="selectedAccount.paymentreceived === 'paid'" style="color:green">Payment Received,
    invoice send to your email or under your
    account under invoices
  </label> -->
    <!-- <label *ngIf="selectedAccount.paymentreceived === 'expired'" style="color:red">Payment expired
    please
    try again</label>
  <label *ngIf="selectedAccount.paymentreceived === 'failed'" style="color:red">Payment failed
    please
    try again or use other method of payment
    method</label>
  <label *ngIf="selectedAccount.paymentreceived === 'cancelled'" style="color:red">Payment
    cancelled</label> -->
  </mat-card>
</div>

<div fxLayout="row" fxLayout.lt-sm="column">



  <div fxFlex="50%" fxLayoutAlign="center center" class="logincontainerback" *ngIf="formstate !== 'selectplan'">


    <!-- <div *ngIf="detectchange"> -->

    <div *ngIf="formstate === 'login'" class="logincontainer">
      <section class="mat-typography" fxLayout="row" fxLayoutGap="40px">
        <img src="../../assets/icon-512x512.png" alt=""
          style="width: 10%; max-width: 512px; height: 10%; max-height: 512px; ">
        <h1 i18n>Login</h1>
      </section>

      <form fxLayout="column" class="form-login">
        <div fxLayout="row">
          <div fxLayout="column" class="form-full-width">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput name="Email" type="email" [(ngModel)]="Account.email" i18n-placeholder placeholder="email"
                autocomplete="email" />
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <div fxLayout="column" class="form-full-width">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label i18n>Password</mat-label>
              <mat-pass-toggle-visibility #toggleVisbility1 matSuffix></mat-pass-toggle-visibility>
              <input matInput #password [type]="toggleVisbility1.type" i18n-placeholder placeholder="Password"
                [(ngModel)]="Account.password" name="password">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox name="rememberme" [(ngModel)]="rememberMe" style="font-size: small;">Remember Me</mat-checkbox>
          <button (click)="login()" style="width:170px;" color="primary" mat-raised-button>Login</button>
        </div>
        <br>
        <mat-divider></mat-divider>
        <br>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
          <label style="font-size: small">New to Enydea?</label>
          <button (click)="registertoggleform()" style="width:170px;" mat-raised-button>Register Now</button>
        </div>
        <br>
        <label style="font-size: 6pt;" (click)="formstate = 'resetpassword';" i18n>I forgot my
          password</label>
        <!-- <div fxLayout="column" fxLayoutGap="20px">
            <label>{{ errorMessage }}</label>
            <label>{{ responsemessage }}</label>
          </div> -->
      </form>

    </div>

    <div *ngIf="formstate === 'resetpassword'" class="registercontainer" fxLayout="column">
      <section class="mat-typography">
        <label i18n>Fill in your email and we will send you an email to reset your password</label>
      </section>
      <br>
      <mat-form-field appearance="outline">
        <input matInput name="Email" type="email" [(ngModel)]="resetEmailAddress" i18n-placeholder
          placeholder="email" />
      </mat-form-field>
      <br>
      <button (click)="resetPassword()" a mat-raised-button i18n>Reset</button>

    </div>

    <div *ngIf="formstate === 'register'" class="registercontainer">
      <section class="mat-typography">
        <h1 i18n>Register</h1>
      </section>
      <div fxLayout="row">
        <div fxLayout="column" class="form-full-width">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput (keyup)="checkall()" name="username" type="name" [(ngModel)]="Account.username"
              i18n-placeholder placeholder="Username" />
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5%">
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>Company name</mat-label>
          <input matInput (keyup)="checkall()" google-place (onSelect)="setNameAddress($event)" #companyname
            name="companyname" type="name" [(ngModel)]="company.companyname" i18n-placeholder placeholder="Company" />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="45%">
          <mat-label>VAT number</mat-label>
          <input matInput (keyup)="checkall()" name="VAT" type="name" [(ngModel)]="Account.vatnr" i18n-placeholder
            placeholder="VAT number" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" fxFlex="48%">
          <mat-label i18n>Address</mat-label>
          <input matInput [(ngModel)]=company.address (keyup)="checkall()" (onSelect)="setAddress($event)" google-place
            i18n-placeholder placeholder="Address" maxlength="100">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" fxFlex="48%">
          <mat-label i18n>Zipcode</mat-label>
          <input matInput [(ngModel)]="company.zipcode" i18n-placeholder placeholder="Zipcode" maxlength="10">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="48%">
          <mat-label i18n>City</mat-label>
          <input matInput google-place (onSelect)="setCity($event)" [(ngModel)]="company.city" maxlength="100"
            i18n-placeholder placeholder="City">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" fxFlex="48%">
          <mat-label i18n>State/Province</mat-label>
          <input matInput [(ngModel)]=company.stateprovince i18n-placeholder placeholder="State/Province"
            maxlength="100">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="48%">
          <mat-label i18n>Country</mat-label>
          <input matInput google-place (onSelect)="setCountry($event)" [(ngModel)]=company.country maxlength="100"
            i18n-placeholder placeholder="Country">
        </mat-form-field>
      </div>


      <div *ngIf="!Account.id">

        <div fxLayout="row">
          <div fxLayout="column" class="form-full-width">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput (keyup)="checkall()" name="email" type="email" [(ngModel)]="Account.email"
                i18n-placeholder placeholder="email" />
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="column">
          <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent.color">
            <mat-label i18n>Password</mat-label>
            <mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
            <input matInput #password [type]="toggleVisbility.type" i18n-placeholder placeholder="Password"
              [(ngModel)]="Account.password">
            <mat-hint aria-live="polite">
              {{password.value.length}} / {{passwordComponent.max}}
            </mat-hint>
          </mat-form-field>
          <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
            [password]="password.value">
          </mat-password-strength>
          <label style="font-size: x-small;" (click)="showpasswordinfo = !showpasswordinfo">Show password info</label>
          <mat-password-strength-info *ngIf="showpasswordinfo" [passwordComponent]="passwordComponent"
            [enableScoreInfo]="true">
          </mat-password-strength-info>
        </div>

      </div>

      <div fxLayout="row">
        <div *ngIf="errorMessage" fxLayout="column">
          <section class="mat-typography">
            <label>{{errorMessage}}</label>
          </section>
        </div>
      </div>
      <div fxLayout="row">
        <div fxLayout="column" *ngIf="responsemessage">
          <section class="mat-typography">
            <label> {{responsemessage}}</label>
          </section>
        </div>
      </div>
      <br>
      <div fxLayout="row">
        <button (click)="backtoggle()" a mat-raised-button>Back</button>
        <button (click)="opendialogconfirmpayment()" a mat-raised-button *ngIf="!Account.id"
          [disabled]="!registerbutton || !company.companyname || !Account.username || !Account.email || !Account.password"
          i18n>
          Register</button>
        <button (click)="opendialogconfirmpayment()" a mat-raised-button *ngIf="Account.id"
          [disabled]="!company.companyname || !Account.username" i18n>
          Register</button>
      </div>

    </div>


    <!-- </div> -->
  </div>

  <div fxFlex="50%" class="backgroundcontainer" fxLayoutAlign="center center" *ngIf="formstate !== 'selectplan'">
    <img src="../../assets/enydea-logo-allwhite-03.png" alt="" fxFlex="50%" class="logo">
  </div>


  <div *ngIf="formstate === 'selectplan'" fxLayoutAlign="start center" class="selectplan" fxLayout="column">

    <app-register [Account]="Account" (emitterPlan)="setPlan($event)" (emitterBack)="setBack()"></app-register>


  </div>


</div>