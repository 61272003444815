import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { DialogsService } from 'src/app/dialogsservice/dialogs.service';
import { Mailing, MarketingplannereventsApi, GoogleanalyticsApi, Googleanalytics, Relations, RelationsApi, Account, Company, Marketingplannerevents, Mailinglist } from 'src/app/shared/sdk';
import { timeconv } from '../../shared/timeconv';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { EditmailingComponent } from './editmailing/editmailing.component';

@Component({
  selector: 'app-campaignbuilder',
  templateUrl: './campaignbuilder.component.html',
  styleUrls: ['./campaignbuilder.component.scss']
})
export class CampaignbuilderComponent implements OnInit {

  @Input('option') option: Relations;
  //@Input('account') account: Account;
  @Input('company') company: Company;
  @Input('Mailinglist') Mailinglist: Mailinglist[];
  @ViewChild('chipInput') chipInput: MatInput;


  selectedItems: string[] = [];
  filteredItems: Observable<any[]>;
  addItems: UntypedFormControl;
  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];


  public mailingaddresscampaign = [];
  public toggleshorttext = [];
  public htmlpreview = [];
  public copyfrommailing;
  public filtermailing = [
    'send',
    'done',
    'all',
    'not send'
  ]
  public filtermailingselect;
  public togglecampaignclasstrans = [];
  public toggleCampaignMailing = [];
  public date;
  public CampaignMailing: Mailing[];
  public analytics_filters;
  public selectedanalytics: Googleanalytics = new Googleanalytics();
  public analytics_ids = 'ga:154403562';
  public analytics_startdate = '2008-10-01';
  public analytics_enddate = 'today';
  public analytics_metrics = [
    { expression: 'ga:bounceRate' },
    { expression: 'ga:pageviewsPerSession' },
    { expression: 'ga:goalStartsAll' },
    { expression: 'ga:avgTimeOnPage' },
  ]
  public analytics_dimensions = [{ name: 'ga:adContent' }];

  public Googleanalyticsreturn;
  public GoogleanalyticsSet;
  public Googleanalyticsnumbers;
  public Googleanalyticsnames;

  public GoogleanalyticsModel: Googleanalytics[];
  public avgTimeOnPage = '-';
  public bounceRate = '-';
  public goalStartsAll = '-';
  public pageview = '-';

  public listviewxsshow = false;
  time: string;

  public Marketingplannerevents: Marketingplannerevents[];
  public selectedMarketingplannerevents: Marketingplannerevents;

  searchboxCampaign: string;
  mails: any[][];

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public RelationsApi: RelationsApi,
    public dialogsService: DialogsService,
    public timeconv: timeconv,
    public snackBar: MatSnackBar,
    public MarketingplannereventsApi: MarketingplannereventsApi,
    public GoogleanalyticsApi: GoogleanalyticsApi,
  ) { }

  ngOnInit(): void {
    this.getMailingCampaign();

  }



  public searchGoCampaign(): void {
    if (this.selectedMarketingplannerevents) {
      this.saveMailingCampaign();
    }

    this.RelationsApi.getMarketingplannerevents(this.option.id,
      {
        include: {
          relation: 'mailinglist', // include the owner object
        },
        where:
          { "name": { "regexp": this.searchboxCampaign + '/i' } },
        order: 'title ASC',
        limit: 20
      })
      .subscribe((marketingplannerevents: Marketingplannerevents[]) => { this.Marketingplannerevents = marketingplannerevents }
      )
  }


  public getMailingCampaign(): void {
    if (this.selectedMarketingplannerevents) {
      this.saveMailingCampaign();
    }
    this.RelationsApi.getMarketingplannerevents(this.option.id,
      {
        include: {
          relation: 'mailinglist', // include the owner object
        },
        order: 'id DESC',
      })
      .subscribe((marketingplannerevents: Marketingplannerevents[]) => { this.Marketingplannerevents = marketingplannerevents }
      )
  }

  sortMailing() {
    // sort mailing by relations to eachother and sort by this.mail[index]
    let parentmails = [];
    this.mails = [[], [], [], [], []]; // index 1 main mail + 4 arrays for follow ups 

    // set first emails
    for (let i = 0; i < this.CampaignMailing.length; i++) {
      const mail: Mailing = this.CampaignMailing[i];
      if (!mail.parentId) {
        this.mails[0].push(mail)
      }
    }


    // filter follow up mails layer for layer
    for (let i = 0; i < this.mails[0].length; i++) {
      let mail0: Mailing = this.mails[0][i];

      let mail1: Mailing, mail2: Mailing, mail3: Mailing, mail4: Mailing;

      if (mail0.followupId) {
        mail1 = this.findId(mail0.followupId, this.CampaignMailing);
        this.mails[1].push(mail1)
      } else { this.mails[1].push(undefined) }


      if (mail1 && mail1.followupId !== undefined) {
        mail2 = this.findId(mail1.followupId, this.CampaignMailing);
        this.mails[2].push(mail2)
      } else this.mails[2].push(undefined)

      if (mail2 && mail2.followupId !== undefined) {
        mail3 = this.findId(mail2.followupId, this.CampaignMailing);
        this.mails[3].push(mail3)
      } else this.mails[3].push(undefined)

      if (mail3 && mail3.followupId !== undefined) {
        mail4 = this.findId(mail3.followupId, this.CampaignMailing);
        this.mails[4].push(mail4)
      } else this.mails[4].push(undefined)

    }

  }

  findId(id: string, array: Array<Mailing>) {
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.id === id) {
        return element;
      }
    }
    return undefined;
  }


  createFollowUpMail(mailing: Mailing, index) {
    //  console.log(mailing.id)
    this.MarketingplannereventsApi.createMailing(this.selectedMarketingplannerevents.id,
      {
        title: 'new',
        subject: 'Follow up Mail',
        parentId: mailing.id,
      })
      .subscribe((newmailing: Mailing) => {
        this.CampaignMailing.push(newmailing);
        mailing.followupId = newmailing.id;
        this.saveMailing(mailing);
        this.sortMailing();
        this.editMailing(newmailing);
      });
  }


  // error! returns full filter not working.
  public getAnalyticsCampaign(i) {
    // for each mailing get stats
    console.log(this.CampaignMailing[i].id)
    this.analytics_filters = 'ga:adContent'; //+ this.CampaignMailing[i].id;
    this.GoogleanalyticsApi.getanalyticsreport(this.selectedanalytics.id, this.analytics_ids, this.analytics_startdate,
      this.analytics_enddate, this.analytics_dimensions, this.analytics_metrics, this.analytics_filters, this.CampaignMailing[i].id)
      .subscribe((data) => {
        console.log(data)
        let obj = data[0].data.rows.find(o => o.dimensions[0] === this.CampaignMailing[i].id);
        console.log(obj);
        if (obj !== undefined) {
          this.Googleanalyticsreturn = obj.metrics[0].values; // data.rows[0],
          this.avgTimeOnPage = this.Googleanalyticsreturn[3];
          this.bounceRate = this.Googleanalyticsreturn[0];
          this.goalStartsAll = this.Googleanalyticsreturn[2];
          this.pageview = this.Googleanalyticsreturn[1];
          console.log(this.Googleanalyticsreturn)
        } else { this.avgTimeOnPage = 'No Data to show' };
        if (!data) {
          this.openSnackBar('No website statistics found, try back later');
        }
      })
  }



  saveMailingCampaign(message?): void {
    // count for listview numbers

    for (let i = 0; i < this.CampaignMailing.length; i++) {
      let mailElement = this.CampaignMailing[i];

      mailElement.text = this.onChangeHtml(mailElement.html); // convert to text
      mailElement.relationsId = this.option.id;
      mailElement.companyId = this.option.companyId;
      // dats is set on select
      if (mailElement.date == null) {
        this.date = moment().format();
        mailElement.date = this.date
      }
      if (mailElement.timezone == null) {
        mailElement.timezone = moment.tz.guess();
      }
      // time
      if (mailElement.time == null) {
        this.time = moment().format('hh:mm')
        mailElement.time = this.time;
      }

      mailElement.date = this.timeconv.convertTime(mailElement.date, mailElement.time, mailElement.timezone);
      // console.log(mailElement.date);
      this.saveMailing(mailElement);
    }
  }

  saveMailing(mailElement: Mailing) {
    this.MarketingplannereventsApi.updateByIdMailing(this.selectedMarketingplannerevents.id, mailElement.id, mailElement)
      .subscribe(res => res,
        error => this.openSnackBar('Mailing could not be saved'));
  }

  openDialogDeleteMailingCampaign(): void {
    this.dialogsService
      .confirm('Delete Mailing Campaign', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          this.deleteMailingCampaign();
        }
      });
  }

  // check destroy is sufficient?
  deleteMailingCampaign(): void {
    this.RelationsApi.destroyByIdMarketingplannerevents(this.option.id, this.selectedMarketingplannerevents.id)
      .subscribe(res => { this.getMailingCampaign(), this.selectedMarketingplannerevents = undefined });
  }

  openDialogDeleteMailingof(mailing: Mailing): void {
    mailing
    this.dialogsService
      .confirm('Delete Mailing', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) { this.deleteMailingCampaignof(mailing.id) };
      });
  }

  deleteMailingCampaignof(id): void {
    this.MarketingplannereventsApi.destroyByIdMailing(this.selectedMarketingplannerevents.id, id)
      .subscribe(res => { this.getMailingCampaignMailings() });
  }

  openDialogScheduleMailingCampaign(): void {
    // const newtoggleCampaignMailing = [];
    // const newtogglecampaignclasstrans = [];
    // this.toggleCampaignMailing.forEach((item) => {
    //   newtoggleCampaignMailing.push(false)
    //   newtogglecampaignclasstrans.push(false)
    // })
    // this.toggleCampaignMailing = newtoggleCampaignMailing;
    // this.togglecampaignclasstrans = newtogglecampaignclasstrans;

    this.dialogsService
      .confirm('Schedule Campaign', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          this.ScheduleMailingCampaign();
        }

      });
  }

  ScheduleMailingCampaign(): void {
    this.saveMailingCampaign();
    const message = this.updatecampaigns();
    //console.log(message),
    this.openSnackBar(message);
    this.saveMailingCampaign();
  }

  public updatecampaigns(): string {
    let message
    this.selectedMarketingplannerevents.scheduled = true;
    if (this.selectedMarketingplannerevents.mailinglistId.length < 1) { message = 'Mailinglist missing' }
    if (message !== undefined) { return message }
    const mailtolist = [];
    let tolist = '';

    // join multiple lists
    this.selectedMarketingplannerevents.mailinglist.forEach(list => {
      mailtolist.push(list.mailgunid)
    })
    if (this.selectedMarketingplannerevents.mailinglist.length > 1) {
      // create comma seperate for mailgun processing
      tolist = mailtolist.join(', ')
    } else { tolist = mailtolist.join() };

    if (this.CampaignMailing.length === 0) { return 'No mailings in campaigns, please add mailings'; }

    for (let i = 0; i < this.CampaignMailing.length; i++) {
      const mailingElement = this.CampaignMailing[i];
      if (mailingElement.from == undefined) { return 'From field missing'; }
      if (mailingElement.subject == undefined) { return 'Subject field empty'; }
      if (mailingElement.html === undefined && mailingElement.text === undefined) { return 'Missing mail content'; }
      if (mailingElement.senddate === undefined && !mailingElement.sendafterclick && !mailingElement.sendafteropen) {return 'Missing Send date'; }
    }

    for (let i = 0; i < this.CampaignMailing.length; i++) {
      const mailingElement = this.CampaignMailing[i];
      if (mailingElement.selectedlists === undefined) { mailingElement.selectedlists = [] }
      if (mailingElement.toopened === true) {
        if (this.selectedMarketingplannerevents.mailinglist.length > 1) {
          this.selectedMarketingplannerevents.mailinglist.forEach(list => {
            mailtolist.push('open' + list.mailgunid)
          })
          // create comma seperate for mailgun processing
          tolist = mailtolist.join(', ')
        } else { console.log(tolist), tolist = 'open' + tolist }
      }
      if (mailingElement.toclicked === true) {
        if (this.selectedMarketingplannerevents.mailinglist.length > 1) {
          this.selectedMarketingplannerevents.mailinglist.forEach(list => {
            mailtolist.push('clicked' + list.mailgunid)
          })
          // create comma seperate for mailgun processing
          tolist = mailtolist.join(', ')
        } else { tolist = 'clicked' + tolist }
      }
      mailingElement.to = tolist;
      mailingElement.scheduled = true;
      mailingElement.companyId = this.option.companyId;
      mailingElement.selectedlists = this.selectedMarketingplannerevents.mailinglist;
    }

    return 'Campaign Scheduled';
  }


  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: 'snackbar-class'
    });
  }



  public getMailingCampaignMailings(): void {
    // select send/done/all/not send
    this.toggleCampaignMailing = [];
    this.togglecampaignclasstrans = [];
    this.toggleshorttext = [];
    this.htmlpreview = [];

    let filter;
    if (this.filtermailingselect === 'send') { filter = { where: { send: true } } }
    if (this.filtermailingselect === 'done') { filter = { where: { done: true } } }
    if (this.filtermailingselect === 'not send') { filter = { where: { send: false } } }
    if (this.filtermailingselect === 'all') { filter = '' }
    this.CampaignMailing = [];


    this.MarketingplannereventsApi.getMailing(this.selectedMarketingplannerevents.id, filter)
      .subscribe((CampaignMailing: Mailing[]) => {
        this.CampaignMailing = CampaignMailing,
          // toggle for css class activation
          this.sortMailing();
      });
  }

  // this.getAnalyticsCampaign(index);
  // this.toggleCampaignMailing.push(false);
  // this.togglecampaignclasstrans.push(false); // transfer css
  // this.toggleshorttext.push(true); // show short text
  // this.htmlpreview.push(this.sanitizer.bypassSecurityTrustHtml(mailing.html)); // accept css innerhtml ng2



  // copy from existing mails
  copyFromMailingCampaign(i): void {
    this.CampaignMailing[i].html = this.copyfrommailing.html;
    this.CampaignMailing[i].subject = this.copyfrommailing.subject;
    this.CampaignMailing[i].to = this.copyfrommailing.to;
    this.CampaignMailing[i].from = this.copyfrommailing.from;
    this.CampaignMailing[i].title = this.copyfrommailing.title;
    // this.CampaignMailing[i].sectionStyle = this.copyfrommailing.sectionStyle;
    // this.CampaignMailing[i].templatearray = this.copyfrommailing.templatearray;
    // this.CampaignMailing[i].columnStyle = this.copyfrommailing.columnStyle;
    this.CampaignMailing[i].relationname = this.copyfrommailing.relationname,
      this.CampaignMailing[i].preview = this.copyfrommailing.preview,
      this.CampaignMailing[i].companyId = this.copyfrommailing.companyId
  }





  public createMarketingPlannerevents(): void {
    this.RelationsApi.createMarketingplannerevents(this.option.id, { companyId: this.option.companyId, name: 'New' })
      .subscribe(res => {
        this.Marketingplannerevents.push(res),
          this.onSelectMarketingplannerevents(res),
          this.getMailingCampaign()
      });
  }

  public createCampaignMailing(): void {
    this.MarketingplannereventsApi.createMailing(this.selectedMarketingplannerevents.id, { title: 'new' })
      .subscribe(newmailing => {
        this.CampaignMailing.push(newmailing);
        this.sortMailing();
        this.editMailing(newmailing);
      });
  }

  public onSelectMarketingplannerevents(marketingplannerevents: Marketingplannerevents): void {

    // this.Googleanalyticsreturn = '';
    // this.avgTimeOnPage = '';
    // this.bounceRate = '';
    // this.goalStartsAll = '';
    // this.pageview = '';

    // this.selectedMarketingplannerevents = undefined;
    this.mailingaddresscampaign = []; // first clean up a few things
    this.selectedItems = [];
    this.selectedMarketingplannerevents = marketingplannerevents; // select current
    this.filtermailingselect = 'all'; // standaard filter mailings
    this.getMailingCampaignMailings(); // get mailings for specific campaign
    // check for mailinglist and add to if not existing
    if (this.selectedMarketingplannerevents.mailinglist === undefined) { this.selectedMarketingplannerevents.mailinglist = []; }
    if (this.selectedMarketingplannerevents.mailinglist[0] !== undefined) {
      Object.keys(this.selectedMarketingplannerevents.mailinglist).forEach(key => {
        const value = this.selectedMarketingplannerevents.mailinglist[key];
        this.selectedItems.push(value.listname);
      })
    }
    this.prepareFilterMaillist(); // quick selection list
    //this.urlckeditorupload = BASE_URL + '/api/Containers/' + this.option.id + '/upload/';

    // set upload url for pictures
    // CKEDITOR.config.filebrowserBrowseUrl = BASE_URL + '/filemanager/' + this.option.id;
    // CKEDITOR.config.filebrowserUploadUrl = this.urlckeditorupload;
    // CKEDITOR.config.filebrowserImageBrowseUrl = BASE_URL + '/filemanager/' + this.option.id;
    // CKEDITOR.config.filebrowserImageUploadUrl = this.urlckeditorupload;

    this.updateBadge();
  }

  updateBadge() {
    this.MarketingplannereventsApi.countMailing(this.selectedMarketingplannerevents.id, { 'send': true })
      .subscribe(sendcount => {
        this.selectedMarketingplannerevents.countsend = sendcount.count,
          this.MarketingplannereventsApi.countMailing(this.selectedMarketingplannerevents.id, { 'send': false })
            .subscribe(notsendcount => {
              this.selectedMarketingplannerevents.countnotsend = notsendcount.count,
                this.updateMarketingPlannerEvent();
            })
      })
  }

  updateMarketingPlannerEvent() {
    this.RelationsApi.updateByIdMarketingplannerevents(
      this.option.id, this.selectedMarketingplannerevents.id,
      this.selectedMarketingplannerevents)
      .subscribe();
  }

  prepareFilterMaillist() {
    // formcontrol initialization
    this.addItems = new UntypedFormControl();

    // asynchronous value changes via reactive form control
    // import Reactive forms module for using it
    this.filteredItems = this.addItems.valueChanges
      .pipe(
        startWith(''),
        map(item =>
          item ? this.filterItems(item.toString()) : this.Mailinglist.slice())
      );
  }

  // filter the Languages with its matched text
  filterItems(itemName: string) {
    return this.Mailinglist.filter(item =>
      item.listname.toLowerCase().indexOf(itemName.toLowerCase()) === 0);
  }

  toggleToFullText(i): void {
    if (this.toggleshorttext[i] === false) {
      this.toggleshorttext[i] = true;
    } else { this.toggleshorttext[i] = false }
  }


  toggleCampaignMailingNow(i): void {
    // delay to anticipate css style change per mailing i = array mailing list
    if (this.togglecampaignclasstrans[i] === true) { this.togglecampaignclasstrans[i] = false } else {
      this.togglecampaignclasstrans[i] = true;
    }
    setTimeout(() => {
      if (this.toggleCampaignMailing[i] === true) { this.toggleCampaignMailing[i] = false } else { this.toggleCampaignMailing[i] = true; }
    }, 500);
  }


  // adding items
  // todo change
  onAddItems(event: MatAutocompleteSelectedEvent) {
    const t: Mailinglist = event.option.value;
    console.log(t);
    // if array is empty then push the elements
    if (this.selectedItems.length === 0) {
      this.selectedItems.push(t.listname);
      this.selectedMarketingplannerevents.mailinglistId.push(t.id);
      this.selectedMarketingplannerevents.mailinglist.push(t);

      console.log(this.selectedMarketingplannerevents.mailinglist)
      // this.selectedMarketingplannerevents.mailinglist.push(t);
    } else {
      // if items already present then items will not be added to the array
      // stringfying the array to find names similiar
      const selectMailingStr = JSON.stringify(this.selectedItems);
      if (selectMailingStr.indexOf(t.listname) === -1) {
        this.selectedItems.push(t.listname);
        this.selectedMarketingplannerevents.mailinglistId.push(t.id);
        this.selectedMarketingplannerevents.mailinglist.push(t);

        console.log(this.selectedMarketingplannerevents.mailinglist)
        // this.selectedMarketingplannerevents.mailinglist.push(t);
      }
    };
    // filter those mailinglists that are selected to avoid duplication
    this.itemsData = this.selectedItems;
    this.chipInput['nativeElement'].blur();
    this.chipInput['nativeElement'].value = '';

    this.saveMailingCampaign();
    this.updateMarketingPlannerEvent();

  }



  // update the array value
  get itemsData(): string[] {
    return this.selectedItems;
  }

  // update the array value
  set itemsData(v: string[]) {
    this.selectedItems = v;
  }

  // removes the items based on its name
  onRemoveItems(itemName: string, i): void {
    this.selectedItems = this.selectedItems.filter((name: string) => name !== itemName);
    this.itemsData = this.selectedItems;
    this.chipInput['nativeElement'].blur();
    this.selectedMarketingplannerevents.mailinglistId.splice(i);
    this.selectedMarketingplannerevents.mailinglist.splice(i);
    this.saveMailingCampaign('saved');
  }

  editMailing(mailing: Mailing) {
    const dialogRef = this.dialog.open(EditmailingComponent, {
      width: '800px',
      data: { mailing: mailing, option: this.option }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        mailing = result;
        this.saveMailing(mailing);
      }

    });
  }



  swiperight(e?) {
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }

  // convert html to text
  onChangeHtml(message) {
    if (message) {
      let text = message.replace(/<(?:.|\n)*?>/gm, '');
      text = text.replace(/&nbsp;/g, '');
      return text;
    }
  }

}
