<div class="formentry">
    <h1 mat-dialog-title>Entry information</h1>
    <div mat-dialog-content [innerHTML]="message  | safeHtml"></div>
    <br>
    <div fxLayoutGap="20px" fxLayout="row">
        <mat-checkbox [(ngModel)]="addRelation">Add as new Relation</mat-checkbox>
    </div>
    <br>
    <div fxLayoutGap="20px" fxLayout="row">
        <mat-checkbox [(ngModel)]="addCall">Add as Call</mat-checkbox>
        <mat-checkbox [(ngModel)]="addContactperson">Add as new Contactperson</mat-checkbox>
    </div>
    <br>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="!addRelation">
        <mat-form-field appearance="outline">
            <mat-label>Assign to relation</mat-label>
            <input type="relation" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="newrelation"
                name="assignrelationform">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option.relationname }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <!-- <button a mat-raised-button (click)="assignCall()">Assign To</button>
    <br> -->
    </div>
    <div *ngIf="addRelation" fxLayoutGap="10px">

        <mat-form-field appearance="outline">
            <mat-label>Relation Name</mat-label>
            <mat-select placeholder="Relation Name" [(ngModel)]="newrelation.relationname" name="type">
                <mat-option *ngFor="let content of entry.formcontent" [value]="content.value">
                    {{content.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Create new Relation</mat-label>
            <input matInput google-place (onSelect)="setAddress($event)" [(ngModel)]="newrelation.relationname"
                placeholder="New Relation Name" class="InputField" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <!-- <button a mat-raised-button (click)="createNewAssign()">Create New</button> -->
    </div>

    <div *ngIf="addCall" fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
            <mat-label>Call Content</mat-label>
            <mat-select placeholder="Select Call content" [(ngModel)]="newcall.content" name="type">
                <mat-option *ngFor="let content of entry.formcontent" [value]="content.value">
                    {{content.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox [(ngModel)]="copyformtocontent">Copy entire form input to content</mat-checkbox>
    </div>


    <div *ngIf="addContactperson">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <mat-select placeholder="Select firstname" [(ngModel)]="newcontactperson.firstname" name="type">
                    <mat-option *ngFor="let firstname of entry.formcontent" [value]="firstname.value">
                        {{firstname.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <mat-select placeholder="Select lastname" [(ngModel)]="newcontactperson.lastname" name="type">
                    <mat-option *ngFor="let lastname of entry.formcontent" [value]="lastname.value">
                        {{lastname.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <mat-select placeholder="Select email" [(ngModel)]="newcontactperson.email" name="type">
                    <mat-option *ngFor="let email of entry.formcontent" [value]="email.value">
                        {{email.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Salutation</mat-label>
                <mat-select placeholder="Salutation" [(ngModel)]="newcontactperson.salutation" name="type">
                    <mat-option *ngFor="let salutation of entry.formcontent" [value]="salutation.value">
                        {{salutation.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Phone</mat-label>
            <mat-select placeholder="Phone" [(ngModel)]="newcontactperson.cellphone" name="type">
                <mat-option *ngFor="let phone of entry.formcontent" [value]="phone.value">
                    {{phone.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- <mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Save as Call/Email/etc
        </mat-expansion-panel-header>
        Select Relation


    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Save as Contactperson
        </mat-expansion-panel-header>
        Select Relation


    </mat-expansion-panel>
</mat-accordion> -->

    <br>

    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
        <button mat-button (click)="save()">Save</button>
    </div>
</div>