import { BASE_URL } from '../../shared/base.api';


// do not forgot to url encode spaces etcc.. !!  

export const StockBackgrounds = [
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/fish-under-the-sea.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/castle.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Merry_Christmas_07_7.png',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula01.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula02.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula03.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula04.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula05.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula06.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula07.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula08.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula09.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/nebula10.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/WinterSnowStorm.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-1.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-2.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-3.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-4.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-5.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-6.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-7.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-8.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-9.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/wood-white-rustic-10.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/green-black-tech.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Tropical-Pink.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/forest.jpg',

    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room01.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room02.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room03.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room04.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room05.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room06.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room07.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room08.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room09.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/room10.jpg',

    BASE_URL  + '/api/Containers/standardbackgroundimages/download/11.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/05.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/01.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/03.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/08.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/06.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/07.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/04.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/02.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/09.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/10.JPG',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/12.JPG',

    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart3.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart2.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart1.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart4.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart6.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/heart5.jpg',

    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool3.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool4.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool10.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool7.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool9.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool1.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool5.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool6.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool2.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/Wool8.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/sunlight-through-the-leaves.jpg',
    BASE_URL  + '/api/Containers/standardbackgroundimages/download/autumn-leaves.jpg',
]