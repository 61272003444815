import { Component, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  PublicationsApi,
  Publications,
  Container,
  ContainerApi,
  Relations,
  RelationsApi,
  Account,
  AccountApi,
  Company,
  CompanyApi,
  ChannelsApi,
  Channels,
  Files,
  Mailing,
  MailingApi,
  MailinglistApi,
  Mailinglist,
  GoogleanalyticsApi,
  Googleanalytics,
  MarketingplannereventsApi,
  Marketingplannerevents,
  Adwords,
  AdwordsApi,
  Forms,
  Mailinglistentries
} from '../shared/sdk';
import { BASE_URL, API_VERSION } from '../shared/base.api';
import { FileUploader } from 'ng2-file-upload';
import { FileuploadComponent } from '../shared/fileupload/fileupload.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location, NgClass, NgStyle } from '@angular/common';
import { DialogsService } from './../dialogsservice/dialogs.service';
import { RandomService } from '../dialogsservice/random.service';
import { LinkedinService } from '../shared/socialservice';

import { UntypedFormControl } from '@angular/forms';
import { UploadResult } from './xlsx-file-upload/xlsx-file-upload.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { map, startWith, timeoutWith } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';
declare const CKEDITOR: any;
const URL = 'http://localhost:3000/api/containers/tmp/upload';
import { MatDialog } from '@angular/material/dialog';

import { MarketingchannelsComponent } from './marketingchannels/marketingchannels.component';
import { timeconv } from '../shared/timeconv';
import { MaileditorComponent } from './maileditor/maileditor.component';
import { TextEditorDialog } from './maileditor/texteditordialog.component';
import { MarketingpublicationsComponent } from './marketingpublications/marketingpublications.component';

import { ChatsComponent, chatDialog } from './chats/chats.component';
import { MarketingformsComponent } from './marketingforms/marketingforms.component';
import { AppserviceService } from '../appservice.service';

export interface uploadResult {
  result: 'failure' | 'success';
  payload: any;
}

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})

export class MarketingComponent implements OnInit {
  myControlRelations = new UntypedFormControl();
  // chipinput for mailings
  @ViewChild('chipInput') chipInput: MatInput;

  @ViewChild(MarketingformsComponent, { static: false })
  private marketingformsComponent: MarketingformsComponent;
  @ViewChild(MaileditorComponent, { static: false })
  private mailEditorlComponent: MaileditorComponent;
  @ViewChild(MarketingchannelsComponent, { static: false })
  private marketingchannelsComponent: MarketingchannelsComponent;
  @ViewChild(MarketingpublicationsComponent, { static: false })
  private marketingpublicationsComponent: MarketingpublicationsComponent;

  @ViewChild(ChatsComponent, { static: false })
  private chatsComponent: ChatsComponent;

  @ViewChild(chatDialog, { static: false })
  private chatDialog: chatDialog;

  @Output() setMailingTemplate: EventEmitter<any> = new EventEmitter();

  public newMailinglistEntry: Mailinglistentries = new Mailinglistentries();
  public Marketingplannerevents: Marketingplannerevents[];
  public filtermailingselect;
  public togglecampaignclasstrans = [];
  public toggleCampaignMailing = [];
  public date;
  public CampaignMailing: Mailing[];
  public analytics_filters;
  public selectedanalytics: Googleanalytics = new Googleanalytics();
  public analytics_ids = 'ga:154403562';
  public analytics_startdate = '2008-10-01';
  public analytics_enddate = 'today';
  public analytics_metrics = [
    { expression: 'ga:bounceRate' },
    { expression: 'ga:pageviewsPerSession' },
    { expression: 'ga:goalStartsAll' },
    { expression: 'ga:avgTimeOnPage' },
  ]
  public analytics_dimensions = [{ name: 'ga:adContent' }];

  public Googleanalyticsreturn;
  public GoogleanalyticsSet;
  public Googleanalyticsnumbers;
  public Googleanalyticsnames;

  public GoogleanalyticsModel: Googleanalytics[];
  public avgTimeOnPage = '-';
  public bounceRate = '-';
  public goalStartsAll = '-';
  public pageview = '-';



  allowedMimeType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  maxFileSize = 10 * 1024 * 1024;

  public showgallery = false;
  public uploaderContent: BehaviorSubject<string> = new BehaviorSubject('Drop File Here');
  public linkedinPostId;
  public newURL: string;
  public urlckeditorupload: string;
  public ID: string;
  public uploader = new FileUploader({
    url: URL,
    allowedMimeType: this.allowedMimeType,
    // headers: [{name:'Accept', value:'application/json'}],
    autoUpload: false,
    maxFileSize: this.maxFileSize,
  });
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  public sub: any;
  public relationName: string;
  public Files: Files[];
  public newFiles: Files = new Files();
  public Adwords: Adwords[];
  public container: Container[];
  public Publications: Publications[];
  public Relations: Relations[];
  public Googleanalytics: Googleanalytics[];

  public Mailing: Mailing[];
  public newMailing: Mailing = new Mailing();
  public selectedMailing: Mailing;
  public selectedMailingList: Mailinglist;
  public response;
  public mailingresponse;
  public Mailinglist: Mailinglist[];
  public newMailinglist: Mailinglist = new Mailinglist();
  public newmailinglisttoggle = false;
  public callback
  public errorMessage;
  public selectedPublications: Publications;
  public images = [];
  public imagesNew = [];
  public listviewxsshow = false;
  public selectedAdwords: Adwords;
  public limitresult: 10;
  public urluse: string;
  public oneaddress;
  public onemonth = 2592000000;
  public headers = [];
  public emailheader;
  public firstnameheader = '';
  public lastnameheader = '';
  public companyheader = '';
  public titleheader = '';
  public mailinglistheader;
  public websiteheader = '';
  public var1header = '';
  public var2header = '';
  public var3header = '';
  public var4header = '';
  public createnewmailinglistonfield = false;
  public uploadlistId = [];
  public mailinglisttotal = 0;
  public mailinglistcount = 0;
  public numbers = [
    { value: '1', viewValue: '1' },
    { value: '20', viewValue: '20' },
    { value: '30', viewValue: '30' }
  ];
  public selectedtimezone;

  selectedOption = false;
  public error;
  public createItem: any;


  public mailinglist = [];
  public mailingaddress;
  public ReadyForUpload;
  //public maillist = [];
  public maillistTemp;
  //public listname;
  public uploadlistfinal = {};
  public toggleuploadlist = false;

  public adwordsoption: Adwords = new Adwords();
  public companypage = [];
  public convertdate;
  public time;
  public localdate;
  public toggletextview = false;
  public mailinglistdetails = [];
  public mailingaddresscampaign = [];
  public firstname;
  public lastname;
  public companyname;
  public title;
  //public analytics_metrics = 'ga:bounceRate,ga:pageviewsPerSession,ga:goalStartsAll,ga:avgTimeOnPage';
  //public analytics_dimensions = 'ga:adContent';
  public updateMailingObj: Mailing = new Mailing;
  public searchboxMailinglist;
  public filteredRelations: Relations[];
  public selectedTab = 0;
  public selectedTabMailing = 0;
  public selectedpublication: Publications;
  public selectedchannel: Channels;
  public selectedform: Forms;


  copyfrommailing: any;
  selectedItems: string[] = [];
  filteredItems: Observable<any[]>;
  addItems: UntypedFormControl;
  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  public options = [];
  public option: Relations;
  public SelectedRelation: Relations;
  public Account: Account;
  public company: Company;
  mailinglistentries: Mailinglistentries;
  skiplist: number = 0;
  totalmaillistentries: any;
  createnewmailinglistlarge: boolean = false;
  previewstring: any;
  spamscore: any;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public AdwordsApi: AdwordsApi,
    public timeconv: timeconv,
    public MarketingplannereventsApi: MarketingplannereventsApi,
    public GoogleanalyticsApi: GoogleanalyticsApi,
    public snackBar: MatSnackBar,
    public MailinglistApi: MailinglistApi,
    public MailingApi: MailingApi,
    public ContainerApi: ContainerApi,
    public ChannelaApi: ChannelsApi,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi,
    public RelationsApi: RelationsApi,
    public LinkedinService: LinkedinService,
    public dialogsService: DialogsService,
    public randomService: RandomService,
    public PublicationsApi: PublicationsApi,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public appservice: AppserviceService,
    public containerApi: ContainerApi) {
    // LoopBackConfig.setBaseURL(BASE_URL);
    // LoopBackConfig.setApiVersion(API_VERSION);

  }

  ngOnInit() {
    this.appservice.isAccounAvailable.subscribe((ready) => {
      if (ready) {
        this.Account = this.appservice.Account;
        this.getCurrentUserInfo()
      }
    });

    this.appservice.isOptionAvailable.subscribe((ready) => {
      if (ready) {
        this.option = this.appservice.option;
      }
    });

    this.appservice.isOptionsAvailable.subscribe((ready) => {
      if (ready) {
        this.options = this.appservice.options;
      }
    });

    this.appservice.isCompanyAvailable.subscribe((ready) => {
      if (ready) {
        this.company = this.appservice.company;
        this.getMailinglist();
      }
    });


    //this.SelectedRelation =  this.appservice.SelectedRelation
    //this.getCurrentUserInfo();
    this.setParameters(); // set url parameters
    // Clear the item queue (somehow they will upload to the old URL)
    this.uploader.clearQueue();

    // if (this.AccountApi.isAuthenticated() === false) { this.router.navigate(['login']) } move to appservice
  }

  public setParameters() {
    let itemid = this.route.snapshot.queryParamMap.get("itemid");
    let tab = this.route.snapshot.queryParamMap.get("tab");
    let relationid = this.route.snapshot.queryParamMap.get("relation");
    let chatid = this.route.snapshot.queryParamMap.get("chatid");
    let roomid = this.route.snapshot.queryParamMap.get("roomid");
    let accountid = this.route.snapshot.queryParamMap.get('accountid');
    let relationchatid = this.route.snapshot.queryParamMap.get('relationid')


    if (tab === 'chat') {
      this.selectedTab = 4;
      this.chatsComponent.getandopenChatDialog(roomid, chatid, relationchatid);
    }

    if (relationid) {
      this.CompanyApi.findByIdRelations(this.Account.companyId, relationid)
        .subscribe(res => {
          this.onSelectRelation(res);
          if (tab === 'mailing') {
            this.selectedTab = 0;
            this.RelationsApi.findByIdMailing(res.id, itemid).subscribe(res1 => {
              this.onSelectMailing(res1);
            })
          }
          if (tab === 'publication') {
            this.selectedTab = 3;
            this.RelationsApi.findByIdPublications(res.id, itemid).subscribe(res2 => {
              this.selectedpublication = res;;
            })
          }
          if (tab === 'channels') {
            this.selectedTab = 1;
            this.RelationsApi.findByIdChannels(res.id, itemid).subscribe(res2 => {
              this.selectedchannel = res2;
            })
          }
          if (tab === 'form') {
            this.selectedTab = 2;

            this.RelationsApi.findByIdForms(res.id, itemid).subscribe(form => {
              //this.selectedform = form;
              this.marketingformsComponent.onSelectForm(form);
              this.marketingformsComponent.selectedTab = 1;
            })
          }
        });
    }
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: 'snackbar-class'
    });
  }

  checkSpamScore() {
    if (this.selectedMailing.html) {
      this.MailingApi.checkSpam(this.selectedMailing.id, { text: this.selectedMailing.html }).subscribe(res => {
        this.spamscore = res;
      })
    }

  }

  getCurrentUserInfo(): void {
    //  this.AccountApi.getCurrent().subscribe((account: Account) => {
    //    this.Account = account;
    this.setParameters(); // set url parameters
    this.CompanyApi.getRelations(this.Account.companyId,
      {
        where: { marketingrelation: true },
        fields: { id: true, relationname: true }
      }
    ).subscribe((relations: Relations[]) => {
      this.Relations = relations
      if (this.Account.standardrelation !== undefined) {

        this.RelationsApi.findById(this.Account.standardrelation)
          .subscribe(rel => {
            //console.log(rel)
            this.onSelectRelation(rel),
              this.CompanyApi.findById(this.Account.companyId)
                .subscribe((company: Company) => {
                  this.company = company;
                });
          })
      }
      //   this.getrelationsEntry()
    });
    //  });
  }

  //display name in searchbox
  displayFnRelation(relation?: Relations): string | undefined {
    return relation ? relation.relationname : undefined;
  }

  searchGoQuick(value): void {
    let searchterm = value.trim();
    this.CompanyApi.getRelations(this.Account.companyId,
      {
        where:
        {
          or: [{ "relationname": { "regexp": searchterm + '/i' } },
          { "address1": { "regexp": searchterm + '/i' } },
          { "city": { "regexp": searchterm + '/i' } }
          ],
          marketingrelation: true
        },
        order: 'relationname ASC'
      })
      .subscribe((Relations: Relations[]) => {
        this.filteredRelations = Relations
        //console.log(this.filteredRelations)
      });
  }

  // filter and to lower case for search
  private filter(relationname: string): Relations[] {
    const filterValue = relationname.toLowerCase();
    return this.options.filter(option => option.relationname.toLowerCase().indexOf(filterValue) === 0);
  }





  tabChange(e) {

    setTimeout(() => {
      //console.log(e);
      switch (e.index) {
        case 0:
          //this.marketingpublicationsComponent.getPublications();
          break;
        case 1:
          //this.mailEditorlComponent.get
          break;
        case 2:
          this.marketingchannelsComponent.getChannels();
          break;
        case 3:
          this.marketingformsComponent.getForms();
          break;
        case 4:
          this.chatsComponent.getChats();
          break;
      }
    }, 500);
  }

  // select relation --> get info for all tabs
  onSelectRelation(option): void {
    this.option = option;
    //this.SelectedRelation = option;
    this.getMailing();
    this.getMailinglist();
    this.myControlRelations.reset();

    this.RelationsApi.getGoogleanalytics(this.option.id)
      .subscribe((googleanalytics: Googleanalytics[]) => {
        this.Googleanalytics = googleanalytics,
          this.AccountApi.addStdRelation(this.Account.id, option.id)
            .subscribe()
      })
  }

  // display name in searchbox
  displayFn(relation?: Relations): string | undefined {
    return relation ? relation.relationname : undefined;
  }


  // file upload 1
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  // file upload 2
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  // delete all or destroy??
  deletePicture(): void {
    this.selectedPublications.picturename = '', this.selectedPublications.pictureurl = '';
    this.RelationsApi.updateByIdPublications(this.option.id, this.selectedPublications.id).subscribe()
  }

  // getFiles(): void {
  //   this.ContainerApi.getFiles(this.selectedPublications.id)
  //     .subscribe((files: Files[]) => this.Files = files)
  // }

  onSelectMailingList(mailinglist: Mailinglist): void {
    // this.uploaderContent.subscription.unsubscribe();
    if (this.selectedMailingList) { this.saveMailingList(); }
    this.selectedMailingList = mailinglist;
    // this.maillist1 = [];
    // this.maillist = [];
    //this.mailinglistdetails = [];
    this.getMailinglistEntries();
    this.toggleuploadlist = true;


    //   this.MailinglistApi.mailinglistinfo(this.selectedMailingList.mailgunid, this.option.domain)
    //     .subscribe(res => {
    //       this.selectedMailingList.total = res.members_count,
    //         this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
    //           .subscribe()
    //     });
    //   this.MailinglistApi.mailinglistinfo('open' + this.selectedMailingList.mailgunid, this.option.domain)
    //     .subscribe(res => {
    //       this.selectedMailingList.totalopened = res.members_count,
    //         this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
    //           .subscribe()
    //     });
    //   this.MailinglistApi.mailinglistinfo('clicked' + this.selectedMailingList.mailgunid, this.option.domain)
    //     .subscribe(res => {
    //       this.selectedMailingList.totalclicked = res.members_count,
    //         this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
    //           .subscribe()
    //     });
  }

  showMailingList(): void {
    let mailinglistitems = [];
    this.MailinglistApi.showaddresses(this.selectedMailingList.mailgunid, this.option.domain)
      .subscribe(res => {
        mailinglistitems = res.items,
          // console.log(mailinglistitems);
          mailinglistitems.forEach((item, index) => {
            this.mailinglistdetails.push(item)
          }) // console.log(this.mailinglistdetails
      })
  }

  randomizeMailing(): void {
    this.randomService.openDialog(
      this.option.id, this.Account.companyId, this.selectedMailing, this.Mailinglist, this.Marketingplannerevents, this.Mailing)
    if (this.randomService.ready === true) {
      this.openSnackBar('Scheduled');
    }
    // console.log(this.selectedMailing)
  }



  // Mailing ______________________________________

  getMailing(): void {
    // select only mailings that are asigned to campaign (marketingplannereventsids)
    this.RelationsApi.getMailing(this.option.id,
      {
        order: 'id DESC',
        where: {
          marketingplannereventsIds: {
            exists: false
          }
        },
        include: {
          relation: 'mailinglist'
        }
      })
      .subscribe((mailing: Mailing[]) => this.Mailing = mailing);
  }

  createMailing(): void {
    this.RelationsApi.createMailing(this.option.id,
      { subject: 'new', relationname: this.option.relationname, companyId: this.option.companyId })
      .subscribe(res => { this.onSelectMailing(res), this.getMailing(); });
  }

  editMailing(): void {
    this.updateMailingObj = this.selectedMailing;
    console.log(this.updateMailingObj)
    this.setMailingTemplate.emit(this.updateMailingObj);
  }

  copyFromMailing(): void {
    this.selectedMailing.html = this.copyfrommailing.html;
    this.selectedMailing.subject = this.copyfrommailing.subject;
    this.selectedMailing.sectionStyle = this.copyfrommailing.sectionStyle;
    this.selectedMailing.templatearray = this.copyfrommailing.templatearray;
    this.selectedMailing.columnStyle = this.copyfrommailing.columnStyle;
    this.selectedMailing.relationname = this.copyfrommailing.relationname;
    this.selectedMailing.preview = this.copyfrommailing.preview;
    this.selectedMailing.companyId = this.Account.companyId;
  }

  sendMailing(): void {
    this.saveMailing();
    this.dialogsService
      .confirm('Send Mailing', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          if (this.selectedMailing.selectedlists?.length > 0) {
            this.MailingApi.sendmailing(this.option.companyId, this.selectedMailing, this.selectedMailing.id)
              .subscribe(res => {
                this.response = res,
                  this.selectedMailing.send = true;
                this.selectedMailing.status = this.response.message;
                this.openSnackBar(this.response.message);
                //     this.saveMailing();
              }, err => { this.openSnackBar(err.message) });

          } else {
            this.dialogsService.confirm('Error', 'No list or email selected');
          }
        }
      });
  }


  swiperight(e?) {
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }


  onSelectMailing(mailing: Mailing): void {
    if (this.selectedMailing){
      this.saveMailing();
    }
  
    this.selectedItems = [];
    this.selectedMailing = null;
    this.selectedMailing = mailing;
    this.mailingaddress = '';
    this.setFilterMailing();
    // this.checkSpamScore();

    this.Googleanalyticsreturn = '';
    this.avgTimeOnPage = '';
    this.bounceRate = '';
    this.goalStartsAll = '';
    this.pageview = '';

    this.mailingaddresscampaign = []; // first clean up a few things
    this.selectedItems = [];
    if (this.mailingaddress === undefined) { this.mailingaddress = []; }
    if (this.selectedMailing.selectedlists) {
      if (this.selectedMailing.selectedlists.length > 0) {
        Object.keys(this.selectedMailing.selectedlists).forEach(key => {
          const value = this.selectedMailing.selectedlists[key];
          // console.log(this.selectedMailing.selectedlists);
          if (value.listname) {
            this.selectedItems.push(value.listname);
          } else { this.selectedItems.push(value) }
        })
      }
    }
    this.prepareFilterMaillist(); // quick selection list

    let previewstring = '<div>' + mailing.html + '</div>';
    this.previewstring = this.sanitizer.bypassSecurityTrustHtml(previewstring);

    // set upload url for pictures and dialog

    //this.urlckeditorupload = BASE_URL + '/api/Containers/' + this.option.id + '/upload/';

    // set upload url for pictures
    // CKEDITOR.config.filebrowserBrowseUrl = BASE_URL + '/filemanager/' + this.option.id;
    // CKEDITOR.config.filebrowserUploadUrl = this.urlckeditorupload;
    // CKEDITOR.config.filebrowserImageBrowseUrl = BASE_URL + '/filemanager/' + this.option.id;
    // CKEDITOR.config.filebrowserImageUploadUrl = this.urlckeditorupload;
  }

  // onRequestCkEvent(evt): void {
  //   evt.stop(); // stop event and set data manual see above
  //   console.log('fileuploadresponse', evt)
  //   const url = BASE_URL + '/api/Containers/' + this.option.id + '/download/'
  //   const data = evt.data;
  //   data.url = url + data.fileLoader.fileName;
  //   // let finalurl = encodeURI(data.url)
  //   // data.fileName = data.fileLoader.fileName,
  //   // data.uploaded = 1;
  //   // const funcNum = 1;
  //   CKEDITOR.tools.callFunction(1, data.url);

  // };

  addTextMailing(): void {
    if (this.selectedMailing.html === undefined) {
      this.selectedMailing.html = this.Account.signature;
    }
    //console.log(this.selectedMailing.html);
    const dialogRef = this.dialog.open(TextEditorDialog, {
      width: '800px',
      data: this.selectedMailing.html, // changingThisBreaksApplicationSecurity,
      id: this.option.id
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.length > 0) {
          this.selectedMailing.html = result
        };  // this.sanitizer.bypassSecurityTrustHtml(result);
      }
    });

  }


  saveMailing(message?): void {
    this.selectedMailing.text = this.onChangeHtml(this.selectedMailing.templatearray); //convert to text
    // dats is set on select
    if (this.selectedMailing.date == null) {
      this.date = moment().format();
      this.selectedMailing.date = this.date
    }
    // timezone
    if (this.selectedMailing.timezone == null) {
      this.selectedMailing.timezone = moment.tz.guess();
    }

    // time
    if (this.selectedMailing.time == null) {
      this.time = moment().format('hh:mm')
      this.selectedMailing.time = this.time;
      this.selectedMailing.companyId = this.Account.companyId;
    }

    this.selectedMailing.date = this.timeconv.convertTime(this.selectedMailing.date, this.selectedMailing.time, this.selectedMailing.timezone);
    this.RelationsApi.updateByIdMailing(this.option.id, this.selectedMailing.id, this.selectedMailing)
      .subscribe(res => {
        this.getMailing()
        if (message !== undefined) { this.openSnackBar('message saved') }
      });
  }

  setFilterMailing(): void {
    this.filteredmailinglist = this.myAddressListControl.valueChanges
      .pipe(
        startWith(''),
        // map(options => options && typeof options === 'object' ? options.relationname : options),
        map(relationname => relationname ? this.filter(relationname) : this.options.slice())
      );
  }

  getEmailAddresslist(): void {
    this.Account.email
  }

  myAddressListControl: UntypedFormControl = new UntypedFormControl();

  filteredmailinglist: Observable<string[]>;

  filterMailing(email: string) {
    return this.mailinglist.filter(mailingaddress =>
      mailingaddress.listname.toLowerCase().indexOf(email.toLowerCase()) === 0);
  }


  displayFnMailing(mailinglist): string {
    return mailinglist ? mailinglist.listname : mailinglist;
  }

  public openDialogDeleteMailing() {
    this.dialogsService
      .confirm('Delete Mailing', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteMailing(this.selectedOption);
      });
  }

  public deleteMailing(selectedOption): void {
    if (selectedOption == true) {
      this.RelationsApi.destroyByIdMailing(this.option.id, this.selectedMailing.id)
        .subscribe(res => {
          this.error = res,
            this.selectedMailing = undefined,
            this.getMailing();
          this.openSnackBar('Mailing Deleted')
          // this.Mailing.splice(this.index, 1)
        })
    }
  }

  public openDialogScheduleMailing() {
    this.dialogsService
      .confirm('Schedule Mailing', 'Your mailing will be scheduled for delivery')
      .subscribe(res => {
        this.selectedOption = res, this.ScheduleMailing(this.selectedOption);
      });
  }

  public ScheduleMailing(selectedOption): void {

    if (selectedOption === true) {
      //this.selectedMailing.scheduled = true,
      this.saveMailing(), this.openSnackBar('message Scheduled'); //this.getMailingCampaignMailings()
    }
  }

  public toggleTextView(): void {
    if (this.toggletextview === true) {
      this.toggletextview = false
    }
    else { this.toggletextview = true }
  }

  // search mailing subject only
  public searchGoMailing(searchentry): void {
    this.RelationsApi.getMailing(this.option.id, { where: { subject: searchentry } })
      .subscribe((mailing: Mailing[]) => this.Mailing = mailing);
  }


  // get all the all the lists and get total count and total number of leads in it.
  getMailinglist(): void {
    this.mailinglistcount = 0;
    this.mailinglisttotal = 0;
    this.RelationsApi.getMailinglist(this.option.id,
      // { order: 'listname ASC' }
      { order: 'id DESC' }
    )
      .subscribe((mailinglist: Mailinglist[]) => {
        this.Mailinglist = mailinglist;
        this.RelationsApi.countMailinglist(this.option.id).subscribe(res => this.mailinglistcount);
        this.Mailinglist.forEach((list) => {
          this.mailinglisttotal = this.mailinglisttotal + list.total
        });
      });
  }





  public createMailingList(): void {
    this.RelationsApi.createMailinglist(this.option.id, this.newMailinglist)
      .subscribe(res => {
        this.openSnackBar('List created');
        // this.updateMailingList();
        //  this.createItem = res, this.selectedMailingList = this.createItem, this.createMailingListMG(), this.newmailinglisttoggle = false
      });
  }

  // create mailgun open/clicked/main lists
  // public createMailingListMG(): void {
  //   this.MailinglistApi.createmailinglist(this.selectedMailingList)
  //     .subscribe(res => { this.mailingresponse = res, this.updateMailingList() });
  // }

  public saveMailingList(): void {
    this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
      .subscribe(); //res => this.getMailinglist()
  }


  public toggleNewMailinglist(): void {
    // this.mailinglistForm.resetForm();
    this.newmailinglisttoggle = true;
  }

  // public updateMailingList(): void {
  //   this.selectedMailingList.mailgunid = this.mailingresponse.list.address;
  //   this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
  //     .subscribe(res => this.getMailinglist());
  // }

  public deleteMailingList(): void {
    this.toggleuploadlist = false;
    this.MailinglistApi.deletemailinglist(this.selectedMailingList.id)
      .subscribe();
    this.RelationsApi.destroyByIdMailinglist(this.option.id, this.selectedMailingList.id)
      .subscribe(res => this.getMailinglist(), this.selectedMailingList = undefined)
  }

  // search mailing subject only
  public searchGoMailingList(): void {

    this.RelationsApi.getMailinglist(this.option.id,
      {
        where:
        {
          or: [{ "listname": { "regexp": this.searchboxMailinglist + '/i' } },
          { "categorie": { "regexp": this.searchboxMailinglist + '/i' } },
          { "location": { "regexp": this.searchboxMailinglist + '/i' } }
          ]
        },
        order: 'relationname ASC',
        limit: 20
      })
      .subscribe((mailinglist: Mailinglist[]) => this.Mailinglist = mailinglist);
  }

  // convert files
  public xlsxUploaded(result: UploadResult) {
    //this.maillist = [];
    this.headers = [];
    this.emailheader = '';
    this.firstnameheader = '';
    this.lastnameheader = '';
    this.companyheader = '';
    this.titleheader = '';
    this.mailinglistheader = '';
    this.websiteheader = '';
    this.var1header = '';
    this.var2header = '';
    this.var3header = '';
    this.var4header = '';
    this.createnewmailinglistonfield = false;
    this.uploadlistId = [];
    this.uploaderContent.next(JSON.stringify(result.payload));
    this.maillistTemp = result.payload[0];

    //  this.headers.push(Object.keys(this.maillist1[0])[0]);


    let i = 0;
    do {
      this.headers.push(Object.keys(this.maillistTemp[0])[i]);
      console.log(Object.keys(this.maillistTemp[0])[i]);
      i++;
    }
    while (Object.keys(this.maillistTemp[0])[i] !== undefined);
    this.headers.push('');

  }

  // set variable and upload + save reference in Publications
  setupload(name): void {
    // this.uploader.clearQueue(),
    // this.newURL = undefined,
    // this.newURL = BASE_URL + '/api/Containers/' + this.option.id + '/upload',
    // this.uploader.setOptions({ url: this.newURL }),
    this.uploader.uploadAll();
    // define the file settings
    this.newFiles.name = name;
    this.newFiles.url = BASE_URL + "/api/Containers/" + this.option.id + "/download/" + name;
    this.newFiles.createdate = new Date();
    this.newFiles.type = "general";
    this.newFiles.companyId = this.Account.companyId;
  }

  uploadFilelarge(): void {
    this.RelationsApi.createFiles(this.option.id, this.newFiles)
      .subscribe(res => {
        this.MailinglistApi.addlargelist(this.option.id, this.Account.companyId, this.newFiles.url, this.selectedMailingList.id,
          this.createnewmailinglistlarge)
          .subscribe(res1 => {
            this.openSnackBar('Uploading mailing list');
            this.uploader.clearQueue();
          })
      }
      );
  }

  public openmailinglistwebsite(i): void {
    console.log(this.mailinglistdetails[i]);
    let res = this.mailinglistdetails[i].vars.website.substring(0, 3);
    let web = this.mailinglistdetails[i].vars.website
    res = res.toLowerCase();
    if (res === 'www') { window.open('http://' + web, '_blank'); }
    else if (res === 'htt') { window.open(web, '_blank'); }
    else { this.dialogsService.confirm('Not a valid URL', 'Please edit'); };
  }

  public async setHeaderPrepImport(): Promise<Array<Mailinglistentries>> {
    // const importlist = this.maillist;
    //  let size = importlist.length;
    let maillist: Array<Mailinglistentries> = [];

    for (let i = 0; i < this.maillistTemp.length; i++) {
      const value = this.maillistTemp[i];

      //  this.maillist1.forEach((value) => {
      // fixed format name, firstname, lastname, address, company, mailinglist
      if (this.companyheader === undefined) { this.openSnackBar('Info missing or wrong format'); }
      else {
        // create import value
        const importvalue: Mailinglistentries = new Mailinglistentries({
          name: value[this.firstnameheader] + ' ' + value[this.lastnameheader],
          email: value[this.emailheader], // change to value[valueselected]
          //  consts: {
          company: value[this.companyheader],
          firstname: value[this.firstnameheader],
          lastname: value[this.lastnameheader],
          title: value[this.titleheader],
          website: value[this.websiteheader],
          mailing: value[this.mailinglistheader],
          const1: value[this.var1header],
          const2: value[this.var2header],
          const3: value[this.var3header],
          const4: value[this.var4header],
          // }
        });
        maillist.push(importvalue);
      }
    }
    return maillist;
    //})
  }

  // convert html to text
  onChangeHtml(template: Array<any>) {
    console.log(template)
    let text = '';
    for (let i = 0; i < template.length; i++) {
      const section = template[i];
      for (let y = 0; y < section.length; y++) {
        const element = section[y][0];
        if (element) {
          if (element.type === 'Text' || element.type === 'Footer') {
            let tmptext = element.content.replace(/<(?:.|\n)*?>/gm, '');;
            tmptext = tmptext.replace(/&nbsp;/g, '');
            text = text + ' \n ' + tmptext;
          }
          if (element.type === 'Footer') {
            text = text + ' \n ' + element.content.replace(/<(?:.|\n)*?>/gm, '');
          }
          if (element.type === 'Button') {
            text = text + ' \n ' + element.buttontext;
          }
        }
      }

    }
    return text;
    // if (message) {
    //   let text = message.replace(/<(?:.|\n)*?>/gm, '');
    //   text = text.replace(/&nbsp;/g, '');
    //   return text;
    // }
  }

  // import and and update totals add to existing
  public runImportMailingList(): void {
    this.setHeaderPrepImport().then((maillist: Mailinglistentries[]) => {

      if (this.createnewmailinglistonfield === true) {
        // get all unique mailinglist names and trim
        const uniquelistname = Array.from(new Set(maillist.map((item: any) => item.consts.mailing.trim())));
        this.createNewListUpload(uniquelistname, maillist) // add to existing
      } else {
        this.MailinglistApi.createManyMailinglistentries(this.selectedMailingList.id, maillist).subscribe(res => {
          this.openSnackBar('Upload to mailinglist Finished')
        })
      }; // add to new
    })
  }

  public createNewListUpload(uniquelistname: Array<string>, maillist: Mailinglistentries[]) {
    this.dialogsService
      .confirm('This will create: ' + uniquelistname.length + ' new Mailing Lists', 'Are you sure you want to do this?')
      .subscribe(async res => {
        if (res) {
          // const listids: Array<string> = await this.createSeperateMailinglists(uniquelistname);
          // this.uploadMultipleList(listids);
          await this.createSeperateMailinglists(uniquelistname, maillist);
        }

      });
  }


  public async createSeperateMailinglists(uniquelistname, maillist: Mailinglistentries[]): Promise<Array<string>> {
    return new Promise(async (resolve, reject) => {
      let uploadlist: Array<string> = [];
      for (let i = 0; i < uniquelistname.length; i++) {
        const value = uniquelistname[i];
        let res: Mailinglist = await this.RelationsApi.createMailinglist(this.option.id, { listname: value }).toPromise();
        const sortedmaillist = this.sortByMailingName(value, maillist)
        this.MailinglistApi.createManyMailinglistentries(res.id, sortedmaillist).toPromise();
        //uploadlist.push(res.id)
      }
      resolve(uploadlist)
    })
  }

  sortByMailingName(value, maillist: Mailinglistentries[]): Array<Mailinglistentries> {
    let sortedlist: Array<Mailinglistentries> = []
    for (let i = 0; i < maillist.length; i++) {
      const entry = maillist[i];
      if (entry.mailing === value) {
        sortedlist.push(entry);
      }
    }
    return sortedlist;
  }

  public togglemultiplelist(): void {
    this.uploader.clearQueue(),
      this.newURL = undefined,
      this.newURL = BASE_URL + '/api/Containers/' + this.option.id + '/upload',
      this.uploader.setOptions({ url: this.newURL }),
      this.uploader.uploadAll();
    if (this.toggleuploadlist === true) {
      this.toggleuploadlist = false
    } else { this.toggleuploadlist = true }
  }

  // public uploadMultipleList(uploadlistId: Array<string>): void {
  //   const importlist = this.maillist;
  //   let size = importlist.length;
  //   const x = size / 999; // check if larger then 999 split as api limit is <1000
  //   if (x > 1) {
  //     for (let i = 1; i < x; i++) {
  //       const result = importlist.slice(0, 999);
  //       importlist.splice(0, 999)
  //       size = importlist.length;
  //       const uploadlistfinal = result;
  //       const id = 'alnfaisufhu76!@';
  //       const req = {
  //         maillist: uploadlistfinal,
  //         listname: uploadlistId
  //       }
  //       console.log(req);
  //       this.MailinglistApi.uploadMultipleList(req).subscribe();
  //     }
  //   }
  //   this.openSnackBar('Running Multi-List upload in background')
  // }

  // public importlist(): void {
  //   // move up --> this.listname = this.selectedMailingList.id;
  //   const importlist = this.maillist;
  //   let size = importlist.length;
  //   const x = size / 500; // check if larger then 500 split as api limit is <1000
  //   if (x > 1) {
  //     for (let i = 1; i < x; i++) {
  //       const result = importlist.slice(0, 500);
  //       importlist.splice(0, 500)
  //       size = importlist.length;
  //       const uploadlistfinal = result;

  //       if (uploadlistfinal[0].address === undefined) { 
  //         this.openSnackBar('Email missing or wrong format'); 
  //       } else {
  //         this.MailinglistApi.uploadmailinglist(uploadlistfinal, this.selectedMailingList.id)
  //           .subscribe(res => {});
  //       }
  //     }
  //   }
  //   else {
  //     const uploadlistfinal = this.maillist;
  //     if (uploadlistfinal[0].address === undefined) { this.openSnackBar('Email missing or wrong format'); }
  //     else {
  //       this.MailinglistApi.uploadmailinglist(uploadlistfinal, this.selectedMailingList.id)
  //         .subscribe(res => {
  //             this.RelationsApi.updateByIdMailinglist(this.option.id, this.selectedMailingList.id, this.selectedMailingList)
  //               .subscribe(res => { console.log(res), this.openSnackBar('Upload Started') });
  //         });
  //     }
  //   }

  // };

  // public async runImportDeleteListMember(): Promise<void> {
  //   let maillist: Mailinglistentries[] = await this.setHeaderPrepImport();
  //   const importlist = maillist;
  //   const size = importlist.length;
  //   const x = size / 500; // check if larger then 500 split as api limit is <1000
  //   const uploadlistfinal = maillist;
  //   this.MailinglistApi.deleteaddresses(this.selectedMailingList.mailgunid, uploadlistfinal)
  //     .subscribe(res => { this.openSnackBar('Running in background') });
  // };

  // public runImportVerifyList(): void {
  //   // let importlist = this.maillist;
  //   // let size = importlist.length;
  //   // let x = size / 500; // check if larger then 500 split as api limit is <1000
  //   const uploadlistfinal = this.maillist;
  //   if (uploadlistfinal[0].address === undefined) { this.openSnackBar('Email missing or wrong format'); }
  //   else {
  //     this.MailinglistApi.verifylist(uploadlistfinal, this.selectedMailingList.id)
  //       .subscribe();
  //     this.openSnackBar('Verify running in background');
  //   }
  // };


  // public async checkListOrCreate(): Promise<void> {
  //   this.uploadlistId = [];
  //   let maillist: Mailinglistentries[] = await this.setHeaderPrepImport()
  //   if (this.createnewmailinglistonfield === true) {
  //     // get all unique mailinglist names and trim
  //     const uniquelistname = Array.from(new Set(maillist.map((item: any) => item.consts.mailing.trim())));
  //     // check if it exists in current mailinglist !error prone as it is text only..
  //     this.Mailinglist.forEach((value) => {
  //       const checkpos = uniquelistname.indexOf(value.listname)
  //       if (checkpos !== -1) {
  //         this.uploadlistId.push({ listname: value.listname, mailid: value.id }),
  //           uniquelistname.splice(checkpos, 1)
  //       }
  //     })
  //     console.log(this.uploadlistId),
  //       this.createNewListMultipleDialog(uniquelistname, maillist)
  //   }
  //   else { this.runImportVerifyList() };
  // }

  // public createNewListMultipleDialog(uniquelistname, maillist): void {
  //   if (uniquelistname.length > 1) {
  //     const Uniquelistname = uniquelistname;
  //     this.dialogsService
  //       .confirm('This will create: ' + Uniquelistname.length + ' new Mailing Lists', 'Are you sure you want to do this?')
  //       .subscribe(res => {
  //         if (res) {
  //           this.createNewListMultiple(Uniquelistname, maillist);
  //         }
  //       });
  //   }
  // }

  // public createNewListMultiple(uniquelistname, mailllist): void {
  //   // if (selectedOption === true) {
  //   if (uniquelistname.length > 1) {
  //     uniquelistname.forEach((value) => {
  //       this.RelationsApi.createMailinglist(this.option.id, { listname: value })
  //         .subscribe(res1 => { // update mailgun id in loopback & set uploadid
  //           this.MailinglistApi.createmailinglist(res1).subscribe(res2 => {
  //             res1.mailgunid = res2.list.address;
  //             this.RelationsApi.updateByIdMailinglist(this.option.id, res1.id, res1)
  //               .subscribe(res => { this.uploadlistId.push({ listname: res.listname, mailid: res.mailgunid }) }
  //               );
  //           });
  //         });
  //     });
  //   }
  //   // else { this.uploadVerifyList(); }
  //   // } else { this.runImportVerifyList(); }
  // }

  // public uploadVerifyList(): void {
  //   const id = 'alnfaisufhu76!@';
  //   const req = {
  //     maillist: this.maillist,
  //     listname: this.uploadlistId
  //   }
  //   console.log(req);
  //   this.MailinglistApi.uploadMultipleList(req).subscribe();
  // }


  // deleteOneMailingList(address?): void {
  //   // if (address !== null) { this.oneaddress = address } creates error address is not used so not use for fixing
  //   this.oneaddress = this.oneaddress.replace(/ /g, '');
  //   this.MailinglistApi.deleteoneaddress(this.selectedMailingList.mailgunid, this.oneaddress)
  //     .subscribe(res => this.openSnackBar(res.message));
  // }

  validateEmail(email) {
    return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/).test(email);
  }

  addOneMailingList(): void {


    if (this.validateEmail(this.newMailinglistEntry.email)) {
      this.MailinglistApi.createMailinglistentries(this.selectedMailingList.id, this.newMailinglistEntry).subscribe(() => {
        this.openSnackBar('Created');
        this.newMailinglistEntry = new Mailinglistentries();
        this.searchGoMailingList();
      }, error => {
        this.openSnackBar(error)
      });
      // this.MailinglistApi.addoneaddress(this.selectedMailingList.mailgunid,
      //   this.oneaddress,
      //   this.firstname,
      //   this.lastname,
      //   this.companyname,
      //   this.title)
      //   .subscribe(res => this.openSnackBar(res.message));
    } else {
      this.openSnackBar('Invalid Email address please provide a valid email address')
    }

  }

  getMailinglistEntries() {
    this.MailinglistApi.countMailinglistentries(this.selectedMailingList.id).subscribe(nr => {
      this.totalmaillistentries = nr.count;
    })
    this.MailinglistApi.getMailinglistentries(this.selectedMailingList.id, {
      skip: this.skiplist,
      limit: 100
    })
      .subscribe((mailinglistentries: Mailinglistentries) => {
        this.mailinglistentries = mailinglistentries;
      })
  }

  getMailingEntriesNextpage(): void {
    if (100 < this.totalmaillistentries) {
      this.skiplist = this.skiplist += 100;
      this.getMailinglistEntries()
    }
  }

  getMailingEntriesbackpage(): void {
    if (this.skiplist > 0) {
      this.skiplist = this.skiplist -= 100;
      this.getMailinglistEntries()
    }
  }


  public getAnalytics() {
    this.analytics_filters = 'ga:adContent';
    //this.analytics_filtervalue =
    this.GoogleanalyticsApi.getanalyticsreport(this.selectedanalytics.id, this.analytics_ids, this.analytics_startdate,
      this.analytics_enddate, this.analytics_dimensions, this.analytics_metrics, this.analytics_filters, this.selectedMailing.id)
      .subscribe((data) => {

        const obj = data.rows.find(o => o[0] === this.selectedMailing.id);
        if (obj !== undefined) {
          this.Googleanalyticsreturn = obj; // data.rows[0],
          this.avgTimeOnPage = this.Googleanalyticsreturn[4];
          this.bounceRate = this.Googleanalyticsreturn[1];
          this.goalStartsAll = this.Googleanalyticsreturn[3];
          this.pageview = this.Googleanalyticsreturn[2];
        } else { this.avgTimeOnPage = 'No Data to show' }
      }), this.openSnackBar('No website statistics found, try back later'); // error => { console.log(error),
  }



  public showTemplatePreview(): void {
    let htmlpreview = [];
    htmlpreview.push(this.sanitizer.bypassSecurityTrustHtml(this.selectedMailing.html));
    this.dialogsService
      .confirm('', '', htmlpreview[0])
      .subscribe();
  }





  prepareFilterMaillist() {
    console.log(this.Mailinglist)
    // formcontrol initialization
    this.addItems = new UntypedFormControl();

    // asynchronous value changes via reactive form control
    // import Reactive forms module for using it
    this.filteredItems = this.addItems.valueChanges
      .pipe(
        startWith(''),
        map(item =>
          item ? this.filterItems(item.toString()) : this.Mailinglist.slice())
      );
  }

  // filter the Languages with its matched text
  filterItems(itemName: string) {
    return this.Mailinglist.filter(item =>
      item.listname.toLowerCase().indexOf(itemName.toLowerCase()) === 0);
  }


  // removes the items based on its name
  onRemoveItemsMailing(itemName: string, i): void {
    this.selectedItems = this.selectedItems.filter((name: string) => name !== itemName);
    this.itemsData = this.selectedItems;
    this.chipInput['nativeElement'].blur();
    //check name and remove from all lists
    this.selectedMailing.mailinglist.forEach((list, index) => {
      if (list.listname === itemName) {
        console.log(index, 'index')
        this.selectedMailing.mailinglist.splice(index, 1);
        this.selectedMailing.mailinglistId.splice(index, 1);
      }
    })
    this.selectedMailing.selectedlists.splice(i, 1);
    // this.saveMailing('saved');
  }


  onAddItemsMailing(event: MatAutocompleteSelectedEvent) {
    if (this.selectedMailing.mailinglist === undefined) {
      this.selectedMailing.mailinglist = [];
    }
    if (this.selectedMailing.selectedlists === undefined) {
      this.selectedMailing.selectedlists = [];
    }
    const t = event.option.value;
    console.log(t);
    // if array is empty then push the elements
    // if (this.selectedItems.length === 0) {
    //   const listn = t.listname;
    //   this.selectedItems.push(listn);
    //   this.selectedMailing.mailinglistId.push(t.id);
    //   this.selectedMailing.mailinglist.push(listn);
    // } else {
    // if items already present then items will not be added to the array
    // stringfying the array to find names similiar
    const selectMailingStr = JSON.stringify(this.selectedItems);
    if (selectMailingStr.indexOf(t.listname) === -1) {
      this.selectedItems.push(t.listname);
      this.selectedMailing.mailinglistId.push(t.id);
      this.selectedMailing.mailinglist.push(t);
      this.selectedMailing.selectedlists.push(t.listname);
      //}
    };

    // filter those mailinglists that are selected to avoid duplication
    this.itemsData = this.selectedItems;
    this.chipInput['nativeElement'].blur();
    this.chipInput['nativeElement'].value = '';
  }


  onAddItemManual($event) {
    console.log($event)
    if (this.selectedMailing.mailinglist === undefined) {
      this.selectedMailing.mailinglist = [];
    }
    if (this.selectedMailing.selectedlists === undefined) {
      this.selectedMailing.selectedlists = [];
    }
    // console.log($event);
    // this.mailingaddress = $event.srcElement.value;
    this.selectedItems.push($event.srcElement.value);
    this.selectedMailing.mailinglist.push($event.srcElement.value);
    this.selectedMailing.selectedlists.push({ listname: $event.srcElement.value });
    this.itemsData = this.selectedItems;
    this.chipInput['nativeElement'].blur();
    this.chipInput['nativeElement'].value = '';
  }


  // update the array value
  get itemsData(): string[] {
    return this.selectedItems;
  }

  // update the array value
  set itemsData(v: string[]) {
    this.selectedItems = v;
  }


  getAdwords(): void {
    this.RelationsApi.getAdwords(this.option.id).subscribe((adwords: Adwords[]) => this.Adwords = adwords);
  }

  onSelectAdwords(adwords: Adwords): void {
    this.selectedAdwords = adwords;
  }

  getAdwordsCampaign(): void {
    this.AdwordsApi.getcampaign(this.adwordsoption.refresh_token).subscribe(res => res = res);
  }

  publishAdwordsCampaign(): void { }


  copyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  setChannel(e) {
    this.marketingchannelsComponent.onSelectChannels(e);
    this.selectedTab = 2;
  }

};


