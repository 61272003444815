/* tslint:disable */
import {
  Mailing,
  Calls,
  Company,
  Publications,
  Marketingplannerevents,
  Mailinglistentries,
  Linktracker
} from '../index';

declare var Object: any;
export interface LinkInterface {
  "link": string;
  "redirecturl": string;
  "type": string;
  "createdate": Date;
  "headerlink"?: boolean;
  "mailinglistentriesId"?: any;
  "id"?: any;
  "mailingId"?: any;
  "callsId"?: any;
  "companyId"?: any;
  "publicationsId"?: any;
  "marketingplannereventsId"?: any;
  mailing?: Mailing;
  calls?: Calls;
  company?: Company;
  publications?: Publications;
  marketingplannerevents?: Marketingplannerevents;
  mailinglistentries?: Mailinglistentries;
  linktracker?: Linktracker[];
}

export class Link implements LinkInterface {
  "link": string;
  "redirecturl": string;
  "type": string;
  "createdate": Date;
  "headerlink": boolean;
  "mailinglistentriesId": any;
  "id": any;
  "mailingId": any;
  "callsId": any;
  "companyId": any;
  "publicationsId": any;
  "marketingplannereventsId": any;
  mailing: Mailing;
  calls: Calls;
  company: Company;
  publications: Publications;
  marketingplannerevents: Marketingplannerevents;
  mailinglistentries: Mailinglistentries;
  linktracker: Linktracker[];
  constructor(data?: LinkInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Link`.
   */
  public static getModelName() {
    return "Link";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Link for dynamic purposes.
  **/
  public static factory(data: LinkInterface): Link{
    return new Link(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Link',
      plural: 'Links',
      path: 'Links',
      idName: 'id',
      properties: {
        "link": {
          name: 'link',
          type: 'string'
        },
        "redirecturl": {
          name: 'redirecturl',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "createdate": {
          name: 'createdate',
          type: 'Date'
        },
        "headerlink": {
          name: 'headerlink',
          type: 'boolean'
        },
        "mailinglistentriesId": {
          name: 'mailinglistentriesId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "mailingId": {
          name: 'mailingId',
          type: 'any'
        },
        "callsId": {
          name: 'callsId',
          type: 'any'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
        "publicationsId": {
          name: 'publicationsId',
          type: 'any'
        },
        "marketingplannereventsId": {
          name: 'marketingplannereventsId',
          type: 'any'
        },
      },
      relations: {
        mailing: {
          name: 'mailing',
          type: 'Mailing',
          model: 'Mailing',
          relationType: 'belongsTo',
                  keyFrom: 'mailingId',
          keyTo: 'id'
        },
        calls: {
          name: 'calls',
          type: 'Calls',
          model: 'Calls',
          relationType: 'belongsTo',
                  keyFrom: 'callsId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        publications: {
          name: 'publications',
          type: 'Publications',
          model: 'Publications',
          relationType: 'belongsTo',
                  keyFrom: 'publicationsId',
          keyTo: 'id'
        },
        marketingplannerevents: {
          name: 'marketingplannerevents',
          type: 'Marketingplannerevents',
          model: 'Marketingplannerevents',
          relationType: 'belongsTo',
                  keyFrom: 'marketingplannereventsId',
          keyTo: 'id'
        },
        mailinglistentries: {
          name: 'mailinglistentries',
          type: 'Mailinglistentries',
          model: 'Mailinglistentries',
          relationType: 'belongsTo',
                  keyFrom: 'mailinglistentriesId',
          keyTo: 'id'
        },
        linktracker: {
          name: 'linktracker',
          type: 'Linktracker[]',
          model: 'Linktracker',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'linkId'
        },
      }
    }
  }
}
