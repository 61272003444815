
export const sourcelang = [
    { code: 'BG', description: 'Bulgarian' },
    { code: 'CS', description: 'Czech' },
    { code: 'DA', description: 'Danish' },
    { code: 'DE', description: 'German' },
    { code: 'EL', description: 'Greek' },
    { code: 'EN', description: 'English' },
    { code: 'ES', description: 'Spanish' },
    { code: 'ET', description: 'Estonian' },
    { code: 'FI', description: 'Finnish' },
    { code: 'FR', description: 'French' },
    { code: 'HU', description: 'Hungarian' },
    { code: 'ID', description: 'Indonesian' },
    { code: 'IT', description: 'Italian' },
    { code: 'JA', description: 'Japanese' },
    { code: 'LT', description: 'Lithuanian' },
    { code: 'LV', description: 'Latvian' },
    { code: 'NL', description: 'Dutch' },
    { code: 'PL', description: 'Polish' },
    { code: 'PT', description: 'Portuguese' },
    { code: 'RO', description: 'Romanian' },
    { code: 'RU', description: 'Russian' },
    { code: 'SK', description: 'Slovak' },
    { code: 'SL', description: 'Slovenian' },
    { code: 'SV', description: 'Swedish' },
    { code: 'TR', description: 'Turkish' },
    { code: 'UK', description: 'Ukrainian' },
    { code: 'ZH', description: 'Chinese' },
]

export const targetlang = [
    { code: 'BG', description: 'Bulgarian' },
    { code: 'CS', description: 'Czech' },
    { code: 'DA', description: 'Danish' },
    { code: 'DE', description: 'German' },
    { code: 'EL', description: 'Greek' },
    //  EN', description: 'English (unspecified variant for backward compatibility; please select EN-GB or EN-US instead)
    { code: 'EN-GB', description: 'English (British)' },
    { code: 'EN-US', description: 'English (American)' },
    { code: 'ES', description: 'Spanish' },
    { code: 'ET', description: 'Estonian' },
    { code: 'FI', description: 'Finnish' },
    { code: 'FR', description: 'French' },
    { code: 'HU', description: 'Hungarian' },
    { code: 'ID', description: 'Indonesian' },
    { code: 'IT', description: 'Italian' },
    { code: 'JA', description: 'Japanese' },
    { code: 'LT', description: 'Lithuanian' },
    { code: 'LV', description: 'Latvian' },
    { code: 'NL', description: 'Dutch' },
    { code: 'PL', description: 'Polish' },
    //   PT', description: 'Portuguese (unspecified variant for backward compatibility; please select PT-BR or PT-PT instead)
    { code: 'PT-BR', description: 'Portuguese (Brazilian)' },
    { code: 'PT-PT', description: 'Portuguese (all Portuguese varieties excluding Brazilian Portuguese)' },
    { code: 'RO', description: 'Romanian' },
    { code: 'RU', description: 'Russian' },
    { code: 'SK', description: 'Slovak' },
    { code: 'SL', description: 'Slovenian' },
    { code: 'SV', description: 'Swedish' },
    { code: 'TR', description: 'Turkish' },
    { code: 'UK', description: 'Ukrainian' },
    { code: 'ZH', description: 'Chinese (simplified)' },
]