import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account, Adwords, Facebook, Googleanalytics, Linkedin, AdwordsApi,
  TwitterApi, LinkedinApi, FacebookApi, PinterestApi, Pinterest, MailingApi,
  Relations, Twitter, RelationsApi, GoogleanalyticsApi, CompanyApi } from 'src/app/shared/sdk';
import { DialogGetname } from './../../dialogsservice/dialog.getname';

@Component({
  selector: 'app-connectedaccounts',
  templateUrl: './connectedaccounts.component.html',
  styleUrls: ['./connectedaccounts.component.scss']
})
export class ConnectedaccountsComponent implements OnInit {
  @Input() selectedRelation: Relations;
  @Input() Account: Account;
  

  public newGoogleAnalytics: Googleanalytics = new Googleanalytics();
  public newTwitter: Twitter = new Twitter();
  public newLinkedin: Linkedin = new Linkedin();
  public newFacebook: Facebook = new Facebook();
  public newAdwords: Adwords = new Adwords();
  public newPinterest: Pinterest = new Pinterest();

  public Googleanalytics: Googleanalytics[];
  public Twitter: Twitter[];
  public Linkedin: Linkedin[];
  public Facebook: Facebook[];
  public Pinterest: Pinterest[];

  public selectedAdwords: Adwords;
  public Adwords: Adwords[];
  
  //public currentdomain: string;
  public domainresponse;
  trackingcode: string;
  public enydea_id: string;
  public enydea_key: string;

  

  constructor(  
    public CompanyApi: CompanyApi,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public RelationsApi: RelationsApi,
    public GoogleanalyticsApi: GoogleanalyticsApi,
    public TwitterApi: TwitterApi,
    public LinkedinApi: LinkedinApi,
    public FacebookApi: FacebookApi,
    public PinterestApi: PinterestApi,
    public AdwordsApi: AdwordsApi,
    public MailingApi: MailingApi,
  ) { }

  ngOnInit(): void {
    this.getGoogleAnalytics();
    //this.currentdomain = this.selectedRelation.domain;
    this.trackingcode = '<script type="text/javascript">' +
    'var currentLocation=window.location,url=new URL("' +
    'https://app.enydea.com/api/websitetrackers/registervisit?id=' + this.selectedRelation.id + '");url.searchParams.append("src",currentLocation);var xmlHttp=new XMLHttpRequest;xmlHttp.onreadystatechange=function(){4==xmlHttp.readyState&&200==xmlHttp.status&&callback(xmlHttp.responseText)},xmlHttp.open("GET",url,!0),xmlHttp.send(null);' +
    '</script>';

    this.enydea_key = this.Account.companyId;
    const myCipher = this.cipher(this.enydea_key)
    this.enydea_id = myCipher(this.selectedRelation.id);
  }


  getGoogleAnalytics(): void {
    this.RelationsApi.getGoogleanalytics(this.selectedRelation.id)
      .subscribe((Googleanalytics: Googleanalytics[]) => this.Googleanalytics = Googleanalytics)
  }

  createGoogleAnalytics(): void {
    this.newGoogleAnalytics.companyId = this.Account.companyId; //include companyid for roleresolver
    this.RelationsApi.createGoogleanalytics(this.selectedRelation.id, this.newGoogleAnalytics)
      .subscribe(res => this.getGoogleAnalytics());
  }

  getTwitter(): void {
    this.RelationsApi.getTwitter(this.selectedRelation.id)
      .subscribe((Twitter: Twitter[]) => this.Twitter = Twitter)
  }

  getPinterest(): void {
    this.RelationsApi.getPinterest(this.selectedRelation.id)
      .subscribe((Pinterest: Pinterest[]) => this.Pinterest = Pinterest)
  }

  deleteTwitter(i): void {
    this.RelationsApi.destroyByIdTwitter(this.selectedRelation.id, this.Twitter[i].id)
      .subscribe(res => this.getTwitter());
  }

  deletePinterest(i): void {
    this.RelationsApi.destroyByIdPinterest(this.selectedRelation.id, this.Pinterest[i].id)
      .subscribe(res => this.getPinterest());
  }


  getLinkedin(): void {
    this.newLinkedin = new Linkedin;
    this.RelationsApi.getLinkedin(this.selectedRelation.id)
      .subscribe((Linkedin: Linkedin[]) => this.Linkedin = Linkedin);
  }

  linkTwitterAccount(): void {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'Twitter Account' }
    });
    dialogRef.afterClosed().subscribe(result => {
      let twitter = new Twitter();
      twitter.name = result;
      this.RelationsApi.createTwitter(this.selectedRelation.id, twitter)
        .subscribe(res => {
          this.newTwitter = res,
            this.redirectTwitter(this.newTwitter.id);
          this.openSnackBar("You will be redirected to Twitter.com");
        })
    });
  };

  redirectTwitter(id): void {
    
    const domain = location.protocol + '//' + location.host + location.pathname;
   // var domain = window.location.protocol + window.location.hostname + ":3000"; //set domain + protocol + 3000 for test purpose only
    this.TwitterApi.sessionsconnect(id, domain).subscribe(res => {
        window.location.href = res;
    });

  }

  linkPinterestAccount(): void {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'Pinterest Account' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.newPinterest.name = result;
      this.RelationsApi.createPinterest(this.selectedRelation.id, this.newPinterest)
        .subscribe(res => {
          //console.log(res.id);
          this.redirectPinterest(res.id)
          this.openSnackBar("You will be redirected to Pinterest.com")
        })
    });
  };

  redirectPinterest(id): void {
    let redirect;
    var domain = location.protocol + '//' + location.host + location.pathname;
  //  const domain = window.location.protocol + window.location.hostname + ":3000"; //set domain + protocol + 3000 for test purpose only
    this.PinterestApi.sessionsconnect(id, domain).subscribe(res => {
      console.log(res),
      redirect = res.res,
        window.location.href = redirect;//redirect.res.request.uri.href;
    });
  }


  linkLinkedinAccount(): void {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'Linkedin Account' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.newLinkedin.name = result;
      this.RelationsApi.createLinkedin(this.selectedRelation.id, this.newLinkedin)
        .subscribe(res => {
          this.newLinkedin = res,
            this.redirectLinkedin(this.newLinkedin.id);
          this.openSnackBar("You will be redirected to Linkedin.com");
        })
    });
  };

  redirectLinkedin(id): void {
    let redirect;
    let domain = location.protocol + '//' + location.host + location.pathname;
    
    // console.log(domain);
    // domain = domain.replace('relation', 'returnpage')
    this.LinkedinApi.linkedinauth(id, domain).subscribe(res => {
      redirect = res, window.location.href = redirect.request.uri.href;
    });
  };

  deleteLinkedin(i): void {
    this.RelationsApi.destroyByIdLinkedin(this.selectedRelation.id, this.Linkedin[i].id)
      .subscribe(res => this.getLinkedin());
  }

  deleteAnalytics(i): void {
    this.RelationsApi.destroyByIdGoogleanalytics(this.selectedRelation.id, this.Googleanalytics[i].id)
      .subscribe(res => this.getGoogleAnalytics());
  }

  getFacebook(): void {
    this.newFacebook = new Facebook;
    this.RelationsApi.getFacebook(this.selectedRelation.id)
      .subscribe((Facebook: Facebook[]) => this.Facebook = Facebook)
  }

  linkFacebookAccount(): void {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'Facebook Account' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.newFacebook.name = result;
      this.RelationsApi.createFacebook(this.selectedRelation.id, this.newFacebook)
        .subscribe(res => {
          this.redirectFacebook(res.id)
          this.openSnackBar("You will be redirected to Facebook.com")
        });
    });
  };

  redirectFacebook(ids): void {
    //console.log(ids);
    var domain = location.protocol + '//' + location.host + location.pathname;
   // let domain = window.location.href;//+ ":3000"; //set domain + protocol + 3000 for test purpose only
    this.FacebookApi.sessionsconnect(ids, domain).subscribe(res => { 
      window.location.href = res; 
    });
  };

  showFacebook(facebook): void {
    this.FacebookApi.me(facebook.AccessToken)
      .subscribe(res => this.openSnackBar(res)); // "Accountname: " + res.name
  }

  updateFacebook(facebook): void {
    this.RelationsApi.updateByIdFacebook(this.selectedRelation.id, facebook.id, facebook).subscribe(res => this.openSnackBar("Facebook saved"));
  }


  deleteFacebook(facebook): void {
    this.RelationsApi.destroyByIdFacebook(this.selectedRelation.id, facebook.id)
      .subscribe(res => {
        // add delete hook to remove authentication from facebook
        this.getFacebook(),
          this.openSnackBar("Facebook Deleted")
      }
      );
  }

  // //Adwordsstart
  // getAdwords(): void {
  //   this.RelationsApi.getAdwords(this.selectedRelation.id).subscribe((Adwords: Adwords[]) => this.Adwords = Adwords);
  // }

  // onSelectAdwords(Adwords: Adwords): void {
  //   this.selectedAdwords = Adwords;
  // }

  // createAdwords(): void {
  //   var redirect;
  //   var domain = window.location.protocol + '//' + window.location.hostname //+ ":3000"; //set domain + protocol + 3000 for test purpose only
  //   this.RelationsApi.createAdwords(this.selectedRelation.id, { account: this.selectedRelation.relationname })
  //     .subscribe(res => {
  //       this.selectedAdwords = res,
  //         this.AdwordsApi.redirect(this.selectedAdwords.id, domain).subscribe(res => { redirect = res, window.location.href = redirect; });
  //       this.getAdwords()
  //     });
  // }

  // deleteAdwords(i): void {
  //   this.RelationsApi.destroyByIdAdwords(this.selectedRelation.id, this.Adwords[i].id)
  //     .subscribe(res => this.getAdwords());
  // }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: "snackbar-class"
    });
  }


  deleteDomain() {
    this.MailingApi.deletedomain(this.selectedRelation.companyId, this.selectedRelation.domain)
      .subscribe(res =>
        this.openSnackBar(res));
  }

  setDomain(): void {
    
    this.saveRelation();
    this.MailingApi.setdomain(this.selectedRelation.companyId, this.selectedRelation.domain)
      .subscribe(res => {
        this.openSnackBar('Saved Domain')
      });
  }

  checkDomainSettings(): void {
    
    this.MailingApi.getdomaininfo(this.selectedRelation.companyId, this.selectedRelation.domain)
      .subscribe(res => {
        if (res.sending_dns_records[1].value) {
          this.domainresponse = res.sending_dns_records[1].value,
            this.openSnackBar('Domain set')
        }
      });
  }

  
  saveRelation(): void {
    this.CompanyApi.updateByIdRelations(this.selectedRelation.companyId,
      this.selectedRelation.id, this.selectedRelation)
      .subscribe(res => {
      }, error => {
        console.error(error)
      });
    // }
  }



  async refreshPinterest(i){
    try {
      let response  = await this.PinterestApi.refreshAccesstoken(this.Pinterest[i].id).toPromise()
      this.openSnackBar('Refresh Succesfull')
    } catch (error) {
      console.log(error);
      this.openSnackBar('Refresh Failed delete and recreate account')
    }
  }

  async refreshLinkedin(i){
    try {
      await this.LinkedinApi.refreshaccesstoken(this.Linkedin[i].id).toPromise();
      this.openSnackBar('Refresh Succesfull')
    } catch (error) {
      console.log(error);
      this.openSnackBar('Refresh Failed delete and recreate account')
    }

  }

  async refreshTwitter(i){
    try {
      console.log('implement account check')
      let response  = await this.TwitterApi.verify(this.Twitter[i].id).toPromise()
      this.openSnackBar('Refresh Succesfull')
    } catch (error) {
      console.log(error);
      this.openSnackBar('Refresh Failed delete and recreate account')
    }

  }

  async refreshFacebook(facebook: Facebook){
    try {
      console.log('implement account check');
     let response = this.FacebookApi.me(facebook.AccessToken).toPromise();
     this.openSnackBar('Facebook is setup correctly')
    //  let response = await this.TwitterApi.refresh(facebook.id).toPromise()
      //this.openSnackBar('Refresh Succesfull')
    } catch (error) {
      console.log(error);
      this.openSnackBar('Refresh Failed delete and recreate account')
    }

  }


  copyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar("Copied")
  }

  cipher = salt => { 
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }


}
