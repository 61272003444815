import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BASE_URL } from 'src/app/shared/base.api';
import { Account, Relations } from 'src/app/shared/sdk';
import { mailtemplatecodes } from './mailtemplatescodes';

@Component({
  selector: 'app-mailtemplates',
  templateUrl: './mailtemplates.component.html',
  styleUrls: ['./mailtemplates.component.scss']
})
export class MailtemplatesComponent implements OnInit {

  public templates = [
    BASE_URL + '/api/Containers/standardmailtemplates/download/template1.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template2.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template3.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template4.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template5.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template6.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template7.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template8.png',
    BASE_URL + '/api/Containers/standardmailtemplates/download/template9.png',
  ]

  public customtemplates = [
    // BASE_URL + '/api/Containers/standardmailtemplates/download/template9.png'
  ]
  selectindex: any;


  constructor(
    public dialogRef: MatDialogRef<MailtemplatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: string, account: Account, relation: Relations }
  ) { }

  ngOnInit(): void {
  }

  selectedtemplate(item, i){
    this.data.selected = item;
    this.selectindex = i;
  }

  select(){
    let template = mailtemplatecodes[this.selectindex];
    console.log(template)
    this.dialogRef.close(template);
  }

}
