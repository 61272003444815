<mat-card class="mailing" *ngIf="CampaignMailing"
    [class.campmailingnotsend]="CampaignMailing.scheduled && CampaignMailing.send === false"
    [class.campmailingdone]="CampaignMailing.scheduled && CampaignMailing.send === true">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between start">

            <div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
                    <label i18n>Subject: {{ CampaignMailing.subject }}</label>
                    <div style="position: absolute; top: 3px; left: 3px;">
                        <mat-icon class="iconsize-10" *ngIf="CampaignMailing.scheduled && CampaignMailing.send === false">
                            schedule </mat-icon>
                        <mat-icon class="iconsize-10" *ngIf="CampaignMailing.done === true">done_all</mat-icon>
                        <mat-icon class="iconsize-10" *ngIf="CampaignMailing.scheduled === false && CampaignMailing.send === false">
                            build</mat-icon>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <label style="font-size: 8pt" i18n>Title: {{ CampaignMailing.title }}</label>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <label style="font-size: 8pt">Date: {{ CampaignMailing.date | date }},
                        {{ CampaignMailing.time }}</label>
                </div>
            </div>
            <div>
                <button mat-icon-button [matMenuTriggerFor]="menucampaign">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menucampaign="matMenu">
                    <button mat-menu-item (click)="openDialogDeleteMailingCampaign()">
                        <mat-icon>delete</mat-icon>
                        <label i18n>Delete</label>
                    </button>
                    <button mat-menu-item (click)="openEdit()">
                        <mat-icon>edit</mat-icon>
                        <label i18n>Edit</label>
                    </button>
                </mat-menu>
            </div>

        </div>
    </mat-card-content>
</mat-card>