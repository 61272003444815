import { BASE_URL } from "src/app/shared/base.api";


export const Female = [
    { name: 'angel', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/angel.svg', },
    { name: 'artist', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/artist.svg', },
    { name: 'astronaut', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/astronaut.svg', },
    { name: 'basic', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/basic.svg', },
    { name: 'builder', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/builder.svg', },
    { name: 'businesswoman1', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/businesswoman1.svg', },
    { name: 'businesswoman2', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/businesswoman2.svg', },
    { name: 'casual', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/casual.svg', },
    { name: 'chef', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/chef.svg', },
    { name: 'devil', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/devil.svg', },
    { name: 'farmergirl', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/farmergirl.svg', },
    { name: 'police', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/police.svg', },
    { name: 'rebel', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/rebel.svg', },
    { name: 'superhero', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/superhero.svg', },
    { name: 'swimmer', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/swimmer.svg', },
    { name: 'teacher', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/teacher.svg', },
];

export const Male = [
    { name: 'angel', url: BASE_URL + '/api/Containers/standardcharactermale/download/angel.svg', },
    { name: 'groom', url: BASE_URL + '/api/Containers/standardcharactermale/download/groom.svg', },
    { name: 'artist', url: BASE_URL + '/api/Containers/standardcharactermale/download/artist.svg', },
    { name: 'astronaut', url: BASE_URL + '/api/Containers/standardcharactermale/download/astronaut.svg', },
    { name: 'builder', url: BASE_URL + '/api/Containers/standardcharactermale/download/builder.svg', },
    { name: 'businessman1', url: BASE_URL + '/api/Containers/standardcharactermale/download/businessman1.svg', },
    { name: 'businessman2', url: BASE_URL + '/api/Containers/standardcharactermale/download/businessman2.svg', },
    { name: 'cook', url: BASE_URL + '/api/Containers/standardcharactermale/download/cook.svg', },
    { name: 'devil', url: BASE_URL + '/api/Containers/standardcharactermale/download/devil.svg', },
    { name: 'doctor', url: BASE_URL + '/api/Containers/standardcharactermale/download/doctor.svg', },
    { name: 'farmer', url: BASE_URL + '/api/Containers/standardcharactermale/download/farmer.svg', },
    { name: 'football', url: BASE_URL + '/api/Containers/standardcharactermale/download/football.svg', },
    { name: 'police', url: BASE_URL + '/api/Containers/standardcharactermale/download/police.svg', },
    { name: 'priest', url: BASE_URL + '/api/Containers/standardcharactermale/download/priest.svg', },
    { name: 'rebel', url: BASE_URL + '/api/Containers/standardcharactermale/download/rebel.svg', },
    { name: 'superhero', url: BASE_URL + '/api/Containers/standardcharactermale/download/superhero.svg', },
    { name: 'swimmer', url: BASE_URL + '/api/Containers/standardcharactermale/download/swimmer.svg', },
    { name: 'teacher', url: BASE_URL + '/api/Containers/standardcharactermale/download/teacher.svg', },
    { name: 'thief', url: BASE_URL + '/api/Containers/standardcharactermale/download/thief.svg', },
    { name: 'regular', url: BASE_URL + '/api/Containers/standardcharactermale/download/regular.svg', },
];


export const Femalehair = [
    { name: 'asian', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/asian.svg', },
    { name: 'blond', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/blond.svg', },
    { name: 'dark long', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/darklong1.svg', },
    { name: 'dark long 2', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/darklong2.svg', },
    { name: 'dark long up', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/darklongup.svg', },
    { name: 'flower', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/flower.svg', },
    { name: 'pigtail', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/pigtail.svg', },
    { name: 'pony long', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/ponylong.svg', },
    { name: 'red', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/red.svg', },
    { name: 'red medium', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/redmedium.svg', },
    { name: 'short', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/short.svg', },
    { name: 'spicy', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/spicy.svg', },
    { name: 'spicy short', url: BASE_URL + '/api/Containers/standardcharacterfemale/download/spicyshort.svg', },
];

export const Malehair = [
    { name: 'beatle', url: BASE_URL + '/api/Containers/standardcharactermale/download/beatle.svg', },
    { name: 'blond', url: BASE_URL + '/api/Containers/standardcharactermale/download/blond.svg', },
    { name: 'curly', url: BASE_URL + '/api/Containers/standardcharactermale/download/curly.svg', },
    { name: 'dark', url: BASE_URL + '/api/Containers/standardcharactermale/download/dark.svg', },
    { name: 'dark2', url: BASE_URL + '/api/Containers/standardcharactermale/download/dark2.svg', },
    { name: 'elvis', url: BASE_URL + '/api/Containers/standardcharactermale/download/elvis.svg', },
    { name: 'long', url: BASE_URL + '/api/Containers/standardcharactermale/download/long.svg', },
    { name: 'red', url: BASE_URL + '/api/Containers/standardcharactermale/download/red.svg', },
    { name: 'spikes', url: BASE_URL + '/api/Containers/standardcharactermale/download/spikes.svg', },
    { name: 'tintin', url: BASE_URL + '/api/Containers/standardcharactermale/download/tintin.svg', },
];


export const Maleheadcodestart = '<path d="M335.43,860';
export const Maleheadcodeend =  '3,3.13,0,0,1,371.36,359.93Z" style="fill:#fdaf89"/>';