<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">
    <mat-button-toggle matTooltip="Open Template" style="width: 33%" value="templates">
      <mat-icon>construction</mat-icon>
    </mat-button-toggle>
    <!-- <mat-button-toggle matTooltip="Open stored" style="width: 25%" value="stored" [checked]="true">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle> -->
    <mat-button-toggle matTooltip="Standard backgrounds" style="width: 33%" value="standardbackgrounds">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Pictures from file" style="width: 33%" value="fromfile">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="group.value == 'standardbackgrounds'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let background of stockbg; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(background)" class="imagebox">
        <!-- <img *ngIf="background.thumb" src="{{ background.thumb }}" class="image" 
        [class.selected]="image === data.selected" /> -->

        <img *ngIf="background.thumb" src="{{ background.thumb }}" class="image" [class.selected]="background === data.selected" />
        <!-- <div [inlineSVG]="background.url" [onSVGLoaded]="handleSVG" (click)="selectedimage(background)"
          [class.selected]="background === data.selected"> -->
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'standardbackgrounds'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{stockbgSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{stockbgSlice}}</label>&nbsp; of &nbsp;
    <label>{{stockbgArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'templates'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let background of templates; let i = index" fxFlex (click)="selectedimage(background)">
      <div class="imagebox">
        <img src="{{ background.url }}" class="image" [class.selected]="background === data.selected" />
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'templates'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{templatesSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{templatesSlice}}</label>&nbsp; of &nbsp;
    <label>{{templatesArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'stored'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let store of stored; let i = index" fxFlex>
      <div fxLayout="column" fxLayoutAlign="space-between start" (click)="selectedimage(store)" class="imagebox">
        <img *ngIf="store.screenshot" src="{{ store.screenshot }}" class="image"
          [class.selected]="store === data.selected" />
        <div *ngIf="!store.screenshot" style="width: 150px; height: 150px; background-color: aliceblue;"
          [class.selected]="store === data.selected">
        </div>
        <label>{{store.name}}</label>
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'stored'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{storedSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{storedSlice}}</label>&nbsp; of &nbsp;
    <label>{{storedArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'fromfile'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let file of data.fromfile; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(file)" fxLayoutAlign="space-around start" class="imagebox">
        <img src="{{ file.url }}" class="image" [class.selected]="file === data.selected" />
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'fromfile'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{fromfileSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{fromfileSlice}}</label>&nbsp; of &nbsp;
    <label>{{fromfileArray.length}}</label>
  </div>

  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>