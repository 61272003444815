<div class="addtobutton">
    <button mat-fab color="primary" matTooltip="Create new Chat" (click)="createChat()">
        <mat-icon>add</mat-icon>
    </button>
    <button mat-fab color="primary" matTooltip="Get implementation code" (click)="createCode()">
        <mat-icon>code</mat-icon>
    </button>
</div>
<div class="listshowcontainer" fxShow fxHide.gt-xs>
    <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div fxLayout fxLayout="row" fxLayoutWrap="wrap" fxFlexAlign="start" class="card-left-menu"
    (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
    <div class="card-left-menu" fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
        [class.listview-xs-show]="listviewxsshow">

        <h4 i18n>Search</h4>
        <mat-form-field appearance="outline">
            <input autocomplete="false" matInput #searchTerm id="seach-box"
                (keyup.enter)="searchChats(searchTerm.value)" placeholder="Name/Address" i18n-placeholder/>
        </mat-form-field>

        <div fxFlex fxLayout="column">
            <button a mat-raised-button color="primary" (click)="searchChats(searchTerm.value)">
                <mat-icon>search</mat-icon>
                <label i18n>Search Result</label>
            </button>
            <button a mat-raised-button (click)="getChats()" i18n>
                Show All
            </button>
            <div *ngFor="let chat of Chats" (click)="onSelectChat(chat)" >
                <span [class.selected]="chat === selectedChat" class="listbox">{{ chat.name }}</span>
            </div>
        </div>

        <mat-select i18n-placeholder placeholder="Max number of results" [(ngModel)]="limitresult" name="limitresult">
            <mat-option *ngFor="let number of numbers" [value]="number.value">
                {{ number.viewValue }}
            </mat-option>
        </mat-select>
        <!-- <button mat-raised-button (click)="createChatroom()">New Chat Box</button> -->
    </div>

    <div cdkDropListGroup fxLayout="column" ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow"
        [class.container-xs-show]="listviewxsshow" (blur)="saveChat()" (focusout)="saveChat()">
        <mat-card *ngIf="selectedChat">
            <mat-toolbar class="titlebox" style="width: 100%;">
                <h2>
                    {{ selectedChat.name | uppercase | slice: 0:30 }}
                </h2>
                <button mat-icon-button [matMenuTriggerFor]="menuchat" class="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuchat="matMenu">
                    <button mat-menu-item (click)="openDialogDelete()">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Delete</span>
                    </button>
                    <button mat-menu-item (click)="createChat()">
                        <mat-icon>note_add</mat-icon>
                        <span i18n>New</span>
                    </button>
                </mat-menu>
            </mat-toolbar>
            <br>
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput i18n-placeholder placeholder="Title" [(ngModel)]="selectedChat.name" ngDefaultControl name="title">
            </mat-form-field>

            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header i18n>
                        Chat settings
                    </mat-expansion-panel-header>

                    <!-- (colorPickerChange)="detectchange()" cpDialogDisplay="inline"-->
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                        <label fxFlex="50%" i18n>Form box color</label>
                        <input fxFlex="20%" [style.background]="selectedChat.color" [(colorPicker)]="selectedChat.color"
                            [cpCancelButton]="true" [cpPresetLabel]="'Company colors'" *ngIf="company"
                            cpDialogDisplay="inline"
                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                    </div>
                    <br>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                        <label fxFlex="50%" i18n>Text Color</label>
                        <input fxFlex="20%" [style.background]="selectedChat.textcolor"
                            [(colorPicker)]="selectedChat.textcolor" [cpCancelButton]="true"
                            [cpPresetLabel]="'Company colors'" *ngIf="company" cpDialogDisplay="inline"
                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                    </div>
                    <br>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                        <div fxLayout="row">
                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                <input [(ngModel)]="selectedChat.width" matInput type="number" ngDefaultControl name="width">
                                <mat-hint i18n>Width</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                <mat-select [(ngModel)]="selectedChat.wform" ngDefaultControl>
                                    <mat-option value="px">px</mat-option>
                                    <mat-option value="%">%</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row">
                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                <input [(ngModel)]="selectedChat.height" matInput type="number" ngDefaultControl name="height">
                                <mat-hint i18n>Height</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                <mat-select [(value)]="selectedChat.hform" ngDefaultControl>
                                    <mat-option value="px">px</mat-option>
                                    <mat-option value="%">%</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <label i18n>Avatar:</label>
                    <app-fileupload *ngIf="!selectedChat.avatar" [account]="Account" [option]="option"
                        (imgurl)="setAvatarImage($event)">
                    </app-fileupload>
                    <div fxLayout="row">
                        <img *ngIf="selectedChat.avatar" src="{{selectedChat.avatar}}" width="50" height="auto">
                        <mat-icon (click)="selectedChat.avatar = ''">delete</mat-icon>
                    </div>
                    <br>
                    <label i18n>Start Icon:</label>
                    <app-fileupload *ngIf="!selectedChat.startIcon" [account]="Account" [option]="option"
                        (imgurl)="setstartIconImage($event)">
                    </app-fileupload>
                    <div fxLayout="row">
                        <img *ngIf="selectedChat.startIcon" src="{{selectedChat.startIcon}}" width="50" height="auto">
                        <mat-icon (click)="selectedChat.startIcon = ''">delete</mat-icon>
                    </div>
                    <br>
                    <label i18n>Thank you message:</label>
                    <div fxLayout="row">
                        <mat-form-field appearance="outline" style="width: 100%;" appearance="fill">
                            <input matInput i18n-placeholder placeholder="Thank you note" [(ngModel)]="selectedChat.thankyoutext" name="thankyoutext">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h3>Chatrooms</h3>
                <mat-icon (click)="getChatrooms()" matTooltip="reload">refresh</mat-icon>
            </div>
        
            <br>
            <div *ngFor="let room of Chatrooms" fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="80%" (click)="onSelectChatroom(room)">
                    <span class="listbox">{{ room.name }}</span>
                </div>
                <mat-icon fxFlex="20%" (click)="deleteRoom(room)" class="iconsize-10">delete</mat-icon>
            </div>
        </mat-card>
        <br>
        <!-- <mat-card *ngIf="selectedChatroom">
            <mat-toolbar class="titlebox" style="width: 100%;">
                <h2>Chat rooms</h2>
                <button mat-icon-button [matMenuTriggerFor]="menurooms" class="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menurooms="matMenu">

                    <button mat-menu-item (click)="createChatroom()">
                        <mat-icon>note_add</mat-icon>
                        <span>New</span>
                    </button>
                </mat-menu>
            </mat-toolbar>
            <br>
            <mat-form-field appearance="outline" style="width: 100%;">
                <input matInput placeholder="Name" [(ngModel)]="selectedChatroom.name" ngDefaultControl>
            </mat-form-field>
            <br>
        </mat-card> -->
        <br>
        <!-- <mat-card *ngIf="selectedChatroom" fxLayout="column" [style.width]="selectedChat.width + selectedChat.wform"
            [style.height]="selectedChat.height + selectedChat.hform" [style.color]="selectedChat.textcolor"
            [style.background-color]="selectedChat.color" class="chatbox">
            <div *ngFor="let message of Chatmessages ">
                <span *ngIf="message.accountId === Account.id" fxFlex="65%" class="box">{{ message.message }}</span>
                <span *ngIf="message.accountId !== Account.id" fxFlex="65%" fxFlexOffset="50%"
                    class="boxguest">{{ message.message }}</span>
            </div>
            <mat-form-field appearance="outline" appearance="outline">
                <input matInput placeholder="message" [(ngModel)]="message.message" (keyup.enter)="sendMessage()" ngDefaultControl>
                <mat-icon matTooltip="select Emoji" (click)="onshowemoji()" matSuffix>insert_emoticon</mat-icon>
                <div *ngIf="showemoji" style="width: 100%; 
                      height: 100%; position: absolute; top: 8; left: 20; z-index: 20;">
                    <emoji-mart (emojiClick)="setemoji($event)" set="apple" title="enydea"></emoji-mart>
                </div>
            </mat-form-field>

            <button mat-raised-button (click)="sendMessage()">send</button>
        </mat-card> -->

    </div>


</div>