<div *ngIf="CampaignMailing.send == false" fxLayoutGap="20px">
    <mat-form-field appearance="outline">
        <mat-label i18n>Email Template</mat-label>
        <mat-select placeholder="Copy from:" [(ngModel)]="copyfrommailing" [attr.disabled]="CampaignMailing.send">
            <mat-option *ngFor="let Mailing of Mailing" [value]="Mailing">
                <label> {{ Mailing.title }}</label>
                <label> {{ Mailing.subject }}</label>
                <!-- {{ Mailing.date | date: "mediumDate" }} -->
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button a mat-raised-button (click)="copyFromMailingCampaign(i)">
        <label i18n>Copy</label>
        <mat-icon>copy</mat-icon>
    </button>
</div>
<div fxLayout="row" fxLayout.xs="column">

    <mat-form-field appearance="outline" class="form-full-width">
        <mat-label i18n>Subject</mat-label>
        <input matInput [(ngModel)]="CampaignMailing.subject" placeholder="Subject" class="InputField"
            [attr.disabled]="CampaignMailing.send" name="subject" />
    </mat-form-field>

</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <mat-form-field appearance="outline" class="form-full-width">
        <mat-label i18n>Title</mat-label>
        <input matInput [(ngModel)]="CampaignMailing.title" placeholder="Custom Titel/ID" class="InputField"
            [attr.disabled]="CampaignMailing.send" name="title" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-full-width">
        <mat-label i18n>Send from</mat-label>
        <input matInput [(ngModel)]="CampaignMailing.from" placeholder="from@email.com" class="InputField"
            [attr.disabled]="CampaignMailing.send" name="from" />
    </mat-form-field>
</div>
<mat-form-field appearance="outline" class="form-full-width">
    <mat-label>From Name</mat-label>
    <input matInput [(ngModel)]="CampaignMailing.sendname" placeholder="Jane Doe" name="sendname" class="InputField" />
</mat-form-field>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <mat-checkbox [(ngModel)]="CampaignMailing.send" [attr.disabled]="CampaignMailing.send" i18n>
        Send
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="CampaignMailing.toopened" i18n>
        Send to Previous Openend Only
    </mat-checkbox>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <mat-checkbox [(ngModel)]="CampaignMailing.done" i18n>
        Done
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="CampaignMailing.toclicked" i18n>
        Send to Previous Clicked Only
    </mat-checkbox>
</div>
<br />

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header matTooltip="Schedule when your mail should be send">
            <mat-panel-title i18n>
                Schedule Mailing
            </mat-panel-title>
            <mat-panel-description i18n>

                <mat-icon>schedule</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

                <mat-form-field appearance="outline">
                    <mat-label i18n>Send time</mat-label>
                    <input matInput [(ngModel)]="CampaignMailing.time" placeholder="Time" class="InputField" type="time"
                        [attr.disabled]="CampaignMailing.send" name="time" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label i18n>Timezone</mat-label>
                    <mat-select placeholder="Timezone" [(ngModel)]="CampaignMailing.timezone"
                        [attr.disabled]="CampaignMailing.send">
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                            {{ timezone.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label i18n>Send date</mat-label>
                    <mat-datepicker #picker2 class="form-half-width"></mat-datepicker>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="myDatepicker2"
                        [(ngModel)]="CampaignMailing.date" placeholder="Send Date"
                        [attr.disabled]="CampaignMailing.send" name="date" />
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header *ngIf="CampaignMailing.parentId"
            matTooltip="Send email after user interaction with previous send mailing">
            <mat-panel-title class="mat-expansion-panel-header-title" i18n>
                Automate Email
            </mat-panel-title>
            <mat-panel-description i18n>

                <mat-icon>settings_suggest</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="CampaignMailing.parentId">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Hours after open</mat-label>
                    <mat-select placeholder="Send email after open" [(ngModel)]="CampaignMailing.sendafteropen"
                        name="sendafteropen">
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                            {{hour}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <label> Or </label>
                <mat-form-field appearance="outline">
                    <mat-label i18n>Hours after click</mat-label>
                    <mat-select placeholder="Send email after click" [(ngModel)]="CampaignMailing.sendafterclick"
                        name="sendafterclick">
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                            {{hour}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<br />

<div style="width: 100%;">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <button a mat-raised-button (click)="showMailPreview()">
            <label i18n>Preview</label>
            <mat-icon>view</mat-icon>
        </button>

        <!-- <button a mat-raised-button (click)="toggleTextView()">
            <label i18n>Show plain text</label>
            <mat-icon>text</mat-icon>
        </button> -->
    </div>
    <!-- <div fxLayout="row" fxLayout.xs="column" *ngIf="CampaignMailing.done == false" rowspan="10">
        <div fxFlexcolspan="3">
            <mat-form-field appearance="outline" class="form-full-width">
                <textarea matInput class="content-form" #content [(ngModel)]="CampaignMailing.text"
                    placeholder="content" i18n>Text Version</textarea>
                <mat-hint align="end">{{content.value.length}}</mat-hint>
            </mat-form-field>
        </div>
    </div> -->
    <br>
    <div fxLayout="row">
        <button mat-raised-button (click)="save()" i18n>Save</button>
        <button mat-raised-button (click)="close()" i18n>Cancel</button>
    </div>

</div>