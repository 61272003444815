<div style="height: 500px" fxLayout="column">
  <div class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let materialfile of storedArray; let i = index" fxFlex>
      <div fxLayout="colmn" (click)="selectedimage(materialfile)">
        <img *ngIf="materialfile.screenshot" src="{{ materialfile.screenshot }}" class="image" [class.selected]="materialfile === data.selected"/>
        <div *ngIf="!materialfile.screenshot" style="width: 150px; height: 150px; background-color: aliceblue;" [class.selected]="materialfile === data.selected">
        </div>
        <h5>{{materialfile.name}}</h5>
        </div>
    </div>
  </div>

  <div style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next()">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{materialthreedSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{materialthreedSlice}}</label>&nbsp; of &nbsp;
    <label>{{materialthreedArray.length}}</label>
  </div>

  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>
