import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogsService } from './dialogsservice/dialogs.service';
import { API_VERSION, BASE_URL } from './shared/base.api';
import {
  LoopBackConfig,
  PublicationsApi,
  Publications,
  Container,
  ContainerApi,
  Relations,
  RelationsApi,
  Account,
  AccountApi,
  Company,
  CompanyApi,
  ChannelsApi,
  Channels,
  Files,
  Mailing,
  MailingApi,
  MailinglistApi,
  Mailinglist,
  GoogleanalyticsApi,
  Googleanalytics,
  MarketingplannereventsApi,
  Marketingplannerevents,
  Adwords,
  AdwordsApi,
  Forms,
  RealTime,
  FireLoopRef,
  Logger
} from './shared/sdk';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppserviceService {

  public Account: Account;
  public company: Company;
  public option: Relations;
  public options = [];
  public Relations: Relations[];
  public isAccounAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCompanyAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOptionAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOptionsAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRelationsAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public LoggerRef: FireLoopRef<Logger>;
  public AcccountReference: FireLoopRef<Account>
  public llogger: FireLoopRef<Logger>;
  public RelationReference: FireLoopRef<Relations>;
  public CompanyReference: FireLoopRef<Company>;

  logger: Logger[];
  messagecount: number;

  constructor(   
    public router: Router,
    public dialogsService: DialogsService,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi,
    public RelationsApi: RelationsApi,
    public realTime: RealTime,
  ) {
    // let BASE_URL = 'https://app.enydea.com'; // TEST ONLY!!
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
    LoopBackConfig.setSecureWebSockets(); // if necessary?? 
    this.getCurrentUserInfo();
  }


  getCurrentUserInfo(): void {
    this.AccountApi.getCurrent({
      include: [
        {
          relation: 'logger',
        }
      ]
    }).subscribe((account: Account) => {
      this.Account = account;
      this.isAccounAvailable.next(true);
      this.CompanyApi.getRelations(this.Account.companyId,
        {
          where: { marketingrelation: true },
          fields: { id: true, relationname: true }
        }
      )
        .subscribe((relations: Relations[]) => {
         
          this.Relations = relations;
          this.isRelationsAvailable.next(true);
          if (this.Account.standardrelation !== undefined) {

            this.RelationsApi.findById(this.Account.standardrelation)
              .subscribe((rel: Relations) => {
                this.option = rel;
                this.isOptionAvailable.next(true)
                // this.onSelectRelation(rel), // to local component
                this.CompanyApi.findById(this.Account.companyId)
                  .subscribe((company: Company) => {
                    this.setIO();
                    this.company = company;
                    this.isCompanyAvailable.next(true)
                  });
              })
          }
        });
    }, error => {
      console.log(error); 
      this.router.navigate(['/login'])
    });
  }

  setIO() {
    // wait/check if connected and authenticated
    if (
      this.realTime.connection.isConnected() &&
      this.realTime.connection.authenticated
    ) {
      this.realTime.onReady().subscribe(() => this.setupIO());
    } else {
      this.realTime.onAuthenticated().subscribe(() => this.setupIO());
      this.realTime.onReady().subscribe();
    }

  }

  setupIO(){
      this.AcccountReference = this.realTime.FireLoop.ref<Account>(Account);
      this.LoggerRef = this.AcccountReference.make(this.Account).child<Logger>('logger');
      //this.LoggerRef = this.realTime.FireLoop.ref<Logger>(Logger);
      this.RelationReference = this.realTime.FireLoop.ref<Relations>(Relations);
     // this.LoggerRef.on('value', {}).subscribe((log) => {console.log(log)})
  }


  openMessageDialog(title, message) {
    this.dialogsService
      .confirm(title, message)
      // .subscribe(res => {

      // });
  }




}
