import { BrowserModule} from '@angular/platform-browser';
// import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerLoader } from '@angular/platform-browser';
// import 'hammerjs';
// declare var Hammer: any;

import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule } from '@angular/router';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule} from '@angular/material/icon';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatListModule} from '@angular/material/list';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent, BottomSheetLogOverview } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SDKBrowserModule } from './shared/sdk/index';
import { SpeechRecognitionService } from './shared/speechservice/speechservice';
import { AuthGuard } from './shared/auth.guard';
import { codesnippetService, CodesnippetDialog } from './dialogsservice/codesnippet-dialog.component';
import { RandomService } from './dialogsservice/random.service';
import { DialogsService } from './dialogsservice/dialogs.service';

import { RandomDialog } from './dialogsservice/random-dialog.component';
import { DialogGetname } from './dialogsservice/dialog.getname';
import { WordpressUploadDialogComponent } from './dialogsservice/wordpressupload-dialog.component';
import { chatDialog } from './marketing/chats/chats.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SpeedDialFabComponent } from './shared/speed-dial-fab/speed-dial-fab.component'
import { ChartsModule } from 'ng2-charts';
import { AgmCoreModule } from '@agm/core';
import { LinkedinService } from './shared/socialservice/';
import { XlsxFileUploadComponent } from './marketing/xlsx-file-upload/xlsx-file-upload.component';
import { GoogleMapService } from './shared/googlemapservice/googlemap.service'
import { timeconv } from './shared/timeconv'
import { CKEditorModule } from 'ng2-ckeditor';
//import { IconService } from '../assets/icons/icon.service';
import { environment } from '../environments/environment';
import { MaileditorText } from './marketing/maileditor/maileditormodel/maileditormodels'
import { FlexLayoutModule } from '@angular/flex-layout';
import { GooglePlacesDirective } from './google-places.directive';
import { ReturnpageComponent } from './returnpage/returnpage.component';
import { MarketingchannelsComponent } from './marketing/marketingchannels/marketingchannels.component';
import { PwaService } from './pwa.service';
import { MaileditorComponent } from './marketing/maileditor/maileditor.component';
import { TextEditorDialog } from './marketing/maileditor/texteditordialog.component';
import { MailingSelectDialog } from './marketing/maileditor/mailingselectdialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SafeHtml } from './pipe/safehtmlpipe.component';
import { SafePipe } from './pipe/safepipe.component';
import { FileName } from './pipe/filenamepipe.component';
import { colorset } from './shared/colorsets';
import { FormatFileSizePipe } from './pipe/filesize.component'
import { MarketingplannerComponent } from './marketingplanner/marketingplanner.component';
import { MarketingpromotionsComponent } from './marketing/marketingpromotions/marketingpromotions.component';
import { MarketingpublicationsComponent } from './marketing/marketingpublications/marketingpublications.component';
import { VideocreatorComponent } from './imaging/videocreator/videocreator.component';
import { ImagecreatorComponent } from './imaging/imagecreator/imagecreator.component';
import { FileuploadComponent, dialoggallerycomponent } from './shared/fileupload/fileupload.component';
import { RelationComponent } from './relation/relation.component';
import { MarketingComponent } from './marketing/marketing.component';
import { SettingsComponent } from './settings/settings.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ConfirmDialog } from './dialogsservice/confirm-dialog.component';
import { VideouploadComponent, dialogvideogallerycomponent } from './shared/videoupload/videoupload.component';
import { AudiouploadComponent, dialogaudiogallerycomponent } from './shared/audioupload/audioupload.component';
//import { FileserverComponent } from './shared/fileserver/fileserver.component';
import { LinkedinComponent } from './shared/linkedin/linkedin.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InlineSVGModule } from 'ng-inline-svg-2';
// import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { VectoruploadComponent, dialogvectorgallerycomponent } from './shared/vectorupload/vectorupload.component';
import { BackgroundComponent, dialogbackgroundgallerycomponent } from './shared/background/background.component';
import { TranslationsComponent } from './translations/translations.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { SpecialCharacterDirective } from './directives/specialcharacter.directive';
import { TableComponent } from './dashboard/table/table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CrawlerComponent } from './relation/crawler/crawler.component';
import { UploadComponent } from './translations/upload/upload.component';
import { UploadcrmComponent } from './settings/uploadcrm/uploadcrm.component';
import { MsalModule } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from 'msal';
import { BASE_URL } from './shared/base.api';
import { MarketingformsComponent } from './marketing/marketingforms/marketingforms.component';
import { FormentriesComponent } from './marketing/marketingforms/formentries/formentries.component';
import { ChatsComponent } from './marketing/chats/chats.component';
import { ThreedComponent } from './imaging/videocreator/threed/threed.component';
import { ThreedfileComponent, dialogthreedfilegallerycomponent } from './shared/threedupload/threedfile.component';
import { ThreedmaterialComponent, dialogthreedmaterialgallerycomponent } from './shared/threedupload/threedmaterial.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { ImagingComponent } from './imaging/imaging.component';
import { GeneralsettingsComponent } from './imaging/videocreator/popupscreens/generalsettings/generalsettings.component';
import { BackgroundsettingsComponent } from './imaging/videocreator/popupscreens/backgroundsettings/backgroundsettings.component';

import { read, writeFileXLSX } from "xlsx";

/* load the codepage support library for extended support with older formats  */
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
import { SavehistoryService } from './imaging/videocreator/supportfunctions/savehistory.service';
import { FormentrydetailsComponent } from './marketing/marketingforms/formentries/formentrydetails/formentrydetails.component';
set_cptable(cptable);
import { PublicClientApplication } from '@azure/msal-browser';
import { VideoeditorComponent } from './imaging/videoeditor/videoeditor.component';
import { VideoplayerComponent } from './imaging/videoeditor/videoplayer/videoplayer.component';
import { NgxVideoControlsComponent} from './imaging/videoeditor/videoplayer/ngx-video-controls/ngx-video-controls.component';
import { NgxOnHoverDirective } from './imaging/videoeditor/videoplayer/directives/ngx-on-hover.directive';
import { NgInitDirective } from './directives/onload.directive';
import { DraggableService } from './imaging/videocreator/supportfunctions/draggable.service';
import { PendingChangesGuard } from './shared/pendingchanges.guard';
import { DialogImageEditgalleryComponent } from './imaging/videoeditor/dialog-image-editgallery/dialog-image-editgallery.component';
import { dialogfromfilegallerycomponent, FromfileComponent } from './shared/fromfile/fromfile.component';
import { CharactercreatorComponent } from './imaging/videocreator/popupscreens/charactercreator/charactercreator.component';
import { TwofactorwindowComponent } from './login/twofactorwindow/twofactorwindow.component';
import { DialogSetpathDialog } from './imaging/videocreator/threed/movepathhelper';
import { CheckRelationDialogComponent } from './dashboard/dialog/check-relation-dialog/check-relation-dialog.component';
import { MailtemplatesComponent } from './marketing/maileditor/mailtemplates/mailtemplates.component';
import { CampaignbuilderComponent } from './marketing/campaignbuilder/campaignbuilder.component';
import { EditmailingComponent } from './marketing/campaignbuilder/editmailing/editmailing.component';
import { MailtemplateComponent } from './marketing/campaignbuilder/mailtemplate/mailtemplate.component';
import { RegisterComponent } from './login/register/register.component';
import { AitoolComponent } from './marketing/marketingpublications/aitool/aitool.component';
import { ConnectedaccountsComponent } from './relation/connectedaccounts/connectedaccounts.component';
import { PreviewchannelsComponent } from './marketing/marketingchannels/previewchannels/previewchannels.component';
import { DynamicComponent } from './marketing/maileditor/dynamic/dynamic/dynamic.component';
// import { BodyPix } from '@tensorflow-models/body-pix';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ImageaitoolComponent } from './imaging/imagecreator/imageaitool/imageaitool.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
//custom configuration Hammerjs
// @Injectable()
// export class HammerConfig extends HammerGestureConfig {
//   overrides = <any> {
//       // I will only use the swap gesture so 
//       // I will deactivate the others to avoid overlaps
//       'pinch': { enable: false },
//       'rotate': { enable: false }
//   }
// }


@NgModule({
    declarations: [
        DialogSetpathDialog,
        NgxOnHoverDirective,
        chatDialog,
        SpecialCharacterDirective,
        NgInitDirective,
        SpeedDialFabComponent,
        XlsxFileUploadComponent,
        BottomSheetLogOverview,
        AppComponent,
        RelationComponent,
        MarketingComponent,
        SettingsComponent,
        DashboardComponent,
        LoginComponent,
        ConfirmDialog,
        CodesnippetDialog,
        RandomDialog,
        DialogGetname,
        MarketingplannerComponent,
        //FileserverComponent,
        LinkedinComponent,
        GooglePlacesDirective,
        ReturnpageComponent,
        MarketingchannelsComponent,
        MaileditorComponent,
        FileuploadComponent,
        dialoggallerycomponent,
        dialogfromfilegallerycomponent,
        dialogvideogallerycomponent,
        dialogaudiogallerycomponent,
        dialogvectorgallerycomponent,
        dialogbackgroundgallerycomponent,
        dialogthreedfilegallerycomponent,
        dialogthreedmaterialgallerycomponent,
        TextEditorDialog,
        MailingSelectDialog,
        WordpressUploadDialogComponent,
        SafeHtml,
        SafePipe,
        FileName,
        FormatFileSizePipe,
        MarketingpromotionsComponent,
        MarketingpublicationsComponent,
        ImagecreatorComponent,
        VideouploadComponent,
        AudiouploadComponent,
        VideocreatorComponent,
        VectoruploadComponent,
        BackgroundComponent,
        FromfileComponent,
        TranslationsComponent,
        TableComponent,
        CrawlerComponent,
        UploadComponent,
        UploadcrmComponent,
        MarketingformsComponent,
        FormentriesComponent,
        ChatsComponent,
        ThreedComponent,
        ThreedfileComponent,
        ThreedmaterialComponent,
        ImagingComponent,
        GeneralsettingsComponent,
        BackgroundsettingsComponent,
        FormentrydetailsComponent,
        VideoeditorComponent,
        VideoplayerComponent,
        NgxVideoControlsComponent,
        DialogImageEditgalleryComponent,
        CharactercreatorComponent,
        TwofactorwindowComponent,
        CheckRelationDialogComponent,
        MailtemplatesComponent,
        CampaignbuilderComponent,
        EditmailingComponent,
        MailtemplateComponent,
        RegisterComponent,
        AitoolComponent,
        ConnectedaccountsComponent,
        PreviewchannelsComponent,
        DynamicComponent,
        ImageaitoolComponent
    ],
    imports: [
        RecaptchaV3Module,
        GuidedTourModule,
        MsalModule.forRoot( new PublicClientApplication({
            auth: {
                // https://docs.microsoft.com/en-us/answers/questions/447296/user-account-from-identity-provider-does-not-exist.html
              clientId: 'fb72996b-0142-4316-b08c-cd6e4fbb9033', // Application (client) ID from the app registration
              authority: 'https://login.microsoftonline.com/common', // 39be0641-93fd-4ad4-ab4d-1ea81e60c35d The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
              //redirectUri: 'http://localhost:3000/setting' 
              redirectUri: 'https://app.enydea.com/settings'// This is your redirect URI
            },
            cache: {
              cacheLocation: 'localStorage',
              storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }
          }), null, null),
        MatSortModule,
        MatPaginatorModule,
        MatPasswordStrengthModule.forRoot(),
//        CanvasWhiteboardModule,
        InlineSVGModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatBottomSheetModule,
        PickerModule,
        ColorPickerModule,
        FlexLayoutModule,
        CKEditorModule,
        FileUploadModule,
        AngularDraggableModule,
        ChartsModule,
        RouterModule,
        AppRoutingModule,
        MatCheckboxModule,
        DragDropModule,
        MatIconModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatTooltipModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatCardModule,
        MatToolbarModule,
        MatTableModule,
        MatToolbarModule,
        MatStepperModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule, MatAutocompleteModule, MatListModule, MatSliderModule,
        FormsModule, ReactiveFormsModule, HttpClientModule, MatGridListModule, MatSlideToggleModule,
        MatNativeDateModule,
        MatDatepickerModule, MatExpansionModule, MatButtonToggleModule, MatChipsModule, MatBadgeModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA0RsiG74QFKTDJZQ1Cl6kso_iOqnAYjV8',
            libraries: ["places"],
            language: 'en' //localStorage && localStorage.gml || 
        }),
        SDKBrowserModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MatPasswordStrengthModule
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
        PendingChangesGuard,
        DraggableService,
        SavehistoryService,
        GuidedTourService,
        colorset,
        codesnippetService,
        PwaService,
        SpeechRecognitionService,
        LinkedinService,
        AuthGuard,
        RandomService,
        DialogsService,
        GoogleMapService,
        timeconv,
        MarketingchannelsComponent,
        WordpressUploadDialogComponent,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    bootstrap: [AppComponent],
    exports: [
        ConfirmDialog,
        CodesnippetDialog,
        RandomDialog,
        DialogGetname,
        chatDialog
    ]
})
export class AppModule { }
