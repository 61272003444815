/* tslint:disable */
import {
  Relations,
  Company,
  Marketingplanner,
  Channels,
  Formentries
} from '../index';

declare var Object: any;
export interface FormsInterface {
  "title"?: string;
  "pictureurl"?: string;
  "text"?: string;
  "sendbyemail"?: boolean;
  "template"?: any;
  "color"?: string;
  "textcolor"?: string;
  "font"?: string;
  "fontsize"?: string;
  "highlight"?: string;
  "suggestioncolor"?: string;
  "width"?: string;
  "height"?: string;
  "function"?: string;
  "wform"?: string;
  "hform"?: string;
  "thankyoutext"?: string;
  "thankyouimgsrc"?: string;
  "zoom"?: boolean;
  "scroll"?: boolean;
  "notificationgroup"?: any;
  "id"?: any;
  "relationsId"?: any;
  "companyId"?: any;
  "marketingplannerId"?: any;
  relations?: Relations;
  company?: Company;
  marketingplanner?: Marketingplanner;
  channels?: Channels[];
  formentries?: Formentries[];
}

export class Forms implements FormsInterface {
  "title": string;
  "pictureurl": string;
  "text": string;
  "sendbyemail": boolean;
  "template": any;
  "color": string;
  "textcolor": string;
  "font": string;
  "fontsize": string;
  "highlight": string;
  "suggestioncolor": string;
  "width": string;
  "height": string;
  "function": string;
  "wform": string;
  "hform": string;
  "thankyoutext": string;
  "thankyouimgsrc": string;
  "zoom": boolean;
  "scroll": boolean;
  "notificationgroup": any;
  "id": any;
  "relationsId": any;
  "companyId": any;
  "marketingplannerId": any;
  relations: Relations;
  company: Company;
  marketingplanner: Marketingplanner;
  channels: Channels[];
  formentries: Formentries[];
  constructor(data?: FormsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Forms`.
   */
  public static getModelName() {
    return "Forms";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Forms for dynamic purposes.
  **/
  public static factory(data: FormsInterface): Forms{
    return new Forms(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Forms',
      plural: 'Forms',
      path: 'Forms',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "pictureurl": {
          name: 'pictureurl',
          type: 'string'
        },
        "text": {
          name: 'text',
          type: 'string'
        },
        "sendbyemail": {
          name: 'sendbyemail',
          type: 'boolean'
        },
        "template": {
          name: 'template',
          type: 'any',
          default: <any>null
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "textcolor": {
          name: 'textcolor',
          type: 'string'
        },
        "font": {
          name: 'font',
          type: 'string'
        },
        "fontsize": {
          name: 'fontsize',
          type: 'string'
        },
        "highlight": {
          name: 'highlight',
          type: 'string'
        },
        "suggestioncolor": {
          name: 'suggestioncolor',
          type: 'string'
        },
        "width": {
          name: 'width',
          type: 'string',
          default: '500'
        },
        "height": {
          name: 'height',
          type: 'string',
          default: '900'
        },
        "function": {
          name: 'function',
          type: 'string'
        },
        "wform": {
          name: 'wform',
          type: 'string',
          default: 'px'
        },
        "hform": {
          name: 'hform',
          type: 'string',
          default: 'px'
        },
        "thankyoutext": {
          name: 'thankyoutext',
          type: 'string',
          default: 'Send successfull!'
        },
        "thankyouimgsrc": {
          name: 'thankyouimgsrc',
          type: 'string'
        },
        "zoom": {
          name: 'zoom',
          type: 'boolean'
        },
        "scroll": {
          name: 'scroll',
          type: 'boolean'
        },
        "notificationgroup": {
          name: 'notificationgroup',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "relationsId": {
          name: 'relationsId',
          type: 'any'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
        "marketingplannerId": {
          name: 'marketingplannerId',
          type: 'any'
        },
      },
      relations: {
        relations: {
          name: 'relations',
          type: 'Relations',
          model: 'Relations',
          relationType: 'belongsTo',
                  keyFrom: 'relationsId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        marketingplanner: {
          name: 'marketingplanner',
          type: 'Marketingplanner',
          model: 'Marketingplanner',
          relationType: 'belongsTo',
                  keyFrom: 'marketingplannerId',
          keyTo: 'id'
        },
        channels: {
          name: 'channels',
          type: 'Channels[]',
          model: 'Channels',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'formsId'
        },
        formentries: {
          name: 'formentries',
          type: 'Formentries[]',
          model: 'Formentries',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'formsId'
        },
      }
    }
  }
}
