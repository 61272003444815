<div class="savetobutton">
    <button mat-fab color="primary" matTooltip="Convert to image" (click)="OnSaveImage()">
        <mat-icon>save</mat-icon>
    </button>

    <!-- <button mat-fab color="primary" matTooltip="Convert to SVG" (click)="convertToSVG()">
        <mat-icon>save</mat-icon>
    </button> -->
</div>

<div class="listshowcontainer" fxShow fxHide.gt-xs>
    <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div style="width: 100%; margin: 10px 0px 10px 0px;" *ngIf="createnew || canvas.bgimg" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
    <mat-accordion style="width: 100%">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]="imagename" type="text" matInput i18n-placeholder placeholder="Image Name"
                            name="imagename">
                    </mat-form-field>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap" fxLayoutGap="20px">



                <label *ngIf="canvas.bgimg">{{canvas.bgimg | fileName}}</label>
                <mat-icon class="iconsize-10" (click)="canvas.bgimg = ''">delete</mat-icon>

                <mat-form-field appearance="outline" *ngIf="company">
                    <mat-label>Background Color</mat-label>
                    <input style="width: 150px; height: 30px;" matInput [(colorPicker)]="canvas['background-color']"
                        (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <input (change)="onpicturesizechange($event, 'width')" [value]="canvas.width" type="text" matInput
                        name="width" i18n-placeholder placeholder="width">
                    <label matSuffix>px</label>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <input (change)="onpicturesizechange($event, 'height')" [value]="canvas.height" type="text" matInput
                        name="height" i18n-placeholder placeholder="height">
                    <label matSuffix>px</label>
                </mat-form-field>

                <mat-checkbox [(ngModel)]="canvas.keepratio">
                    <mat-icon>lock</mat-icon>
                </mat-checkbox>

                <mat-form-field *ngIf="canvas.bgimg" appearance="outline">
                    <input (change)="detectchange()" [(ngModel)]="canvas.bgwidth" type="text" matInput name="bgwidth"
                        i18n-placeholder placeholder="width image">
                    <label matSuffix>px/%</label>
                </mat-form-field>

                <mat-form-field *ngIf="canvas.bgimg" appearance="outline">
                    <input (change)="detectchange()" [(ngModel)]="canvas.bgheight" type="text" matInput name="bgimg"
                        i18n-placeholder placeholder="height image">
                    <label matSuffix>px/%</label>
                </mat-form-field>

                <mat-slide-toggle fxFlex *ngIf="canvas.bgimg" [(ngModel)]="canvas.blur">Blur</mat-slide-toggle>

                <mat-slide-toggle fxFlex *ngIf="canvas.bgimg" [(ngModel)]="canvas.grey">Grey</mat-slide-toggle>
                

                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="selectedvideoformat" (selectionChange)="changeimageormat()"
                        i18n-placeholder placeholder="Select image size">
                        <mat-option value="1024px x 1024px">1024 x 1024</mat-option>
                        <mat-option value="512px x 512px">512 x 512</mat-option>
                        <mat-option value="256px x 256px">256 x 256</mat-option>
                        <!-- <mat-option value="4096px x 2048px">4096 x 2048</mat-option>
                        <mat-option value="1920px x 1080px">1920 x 1080</mat-option>
                        <mat-option value="1280px x 720px">1280 x 720</mat-option>
                        <mat-option value="1080px x 1080px">1080 x 1080</mat-option>
                        <mat-option value="1024px x 576px">1024 x 576</mat-option>
                        <mat-option value="735px x 1102px">735 x 1102 Pinterest</mat-option>
                        <mat-option value="1024px x 512px">1024 x 512 Twitter</mat-option>
                        <mat-option value="1200px x 1200px">1200 x 1200 Linkedin</mat-option>
                        <mat-option value="1200px x 1200px">1200 x 628 Facebook</mat-option>
                        <mat-option value="768px x 432px">768 x 432</mat-option>
                        <mat-option value="512px x 512px">512 x 512</mat-option>
                        <mat-option value="512px x 288px">512 x 288</mat-option>
                        <mat-option value="256px x 144px">256 x 144</mat-option>
                        <mat-option value="468px x 60px">468 x 60 fullbanner</mat-option>
                        <mat-option value="728px x 90px">728 x 90 leaderboard</mat-option>
                        <mat-option value="1366px x 480px">1366 x 480 website banner</mat-option> -->
                    </mat-select>
                </mat-form-field>

                <!-- <app-background [account]="Account" [option]="option" (imgurl)="setBackground($event)">
                </app-background> -->

                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="zoomfactor" (selectionChange)="detectchange()" i18n-placeholder
                        placeholder="Zoom">
                        <mat-option value="1">100%</mat-option>
                        <mat-option value="0.9">90%</mat-option>
                        <mat-option value="0.8">80%</mat-option>
                        <mat-option value="0.7">70%</mat-option>
                        <mat-option value="0.6">60%</mat-option>
                        <mat-option value="0.5">50%</mat-option>
                        <mat-option value="0.4">40%</mat-option>
                        <mat-option value="0.3">30%</mat-option>
                        <mat-option value="0.2">20%</mat-option>
                        <mat-option value="0.1">10%</mat-option>
                        <mat-option value="1.5">150%</mat-option>
                        <mat-option value="2">200%</mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxLayout="row" *ngIf="canvas.bgimg" fxLayoutGap="20px"
                    style="background-color: whitesmoke; margin: 10px">
                    <button mat-button (click)="imageCropPath()">Crop</button>
                    <button mat-button (click)="imageSaveCropPath()">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button mat-button (click)="imageRemoveCrop()">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="standardpath" (selectionChange)="resetImageCropPath()" i18n-placeholder
                            placeholder="Crop shape">
                            <mat-option value="linear" i18n>Linear</mat-option>
                            <mat-option value="circle" i18n>Circle</mat-option>
                            <mat-option value="square" i18n>Square</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button mat-button (click)="resetImage()" i18n>Reset</button>
                <!-- <button mat-button (click)="getEditFile()">get images</button> -->
                <!-- <mat-form-field appearance="outline">
                    <mat-label i18n>Edit existing image</mat-label>
                    <mat-select [(ngModel)]="editableimage">
                        <mat-option *ngFor="let editimage of editableimages" [value]="editimage">
                            {{editimage.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <!-- <button mat-button (click)="loadEditableImage()" i18n>Start edit</button> -->


            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar *ngIf="busy"
        mode="indeterminate">
    </mat-progress-bar>
</div>

<!-- <div *ngIf="activeMediaQuery.mqAlias === 'xs'">
    <div *ngIf="!listviewxsshow">
        <label (click)="listviewxsshow = true">show</label>
    </div>
    <div *ngIf="listviewxsshow">
        <label (click)="listviewxsshow = false">don't show</label>
    </div>
</div> -->

<div *ngIf="listviewxsshow">
    <label (click)="listviewxsshow = false" i18n>don't show</label>
</div>

<div class="tabcontainer" fxLayout="row" cdkDropListGroup (swipeleft)="swipeleft($event)"
    (swiperight)="swiperight($event)">

    <div class="card-left-menu" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
        [class.listview-xs-show]="listviewxsshow" fxLayout="column" *ngIf="createnew || canvas.bgimg">
        <h3 i18n>Edit/Add Elements</h3>
        <div fxLayout="row wrap" fxLayout.xs="column">
            <button mat-mini-fab class="addbutton" matTooltip="Add image" (click)="addNewImage()" color="primary">
                <mat-icon>image</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add text" (click)="addNewText()" color="primary">
                <mat-icon>text_format</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add shape" (click)="addNewShape()" color="primary">
                <mat-icon>format_shapes</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add Chart" (click)="addNewChart()" color="primary">
                <mat-icon>show_chart</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Draw" (click)="addNewWhiteboard()" color="primary">
                <mat-icon>brush</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add Vector image" (click)="addNewVector()"
                color="primary">
                <mat-icon>emoji_nature</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add a AI Image" (click)="addNewAIImage()"
                color="primary">
                <mat-icon>memory</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Add part with AI" (click)="editWithAI()" color="primary">
                <mat-icon>smart_button</mat-icon>
            </button>
            <button mat-mini-fab class="addbutton" matTooltip="Create variation with AI" (click)="createVariation()" color="primary">
                <mat-icon>smart_button</mat-icon>
            </button>
            


        </div>
        <br>
        <div fxLayout="column">
            <div fxLayout="column" fxLayout.xs="column" cdkDropList (cdkDropListDropped)="drop($event)">
                <mat-accordion *ngIf="AIwhiteboard === true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title i18n>
                                Draw place to enhance picture with AI
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div fxLayout="column">

                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="shapedraw" i18n-placeholder placeholder="shape">
                                    <mat-option value="" i18n>Draw</mat-option>
                                    <mat-option value="rectangle" i18n>Rectangle</mat-option>
                                    <mat-option value="circle" i18n>Circly</mat-option>
                                    <!-- <mat-option value="figure" i18n>Figure</mat-option>
                                    <mat-option value="line" i18n>Line</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                            <br>
                            <div fxLayout="row">
                                <button mat-mini-fab matTooltip="Start Draw" (click)="startDraw()" color="primary">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-mini-fab matTooltip="Rearrange" (click)="startDraggable()" color="primary">
                                    <mat-icon>open_with</mat-icon>
                                </button>
                            </div>
                            <br>
                            <div fxLayout="row">
                                <button mat-raised-button matTooltip="Save drawing" (click)="savewhiteboardAI()">
                                    <mat-icon>draw_collage</mat-icon>
                                    <label i18n>Start Image Creator</label>
                                </button>
                                <button mat-raised-button matTooltip="Delete drawing" (click)="deletewhiteboard(true)">
                                    <mat-icon>delete</mat-icon>
                                    <label i18n>Delete</label>
                                </button>
                            </div>

                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="whiteboard === true">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title i18n>
                                Draw
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div fxLayout="column">
                            <div>
                                <mat-form-field appearance="outline" *ngIf="company">
                                    <input style="width: 60px;" [style.background]="whiteboardcolor"
                                        [(colorPicker)]="whiteboardcolor" matInput [cpPresetLabel]="'Company colors'"
                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                </mat-form-field>
                            </div>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="whiteboardstokewidth" type="number" matInput i18n-placeholder
                                    placeholder="Stroke" name="stroke">
                            </mat-form-field>



                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="shapedraw" i18n-placeholder placeholder="shape">
                                    <mat-option value="" i18n>Draw</mat-option>
                                    <mat-option value="rectangle" i18n>Rectangle</mat-option>
                                    <mat-option value="circle" i18n>Circly</mat-option>
                                    <mat-option value="figure" i18n>Figure</mat-option>
                                    <mat-option value="line" i18n>Line</mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="whiteboardsmoothing" i18n-placeholder placeholder="Smoothness">
                                    <mat-option value="1" i18n>1 - No smoothing</mat-option>
                                    <mat-option value="4" i18n>4 - Sharp curves</mat-option>
                                    <mat-option value="8" i18n>8 - Smooth curves</mat-option>
                                    <mat-option value="12" i18n>12 - Very smooth curves</mat-option>
                                    <mat-option value="16" i18n>16 - Super smooth curves</mat-option>
                                    <mat-option value="20" i18n>20 - Hyper smooth curves</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-checkbox [(ngModel)]="closewhiteboard" matTooltip="Connect end-points">Close
                            </mat-checkbox>
                            <br>
                            <div fxLayout="row">
                                <button mat-mini-fab matTooltip="Start Draw" (click)="startDraw()" color="primary">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-mini-fab matTooltip="Rearrange" (click)="startDraggable()" color="primary">
                                    <mat-icon>open_with</mat-icon>
                                </button>
                            </div>
                            <br>
                            <div fxLayout="row">
                                <button mat-raised-button matTooltip="Save drawing" (click)="savewhiteboard()">
                                    <mat-icon>save</mat-icon>
                                    <label i18n>Save</label>
                                </button>
                                <button mat-raised-button matTooltip="Delete drawing" (click)="deletewhiteboard()">
                                    <mat-icon>delete</mat-icon>
                                    <label i18n>Delete</label>
                                </button>
                            </div>

                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngFor="let image of images; let i = index" cdkDrag>
                    <div *ngIf="image.type === 'image'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title>
                                        <img *ngIf="image.src" src={{image.src}} class="imagesedit"
                                            style="height: 30px; width: auto;">
                                        <mat-icon (click)="deleteitem(i)" class="iconsize-12">delete</mat-icon>
                                        <mat-icon (click)="copyElement(i, image)" matTooltip="Duplicate element"
                                            class="iconsize-10">
                                            filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxLayout="column">
                                    <div *ngIf="image.src">
                                        <img src={{image.src}} class="imagesedit">
                                    </div>
                                    <app-fileupload *ngIf="!image.src" [account]="Account" [option]="option"
                                        (imgurl)="setImage($event, i)">
                                    </app-fileupload>
                                    <mat-form-field>
                                        <input (change)="detectchange()" type="number" [(ngModel)]="image.opacity"
                                            matInput min="0" max="1" step="0.1" name="opcaity">
                                        <mat-hint>opacity</mat-hint>
                                    </mat-form-field>
                                    <br>
                                    <mat-slide-toggle [(ngModel)]="image.blur" i18n>Blur
                                    </mat-slide-toggle>
                                    <br>
                                    <mat-slide-toggle [(ngModel)]="image.grey" i18n>Grey
                                    </mat-slide-toggle>
                                    <br>
                                    <div fxLayoutGap="20px" fxLayout="row">
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Height</mat-label>
                                            <input (change)="detectchange()" [(ngModel)]="image.style.height" matInput>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Width</mat-label>
                                            <input (change)="detectchange()" [(ngModel)]="image.style.width" matInput>
                                        </mat-form-field>
                                    </div>
                                    <br>
                                    <div fxLayoutGap="20px" fxLayout="row">
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label>Y |</mat-label>
                                            <input (change)="detectchange()" [(ngModel)]="image.posy" type="number"
                                                matInput>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label>X -</mat-label>
                                            <input (change)="detectchange()" [(ngModel)]="image.posx" type="number"
                                                matInput>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div *ngIf="image.type === 'vector'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title>
                                        <div *ngIf="image.src" [inlineSVG]="image.src" class="imagesedit"
                                            style="height: 30px; width: auto;" [onSVGLoaded]="previewSVG"></div>
                                        <mat-icon (click)="deleteitem(i)" class="iconsize-12" i18n>delete</mat-icon>
                                        <mat-icon (click)="copyElement(i, image)" matTooltip="Duplicate element"
                                            class="iconsize-10">
                                            filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div *ngIf="image.src" [inlineSVG]="image.src" class="imagesedit"
                                    [onSVGLoaded]="previewSVGBig">
                                </div>
                                <app-vectorupload *ngIf="!image.src" [account]="Account" [option]="option"
                                    (imgurl)="setVector($event, i)">
                                </app-vectorupload>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div *ngIf="image.type === 'svg'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title>
                                        <label i18n>Drawing</label>
                                        <mat-icon (click)="deleteitem(i)" class="iconsize-12">delete</mat-icon>
                                        <mat-icon (click)="copyElement(i, image)" matTooltip="Duplicate element"
                                            class="iconsize-10">
                                            filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>


                    <div *ngIf="image.type === 'text'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title fxLayout="row" fxLayoutGap="5px">
                                        <label fxflex="80%"
                                            style="overflow: hidden; white-space:nowrap;">{{image.content}}</label>
                                        <mat-icon fxflex="10%" (click)="deleteitem(i)" class="iconsize-12">delete
                                        </mat-icon>
                                        <mat-icon fxflex="10%" (click)="copyElement(i, image)"
                                            matTooltip="Duplicate element" class="iconsize-10">
                                            filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxLayout="column">
                                    <div fxLayout="row" fxLayout.xs="column">

                                        <mat-form-field style="width: 95%;" appearance="outline">
                                            <textarea matTooltip="insert text" #inputpreview [(ngModel)]="image.content"
                                                name="image text" type="text" matInput i18n-placeholder
                                                placeholder="Image text"></textarea>
                                            <mat-icon matTooltip="select Emoji" (click)="onshowemoji(i)" matSuffix>
                                                insert_emoticon</mat-icon>
                                            <mat-hint align="end">{{inputpreview.value?.length || 0}}/90</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" *ngIf="showemoji">
                                        <div fxFlex>
                                            <div style="width: 100%;
                                          height: 100%;
                                          position: absolute;
                                          top: 8;
                                          left: 20;
                                          z-index: 20;">
                                                <emoji-mart (emojiClick)="setemoji($event, i)" set="apple"
                                                    title="enydea">
                                                </emoji-mart>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field>
                                            <input [(ngModel)]="image.style['font-size']" type="text" matInput
                                                i18n-placeholder placeholder="font size" (change)="detectchange()"
                                                name="font">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-accordion>
                                            <mat-expansion-panel style="width: 240px;">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title i18n>
                                                        Text Color
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <br>
                                                <input *ngIf="company" style="width: 230px;"
                                                    [style.background]="image.style['color']" cpDialogDisplay="inline"
                                                    cpDialogDisplay="inline" [(colorPicker)]="image.style['color']"
                                                    (colorPickerChange)="detectchange()"
                                                    [cpPresetLabel]="'Company colors'"
                                                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">


                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                    <br>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field>
                                            <mat-label i18n>Select font</mat-label>
                                            <mat-select [(ngModel)]="image.style['font-family']" name="fontfamily"
                                                (selectionChange)="detectchange()">
                                                <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                    <label [style.font-family]="font.value">{{font.name}}</label>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="row">
                                        <mat-button-toggle fxFlex (click)="setbold(image)" value="bold" i18n>Bold
                                        </mat-button-toggle>
                                        <mat-button-toggle fxFlex (click)="setitalic(image)" value="italic" i18n>Italic
                                        </mat-button-toggle>
                                    </div>

                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div *ngIf="image.type === 'shape'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title>
                                        <div style="height: 30px; width: 30px"
                                            [style.background-color]="image.style['background-color']"></div>
                                        <mat-icon (click)="deleteitem(i)" class="iconsize-12">delete</mat-icon>
                                        <mat-icon (click)="copyElement(i, image)" matTooltip="Duplicate element"
                                            class="iconsize-10">
                                            filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxLayout="column">

                                    <mat-form-field>
                                        <mat-select [(ngModel)]="image.shape" (selectionChange)="onChangeShape(image)">
                                            <mat-option value="square" i18n>Square</mat-option>
                                            <mat-option value="round" i18n>Round</mat-option>
                                            <mat-option value="heart" i18n>Heart</mat-option>
                                            <!-- <mat-option value="star">Star</mat-option> -->
                                            <!-- <mat-option value="triangle">Triangle</mat-option> -->
                                        </mat-select>
                                    </mat-form-field>

                                    <input *ngIf="company" style="width: 230px;"
                                        [style.background]="image.style['background-color']" cpDialogDisplay="inline"
                                        cpDialogDisplay="inline" [(colorPicker)]="image.style['background-color']"
                                        (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div *ngIf="image.type === 'chart'">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header cdkDragHandle>
                                    <mat-panel-title>
                                        <mat-icon>show_chart</mat-icon>
                                        <mat-icon (click)="deleteitem(i)" class="iconsize-12" i18n>delete</mat-icon>
                                        <mat-icon (click)="copyElement(i, image)" matTooltip="Duplicate element"
                                            class="iconsize-10">filter_none</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Data
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngFor="let label of image.label; let i1 = index;">
                                            <mat-form-field fxflex>
                                                <input (change)="detectchangeLabel(i, i1, image.label[i1])"
                                                    [(ngModel)]="image.label[i1]" type="text" matInput i18n-placeholder
                                                    placeholder="label" name="label">
                                            </mat-form-field>
                                            <mat-icon (click)="deletelabel(i, i1)" class="iconsize-12">delete</mat-icon>
                                        </div>

                                        <mat-icon (click)="addLabel(i)">add</mat-icon>
                                        <div *ngFor="let row of image.data; let i1 = index;" fxLayout="column">

                                            <mat-form-field fxflex>
                                                <input (change)="detectchangerowlabel(i, i1, row.label)"
                                                    [(ngModel)]="row.label" type="text" matInput name="rowlabel">
                                            </mat-form-field>
                                            <div fxFlex fxLayout="row wrap">
                                                <div *ngFor="let cell of row.data; let i2 = index;">
                                                    <mat-form-field fxflex style="width: 20px;">
                                                        <input (change)="detectchangerowcell(i, i1, i2, row.data[i2])"
                                                            [(ngModel)]="row.data[i2]" type="text" matInput
                                                            name="rowdata">
                                                    </mat-form-field>
                                                </div>
                                                <mat-icon (click)="deletecell(i, i1)" class="iconsize-12">delete
                                                </mat-icon>
                                            </div>

                                            <mat-icon (click)="addcell(i, i1)">add</mat-icon>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header cdkDragHandle>
                                            <mat-panel-title i18n>
                                                Colors
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngFor="let colors of image.colors" fxLayout="column">
                                            <label i18n>Color Specification</label>
                                            <input *ngIf="company" style="width: 150px;"
                                                [style.background]="colors.backgroundColor" cpDialogDisplay="inline"
                                                cpDialogDisplay="inline" [(colorPicker)]="colors.backgroundColor"
                                                (colorPickerChange)="detectchange()" [cpCancelButton]="true"
                                                [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12" (click)="colors.backgroundColor = ''">delete
                                            </mat-icon>
                                            <input *ngIf="company" style="width: 150px;"
                                                [style.background]="colors.borderColor" cpDialogDisplay="inline"
                                                cpDialogDisplay="inline" [(colorPicker)]="colors.borderColor"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12" (click)="colors.borderColor = ''">delete
                                            </mat-icon>
                                            <input *ngIf="company" style="width: 150px;"
                                                [style.background]="colors.pointBackgroundColor"
                                                cpDialogDisplay="inline" cpDialogDisplay="inline"
                                                [(colorPicker)]="colors.pointBackgroundColor"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12" (click)="colors.pointBackgroundColor = ''">
                                                delete
                                            </mat-icon>
                                            <input *ngIf="company" style="width: 150px;"
                                                [style.background]="colors.pointBorderColor" cpDialogDisplay="inline"
                                                cpDialogDisplay="inline" [(colorPicker)]="colors.pointBorderColor"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12" (click)="colors.pointBorderColor = ''">delete
                                            </mat-icon>
                                        </div>

                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Chart type
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="image.charttype"
                                                (selectionChange)="detectchangetype(i, image.charttype)">
                                                <mat-option value="line" i18n>line</mat-option>
                                                <mat-option value="bar" i18n>bar</mat-option>
                                                <mat-option value="radar" i18n>radar</mat-option>
                                                <mat-option value="pie" i18n>pie</mat-option>
                                                <mat-option value="polarArea" i18n>Polararea</mat-option>
                                                <mat-option value="doughnut" i18n>Doughnut</mat-option>
                                                <!-- <mat-option value="bubble">Bubble</mat-option>
                                                      <mat-option value="scatter">Scatter</mat-option> -->
                                            </mat-select>
                                        </mat-form-field>
                                        <div fxLayout="column">
                                            <label i18n>X-Axe
                                                Gridline color</label>
                                            <input *ngIf="company"
                                                [style.background]="image.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                cpDialogDisplay="inline" cpDialogDisplay="inline"
                                                [(colorPicker)]="image.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12"
                                                (click)="image.lineChartOptions.scales.xAxes[0].gridLines.color = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n> X-Axe
                                                Font color</label>
                                            <input *ngIf="company"
                                                [style.background]="image.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                cpDialogDisplay="inline" cpDialogDisplay="inline"
                                                [(colorPicker)]="image.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12"
                                                (click)="image.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n>Y-axe
                                                Gridline color</label>
                                            <input *ngIf="company"
                                                [style.background]="image.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                cpDialogDisplay="inline" cpDialogDisplay="inline"
                                                [(colorPicker)]="image.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12"
                                                (click)="image.lineChartOptions.scales.yAxes[0].gridLines.color = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n>Y-axe
                                                Font color</label>
                                            <input *ngIf="company"
                                                [style.background]="image.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                cpDialogDisplay="inline" cpDialogDisplay="inline"
                                                [(colorPicker)]="image.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            <mat-icon class="iconsize-12"
                                                (click)="image.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                delete
                                            </mat-icon>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-icon (click)="addgraph(i)">add</mat-icon>
                                <mat-icon (click)="deletegraph(i)">delete</mat-icon>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf='showprogressbar' class="showprogressbardiv">
        <div style="width: 50%; margin: 0 auto; justify-content: center; align-items: center;">
            <mat-spinner style="width: 50%; margin: 0 auto;" mode="indeterminate"></mat-spinner>
            <br>
            <h1 i18n>Creating your awesome image one moment please... </h1>
        </div>
    </div>

    <div *ngIf="!createnew && !canvas.bgimg" style="height:80vh; width:100%;" fxLayout="column"
        fxLayoutAlign="center center">
        <h2>Select Image to edit or create new with AI tool or as blank.</h2>
        <app-fileupload *ngIf="!canvas.bgimg && Account && option" [account]="Account" [option]="option"
            (imgurl)="setMainImage($event)" style="width:300px">
        </app-fileupload>
        <br>
        <app-vectorupload *ngIf="!canvas.bgimg && Account && option" [account]="Account" [option]="option"
            (imgurl)="setMainSVG($event)" style="width:300px">
        </app-vectorupload>
        <br>
        <div fxLayout="row" fxLayoutGap="20px">
            <button mat-raised-button (click)="createnew = true">Create New</button>
            <button mat-raised-button (click)="startWithNewAIImage()">Start with AI Image</button>
        </div>

    </div>

    <div id="imagecontainer" *ngIf="createnew || canvas.bgimg" style="height: 80vh; overflow: scroll; padding: 40px;"
        ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">

        <div *ngIf="changenow" #myBounds id="myBounds" [style.transform]="'scale('+zoomfactor+')'" [ngStyle]="canvas"
             class="template-container-main" [class.busy]="busy">
<!-- style="overflow:hidden;" -->
            <div style="width: 100%; height: 100%; z-index: -1; position: absolute; top: 0;
            left: 0;" (click)="selectedimage = undefined"></div>

            <img *ngIf="canvas.bgimg && canvas.cliparray.length === 0" id="imagebgcontainer" (load)="onLoad()"
                #imagebgcontainer style="position: absolute; pointer-events: none;" src={{canvas.bgimg}}
                [style.width]="canvas.bgwidth" [style.height]="canvas.bgheight" [style.clip-path]="'url(#cropclip)'"
                [class.imggreyclass]="canvas.grey" [class.imgblur]="canvas.blur">

            <div *ngIf="canvas.cropimages" [style.z-index]="imgzindex"
                style="position: absolute; width: 100%; height: 100%;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    width="100%" height="100%" viewBox="0 0 500 500" id="croppathset">
                    <defs>
                        <clipPath id="cropclip">
                            <path id="croppath" style="fill:transparent; stroke:black; stroke-width: 2px;"
                                [attr.d]="canvas.clippath" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div *ngIf="AIwhiteboard === true" class="whiteboard" id="svgElementParent"
                style="z-index: 1000; position: absolute;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    id="svgElement" width="100%" height="100%" style="fill:#e52b50;" viewBox="0 0 500 500"></svg>
            </div>

            <div *ngIf="whiteboard === true" class="whiteboard" id="svgElementParent"
                style="z-index: 1000; position: absolute;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    id="svgElement" width="100%" height="100%"
                    style="fill:transparent; stroke:black; stroke-width: 2px;" viewBox="0 0 500 500"></svg>
            </div>

            <div *ngFor="let image of images; let i = index;" (mousedown)="setDraggable($event, image)"
                (click)="onSelectElement($event, image)">
                <!-- [position]="image.setpos" -->
                <div *ngIf="image.type === 'image'" [ngStyle]="image.style" [ngStyle]="{'opacity': image.opacity}"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [style.width]="image.style.width" [style.height]="image.style.height" [id]="image.id"
                    (rzResizing)="onResizing($event, i)" [ngResizable]="editmode" [rzAspectRatio]="true">
                    <img *ngIf="image.src" style="width: 100%; height: 100%;" src={{image.src}}
                        [ngClass]="{'imggreyclass':image.grey, 'imgblur': image.blur}" [class.imgblur]="image.blur">
                    <!-- [class.imggreyclass]="image.grey" [class.imgblur]="image.blur" -->

                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div>
                <div *ngIf="image.type === 'vector'" [ngStyle]="image.style"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [style.width]="image.style.width" [style.height]="image.style.height" [id]="image.id"
                    [class]="image.style.class" (rzResizing)="onResizing($event, i)" [ngResizable]="editmode"
                    [rzAspectRatio]="true">
                    <div style="height: 100%; width: 100%;" [inlineSVG]="image.src"></div>

                    <!-- [innerHTML]="image.svg | safeHtml" -->
                    <!-- <div style="height: 100%; width: 100%;" src="image.svg">
                    </div> -->
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div>

                <div *ngIf="image.type === 'svg'" [ngStyle]="image.style"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [style.width]="image.style.width" [style.height]="image.style.height" [id]="image.id"
                    [class]="image.style.class" (rzResizing)="onResizing($event, i)" [ngResizable]="editmode"
                    [rzAspectRatio]="true">

                    <div style="height: 100%; width: 100%;" [innerHTML]="image.svg | safeHtml"></div>

                    <!-- [innerHTML]="image.svg | safeHtml" -->
                    <!-- <div style="height: 100%; width: 100%;" src="image.svg">
                </div> -->
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div>

                <label (dblclick)="setEditText()" [ngResizable]="editmode" *ngIf="image.type === 'text' && !edittext"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [id]="image.id" [style.width]="image.style.width" [style.height]="image.style.height"
                    class="elementanimationarray" (rzResizing)="onResizing($event, i)" [ngStyle]="image.style"
                    [rzAspectRatio]="false">{{image.content}}
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </label>
                <mat-form-field *ngIf="image.type === 'text' && edittext" (dblclick)="setEditText()"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [attr.id]="image.id" [ngStyle]="image.style" class="elementanimationarray">
                    <textarea matInput [(ngModel)]="image.content" style="width: 100%; height: 100%;"
                        name="content"></textarea>
                    <mat-icon *ngIf="image === selectedimage" class="iconsize-4" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </mat-form-field>

                <div *ngIf="image.type === 'shape'" [class]='image.style.class' [attr.id]="image.id"
                    [rzAspectRatio]="image.shape === 'heart'" [ngStyle]="image.style" [style.width]="image.style.width"
                    [style.height]="image.style.height" [ngResizable]="editmode"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    (rzResizing)="onResizing($event, i)">
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div>
                <!-- <div *ngIf="image.type === 'vector'" [id]="image.id" [style.height]="image.style.height"
                    (rzResizing)="onResizing($event, i)" [ngResizable]="editmode"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [rzAspectRatio]="false" [ngStyle]="image.style"> -->
                <!-- <div style="height: 100%; width: 100%;" [innerHTML]="image.src | safeHtml">
                    </div> -->
                <!-- <img *ngIf="image.src" style="width: 100%; height: 100%;" src={{image.src}}>
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div> -->
                <div *ngIf="image.type === 'chart'" [id]="image.id" [style.height]="image.style.height"
                    (rzResizing)="onResizing($event, i)" [ngResizable]="editmode"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: image === selectedimage }"
                    [rzAspectRatio]="false" [ngStyle]="image.style">

                    <canvas baseChart width="image.style.width" height="image.style.height" [datasets]="image.data"
                        [labels]="image.label" [colors]="image.colors" [legend]="image.legend"
                        [options]="image.lineChartOptions" [chartType]="image.charttype" [attr.id]="'graph' + i">
                    </canvas>
                    <mat-icon *ngIf="image === selectedimage" class="rotatehandle"
                        [attr.id]="image.id + 'rotatehandle'">replay</mat-icon>
                </div>
                <!--  -->
            </div>
        </div>

    </div>

</div>