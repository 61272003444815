<div fxLayout="column">
    <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
        [vertical]="false">
        <mat-button-toggle matTooltip="Text Tool" style="width: 50%" value="aitool" [checked]="true">
            <mat-icon>quickreply</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Translate" style="width: 50%" value="translate">
            <mat-icon>translate</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <br>

    <div *ngIf="group.value == 'aitool'">
        <div class="aicontainer">
            <h4>Text Helper</h4>
            <div fxLayout="column" style="width: 95%;">
                <mat-form-field appearance="outline">
                    <mat-label>Ask your Question</mat-label>
                    <textarea matInput placeholder="Question" i18n-placeholder name="Question" type="Question"
                        [(ngModel)]="searchstring" (keydown.enter)="getSuggestions()" [disabled]="waiting"> </textarea>
                    <mat-icon matTooltip="Get" (click)="getSuggestions()" matSuffix>
                        search</mat-icon>
                </mat-form-field>

                <a mat-mini-fab i18n-matTooltip matTooltip="Speech to Text recording" color="primary"
                    (click)="activateSpeech()" [class.speechbutton]="recordactive">
                    <mat-icon>record_voice_over</mat-icon>
                </a>

                <mat-progress-bar *ngIf="waiting" mode="indeterminate">
                </mat-progress-bar>
                <!-- <mat-icon (click)="getSuggestions()" fxFlex="10%">search</mat-icon> -->

            </div>
            <br>

            <!-- <label style="margin: 20px;">{{resultstring}}</label> -->
            <mat-form-field style="width: 95%;" appearance="outline" *ngIf="resultstring">
                <mat-label>Answer</mat-label>
                <textarea matTooltip="Answer Text" #inputpreview [(ngModel)]="resultstring" name="Text" type="text"
                    matInput i18n-placeholder placeholder="Answer" style="height: 200px;"></textarea>
                <mat-icon matTooltip="copy" (click)="copy(resultstring)" matSuffix>
                    content_copy</mat-icon>
                <mat-hint align="end">{{inputpreview.value?.length || 0}}</mat-hint>
            </mat-form-field>
            <br>
            

        </div>
    </div>

    <div *ngIf="group.value == 'translate'">
        <div class="aicontainer">
            <h4>Text Translator</h4>
            <div fxLayout="column" style="width: 95%;">
                <mat-form-field appearance="outline">
                    <mat-label>Translate Text</mat-label>
                    <textarea matInput placeholder="Question" i18n-placeholder name="Translate Text" type="Question"
                        [(ngModel)]="searchstringtranslate" (keydown.enter)="getTranslation()"
                        [disabled]="waiting"> </textarea>
                    <mat-icon matTooltip="Get" (click)="getTranslation()" matSuffix>
                        translate</mat-icon>
                </mat-form-field>

                <br>

                <div fxLayout="row" fxLayoutGap="40px">
                    <a mat-mini-fab i18n-matTooltip matTooltip="Speech to Text recording" color="primary"
                        (click)="activateSpeechTranslate()" [class.speechbutton]="recordactive">
                        <mat-icon>record_voice_over</mat-icon>
                    </a>
                    <mat-form-field appearance="outline">
                        <mat-label>Source language</mat-label>
                        <mat-select i18n-placeholder placeholder="Source language" [(ngModel)]="sourceLang"
                            name="sourcelanguage">
                            <mat-option *ngFor="let lang of sourcelanguages" [value]="lang.code">
                                {{lang.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Target language</mat-label>
                        <mat-select i18n-placeholder placeholder="Target language" [(ngModel)]="targetLang"
                            name="targetlanguage">
                            <mat-option *ngFor="let lang of targetlanguages" [value]="lang.code">
                                {{lang.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-progress-bar *ngIf="waitingtranslate" mode="indeterminate">
                </mat-progress-bar>
                <!-- <mat-icon (click)="getSuggestions()" fxFlex="10%">search</mat-icon> -->

            </div>
            <br>

            <!-- <label style="margin: 20px;">{{resultstring}}</label> -->
            <mat-form-field style="width: 95%;" appearance="outline" *ngIf="resultstringtranslate">
                <mat-label>Translation</mat-label>
                <textarea matTooltip="Answer Text" #inputpreview [(ngModel)]="resultstringtranslate" name="Text"
                    type="text" matInput i18n-placeholder placeholder="Answer" style="height: 200px;"></textarea>
                <mat-icon matTooltip="copy" (click)="copy(resultstringtranslate)" matSuffix>
                    content_copy</mat-icon>
                <mat-hint align="end">{{inputpreview.value?.length || 0}}</mat-hint>
            </mat-form-field>
            <br>
            <div fxLayout="row" fxLayoutGap="20px">
                <button mat-button (click)="fileInput.click()" i18n i18n-matTooltip matTooltip="supports docx, pptx, html, txt, xlf">Choose file</button>
                <span
                    style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                    <input type="file" #fileInput ng2FileSelect [uploader]="uploaderSec" />
                </span>
                <mat-checkbox *ngIf="xliff" [(ngModel)]="oldxliff">Old XLIFF(before 2.1)</mat-checkbox>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" *ngFor="let item of uploaderSec.queue">
                <span>{{ item?.file?.name }}</span>
                <button mat-raised-button (click)="setuploadSec()"
                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
                    <mat-icon>file_upload</mat-icon>Translate File
                </button>
            </div>
            <br>
            <mat-progress-bar *ngIf="busyconverting" mode="indeterminate">
            </mat-progress-bar>
            <label style="font-size: 10pt; color: red">{{errorMessage}}</label>

        </div>
    </div>
</div>