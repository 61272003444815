import { Injectable } from '@angular/core';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import { parseInt } from 'core-js/core/number';
import { DomSanitizer } from '@angular/platform-browser';
import { BodyPix, PersonSegmentation, SemanticPersonSegmentation, toMask } from '@tensorflow-models/body-pix';
export type Canvas = HTMLCanvasElement;
import { getLabels, getColormap } from '@tensorflow-models/deeplab';
// import { BodyPix } from '@tensorflow-models/body-pix';
// const bodyPix = require('@tensorflow-models/body-pix');
// import * as bodyPix from '@tensorflow/tfjs';
const offScreenCanvases: { [name: string]: Canvas } = {};
const CANVAS_NAMES = {
  blurred: 'blurred',
  blurredMask: 'blurred-mask',
  mask: 'mask',
  lowresPartMask: 'lowres-part-mask',
};
type ImageType = HTMLImageElement | HTMLVideoElement | Canvas;

@Injectable({
  providedIn: 'root'
})
export class TensorflowService {

  constructor(
    private sanitizer: DomSanitizer,
    // private bodyPix: BodyPix
  ) { }

  async blurBackgroundImage(img, id) {
    const bodyPix = require('@tensorflow-models/body-pix');
    const canvas = document.createElement("CANVAS") as HTMLCanvasElement;
    //document.getElementById(id).appendChild(canvas);

    const net = await bodyPix.load();
    const segmentation = await net.segmentPerson(img);

    const backgroundBlurAmount = 3;
    const edgeBlurAmount = 3;
    const flipHorizontal = false;

    //const canvas = document.getElementById('canvas');
    // Draw the image with the background blurred onto the canvas. The edge between
    // the person and blurred background is blurred by 3 pixels.
    bodyPix.drawBokehEffect(
      canvas, img, segmentation, backgroundBlurAmount,
      edgeBlurAmount, flipHorizontal);

    const dataURL = canvas.toDataURL();

    return dataURL;
  }


  async classifyImage(img) {

    //const bodyPix = require('@tensorflow-models/body-pix');
    const mobilenet = require('@tensorflow-models/mobilenet');
    const model = await mobilenet.load();
    // Classify the image.
    const predictions = await model.classify(img);
    return predictions;


  }



  async removeBackgroundImage(img, width, height, id, imgorg) {
    try {

      const canvas = document.createElement('canvas');  //  as HTMLCanvasElement;
      // document.getElementById(id).parentElement.parentElement.appendChild(canvas)
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height)

      const bodyPix = require('@tensorflow-models/body-pix');
      const net = await bodyPix.load({
        architecture: 'ResNet50',
        outputStride: 32,
        quantBytes: 2
      })
      const segmentation = await net.segmentPerson(img, {
        flipHorizontal: false,
        internalResolution: 'medium',
        segmentationThreshold: 0.7
      })
      const { data: imgData } = ctx.getImageData(0, 0, canvas.width, canvas.height)

      const newImg = ctx.createImageData(canvas.width, canvas.height)
      const newImgData = newImg.data

      for (let i = 0; i < segmentation.data.length; i++) {
        const segment = segmentation.data[i];
        if (segment == 1) {
          newImgData[i * 4] = imgData[i * 4]
          newImgData[i * 4 + 1] = imgData[i * 4 + 1]
          newImgData[i * 4 + 2] = imgData[i * 4 + 2]
          newImgData[i * 4 + 3] = imgData[i * 4 + 3]
        }
      }


      ctx.putImageData(newImg, 0, 0)


      const dataURL = canvas.toDataURL();

      return dataURL;
    } catch (error) {
      console.log(error)
      return 'failed'
    }

  }


  async removeBackgroundImageBlurEdges(img, width, height, id, imgorg): Promise<any> {

    try {


      const canvas = document.createElement('canvas');  //  as HTMLCanvasElement;
      // document.getElementById(id).parentElement.parentElement.appendChild(canvas)
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');


      const bodyPix = require('@tensorflow-models/body-pix');
      const net = await bodyPix.load({
        architecture: 'ResNet50',
        outputStride: 32,
        quantBytes: 2
      })
      const segmentation = await net.segmentPerson(img, {
        flipHorizontal: false,
        internalResolution: 'medium',
        segmentationThreshold: 0.7
      })

      const canvasBG = document.createElement('canvas');
      canvasBG.width = width;
      canvasBG.height = height;

      let personmask = this.createPersonMask(segmentation, 10)
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height)
      this.drawWithCompositing(ctx, personmask, 'destination-in')
      this.drawWithCompositing(ctx, canvasBG, 'destination-over');
      ctx.restore();

      const blob: Blob = await new Promise(resolve => canvas.toBlob(resolve));

      return blob
    } catch (error) {
      console.log(error)
      return 'failed'
    }

  }

  createPersonMask(
    multiPersonSegmentation: PersonSegmentation[] | SemanticPersonSegmentation,
    edgeBlurAmount: number): Canvas {
    const backgroundMaskImage = toMask(
      multiPersonSegmentation, { r: 0, g: 0, b: 0, a: 255 },
      { r: 0, g: 0, b: 0, a: 0 });

    const backgroundMask =
      this.renderImageDataToOffScreenCanvas(backgroundMaskImage, CANVAS_NAMES.mask);
    if (edgeBlurAmount === 0) {
      return backgroundMask;
    } else {
      return this.drawAndBlurImageOnOffScreenCanvas(
        backgroundMask, edgeBlurAmount, CANVAS_NAMES.blurredMask);
    }
  }

  drawWithCompositing(
    ctx: any,
    image: Canvas | ImageType,
    compositeOperation: any) {
    ctx.globalCompositeOperation = compositeOperation;
    ctx.drawImage(image, 0, 0);
  }

  renderImageDataToOffScreenCanvas(
    image: ImageData, canvasName: string): Canvas {
    const canvas = this.ensureOffscreenCanvasCreated(canvasName);
    this.renderImageDataToCanvas(image, canvas);

    return canvas;
  }

  ensureOffscreenCanvasCreated(id: string): Canvas {
    if (!offScreenCanvases[id]) {
      offScreenCanvases[id] = document.createElement('canvas');
    }
    return offScreenCanvases[id];
  }

  renderImageDataToCanvas(image: ImageData, canvas: Canvas) {
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');

    ctx.putImageData(image, 0, 0);
  }

  drawAndBlurImageOnOffScreenCanvas(
    image: ImageType, blurAmount: number,
    offscreenCanvasName: string): Canvas {
    const canvas = this.ensureOffscreenCanvasCreated(offscreenCanvasName);
    if (blurAmount === 0) {
      this.renderImageToCanvas(image, canvas);
    } else {
      this.drawAndBlurImageOnCanvas(image, blurAmount, canvas);
    }
    return canvas;
  }

  renderImageToCanvas(image: ImageType, canvas: Canvas) {
    const { width, height } = image;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, 0, 0, width, height);
  }

  drawAndBlurImageOnCanvas(
    image: ImageType, blurAmount: number, canvas: Canvas) {
    const { height, width } = image;
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.clearRect(0, 0, width, height);
    ctx.save();
    if (this.isSafari()) {
      this.cpuBlur(canvas, image, blurAmount);
    } else {
      // tslint:disable:no-any
      (ctx as any).filter = `blur(${blurAmount}px)`;
      ctx.drawImage(image, 0, 0, width, height);
    }
    ctx.restore();
  }

  isSafari() {
    return (/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }

  cpuBlur(
    canvas: HTMLCanvasElement,
    image: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement,
    blur: number) {
    const ctx = canvas.getContext('2d');

    let sum = 0;
    const delta = 5;
    const alphaLeft = 1 / (2 * Math.PI * delta * delta);
    const step = blur < 3 ? 1 : 2;
    for (let y = -blur; y <= blur; y += step) {
      for (let x = -blur; x <= blur; x += step) {
        const weight =
          alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta));
        sum += weight;
      }
    }
    for (let y = -blur; y <= blur; y += step) {
      for (let x = -blur; x <= blur; x += step) {
        ctx.globalAlpha = alphaLeft *
          Math.exp(-(x * x + y * y) / (2 * delta * delta)) / sum * blur;
        ctx.drawImage(image, x, y);
      }
    }
    ctx.globalAlpha = 1;
  }

  async objectDetection(img, id, width, height): Promise<any> {
    // const cocoSsd = require('@tensorflow-models/coco-ssd');
    // const model = await cocoSsd.load();
    // // Classify the image.
    // const predictions = await model.detect(img);
    // return predictions;

    // const mobilenet = require('@tensorflow-models/mobilenet');
    // const model = await mobilenet.load();v
    // // Classify the image.
    // const predictions = await model.classify(img);
    // return predictions;


    const model = 'ade20k'; // set to your preferred model, either `pascal`, `cityscapes` or `ade20k`
    const colormap = getColormap(model);
    const labels = getLabels(model);
    const tfconv = require('@tensorflow/tfjs-converter');
    const deeplab = require('@tensorflow-models/deeplab');
    const loadModel = async () => {
      const modelName = model;
      const quantizationBytes = 2;  // either 1, 2 or 4
      return await deeplab.load({ base: modelName, quantizationBytes });
    };

    const canvas = document.createElement('canvas');  //  as HTMLCanvasElement;
    document.getElementById(id).parentElement.parentElement.appendChild(canvas)
    canvas.width = width;
    canvas.height = height;



    loadModel().then(async (model) => {
      let newcanvas = await model.segment(img, { canvas: canvas });
      return newcanvas;

    });
  }




}
