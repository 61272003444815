import { ChartDataSets, ChartOptions, Chart } from 'chart.js';
import { Color, BaseChartDirective, Label, MultiDataSet } from 'ng2-charts';
import { CountUpOptions, CountUp } from 'countup.js';

export class chart {
  type: 'chart';
  groupmember: false;
  start_time: number;
  delay: number;
  charttype: string;
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  label: Label[] = [];
  data: any;
  productiondata: any;
  originaldata: any;
  //options:
  savecolorset: any;
  colors: Color[] = [
    { // grey
      backgroundColor: '#232222',
      borderColor: '#232222',
      pointBackgroundColor: '#232222',
      pointBorderColor: '#fff'
    }
  ]
  legend: true;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
    'box-shadow': string;
    'filter': string;
  }
  lineChartOptions: ChartOptions = {
    legend: {
      labels: {
        fontFamily: 'Open Sans',
        fontSize: 14,
      },
      onClick: function (e, legendItem) { }
    },
    animation: {
      duration: 1000,
      easing: 'easeInQuad'
    },

  }
}

export function factoryChart(newelnr, colorset, anim, lineChartOptions, motionpath: Array<string>, newz) {
  let chart = {
    groupmember: false,
    start_time: 0,
    type: 'chart',
    src: '',
    charttype: 'line',
    id: newelnr,
    label: ['January', 'February', 'March'],
    data: [
      { data: [65, 59, 40], label: 'Series A' },
      { data: [28, 27, 90], label: 'Series B' }
    ],
    productiondata: [
      { data: [0, 0, 0], label: 'Series A' },
      { data: [0, 0, 0], label: 'Series B' }
    ],
    originaldata: [
      { data: [65, 59, 40], label: 'Series A' },
      { data: [28, 27, 90], label: 'Series B' }
    ],
    //options:
    colors: colorset,
    savecolorset: colorset,
    legend: true,
    style: {
      'z-index': newz,
      width: '400px',
      height: '220px',
      position: 'absolute',
      opacity: 1
    },
    posx: 20,
    posy: 50,
    setpos: { 'x': 20, 'y': 50 },
    animation: anim,
    lineChartOptions: lineChartOptions,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    motionpath: motionpath
  }
  return chart;

}

export class animationtype {
  start_time: number; //delayt
  delay: number;
  end_time: number;
  anim_type: string;
  duration: number;
  ease: string;
  posx: number;
  posy: number;
  rotationcycle: string;
  travellocX: number;
  travellocY: number;
  scalesize: number;
  skewY: number;
  skewX: number;
  easetype: any;
  fromto: string;
  transformOriginX: string;
  transformOriginY: string;
  repeat: number;
  yoyo: boolean;
  audioeffectsrc: string;
  rotationkeeppos: boolean
}

export class vectoranimationtype {
  svganimationpaths: Array<Array<string>>;
  svganimationtype: string;
  drawcolor: string;
  linethickness: string;
  repeat: number;
  yoyo: boolean;
  fillright: string;
  fillleft: string;
  drawright: string;
  drawleft: string;
  start_time: number; //delay
  end_time: number;
  delay: number;
  duration: number;
  hideimage: boolean;
  easetype: any;
  fromto: string;
  colorpath: string;
  selectedpaths: Array<string>;
  keepshape: boolean;
}

export class splittexttype {
  textanimationtype: string;
  repeat: number;
  start_time: number; //delay
  delay: number;
  end_time: number;
  duration: number;
  x: number;
  y: number;
  fromto: string;
  easetype: any;
  rotationX: number;
  rotationY: number;
}

export class threedanimation {
  type: 'threed';
  mesh: Object;
  groupmember: false;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: string;
    opacity: 1;
    'clip-path': string;
    'box-shadow': string;
    'filter': string;
  };
  clippath: string;
  src: string;
  blobsrc: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  grey: false;
  cliparray: [];
  wireframe: false;
  wireframesize: 1;
  color: string;
  transparent: false;
  opacity: number;
  components: Array<Object>;
  materialsrcfile: string;
  rotationx: number; //0.01
  rotationy: number;//0.01
  rotationz: number; //0.01
  partpropertiesarray: Array<positUuid>;
  explodedepthx: number;
  explodedepthy: number;
  explodedepthz: number;
  positionx: number;
  positiony: number;
  positionz: number;
  explode: false;
  //createScreenshot: boolean;
  threedanimations: Array<threedanimationcontrol>
  center: boolean;
  dragcontrolgroup: boolean;
  shininess: number;
  roughness: number;
  svg: string;
  svgurl: string;
  exdepth: number;
  multiplyScalar: number;
  shadows: boolean;
  texture: string;
  uuid: string;
  scale: number;
  bevel: boolean;
  imageurl: string;
  imagewidth: number;
  imageheight: number;
  illuminated: boolean;
  videourl: string;
}

export function factoryThreedanimation(newz, canvas, anim, newelnr, motionpath: Array<string>, svg?: string, svgurl?: string, imageurl?:string, videourl?:string) {
  let threed: threedanimation = {
    type: 'threed',
    mesh: undefined,
    groupmember: false,
    style: {
      'z-index': newz,
      width: canvas.width,
      height: canvas.height,
      position: 'relative',
      opacity: 1,
      'clip-path': '',
      'box-shadow': '',
      'filter': ''
    },
    clippath: '',
    cliparray: [],
    src: '',
    blobsrc: '',
    posx: 0,
    posy: 0,
    setpos: { 'x': 0, 'y': 0 },
    animation: anim,
    id: newelnr,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    grey: false,
    motionpath: motionpath,
    wireframe: false,
    wireframesize: 1,
    color: '#23549d',
    opacity: 1,
    transparent: false,
    components: [],
    materialsrcfile: '',
    explode: false,
    explodedepthx: 100,
    explodedepthy: 100,
    explodedepthz: 100,
    positionx: 0,
    positiony: 0,
    positionz: 0,
    rotationx: 0, //0.01
    rotationy: 0,//0.01
    rotationz: 0, //0.01
    //createScreenshot: false,
    partpropertiesarray: [],
    threedanimations: [],
    center: false,
    dragcontrolgroup: false,
    shininess: 0.1,
    roughness: 1,
    svg: svg,
    svgurl: svgurl,
    exdepth: 20,
    multiplyScalar: 0.01,
    shadows: true,
    texture: '',
    uuid: '',
    scale: 1,
    bevel: false,
    imageurl: imageurl,
    videourl: videourl,
    imagewidth: 200,
    imageheight: 200,
    illuminated: false
  }

  return threed;
}

export interface threedanimationcontrol {
  type: string;
  explodedepthx: number;
  explodedepthy: number;
  explodedepthz: number;
  rotationx: number; //0.01
  rotationy: number;//0.01
  rotationz: number; //0.01
  positionx: number; //0.01
  positiony: number;//0.01
  positionz: number; //0.01
  repeat: number;
  start_time: number; //delay
  delay: number;
  end_time: number;
  duration: number;
  x: number;
  y: number;
  z: number
  fromto: string;
  easetype: any;
  yoyo: boolean;
  scalex: number;
  scaley: number;
  scalez: number;
  motionpath: Array<string>;
  motionrotation: number;
  adjustrotation: boolean;
  ysvg: string;
  clips: Array<any>
}

export function factoryThreedanimationcontrol() {
  let threedanimationcontrol: threedanimationcontrol = {
    type: '',
    rotationx: 0.00, //0.01
    rotationy: 360, //0.01
    rotationz: 0.00, //0.01
    positionx: 0.00, //0.01
    positiony: 0.00, //0.01
    positionz: 0.00, //0.01
    explodedepthx: 200,
    explodedepthy: 200,
    explodedepthz: 200,
    start_time: 0, //delayt
    delay: 0,
    end_time: 10,
    easetype: 'scale',
    x: 0,
    y: 0,
    z: 0,
    fromto: 'to',
    duration: 3,
    repeat: 0,
    yoyo: false,
    scalex: 1.2,
    scaley: 1.2,
    scalez: 1.2,
    motionpath: [],
    motionrotation: 0,
    adjustrotation: true,
    ysvg: '',
    clips: []
  }
  return threedanimationcontrol
}

export interface positUuid {
  texture: any;
  name: string,
  uuid: string,
  nameId: string,
  position: {
    x: number,
    y: number,
    z: number
  }
  color: any,
  visible: boolean,
  illuminated: boolean,
}

export class imageanimation {
  type: 'image';
  groupmember: false;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
    'clip-path': string;
    'box-shadow': string;
    'filter': string;
  };
  clippath: string;
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  grey: false;
  blur: false;
  blurborder: false;
  cliparray: [];
  opacity: number;
  blobsrc: string;
  'clip-path': string;
  effect: string;
}

export function imageanimationFactory(anim, newelnr, motionpath: Array<string>, newz) {
  let img: imageanimation = {
    type: 'image',
    groupmember: false,
    style: {
      'z-index': newz,
      width: "auto",
      height: "auto",
      position: 'absolute',
      opacity: 1,
      'clip-path': '',
      'box-shadow': '',
      'filter': ''
    },
    clippath: '',
    cliparray: [],
    src: '',
    posx: 0,
    posy: 0,
    setpos: { 'x': 0, 'y': 0 },
    animation: anim,
    id: newelnr,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    grey: false,
    blur: false,
    blurborder: false,
    motionpath: motionpath,
    opacity: 1,
    blobsrc: '',
    'clip-path': '',
    effect: ''
  }
  return img;
}

export interface countertype {
  counteranimationtype: string;
  repeat: number;
  start_time: number; //delay
  delay: number;
  end_time: number;
  duration: number;
  x: number;
  y: number;
  fromto: string;
  easetype: any;
  svganimationtype: string;
  drawcolor: string;
  linethickness: string;
  yoyo: boolean;
  fillright: string;
  fillleft: string;
  drawright: string;
  drawleft: string;
  hideimage: boolean;
  counterstyle: string;
  fromr: number;
  tor: number;
}

export interface counteranimation extends CountUpOptions {
  type: 'counter';
  count: number;
  maxcount: number;
  mincount: number;
  groupmember: false;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
    'box-shadow': string;
    //'filter': string;
    'font-size': string;
    'font-style': string;
    'font-weight': string;
    'font-family': string;
    'color': string;
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  countertype: countertype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  onclickurl: string;
  onhovershow: string;
  onhoveraction: string;
}

export class vectorcombinator {
  type: 'vectorcombi';
  groupname: string;
  groupmember: false;
  vectors: vectoranimation[];
  animation: animationtype[];
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
  }
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  animate: boolean;
}

export class vectoranimation {
  groupmember: false;
  type: 'vector';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
    'stroke-width': string;
    stroke: string;
    'box-shadow': string;
    'filter': string;
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  svganimationpaths = [];
  animation: animationtype[];
  vectors: vectorelement[];
  vectoranimation: vectoranimationtype[];
  svgcombi: string;
  selected: boolean;
  morph: boolean;
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  animate: boolean;
  character: boolean;
}

export function factoryVectoranimation(newwidth, newheight, motionpath: Array<string>, posxset, posyset, newelnr, vectors, svgc, vectanim, newz, character) {
  // console.log(newwidth, newheight)
  let vectoranimation = {
    type: 'vector',
    groupmember: false,
    style: {
      'z-index': newz,
      width: newwidth,
      height: newheight,
      position: 'absolute',
      opacity: 1,
      'stroke-width': '',
      stroke: '',
      'box-shadow': ''
    },
    src: '',
    posx: posxset,
    posy: posyset,
    setpos: { 'x': 0, 'y': 0 },
    animation: [],
    id: newelnr,
    vectors: vectors,
    svgcombi: svgc,
    svganimationpaths: [],
    vectoranimation: vectanim,
    selected: false,
    morph: false,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    motionpath: motionpath,
    animate: true,
    character: character

  }
  return vectoranimation;
}

export class vectorelement {
  idx: string;
  src: string;
  object: string;
  duration: number;
  start_time: number;
  delay: number;
  pathids: string[];
  easetype: any;
  fromto: string;
  scale: number;
}

export function factoryVectorelement(newsrc, vectorid, pathidar) {
  let vector = {
    src: newsrc,
    idx: vectorid,
    duration: 1,
    start_time: undefined,
    delay: 0,
    pathids: pathidar,
    easetype: '',
    fromto: 'to',
    scale: 0,
    object: ''
  }
  return vector
}

export class shapeanimation {
  type: 'shape';
  groupmember: false;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    'background-color': string;
    opacity: 1;
    'border-radius': string;
    class: string;
    'box-shadow': string;
    'filter': string;
  };
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  shape: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  stripewidth: number;
  direction: number;
  color1: string;
  color2: string;
  percentagecolor1: number;
  percentagecolor2: number;
  percentagecolor3: number;
  position: string;
  rounding: number;
  roundingTL: number;
  roundingTR: number;
  roundingBL: number;
  roundingBR: number;
  coloreffect: string;
  stripedirection: number;
}

export function shapeanimationFactory(anim, newelnr, newz, motionpath: Array<string>) {
  let img: shapeanimation = {
    groupmember: false,
    type: 'shape',
    style: {
      'z-index': newz,
      width: "200px",
      height: "200px",
      position: 'absolute',
      'background-color': '#000000',
      opacity: 1,
      'border-radius': '0%',
      class: '',
      'box-shadow': '',
      'filter': ''
    },
    src: '',
    posx: 50,
    posy: 50,
    setpos: { 'x': 0, 'y': 0 },
    animation: anim,
    id: newelnr,
    shape: 'square',
    transform: '',
    rotation: 0,
    motionrotation: 0,
    stripewidth: 5,
    direction: 45,
    color1: 'white',
    color2: 'black',
    percentagecolor1: 0,
    percentagecolor2: 100,
    percentagecolor3: 0,
    position: '',
    motionpath: motionpath,
    rounding: 50,
    roundingTL: 0,
    roundingTR: 0,
    roundingBL: 0,
    roundingBR:0,
    coloreffect: '', 
    stripedirection: 45
  }
  return img;
}

export class textanimation {
  content: string;
  groupmember: false;
  type: 'text';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    'font-size': string;
    'line-height': string;
    'font-style': string;
    'font-weight': string;
    'font-family': string;
    'letter-spacing': string;
    padding: string;
    opacity: 1;
    'box-shadow': string;
    'filter': string;
    'color': string;
  }
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  splittextanimation: splittexttype[];
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
}

export function factoryTextanimation(newelnr, newz) {
  let anim: animationtype[] = [];
  let splittext: splittexttype[] = [{
    textanimationtype: '',
    repeat: 0,
    start_time: 0, //delay
    delay: 0,
    end_time: 10,
    duration: 3,
    x: 0,
    y: 100,
    fromto: 'to',
    easetype: 'bounce',
    rotationX: 0,
    rotationY: 0
  }]

  let text = {
    type: 'text',
    groupmember: false,
    style: {
      'z-index': newz,
      width: "auto",
      height: "auto",
      position: 'absolute',
      'font-size': '20px',
      'line-height': '20px',
      'font-family': 'Open Sans',
      'font-style': '',
      'font-weight': '',
      'letter-spacing': '1px',
      opacity: 1,
      padding: '15px', //neccarry to get all fonts,
      'box-shadow': '',
      'filter': '',
      'color': 'black',
      'text-align': 'left'
    },
    content: 'start writing',
    posx: 20,
    posy: 50,
    setpos: { 'x': 0, 'y': 0 },
    animation: anim,
    id: newelnr,
    splittextanimation: splittext,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    motionpath: []
  }
  return text;
}

export class formanimation {
  groupmember: false;
  formsrc: string;
  type: 'form';
  formId: '';
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    'font-size': string;
    'font-style': string;
    'font-weight': string;
    'font-family': string;
    padding: string;
    opacity: 1;
    'box-shadow': string;
    'filter': string;
    'color': string;
  }
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
}

// 
export function factoryVectorAnimationLayer(): Array<Object> {

  let vectanim = [{
    svganimationtype: [],
    drawcolor: 'blue',
    linethickness: '5px',
    repeat: 0,
    yoyo: false,
    fillright: '100%',
    fillleft: '0%',
    drawright: '0%',
    drawleft: '0%',
    start_time: 0, //delayt
    end_time: 10,
    duration: 3,
    hideimage: false,
    easetype: 'linear',
    fromto: 'to'
  }]

  return vectanim;
}

export class GlobalThreedControls {
  dragcontrol: boolean;
  dragcontrolgroup: boolean;
  rotatecontrol: boolean;
  rotatecontrolx: boolean;
  rotatecontroly: boolean;
  rotatecontrolz: boolean;
}

export function factoryGlobalThreedControls(): GlobalThreedControls {
  let globalThreedControls = {
    dragcontrol: false,
    dragcontrolgroup: false,
    rotatecontrol: false,
    rotatecontrolx: false,
    rotatecontroly: false,
    rotatecontrolz: false,
  }
  return globalThreedControls;
}

export class Canvas {
  'border-radius': string;
  direction: number;
  stripewidth: number;
  percentagecolor1: number;
  percentagecolor2: number;
  percentagecolor3: number;
  color1: string;
  color2: string;
  bgstyle: string;
  width: string;
  height: string;
  'background-color': string;
  'background-image': string;
  background: string;
  positiongradient: string;
  videourl: string;
  imageurl: string;
  loop: boolean;
  weather: string;
  audio: string;
  top: string;
  left: string;
  hovereffect: boolean;
  zoom: boolean;
  'background-image-gradient': string;
  overflow: boolean;

  grid: boolean;
  gridcolor: string;
  helper: boolean;
  shadows: boolean;
  materialtype: boolean;
  light: number;
  plane: string;
  planecolor: string;
  pretheme: string;

  light1: boolean;
  light2: boolean;
  sky: boolean;
  sunlocation: number;
  sunheight: number;
  cloud: boolean;

  camerapositionx: number;
  camerapositiony: number;
  camerapositionz: number;
  camerarotationx: number;
  camerarotationy: number;
  camerarotationz: number;
  cameracontrol: boolean;
  walkcontrol: boolean;
  planetexture: string;
}


export function factoryCanvas(): Canvas {
  let canvas = {
    'border-radius': '',
    direction: 45,
    stripewidth: 5,
    percentagecolor1: 0,
    percentagecolor2: 100,
    percentagecolor3: 0,
    color1: 'black',
    color2: 'white',
    bgstyle: '',
    width: '1024px',
    height: '576px',
    'background-color': '#ffffff',
    'background-image': '',
    background: '',
    positiongradient: 'circle at top right,',
    videourl: '',
    imageurl: '',
    loop: false,
    weather: '',
    audio: '',
    top: '',
    left: '',
    hovereffect: true,
    zoom: true,
    'background-image-gradient': '',
    overflow: false,
    grid: false,
    gridcolor: 'green',
    helper: false,
    shadows: true,
    materialtype: false,
    light: 1,
    plane: 'none',
    planecolor: 'grey',
    pretheme: '',
    camerarotationx: 0,
    camerarotationy: 0,
    camerarotationz: 0,
    camerapositionx: 100,
    camerapositiony: 30,
    camerapositionz: 100,
    cameracontrol: true,
    dragcontrol: false,
    dragcontrolgroup: false,
    walkcontrol: false,
    light1: true,
    light2: true,
    sky:false,
    sunlocation: 180,
    sunheight: 20,
    cloud: false,
    planetexture: ''
  }
  return canvas
}



export class EmbeddedVideo {
  type: 'embeddedvideo';
  groupmember: false;
  style: {
    'z-index': number,
    width: string;
    height: string;
    position: 'absolute';
    opacity: 1;
    'clip-path': string;
    'box-shadow': string;
    'filter': string;
  };
  clippath: string;
  src: string;
  posx: number;
  posy: number;
  setpos: object;
  id: string;
  animation: animationtype[];
  motionpath: string[];
  transform: string;
  motionrotation: number;
  rotation: number;
  grey: false;
  blur: false;
  blurborder: false;
  cliparray: [];
  opacity: number;
  blobsrc: string;
  'clip-path': string;
  start_time: number;
  duration: number;
  effect: string;
}

export function factoryEmbeddedVideo(anim, newelnr, motionpath: Array<string>, newz): EmbeddedVideo {
  let embeddedVideo: EmbeddedVideo = {
    type: 'embeddedvideo',
    groupmember: false,
    style: {
      'z-index': newz,
      width: "400px",
      height: "400px",
      position: 'absolute',
      opacity: 1,
      'clip-path': '',
      'box-shadow': '',
      'filter': ''
    },
    clippath: '',
    cliparray: [],
    src: '',
    posx: 0,
    posy: 0,
    setpos: { 'x': 0, 'y': 0 },
    animation: anim,
    id: newelnr,
    transform: '',
    rotation: 0,
    motionrotation: 0,
    grey: false,
    blur: false,
    blurborder: false,
    motionpath: motionpath,
    opacity: 1,
    blobsrc: '',
    'clip-path': '',
    start_time: 0,
    duration: undefined,
    effect: ''
  }

  return embeddedVideo
}