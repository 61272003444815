import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { FireLoopRef } from '../../shared/sdk'
import { Chatrooms, Chatmessages, Relations, Company, Chats, Account } from '../../shared/sdk/models';
import { ChatroomsApi, AccountApi, RelationsApi, ChatmessagesApi, ChatsApi, RealTime } from '../../shared/sdk/services';
import { DialogsService } from './../../dialogsservice/dialogs.service';
import { codesnippetService } from './../../dialogsservice/codesnippet-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fonts } from '../../shared/listsgeneral/fonts';
import { AppserviceService } from 'src/app/appservice.service';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {
  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;

  public limitresult: 10;
  public numbers = [
    { value: '1', viewValue: '1' },
    { value: '20', viewValue: '20' },
    { value: '30', viewValue: '30' }
  ];

  public newmessage: string;
  public Chats: Chats[];
  public selectedChat: Chats;
  public Chatrooms: Chatrooms[];
  public listviewxsshow = false;

  constructor(
    public codesnippetService: codesnippetService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogsService: DialogsService,
    //  private realTime: RealTime,
    private ChatsApi: ChatsApi,
    private RelationsApi: RelationsApi,
    //  private accountApi: AccountApi,
    private ChatroomsApi: ChatroomsApi,
     private ChatmessagesApi: ChatmessagesApi
  ) {


  }

  ngOnInit(): void {
  }


  public saveChat(): void {
    this.RelationsApi.updateByIdChats(this.option.id, this.selectedChat.id, this.selectedChat).subscribe(res => {
      console.log(res)
    });
    // if (this.selectedChatroom) {
    //   this.saveRoom();
    // }
  }

  public saveRoom(): void {
    // this.ChatsApi.updateByIdChat(this.option.id, this.selectedChatroom.id, this.selectedChatroom).subscribe();
    this.RelationsApi.updateByIdChats(this.option.id, this.selectedChat.id).subscribe();
  }

  createChat() {
    let chats = new Chats();
    chats.name = 'new';
    chats.owners = [this.Account.id];
    this.RelationsApi.createChats(this.option.id, chats).subscribe(chat => {
      this.getChats();
      this.onSelectChat(chat);
    });
  }

  createChatroom() {
    let chatroom = new Chatrooms();
    chatroom.name = 'New';
    chatroom.date = new Date();
    this.ChatsApi.createChatroom(this.selectedChat.id, chatroom).subscribe(chatroom => {
      this.getChatrooms();
      this.onSelectChatroom(chatroom);
    });
  }

  getChats() {
    this.RelationsApi.getChats(this.option.id).subscribe((chats: Chats[]) => {
      this.Chats = chats;
    });
  }

  onSelectChat(chat) {
    this.selectedChat = chat;
    this.getChatrooms();
  }

  getChatrooms() {
    this.ChatsApi.getChatroom(this.selectedChat.id).subscribe((rooms: Chatrooms[]) => {
      this.Chatrooms = rooms;
    });
  }

  onSelectChatroom(room: Chatrooms) {
    // clean messageref if exists
    this.openChatDialog(room);
  }

  deleteRoom(room) {
    this.ChatroomsApi.deleteById(room.id).subscribe((res) => {
      this.openSnackBar('Room deleted');
      this.getChatrooms();
    });


  }


  setAvatarImage(event) {
    //console.log(event)
    setTimeout(() => {
      this.selectedChat.avatar = event;
    }, 500);
  }

  setstartIconImage(event) {
    //console.log(event)
    setTimeout(() => {
      this.selectedChat.startIcon = event;
    }, 500);
  }


  codesnippetExamp() {
    // !! do not use code as is
    let co;
    let link = "https://chat.enydea.com?id=${this.selectedChat.id}?origin=" + window.location.href;
    let rurl = "https://chat.enydea.com/api/Chats/${this.selectedChat.id}";
    var match = document.cookie.match(new RegExp('(^| )' + 'enydeachatidroom' + '=([^;]+)'));
    if (match) { link = link + '?roomid=' + match[2] };


    hget(rurl, function (b) {
      co = JSON.parse(b);
      console.log(co)
      let img = document.createElement('img');
      img.src = co.startIcon;
      img.style.cssText = "bottom: 20px; right: 20px; position: fixed; z-index: 99; width:80px; height:80px";

      document.body.appendChild(img);
      img.addEventListener('click', function () {
        let Iframe = document.createElement('iframe');
        Iframe.onload = function () {
          window.addEventListener("message", msgX, false);
        };
        Iframe.src = link;
        Iframe.setAttribute('id', 'enydeaChat');
        Iframe.style.cssText = "border:0; bottom: 0px; right: 0px; position: fixed; z-index: 99; " +
          "scrolling=no frameborder=0 allowfullscreen;" +
          "width:" + (co.width + 10) + co.wform + "; height:" + (co.height + 10) + co.hform + ";";
        document.body.appendChild(Iframe);

        let imgcl = document.createElement('img');
        imgcl.setAttribute('id', 'xBbutton')
        imgcl.src = 'https://app.enydea.com/api/Containers/standardiconcolourcircle/download/cross.png';
        imgcl.style.cssText = "bottom: " + (co.height - 5) + co.hform + "; right: 10px; position: fixed; z-index: 99; width:40px; height:40px";
        document.body.appendChild(imgcl);
        imgcl.addEventListener('click', function () {
          var element = document.getElementById('enydeaChat');
          element.parentNode.removeChild(element);
          var element = document.getElementById('xBbutton');
          element.parentNode.removeChild(element);
        });
      });
    });

    function msgX(e) {
      if (e.origin !== window.location.href) { return; }
      document.cookie = "enydeachatidroom=" + e.data;
    }

    function hget(u, cb) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
          cb(xmlHttp.responseText);
      }
      xmlHttp.open("GET", u, true); // true for asynchronous 
      xmlHttp.send(null);
    }


  }

  createCode() {
    // load script open loopback api point 
    // remote method
    // load chat and set specific css
    // html 

    // start chat --> create chatRoom and invite available person
    // after timeout show form

    let scriptlocation = 'https://app.enydea.com/api/Containers/standardjs/download/chat-enydea.js';
    let csslocation = 'https://app.enydea.com/api/Containers/standardcss/download/chat-enydea.css';

    let newcode = `<script type='text/javascript'>window.enydeachatid="${this.selectedChat.id}"</script>
    <script type="text/javascript" src="${scriptlocation}"></script>
    <link rel = "stylesheet" type = "text/css" href = "${csslocation}" />`

    // let code = `<script type='text/javascript'>let co,link="https://chat.enydea.com?id=${this.selectedChat.id}?origin="+window.location.href,rurl="https://chat.enydea.com/api/Chats/${this.selectedChat.id}";var match=document.cookie.match(new RegExp("(^| )enydeachatidroom=([^;]+)"));function msgX(t){t.origin===window.location.href&&(document.cookie="enydeachatidroom="+t.data)}function hget(t,e){var o=new XMLHttpRequest;o.onreadystatechange=function(){4==o.readyState&&200==o.status&&e(o.responseText)},o.open("GET",t,!0),o.send(null)}match&&(link=link+"?roomid="+match[2]),hget(rurl,function(t){co=JSON.parse(t),console.log(co);let e=document.createElement("img");e.src=co.startIcon,e.style.cssText="bottom: 20px; right: 20px; position: fixed; z-index: 99; width:80px; height:80px",document.body.appendChild(e),e.addEventListener("click",function(){let t=document.createElement("iframe");t.onload=function(){window.addEventListener("message",msgX,!1)},t.src=link,t.setAttribute("id","enydeaChat"),t.style.cssText="border:0; bottom: 0px; right: 0px; position: fixed; z-index: 99; scrolling=no frameborder=0 allowfullscreen;width:"+(co.width+10)+co.wform+"; height:"+(co.height+10)+co.hform+";",document.body.appendChild(t);let e=document.createElement("img");e.setAttribute("id","xBbutton"),e.src="https://app.enydea.com/api/Containers/standardiconcolourcircle/download/cross.png",e.style.cssText="bottom: "+(co.height-5)+co.hform+"; right: 10px; position: fixed; z-index: 99; width:40px; height:40px",document.body.appendChild(e),e.addEventListener("click",function(){var t;(t=document.getElementById("enydeaChat")).parentNode.removeChild(t),(t=document.getElementById("xBbutton"))
    // .parentNode.removeChild(t)})})});</script>`


    this.codesnippetService.confirm('Copy Code', 'Copy code and put in your website header', newcode).subscribe()
  }

  // `<script type='text/javascript'>
  // window.purechatApi = { l: [], t: [], on: 
  //   function () { this.l.push(arguments); } }; (function () 
  //   { var done = false; var script = document.createElement('script'); 
  //   script.async = true; script.type = 'text/javascript'; 
  //   script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; 
  //   document.getElementsByTagName('HEAD').item(0).appendChild(script); 
  //   script.onreadystatechange = script.onload = function (e) { 
  //     if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { 
  //       var w = new PCWidget({c: '7bae897f-3982-49f4-818c-577af5c233ac', f: true }); done = true; } }; })();
  //       </script>`

  openDialogDelete() {
    this.dialogsService
      .confirm('Delete Chat', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          this.deleteChat();
        }
      });
  }

  deleteChat() {
    this.RelationsApi.destroyByIdChats(this.SelectedRelation.id, this.selectedChat.id).subscribe(res => {
      this.getChats();
      this.selectedChat = undefined;
      this.openSnackBar('Chat deleted')
    });
  }

  getandopenChatDialog(roomid, chatid, relationsid) {
    this.RelationsApi.findByIdChats(relationsid, chatid).subscribe((chat: Chats) => {
      this.selectedChat = chat;
      this.ChatroomsApi.findById(roomid).subscribe(room => {
        this.openChatDialog(room)
      })
    })

  }


  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: 'snackbar-class'
    });
  }

  searchChats(value) {

  }

  openChatDialog(room) {
    this.dialog.open(chatDialog, {
      data: {
        account: this.Account,
        room: room,
        chat: this.selectedChat
      }
    });
  }


  swiperight(e?) {
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }

}

@Component({
  selector: 'chat-dialog',
  templateUrl: 'chat-dialog.html',
  styleUrls: ['chat-dialog.scss']
})



export class chatDialog implements OnDestroy {

  private room: Chatrooms = new Chatrooms();
  public message: Chatmessages = new Chatmessages();
  public showemoji = false;
  public showemojibutton = false;
  public RoomReference: FireLoopRef<Chatrooms>;
  public MessageReference: FireLoopRef<Chatmessages>;
  public Account;
  public selectedChatroom: Chatrooms;
  public Chatmessages: Chatmessages[];
  public selectedChat: Chats;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public appservice: AppserviceService,
    private ChatroomsApi: ChatroomsApi,
    ) {
    
    this.Account = data.account;
    this.selectedChat = data.chat;
    this.RoomReference = this.appservice.realTime.FireLoop.ref<Chatrooms>(Chatrooms);
    this.onSelectChatroom(data.room);
  }


  setemoji(event) {
    const bufStr = String.fromCodePoint(parseInt(event.emoji.unified, 16));
    if (this.message.message === undefined) {
      this.message.message = event.emoji.native;
    } else {
      this.message.message = this.message.message + event.emoji.native;
    }
    this.onshowemoji();
  }

  onshowemoji(selected?) {
    if (this.showemoji) { this.showemoji = false } else {
      this.showemoji = true;
    }
  }

  // Built in logged account functionality
  sendMessage(): void {
    this.message.accountId = this.Account.id;
    this.MessageReference.upsert(this.message)
      .subscribe(() => {this.message = new Chatmessages()
      
        document.getElementById('chatbox').scrollTo(0, document.body.scrollHeight);
      }
      );
  }


  // onSelectChatroom(room: Chatrooms) {
  //   // clean messageref if exists
  //   if (this.MessageReference) {
  //     this.MessageReference.dispose();
  //   }


  //   this.selectedChatroom = room;
  //   this.MessageReference = this.RoomReference.make(room).child<Chatmessages>('chatmessages');

  //   this.MessageReference.stats().subscribe(res => {console.log(res)})

  //   this.MessageReference.on('value', {
  //     limit: 100,
  //     order: 'id ASC',
  //     //include: 'members'
  //   }).subscribe((messages: Chatmessages[]) => { this.Chatmessages = messages, console.log(messages) });

  // }

  getMessages(){
    this.ChatroomsApi.getChatmessages(this.selectedChatroom.id).subscribe((messages: Chatmessages[]) => { 
      //console.log(messages)
      this.Chatmessages = messages;
      
      document.getElementById('chatbox').scrollTo(0, document.body.scrollHeight);
    });

  }

  onSelectChatroom(room: Chatrooms) {
    // clean messageref if exists
    console.log(room)
    if (this.MessageReference) {
      this.MessageReference.dispose();
    }
    this.selectedChatroom = room;
    this.MessageReference = this.RoomReference.make(room).child<Chatmessages>('chatmessages');
    console.log(this.MessageReference)
    this.MessageReference.on('value', {
      limit: 100,
      order: 'id ASC',
      //include: 'members'
    }).subscribe((messages: Chatmessages[]) => { 
      console.log(messages)
      this.Chatmessages = messages;
      this.getMessages();
     // document.getElementById('chatconversation').scrollTo(0, document.body.scrollHeight);
    });

  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.MessageReference) {
      this.MessageReference.dispose();
      this.RoomReference.dispose();
    }

    if (this.RoomReference) {
      this.RoomReference.dispose();
    }
  }


}
