import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpeeddialService {

  public speedDialFabButtons = [
    {
      icon: 'hourglass_full',
      tooltip: $localize`Add new counter`,
      type: 'counter'
    },
    {
      icon: 'image',
      tooltip: $localize`Add new image`,
      type: 'image'
    },
    {
      icon: 'text_format',
      tooltip: $localize`Add new text`,
      type: 'text'
    },
    {
      icon: 'square_foot',
      tooltip: $localize`Add new shape`,
      type: 'shape'
    },
    {
      icon: 'polyline',
      tooltip: $localize`Add animated image`,
      type: 'animatedimage'
    },
    {
      icon: 'edit',
      tooltip: $localize`Add drawing`,
      type: 'draw'
    },
    {
      icon: 'show_chart',
      tooltip: $localize`Add chart`,
      type: 'chart'
    },
    // {
    //   icon: 'collections',
    //   tooltip: 'Add animation group'
    // },
    {
      icon: 'call_to_action',
      tooltip: $localize`Add form`,
      type: 'form'
    },
    {
      icon: 'face_6',
      tooltip: $localize`Add character`,
      type: 'character'
    },
    {
      icon: '3d_rotation',
      tooltip: $localize`Add 3D model`,
      type: '3d'
    },
    {
      icon: 'video_library',
      tooltip: $localize`Add embedded video`,
      type: 'video'
    }
   
  ];
  
  constructor() { }

  
}
