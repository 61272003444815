
import { BASE_URL } from '../../shared/base.api';

export const StockVectors = [
    BASE_URL + '/api/Containers/standardvectors/download/Comment_1.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Comment.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Dislike.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Eye.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Image.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Like.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Love.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Megaphone.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Message.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Microphone.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Notification.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Repost.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Search.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Share.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Video.svg',
    BASE_URL + '/api/Containers/standardvectors/download/electronic-device-mockup.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk1.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk2.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk4.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Abacus.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Art.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Backpack.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Calculator.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Diploma.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Geography.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk3.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Graduation.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Mathematics.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Monitor.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Notebook.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk6.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Pencil.svg',
    BASE_URL + '/api/Containers/standardvectors/download/School.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SchoolBook.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk5.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SchoolBus.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Schoolgirl.svg',
    BASE_URL + '/api/Containers/standardvectors/download/desk_desk7.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/dodo_bird.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/gloomy_bat.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/glyptodon.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/golden_toad.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/great_auk.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/haasts_eagle.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/irish_elk.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/lesser_bilby.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/mammoth.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/pyrenean_ibex.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/quagga.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/saber-toothed_cat.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/sea_monk.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/shrub-ox.svg',
    // BASE_URL + '/api/Containers/standardvectors/download/tasmanian_tiger.svg',
    BASE_URL + '/api/Containers/standardvectors/download/champagne.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-balls.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-candle.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-candy.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-leaf.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-sock.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-tree-ice.svg',
    BASE_URL + '/api/Containers/standardvectors/download/christmas-tree.svg',
    BASE_URL + '/api/Containers/standardvectors/download/iceman-tree.svg',
    BASE_URL + '/api/Containers/standardvectors/download/iceman.svg',
    BASE_URL + '/api/Containers/standardvectors/download/missletoe.svg',
    BASE_URL + '/api/Containers/standardvectors/download/santa-christmas.svg',
    BASE_URL + '/api/Containers/standardvectors/download/AIR-CONDITIONER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/ALARM-CLOCK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BACKPACK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BATHROOM.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BED.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BIKE.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BIKINI.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BLUSH.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BREAKFAST.svg',
    BASE_URL + '/api/Containers/standardvectors/download/BROOM.svg',
    BASE_URL + '/api/Containers/standardvectors/download/CAP.svg',
    BASE_URL + '/api/Containers/standardvectors/download/CHECK-LIST.svg',
    BASE_URL + '/api/Containers/standardvectors/download/CLOCK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/COFFEE-GRINDER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/COFFEE.svg',
    BASE_URL + '/api/Containers/standardvectors/download/CUTLERY.svg',
    BASE_URL + '/api/Containers/standardvectors/download/DOG-FOOD.svg',
    BASE_URL + '/api/Containers/standardvectors/download/GAME-CONTROLLER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/GLASSES.svg',
    BASE_URL + '/api/Containers/standardvectors/download/HAIR-COMB.svg',
    BASE_URL + '/api/Containers/standardvectors/download/HAND-MIRROR.svg',
    BASE_URL + '/api/Containers/standardvectors/download/HEADPHONES.svg',
    BASE_URL + '/api/Containers/standardvectors/download/JUICER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/KEY.svg',
    BASE_URL + '/api/Containers/standardvectors/download/LAMP-DESK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/LIPSTICK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/MAIL-BOX.svg',
    BASE_URL + '/api/Containers/standardvectors/download/MAIL.svg',
    BASE_URL + '/api/Containers/standardvectors/download/MEAT.svg',
    BASE_URL + '/api/Containers/standardvectors/download/NEWSPAPER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/NOTEBOOK.svg',
    BASE_URL + '/api/Containers/standardvectors/download/PHONE-CALL.svg',
    BASE_URL + '/api/Containers/standardvectors/download/PLANT-POT.svg',
    BASE_URL + '/api/Containers/standardvectors/download/RAZOR.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SANITARY-PAD.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SHOES.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SHOPPING-BAG.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SOCKS.svg',
    BASE_URL + '/api/Containers/standardvectors/download/SODA.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TELEPHONE.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TOASTER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TOILET-PAPER.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TOOTH-BRUSH.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TOWELS.svg',
    BASE_URL + '/api/Containers/standardvectors/download/TSHIRT.svg',
    BASE_URL + '/api/Containers/standardvectors/download/UMBRELLA.svg',
    BASE_URL + '/api/Containers/standardvectors/download/UNDERWEAR.svg',
    BASE_URL + '/api/Containers/standardvectors/download/VITAMIN.svg',
    BASE_URL + '/api/Containers/standardvectors/download/WALLET.svg',
    BASE_URL + '/api/Containers/standardvectors/download/WRISTWATCH.svg',
    BASE_URL + '/api/Containers/standardvectors/download/dinner-set.svg',
    BASE_URL + '/api/Containers/standardvectors/download/airplane.svg',
    BASE_URL + '/api/Containers/standardvectors/download/alarmclock.svg',
    BASE_URL + '/api/Containers/standardvectors/download/axe.svg',
    BASE_URL + '/api/Containers/standardvectors/download/backpack.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beach-bag.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beach-bar.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beach-chair-towel.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beach-chair-umbrella.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beach.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beachball.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beanie-google.svg',
    BASE_URL + '/api/Containers/standardvectors/download/beanie.svg',
    BASE_URL + '/api/Containers/standardvectors/download/bed.svg',
    BASE_URL + '/api/Containers/standardvectors/download/bikini.svg',
    BASE_URL + '/api/Containers/standardvectors/download/binocular.svg',
    BASE_URL + '/api/Containers/standardvectors/download/boot.svg',
    BASE_URL + '/api/Containers/standardvectors/download/bucket-shovel.svg',
    BASE_URL + '/api/Containers/standardvectors/download/bus.svg',
    BASE_URL + '/api/Containers/standardvectors/download/calendar.svg',
    BASE_URL + '/api/Containers/standardvectors/download/calendarsingle.svg',
    BASE_URL + '/api/Containers/standardvectors/download/camera.svg',
    BASE_URL + '/api/Containers/standardvectors/download/campfire.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Christ-the-Redeemer.svg',
    BASE_URL + '/api/Containers/standardvectors/download/cocktail.svg',
    BASE_URL + '/api/Containers/standardvectors/download/coconut-drink.svg',
    BASE_URL + '/api/Containers/standardvectors/download/coconut-tree.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Colloseum.svg',
    BASE_URL + '/api/Containers/standardvectors/download/compass.svg',
    BASE_URL + '/api/Containers/standardvectors/download/creditcard.svg',
    BASE_URL + '/api/Containers/standardvectors/download/donotdisturb.svg',
    BASE_URL + '/api/Containers/standardvectors/download/door.svg',
    BASE_URL + '/api/Containers/standardvectors/download/dualtimewatch.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Eiffel-Tower.svg',
    BASE_URL + '/api/Containers/standardvectors/download/flashlight.svg',
    BASE_URL + '/api/Containers/standardvectors/download/flipflop.svg',
    BASE_URL + '/api/Containers/standardvectors/download/foodservice.svg',
    BASE_URL + '/api/Containers/standardvectors/download/globe.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Golden-Gate-Bridge.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Greatwall.svg',
    BASE_URL + '/api/Containers/standardvectors/download/hammock.svg',
    BASE_URL + '/api/Containers/standardvectors/download/hangar.svg',
    BASE_URL + '/api/Containers/standardvectors/download/hotairballoon.svg',
    BASE_URL + '/api/Containers/standardvectors/download/hotel.svg',
    BASE_URL + '/api/Containers/standardvectors/download/hotelbell.svg',
    BASE_URL + '/api/Containers/standardvectors/download/jetski.svg',
    BASE_URL + '/api/Containers/standardvectors/download/key.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lantern.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lifeguard-female.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lifeguard-male.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lifeguard.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lift.svg',
    BASE_URL + '/api/Containers/standardvectors/download/lighthouse.svg',
    BASE_URL + '/api/Containers/standardvectors/download/luggage.svg',
    BASE_URL + '/api/Containers/standardvectors/download/map.svg',
    BASE_URL + '/api/Containers/standardvectors/download/money.svg',
    BASE_URL + '/api/Containers/standardvectors/download/MountFuji.svg',
    BASE_URL + '/api/Containers/standardvectors/download/mug.svg',
    BASE_URL + '/api/Containers/standardvectors/download/palm-tree.svg',
    BASE_URL + '/api/Containers/standardvectors/download/passport.svg',
    BASE_URL + '/api/Containers/standardvectors/download/phone.svg',
    BASE_URL + '/api/Containers/standardvectors/download/plane-around-the-globeplane-around-the-globe.svg',
    BASE_URL + '/api/Containers/standardvectors/download/polaroid.svg',
    BASE_URL + '/api/Containers/standardvectors/download/pool.svg',
    BASE_URL + '/api/Containers/standardvectors/download/postcard.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Pyramid.svg',
    BASE_URL + '/api/Containers/standardvectors/download/reindeer.svg',
    BASE_URL + '/api/Containers/standardvectors/download/roadsign.svg',
    BASE_URL + '/api/Containers/standardvectors/download/rope.svg',
    BASE_URL + '/api/Containers/standardvectors/download/rv.svg',
    BASE_URL + '/api/Containers/standardvectors/download/sandcastle.svg',
    BASE_URL + '/api/Containers/standardvectors/download/sea-wave.svg',
    BASE_URL + '/api/Containers/standardvectors/download/shark-warning.svg',
    BASE_URL + '/api/Containers/standardvectors/download/shoppingbag.svg',
    BASE_URL + '/api/Containers/standardvectors/download/ski.svg',
    BASE_URL + '/api/Containers/standardvectors/download/skiing.svg',
    BASE_URL + '/api/Containers/standardvectors/download/snow-mountain.svg',
    BASE_URL + '/api/Containers/standardvectors/download/snowboard.svg',
    BASE_URL + '/api/Containers/standardvectors/download/snowboarding.svg',
    BASE_URL + '/api/Containers/standardvectors/download/snowhat-with-glasses.svg',
    BASE_URL + '/api/Containers/standardvectors/download/snowman.svg',
    BASE_URL + '/api/Containers/standardvectors/download/starfish.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Statue-of-Liberty.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Stonehenge.svg',
    BASE_URL + '/api/Containers/standardvectors/download/suitcase.svg',
    BASE_URL + '/api/Containers/standardvectors/download/suncream.svg',
    BASE_URL + '/api/Containers/standardvectors/download/sunglasses.svg',
    BASE_URL + '/api/Containers/standardvectors/download/sunhat.svg',
    BASE_URL + '/api/Containers/standardvectors/download/surfboard.svg',
    BASE_URL + '/api/Containers/standardvectors/download/swimming-trunks.svg',
    BASE_URL + '/api/Containers/standardvectors/download/swissknife.svg',
    BASE_URL + '/api/Containers/standardvectors/download/Taj-Mahal.svg',
    BASE_URL + '/api/Containers/standardvectors/download/taxi.svg',
    BASE_URL + '/api/Containers/standardvectors/download/tent.svg',
    BASE_URL + '/api/Containers/standardvectors/download/ticket.svg',
    BASE_URL + '/api/Containers/standardvectors/download/towel.svg',
    BASE_URL + '/api/Containers/standardvectors/download/train.svg',
    BASE_URL + '/api/Containers/standardvectors/download/wifi.svg',
    BASE_URL + '/api/Containers/standardvectors/download/winter-tree.svg',
    BASE_URL + '/api/Containers/standardvectors/download/yatch.svg',

]

export const BubbleVectors = [
    BASE_URL + '/api/Containers/bubblevectors/download/bubble1.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble3.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble4.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble5.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble6.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble7.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble8.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble9.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble10.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble11.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble12.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble13.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble14.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble15.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble16.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble17.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble18.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble20.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble21.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble22.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble23.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble24.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble25.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble26.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble27.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble28.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble29.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble30.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble31.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble32.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble33.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble34.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble35.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble36.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble37.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble38.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble39.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble40.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble41.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble42.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble43.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble44.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble45.svg',
    BASE_URL + '/api/Containers/bubblevectors/download/bubble46.svg',
];