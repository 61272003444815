import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account, Company, Relations } from 'src/app/shared/sdk';
import { Canvas } from '../../videocreator.model';
import { GeneralsettingsComponent } from '../generalsettings/generalsettings.component';


export interface context {
  account: Account,
  canvas: Canvas,
  option: Relations,
  videourl: string,
  company: Company,
}

@Component({
  selector: 'app-backgroundsettings',
  templateUrl: './backgroundsettings.component.html',
  styleUrls: ['./backgroundsettings.component.scss']
})
export class BackgroundsettingsComponent implements OnInit {
  option: Relations;
  canvas: Canvas;
  Account: Account;
  videourl: string;
  company: Company



  constructor(
    public http: HttpClient,
    public dialogRef: MatDialogRef<GeneralsettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public context: context
  ) { 
    this.canvas = context.canvas;
   // this.videourl = context.videourl;
    this.company = context.company;
    this.Account = context.account;
    this.option = context.option;
  }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(){

    if (this.canvas.weather === 'stars'){
      this.canvas['background-color'] = undefined;
    }

    this.context.account = this.Account;
    this.context.canvas = this.canvas;
    this.context.option = this.option;
   // this.context.videourl = this.videourl;
    this.dialogRef.close(this.context);
  }


  setVideoBG(event) {
     console.log(event);
    // this.videourl = undefined;

    this.context.canvas.videourl = event;
    //this.onchangecanvas();
  }

  setAudio(event, animation) {
    //console.log('audio file', event, animation)
    animation.audioeffectsrc = event;
    // delete this.onchangeaudio();
  }

  setAudioCanvas(event) {
    //console.log('audio canvas file', event)
    this.canvas.audio = event;
  }

  setBackground(event) {
    this.canvas['background-image'] = 'url(' + event + ')';
    this.canvas.imageurl = event;
  }

  deleteBackgroundImage(){
    this.canvas['background-image'] = undefined;
    this.canvas.imageurl = undefined;
  }

  

}
