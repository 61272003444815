<div fxLayout="column">
    <div fxFlex="200px" width="800px" fxLayout="column" style="overflow: scroll">


        <mat-button-toggle-group #group="matButtonToggleGroup" style="width: 100%" fxFlexAlign="start"
            [vertical]="false">
            <mat-button-toggle style="width: 50%" matTooltip="Male" value="male" (click)="selected = undefined">
                <mat-icon>male</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle style="width: 50%" matTooltip="Female" value="female" (click)="selected = undefined">
                <mat-icon>female</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="group.value == 'male'" fxLayout="row">
            <div *ngFor="let vector of male; let i = index" style="width: 200px; height: 100px; margin-bottom: 30px;">
                <div [inlineSVG]="vector.url" (click)="selectedvector(vector.url)" id="vectorid"
                    *ngIf="vector.url !== selected && !hiderow" style="width: 100%; height: 100%;">
                </div>
                <div *ngIf="vector.url !== selected && hiderow"
                    style="width: 100%; height: 100%; background-color: var(--primary-color);"></div>
            </div>
        </div>

        <div *ngIf="group.value == 'female'" fxLayout="row">
            <div *ngFor="let vector of female; let i = index" style="width: 200px; height: 100px; margin-bottom: 30px;">
                <div [inlineSVG]="vector.url" (click)="selectedvector(vector.url)" id="vectorid"
                    *ngIf="vector.url !== selected && !hiderow" style="width: 100%; height: 100%;">
                </div>
                <div *ngIf="vector.url !== selected && hiderow"
                    style="width: 100%; height: 100%; background-color: var(--primary-color);"></div>
            </div>
        </div>
    </div>
    <div fxFlex="450px" style="width: 600px; min-height: 400px; margin-top: 20px;">
        <div fxLayout="row">
            <div fxFlex="70%">
                <div *ngIf="!selected" style="min-height: 400px;">
                </div>
                <div *ngIf="selected && !svgcombi" [inlineSVG]="selected" [onSVGLoaded]="handleSVG" id="characterset"
                    style="height: 400px">
                </div>
                <div *ngIf="selected && svgcombi" [innerHTML]="svgcombi | safeHtml" id="characterset"
                    style="height: 400px"></div>
            </div>
            <div fxFlex="30%" *ngIf="group.value">
                <div *ngIf="group.value == 'male'">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-select (selectionChange)="changeHead()" placeholder="hairstyle"
                            [(ngModel)]="selectedHairstyle" name="hair">
                            <mat-option *ngFor="let hair of malehair" [value]="hair" style="height: 100px;">
                                <div [inlineSVG]="hair.url" [onSVGLoaded]="handleSVGSmall" [id]="hair.name"></div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="group.value == 'female'">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-select (selectionChange)="changeHead()" placeholder="hairstyle"
                            [(ngModel)]="selectedHairstyle" name="hair">
                            <mat-option *ngFor="let hair of femalehair" [value]="hair" style="height: 100px;">
                                <div [inlineSVG]="hair.url" [onSVGLoaded]="handleSVGSmall" [id]="hair.name"></div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline" style="width: 100%">
                    <mat-select (selectionChange)="changeSkinColor()" placeholder="skincolor"
                        [(ngModel)]="selectedskincolor" name="color">
                        <mat-option *ngFor="let color of skincolors" [value]="color">
                            <div [style.background-color]="color.hex"></div>
                            <label>{{color.name}}</label>
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center end" style="margin-top: 10px">
            <button mat-raised-button (click)="import()">import</button>
            <button mat-raised-button (click)="cancel()">cancel</button>
        </div>

    </div>
</div>