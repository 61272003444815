<div fxLayout="row" fxFlexOffset="10px" class="bottomlist">





  <div class="card-left-menu" fxLayout="column" ngClass.gt-xs="listview-normal" style="width: 100%;"
    ngClass.lt-sm="listview-noshow" [class.listview-xs-show]="listviewxsshow">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="searchboxCampaign" (keyup.enter)="searchGoCampaign()" placeholder="Search"
        name="seachbox" />
    </mat-form-field>
    <button a mat-raised-button color="primary" (click)="searchGoCampaign()">
      <mat-icon>search</mat-icon>
      <label i18n>Search</label>
    </button>

    <button a mat-raised-button (click)="getMailingCampaign()">
      Show All
    </button>
    <div class="listview" fxLayout="column">

      <div *ngFor="let Marketingplannerevents of Marketingplannerevents"
        (click)="onSelectMarketingplannerevents(Marketingplannerevents)">
        <div class="listbox" [class.selected]="Marketingplannerevents === selectedMarketingplannerevents">
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="50px">
              <mat-icon *ngIf="Marketingplannerevents.countsend > 0" class="iconsize-15"
                matBadge="{{ Marketingplannerevents.countsend }}" matBadgeColor="primary" matBadgeSize="small"
                matBadgePosition="below after">mail</mat-icon>
              &nbsp;
              <mat-icon *ngIf="Marketingplannerevents.countsend === 0" class="iconsize-15">mail</mat-icon>
              &nbsp;
              <mat-icon *ngIf="Marketingplannerevents.countnotsend > 0" class="iconsize-15"
                matBadge="{{ Marketingplannerevents.countnotsend }}" matBadgeColor="primary" matBadgeSize="small"
                matBadgePosition="below after">markunread_mailbox</mat-icon>
              <mat-icon class="iconsize-15" *ngIf="Marketingplannerevents.countnotsend === 0">
                markunread_mailbox</mat-icon>
            </div>
            <div fxFlex style="font-size: 8pt;">
              {{ Marketingplannerevents.name | slice: 0:26 }}
            </div>
          </div>
        </div>
      </div>

      <button mat-fab color="primary" i18n-matTooltip matTooltip="Add Campaign"
        (click)="createMarketingPlannerevents()">
        <mat-icon>add</mat-icon>
      </button>
    </div>


  </div>
</div>

<!-- ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow"  -->
<div *ngIf="selectedMarketingplannerevents" [class.container-xs-show]="listviewxsshow" class="mailingcampaign"
  (blur)="saveMailingCampaign()">
  <div class="form-full-width" style="width: 800px;">
    <div class="titlebox" fxLayoutAlign="space-between center">
      <h2>{{ selectedMarketingplannerevents.name | uppercase | slice: 0:30 }}</h2>

      <button mat-icon-button [matMenuTriggerFor]="menucampaign">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menucampaign="matMenu">
        <button mat-menu-item (click)="openDialogDeleteMailingCampaign()">
          <mat-icon>delete</mat-icon>
          <label i18n>Delete</label>
        </button>
        <button mat-menu-item (click)="saveMailingCampaign()">
          <mat-icon>save</mat-icon>
          <label i18n>Save</label>
        </button>
      </mat-menu>
    </div>
    <br>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
      <mat-form-field appearance="outline" class="form-full-width">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="selectedMarketingplannerevents.name" placeholder="Name" name="name"
          class="InputField" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-full-width">
        <mat-label>Notes</mat-label>
        <textarea matInput matTextareaAutosize="true" matAutosizeMinRows="6" name="notes"
          [(ngModel)]="selectedMarketingplannerevents.notes" placeholder="Notes" class="InputField"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

      <!-- [(mailingaddresscampaign)] -->
      <div fxFlex="50%">
        <mat-form-field fxFlex appearance="outline">
          <mat-label i18n>Mailing list</mat-label>
          <input matInput placeholder="Type and Select" [matChipInputFor]="chipList" ngDefaultControl
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
            [matAutocomplete]="maillists" [formControl]="addItems" #chipInput name="mailinglist" />
          <mat-autocomplete #maillists="matAutocomplete" (optionSelected)="onAddItems($event)">
            <mat-option *ngFor="let mailingaddresscampaign of filteredItems | async" [value]="mailingaddresscampaign">
              <div>{{ mailingaddresscampaign.listname }} |
                {{ mailingaddresscampaign.industry }}</div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-chip-list #chipList fxFlex="50%">
        <mat-chip color="primary" *ngFor="let item of selectedItems; let i = index" ngDefaultControl>
          {{ item }}
          <mat-icon matChipRemove (click)="onRemoveItems(item, i)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>



    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
      <!-- <mat-form-field appearance="outline" style="width: 200px">
                <mat-label i18n>Send to</mat-label>
                <mat-select placeholder="select" [(ngModel)]="filtermailingselect"
                    (selectionChange)="getMailingCampaignMailings()">
                    <mat-option *ngFor="let filtermailingselect of filtermailing" [value]="filtermailingselect">
                        {{ filtermailingselect }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

    </div>
  </div>
  <br />
  <div *ngIf="mails">
    <div *ngFor="let mail of mails[0]; let i = index;" class="mailingcontainer">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

        <app-mailtemplate [CampaignMailing]="mail" (EditEvent)="editMailing($event)"
          (DeleteEvent)="openDialogDeleteMailingof($event)"></app-mailtemplate>
        <mat-icon i18n-matTooltip matTooltip="add Follow up Mail" class="addfollowupmail"
          (click)="createFollowUpMail(mail)" *ngIf="mails[1][i] === undefined">add</mat-icon>

        <mat-icon *ngIf="mails[1][i] !== undefined">arrow_right_alt</mat-icon>

        <app-mailtemplate *ngIf="mails[1][i]" [CampaignMailing]="mails[1][i]" (EditEvent)="editMailing($event)"
          (DeleteEvent)="openDialogDeleteMailingof($event)"></app-mailtemplate>
        <mat-icon i18n-matTooltip matTooltip="add Follow up Mail" class="addfollowupmail"
          (click)="createFollowUpMail(mails[1][i])"
          *ngIf="mails[2][i] === undefined && mails[1][i] !== undefined">add</mat-icon>

        <mat-icon *ngIf="mails[2][i] !== undefined">arrow_right_alt</mat-icon>

        <app-mailtemplate *ngIf="mails[2][i]" [CampaignMailing]="mails[2][i]" (EditEvent)="editMailing($event)"
          (DeleteEvent)="openDialogDeleteMailingof($event)"></app-mailtemplate>
        <mat-icon i18n-matTooltip matTooltip="add Follow up Mail" class="addfollowupmail"
          (click)="createFollowUpMail(mails[2][i])"
          *ngIf="mails[3][i] === undefined && mails[2][i] !== undefined">add</mat-icon>

        <mat-icon *ngIf="mails[3][i] !== undefined">arrow_right_alt</mat-icon>

        <app-mailtemplate *ngIf="mails[3][i]" [CampaignMailing]="mails[3][i]" (EditEvent)="editMailing($event)"
          (DeleteEvent)="openDialogDeleteMailingof($event)"></app-mailtemplate>
        <mat-icon i18n-matTooltip matTooltip="add Follow up Mail" class="addfollowupmail"
          (click)="createFollowUpMail(mails[3][i])"
          *ngIf="mails[4][i] === undefined && mails[3][i] !== undefined">add</mat-icon>

        <mat-icon *ngIf="mails[4][i] !== undefined">arrow_right_alt</mat-icon>

        <app-mailtemplate *ngIf="mails[4][i]" [CampaignMailing]="mails[4][i]" (EditEvent)="editMailing($event)"
          (DeleteEvent)="openDialogDeleteMailingof($event)"></app-mailtemplate>


      </div>
      <br>
    </div>
  </div>

  <!--  -->
  <br>
  <!-- <div *ngIf="CampaignMailing.send">
    <div class="max-div -content">
      <div fxLayout="row" fxLayout.xs="column" *ngIf="toggleshorttext[i]">
        <div fxFlexcolSpan="4">
          <br />
          <br />
          <label (click)="toggleToFullText(i)" i18n>Show Message</label>
          <br />
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" *ngIf="toggleshorttext[i] === false">
        <div fxFlexcolSpan="4">
          <br />
          <div class="mailpreview" [innerHTML]="htmlpreview[i]"></div>
          <br />
          <label (click)="toggleToFullText(i)">hide</label>
          <br />
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          <label i18n>Opened </label>
        </div>
        <div fxFlex>
          <label i18n>Clicked </label>
        </div>
        <div fxFlex>
          <label i18n>Bounced</label>
        </div>
        <div fxFlex>
          <label i18n>Unsubscribed</label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex>
          {{ CampaignMailing.totalopened }}
        </div>
        <div fxFlex>
          {{ CampaignMailing.totalclicked }}
        </div>
        <div fxFlex>
          {{ CampaignMailing.totalbounced }}
        </div>
        <div fxFlex>
          {{ CampaignMailing.totalunsubscribed }}
        </div>
      </div>
    </div>

    <mat-expansion-panel class="max-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>
          Website Statistics
        </mat-panel-title>
        <mat-panel-description i18n>
          Select your account to get Mailings website
          behavior
          <mat-icon>insert_chart</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="max-div -content">
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <mat-form-field appearance="outline" class="form-full-width">
              <mat-select [(ngModel)]="selectedanalytics" placeholder="GA Account" class="InputField">
                <mat-option *ngFor="let selectedanalytics of Googleanalytics" [value]="selectedanalytics">
                  {{ selectedanalytics.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex></div>
          <div fxFlex>
            <button a mat-raised-button (click)="getAnalyticsCampaign(i)" i18n>
              Get Statistics
              <mat-icon>insert_chart</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex>
            <label i18n>Average Time on Website</label>
          </div>
          <label i18n>Average Bounce Rate</label>
          <div fxFlex>
            <label i18n>Completed Goals</label>
          </div>
          <div fxFlex>
            <label i18n>Page Views</label>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <div fxFlex i18n>
            {{ avgTimeOnPage | slice: 0:4 }} seconds
          </div>
          {{bounceRate | slice: 0:4}}
          %
          <div fxFlex i18n>{{ goalStartsAll }} goals</div>
          <div fxFlex i18n>{{ pageview | slice: 0:4 }} views</div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
-->

  <div style="width: 100%;" fxLayoutAlign="space-between center">
    <button a mat-raised-button *ngIf="!CampaignMailing.send" (click)="openDialogScheduleMailingCampaign()" i18n>
      Schedule <mat-icon>schedule</mat-icon>
    </button>

    <div *ngIf="selectedMarketingplannerevents">
      <button mat-fab color="primary" i18n-matTooltip matTooltip="Add Mailing" (click)="createCampaignMailing()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

</div>