<div fxLayout="column" class="container">

    <h2 i18n>Templates</h2>

    <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
        [vertical]="false">
        <mat-button-toggle matTooltip="Local stored Templates" style="width: 33.333%" value="fromaccount">
            <mat-icon>storage</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Standard Templates" [checked]="true" style="width: 33.333%"
            value="standardtemplates">
            <mat-icon>insert_emoticon</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="Template Examples" style="width: 33.333%" value="exampletemplates">
            <mat-icon>insert_emoticon</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <div *ngIf="group.value == 'fromaccount'" >

    </div>
    <div *ngIf="group.value == 'standardtemplates'" class="holderview" >
        <h3 i18n>BASIC TEMPLATES</h3>
        <div class="gallerytemplate">
            <div fxLayout="row wrap">
                <div *ngFor="let item of templates; let i = index;" class="templatethumb">
                    <img src="{{item}}" alt="template"  [class.selected]="item === data.selected" (click)="selectedtemplate(item, i)">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="group.value == 'exampletemplates'" fxLayout="column">

        <div fxLayout="row">

        </div>

        <div fxLayout="row">

        </div>

        <div fxLayout="row">

        </div>

    </div>
    <button mat-raised-button (click)="select()" color="primary">Select</button>
</div>