import { Component, OnInit, Output, EventEmitter, Input, NgZone, ViewChild } from '@angular/core';
import {
  Relations, RelationsApi, CompanyApi, Company, Account,
  Files, FilesApi, ContainerApi, Forms, FormsApi
} from '../../shared/sdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogsService } from './../../dialogsservice/dialogs.service';
import { Formcheckbox, Formbutton, Formtext, Forminputfield, Formselectionfield, FormImage, FormDivider, Forminputtextfield } from './formsmodel';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { autoDetectRenderer } from 'pixi.js';
import { FormentriesComponent } from './formentries/formentries.component';
import { codesnippetService } from '../../dialogsservice/codesnippet-dialog.component';
import { fonts } from '../../shared/listsgeneral/fonts';

@Component({
  selector: 'app-marketingforms',
  templateUrl: './marketingforms.component.html',
  styleUrls: ['./marketingforms.component.scss']
})
export class MarketingformsComponent implements OnInit {
  @Input() Account: Account;
  @Input() SelectedRelation: Relations;s
  @Input() option: Relations;
  @Input() company: Company;
  @Input() selectedForm: Forms;

  @ViewChild(FormentriesComponent, { static: false })
  public FormentriesComponent: FormentriesComponent;
  public selectedTab = 0;
  public snippetcode: string;
  public formfunctions = [
    'save contactform',
    'send as email',
    'save as contact',
    'save as message'
  ]

  public appearance = [
    'standard',
    'fill',
    'outline'
  ]

  public inputtypes = [
    'color',
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    ' text',
    'time',
    'url',
    'week'
  ]
  public limitresult: 10;
  public numbers = [
    { value: '1', viewValue: '1' },
    { value: '20', viewValue: '20' },
    { value: '30', viewValue: '30' }
  ];
  public listviewxsshow = false;
  public Forms: Forms[];
  //public selectedForm: Forms;
  public selectedelement;
  //Formcheckbox, Formbutton, Formtext, Forminputfield, Formselectionfield, FormImage, FormDivider 
  public toolboxcheckbox = this.createNewFormcheckbox();
  public toolboxbutton = this.createNewFormbutton();
  public toolboxtext = this.createNewFormtext();
  public toolboxinput = this.createNewForminputfield();
  public forminputtext = this.createNewForminputtext();
  public toolboxselection = this.createNewFormselectionfield();
  public toolboximage = this.createNewFormImage();
  public toolboxdivider = this.createNewFormdivider();
  public Fonts = fonts;

  toolset = [
    this.toolboximage,
    this.toolboxtext,
    this.toolboxbutton,
    this.toolboxdivider,
    this.toolboxinput,
    this.toolboxselection,
    this.toolboxcheckbox,
    this.forminputtext
  ];
  selectedFormfont: string;
  fontsize: number;
  formelementfontsize: number;
  sideopen: boolean = true;
  sideopenlist: boolean = true;

  constructor(
    public codesnippetService: codesnippetService,
    public FormsApi: FormsApi,
    public snackBar: MatSnackBar,
    public RelationsApi: RelationsApi,
    public dialogsService: DialogsService
  ) { }

  ngOnInit(): void {
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000,
      panelClass: "snackbar-class"
    });
  }

  // dialog delete on yes activates deletePublications
  public openDialogDelete() {
    this.dialogsService
      .confirm('Delete Form', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.deleteForms(res);
      });
  }

  public onSelectForm(form) {
    this.selectedForm = form;
    this.selectedFormfont = this.selectedForm.font
    setTimeout(() => {
      this.FormentriesComponent.getFormEntries();
      this.setFormStyle()
    }, 300);
    if (this.selectedForm.fontsize){
      this.fontsize = parseInt(this.selectedForm.fontsize) 
    }
    this.sideopen = true;
  }

  sideToggle() {
    
    if (this.sideopen === true) {
      this.sideopen = false
    } else {
      this.sideopen = true
    }
    console.log(this.sideopen)
  }

  // sideTogglelist(){
  //   console.log('open')
  //   if (this.sideopenlist === true) {
  //     this.sideopenlist = false
  //   } else {
  //     this.sideopenlist = true
  //   }
  // }

  setFontSize(element?){
    // console.log(element)
    if (element){
      element.fontsize = this.formelementfontsize + 'pt';
    } else {
      this.selectedForm.fontsize = this.fontsize + 'pt';
      this.setFormStyle();
    }
  }

  public setFormStyle(){
    let r = document.querySelector('.rootenydea') as any; //Get global root element
    // console.log(r)
    if (r){
      r.style.setProperty('--myCSS_ValueOne', this.selectedForm.highlight);
      r.style.setProperty('--myCSS_Valuefont', this.selectedForm.font);
      r.style.setProperty('--myCSS_Valuefontsize', this.selectedForm.fontsize);
      r.style.setProperty('--myCSS_suggestioncolor', this.selectedForm.suggestioncolor);
      
    }
    this.saveForm()
  }

  public saveForm(): void {
    this.RelationsApi.updateByIdForms(this.option.id, this.selectedForm.id, this.selectedForm).subscribe();
  }

  public newForm(): void {
    const newForm: Forms = new Forms();
    newForm.companyId = this.Account.companyId;
    newForm.title = 'New form';
    newForm.template = [[this.toolboxtext]];
    newForm.companyId = this.Account.companyId;
    this.RelationsApi.createForms(this.option.id, newForm)
      .subscribe(res => {
        console.log(res),
          this.selectedForm = res;
        this.getForms();
      });
  }

  // delete Forms -> check container?
  deleteForms(selectedOption): void {
    if (selectedOption === true) {
      this.RelationsApi.destroyByIdForms(this.option.id, this.selectedForm.id)
        .subscribe(res => {
          this.getForms();
          this.selectedForm = undefined;
        })
    }
  }

  searchForms(value){
    this.RelationsApi.getForms(this.option.id, {
      order: 'title DESC',
      where: {title:  { "regexp": value + '/i' } }
    }).subscribe((Forms: Forms[]) => this.Forms = Forms);
  }

  deleteItem(i, i2){
    this.selectedForm.template[i].splice(i2, 1);
    this.selectedelement = undefined;
  }

  deleteRow(i){
    this.selectedForm.template.splice(i, 1);
    this.selectedelement = undefined;
  }

  rowUp(i){
    if (i !== 0){
      this.array_move(this.selectedForm.template, i, i-1)
    }
   
  }

  rowDown(i){
    if (i !== (this.selectedForm.template.lenght-1) ){
      this.array_move(this.selectedForm.template, i, i+1)
    }
  }

  itemCopy(i, i2){
    let clonedArray = JSON.parse(JSON.stringify(this.selectedForm.template[i][i2]))
    clonedArray.fieldid = Math.random().toString(36).substring(7);
    this.selectedForm.template.push([clonedArray]);
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    //return arr; // for testing
};

  getForms(): void {
    this.RelationsApi.getForms(this.option.id, {
      order: 'title DESC'
    }).subscribe((Forms: Forms[]) => this.Forms = Forms);
  }


  //Formcheckbox, Formbutton, Formtext, Forminputfield, Formselectionfield, FormImage, FormDivider 
  createNewFormcheckbox() {
    const checkbox: Formcheckbox = {
      type: 'formcheckbox',
      content: false,
      text: '',
      value: false,
      fieldid: Math.random().toString(36).substring(7),
      optional: false,
      optionalfield: undefined,
      optionfieldvalue: undefined
    }
    return checkbox;
  }
  createNewFormbutton() {
    const button: Formbutton = {
      type: 'formbutton',
      action: '',
      text: 'Button',
      url: '',
      width: 100,
      color:'',
      textcolor:'',
      align: 'left'
    }
    return button;
  }
  createNewFormtext() {
    const text: Formtext = {
      type: 'formtext',
      text: 'Free text',
      fontsize: '11pt'
    }
    return text;
  }

  createNewForminputfield() {
    const input: Forminputfield = {
      type: 'forminput',
      text: 'input',
      valuetype: '',
      required: false,
      appearance: 'standard',
      value: '',
      fieldid: Math.random().toString(36).substring(7),
      optional: false,
      optionalfield: undefined,
      optionfieldvalue: undefined
    }
    return input;
  }

  createNewForminputtext(){
    const inputtext: Forminputtextfield = {
      type: 'forminputtextfield',
      text: 'input',
      valuetype: '',
      required: false,
      appearance: 'standard',
      value: '',
      fieldid: Math.random().toString(36).substring(7),
      optional: false,
      optionalfield: undefined,
      optionfieldvalue: undefined
    }
    return inputtext;
  }

  createNewFormselectionfield() {
    const selection: Formselectionfield = {
      type: 'formselectionfield',
      test: 'select',
      selectionoptions: [],
      required: false,
      appearance: 'standard',
      value: '',
      fieldid: Math.random().toString(36).substring(7),
      optional: false,
      optionalfield: undefined,
      optionfieldvalue: undefined
    }
    return selection;
  }

  createNewFormImage() {
    const image: FormImage = {
      type: 'formimage',
      src: '',
      width: '50',
      height: '50'
    }
    return image;
  }

  createNewFormdivider() {
    const divider: FormDivider = {
      type: 'formdivider'
    }
    return divider;
  }

  saveOption(e, i) {
    console.log(e.srcElement.value, i);
    this.selectedelement.selectionoptions[i] = e.srcElement.value;
    this.saveForm();
  }


  setImage(event): void {
    //console.log(event)
    setTimeout(() => {
      this.selectedelement.src = event;
    }, 500);
  }

  setThankImage(event): void {
    //console.log(event)
    //if (event.indexOf('localhost' !== -1)){}
    setTimeout(() => {
      this.selectedForm.thankyouimgsrc = event;
    }, 500);
  }

  deleteSelectionOption(i) {
    this.selectedelement.selectionoptions = this.selectedelement.selectionoptions.splice(i, 1)
  }


  onSelectElement(element) {
    this.selectedelement = element;
    if (element.fontsize){
      this.formelementfontsize = parseInt(element.fontsize)
    }
  
    
  }

  dropNew(event: CdkDragDrop<any>) {
    console.log('drop new')
    if (event.previousContainer.id === 'toolboxid') {
      const type = this.toolset[event.previousIndex];
      const newdata = this.copyElement(type);
      console.log(newdata, type);
      this.selectedForm.template.push([newdata]);
      this.selectedelement = newdata;
    } else {
      const type = event.previousContainer.data[event.previousIndex];
      const newdata = this.copyElement(type);
      console.log(newdata, type);
      this.selectedForm.template.push([newdata]);
      this.selectedelement = newdata;
      event.previousContainer.data.splice(event.previousIndex, 1);
    }
  }

  dropRow(event: CdkDragDrop<any>) {
    if (event.previousContainer.id === 'toolboxid') {
      const type = this.toolset[event.previousIndex];
      const newdata = this.copyElement(type);
      console.log(newdata, type);
      event.container.data.push(newdata)
      this.selectedelement = newdata;
    } else {
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  drop(event: CdkDragDrop<any>) {
    console.log(event, this.selectedForm, 'drop', this.selectedForm.template)
    if (event.previousContainer === event.container) {
      //if (event.previousContainer.id === 'toolboxid') { return } else {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      //}
    } else if (event.previousContainer.id === 'toolboxid') {
      const type = this.toolset[event.previousIndex];
      const newdata = this.copyElement(type);
      console.log(newdata, type);
      event.container.data.splice(event.currentIndex, 0, newdata);
    }
    else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  copyElement(element) {
    const curan = element;
    let newElement = JSON.parse(JSON.stringify(curan));
    return newElement;
  }

  swiperight(e?) {
    console.log(e);
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    console.log(e);
    this.listviewxsshow = false;
  }


  createCode() {
    //let baseurl = "http://localhost:4401"; //test
    let baseurl = "https://dlcr.enydea.com";
    let url = baseurl + '/formobject?id=' + this.selectedForm.id;
   // let actwidth = document.getElementsByClassName('formstyle')[0].getBoundingClientRect().width;
    let actheight = document.getElementsByClassName('formstyle')[0].getBoundingClientRect().height;
    let height = Math.round(actheight);
    height = 100;
    //console.log(height)
    let js = `window.addEventListener("message",function(e){
      if (e.origin !== "${baseurl}") return;
      var t=document.getElementById("${this.selectedForm.id}"),n=e.data[1];t.style.height=n+"px"},!1);`;
    // this.snippetcode = `<div style="height: ${height}px; width:100%; overflow:visible;" id="enydeaCn"><iframe id="enydeaFr" overflow:visible; src="${url}" frameborder="0" scrolling="no" style="height: 100%;"> </iframe></div><script type="application/javascript">function resizeIFrameToFitContent() { var enydeacontainer1 = document.getElementById( "enydeaCn" ); var iFrame = document.getElementById( "enydeaFr" ); iFrame.width = enydeacontainer1.offsetWidth + 'px';}window.addEventListener('resize', function(e) { resizeIFrameToFitContent();}, true); window.addEventListener('DOMContentLoaded', function(e) { resizeIFrameToFitContent();}, true);</script>`;
    this.snippetcode = `<div style="height: ${height}px; width:100%; overflow:hiddden;" id="${this.selectedForm.id}"><iframe id="enydeaFr" src="${url}" frameborder="0" scrolling="no" style="overflow:hidden; height: 100%; width: 100%;"> </iframe></div><script type="application/javascript">${js}</script>`
    this.codesnippetService.confirm('Copy Code', 'Copy code and input in your website', this.snippetcode).subscribe()
  }


  copyForm(){
    this.saveForm();
    const clone : Forms = structuredClone(this.selectedForm);
    clone.id = undefined;
    clone.title = clone.title + '-copy';
    this.RelationsApi.createForms(this.option.id, clone)
      .subscribe(res => {        
          this.onSelectForm(res)
      });
  }


}
