<div class="picture-container" fxLayout="column">
    <!-- <button mat-raised-button matTooltip="Open gallery" class="deletepicture" mat-mini-fab color="primary">
        
    </button> -->
    <button mat-raised-button (click)="onOpenGallery()">Gallery</button>
    <mat-expansion-panel *ngIf="showdropbox">
            <mat-expansion-panel-header>
                <mat-panel-title i18n fxLayoutAlign="center center">
                    Upload Image/Vector
                </mat-panel-title>
            </mat-expansion-panel-header>
    <div *ngIf="showdropbox">
        <div ng2FileDrop #fileInput [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}"
            (fileOver)="fileOverBase($event)" [uploader]="uploader" class="drop-zone" (onFileDrop)="dropped($event)" i18n>
            Drop vector here
        </div>

        <button mat-button (click)="fileInputsel.click()" i18n>Choose file</button>
        <span
            style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
            <input type="file" #fileInputsel ng2FileSelect [uploader]="uploadersvg" />
        </span>

        <!-- <input type="file" #fileInputsel ng2FileSelect [uploader]="uploader" /> -->



        <div *ngIf="errorMessage">{{errorMessage}}</div>

        <div *ngFor="let item of uploader.queue" style="min-width: 50px;">
            <table>
                <tr>
                    {{ item?.file?.name}}
                </tr>
                <tr>
                    <mat-progress-bar mode="determinate" [value]="item.progress" i18n>
                        upload </mat-progress-bar>
                </tr>
                <tr>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">done</mat-icon>
                        </span>
                        <span *ngIf="item.isCancel">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon>
                        </span>
                        <span *ngIf="item.isError">
                            <mat-icon aria-hidden="false" class="iconsize-10">error</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td nowrap>
                        <button mat-button (click)="setupload(item?.file?.name, item?.file?.size)"
                            [disabled]="item.isReady || item.isUploading || item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">cloud_upload</mat-icon>
                                <label i18n> Upload</label>
                        </button>
                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon>
                            <label i18n> Cancel</label>
                        </button>
                        <button mat-button (click)="item.remove()">
                            <mat-icon class="iconsize-10">remove_circle</mat-icon>
                            <label i18n>  Remove</label>
                          </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div *ngIf="showdropbox">
        <div ng2FileDrop #fileInputSVG [ngClass]="{'drop-zone-over': hasBaseDropZoneOverSvg}"
            (fileOver)="fileOverBaseSvg($event)" [uploader]="uploadersvg" class="drop-zone"
            (onFileDrop)="dropped($event)">
            Drop EPS/PNG/BMP/AI/PSD file format here (needs converting)
        </div>
        <!-- <input type="file" #fileInputSVGsel ng2FileSelect [uploader]="uploadersvg" /> -->

        <button mat-button (click)="fileInputSVGsel.click()" i18n>Choose file</button>
        <span
            style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
            <input type="file" #fileInputSVGsel ng2FileSelect [uploader]="uploadersvg" />
        </span>

        <div *ngIf="errorMessageSvg">{{errorMessageSvg}}</div>
        <div *ngFor="let item of uploadersvg.queue" style="min-width: 50px;">
            <table>
                <tr>
                    {{ item?.file?.name}}
                </tr>
                <tr>
                    <mat-progress-bar mode="determinate" [value]="item.progress">
                        Uploading </mat-progress-bar>
                    <mat-progress-bar mode="indeterminate" 
                    *ngIf="!item.progress && convertingbusy">
                           Converting this may take a while</mat-progress-bar>
                </tr>
                <tr>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">done</mat-icon>
                        </span>
                        <span *ngIf="item.isCancel">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon>
                        </span>
                        <span *ngIf="item.isError">
                            <mat-icon aria-hidden="false" class="iconsize-10">error</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td nowrap>
                        <button mat-button (click)="setSvgupload(item?.file?.name)"
                            [disabled]="item.isReady || item.isUploading || item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">cloud_upload</mat-icon>Upload
                        </button>
                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon> Cancel
                        </button>
                        <button mat-button (click)="item.remove()">
                            <mat-icon class="iconsize-10">remove_circle</mat-icon> Remove
                          </button>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</mat-expansion-panel>
</div>