<div class="picture-container" fxLayout="column">
    <!-- <button matTooltip="Open gallery" class="deletepicture" mat-mini-fab color="primary" (click)="onOpenGallery()">
        <mat-icon>ondemand_video</mat-icon>
    </button> -->
    <button mat-raised-button (click)="onOpenGallery()">Load Video</button>
    <div *ngIf="showdropbox && !accordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title i18n>
                    upload video
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div ng2FileDrop [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                [uploader]="uploader" class="drop-zone" i18n>
                Drop video here
            </div>
            <!-- <input type="file" ng2FileSelect [uploader]="uploader" /> -->

            <button mat-button (click)="fileInput.click()" i18n>Choose file</button>
            <span
                style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
            </span>

            <div *ngIf="errorMessage">{{errorMessage}}</div>

            <div *ngFor="let item of uploader.queue" style="min-width: 50px;" fxLayout="column">
                <table>
                    <tr>
                        {{ item?.file?.name}}
                    </tr>
                    <tr>
                        <mat-progress-bar mode="determinate" [value]="item.progress" i18n>
                            upload </mat-progress-bar>
                    </tr>
                    <tr>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess">
                                <mat-icon aria-hidden="false" class="iconsize-10">done</mat-icon>
                            </span>
                            <span *ngIf="item.isCancel">
                                <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon>
                            </span>
                            <span *ngIf="item.isError">
                                <mat-icon aria-hidden="false" class="iconsize-10">error</mat-icon>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td nowrap>
                            <button mat-button (click)="setupload(item?.file?.name, item?.file?.size)"
                                [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <mat-icon aria-hidden="false" class="iconsize-10">cloud_upload</mat-icon>
                                <label i18n>Upload</label>
                            </button>
                            <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon> 
                                <label i18n>Cancel</label>
                            </button>
                            <button mat-button (click)="item.remove()">
                                <mat-icon class="iconsize-10">remove_circle</mat-icon> 
                                <label i18n>Remove</label>
                            </button>
                        </td>
                    </tr>
                </table>
                <div *ngIf="converting">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <label *ngIf="item.isUploading" i18n>Uploading file</label>
                    <label *ngIf="converting" i18n>Converting Video.. this can take a few minutes</label>
                </div>
            </div>


        </mat-expansion-panel>
    </div>

    <div *ngIf="showdropbox && accordion">
        <div ng2FileDrop [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
            [uploader]="uploader" class="drop-zone" *ngIf="uploader && uploader.queue.length === 0" i18n>
            Drop video here
        </div>
        <!-- <input type="file" ng2FileSelect [uploader]="uploader" /> -->

        <button mat-button (click)="fileInput.click()" i18n>Choose file</button>
        <span
            style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
            <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
        </span>

        <div *ngIf="errorMessage">{{errorMessage}}</div>

        <div *ngFor="let item of uploader.queue" style="min-width: 50px;" fxLayout="column">
            <table>
                <tr>
                    {{ item?.file?.name}}
                </tr>
                <tr>
                    <mat-progress-bar mode="determinate" [value]="item.progress">
                        upload </mat-progress-bar>
                </tr>
                <tr>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">done</mat-icon>
                        </span>
                        <span *ngIf="item.isCancel">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon>
                        </span>
                        <span *ngIf="item.isError">
                            <mat-icon aria-hidden="false" class="iconsize-10">error</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td nowrap>
                        <button mat-button (click)="setupload(item?.file?.name, item?.file?.size)"
                            [disabled]="item.isReady || item.isUploading || item.isSuccess">
                            <mat-icon aria-hidden="false" class="iconsize-10">cloud_upload</mat-icon>
                            <label i18n>Upload</label>
                        </button>
                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                            <mat-icon aria-hidden="false" class="iconsize-10">cancel</mat-icon> 
                            <label i18n>Cancel</label>
                        </button>
                        <button mat-button (click)="item.remove()">
                            <mat-icon class="iconsize-10">remove_circle</mat-icon> 
                            <label i18n>Remove</label>
                        </button>
                    </td>
                </tr>
            </table>
            <div *ngIf="converting">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <label *ngIf="item.isUploading" i18n>Uploading file</label>
                <label *ngIf="!item.isUploading" i18n>Converting Video.. this can take a few minutes</label>
            </div>
        </div>
    </div>


</div>