<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"  [vertical]="false">
    <mat-button-toggle matTooltip="Local stored videos" style="width: 50%" value="fromaccount">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Standard videos" [checked]="true"  style="width: 50%"  value="standardvideos">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
    </mat-button-toggle-group>

  <div *ngIf="group.value == 'standardvideos'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let video of stockvideos; let i = index" fxFlex>
      <div fxLayout="column" [class.selected]="video.url === data.selected" (click)="selectedimage(video.url)">
          <img src={{video.fileimage}} class="image" (click)="selectedimage(video.url)"
          [class.selected]="video.url === data.selected"/>
        </div>
    </div>
  </div>

  <div *ngIf="group.value == 'standardvideos'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{stockvideosSliceMin + 1}}</label>&nbsp;-&nbsp;
    <label>{{stockvideosSlice + 1}}</label>&nbsp; of &nbsp;
    <label>{{stockvideos.length}}</label>
  </div>

  <div *ngIf="group.value == 'fromaccount'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let video of accountvideos; let i = index" fxFlex>
      <!-- <img src={{video.fileimage}} class="image" (click)="selectedimage(video.url)"
      [class.selected]="video.url === data.selected"/> -->
      <button a mat-raised-button (click)="selectedimage(video.url)" style="width: 200px;"
      [class.selected]="video.url === data.selected"><mat-icon>ondemand_video</mat-icon> {{video.name}}</button>
     
    </div>
  </div>

  <div *ngIf="group.value == 'fromaccount'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{fromaccountSliceMin + 1}}</label>&nbsp;-&nbsp;
    <label>{{fromaccountSlice + 1}}</label>&nbsp; of &nbsp;
    <label>{{accountvideosArray.length}}</label>
  </div>


  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()" i18n>Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected" i18n>
      Import
    </button>
  </div>
</div>
