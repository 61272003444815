<div fxLayoutAlign="center center" style="width: 100%;">
    <mat-card class="planselection">
        <section fxLayout="row" fxLayoutAlign="space-between start">
            <h1 i18n>Select Plan</h1>
            <mat-button-toggle-group name="currency" [(ngModel)]="plan.selectedcurrency">
                <mat-button-toggle value="EUR" (change)="updateplan()">
                    EUR
                </mat-button-toggle>
                <mat-button-toggle value="USD" (change)="updateplan()">
                    USD
                </mat-button-toggle>
            </mat-button-toggle-group>
        </section>
        <div class="form-login">

            <div fxLayout="row">
                <mat-button-toggle-group name="plan" [(ngModel)]="plan.selectedplan" class="plan" fxLayout="row">
                    <mat-button-toggle fxFlex="50%" value="go" (change)="updateplan($event.value)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon class="iconsize-22" color="primary">rocket_launch</mat-icon>
                            <h3 i18n>Go Plan</h3>
                        </div>

                        <mat-list role="list">
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>5.000 emails/Month</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>E-mail/Chat Support</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>1 user</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>1 Marketing Accounts</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>1 GB storage</label>
                            </mat-list-item>
                            <label *ngIf="!showmore" (click)="showmore = true" class="showmore">Show more..</label>
                            <div *ngIf="showmore">
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Video creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>GIF creator</label>
                                </mat-list-item>

                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Image creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Email creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>> 1000 standard graphics</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Website tracker</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Social Media Planner</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label>CRM</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Translation service</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>AI text creation service</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>clear</mat-icon>
                                    <label i18n>Work in Teams</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>clear</mat-icon>
                                    <label>Pay-As-You-Go</label>
                                </mat-list-item>
                                <label *ngIf="showmore" (click)="showmore = false" class="showmore">Show less..</label>
                            </div>
                            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                                <h3> {{ go | currency: plan.selectedcurrency}}</h3>
                            </div>

                        </mat-list>
                    </mat-button-toggle>
                    <mat-button-toggle fxFlex="50%" value="enterprise" (change)="updateplan($event.value)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon class="iconsize-22" color="primary">corporate_fare</mat-icon>
                            <h3 i18n>Enterprise Plan</h3>
                        </div>
                        <mat-list role="list">
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>60.000 emails/Month</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>24/7 support </label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>10 users</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>5 Marketing Accounts</label>
                            </mat-list-item>
                            <mat-list-item role="listitem">
                                <mat-icon matListIcon>check</mat-icon>
                                <label i18n>unlimited storage</label>
                            </mat-list-item>
                            <label *ngIf="!showmore" (click)="showmore = true" class="showmore">Show more..</label>
                            <div *ngIf="showmore">
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Video creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>GIF creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Image creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Email creator</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>> 1000 standard graphics</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Website tracker</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Social Media Planner</label>
                                </mat-list-item>

                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label>CRM</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Translation service</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>AI text creation service</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label i18n>Work in Teams</label>
                                </mat-list-item>
                                <mat-list-item role="listitem">
                                    <mat-icon matListIcon>check</mat-icon>
                                    <label>Pay-As-You-Go</label>
                                </mat-list-item>
                                <label *ngIf="showmore" (click)="showmore = false" class="showmore">Show less..</label>
                            </div>
                            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
                                <h3> {{ enterprise | currency: plan.selectedcurrency}} </h3>
                            </div>
                        </mat-list>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <br>


            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Add Additional Users, Mails, Accounts and Support
                </mat-expansion-panel-header>

                <div fxLayout="column">
                    <mat-card fxLayout="column">
                        <!-- <mat-checkbox [(ngModel)]="addemails" (change)="updateplan()" i18n>Add extra
                            emails</mat-checkbox> -->
                        <mat-slider matTooltip-i18n matTooltip="Add extra email" [(ngModel)]="plan.emailcount" min="0"
                            [tickInterval]="tickInterval" [step]="1" max="100" thumbLabel="true"
                            (change)="updateplan()"></mat-slider>
                        <section class="mat-typography">
                            <label>{{plan.emailcount}}</label><label *ngIf="plan.emailcount > 0">000 &nbsp; emails</label>
                        </section>
                    </mat-card>
                </div>
                <br>
                <div fxLayout="column">
                    <mat-card fxLayout="column">
                        <mat-slider matTooltip-i18n matTooltip="Add extra users"
                            [(ngModel)]="plan.additionalmarketingrelationaccounts" min="0" [tickInterval]="tickInterval"
                            [step]="1"  max="20" thumbLabel="true"
                            (change)="updateplan()"></mat-slider>
                        <section class="mat-typography">
                            <label i18n>{{plan.additionalmarketingrelationaccounts}} &nbsp; Marketing Accounts
                                {{marketingrelationprice |
                                currency: plan.selectedcurrency}}</label>
                        </section>
                    </mat-card>
                </div>
                <br>
                <div fxLayout="column" *ngIf="plan.selectedplan === 'enterprise'">
                    <mat-card fxLayout="column">
                        <mat-slider matTooltip-i18n matTooltip="Add extra users" [(ngModel)]="plan.additionalusers" min="0"
                            [tickInterval]="tickInterval" [step]="1" max="20"
                            thumbLabel="true" (change)="updateplan()"></mat-slider>
                        <section class="mat-typography">
                            <label>{{plan.additionalusers}} &nbsp; users {{userprice | currency: plan.selectedcurrency}}</label>
                        </section>
                    </mat-card>
                </div>
                <br>
                <div fxLayout="column">
                    <mat-card>
                        <mat-checkbox [(ngModel)]="plan.migrationsupport" (change)="updateplan()" i18n>Migration Support
                            {{ migration | currency: plan.selectedcurrency }}</mat-checkbox>
                    </mat-card>
                </div>
                <br>
                <div fxLayout="column">
                    <mat-card>
                        <mat-checkbox [(ngModel)]="plan.trainingsupport" (change)="updateplan()" i18n>Training Support
                            {{ training | currency: plan.selectedcurrency }} </mat-checkbox>
                    </mat-card>
                </div>
            </mat-expansion-panel>

            <br>
            <div fxLayout="column">
                <mat-button-toggle-group name="paymentterm" [(ngModel)]="plan.terms" fxLayout="row">
                    <mat-button-toggle fxFlex="50%" value="monthly" (change)="updateplan()"
                        i18n>Monthly</mat-button-toggle>
                    <mat-button-toggle fxFlex="50%" value="yearly" (change)="updateplan()"
                        [disabled]="plan.selectedplan === 'enterprise'" i18n>
                        Yearly (-10% discount)</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <br>
            <div fxLayout="column" *ngIf="plan.monthly">
                <mat-card fxLayout="column">
                    <label i18n>Total Monthly:
                        &nbsp; {{ plan.monthly | currency: plan.selectedcurrency }}</label>
                        <label i18n *ngIf="discountvalue">Discount: {{discountvalue}} % </label>
                </mat-card>
                <br>
            </div>
            <div fxLayout="column" *ngIf="plan.yearly > 0">
                <mat-card fxLayout="column">
                    <label i18n>Total Yearly:
                        &nbsp; {{ plan.yearly | currency: plan.selectedcurrency }} </label>
                        <label i18n *ngIf="discountvalue && discountvalue !== 100">Discount: {{discountvalue + 10}} % </label>
                        <label i18n *ngIf="discountvalue && discountvalue === 100">Discount: {{discountvalue}} % </label>
                </mat-card>
            </div>
            <br>
            <div fxLayout="column" *ngIf="plan.onetime > 0">
                <mat-card fxLayout="column">
                    <label i18n>Total One-time:
                        &nbsp; {{ plan.onetime| currency: plan.selectedcurrency }} </label>
                        <!-- <label i18n *ngIf="discountvalue">Discount: {{discountvalue}} %</label> -->
                </mat-card>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                <label *ngIf="!discount" i18n (click)="discount = true" style="font-size: small;">I have a discount code</label>
                <mat-form-field *ngIf="discount" fxFlex="60%">
                    <input matInput placeholder="Discount code" [(ngModel)]="plan.discountcode">
                </mat-form-field>
                <button *ngIf="discount" mat-button (click)="checkDiscount()">Check</button>
                <mat-icon *ngIf="baddiscount">error</mat-icon>
                <mat-icon *ngIf="gooddiscount">check</mat-icon>
            </div>

            <div fxLayout="row">
                <div fxLayout="column" *ngIf="error">
                    <section class="mat-typography">
                        <label>{{ errorMessage }}</label>
                    </section>
                </div>
            </div>
            <!-- <div fxLayout="row">
    <div fxLayout="column" *ngIf="response">
      <section class="mat-typography">
        <label>{{ responsemessage }}</label>
      </section>
    </div>
  </div> -->
            <br>

            <div fxLayout="row" fxLayoutGap="40px">
                <button (click)="emitBack()" a mat-raised-button i18n fxFlex="20%">Back</button>
                <button color="primary" (click)="emitPlan()" a mat-raised-button i18n fxFlex="80%">Go to account details</button>
               
            </div>

        </div>
    </mat-card>
</div>