import { EventEmitter, HostListener, Injectable, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { vector } from '../../imagecreator/imagecreator.component';
import { PatheditorService } from './patheditor.service';

@Injectable({
  providedIn: 'root'
})
export class SavehistoryService implements OnDestroy {
  public destroy = false;
  public animationarray = []; // array with style and position settings;
  public history = ['[]'];
  public currenthistoryversion = 0;


  // /* Save history  */

  // @HostListener('document:keydown', ['$event'])
  // onKeydownHandler(event: KeyboardEvent) {
  //   this.keyinput.next(event);
  // }

  // /* Retrieve one step history  */
  // // turn off for memory usage
  // @HostListener('mouseup', ['$event'])
  // clickEvent(event) {
  //   console.log('mouse up')
  //   this.clicks.next(event);
  // }


  @Input() debounceTime = 50;

  @Output() debounceClick = new EventEmitter();
  public clicks = new Subject();
  public subscriptionclick: Subscription;
  versionsaved: boolean;


  constructor(
    private patheditorService: PatheditorService,
  ) {
    /* Save history  */
    // this.app = new Application({});


    /* Retrieve one step history  */
    this.subscriptionclick = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => {
      this.saveToLocalStorageHistory()
      // console.log('create history')
    }
      //console.log("Match:", this.arraysEqual(this.animationarray, ar2))
    );
  }


  ngOnDestroy() {
    this.destroy = true;
  }

  async KeyPress(evtobj, whiteboard, editpath): Promise<Array<any>> {

    if (editpath){return}
    
    //console.log(evtobj)
    if (evtobj.keyCode == 90 && evtobj.ctrlKey) {
      if (whiteboard) { this.patheditorService.reverseHistory(); return } else {
        return await this.historyBack(); //this.detectchange(); 
      }

    } // windows
    else if (evtobj.keyCode == 89 && evtobj.ctrlKey) {
      if (whiteboard) { this.patheditorService.forwardHistory(); return } else {
        return await this.historyForward(); //this.detectchange(); 
      }
    } // windows
    else if (evtobj.keyCode == 90 && evtobj.metaKey && !evtobj.shiftKey) {
      if (whiteboard) { this.patheditorService.reverseHistory(); return } else {
        return await this.historyBack(); //this.detectchange(); 
      }
    } // mac
    else if (
      evtobj.keyCode == 90 && evtobj.metaKey && evtobj.shiftKey) {
      if (whiteboard) { this.patheditorService.forwardHistory(); return } else {
        return await this.historyForward(); //this.detectchange(); 
      }
    } // mac
    else { return }
  }



  async saveToLocalStorageHistory(setnr?) {
    if (!this.destroy) { // check destroy is not triggered same time as save to storage

      this.versionsaved = false;
      // check if is actually a newer version check last with new is similar
      // triggers on mouseup event set debounce time if triggered to fast see ngoninit
      // clean chart area for JSON

      let meta = [];
      for (let i = 0; i < this.animationarray.length; i++) {
        meta.push([])
        if (this.animationarray[i].type === 'chart') {
          for (let y = 0; y < this.animationarray[i].data.length; y++) {
            let meta1 = this.animationarray[i].data[y]._meta;
            let meta2 = this.animationarray[i].productiondata[y]._meta;
            meta[i].push(meta1);
            meta[i].push(meta2);
            delete this.animationarray[i].data[y]._meta;
            delete this.animationarray[i].productiondata[y]._meta;
          }
        }
      }

      let clonearray = [...this.animationarray];
      for (let i = 0; i < this.animationarray.length; i++) {
        if (this.animationarray[i].type === 'chart') {
          clonearray[i].components = [];
        }
        if (this.animationarray[i].type === 'threed') {
          clonearray[i].mesh = undefined;
        }
      }

      //console.log('save', this.animationarray);
      let jsonaniarray = JSON.stringify(clonearray); // ,this.getCircularReplacer()
      let jsonaniarraylast = this.history[this.currenthistoryversion];

      //console.log(jsonaniarray, jsonaniarraylast)
      if (this.currenthistoryversion < this.history.length - 1) {
        this.history = this.history.splice(this.currenthistoryversion + 1, this.history.length - 1)
      }
      if (jsonaniarray !== jsonaniarraylast) {
        //console.log('newhistory')
        this.currenthistoryversion = this.currenthistoryversion + 1;
        this.history.push(jsonaniarray);
      }

      // restore chart area meta function
      this.restoreChart(meta);
    }
  }

  // circular chart data._meta
  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };


  async historyBack(): Promise<Array<Object>> {
    // console.log('ctrl-z -1 ', this.currenthistoryversion, this.history)
    // if not last or not empty and if is new;
    if (this.currenthistoryversion > 0) {
      try {
        this.currenthistoryversion = this.currenthistoryversion - 1;
        let storedback = this.history[this.currenthistoryversion];
        //console.log(storedback); /// this.history, this.currenthistoryversion, 
        this.animationarray = JSON.parse(storedback);
        //console.log(this.animationarray)
        //this.detectchange();
        return this.animationarray;
      } catch (error) {
        console.log(error, 'version: ' + this.currenthistoryversion, this.history[this.currenthistoryversion])
      }

    }
  }

  async historyForward(): Promise<Array<Object>> {
    // console.log('ctrl-y', this.currenthistoryversion)
    // if not latest or not empty
    let histlast = this.history.length - 1;
    if (this.currenthistoryversion < histlast) {
      this.currenthistoryversion = this.currenthistoryversion + 1;
      let storedback = this.history[this.currenthistoryversion]
      this.animationarray = JSON.parse(storedback);
      //this.detectchange();
      return this.animationarray;
    }
  }


  restoreChart(meta) {
    for (let i = 0; i < this.animationarray.length; i++) {
      if (this.animationarray[i].type === 'chart') {
        for (let y = 0; y < this.animationarray[i].data.length; y++) {
          this.animationarray[i].data[y]._meta = meta[i][0];
          this.animationarray[i].productiondata[y]._meta = meta[i][1];
        }
      }
    }
  }



  objectsEqual = (o1, o2) => {
    let match = false
    if (typeof o1 === 'object' && Object.keys(o1).length > 0) {
      match = (Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p])))
    } else {
      match = (o1 === o2)
    }
    return match
  }

  arraysEqual = (a1, a2) => {
    let finalMatch = []
    let itemFound = []

    if (a1.length === a2.length) {
      finalMatch = []
      a1.forEach(i1 => {
        itemFound = []
        a2.forEach(i2 => {
          itemFound.push(this.objectsEqual(i1, i2))
        })
        finalMatch.push(itemFound.some(i => i === true))
      })
    }
    return finalMatch.every(i => i === true)
  }

}
