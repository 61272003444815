<div class="tabcontainer" fxLayout="row">
    <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow" class="card-left-menu"
        fxFlex="20%">

        <mat-card>
            <mat-card-header>
                <mat-card-title i18n>Account Type</mat-card-title>
            </mat-card-header>
            <mat-list role="list">

                <mat-button-toggle-group #group="matButtonToggleGroup" [vertical]="true" style="width: 100%;">
                    <mat-button-toggle value="Analytics">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon>details</mat-icon>
                            <label i18n>Analytics</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Domain" (click)="checkDomainSettings()"
                        fxLayoutAlign="space-between start">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon>domain</mat-icon>
                            <label i18n>Domain</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Twitter" (click)="getTwitter()">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon svgIcon="enydea_twitter"></mat-icon>
                            <label>Twitter</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Linkedin" (click)="getLinkedin()">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon svgIcon="enydea_linkedin"></mat-icon>
                            <label>Linkedin</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Facebook" (click)="getFacebook()">
                        <div fxLayoutAlign="space-between center">
                            <div fxLayoutGap="5px">
                                <mat-icon svgIcon="enydea_facebook"></mat-icon>
                                <mat-icon svgIcon="enydea_instagram"></mat-icon>
                            </div>

                            <label>Facebook</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Pinterest" (click)="getPinterest()">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon svgIcon="enydea_pinterest"></mat-icon>
                            <label>Pinterest</label>
                        </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="Tracking">
                        <div fxLayoutAlign="space-between center">
                            <mat-icon>track_changes</mat-icon>
                            <label>Tracking</label>
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>

            </mat-list>
        </mat-card>
    </div>

    <div class="relationcontainer" ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow" fxFlex="80%">

        <mat-card *ngIf="group.value == 'Domain'">
            <mat-card-header>
                <mat-card-title i18n>Domain Settings</mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <br>
                <mat-form-field style="margin-top: 10px;" appearance="outline">
                    <mat-label>Domain</mat-label>
                    <input matInput [(ngModel)]="selectedRelation.domain" i18n-placeholder placeholder="Domain">
                </mat-form-field>
                <br>
                <label i18n>Setup new domain, add your domain and add the DNS variables to your hosting DNS
                    records</label>
                    <br>
                <h3>DNS variables</h3>
                <div fxLayout="column" fxLayoutGap="10px" style="font-size: 8pt; overflow-wrap: break-word;">
                    <div fxLayout="row">
                        <h3 fxFlex="10%" i18n>Type</h3>
                        <h3 fxFlex="30%" i18n>Hostname</h3>
                        <h3 fxFlex="60%" i18n>Value to enter</h3>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <label fxFlex="10%">TXT</label>
                        <label fxFlex="30%"
                            (click)="copyMessage(selectedRelation.domain)">{{selectedRelation.domain}}</label>
                        <label fxFlex="60%" (click)="copyMessage('v=spf1 include:mailgun.org ~all')">v=spf1
                            include:mailgun.org ~all</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <label fxFlex="10%">TXT</label>
                        <label fxFlex="30%"
                            (click)="copyMessage('pic._domainkey.' + selectedRelation.domain)">pic._domainkey.{{selectedRelation.domain}}</label>
                        <div style="max-width: 400px;">
                            <label fxFlex="60%" (click)="copyMessage(domainresponse)">{{domainresponse}}</label>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <label fxFlex="10%">CNAME</label>
                        <label fxFlex="30%"
                            (click)="copyMessage('mail.' + selectedRelation.domain)">mail.{{selectedRelation.domain}}</label>
                        <label fxFlex="60%" (click)="copyMessage('mailgun.org')">mailgun.org</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <label fxFlex="10%">MX</label>
                        <label fxFlex="30%">priority: 10</label>
                        <label fxFlex="60%" (click)="copyMessage('mxa.mailgun.org')">mxa.mailgun.org</label>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <label fxFlex="10%">MX</label>
                        <label fxFlex="30%">priority: 10</label>
                        <label fxFlex="60%" (click)="copyMessage('mxb.mailgun.or')">mxb.mailgun.org</label>
                    </div>
                </div>
                <br>
                <button (click)="setDomain()" a mat-raised-button class="button">Save domain settings</button>
                <!-- <button (click)="setDomain()" a mat-raised-button class="button">Check settings</button> -->
                <button (click)="checkDomainSettings()" a mat-raised-button class="button">Check Status</button>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="group.value == 'Analytics'">
            <mat-card-header>
                <mat-card-title>Google Analytics</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <div *ngFor="let Googleanalytics of Googleanalytics; let i = index;">
                            <span>{{ Googleanalytics.gaclientid }} {{ Googleanalytics.name }}</span>
                            <mat-icon class="iconsize-10" (click)="deleteAnalytics(i)">delete</mat-icon>
                        </div>
                    </div>
                </div>
                <br>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <label i18n>Please add:</label>
                        <br>
                        <strong>starting-account-1gh6d1ycl2sq@enydea-1650438501076.iam.gserviceaccount.com</strong>
                        <br>
                        <label i18n> To you management accounts and fill in your
                            Google analytics account ID below.
                            Do not use Universal Analytics as it will no longer be supported.
                            for more info: </label> <a
                            href="https://support.google.com/analytics/answer/11583528">Google</a>
                    </div>
                </div>
                <br>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field appearance="outline">
                        <mat-label i18n>ga: ID Number</mat-label>
                        <input matInput [(ngModel)]=newGoogleAnalytics.gaclientid i18n-placeholder
                            placeholder="ga:ID number">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n>Your Account name</mat-label>
                        <input matInput [(ngModel)]=newGoogleAnalytics.name i18n-placeholder
                            placeholder="Your Account name">
                    </mat-form-field>
                </div>
                <br>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button (click)="createGoogleAnalytics()" i18n>Save</button>
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="group.value == 'Twitter'">
            <mat-card-header>
                <mat-card-title>Twitter</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <div *ngFor="let Twitter of Twitter; let i = index">
                            <div class="conaccount" fxLayoutAlign="start center">
                                <span fxFlex="60%"> {{ Twitter.name }}</span>
                                <span fxFlex="20" matTooltip="created at"> {{ Twitter.created_at |
                                    date:shortDate}}</span>
                                <mat-icon fxFlex="10%" *ngIf="Twitter.AccessToken"
                                    matTooltip="User verified">verified_user</mat-icon>
                                <mat-icon fxFlex="10%" (click)="deleteTwitter(i)" matTooltip="Delete Account"
                                    class="iconsize-10">delete</mat-icon>
                                <mat-icon fxFlex="10%" (click)="refreshTwitter(i)" matTooltip="Refresh Account"
                                    class="iconsize-10">refresh</mat-icon>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>

            </mat-card-content>
            <mat-card-actions>
                <a mat-mini-fab color="primary" (click)="linkTwitterAccount()" i18n-matTooltip
                    matTooltip="Add Twitter account">
                    <mat-icon>add</mat-icon>
                </a>
                <!-- <button mat-button>Save</button> -->
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="group.value == 'Pinterest'">
            <mat-card-header>
                <mat-card-title>Pinterest</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <div *ngFor="let Pinterest of Pinterest; let i = index">
                            <div class="conaccount" fxLayoutAlign="start center">
                                <span fxFlex="60%"> {{ Pinterest.name }}</span>
                                <span fxFlex="20" matTooltip="created at"> {{ Pinterest.created_at |
                                    date:shortDate}}</span>
                                <mat-icon fxFlex="10%" *ngIf="Pinterest.AccessToken"
                                    matTooltip="User verified">verified_user</mat-icon>
                                <mat-icon fxFlex="10%" (click)="deletePinterest(i)" matTooltip="Delete Account"
                                    class="iconsize-10">delete</mat-icon>
                                <mat-icon fxFlex="10%" (click)="refreshPinterest(i)" matTooltip="Refresh Account"
                                    class="iconsize-10">refresh</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
            <mat-card-actions>
                <a mat-mini-fab color="primary" (click)="linkPinterestAccount()">
                    <mat-icon>add</mat-icon>
                </a>
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="group.value == 'Linkedin'">
            <mat-card-header>
                <mat-card-title>Linkedin</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <div *ngFor="let Linkedin of Linkedin; let i = index">
                            <div class="conaccount" fxLayoutAlign="start center">
                                <span fxFlex="60%"> {{ Linkedin.name }}</span>
                                <span fxFlex="20" matTooltip="created at"> {{ Linkedin .created_at |
                                    date:shortDate}}</span>
                                <mat-icon fxFlex="10%" *ngIf="Linkedin.accesstoken"
                                    matTooltip="User verified">verified_user</mat-icon>
                                <mat-icon fxFlex="10%" (click)="deleteLinkedin(i)" matTooltip="Delete Account"
                                    class="iconsize-10">delete</mat-icon>
                                <mat-icon fxFlex="10%" (click)="refreshLinkedin(i)" matTooltip="Refresh Account"
                                    class="iconsize-10">refresh</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <a mat-mini-fab color="primary" (click)="linkLinkedinAccount()">
                    <mat-icon>add</mat-icon>
                </a>
            </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="group.value == 'Facebook'">
            <mat-card-header>
                <mat-card-title>Facebook</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <label i18n>Add Facebook + Instagram business Account (Your instagram account will need to be connected to your Facebook account)  </label>
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                        <div *ngFor="let Facebook of Facebook; let i = index">
                            <div class="conaccount" fxLayoutAlign="start center">
                                <span fxFlex="60%"> {{ Facebook.name }}</span>
                                <span fxFlex="20" matTooltip="created at"> {{ Facebook.created_at |
                                    date:shortDate}}</span>
                                <mat-icon fxFlex="10%" *ngIf="Facebook.accesstoken"
                                    matTooltip="User verified">verified_user</mat-icon>
                                <mat-icon fxFlex="10%" (click)="deleteFacebook(Facebook)" matTooltip="Delete Account"
                                    class="iconsize-10">delete</mat-icon>
                                <mat-icon fxFlex="10%" (click)="refreshFacebook(Facebook)" matTooltip="Refresh Account"
                                    class="iconsize-10">refresh</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
            <mat-card-actions>
                <a mat-mini-fab color="primary" (click)="linkFacebookAccount()">
                    <mat-icon>add</mat-icon>
                </a>
            </mat-card-actions>
        </mat-card>

        <!-- <mat-card *ngIf="group.value == 'Adwords'">
        <mat-card-header>
          <mat-card-title>Adwords</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex>
              <div *ngFor="let Adwords of Adwords; let i = index">
                <span>{{ Adwords.id }} {{ Adwords.name }}</span>
                <a mat-mini-fab color="primary" (click)="deleteAdwords(i)">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex>
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]=newAdwords.id i18n-placeholder placeholder="ID">
              </mat-form-field>
            </div>
            <div fxFlex>
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]=newAdwords.name i18n-placeholder placeholder="Name">
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <label i18n>Please login to verify</label>
        <mat-card-actions>
          <a mat-mini-fab color="primary" (click)="createAdwords()">
            <mat-icon>add</mat-icon>
          </a>
          <button mat-button i18n>Save</button>
        </mat-card-actions>
      </mat-card> -->

        <mat-accordion *ngIf="group.value == 'Tracking'">
            <mat-expansion-panel>
                <mat-expansion-panel-header i18n>
                    Tracking code
                </mat-expansion-panel-header>
                <div fxLayout="column">
                    <label i18n>Copy and paste the code below in your website header</label>
                    <a href="https://support.autopilothq.com/hc/en-us/articles/207933036-How-do-I-add-the-tracking-code-to-a-Wordpress-website-"
                        target="_blank">For Wordpress</a>
                    <a href="https://support.energizethemes.com/how-do-i-update-the-header-and-footer-code/"
                        target="_blank">For
                        Joomla</a>
                    <a href="https://www.drupal.org/project/header_and_footer_scripts" target="_blank">For Drupal</a>
                    <mat-form-field class="example-full-width">
                        <textarea matInput [(ngModel)]="trackingcode" (click)="copyMessage(trackingcode)" disabled
                            #tooltip="matTooltip" (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" i18n-matTooltip matTooltip="Copied!"
                            (click)="tooltip.toggle()"></textarea>
                    </mat-form-field>
                    <button mat-button (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" i18n-matTooltip matTooltip="Copied!"
                        #tooltip="matTooltip" (click)="copyMessage(trackingcode)"
                        (click)="tooltip.toggle()">Copy</button>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Wordpress Plugin
                </mat-expansion-panel-header>
                <div fxLayout="column">
                    <label i18n>Copy and paste the Key and ID after installing the plugin under settings enydea</label>
                    <br>
                    <a href=" https://www.wpbeginner.com/beginners-guide/step-by-step-guide-to-install-a-wordpress-plugin-for-beginners/"
                        target="_blank" i18n>How to install a plugin</a>
                    <br>
                    <button (click)="downloadPlugin()" mat-raised-button i18n>Download Plugin</button>
                    <br>
                    <h4>Plugin ID</h4>
                    <br>
                    <label (click)="copyMessage(enydea_id)" #tooltip="matTooltip"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" i18n-matTooltip matTooltip="Copied!"
                        (click)="tooltip.toggle()">{{enydea_id}}</label>
                    <br>
                    <h4>Plugin Key</h4>
                    <br>
                    <label matInput (click)="copyMessage(enydea_key)" #tooltip="matTooltip"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" i18n-matTooltip matTooltip="Copied!"
                        (click)="tooltip.toggle()">{{enydea_key}}</label>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>


</div>