import { Component, OnInit, Output, EventEmitter, Input, NgZone, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import {
  Relations, RelationsApi, CompanyApi, Company, Account,
  Files, FilesApi, ContainerApi, Forms
} from '../../shared/sdk';
import { BASE_URL, API_VERSION } from '../../shared/base.api';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
//import { gsap } from 'src/assets/js/all';
import gsap from "gsap";
import { CustomBounce, CustomEase, PixiPlugin, Physics2DPlugin, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper, Draggable } from 'src/assets/js/all';
gsap.registerPlugin(Physics2DPlugin, PixiPlugin, CustomEase, CustomBounce, Draggable, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper);
const plugins = [PixiPlugin, Draggable, CustomEase, CustomBounce, InertiaPlugin, DrawSVGPlugin, MorphSVGPlugin, ScrambleTextPlugin, SplitText, Physics2DPlugin, MotionPathPlugin, MotionPathHelper]; //needed for GSAP
import { FileUploader, FileItem } from 'ng2-file-upload';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
//import domtoimage from 'dom-to-image';
import domtoimage from 'dom-to-image-improved';
import { fonts } from '../../shared/listsgeneral/fonts';

import { codesnippetService } from '../../dialogsservice/codesnippet-dialog.component';
// import { debounceTime } from 'rxjs/operators';
// import { HostListener } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import PathEditor from 'src/assets/js/utils/PathEditor';
import {
  chart, animationtype, vectoranimationtype, vectoranimation, vectorcombinator, vectorelement,
  splittexttype, shapeanimation, imageanimation, textanimation, counteranimation, countertype, formanimation,
  threedanimation, factoryThreedanimation, factoryThreedanimationcontrol, imageanimationFactory, shapeanimationFactory,
  factoryChart, factoryVectoranimation, factoryVectorelement, factoryVectorAnimationLayer, factoryCanvas,
  factoryTextanimation,
  factoryGlobalThreedControls,
  factoryEmbeddedVideo,
  EmbeddedVideo
} from './videocreator.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogGetname } from '../../dialogsservice/dialog.getname'
import { DialogsService } from './../../dialogsservice/dialogs.service';
import { BackgroundComponent } from '../../shared/background/background.component';
import { Router, NavigationStart } from '@angular/router';
import { colorset } from '../../shared/colorsets';
import { _ } from 'core-js';
import { Observable, BehaviorSubject } from 'rxjs';
import { CountUpOptions, CountUp } from 'countup.js';
// import { start } from 'repl';
import { ThreedComponent } from './threed/threed.component';
import { SavehistoryService } from './supportfunctions/savehistory.service';
import { SpeeddialService } from './supportfunctions/speeddial.service';
import { AnimationsService } from './supportfunctions/animations.service';
import { EasetypesService } from './supportfunctions/easetypes.service';
import { AudioService } from './supportfunctions/audio.service';
import { PatheditorService } from './supportfunctions/patheditor.service';
import { BackgroundeffectsService } from './supportfunctions/backgroundeffects.service';
import { VectorService } from './supportfunctions/vector.service';
import { GeneralsettingsComponent } from './popupscreens/generalsettings/generalsettings.component'
import { BackgroundsettingsComponent } from './popupscreens/backgroundsettings/backgroundsettings.component';
import { UntypedFormControl } from '@angular/forms';
import { CodegeneratorService } from './supportfunctions/codegenerator.service';
import { debounceTime } from 'rxjs/operators';
import { load } from 'opentype.js'
import { DraggableService } from './supportfunctions/draggable.service';
import { ComponentCanDeactivate } from 'src/app/shared/pendingchanges.guard';
import { CharactercreatorComponent } from './popupscreens/charactercreator/charactercreator.component';
import { MovePathHelperService } from './threed/movepathhelper';
import { EmbeddedpixiService } from './supportfunctions/embeddedpixi.service';
import { AppserviceService } from 'src/app/appservice.service';
import { TensorflowService } from './supportfunctions/tensorflow.service';
import axios from 'axios';
import { ObjectDetection } from '@tensorflow-models/coco-ssd';


@Component({
  selector: 'app-videocreator',
  templateUrl: './videocreator.component.html',
  styleUrls: ['./videocreator.component.scss']
})

export class VideocreatorComponent implements OnInit, ComponentCanDeactivate {
  public animationarray = []; //array with style and position settings;

  @ViewChild(ThreedComponent, { static: false })
  private threedComponent: ThreedComponent;
  @ViewChild('progressbar') progressbar: ElementRef;
  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;
  @Output() newItemEvent = new EventEmitter<Files>();

  planes = [
    'none',
    'flat',
    'rough',
    'mountain',
    'water',
    'stars'
  ]

  videoPlayer: HTMLVideoElement;
  bufferValue: number;
  totalValue: number;
  stripewith: any;
  splitscreen: boolean;
  sideopen: boolean;
  selectedanimation: any;
  drawing: boolean = false;
  selectedelementindex: any;
  selectanimationindex: any;
  temptween: GSAPTimeline;
  tween: any;
  playing: boolean = false;
  versionsaved: boolean = true;
  screenshottoggle: boolean = false;




  @ViewChild('videoPlayer')
  set mainVideoEl(el: ElementRef) {
    if (el !== undefined) {
      this.videoPlayer = el.nativeElement;
    }
  }
  public speedDialFabButtons = this.SpeeddialService.speedDialFabButtons;
  public Forms: Forms[];
  public hasBaseDropZoneOver = false;
  public uploaderContent: BehaviorSubject<string> = new BehaviorSubject('Drop Excel File Here, must included "Text and Translation header"');
  public videourl: string;
  public zoomfactor = 1;
  public selectedvideoformat: string;
  //public editfigure = false;
  public closewhiteboard = false;
  public standardpath = '';
  public edittext = false;
  public pathHelper: MotionPathHelper;
  public pathEditor: PathEditor;
  public snaptogrid = false;
  public snaptogridwidth = 20;
  public snaptogridheight = 20;
  public colorpick = 'white';
  public colorpickline = 'black';
  public linewidth = 2;
  public shapedraw = '';
  public vectorcombiedit = false;
  public whiteboard = false;
  public whiteboardcolor = "#000";
  public whiteboardfillcolor = '#000';
  public whiteboardstokewidth = 2;
  public whiteboardsmoothing = 8;
  public cropimages = false;
  public t; // actual counter
  public counter = 10;
  public currenttime = 0;

  public play = false;
  public primairytimeline: GSAPTimeline = gsap.timeline({ paused: true, reversed: true }); //gsap timeline control
  public listviewxsshow = false;
  public showprogressbar = false;
  public uploader: FileUploader;
  public newFiles: Files = new Files();
  public changenow = true;
  public changevideo = true;
  public shiftX = 0;
  public shiftY = 0;
  public editablevideo: Files;
  public editablevideos: Files[];
  public canvas = factoryCanvas();
  public globalThreedControls = factoryGlobalThreedControls();
  public moveitem = false;
  public selectedImage: imageanimation;
  public showemoji = false;
  public newz = this.vectorService.newz;
  public inBounds = true;
  public edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  public Fonts = fonts;
  private watcher: Subscription;
  public activeMediaQuery;
  public selectedelement;
  public name;
  public setreplay = false;
  public editpath = false;
  public standardvector;
  public dragAreaOverlay;
  public snippetcode: string;
  public systembusy = false;
  public boxshadow = false;
  public generalsettingsComponent: GeneralsettingsComponent;
  public selectableanimationarray = [];
  public selectablevectorarray = [];
  private routeSub: any;
  public zoomForm = new UntypedFormControl();
  private colorarray = ['greycolors', 'bluecolors', 'redcolors', 'greencolors', 'pinkcolors',
    'yellowcolors', 'azurecolors', 'purplecolors', 'darkbluecolors'];
  public scales = {
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,0.3)',
        },
        ticks: {
          fontColor: 'black',
          fontFamily: 'Open Sans',
          fontSize: 12,
          suggestedMin: 0,
          suggestedMax: 100,
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,0.3)',
        },
        ticks: {
          fontColor: 'black',
          fontFamily: 'Open Sans',
          fontSize: 12,
          suggestedMin: 0,
          suggestedMax: 100,
        }
      }
    ]
  }
  public prethemes = ['particles', 'linebox', 'lightbulbs', 'none']

  hoveractions = [
    { value: 'scale-class', name: "Scale" },
    { value: 'rotate-class', name: "Rotate" }
  ]
  @Input() debounceTime = 30;
  @Output() debounceKey = new EventEmitter();
  public keyinput = new Subject();
  public subscription: Subscription;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.animationarray.length === 0 || this.versionsaved) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private tensorflowService: TensorflowService,
    private appservice: AppserviceService,
    private embeddedpixi: EmbeddedpixiService,
    private movePathHelperService: MovePathHelperService,
    private draggableService: DraggableService,
    public codegeneratorService: CodegeneratorService,
    public vectorService: VectorService,
    private backgroundeffectsService: BackgroundeffectsService,
    private patheditorService: PatheditorService,
    private audioService: AudioService,
    private easetypesService: EasetypesService,
    private animationsService: AnimationsService,
    private SavehistoryService: SavehistoryService,
    private SpeeddialService: SpeeddialService,
    private colorset: colorset,
    private sanitizer: DomSanitizer,
    public codesnippetService: codesnippetService,
    public dialog: MatDialog,
    private relationsApi: RelationsApi,
    private filesApi: FilesApi,
    public snackBar: MatSnackBar,
    public media: MediaObserver,
    public dialogsService: DialogsService,
    public http: HttpClient,
    public router: Router
  ) {
    // this.watcher = media.media$.subscribe((change: MediaChange) => {
    //   this.activeMediaQuery = change;
    // });

    this.subscription = this.keyinput
      .pipe(debounceTime(this.debounceTime))
      .subscribe(async e => {
        // if space hit play
        // console.log(e)
        if (e['keyCode'] == 32) {
          if (this.playing) {
            this.pauseFunc();
          } else {
            this.playFunc();
          }
        } else {
          let newani = await this.SavehistoryService.KeyPress(e, this.whiteboard, this.editpath);
          if (newani) {
            this.animationarray = newani;
            this.detectchange();
            // add timeout?? 
            // this.changenow = false;
            // await new Promise(resolve => setTimeout(resolve, 10));
            // this.changenow = true;
          }
        }
        // this.keyinput.next(e);
      });
  }

  setScroll(){
    setTimeout(() => { 
      document.getElementById("scrollcontainer").scrollTo(1300, 1600);
      }, 500);
  }

  ngOnInit() {

    this.setScroll();
    this.versionsaved = this.SavehistoryService.versionsaved;
    this.SavehistoryService.animationarray = this.animationarray;
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.animationarray.length > 0) {
          this.dialogsService
            .confirm('Save data?', 'Any unsaved data will be lost')
            .subscribe(res => {
              this.routeSub.unsubscribe();
              if (res) {
                if (this.newFiles.id) {
                  this.saveVideo();
                } else {
                  this.saveVideoAs();
                }
              }
            });
        }
      }
    });
  }

  scrollTo

  /* get keyboard input  */
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.keyinput.next(event);
  }

  /* Retrieve one step history  */
  // turn off for memory usage
  @HostListener('mouseup', ['$event'])
  clickEvent(event) {
    // console.log('mouse up')
    this.SavehistoryService.clicks.next(event);

  }

  async setSelectedAnimation(vectoranimation: vectoranimationtype, i) {
    if (vectoranimation.svganimationtype === 'changepath') {
      this.vectorService.selectPathsFromAnimation(vectoranimation, this.selectedelement)
    }
    if (this.selectedanimation === vectoranimation) { return }
    if (this.editpath) { await this.saveNewMotionPath(); }

    this.selectedanimation = vectoranimation;
    this.selectanimationindex = i;
  }



  changevideoformat() {
    let setvideo = this.selectedvideoformat.split(' x ');
    this.canvas.width = setvideo[0];
    this.canvas.height = setvideo[1];
    this.onchangecanvas();
  }

  // turn off PathEditor.js line 91 deselect 
  editMotionPath(animation, i) {
    this.selectedanimation = animation;
    this.selectanimationindex = i;
    this.zoomfactor = 1;
    this.editpath = true;

    this.setMotionPath(this.selectedelement.id, this.selectedelement, animation);
  }

  editThreedMotionPath(animation, i) {
    this.selectedanimation = animation;
    this.selectanimationindex = i;
    this.zoomfactor = 1;
    this.editpath = true;
    this.movePathHelperService.editThreedMotionPath(animation, i, this.selectedelement.id);
    this.threedComponent.fixCameraZX();
  }

  async createYmotionPath() {
    this.selectedanimation = await this.movePathHelperService.createYmotionPath(this.selectedanimation);
  }



  setEditText() {
    this.edittext = true;
    this.detectchange();
    //console.log('set edit text');
  }

  setEditTextClose() {
    this.edittext = false;
  }

  async deSelectAll() {
    this.stopFunc();
    await new Promise(resolve => setTimeout(resolve, 400));
    this.edittext = false;
    this.vectorService.setDragSelect(this.selectedelement, false);
    //this.vectorService.removeVectorPathSelection(this.selectedelement);
    this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    this.detectchange();
  }


  // !On select determine action
  // editpath true animation return  (motion, minions, etc)
  // clickVectorPaths --> check for vector problems
  // 

  // console.log(
  //   this.cropimages,
  //   this.editpath,
  //   this.vectorcombiedit,
  //   element.type,
  //   this.editfigure,
  //   this.vectorService.draggableObject
  // )

  onSelectElement(event, element, i): void {


    if (element === this.selectedelement) { return } // end function if same object

    this.edittext = false; // close open text box

    if (this.editpath === true) { return } // this.saveNewMotionPath(); 

    // reset path editing if necessary
    this.vectorService.rotatepath = false;
    this.vectorService.editfigure = false;

    if (this.cropimages) { this.imageSaveCropPath() }

    if (this.selectedelement && this.selectedelement.type === 'whiteboard') {
      this.savewhiteboard();
    }
    // prepare selection 
    // if (this.vectorService.editsvg && this.draggableService.draggableObject) {
    //   this.draggableService.cancelDragSelect();
    // }
    if (element.type === 'vectorcombi') {
      this.selectableanimationarray = this.animationarray.filter(item => item.type !== 'vectorcombi')
    }
    if (element.type === 'vector') {
      this.selectablevectorarray = this.animationarray.filter(item => item.type === 'vector');

    }
    if (this.vectorService.editsvg) { this.vectorService.editsvg = false }

    if (!this.selectedelement) { this.selectedelement = element }

    //this.vectorService.removeVectorPathSelection(this.selectedelement); //include save svg
    this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    this.edittext = false;
    this.vectorService.selectedVecPath = '';
    // this.vectorService.setDragSelect(false);
    if (element.style['box-shadow'] === '') { this.boxshadow = false } else { this.boxshadow = true }
    if (element.style['filter'] === '') { this.boxshadow = false } else { this.boxshadow = true }

    this.selectedelement = element;
    this.selectedelementindex = i;
    // if (this.editpath === false && this.selectedelement.id !== element.id) {
    //   if (this.selectedelement.type === 'vector' && this.selectedelement.svgcombi !== '') {
    //     this.vectorService.removeVectorPathSelection(this.selectedelement);
    //     this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    //   }
    //   if (this.whiteboard) { this.deletewhiteboard() }
    //   this.edittext = false;
    //   this.vectorService.selectedVecPath = '';
    //   this.vectorService.setDragSelect(false);
    //   if (element.style['box-shadow'] === '') { this.boxshadow = false } else { this.boxshadow = true }
    //   this.selectedelement = element;
    // }
  }


  // enableDraggable() {
  //   let el = document.getElementById(this.selectedelement.id) as unknown
  //   let element = el as Draggable;
  //   if (typeof element.disable === 'function') {
  //     element.enable('soft');
  //   }
  // }

  // disableDraggable() {
  //   let el = document.getElementById(this.selectedelement.id) as unknown
  //   if (el) {
  //     let element = el as Draggable;
  //     if (typeof element.disable === 'function') {
  //       element.disable('soft');
  //     }
  //   }
  // }

  setGlobalThreedControls(e) {
    // console.log(e)
    this.globalThreedControls = e;
  }

  setCanvas(e) {
    this.canvas = e;
  }

  setSelectedElement(e) {
    this.selectedelement = e;
  }

  setDraggable(event, idel) {
    // if (idel === this.selectedelement) { return } 
    if (this.vectorService.editsvg) { return; }
    // console.log('draggable', event, idel)
    // Draggable does not recognise ts angular so changes are direct dom js related
    // drag selectionbox should be off and vectorcombinator should be off
    let element = document.getElementById(idel.id);

    if (this.vectorService.rotatepath && event.path) {
      //console.log(event.path)
      this.vectorService.setRotatePath(event, idel, event.path[0]);
      return
    }

    if (this.vectorService.editsvg) { return }
    if (this.vectorService.dragselectvectpath) { return }
    if (this.cropimages) { return }

    // if (!this.vectorcombiedit) { return }
    if (idel.groupmember) { return }

    // quit if threed drag or camer drag is activated
    if (idel.type === 'threed') {
      //if (idel.cameracontrol || idel.dragcontrol || idel.dragcontrolgroup) {
      return
      //}
    }
    if (this.editpath) { // Motionpath?
      // this.editMotionPath(this.selectedanimation);
    }

    this.setMovable(event, idel, element);

    // if (!this.vectorService.dragselectvectpath && !this.vectorcombiedit && !idel.groupmember && !this.cropimages) {
    //   this.setMovable(event, idel);
    // } else { this.disableDraggable(); }

    // if (!this.vectorService.dragselectvectpath && this.vectorcombiedit && idel.groupmember) {
    //   this.setMovable(event, idel);
    // }

  }

  setMovable(event, idel, element) {
    // set drag and move and snapto grid on mousedown
    //     console.log('set draggable')

    if (this.vectorService.editsvg) { return }

    if (this.draggableService.draggableObject) {
      this.draggableService.draggableObject = undefined;
    }


    let snapw = this.snaptogridwidth;
    let snaph = this.snaptogridheight;
    let dragfunc;
    let throwfunc;

    // set grid snap
    let snap = {
      x: (value: any) => {
        return Math.round(value / snapw) * snapw;
      },
      y: (value: any) => {
        return Math.round(value / snaph) * snaph;
      }
    }

    // if snap is turned on
    if (!this.snaptogrid) {
      snap = undefined;
      dragfunc = this.setMoveableItem;
      throwfunc = undefined;
    } else {
      dragfunc = undefined;
      throwfunc = this.setMoveableItem;
    }

    if (event.target.id === idel.id + 'rotatehandle') {

      this.setRotate(event, idel)
    } else {
      this.draggableService.draggableObject = new Draggable(element, {
        type: "x,y",
        snap: snap,
        onThrowCompleteParams: [idel, snapw, snaph],
        onThrowComplete: throwfunc,
        onDragEnd: dragfunc,
        onDragEndParams: [idel, snapw, snaph],
        inertia: this.snaptogrid,
        edgeResistance: 0.95,
        allowEventDefault: false
      });
    }


  }

  setRotate(event, idel, node?) {
    // console.log('roate path', event, idel, node);
    let el;
    if (node) { el = node } else {
      el = document.getElementById(idel.id) as unknown
    }
    let element = el as Draggable;
    //Draggable.create(element, {
    this.draggableService.draggableObject = new Draggable(element, {
      type: "rotation",
      onDragEndParams: [idel],
      onDragEnd:
        (idl) => {
          idl.rotation = this.draggableService.draggableObject.rotation;
          this.draggableService.cancelDragSelect();
        }
    })
  }



  setForm(form) {
    //console.log(form);
    this.selectedelement.formId = form.value.id
    this.selectedelement.style.width = form.value.width + form.value.wform;
    this.selectedelement.style.height = form.value.height + form.value.wform;
    this.selectedelement.formsrc = 'https://dlcr.enydea.com/formobject?id=' + this.selectedelement.formId;
  }

  setBoxShadow() {
    if (this.boxshadow) {
      //this.selectedelement.style['box-shadow'] = ' 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)';
      this.selectedelement.style['filter'] = 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));';
    } else {
      this.selectedelement.style['box-shadow'] = '';
      this.selectedelement.style['filter'] = '';
    }
    this.detectchange();
  }

  async setMotionPath(id, element, animation) {
    //console.log('set motionpath')
    //for (let i = 0; i < element.motionpath.length; i++) {
    let i = this.selectanimationindex; //this.selectedelementindex;
    // console.log(i)
    let idmotion = element.id + '-' + i + 'p';
    this.primairytimeline = gsap.timeline({ paused: true, reversed: true });
    let svgpath = document.getElementById(idmotion) as unknown;
    if (!svgpath) { console.log('motionpath not found: ' + idmotion); return }
    let svgpath2 = svgpath as SVGPathElement;
    const docset = document.getElementById(id);
    let ease = this.easetypesService.selectEaseType(animation.easetype);
    let orgin = animation.transformOriginX + ' ' + animation.transformOriginY;

    let rotate;
    if (!animation.rotationkeeppos) {
      gsap.set(docset, {
        xPercent: animation.travellocX, yPercent: animation.travellocY,
        transformOrigin: orgin, autoAlpha: 1
      }); // tranformorgin to set offset??
      rotate = animation.rotationcycle
    } else {
      rotate = false;
      gsap.set(docset, {
        xPercent: animation.travellocX, yPercent: animation.travellocY, autoAlpha: 1,
      });
    }

    let to: GSAPTweenVars = {
      duration: animation.duration,
      ease: ease,
      repeat: animation.repeat,
      repeatDelay: animation.delay,
      yoyo: animation.yoyo,
      motionPath: {
        path: svgpath2,
        //align: svgpath,  // do not use self
        autoRotate: rotate,
      }
    }

    if (this.tween) { this.tween.kill(); }
    // change this.primairytimeline. to gsap.
    this.tween = gsap.to(docset, to);


    if (element.type !== 'threed') {
      this.pathHelper = MotionPathHelper.create(docset);
    } else {
      this.pathEditor = undefined;
      this.pathEditor = PathEditor.create(svgpath, {
        handleSize: 2,
        selected: true,
        draggable: true
      });
    }
  }

  async saveNewThreedMotionPath() {
    this.editpath = false;
    let id = this.selectedelement.id;
    this.movePathHelperService.saveNewThreedMotionPath(id, this.canvas, this.selectedelement, this.selectanimationindex);
  }

  async saveNewMotionPath() {
    // console.log('save motion path')
    // delete copy motion path button --> standard gsap edit see plugin
    if (!this.selectedelement) { return; }

    const collection = document.getElementsByClassName('path-editor-selection');
    let svgpath = collection[(collection.length - 1)]
    let newmatrix = this.getSizeMatrix(svgpath);
    let id = this.selectedelement.id;
    // let newpath: string = this.pathHelper.getString();//
    let stringpath: string = this.pathHelper.getString();//
    //   let stringpath: string = this.pathEditor.getString();//
    let newpath: string = MotionPathPlugin.stringToRawPath(stringpath);
    if (newmatrix.length > 2) {
      newpath = MotionPathPlugin.transformRawPath(newpath, newmatrix[0], newmatrix[1], newmatrix[2], newmatrix[3], newmatrix[4], newmatrix[5]);
    }
    let finalpath = MotionPathPlugin.rawPathToString(newpath);
    //  for (let i = 0; i < this.selectedelement.animation.length; i++) {
    //let animation = this.selectedelement.animation[];
    //  if (animation === this.selectedanimation) {


    if (!finalpath) { console.log('no path'); return; }
    await this.saveMotionPath(finalpath, id, this.selectanimationindex);
    this.editpath = false;
    this.primairytimeline = gsap.timeline({ paused: true, reversed: true });
    this.vectorService.removePathEditor();
    this.stopFunc();
    this.selectedanimation = undefined;
    // this.detectchange(); // remove as it is also fired from detectchange?? 
  }

  async saveMotionPath(newpath, id, i) {
    let newid = id + '-' + i;

    let w = this.canvas.width.replace('px', '');
    let h = this.canvas.height.replace('px', '');
    let newview = '0 0 ' + w + ' ' + h;
    let newsvgpath = '<svg id="' + newid + 'mp" viewBox="' + newview +
      '" class="path-edit"><path id="' + newid + 'p" style="opacity: 0; " d="' + newpath + '" /></svg>';
    this.selectedelement.motionpath[i] = newsvgpath;
  }


  getSizeMatrix(svgpath) {

    let transf = svgpath.getAttribute('transform'); // there is transform on the element we need remove it
    let style = transf;

    style = style.replace('matrix(', '');
    style = style.replace('matrix(', '');
    style = style.replace('translate(', '');
    style = style.replace(')', '');
    style = style.replace(/,/g, ' ');

    // just skip but needs fixing to matrix!!! 
    if (style.indexOf('rotate') > 0) {
      return
    }
    // console.log(style)
    let newmatrix = style.split(' ').map(Number);
    return newmatrix;
  }

  enlargePath() {
    const collection = document.getElementsByClassName('path-editor-selection');
    let svgpath = collection[(collection.length - 1)]
    let m = this.getSizeMatrix(svgpath);
    if (m.length === 2) { // translate has only 2 values but is normally empty..
      m = [1, 0, 0, 1, 0, 0]
    }
    m[0] = m[0] + 0.1;
    m[3] = m[3] + 0.1;

    let scale = m[0] - 1;
    // compensate location for reduce size ()
    let w = collection[0].getBoundingClientRect().x - collection[0].getBoundingClientRect().width;
    let h = collection[0].getBoundingClientRect().y - collection[0].getBoundingClientRect().height;
    m[4] = w * scale; // + 'px';
    m[5] = h * scale; // + 'px';

    svgpath.setAttribute('transform', `matrix(${m[0]} ${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]})`);
  }

  reduceSizePath() {
    const collection = document.getElementsByClassName('path-editor-selection');
    let svgpath = collection[(collection.length - 1)]
    let m = this.getSizeMatrix(svgpath);
    if (m.length === 2) {
      m = [1, 0, 0, 1, 0, 0]
    }
    m[0] = m[0] - 0.1;
    m[3] = m[3] - 0.1;

    let scale = m[0] - 1;
    let w = collection[0].getBoundingClientRect().x - collection[0].getBoundingClientRect().width;
    let h = collection[0].getBoundingClientRect().y - collection[0].getBoundingClientRect().height;
    m[4] = w * scale; // + 'px';
    m[5] = h * scale; // + 'px';

    svgpath.setAttribute('transform', `matrix(${m[0]} ${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]})`)

  }


  async resetPath(animation, i) {
    this.stopFunc();
    this.pathEditor = undefined;
    this.pathHelper = undefined;
    this.vectorService.removePathEditor();
    let newid = this.selectedelement.id + '-' + this.selectanimationindex;
    let h = parseInt(this.canvas.height, 10);
    let w = parseInt(this.canvas.width, 10);
    let pathset = document.getElementById(newid + 'p')

    if (!pathset) { console.log('missing pathset'); return; }
    //console.log(pathset, animation)

    switch (this.standardpath) {
      case 'vertical-line': {
        let editpath = 'M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457 ';
        this.selectedelement.clippath = editpath; // clip
        pathset.setAttribute('d', editpath);
        break
      }
      case 'horizontal-line': {
        let editpath = 'M281.076,267.563 C281.076,267.563 665.6,267.603 750.263,267.708 ';
        this.selectedelement.clippath = editpath; // clip
        pathset.setAttribute('d', editpath);
        break
      }
      case 'circle': {
        let editpath = 'M790,507 C790,663.29658 663.29658,790 507,790 350.70342,790 224,663.29658 224,507 224,350.70342 350.70342,224 507,224 663.29658,224 790,350.70342 790,507 z'
        this.selectedelement.clippath = editpath; // clip
        pathset.setAttribute('d', editpath);
        break
      }
      case 'square': {
        let editpath = 'M 10,10 L' + (w - 50) + ',10 L' + (w - 50) + ',' + (h - 50) + ' L10,' + (h - 50) + ' z';;
        this.selectedelement.clippath = editpath;
        pathset.setAttribute('d', editpath);
        break
      }
    }
    // let newpath = pathset.getAttribute('d')
    // console.log(newpath)
    // this.setNewMotionPath(newpath)
    // await new Promise(resolve => setTimeout(resolve, 400));
    // this.saveNewMotionPath();
    this.editMotionPath(animation, this.selectanimationindex);
  }

  setAudio(event, animation) {
    //console.log('audio file', event, animation)
    animation.audioeffectsrc = event;
    // delete this.onchangeaudio();
  }

  detectMorph(value) {
    //console.log(value, this.selectedanimation)
    if (value === 'morph') {
      this.selectedelement.morph = true;
    } else {
      this.selectedelement.morph = false;
      this.selectedelement.vectors.splice(1, 1);
      // this.detectchange();
    }
    if (value === 'liquid') {
      this.selectedanimation.drawleft = '-100';
      this.selectedanimation.drawright = '-200';
      this.selectedanimation.fillright = '0';
      this.selectedanimation.fillleft = '150';
    }
    if (value === 'draw') {
      this.selectedanimation.drawleft = '0%';
      this.selectedanimation.drawright = '0%';
      this.selectedanimation.fillright = '100%';
      this.selectedanimation.fillleft = '0%';
    }

  }

  // set items that do not need edit settings 
  async setAfterEdit() {
    for (let i = 0; i < this.animationarray.length; i++) {
      const elm = this.animationarray[i];
      if (elm.type === 'vector') { //vector animation
        for (let i2 = 0; i2 < elm.vectoranimation.length; i2++) {
          const vecani = elm.vectoranimation[i2];
          //   if (vecani.svganimationtype === 'changepath') { this.vectorService.createChangePathVectorHide(elm, vecani, this.primairytimeline) }
        }
      }
    }
  }

  // show hidden paths for editing
  async setBeforeEdit() {
    for (let i = 0; i < this.animationarray.length; i++) {
      const elm = this.animationarray[i];
      if (elm.type === 'vector') { //vector animation
        for (let i2 = 0; i2 < elm.vectoranimation.length; i2++) {
          const vecani = elm.vectoranimation[i2];
          if (vecani.svganimationtype === 'changepath') { this.vectorService.createChangePathVectorShow(elm, vecani, this.primairytimeline) }
        }
      }
    }
  }

  // render components
  async detectchange() {

    //  possible fix for the webgl context lost error => multiple webgl three.js + pixijs cause 
    let canv = document.getElementsByTagName('canvas');
    for (let i = 0; i < canv.length; i++) {
      let canvas = canv[i];
      canvas.parentNode.removeChild(canv[i])
    }
    this.embeddedpixi.app = null;


    console.log(this.animationarray)

    this.stopFunc();
    this.systembusy = true;
    await new Promise(resolve => setTimeout(resolve, 100)); // wait to propergate all settings


    if (this.selectedelement && this.selectedelement.type === 'vector' && this.editpath) {
      // this.vectorService.saveSVG(this.selectedelement);
      this.vectorService.saveSVG(this.selectedelement);
      this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    }
    if (this.editpath === true) {
      this.saveNewMotionPath();
    }
    if (this.whiteboard) { this.deletewhiteboard() }

    // reset timeline
    this.primairytimeline = null; // remove just to make sure all references are gone
    this.primairytimeline = gsap.timeline({ paused: true, reversed: true });

    // force dom update
    this.changenow = false;
    await new Promise(resolve => setTimeout(resolve, 10));
    this.changenow = true;
    // wait for dom update to finish otherwise it will create the effects on the old dom
    // detectchange angular also should work but did not get it to work properly 
    await new Promise(resolve => setTimeout(resolve, 100));

    if (this.canvas.weather !== '') { await this.addWeatherEffect() };

    // Create specific animations and set positions
    for (let i = 0; i < this.animationarray.length; i++) {
      const elm = this.animationarray[i];
      await this.setPosition(elm);

      if (elm.type === 'chart') {
        await this.setChartType(elm);
      }

      if (elm.type === 'embeddedvideo') {
        this.setEmbeddedVideo(elm);
      }

      if (elm.type === 'vector') { //vector animation
        for (let i2 = 0; i2 < elm.vectoranimation.length; i2++) {
          const vecani = elm.vectoranimation[i2];
          if (vecani.svganimationtype === 'draw') { this.vectorService.drawVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'liquid') { this.vectorService.createWaveVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'color') { this.vectorService.createColorVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'changepath') { this.vectorService.createChangePathVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'morph') {
            if (elm.vectors.length > 1) { await this.createMorph(elm, vecani) }
          }
        }
      }

      if (elm.type === 'counter') {
        await this.createCounter(elm,);
      }

      if (elm.type === 'text') {
        for (let i3 = 0; i3 < elm.splittextanimation.length; i3++) {
          const textani: splittexttype = elm.splittextanimation[i3];
          if (textani.textanimationtype) { await this.createSplitText(elm, textani) }
        }
      }

      await this.addEffect(elm); //add generic animations
    }
    this.systembusy = false;

    this.setGrid();
  }

  async deleteSelectedVectorPath(selectedelement) {
    this.stopFunc();
    await new Promise(resolve => setTimeout(resolve, 400));
    this.vectorService.deleteSelectedVectorPath(selectedelement);
  }

  async createPathAnimation(selectedelement) {
    this.stopFunc();
    await new Promise(resolve => setTimeout(resolve, 400));
    this.vectorService.createPathAnimation(selectedelement)
  }

  async saveFigurePath(selectedelement) {
    this.stopFunc();
    await new Promise(resolve => setTimeout(resolve, 400));
    this.vectorService.saveFigurePath(selectedelement)
  }


  async createCounter(elm: counteranimation) {
    let start_time = elm.countertype[0].start_time;
    for (let i = 0; i < elm.countertype.length; i++) {
      let countertype = elm.countertype[i];
      let doc = document.getElementById(elm.id + 'svgcircle' + i);
      //console.log(doc)
      this.vectorService.setDrawAni(doc, countertype, this.primairytimeline);
      if (countertype.start_time < start_time) {
        start_time = countertype.start_time
      }
    }

    const countUp = new CountUp(elm.id + 'num', elm.maxcount, elm);
    this.primairytimeline.call(this.startCounter, [countUp, elm.id, elm.maxcount], start_time);
  }

  async startCounter(countUp: CountUp, id, count) {
    countUp.start();
  }

  changeChartType() {
    let elm: chart = this.selectedelement;
    // save colorset
    if (elm.colors[0].borderColor) {

      elm.savecolorset = [];
      elm.colors.forEach(element => {
        elm.savecolorset.push(element);
      });
    }
    // change color scheme
    // if (elm.charttype === 'pie' || elm.charttype === 'doughnut' || elm.charttype === 'polarArea') {
    elm.colors = [];

    let colorrandom = { backgroundColor: [] };
    elm.label.forEach(label => {
      let randomnum: number = this.RandomInt(0, 8);
      colorrandom.backgroundColor.push(this.colorset.setColor(this.colorarray[randomnum]).backgroundColor);
    });
    elm.originaldata.forEach(section => {
      elm.colors.push(colorrandom);
    });
    elm.lineChartOptions.scales = undefined;
    // } else {
    // set back if
    // if (elm.lineChartOptions.scales === undefined) {
    //   elm.lineChartOptions.scales = this.scales
    // }
    // elm.colors = [];
    // if (elm.originaldata.length > elm.savecolorset.length) {
    //   elm.originaldata.forEach(element => {
    //     let randomnum: number = this.RandomInt(0, 8);
    //     elm.colors.push(this.colorset.setColor(this.colorarray[randomnum]));
    //   });
    // } else {
    //   elm.savecolorset.forEach(element => {
    //     elm.colors.push(element);
    //   });
    // }

    //}
    //console.log(elm)
    this.detectchange();
  }



  async setEmbeddedVideo(elm: EmbeddedVideo) {

    let videodoc = document.getElementById(elm.id);
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    //console.log(video)

    if (elm.start_time === undefined) { elm.start_time = 0 }
    this.primairytimeline.call(this.playEmbeddedVideo, [elm.id], elm.start_time);

    elm.duration = this.counter;
    if (video && video.duration) {
      elm.duration = video.duration;
      this.primairytimeline.call(this.stopEmbeddedVideo, [elm.id], elm.duration);
    }

    if (elm.effect) {
      this.embeddedpixi.setPixi(elm, this.primairytimeline);
    }

  }

  stopAllEmbeddedVideo() {
    this.animationarray.forEach((element, i) => {
      if (element.type === 'embeddedvideo') {
        this.stopEmbeddedVideo(element.id);

      }
    });
  }

  stopEmbeddedVideo(id, reset?) {
    let videodoc = document.getElementById(id);
    if (!videodoc) { return }
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    if (videodoc === undefined) { console.error('missing video html element') }
    video.pause();
  }

  playEmbeddedVideo(id) {
    let videodoc = document.getElementById(id);
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    if (videodoc === undefined) { console.error('missing video html element') }
    video.play();
  }

  async setChartType(elm) {
    elm.productiondata = [];
    elm.data = [];
    for (let k = 0; k < elm.originaldata.length; k++) {
      //console.log(elm.originaldata[k])
      let productiondataset = { data: [], label: elm.originaldata[k].label };
      let newdataset = { data: [], label: elm.originaldata[k].label };
      elm.originaldata[k].data.forEach(element => {
        productiondataset.data.push(0);
        newdataset.data.push(element);
      });
      elm.productiondata.push(productiondataset);
      elm.data.push(newdataset);
    }
    if (elm.start_time === undefined) { elm.start_time = 0 }
    this.primairytimeline.call(this.setChartData, [elm], elm.start_time);
    //console.log(elm)

  }

  setChartData(elm) {

    // data later to animate

    //elm.productiondata = Object.assign({}, elm.data);
    //if (elm.charttype === 'line' || elm.charttype === 'bar') {
    elm.productiondata = [];
    for (let k = 0; k < elm.data.length; k++) {
      let productiondataset = { data: [], label: elm.data[k].label };
      elm.data[k].data.forEach(element => {
        productiondataset.data.push(element);
      });
      elm.productiondata.push(productiondataset);
    }

    //}
    // if (elm.charttype === 'doughnut') {
    //   elm.productiondata = elm.data;

    //   // elm.productiondata = [];
    //   // for (let k = 0; k < elm.data.length; k++) {
    //   //   let productiondataset = [];
    //   //   elm.data[k].forEach(element => {
    //   //     productiondataset.push(element);
    //   //   });
    //   //   elm.productiondata.push(productiondataset);
    //   // }
    // }
  }

  async createSplitText(elm: textanimation, textani: splittexttype) {
    let splittextwhere = textani.textanimationtype;
    let id = document.getElementById(elm.id);
    let splitText = new SplitText(id, { type: textani.textanimationtype })
    let toset = {
      y: 100,
      autoAlpha: 0
    }
    let char = splitText.chars;
    let word = splitText.words;
    let line = splitText.lines;
    let setto;
    let lengtharr;
    if (textani.textanimationtype === 'chars') { setto = char; lengtharr = char.length }
    if (textani.textanimationtype === 'words') { setto = word; lengtharr = word.length }
    if (textani.textanimationtype === 'lines') { setto = line; lengtharr = line.length }
    let dura = textani.duration / lengtharr;
    // stagger durationis for each stag (word line character etc.. )

    let ease = this.easetypesService.selectEaseType(textani.easetype);
    if (textani.fromto === 'from') {
      this.primairytimeline.from(setto,
        {
          duration: textani.duration, x: textani.y, y: textani.x, rotationX: textani.rotationX, rotationY: textani.rotationY, autoAlpha: 0, ease: ease, stagger: 0.1,
          delay: textani.start_time, repeatDelay: textani.delay
        }, 0)
    }
    if (textani.fromto === 'to') {
      this.primairytimeline.to(setto, {
        duration: textani.duration, x: textani.y, y: textani.x, rotationX: textani.rotationX, rotationY: textani.rotationY, autoAlpha: 0, ease: ease, stagger: 0.1,
        delay: textani.start_time, repeatDelay: textani.delay
      }, 0)
    }
  }

  onchangecanvas() {
    if (this.canvas.videourl) { this.canvas['background-color'] = 'transparent' }
    this.animationarray.forEach(element => {
      // let w = this.canvas.width.replace('px', '');
      // let h = this.canvas.height.replace('px', '');
      // let newview = '0 0 ' + w + ' ' + h;
      // let regex = /viewBox="(.*?)"/;
      // let strToMatch = element.motionpath;
      // let matched = regex.exec(strToMatch);
      // element.motionpath = this.vectorService.createMotionPath(element.id, this.canvas);
      //element.motionpath.replace(matched[1], newview);
      //console.log(element);
    });


    if (this.zoomfactor !== 1) {
      // let element = document.getElementById('containernormal');
      // let rect = element.getBoundingClientRect();
      let scale = this.zoomfactor - 1;
      let w = parseInt(this.canvas.width) / 2;
      let h = parseInt(this.canvas.height) / 2;
      this.canvas.left = w * scale + 'px';
      this.canvas.top = h * scale + 'px';
    }

    if (this.zoomfactor === 1) {
      this.canvas.left = '0px';
      this.canvas.top = '0px';
    }


    if (this.canvas.bgstyle !== '') {
      this.setBgCanvas();
    } else {
      //console.log('set background no style', this.canvas)
      this.canvas['border-radius'] = '';
      this.canvas['background-image-gradient'] = '';
      this.canvas['background-image'] = '';
      this.canvas['background'] = this.canvas['background-color'];
    }

    this.changevideo = false;
    setTimeout(() => this.changevideo = true);

    this.setGrid();

  }

  async setGrid() {
    //await this.removeGrid();
    const myNode = document.getElementById("snapgrid");
    myNode.innerHTML = '';

    if (this.snaptogrid) {
      let gridHeight = this.snaptogridheight;
      let gridWidth = this.snaptogridwidth;
      let gridColumns = parseInt(this.canvas.width, 10) / gridWidth;
      let gridRows = parseInt(this.canvas.height, 10) / gridHeight;

      let docset = document.getElementById('snapgrid');
      //console.log('set grid', docset, gridRows, gridColumns)
      for (let i = 0; i < gridRows * gridColumns; i++) {
        let y = Math.floor(i / gridColumns) * gridHeight;
        let x = (i * gridWidth) % (gridColumns * gridWidth);
        let divi = document.createElement("div");
        divi.className = 'gridcells';
        divi.id = 'gridcell' + i;
        divi.style.width = (gridWidth - 1) + 'px';
        divi.style.height = (gridHeight - 1) + 'px';
        // divi.style.top = y + 'px';
        // divi.style.left = x + 'px';
        docset.appendChild(divi);
      }
    }
  }

  setBgCanvas() {
    //console.log('set background', this.canvas)
    this.canvas['border-radius'] = '';
    this.canvas['background-image-gradient'] = '';
    this.canvas['background-image'] = '';
    this.canvas['background'] = '';
    //this.canvas.overflow = '';


    if (this.canvas.bgstyle === 'stripes') {
      this.canvas['background'] = `repeating-linear-gradient(
        ${this.canvas.direction}deg,
        ${this.canvas['background-color']},
        ${this.canvas['background-color']} ${this.canvas.stripewidth}px,
        transparent ${this.canvas.stripewidth}px,
        transparent ${this.canvas.stripewidth * 2}px
      )`;
      //this.canvas.overflow = 'hidden';
    }

    if (this.canvas.bgstyle === 'gradient') {
      this.canvas['background'] = `linear-gradient(${this.canvas.direction}deg, 
        ${this.canvas.color1} ${this.canvas.percentagecolor1}%, 
        ${this.canvas.color2} ${this.canvas.percentagecolor2}%, 
        ${this.canvas['background-color']} ${this.canvas.percentagecolor3}%)`;
    }

    if (this.canvas.bgstyle == 'radial-gradient') {
      this.canvas['background-image-gradient'] = `radial-gradient(
        ${this.canvas.positiongradient}
        ${this.canvas.color1} ${this.canvas.percentagecolor1}%, 
        ${this.canvas.color2} ${this.canvas.percentagecolor2}%, 
        ${this.canvas['background-color']} ${this.canvas.percentagecolor3}%
        )`;
    }

    if (this.canvas.bgstyle == '') {
      this.canvas['background-image-gradient'] = `radial-gradient(
        ${this.canvas.positiongradient}
        ${this.canvas.color1} ${this.canvas.percentagecolor1}%, 
        ${this.canvas.color2} ${this.canvas.percentagecolor2}%, 
        ${this.canvas['background-color']} ${this.canvas.percentagecolor3}%
        )`;
    }
  }

  async addEffect(element) {
    let id = document.getElementById(element.id);

    for (let i = 0; i < element.animation.length; i++) {
      let animationsection = element.animation[i];
      this.animationsService.addAnimation(id, animationsection, element, i, this.canvas, this.primairytimeline);
    }

    // add placeholder to set timeline
    if (element.animation.length === 0) {
      this.primairytimeline.from(id, { duration: this.counter }, 0);
    }

  }

  sideToggle() {
    if (this.sideopen === true) {
      this.sideopen = false
    } else {
      this.sideopen = true
    }
  }


  addNewEffectTopBar() {
    this.addNewEffect();
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    let scrollContainer = document.getElementById('idmaincontainer') as HTMLElement;
    try {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    } catch (err) {
      // console.log(err)
    }
  }

  addNewEffect(): void {
    console.log('add new effect')
    // turn off wihiteboard. 
    if (this.whiteboard) { this.deletewhiteboard() }
    let rotationcycle = '0';
    if (this.selectedelement.rotation !== 0) {
      rotationcycle = this.selectedelement.rotation;
    }

    // add new motionpath for moving elements. 
    this.selectedelement.motionpath = this.vectorService.addMotionPath(this.selectedelement.id, this.canvas, this.selectedelement.motionpath);

    let newanimation: animationtype = {
      start_time: 0, //delayt
      delay: 0,
      end_time: 10,
      anim_type: 'scale',
      duration: 3,
      ease: '',
      posx: this.selectedelement.posx,
      posy: this.selectedelement.posy,
      rotationcycle: rotationcycle,
      travellocX: -50,
      travellocY: -50,
      scalesize: 0.8,
      skewY: 50,
      skewX: 50,
      easetype: '',
      fromto: 'to',
      transformOriginX: '50%',
      transformOriginY: '50%',
      repeat: 0,
      yoyo: false,
      audioeffectsrc: '',
      rotationkeeppos: true
    }
    this.selectedelement.animation.push(newanimation);
    this.detectchange();
  }

  deleteEffect(i) {
    this.selectedelement.animation.splice(i, 1);
  }

  copyEffect(i) {
    const curan = this.selectedelement.animation[i];
    let neweffect = JSON.parse(JSON.stringify(curan));
    this.selectedelement.animation.push(neweffect);
  }

  copyVectorEffect(i) {
    const curan = this.selectedelement.vectoranimation[i];
    let neweffect = JSON.parse(JSON.stringify(curan));
    this.selectedelement.vectoranimation.push(neweffect);
  }

  copyCounterEffect(i) {
    const curan = this.selectedelement.countertype[i];
    let neweffect = JSON.parse(JSON.stringify(curan));
    this.selectedelement.countertype.push(neweffect);
  }

  async copyElement(i, element) {
    const curel = element;
    let newElement = JSON.parse(JSON.stringify(curel));
    newElement.id = undefined;
    // redo all ids
    let newelnr = this.createNewID();
    newElement.id = newelnr;

    this.newz = this.newz + 1;
    newElement.style['z-index'] = this.newz;
    newElement.groupmember = false;

    if (element.type === 'vector') {
      let newVectorElement: vectoranimation = newElement;
      for (let y = 0; y < newVectorElement.vectors.length; y++) {
        let vector = newVectorElement.vectors[y];
        let addnr = y;
        let idnr = newElement.id + 'vec-' + addnr;
        vector.idx = idnr;

        this.renumberSvgIds(newVectorElement.svgcombi, vector.idx, vector.pathids).then(newvectstring => {
          let pathidar = newvectstring.match(/id="(.*?)"/g); //get ids
          //console.log( newvectstring);
          this.cleantags(pathidar).then(paths => {
            vector.pathids = [];
            paths.forEach((newpat: string) => {
              vector.pathids.push(newpat);
            });
          });
        });
      }
    }
    if (newElement.motionpath) {

      for (let i = 0; i < newElement.motionpath.length; i++) {
        let motionpath = newElement.motionpath[i];
        let p = this.selectedelement.id + '-' + i + 'p';
        let newp = newElement.id + '-' + i + 'p';
        let mp = this.selectedelement.id + '-' + i + 'mp';
        let newmp = newElement.id + '-' + i + 'mp';
        // console.log(p, newp, mp, newmp);
        motionpath = motionpath.replace(p, newp);//cropclip for id newid + mp , newid + p
        motionpath = motionpath.replace(mp, newmp); //cropclip for id newid + mp , newid + p
        // console.log(motionpath);
        newElement.motionpath[i] = motionpath;
        // console.log(this.animationarray)
      }

      // newElement.motionpath.forEach((motionpath, index) => {
      //   let p = this.selectedelement.id + '-' + index + 'p';
      //   let newp = newElement.id + '-' + index +  'p';
      //   let mp = this.selectedelement.id + '-' + index + 'mp';
      //   let newmp = newElement.id + '-' + index +  'mp';
      // // console.log(this.selectedelement.id + '-' + index + 'p',  newElement.id + '-' + index + 'p', motionpath)
      //   motionpath = motionpath.replace(p, newp ) //cropclip for id newid + mp , newid + p
      //   motionpath = motionpath.replace(mp, newmp) //cropclip for id newid + mp , newid + p
      // // console.log(this.animationarray)
      // });
    }

    if (element.type === 'image') {
      newElement.blobsrc = element.blobsrc;
      // set new croppath id 
      if (element.clippath) {
        // id + crop 
        // id + croppath  
        let newpathid = newElement.id + 'croppath';
        let currentpathid = curel.id + 'croppath';
        let newcrop = newElement.id + 'crop';
        let oldcrop = curel.id + 'crop';
        newElement.clippath.replace(currentpathid, newpathid);
        newElement.clippath.replace(oldcrop, newcrop);
        newElement.style['clip-path'].replace(curel.id, newElement.id)
      }
    }
    // add crop path copy??? 
    if (!newElement.id) {
      console.error('missing ID! when copying')
    }
    this.animationarray.push(newElement);
    this.selectedelement = newElement;
    await new Promise(resolve => setTimeout(resolve, 400));
    // console.log(this.animationarray);
    this.detectchange();
  }

  getEditFile() {
    this.relationsApi.getFiles(this.option.id, {
      where: { template: { "neq": null }, type: 'video' },
      fields: { name: true, id: true, }
    })
      .subscribe((files: Files[]) => {
        this.editablevideos = files;
        //console.log(this.editablevideos);
      });


  }

  setBlob(event, i): void {
    setTimeout(() => {
      this.animationarray[i].src = event;
      // set blob 

      this.downloadFile(this.animationarray[i].src).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.bufferValue = (event.loaded / event.total) * 100;
        }
        if (event.type === HttpEventType.Response) {
          //console.log("donwload completed");
          let urlCreator = window.URL;
          let bloburl = urlCreator.createObjectURL(event.body);
          this.animationarray[i].blobsrc = this.sanitizer.bypassSecurityTrustResourceUrl(bloburl);
          this.systembusy = false;
        }
      });
    }, 500);
  }

  setblobEmbeddedvideo(event, i): void {

    this.selectedelement.src = event;
    // set blob 
    this.downloadFile(this.selectedelement.src).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.bufferValue = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        //console.log("donwload completed");
        let urlCreator = window.URL;
        let bloburl = urlCreator.createObjectURL(event.body);
        this.selectedelement.blobsrc = this.sanitizer.bypassSecurityTrustResourceUrl(bloburl);

        this.systembusy = false;
        this.checkEmbeddedVideoSize();
      }
    });
  }

  checkEmbeddedVideoSize() {

    setTimeout(() => {
      let videodoc = document.getElementById(this.selectedelement.id);
      let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
      if (video.videoHeight) {
        this.selectedelement.style.height = (video.videoHeight / 2) + 'px';
        this.selectedelement.style.width = (video.videoWidth / 2) + 'px';
      }
    }, 500);
  }

  setVector(url: string, i: number, idx: number, animate?: boolean): void {
    //console.log(url)
    if (animate) {
      this.animationarray[i].vectors[idx].animate = true;
    }
    setTimeout(() => {
      this.animationarray[i].vectors[idx].src = url;

    }, 500);
  }

  setExistingVector(e, i, idx): void {
    let docset = document.getElementById(e.value.id);
    let vectorid = e.value.vectors[0].idx;

    let svgdiv = document.getElementById(vectorid);
    let svg = svgdiv.getElementsByTagName('svg')[0].outerHTML;

    setTimeout(() => {
      //console.log(docset, e, i, idx);
      this.animationarray[i].vectors[idx].object = svg;
      this.initVectors(docset, i, idx, vectorid)
    }, 500);
  }

  // setVectorToAnimate(i) {
  //   let idx = this.animationarray[i].id;
  //   let e = document.getElementById(idx).getElementsByTagName('svg')[0];
  //   let vectorid = this.animationarray[i].vectors[0].idx;
  //   //console.log(e, i, idx, vectorid, this.animationarray[i].animate);
  //   this.animationarray[i].svgcombi = '';
  //   this.initVectors(e, i, 0, vectorid)
  // }

  /* 
  e = svg node
  i = animationarray index
  idx = element id
  vectorid = vector id 
  */
  async initVectors(e, i, idx, vectorid, character?) {
    // console.log('init vector', e, i, idx, vectorid)
    let vector: vectoranimation = this.animationarray[i];
    //this.systembusy = true;
    if (vector.svgcombi && !vector.character) { return }
    // rename class names to prevent clashes in classnames
    let className = e.getElementsByTagName("style");
    //console.log(className)
    let svgstring = e.outerHTML;
    //console.log(className[0].sheet.cssRules);
    if (className.length > 0) {
      for (let i = 0; i < className[0].sheet.cssRules.length; i++) {
        let element = className[0].sheet.cssRules[i];
        let clname: string = element.selectorText; //CSSStyleRule
        let newclname: string = clname + '-enydea-' + vectorid;
        element.selectorText = newclname;
        let searchnewclname = newclname.substring(1);
        let clsearchname = clname.substring(1);
        let re = new RegExp(clsearchname, 'g');
        svgstring = svgstring.replace(re, searchnewclname);
      }
      e.outerHTML = svgstring;
    }
    let svgdiv = document.getElementById(vectorid);
    let svg = svgdiv.getElementsByTagName('svg')[0];
    await this.vectorService.removeSwitch(svg)
    this.vectorService.removeHeadG(svg);

    this.vectorService.toSVGInlineStyle(vector);

    if (!vector.animate) {
      //svg.setAttribute('viewBox', x + ' ' + y + ' ' + width + ' ' + height);
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
      vector.svgcombi = svg.outerHTML;
      // console.log(svg)
    }

    // if (this.animationarray[i].svgcombi === '' || this.animationarray[i].morph) {

    // convert all svgs and all other then paths (website wide)
    await MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");

    // get viewbox info to resize get new vector
    let vector1 = this.animationarray[i].vectors[idx] as vectorelement;
    let getview = document.getElementById(vector1.idx);
    let svgview = getview.getElementsByTagName('svg');
    let originalsizestring = svgview[0].getAttribute("viewBox");

    let newsize, originalsize;
    if (!originalsizestring) {
      console.error('missing viewbox')
      newsize = { x: 0, y: 0, width: 100, height: 100 }
      originalsize = { x: 0, y: 0, width: 100, height: 100 }
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
    } else {
      let origarray = originalsizestring.split(' ');
      newsize = { x: origarray[0], y: origarray[1], width: origarray[2], height: origarray[3] }

      // get vector 1 where to change too
      let vector2 = this.animationarray[i].vectors[0] as vectorelement;
      let getview2 = document.getElementById(vector2.idx)
      let svgview2 = getview2.getElementsByTagName('svg');
      let originalsizestring2 = svgview2[0].getAttribute("viewBox");
      let origarray2 = originalsizestring2.split(' ');
      originalsize = { x: origarray2[0], y: origarray2[1], width: origarray2[2], height: origarray2[3] }
    }



    //await this.removeclipPath(svg);
    //await this.removeOpacity(svg, vectorid);
    await this.vectorService.numberPaths(svg);
    //console.log('renumber paths');
    await this.removeText(svg, vectorid);
    //console.log('text removed');

    let gal = svg.getElementsByTagName("g").length;

    for (let i = 0; i < gal; i++) {
      await this.vectorService.deleteVectorGroup(svg);
    }


    //console.log("vector groups deleted");
    //   if (!character){
    await this.vectorService.resizeVector(originalsize, newsize, idx, svg);
    //    }

    //console.log("vector resized");
    //console.log("remove last group");
    await this.deleteImagesVector(svg); // repeat some reason first time does not always work
    await this.deleteImagesVector(svg);
    await this.deleteImagesVector(svg);
    await this.deleteImagesVector(svg);
    await this.deleteIllustratorVector(svg)

    await this.combineSVGs2(this.animationarray[i], originalsize);

    this.deleteWhitespaceSVG();
    //console.log("vectors combined");
    //this.systembusy = false;
    // }
  }

  deselectAll() {
    this.stopFunc();
    this.vectorService.removeVectorPathMultiSelection(this.selectedelement);

    if (this.whiteboard) {
      this.savewhiteboard();
    }

    if (this.editpath) {
      this.saveNewMotionPath();
      //this.editpath = false;
    }

    // this.vectorService.removePathEditor();
    this.selectedelement = undefined;

  }

  deleteImagesVector(svg) {
    return new Promise(async (resolve, reject) => {
      let images = await svg.getElementsByTagName("image");
      //console.log(images)
      for (let p = 0; p < images.length; p++) {
        let child = await svg.getElementById(images[p].id);
        //console.log(child)
        await child.parentNode.removeChild(child);
      }
      resolve(svg);
    });
  }

  deleteIllustratorVector(svg) {
    return new Promise(async (resolve, reject) => {
      let images1 = await svg.getElementsByTagName("i:pgf") as HTMLCollection;
      let images2 = await svg.getElementsByTagName("switch") as HTMLCollection;
      let images3 = await svg.getElementsByTagName("foreignObject") as HTMLCollection;
      let images = [];
      images = images.concat(Array.from(images1), Array.from(images2), Array.from(images3));
      console.log(images)
      for (let p = 0; p < images.length; p++) {
        let child = await svg.getElementById(images[p].id);
        //console.log(child)
        if (child) {
          await child.parentNode.removeChild(child);
        } else {
          images[p].parentNode.removeChild(images[p]);
        }

      }
      resolve(svg);
    });
  }


  removeText(svg, vectorid): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let texts = svg.getElementsByTagName('text');
      //let textarray = Array.from(texts);
      // let removeA = [];
      //console.log(texts);

      // for (let i = 0; i < texts.length; i++) {
      //   removeA.push(texts[i].cloneNode(true));
      // }
      //console.log(removeA);
      for (let i = 0; i < texts.length; i++) {
        //let child = svg.getElementById(textarray[i].id)
        texts[i].parentNode.removeChild(texts[i]);
        //if (i === texts.length -1){resolve()}
      }
      resolve();
    })
  }

  removeOpacity(svg, vectorid): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let paths = svg.getElementsByTagName('paths');
      for (let i = 0; i < paths.length; i++) {
        paths[i].style.removeProperty('fill-opacity');
      }
      resolve();
    })
  }

  removeclipPath(svg) {
    let clippaths = svg.getElementsByTagName('clipPath');
    //console.log(clippaths);
    let index;
    for (index = clippaths.length - 1; index >= 0; index--) {
      clippaths[index].parentNode.removeChild(clippaths[index]);
    }
  }

  onMovingAnimationChart(event, selectedelement) {
    selectedelement.start_time = event.x / 10;
  }

  onResizeAnimationChart(event, selectedelement) {
    selectedelement.lineChartOptions.animation.duration = event.size.width * 100;
  }

  onMovingAnimationEl(event, animation) {
    animation.start_time = event.x / 10;
    // [style.left]="animation.start_time * 10 + 'px'"
  }

  initTimeline(animations) {
    //console.log(animations);
    if (animations) {
      animations.forEach((animation, index) => {
        let doc = document.getElementById('timeline' + index);
        doc.style.left = animation.start_time * 10 + 'px';
      });
    }
  }

  initTimelineSidebar(animations) {
    // console.log(animations);
    animations.forEach((animation, index) => {
      let doc = document.getElementById('timeline-side' + index);
      doc.style.left = animation.start_time * 10 + 'px';
    });
  }

  onResizeAnimationEl(event, i, animation) {
    animation.duration = event.size.width / 10;
  }

  async onMovingTimeline(event) {
    //console.log(event)
    //await new Promise(resolve => setTimeout(resolve, 20));
    this.currenttime = event.x;
  }

  async setPosition(idel) {
    //if has animation is move --> path determines position
    let elm = document.getElementById(idel.id);
    //  // console.log(idel.style['z-index'], idel.id)
    gsap.set(elm, { x: idel.posx, y: idel.posy, rotation: idel.rotation });
  }

  private setMoveableItem = async (idel) => {
    //  console.log('move', idel, this.draggableService.draggableObject)

    // set position object 
    idel.posy = this.draggableService.draggableObject.y;
    idel.posx = this.draggableService.draggableObject.x;

    for (let i = 0; i < idel.animation.length; i++) {
      let anim = idel.animation[i];

      //  if (animationmove.length > 0 || animationbounce.length > 0) {
      if (anim.anim_type === 'move' || anim.anim_type === 'bounce' || anim.anim_type === 'followminions') {
        // calculate the new difference from old location to new
        let newy = this.draggableService.draggableObject.y - this.draggableService.draggableObject.startY;
        let newx = this.draggableService.draggableObject.x - this.draggableService.draggableObject.startX;


        if (idel.groupmember) {
          // let boundposition = document.getElementById('myBounds').getBoundingClientRect();

          // // get the actual position on the screen / relative
          // let relx = document.getElementById(idel.id).getBoundingClientRect().left - boundposition.left;
          // let rely = document.getElementById(idel.id).getBoundingClientRect().top - boundposition.top;
          // let path = await document.getElementById(idel.id + 'p');
          // let rawpath = await MotionPathPlugin.getRawPath(path);
          // let newpath = await MotionPathPlugin.transformRawPath(rawpath, 1, 0, 0, 1, relx, rely);
          // let stringpath = await MotionPathPlugin.rawPathToString(newpath);
          // this.saveMotionPath(stringpath, idel.id, i);
        } else {
          let idmotion = idel.id + '-' + i + 'p';
          let path = await document.getElementById(idmotion);
          if (!path) { console.log('missing motionpath: ' + idmotion); return; }
          let rawpath = await MotionPathPlugin.getRawPath(path);
          let newpath = await MotionPathPlugin.transformRawPath(rawpath, 1, 0, 0, 1, newx, newy); // set the new path position
          let stringpath = await MotionPathPlugin.rawPathToString(newpath);
          this.saveMotionPath(stringpath, idel.id, i);
        }
      }
    }

    // check if combi item
    if (idel.groupmember) {
      //console.log('update combibox')
      this.vectorService.updateCombiBox(idel, this.animationarray);
    }

    if (this.draggableService.draggableObject) {
      // console.log(this.draggableService.draggableObject, idel)
      // cancel drag after drag ends..
      this.draggableService.cancelDragSelect();
    }
  }


  async combiBoxCalculator(vectorcombi: vectorcombinator) {
    this.vectorService.combiBoxCalculator(vectorcombi);
    this.detectchange();
  }

  toggleVectorImageEdit() {
    // console.log(this.vectorService.editsvg)
    if (!this.vectorService.editsvg) {
      this.vectorService.editsvg = true;
      this.setBeforeEdit();
    } else {
      this.vectorService.editsvg = false;
      this.detectchange();
    }

  }

  onResizing(e, i) {
    this.animationarray[i].style.width = e.size.width + 'px';
    this.animationarray[i].style.height = e.size.height + 'px';
  }

  onChangeShape(element) {
    //console.log(element)
    //element.style['border-radius'] = '';
    element.style.class = '';
    element.overflow = '';
    element.style['border-radius'] = '0%';
    element.style['background-color'] = element.color1;



    if (element.shape === 'square') {
      element.style['border-radius'] = element.roundingTL + 'px ' + element.roundingTR + 'px ' + element.roundingBL + 'px ' + element.roundingBR + 'px ';
    }

    if (element.shape === 'round') {
      element.style['border-radius'] = element.rounding + '%';
    }


    // if (element.shape === 'heart') {
    //   element.style.class = 'heart'
    //   element.style['background-color'] = 'rgba(0, 0, 0, 0)';
    //   element.style.width = element.style.height;
    // }

    // if (element.shape === 'star') {
    //   element.style.class = 'star-six'
    //   element.style['background-color'] = 'rgba(0, 0, 0, 0)';
    // }

    // if (element.coloreffect === 'stripes') {
    //   element.style['background'] = `repearting-linear-gradient(
    //     45deg,
    //     ${element.style['background-color']},
    //     ${element.style['background-color']} 5px,
    //     transparent 5px,
    //     transparent 10px
    //   )`;
    //   element.overflow = 'hidden';
    // }

    if (element.coloreffect === 'stripes') {
      element.style['background'] = `repeating-linear-gradient(
        ${element.stripedirection}deg,
        ${element.style['background-color']},
        ${element.style['background-color']} ${element.stripewidth}px,
        transparent ${element.stripewidth}px,
        transparent ${element.stripewidth * 2}px
      )`;
      element.overflow = 'hidden';
    }

    else if (element.coloreffect === 'gradient') {
      element.style['background'] =
        `linear-gradient(${element.direction}deg, ${element.color1} ${element.percentagecolor1}%, ${element.color2} ${element.percentagecolor2}%`;
      //${element.style['background-color']} ${element.percentagecolor3}%)

      console.log(element.style['background'])
    }

    else if (element.coloreffect == 'radial-gradient') {
      element.style['background-image'] = `radial-gradient(
        ${element.position}
        ${element.color1} ${element.percentagecolor1}%, 
        ${element.color2} ${element.percentagecolor2}%, 
        ${element.style['background-color']} ${element.percentagecolor3}%
        )`;
    }

    this.detectchange();
  }



  deletecounteranimation(iv) {
    this.selectedelement.countertype.splice(iv, 1);
  }

  addCounterAnimation(element: counteranimation) {
    let vectanim = {
      counteranimationtype: '',
      repeat: 0,
      start_time: 0, //delay
      delay: 0,
      end_time: 10,
      duration: 3,
      x: 0,
      y: 100,
      fromto: 'to',
      easetype: 'bounce',
      svganimationtype: 'draw',
      drawcolor: 'red',
      linethickness: '5px',
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      hideimage: false,
      counterstyle: 'circle',
      fromr: 100,
      tor: 100
    }
    element.countertype.push(vectanim);
  }

  addNewCounterType() {
    let countertype: countertype = {
      counteranimationtype: '',
      repeat: 0,
      start_time: 0, //delay
      delay: 0,
      end_time: 10,
      duration: 3,
      x: 0,
      y: 100,
      fromto: 'to',
      easetype: 'bounce',
      svganimationtype: 'draw',
      drawcolor: 'red',
      linethickness: '5px',
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      hideimage: false,
      counterstyle: 'circle',
      fromr: 100,
      tor: 100
    }
    return countertype;
  }

  addNewForm(): void {
    this.relationsApi.getForms(this.option.id).subscribe((forms: Forms[]) => {
      console.log(forms)
      this.Forms = forms;
    })
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;

    let anim: animationtype[] = [];
    let form: formanimation = {
      type: 'form',
      formsrc: '',
      groupmember: false,
      formId: '',
      style: {
        'z-index': 0,
        width: '',
        height: '',
        position: 'absolute',
        'font-size': '',
        'font-style': '',
        'font-weight': '',
        'font-family': '',
        padding: '',
        opacity: 1,
        'box-shadow': '',
        'filter': '',
        'color': ''
      },
      posx: 0,
      posy: 0,
      setpos: { 'x': 0, 'y': 0 },
      animation: anim,
      id: newelnr,
      transform: '',
      rotation: 0,
      motionrotation: 0,
      motionpath: [], // []
    }
    this.animationarray.push(form);
    this.selectedelement = form;
    this.detectchange();
  }

  addNewCounter(): void {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let newcoutertype = this.addNewCounterType();
    let anim: animationtype[] = [];
    let img: counteranimation = {
      countertype: [newcoutertype],
      type: 'counter',
      count: 0,
      maxcount: 100,
      mincount: 100,
      groupmember: false,
      style: {
        'z-index': this.newz,
        width: "100px",
        height: "100px",
        position: 'absolute',
        opacity: 1,
        'box-shadow': '',
        'font-size': '20px',
        'font-family': 'Open Sans',
        'font-style': '',
        'font-weight': '',
        'color': 'black',
      },
      src: '',
      posx: 0,
      posy: 0,
      setpos: { 'x': 0, 'y': 0 },
      animation: anim,
      id: newelnr,
      transform: '',
      rotation: 0,
      motionrotation: 0,
      onclickurl: '',
      onhovershow: '',
      onhoveraction: '',
      motionpath: []
    }
    this.animationarray.push(img);
    this.selectedelement = img;
    this.detectchange();
  }

  downloadFile(url): Observable<HttpEvent<any>> {
    return this.http.get(url, {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders()
    });
  }

  async getThreedFile(element: threedanimation, url): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.systembusy = true;
      this.downloadFile(url).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.bufferValue = (event.loaded / event.total) * 100;
        }
        if (event.type === HttpEventType.Response) {
          //console.log("donwload completed");
          let urlCreator = window.URL;
          element.blobsrc = urlCreator.createObjectURL(event.body);
          this.systembusy = false;
          resolve(element);
        }
      });
    });

  }

  async addNewThreed(svg?: string, svgurl?: string, imageurl?: string, videourl?: string, width?: number, height?: number) {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let anim: animationtype[] = [];
    let motionpath = [];

    let threed = factoryThreedanimation(this.newz, this.canvas, anim, newelnr, motionpath, svg, svgurl, imageurl, videourl);
    if (imageurl || videourl) {
      threed.imageheight = height;
      threed.imagewidth = width;
    }

    this.animationarray.push(threed);
    this.selectedelement = threed;
    this.detectchange();
  }


  addthreedanimation(selectedelement) {
    let threedanimationcontrol = factoryThreedanimationcontrol();
    let motionpath = this.vectorService.addMotionPath(this.selectedelement.id, this.canvas, this.selectedelement.motionpath);
    selectedelement.motionpath = motionpath;
    selectedelement.threedanimations.push(threedanimationcontrol);
  }


  addNewEmbeddedVideo() {
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let anim: animationtype[] = [];
    let motionpath = []
    let embedvid = factoryEmbeddedVideo(anim, newelnr, motionpath, this.newz);
    this.animationarray.push(embedvid);
    this.selectedelement = embedvid;
    this.detectchange();
  }


  addThreeClipAnimation(clips) {

  }

  addNewImage(): void {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let anim: animationtype[] = [];
    let motionpath = []
    let img = imageanimationFactory(anim, newelnr, motionpath, this.newz);
    this.animationarray.push(img);
    this.selectedelement = img;
    this.detectchange();
  }

  addNewShape(): void {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let anim: animationtype[] = [];
    let motionpath = []
    let img = shapeanimationFactory(anim, newelnr, this.newz, motionpath);
    this.animationarray.push(img);
    this.selectedelement = img;
    this.detectchange();

  }

  addNewChart(): void {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    this.newz = this.newz + 1;
    let randomnum1 = this.RandomInt(0, 8);
    let randomnum2 = this.RandomInt(0, 8);
    let colorrandom1 = this.colorset.setColor(this.colorarray[randomnum1]); //assign to prevent reference with manual change
    let colorrandom2 = this.colorset.setColor(this.colorarray[randomnum2]);
    let colorset = [
      colorrandom1,
      colorrandom2
    ];
    let lineChartOptions = {
      legend: {
        labels: {
          fontFamily: 'Open Sans',
          fontSize: 14
        },
        onClick: function (e, legendItem) {
          let index = legendItem.datasetIndex;
          let ci = this.chart;
          //console.log(e, ci, legendItem);
          ci.data.datasets.forEach(function (e, i) {
            let meta = ci.getDatasetMeta(i);
            //console.log(meta);

            if (i === index) {
              if (meta.hidden) {
                meta.hidden = false;
              } else {
                meta.hidden = true;
              }
            }
          });
          //ci.update();
        }
      },
      animation: {
        duration: 1000,
        easing: 'easeInQuad'
      },
      scales: this.scales
    };
    let anim: animationtype[] = [];
    let motionpath = [];
    let chart = factoryChart(newelnr, colorset, anim, lineChartOptions, motionpath, this.newz);
    // console.log(chart, colorset)
    this.animationarray.push(chart);
    //console.log(this.animationarray[this.animationarray.length - 1]);
  }


  startDraw(): void {
    if (this.drawing === false) {
      this.drawing = true;
    } else {
      //console.log('already drawing'); return;
      this.patheditorService.removeEventListeners();
    }

    //console.log(this.shapedraw);
    this.zoomfactor = 1;
    if (this.shapedraw === 'line') {
      this.addNewLine();
    }
    if (this.shapedraw === 'figure') {
      this.vectorService.addNewFigure();
    } else {
      this.startNewWhiteboard();
    }
  }

  addNewWhiteboard(): void {
    this.shapedraw = '';
    if (this.whiteboard === false) {
      this.whiteboard = true;
    }
  }

  textToSVG(textelement: textanimation) {

    // get font url.. search for faster option 

    let fontfound = textelement.style['font-family'];
    let fontpath: string = "OpenSans.ttf";

    if (fontfound === "Roboto") {
      fontpath = "Roboto.ttf";
    }

    if (fontfound === "Open Sans") {
      fontpath = "OpenSans.ttf";
    }

    if (fontfound === "Baloo Bhai") {
      fontpath = "BalooBhai-Regular.ttf"
    }

    if (fontfound === "SandBrush") {
      fontpath = "SandbrushPro.ttf";
    };

    if (fontfound === "Shipwreck") {
      fontpath = "SchoonerScript.ttf";
    };

    if (fontfound === "WesternOld") {
      fontpath = "WesternOld.ttf"; // http://localhost:4200/WesternOld.ttf
    };

    if (fontfound === "IndustrialOld") {
      fontpath = "IndustrialOld.ttf";
    };

    if (fontfound === "Hillpark") {
      fontpath = "Hillpark.ttf";
    };

    load(fontpath, (err, font) => {
      if (err) {
        console.log('Font could not be loaded: ' + err, fontpath);
      } else {
        // console.log(font)
        // Now let's display it on a canvas with id "canvas"
        //const ctx = document.getElementById('canvas').getContext('2d');

        // Construct a Path object containing the letter shapes of the given text.
        // The other parameters are x, y and fontSize.
        // Note that y is the position of the baseline.
        const path = font.getPath(textelement.content, 0, 150, 100);
        // console.log(path)

        let pathsvg = path.toSVG();


        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute('viewBox', 0 + ' ' + 0 + ' ' + 500 + ' ' + 500);
        svg.setAttribute('height', '100%');
        svg.setAttribute('width', '100%');

        let newpath = document.createElementNS('http://www.w3.org/2000/svg', "path");
        svg.innerHTML = pathsvg;
        svg.appendChild(newpath)
        // console.log(svg);
        this.addNewVector(undefined, textelement.style.height, textelement.style.width, svg.outerHTML, textelement.posx, textelement.posy);
        // If you just want to draw the text you can also use font.draw(ctx, text, x, y, fontSize).
        //  path.draw(ctx);
      }
    });


  }

  async startDraggable() {
    let svgElement = document.getElementById("svgElement");

    let new_element = svgElement.cloneNode(true);
    svgElement.parentNode.replaceChild(new_element, svgElement);
    let svg = SVG(svgElement) as any;
    //svg.draggable();

    // or by path??
    let paths = svgElement.getElementsByTagName('path');
    //console.log(paths);
    for (let i = 0; i < paths.length; i++) {
      let groupie = await document.getElementById(paths[i].id);
      if (groupie !== null) {
        let path = SVG(groupie) as any;
        path.draggable();
      }
    }
  }

  startNewWhiteboard(): void {
    this.selectedelement = '';
    this.patheditorService.startNewWhiteboard(this.canvas,
      this.whiteboardsmoothing, this.shapedraw, this.whiteboardcolor, this.whiteboardstokewidth, this.whiteboardfillcolor);
  }

  addNewLine(): void {
    let docset = document.getElementById('svgElement');
    let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    let strPath = 'M 50,50 L 100,100 ';
    path.setAttribute("d", strPath);
    path.setAttribute("id", 'svgElementPath');
    //path.setAttribute('fill-rule', 'evenodd');
    docset.appendChild(path);
    let pathset = document.getElementById('svgElementPath');
    this.pathEditor = PathEditor.create(pathset, {
      handleSize: 2,
      selected: true,
      draggable: true
    });
  }

  async savewhiteboard() {
    this.patheditorService.resetHistory(); // delete savehistory ctrlz etc. 
    let svgElement = document.getElementById("svgElement");
    if (this.closewhiteboard) {
      let path = svgElement.getElementsByTagName('path');
      for (let i = 0; i < path.length; i++) {
        let d = path[i].getAttribute('d');
        let newd = d + ' z';
        path[i].setAttribute('d', newd);
      }
    }
    // convert to proper type
    let svg = svgElement as unknown;
    let svg2 = svg as SVGAElement;
    let rect = svg2.getBBox();
    let height = rect.height + 'px';
    let width = rect.width + 'px';

    let newsvg = svgElement.outerHTML;
    let newelnr = this.createNewID();

    // rename ids
    let pathidar = svgElement.innerHTML.match(/id="(.*?)"/g); //get ids
    let idnr = newelnr + 'vec-' + 1;
    let newvectstring = await this.renumberSvgIds(newsvg, idnr, pathidar);
    let pathidarfinal = newvectstring.match(/id="(.*?)"/g); //get ids
    newvectstring = newvectstring.replace(pathidarfinal[0], 'id=svgDraw')
    pathidarfinal.splice(0, 1);

    for (let i = 0; i < pathidarfinal.length; i++) {
      pathidarfinal[i] = pathidarfinal[i].replace('id=', '');
      pathidarfinal[i] = pathidarfinal[i].replace(/"/g, '')
    }
    this.addNewVector(
      null,
      height,
      width,
      newvectstring,
      rect.x, rect.y, pathidarfinal, null, true);

    this.deletewhiteboard();
    // this.vectorService.removePathEditor();

  }

  deletewhiteboard() {
    this.patheditorService.resetHistory(); // delete history
    let svgElement = document.getElementById("svgElement");
    let new_element = svgElement.cloneNode(true);
    svgElement.parentNode.replaceChild(new_element, svgElement);
    this.whiteboard = false;
  }

  addNewText(): void {
    if (this.whiteboard) { this.deletewhiteboard() }
    let newelnr = this.createNewID();
    //let elname = '#element' + newelnr;
    this.newz = this.newz + 1;


    let txt = factoryTextanimation(newelnr, this.newz);
    this.animationarray.push(txt);
    this.selectedelement = txt;
    this.detectchange();
  }

  deleteTextAnimation(iv) {
    this.selectedelement.splittextanimation.splice(iv, 1);
  }

  deletethreedanimationcontrol(iv) {
    this.selectedelement.threedanimations.splice(iv, 1);
  }

  async imageCropPath() {
    // this.selectedelement.style['clip-path'] = 'url(#' + this.selectedelement.id + 'cropclip)'
    this.selectedelement.style['clip-path'] = '';
    this.cropimages = true;
    let pathset = document.getElementById(this.selectedelement.id + 'croppath');
    let docset = document.getElementById(this.selectedelement.id);
    let svg = document.getElementById(this.selectedelement.id + 'crop');
    let editpath;
    let h = docset.getBoundingClientRect().height;
    let w = docset.getBoundingClientRect().width;
    let viewbox = '0 0 ' + w + ' ' + h;
    svg.setAttribute('viewBox', viewbox);
    // if not clippath present set to current size box
    if (!this.selectedelement.clippath) {
      //editpath = 'M0,0 L300,0 L300,300 L0,300z'
      // editpath = 'M 10,10 L' + (w - 10) + ',10 L' + (w - 10) + ',' + (h - 10) + ' L10,' + (h - 10) + ' z';
      // this.selectedelement.clippath = editpath;
      // pathset.setAttribute('d', editpath);
      this.resetImageCropPath()
    } else {
      editpath = this.selectedelement.clippath;
    }
    docset.style['clip-path'] = '';
    this.pathEditor = PathEditor.create(pathset, {
      handleSize: 2,
      selected: true,
      draggable: true
    });
  }



  resetImageCropPath() {
    let pathset = document.getElementById(this.selectedelement.id + 'croppath');
    let docset = document.getElementById(this.selectedelement.id);
    let svg = document.getElementById(this.selectedelement.id + 'crop');
    let h = docset.getBoundingClientRect().height;
    let w = docset.getBoundingClientRect().width;
    switch (this.standardpath) {
      case 'linear': {
        let editpath = 'M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457   ';
        this.selectedelement.clippath = editpath;
        pathset.setAttribute('d', editpath);
        break
      }
      case 'circle': {
        let circw = (w - 10) / 2;
        let circh = (h - 10) / 2;
        let newsvgpath = '<ellipse cx="' + circw + '" cy="' + circw + '" rx="' + circh + '" ry="' + circh + '" id="' + this.selectedelement.id + 'croppath" style="opacity: 0;" />';
        // console.log(newsvgpath)
        pathset.outerHTML = newsvgpath;
        MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");
        let pathset2 = document.getElementById(this.selectedelement.id + 'croppath');
        this.selectedelement.clippath = pathset2;
        break
      }
      case 'square': {
        let editpath = 'M 10,10 L' + (w - 10) + ',10 L' + (w - 10) + ',' + (h - 10) + ' L10,' + (h - 10) + ' z';;
        this.selectedelement.clippath = editpath;
        pathset.setAttribute('d', editpath);
        break
      }
    }
  }

  async imageSaveCropPath() {
    let pathset = document.getElementById(this.selectedelement.id + 'croppath');
    let rawpath = await MotionPathPlugin.getRawPath(pathset);
    let stringpath;
    let style = pathset.getAttribute('transform');
    if (style) {
      style = style.replace('matrix(', '');
      style = style.replace('matrix(', '');
      style = style.replace(')', '');
      style = style.replace(/,/g, ' ');
      let newmatrix = style.split(' ').map(Number);
      //console.log(newmatrix)
      let testpath2 = await MotionPathPlugin.transformRawPath(rawpath, newmatrix[0], newmatrix[1], newmatrix[2], newmatrix[3], newmatrix[4], newmatrix[5]);
      stringpath = await MotionPathPlugin.rawPathToString(testpath2);
    } else {
      stringpath = await MotionPathPlugin.rawPathToString(rawpath);
    }
    this.selectedelement.clippath = stringpath;
    this.selectedelement['clip-path'] = 'url(#' + this.selectedelement.id + 'cropclip)'
    this.cropimages = false;
    this.detectchange();
  }

  imageRemoveCrop() {
    this.selectedelement.style['clip-path'] = ''
    this.cropimages = false;
    this.detectchange();
  }

  async playFunc() {
    if (this.currenttime < this.counter) {
      this.vectorService.editsvg = false; // reset outerwise animation won't work
      //console.log('play', this.animationarray);
      // clean up edits
      // this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
      // this.vectorService.removeVectorPathSelection(this.selectedelement);
      this.vectorcombiedit = false;
      //this.selectedelement = '';
      this.vectorService.setDragSelect(this.selectedelement, false);
      //if (this.currenttime === 0) { this.detectchange() }
      await new Promise(resolve => setTimeout(resolve, 400));
      if (this.canvas.audio) {
        this.audioService.playSound('canvassound', null, this.canvas.loop);
        this.primairytimeline.eventCallback("onComplete", this.audioService.stopSound, ['canvassound', null]);
      }
      this.TimelinesmallSetter(); // timeline animation
      // clean up for play
      // this.selectedVecPath = false; !! is object not boolean??
      clearTimeout(this.t); //to make sure there is no second loop
      this.playing = true;
      if (this.canvas.videourl && this.canvas.weather !== 'glitch') {
        this.videoPlayer.play();
      }
      if (this.canvas.loop) {
        this.videoPlayer.loop = true;
      }
      if (this.currenttime === 0) {
        this.primairytimeline.play(0, true);
      } else {
        this.primairytimeline.resume();
      }
      this.t = setInterval(() => { this.incrementSeconds() }, 100);
    }
  }

  TimelinesmallSetter() {
    // [style.transform]="'translate(' + currenttime + 'px, 0px)'"
    let timelinesetter = document.getElementById('timelinesmallsetter');
    let currentloc = this.currenttime;
    let newtime = this.counter - this.currenttime;
    //console.log(currentloc, newtime);
    this.primairytimeline.fromTo(timelinesetter, { x: currentloc }, { x: this.counter, duration: newtime, ease: "none" }, 0);
  }

  stopFunc() {

    let timelinesetter = document.getElementById('timelinesmallsetter');//timebar bottom
    clearTimeout(this.t);
    this.primairytimeline.pause(0, false);
    this.currenttime = 0;
    this.primairytimeline.set(timelinesetter, { x: 0 }, 0) //timebar bottom
    this.primairytimeline.timeScale(1);
    this.playing = false;
    if (this.canvas.videourl) {
      this.videoPlayer.pause();
      this.videoPlayer.currentTime = 0;
    }

    this.stopAllEmbeddedVideo();
    // this.detectchange(); remove seperate render operation
    if (this.canvas.audio) {
      this.audioService.stopSound('canvassound', null);
      this.audioService.stopAll();
    }

  }

  pauseFunc() {
    this.primairytimeline.pause(); //0, true
    this.playing = false;
    this.stopAllEmbeddedVideo();
    if (this.canvas.videourl) {
      this.videoPlayer.pause();
    }
    clearTimeout(this.t);
    if (this.canvas.audio) {
      this.audioService.pauseSound('canvassound', null);
    }
  }

  setReplay() {
    if (this.setreplay === true) {
      this.setreplay = false;
    } else {
      this.setreplay = true;
    }
  }

  reverseFunc() {
    clearTimeout(this.t);
    this.t = setInterval(() => { this.deleteSeconds() }, 100);
    this.primairytimeline.reverse(null, false);
    if (this.canvas.videourl) {
      this.videoPlayer.playbackRate = 1.0;
    }
  }

  fastforwardFunc() {
    this.primairytimeline.timeScale(5);
  }

  incrementSeconds() {
    if (this.currenttime < (this.counter - 0.1)) {
      this.currenttime = this.currenttime + 0.1;
    } else {
      if (this.setreplay === false) {
        this.pauseFunc();
      } else {
        this.currenttime = 0;
        this.stopFunc();
        this.playFunc();
      }
    }
  }

  deleteSeconds() {
    if (this.currenttime >= 0) {
      this.currenttime = this.currenttime - 0.1;
    }
  }

  drop(e) {
    this.swapElement(this.animationarray, e.currentIndex, e.previousIndex);
    for (let i = 0; i < this.animationarray.length; i++) {
      let element = this.animationarray[i];
      element.style['z-index'] = i + 1;
    }

    this.detectchange();
  }

  swapElement(array, indexA, indexB) {
    let tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
  }

  loadEditableImage() {
    // used?? delete?
    this.animationarray = this.editablevideo.template;
    this.canvas = this.editablevideo.canvas[0];
    this.detectchange();
  }

  setAlign(element: textanimation, align: string) {
    element.style['align-text'] = align;
  }

  setbold(img) {
    if (img.style['font-weight'] === 'bold') {
      img.style['font-weight'] = '';
    } else {
      img.style['font-weight'] = 'bold';
    }
    this.detectchange();
  }

  setitalic(img) {
    if (img.style['font-style'] === 'italic') {
      img.style['font-style'] = '';
    } else {
      img.style['font-style'] = 'italic';
    }
    this.detectchange();
  }

  deleteitem(i) {
    if (this.animationarray[i].type === 'whiteboard') {
      this.whiteboard = false;
    }
    // this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    // this.vectorService.removeVectorPathSelection(this.selectedelement)
    this.animationarray.splice(i, 1);
    this.selectedelement = '';
  }

  deletevcgroup(i) {
    this.vectorcombiedit = false;
    this.animationarray[i].vectors.forEach(element => {
      element.groupmember = false;
    });
    this.animationarray.splice(i, 1);
    this.detectchange();
  }

  deleteitemvcgroup(i, idy) {
    this.animationarray[i].vectors[idy].groupmember = false;
    this.animationarray[i].vectors.splice(idy, 1)
  }

  swiperight(e?) {
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }

  async setThreed(event): Promise<any> {
    this.selectedelement.src = event.fileurl;
    await this.getThreedFile(this.selectedelement, event.fileurl);
    if (event.mtlurl) {
      this.setThreedMaterial(event.mtlurl)
    }
    return
  }

  setThreedMaterial(event) {
    this.selectedelement.materialsrcfile = event;
  }



  handleSVG(svg: SVGElement, parent): SVGElement {
    //console.log('Loaded SVG: ', svg, parent);
    svg.setAttribute('width', '150'); // parent.style.width
    svg.setAttribute('height', '150'); // parent.style.height
    return svg;
  }

  previewSVG(svg: SVGElement, parent): SVGElement {
    svg.setAttribute('width', '30');
    svg.setAttribute('height', '30');
    return svg;
  }

  previewSVGBig(svg: SVGElement, parent): SVGElement {
    svg.setAttribute('width', '100');
    svg.setAttribute('height', '100');
    return svg;
  }

  addNewVectorSrc(i, element: vectoranimation) {
    const addnr = element.vectors.length + 1
    let idnr = element.id + 'vec-' + addnr;
    let newVector: vectorelement = {
      src: '',
      idx: idnr,
      duration: 1,
      start_time: 0,
      delay: 0,
      pathids: [],
      easetype: '',
      fromto: 'to',
      scale: 0,
      object: ''
    }
    this.animationarray[i].vectors.push(newVector);
  }

  addNewVectorAnimation(i, element: vectoranimation) {
    let vectanim: vectoranimationtype[] = [{
      svganimationtype: 'draw',
      drawcolor: 'red',
      linethickness: '5px',
      repeat: 0,
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      start_time: 0, //delayt
      end_time: 10,
      delay: 0,
      duration: 3,
      hideimage: false,
      easetype: 'linear',
      fromto: 'to',
      colorpath: '',
      selectedpaths: [],
      svganimationpaths: undefined,
      keepshape: true
    }]
    this.animationarray[i].vectoranimation.push(vectanim);
  }

  async deleteVectorSrc(i, idx, element) {
    this.selectedelement.vectors.splice(idx, 1);
    this.selectedelement.svgcombi = '';
    for (let i = 0; i < this.selectedelement.vectors.length; i++) {
      let vector: vectorelement = this.selectedelement.vectors[i];
      let e = document.getElementById(vector.idx);
      let vectorid = vector.idx;
      await this.initVectors(e, i, idx, vectorid);
    }
  }

  async combineSVGs2(element, newsize): Promise<void> {
    return new Promise(async (resolve, reject) => {
      // console.log(newsize)
      let height = 500, width = 500, x = 0, y = 0;
      let originalsize = newsize; // get original viewbox
      if (originalsize) {
        x = originalsize['x'];
        y = originalsize['y'];
        width = originalsize['width']; // * newscale1;
        height = originalsize['height']; // * newscale1;
      }

      // create SVG element
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('viewBox', x + ' ' + y + ' ' + width + ' ' + height);
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');

      // add paths and number
      for (let w = 0; w < element.vectors.length; w++) {
        // get paths and renumber
        let vect: vectorelement;
        vect = element.vectors[w];
        vect.pathids = [];
        let vectnew = document.getElementById(vect.idx);

        // sort paths -- to do push in new svg
        let pathz1 = vectnew.getElementsByTagName('path');
        let pathz = Array.prototype.slice.call(pathz1)

        pathz = await this.setVectId(pathz, vect);
        let childeren = await this.getChilderen(vectnew) as Array<Node>;
        svg = await this.addNewChilderen(childeren, svg) as SVGSVGElement;
        svg = await this.vectorService.sortVect(svg) as SVGSVGElement;

      }

      // convert to string
      let s = new XMLSerializer();
      let str = s.serializeToString(svg);

      let str2 = await this.deleteMetaSvg(str);

      element.svgcombi = str2;
      // element.style.height = height + 'px';
      // element.style.width = width + 'px';

      for (let x = 0; x < element.vectors.length; x++) {
        let vectnew = document.getElementById(element.vectors[x].idx);
        let pathz1 = vectnew.getElementsByTagName('path');
        await this.setVectIdRedo(pathz1);
      }

      resolve();

    });
  }



  async setVectIdRedo(pathz): Promise<void> {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < pathz.length; i++) {
        let p = pathz[i];
        let r = Math.random().toString(36).substring(7); // add random sring
        let newid = 'enydea' + i + r;
        p.setAttribute('id', newid);
      }
      resolve();
    });
  }



  async setVectId(pathz, vect) {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < pathz.length; i++) {
        let p = pathz[i];
        let r = Math.random().toString(36).substring(7); // add random sring
        let newid = vect.idx + i + r;
        vect.pathids.push(newid);
        p.setAttribute('id', newid);
      }
      resolve(pathz);
    });
  }

  async addNewChilderen(childeren, svg: SVGSVGElement) {
    return new Promise(async (resolve, reject) => {
      for (let y = 0; y < childeren.length; y++) {
        svg.appendChild(childeren[y]);
      }
      resolve(svg);
    });
  }

  async getChilderen(vectnew) {
    return new Promise(async (resolve, reject) => {
      let childeren = [];
      let childnodes = vectnew.firstChild.childNodes;
      for (let i = 0; i < childnodes.length; i++) {
        let elemen = childnodes[i];
        childeren.push(elemen.cloneNode(true));
      }
      resolve(childeren);
    });
  }



  async combineSVGs(element, newsize?): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let idnew;
      let total = []; // array to combine all parts
      let h = 500, w = 500, x = 0, y = 0;
      let startstr;
      let originalsize = newsize; // get original viewbox
      if (originalsize) {
        x = originalsize['x'];
        y = originalsize['y'];
        w = originalsize['width']; // * newscale1;
        h = originalsize['height']; // * newscale1;
      }

      startstr = '<svg xmlns="http://www.w3.org/2000/svg" ' +
        'viewBox="' + x + ' ' + y + ' ' + w + ' ' + h + '" height="100%" width="100%"' +
        'id="svg2" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">';
      total.push(startstr);

      // set defsz
      for (let w = 0; w < element.vectors.length; w++) {
        let vect = element.vectors[w]
        idnew = document.getElementById(vect.idx);
        let defs = idnew.getElementsByTagName('defs');
        for (let y = 0; y < defs.length; y++) {
          let defstring = defs[y];
          total.push(defstring.outerHTML)
        }
      }

      let index = 0;

      for (let z = 0; z < element.vectors.length; z++) {
        let vect = element.vectors[z];
        idnew = document.getElementById(vect.idx); // get document
        const stylstr = idnew.getElementsByTagName('style');

        if (stylstr.length > 0) {
          total.push(stylstr[0].outerHTML);
        }

        //console.log(idnew);
        let vectstring;
        let svgset = idnew.getElementsByTagName('svg');
        if (idnew === null) {
          vectstring = element.svgcombi;
        } else if (svgset.length > 0) {
          vectstring = svgset[0].innerHTML; //was outerhtml
        } else {
          //console.log('can not load SVG', idnew)
        }

        //console.log(idnew, vectstring)
        let pathidar;
        let newvectstring;
        // pathidar = vectstring.match(/id="(.*?)"/g); //get ids
        // newvectstring = await this.GrabPaths(vectstring, pathidar);
        newvectstring = await this.vectorService.getPath(vect.idx);

        //newvectstring = vectstring;

        pathidar = newvectstring.match(/id="(.*?)"/g); //get ids
        newvectstring = await this.renumberSvgIds(newvectstring, vect.idx, pathidar); // set ids
        pathidar = newvectstring.match(/id="(.*?)"/g); //get ids
        pathidar = await this.cleantags(pathidar);
        element.vectors[index].pathids = pathidar;
        total.push(newvectstring);
        ++index;
      }
      total.push('</svg>');
      let childrenst = total.join('');
      element.svgcombi = childrenst;
      element.style.height = h + 'px';
      element.style.width = w + 'px';
      resolve();
    });
  }

  async cleantags(paths) {
    let newpaths = [];
    for (const path of paths) {
      let newpath = path.replace(/id=/g, '');
      let finalpath = newpath.replace(/"/g, '');
      newpaths.push(finalpath);
    };
    return newpaths
  }

  async createMorph(element: vectoranimation, animation: animationtype) {
    // create vector animation foreach path vector 1 to 2, 2 to 3 etc..
    // add appear and dissapear effect for if the paths are uneven
    let vectors: vectorelement[];
    vectors = element.vectors;
    let ease = this.easetypesService.selectEaseType(animation.easetype);

    // await this.combineSVGs(element); // takes to long to rebuild

    for (let i1 = 0; i1 < vectors.length - 1; i1++) {
      let fromvector = vectors[i1];
      let tovector = vectors[i1 + 1];
      let fintime = animation.start_time + animation.duration + (animation.duration * i1);
      let fintimehalf = animation.duration / 0.5;
      let starttime = animation.start_time + (animation.duration * i1) + (1 * i1);
      let repeat = animation.repeat;
      let yoyo = animation.yoyo;

      //console.log(yoyo);
      // if vector 1 has less paths then vector 2
      if (vectors[i1].pathids.length < vectors[i1 + 1].pathids.length) {
        for (let ix = 0; ix < vectors[i1 + 1].pathids.length; ix++) {
          // copy random vector paths to connect to empty paths
          if (ix >= vectors[i1].pathids.length) {

            let topathid = vectors[i1 + 1].pathids[ix];
            let toel = document.getElementById(topathid);
            let vectornewpathset = document.getElementById('0elvect-res' + ix) as unknown;
            let vectornewpath = vectornewpathset as SVGPathElement;
            //console.log(vectornewpath);
            if (vectornewpath === null) {
              const sindex = Math.floor(Math.random() * fromvector.pathids.length); //connect to random paths;
              const frompathid = fromvector.pathids[sindex];
              const fromel = document.getElementById(frompathid);
              const svgnew = fromel.parentElement;// vectornew.getElementsByTagName('svg');
              const newid = '0elvect-res' + ix;
              const checkifexit = document.getElementById(newid) as unknown;
              if (checkifexit == null) {
                const newElement = fromel.cloneNode(true) as HTMLElement;
                newElement.setAttribute('id', newid);
                svgnew.insertAdjacentElement('afterbegin', newElement)
                //svgnew.appendChild(newElement);
                let vectornewpathset = document.getElementById('0elvect-res' + ix) as unknown;
                vectornewpath = vectornewpathset as SVGPathElement;
              } else {
                vectornewpath = checkifexit as SVGPathElement;
              }
            }
            //this.primairytimeline.set()
            if (repeat !== -1) {
              this.primairytimeline.set(vectornewpath, { morphSVG: { shape: vectornewpath }, autoAlpha: 1 }, 0); //reset to original
              this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
              this.primairytimeline.to(vectornewpath, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);
            }
            if (repeat === -1) {
              this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
              this.primairytimeline.set(vectornewpath, { autoAlpha: 1 }, 0); //reset to original
            }

            let vars: GSAPTimelineVars = {
              duration: animation.duration, morphSVG: {
                shape: toel,
                //type: "rotational",
                //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
              }, ease: ease, repeat: repeat, repeatDelay: animation.delay, yoyo: yoyo
            }

            this.primairytimeline.to(vectornewpath, vars, starttime);

          }
        }
      }

      for (let i2 = 0; i2 < fromvector.pathids.length; i2++) {
        // vector 1 is eqeal or smaller then vector 2
        if (i2 < tovector.pathids.length) {
          let frompathid = fromvector.pathids[i2];
          let topathid = tovector.pathids[i2];
          let fromelset = document.getElementById(frompathid) as unknown;
          let fromel = fromelset as SVGPathElement;
          let toel = document.getElementById(topathid);


          // always reset morph element
          this.primairytimeline.set(fromel, {
            morphSVG: {
              shape: fromel,
            }
          }, 0);
          if (repeat !== -1) {
            this.primairytimeline.set(fromel, { morphSVG: { shape: fromel }, autoAlpha: 1 }, 0); //reset to original
            this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
            this.primairytimeline.to(fromel, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);

            let vars: GSAPTimelineVars = {
              duration: animation.duration,
              morphSVG: {
                shape: toel,
                //type: "rotational",
                //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
              }, ease: ease, repeat: repeat,
            }

            this.primairytimeline.to(fromel, vars, starttime);
          }
          if (repeat === -1) {
            let ease2 = ease.replace('in', 'out')
            this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
            this.primairytimeline.set(fromel, { autoAlpha: 1 }, 0); //reset to original

            let vars1: GSAPTimelineVars = {
              duration: animation.duration / 2,
              morphSVG: {
                shape: toel,
              }, ease: ease, repeat: repeat
            }

            let vars2: GSAPTimelineVars = {
              delay: animation.duration / 2,
              repeatDelay: animation.duration / 2,
              duration: animation.duration / 2,
              morphSVG: {
                shape: fromel,
              },
              ease: ease2,
              repeat: repeat,
            }

            this.primairytimeline.to(fromel, vars1, starttime);
            this.primairytimeline.to(fromel, vars2, starttime);
          }

          let varsmisc: GSAPTimelineVars = {
            duration: animation.duration,
            morphSVG: {
              shape: toel,
              //type: "rotational",
              //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
            }, ease: ease, repeat: repeat
          }
          this.primairytimeline.to(fromel, varsmisc, starttime);


        } else { // (i2 > tovector.pathids.length)
          // vector 1 is larger then vector 2
          let frompathid = fromvector.pathids[i2];
          let sindex = Math.floor(Math.random() * tovector.pathids.length); //connect to random paths;
          let topathid = tovector.pathids[sindex];
          let fromel = document.getElementById(frompathid) as unknown;
          let fromel1 = fromel as SVGPathElement;
          let toel = document.getElementById(topathid);

          if (repeat !== -1) {
            this.primairytimeline.set(fromel1, { morphSVG: { shape: fromel1 }, autoAlpha: 1 }, 0); //reset to original
            this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
            this.primairytimeline.to(fromel1, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);
          }
          if (repeat === -1) {
            this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
            this.primairytimeline.set(fromel1, { autoAlpha: 1 }, 0); //reset to original
          }

          let vars: GSAPTimelineVars = {
            duration: animation.duration, morphSVG: {
              shape: toel,
              //type: "rotational",
              //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
            }, ease: ease, repeat: repeat, yoyo: yoyo
          }
          this.primairytimeline.to(fromel1, vars, starttime);

        }
      }
    }
  }


  hideImageVideo() {
    // hide image or video if neccessary
    let img = document.getElementById("imagebgcontainer");
    if (img) {
      img.style.opacity = "0";
    }

    let vid = document.getElementById("videobgcontainer");
    if (vid) {
      vid.style.opacity = "0";
    }

  }


  async addWeatherEffect() {
    await this.backgroundeffectsService.addWeatherEffect(this.canvas, this.primairytimeline, this.videourl, this.currenttime);
    return
  }

  R(min, max) { return min + Math.random() * (max - min) };

  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  async renumberSvgIds(svgstring, idx, pathidar) {
    // console.log(svgstring, idx, pathidar);
    let newsvgstring = svgstring;
    let index = 0;
    let r = Math.random().toString(36).substring(7); // add random sring
    for (const element of pathidar) {
      let ind = index + 1;
      let newid = 'id="' + idx + ind + r + '"';
      newsvgstring = await this.runloop(newsvgstring, element, newid);
      ++index;
    };
    return newsvgstring;
  }

  // Moved to server
  // async renumberSvgIds(svgstring, idx, pathidar): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     let jsonaniarray = JSON.stringify(pathidar);
  //     let data = {
  //       svgstring: svgstring,
  //       pathidar: jsonaniarray,
  //       idx: idx
  //     }
  //     this.filesApi.renumberSvgIds(data)
  //       .subscribe((newsvgstring: string) => {
  //         resolve(newsvgstring);
  //       });
  //   });
  // }



  async runloop(newsvgstring, element, newid) {
    newsvgstring = newsvgstring.replace(element, newid);
    return newsvgstring
  }

  // grabPaths(svgstring, pathidar) {
  //   return new Promise((resolve, reject) => {
  //     let svgarray = [];
  //     // const element of pathidar
  //     for (let i = 0; i < pathidar.length; i++) {
  //       let n = svgstring.indexOf('<path ');
  //       let lx = svgstring.indexOf('</path>'); //<defs
  //       let l = lx + 7;
  //       if (n !== -1) {
  //         svgarray.push(svgstring.substring(n, l));
  //         svgstring = svgstring.replace(svgstring.substring(n, l), '');
  //       }
  //     }
  //     svgstring = svgarray.join('');
  //     resolve(svgstring)
  //   });
  // }

  // Moved to server


  deleteMetaSvg(svgstring) {
    return new Promise((resolve, reject) => {
      let n = svgstring.indexOf('<metadata');
      let lx = svgstring.indexOf('</metadata>'); //<defs
      let l = lx + 11;
      if (n !== -1) {
        svgstring = svgstring.replace(svgstring.substring(n, l), '');
      }
      // "stroke: none;"
      // svgstring = svgstring.replace(/stroke:none/g, '');
      // svgstring = svgstring.replace(/stroke: none/g, '');
      // n = svgstring.indexOf('<defs');
      // lx = svgstring.indexOf('</defs>'); //<defs
      // l = lx + 7;
      // if (n !== -1) {
      //   svgstring = svgstring.replace(svgstring.substring(n, l), '');
      // }
      resolve(svgstring)
    })
  }





  async removeLinearGradient(svg, id) {
    let gradients = svg.getElementsByTagName("linearGradient");

  }

  deleteWhitespaceSVG(): void {
    if (!this.selectedelement.svgcombi) { return }
    // this.vectorService.removeVectorPathSelection(this.selectedelement);
    // this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    setTimeout(() => {
      let element = document.getElementById(this.selectedelement.id);
      // console.log(element, this.selectedelement);
      let svg = element.getElementsByTagName('svg');
      // console.log(svg)
      let bbox = svg[0].getBBox();
      let viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
      svg[0].setAttribute("viewBox", viewBox);
      this.selectedelement.svgcombi = svg[0].outerHTML;
      this.detectchange();
    }, 500);
  }

  deleteWhitespaceSVGWhite(): void {
    if (!this.selectedelement.svgcombi) { return }
    //this.vectorService.removeVectorPathSelection(this.selectedelement);
    //this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    setTimeout(() => {
      let element = document.getElementById(this.selectedelement.id);
      let svg = element.getElementsByTagName("svg")[0];
      let bbox = svg.getBBox();
      let viewBox = [bbox.x - 5, bbox.y - 5, bbox.width + 10, bbox.height + 10].join(" ");
      svg.setAttribute("viewBox", viewBox);
      this.selectedelement.svgcombi = svg.outerHTML;
      this.detectchange();
    }, 300);
  }


  selectPathSelectionBox() {
    let svg = document.getElementById(this.selectedelement.id);
    let p = svg.getElementsByTagName('path');
    for (let i = 0; i < p.length; i++) {
      let posstring = p[i].getBBox();
      //console.log(posstring);
    }
  }



  async onSVGsave(url): Promise<string> {
    return new Promise(async (resolve, reject) => {

      const dialogRef = this.dialog.open(DialogGetname, {
        width: '250px',
        data: { name: this.name }
      });

      dialogRef.afterClosed().subscribe(result => {
        let name = result + '.svg';
        // console.log(name)
        if (!name) { name = Math.random().toString(36).substring(7) + '.svg' }
        let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
        this.uploader = new FileUploader({ url: urluse });
        let date: number = new Date().getTime();
        let data = url;
        let contentType = '';
        const blob = new Blob([data], { type: contentType });
        // contents must be an array of strings, each representing a line in the new file
        let file = new File([blob], name, { type: "image/svg+xml", lastModified: date });
        let fileItem = new FileItem(this.uploader, file, {});
        this.uploader.queue.push(fileItem);
        let size = this.uploader.queue[0].file.size;
        // fileItem.upload();
        this.uploader.uploadAll();
        //  single upload onlly
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if (status === 200) {
            // set download url or actual url for publishing
            let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
            let setimgurl: string;
            setimgurl = 'https://app.enydea.com/api/Containers/' + this.option.id + '/download/' + name;
            imgurl = imgurl.replace(/ /g, '-');
            // define the file settings
            let newFiles = new Files();
            newFiles.name = name,
              newFiles.url = setimgurl,
              newFiles.createdate = new Date(),
              newFiles.type = 'vector',
              newFiles.companyId = this.Account.companyId,
              newFiles.size = size
            // check if container exists and create
            this.relationsApi.createFiles(this.option.id, newFiles)
              .subscribe(res => {
                this.openSnackbar('SVG saved');
                resolve(setimgurl);
              });
          }
        };
      });
    });
  }




  onshowemoji(i) {
    if (this.showemoji) { this.showemoji = false } else {
      this.showemoji = true;
    }
  }

  setemoji(event, i, element) {
    // console.log(event)
    // const bufStr = String.fromCodePoint(parseInt(event.emoji.unified, 16));
    // console.log(bufStr)
    element.content = element.content + event.emoji.native;//bufStr;
    this.onshowemoji(i)
  }


  saveAsNewVector(element?) {
    //this.vectorService.removeVectorPathSelection(this.selectedelement);
    this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    let svgel;
    if (element === undefined) {
      let sv
      svgel = document.getElementById(this.selectedelement.id);
      let svg = svgel.getElementsByTagName('svg')[0].outerHTML;
      this.selectedelement.src = this.onSVGsave(svg);
      // console.log(svg)
    } else {
      svgel = document.getElementById(element.id);
      let svg = svgel.getElementsByTagName('svg')[0].outerHTML;
      element.src = this.onSVGsave(svg);
      // console.log(svg)
    }
  }

  async resetLocalhost(): Promise<any> {
    let meta = [];
    for (let i = 0; i < this.animationarray.length; i++) {
      meta.push([])
      if (this.animationarray[i].type === 'chart') {
        for (let y = 0; y < this.animationarray[i].data.length; y++) {
          let meta1 = this.animationarray[i].data[y]._meta;
          let meta2 = this.animationarray[i].productiondata[y]._meta;
          meta[i].push(meta1);
          meta[i].push(meta2);
          delete this.animationarray[i].data[y]._meta;
          delete this.animationarray[i].productiondata[y]._meta;
        }
      }
      if (this.animationarray[i].type === 'threed') {
        this.animationarray[i].src = this.animationarray[i].src.replace('http://localhost:3000', 'https://app.enydea.com');
      }
    }
    return meta;
  }

  async saveVideo() {
    this.systembusy = true;
    this.deselectAll();
    if (!this.name) {
      let name = await this.getName(this.name);
      if (!name) { return };
      this.name = await this.checkName(name);
    }
    this.saveFinalVideo();
  }

  async addSceneToVideo() {

    this.dialogsService
      .confirm('Convert and add scene', 'Are you sure you want to do this?')
      .subscribe(async res => {
        if (!res) { return }
        if (!this.name) {
          this.name = await this.getName('');
          // console.log(this.name)
          //if (!name) { return };
          // this.name = await this.checkName(name);
          this.converttovideo(true);
        } else {
          this.converttovideo(true);
        }

      });
  }

  setVideoFile(): Files {
    let baseurl = 'https://app.enydea.com';// BASE_URL;
    let imgurl = baseurl + '/api/Containers/' + this.option.id + '/download/' + this.name;
    let screenshoturl = baseurl + '/api/Containers/' + this.option.id + '/download/' + this.name + '-screenshot.jpg';
    let setimgurl = baseurl + '/Containers/' + this.option.id + '/download/' + this.name;
    imgurl = imgurl.replace(/ /g, '-'),
      // define the file settings
      this.newFiles.name = this.name;
    this.newFiles.url = setimgurl;
    this.newFiles.createdate = new Date();
    this.newFiles.type = 'video-scene';
    this.newFiles.companyId = this.Account.companyId;
    this.newFiles.canvas = [this.canvas];
    this.newFiles.template = this.animationarray;
    this.newFiles.counter = this.counter;
    this.newFiles.screenshot = screenshoturl;
    return this.newFiles;
  }

  async saveFinalVideo() {

    let animationTMParray = JSON.parse(JSON.stringify(this.animationarray));
    for (let i = 0; i < animationTMParray.length; i++) {
      const element = animationTMParray[i];
      if (element.blobsrc) {
        element.blobsrc = null;
      }

    }

    this.saveScreenShot();
    // clean chart area for JSON
    let meta = await this.resetLocalhost();

    // console.log(this.newFiles)

    if (this.newFiles.id) {
      this.newFiles.size = new Blob([JSON.stringify(this.newFiles)]).size;
      this.newFiles.name = this.name;
      this.newFiles.createdate = new Date();
      this.newFiles.canvas = [this.canvas];
      this.newFiles.template = animationTMParray;
      this.newFiles.counter = this.counter;
      await this.relationsApi.updateByIdFiles(this.option.id, this.newFiles.id, this.newFiles).toPromise();
    } else {
      let newfile = this.setVideoFile();
      newfile.size = new Blob([JSON.stringify(newfile)]).size;
      let file = await this.relationsApi.createFiles(this.option.id, newfile).toPromise();
      this.newFiles = file;
    }
    this.openSnackbar('Video Saved!')
    this.systembusy = false;
    this.SavehistoryService.restoreChart(meta);
    this.versionsaved = true;


  }

  async saveVideoAs(): Promise<any> {
    let name = await this.getName(this.name);
    if (!name) { return };
    this.name = await this.checkName(name);
    this.newFiles.id = undefined; // no id means will create new file
    this.saveFinalVideo();
  }


  async checkName(name): Promise<string> {
    let newname = name;
    let namescount = await this.relationsApi.countFiles(this.option.id, { name: name }).toPromise();
    // console.log(namescount)
    if (namescount.count > 0) {
      let numfiles = namescount.count + 1;
      if (name.indexOf('.') !== -1) {
        let ext = '.' + name.split('.').pop();
        let newname = name.replace(ext, '');
        // console.log(newname, numfiles, ext)
        newname = newname + numfiles + ext;
      } else {
        newname = newname + numfiles;
      }
      return newname;
    } else {
      return newname;
    }
  }

  openSnackbar(message) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: 'snackbar-class'
    });
  }

  async getName(name?): Promise<string> {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: name }
    });
    let result = await dialogRef.afterClosed().toPromise();
    // console.log(result)
    if (!result) {
      this.openSnackbar('Please enter name');
    }

    return result;

  }

  async createVideoCodeSnippet() {
    if (this.newFiles.id) {
      this.dialogsService
        .confirm('Implement code', 'Do you want to save as seperate video?')
        .subscribe(async res => {
          //console.log(res)
          if (res === true) {
            await this.saveVideoAs()
            this.createCode();
          }
          else {
            await this.saveVideo();
            this.createCode();
          }
        });
    } else {
      await this.saveVideoAs();
      this.createCode();
    }

  }

  createCode() {
    let myJSON = JSON.stringify(this.canvas);
    let canvasjson = encodeURIComponent(myJSON);
    let url = 'https://dlcr.enydea.com?id=' + this.newFiles.id + '&canvas=' + canvasjson + '&repeat=false&remote=true';
    //this.snippetcode = '<iframe scrolling="no" width="' + this.canvas.width + '" height="' + this.canvas.height + '" src="' + url + 'counter="' + this.counter + '"></iframe>';

    let w = parseInt(this.canvas.width);
    let h = parseInt(this.canvas.height);
    //console.log(w, h)
    let aspectratio = (h / w) * 100;
    let containerstyle = 'overflow:hidden; padding-top:' + aspectratio + '%; position: relative;';
    //  let iframestyle = 'border:0; height:100%; left:0; position:absolute; top:0; width:100%;';
    let iframestyle = 'border:0; height:100%; width:100%;';

    //let url = 'https://dlcr.enydea.com?id=' + this.editablevideo.id + '&canvas=' + canvasjson + '&repeat=false&remote=true';
    this.snippetcode =
      //'<div style="' + containerstyle + '">' +
      '<iframe style="' + iframestyle + '" scrolling="no" frameborder="0" allowfullscreen src="' + url +
      '"></iframe>';

    //  </div>';

    this.codesnippetService.confirm('Copy Code', 'Copy code and input in your website', this.snippetcode).subscribe()
  }

  loadAsJSON() {
    var el = document.createElement('input');
    el.setAttribute('type', 'file');
    el.style.display = 'none';
    document.body.appendChild(el);
    el.onchange = () => {
      const reader = new FileReader();
      reader.onload = () => {
        //console.log(reader.result);
        let result = reader.result as string;
        let filearray = JSON.parse(result)
        document.body.removeChild(el);

        this.name = filearray.name;
        this.canvas = filearray.canvas[0];
        this.animationarray = filearray.animationarray;
        this.counter = filearray.counter;

      };
      reader.readAsText(el.files[0]);
    }
    el.click();
  }

  downloadAsJSON() {
    this.detectchange();
    let meta = [];
    for (let i = 0; i < this.animationarray.length; i++) {
      meta.push([])
      if (this.animationarray[i].type === 'chart') {
        for (let y = 0; y < this.animationarray[i].data.length; y++) {
          let meta1 = this.animationarray[i].data[y]._meta;
          let meta2 = this.animationarray[i].productiondata[y]._meta;
          meta[i].push(meta1);
          meta[i].push(meta2);
          delete this.animationarray[i].data[y]._meta;
          delete this.animationarray[i].productiondata[y]._meta;
        }
      }
    }

    let downloadjson = {
      name: this.name,
      canvas: [this.canvas],
      animationarray: this.animationarray,
      counter: this.counter,
    }
    let downloadstring = JSON.stringify(downloadjson);
    // create downloadable string
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(downloadstring);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.name + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    this.SavehistoryService.restoreChart(meta);
  }

  loadVideo() {
    this.dialogsService
      .confirm('Load video', 'Do you want to load this video?')
      .subscribe(res => {
        if (res) {
          this.loadEditableVideo();
        }
      });
  }

  async converttovideo(emit?) {
    this.dialogsService
      .confirm('Convert to video', 'Are you sure you want to do this?')
      .subscribe(async res => {
        if (!res) { return }

        this.systembusy = true;
        let name = this.name; //+ '.mp4';
        if (!emit) { // 
          name = await this.getName(name);
        }
        if (!name) { return };
        name = name.replace(/[^a-zA-Z0-9 ]/g, '') + '.mp4';
        let newname = await this.checkName(name);
        // console.log(this.name)

        //this.vectorService.removeVectorPathSelection(this.selectedelement);
        this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
        let array = this.animationarray;
        let myJSON = JSON.stringify(array);
        this.canvas.videourl = this.canvas.videourl.replace('http://localhost:3000', 'https://app.enydea.com');
        //let aniarray = encodeURIComponent(myJSON);
        if (this.name === undefined) { this.name = Math.random().toString(36).substring(7); }
        this.createVideo(newname, myJSON, emit);
        this.saveFinalVideo();
        this.appservice.openMessageDialog('Converting to Video', 'Converting to video, this can take a while you can proceed with your work and will be notified once ready.')
        // this.openSnackbar('Converting to video, this can take a while you can proceed with your work');
      });

  }

  async createVideo(newname, myJSON, emit) {
    this.filesApi.createvideo(this.option.id, this.option.companyId,
      newname, this.canvas, myJSON, this.counter)
      .subscribe(
        (res: Files) => {
          // console.log(res);
          if (emit) {
            this.newItemEvent.emit(res);

          }
          //this.downloadFile(res.url);
        }
      );
  }


  async converttogif(optimize) {

    this.systembusy = true;
    let name = this.name + '.gif';

    name = await this.getName(name);
    if (!name) { return };
    let newname = await this.checkName(name);

    this.saveFinalVideo();
    // if (this.newFiles.id) {
    //   this.dialogsService
    //     .confirm('Convert to GIF', 'Save as seperate video?')
    //     .subscribe(async res => {
    //       if (res) { await this.saveVideoAs() } else {
    //         await this.saveVideo();
    //       }
    //     });
    // }
    this.canvas.videourl = this.canvas.videourl.replace('http://localhost:3000', 'https://app.enydea.com')
    // this.vectorService.removeVectorPathSelection(this.selectedelement);
    this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    let array = this.animationarray;
    let myJSON = JSON.stringify(array);
    if (this.name === undefined) { this.name = Math.random().toString(36).substring(7); }
    this.openSnackbar('Converting to GIF this can take a while you can proceed with your work');
    this.filesApi.creategif(this.option.id, this.option.companyId,
      newname, this.canvas, myJSON, this.counter, optimize)
      .subscribe(
        (res: Files) => {
          this.openSnackbar('GIF ready');
          this.downloadFile(res.url);
        }
      );
  }


  async jsonVectors() {
    let newanimationarray = JSON.parse(JSON.stringify(this.animationarray));
    for (let index = 0; index < newanimationarray.length; index++) {
      if (newanimationarray[index].type === 'vector') {
        newanimationarray[index].svgcombi = JSON.stringify(newanimationarray[index].svgcombi);
        return newanimationarray
      }
    }
  }

  resetVideo() {
    this.dialogsService
      .confirm('Reset', 'Do you want to reset this video?')
      .subscribe(res => {
        if (res) {
          const myNode = document.getElementById('weathercontainer');
          myNode.innerHTML = '';
          this.newFiles = this.editablevideo;
          this.name = this.editablevideo.name;
          this.canvas = this.editablevideo.canvas[0];
          this.animationarray = this.editablevideo.template;
          this.counter = this.editablevideo.counter;
          this.detectchange();
        }
      });
  }

  newVideo() {
    this.selectedelement = undefined;
    this.name = '';
    this.canvas = {
      'border-radius': '',
      direction: 45,
      stripewidth: 5,
      percentagecolor1: 0,
      percentagecolor2: 0,
      percentagecolor3: 0,
      color1: '',
      color2: '',
      bgstyle: '',
      width: '1024px',
      height: '576px',
      'background-color': '#ffffff',
      'background-image': '',
      background: '',
      positiongradient: '',
      videourl: '',
      imageurl: '',
      loop: false,
      weather: '',
      audio: '',
      top: '',
      left: '',
      hovereffect: true,
      zoom: true,
      'background-image-gradient': '',
      overflow: false,
      grid: false,
      gridcolor: 'green',
      helper: false,
      shadows: false,
      materialtype: false,
      light: 1,
      plane: 'none',
      planecolor: 'green',
      pretheme: '',
      camerarotationx: 0,
      camerarotationy: 0,
      camerarotationz: 0,
      camerapositionx: 200,
      camerapositiony: 80,
      camerapositionz: 300,
      cameracontrol: true,
      walkcontrol: false,
      light1: true,
      light2: true,
      sky: false,
      sunlocation: 180,
      sunheight: 20,
      cloud: false,
      planetexture: ''
    }
    this.newFiles = undefined;
    this.animationarray = [];
    this.counter = 10;
    const myNode = document.getElementById('weathercontainer');
    myNode.innerHTML = '';
    this.detectchange();
  }

  public loadVideoGallery(event) {
    // console.log('set video edit', event)
    // let newedit = this.editablevideos.filter(function (files) {
    //   return files.id === event;
    // });
    // console.log(newedit);
    // this.editablevideo = newedit[0];
    this.loadEditableVideo(event);
  }

  public setTemplate(event) {
    // console.log(event)
  }

  loadEditableVideo(id?) {
    this.newVideo();
    this.systembusy = true;
    if (id) {
      this.relationsApi.findByIdFiles(this.option.id, id)
        .subscribe(async (video: Files) => {
          console.log(video)

          this.editablevideo = video;

          await this.checkForThreed(this.editablevideo.template); // download first all necessary components
          // console.log('set editable animsation/video')
          const myNode = document.getElementById('weathercontainer');
          myNode.innerHTML = '';
          this.newFiles = this.editablevideo;
          this.name = this.editablevideo.name;
          this.canvas = this.editablevideo.canvas[0]; //always an array not implemented change of background effects
          this.animationarray = this.editablevideo.template;
          this.counter = this.editablevideo.counter;

          if (this.canvas.videourl) {
            await this.setVideobgBlob();
          }

          // set blob image
          this.animationarray.forEach((element, i) => {
            if (element.type === 'image') {
              this.setBlob(element.src, i);
            }
            if (element.type === 'embeddedvideo') {
              this.setblobEmbeddedvideo(element.src, i);
            }
          });


          //this.setVideo(this.canvas.videourl);

          this.detectchange();
          this.systembusy = false;
        })
    } else {
      // console.log('no video')
    }
  }

  async checkForThreed(animationarray): Promise<void> {
    return new Promise(async (resolve, reject) => {
      //console.log(this.animationarray)
      for (let i = 0; i < animationarray.length; i++) {
        if (animationarray[i].type === 'threed') {
          animationarray[i].blobsrc = '';
          //console.log(this.animationarray[i])
          if (animationarray[i].src) {
            animationarray[i] = await this.getThreedFile(animationarray[i], animationarray[i].src)
          }

        }
      }
      resolve();
    });
  }


  addcell(i1): void {
    this.selectedelement.data[i1].data.push(0);
    this.selectedelement.productiondata[i1].data.push(0);
    this.selectedelement.originaldata[i1].data.push(0);
  }

  addLabel(): void {
    this.selectedelement.label.push("new label");
  }

  addgraph(): void {
    this.selectedelement.data.push({ data: [0, 0, 0], label: 'new label' });
    this.selectedelement.productiondata.push({ data: [0, 0, 0], label: 'new label' });
    this.selectedelement.originaldata.push({ data: [0, 0, 0], label: 'new label' });

    let randomnum1 = this.RandomInt(0, 8);
    let colorrandom1 = Object.assign({}, this.colorarray[randomnum1]); //assign to prevent reference with manual change
    this.selectedelement.colors.push(colorrandom1);
    this.detectchange();
  }

  deletegraph(): void {
    let del = this.selectedelement.data.length - 1;
    this.selectedelement.data.splice(del, 1);
    this.selectedelement.productiondata.splice(del, 1);
    this.selectedelement.originaldata.splice(del, 1);
    this.selectedelement.colors.splice(del, 1);
    this.detectchange();
  }

  detectchangerowcell(i1, i2, event): void {
    const cell = event.target.value; // use event ngmodel does not pick up complex layered arrays
    // change axes and value to show in canvas !do not change
    //console.log(i1, i2, cell);
    this.selectedelement.originaldata[i1].data[i2] = cell;
    this.selectedelement.data[i1].data[i2] = cell;
    let max = Math.max(... this.selectedelement.originaldata[i1]);
    let min = Math.min(... this.selectedelement.originaldata[i1]);
    if (max < cell && this.selectedelement.lineChartOptions.scales !== false) {
      this.selectedelement.lineChartOptions.scales.yAxes[0].ticks.suggestedMax = cell;
      this.selectedelement.lineChartOptions.scales.yAxes[0].ticks.suggestedMin = min;
    }
    if (min > cell && this.selectedelement.lineChartOptions.scales !== false) {
      this.selectedelement.lineChartOptions.scales.yAxes[0].ticks.suggestedMin = cell;
      this.selectedelement.lineChartOptions.scales.yAxes[0].ticks.suggestedMax = max; // throws error if not
    }
    this.detectchange();
  }

  detectchangerowlabel(i1, labelnew): void {
    this.selectedelement.data[i1].label = labelnew;
    this.selectedelement.productiondata[i1].label = labelnew;
    this.selectedelement.originaldata[i1].label = labelnew;
    this.detectchange();
  }

  detectchangeLabel(i1, label): void {
    this.selectedelement.label[i1] = label;
    this.detectchange();
  }

  detectchangetype(type): void {
    this.selectedelement.charttype = type;
    this.detectchange();
  }

  deletelabel(i1) {
    let del = this.selectedelement.label.length - 1;
    this.selectedelement.label.splice(del, 1);
    this.detectchange();
  }

  deletecell(i1) {
    let del = this.selectedelement.data[i1].data.length - 1;
    this.selectedelement.data[i1].data.splice(del, 1);
    this.selectedelement.productiondata[i1].data.splice(del, 1);
    this.selectedelement.originaldata[i1].data.splice(del, 1);
    this.detectchange();
  }


  onSpeedDialFabClicked(btn) {
    this.sideopen = true;
    console.log(btn.type);
    if (btn.type === 'form') { this.addNewForm() }
    if (btn.type === 'counter') { this.addNewCounter() }
    if (btn.type === 'image') { this.addNewImage() }
    if (btn.type === 'text') { this.addNewText() }
    if (btn.type === 'shape') { this.addNewShape() }
    if (btn.type === 'animatedimage') { this.addNewVector() }
    if (btn.type === 'draw') { this.addNewWhiteboard() }
    if (btn.type === 'chart') { this.addNewChart() }
    //if (btn.type === 'Add animation group') { this.vectorService.addNewVectorCombi(this.animationarray, this.canvas) }
    if (btn.type === '3d') { this.addNewThreed() }
    if (btn.type === 'character') { this.openDialogCharacter() }
    if (btn.type === 'video') { this.addNewEmbeddedVideo() }
  }

  movePartUp(i1, combi) {
    if (i1 > 0) {
      this.swapElement(combi, (i1 - 1), i1);
      combi.forEach((part, i) => {
        part.style['z-index'] = i;
      })
      this.detectchange();
      //console.log(i1, combi);
    }
  }

  movePartDown(i1, combi) {
    if ((combi.length - 1) > i1) {
      this.swapElement(combi, (i1 + 1), i1);
      combi.forEach((part, i) => {
        part.style['z-index'] = i;
      })
      this.detectchange();
      //console.log(i1, combi);
    }
  }

  xlsxUploaded(result) {
    //console.log(result);
    this.selectedelement.productiondata = [];
    this.selectedelement.data = [];
    this.selectedelement.originaldata = [];
    result.payload[0].forEach(element => {
      //console.log(element)
      let keys = Object.keys(element);
      let newdata = [];
      let newdummydata = [];
      this.selectedelement.label = keys.slice(1, keys.length);
      keys.forEach(key => {
        if (key !== '__EMPTY') {
          newdata.push(element[key]);
          newdummydata.push(0)
        }
      });
      this.selectedelement.data.push({ data: newdata, label: element.__EMPTY });
      this.selectedelement.originaldata.push({ data: newdata, label: element.__EMPTY });
      this.selectedelement.productiondata.push({ data: newdummydata, label: element.__EMPTY });
      this.selectedelement.colors.push(
        { // grey
          backgroundColor: '',
          borderColor: '#232222',
          pointBackgroundColor: '#232222',
          pointBorderColor: '#fff'
        }
      )
      this.detectchange();
    });
  }

  // public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  // // console.log(event, active);
  // // console.log(this.animationarray)
  // }

  public chartClicked(e, chart) {
    // console.log(e, chart)
  }

  public hideAllTechParts(element: threedanimation) {
    for (let i = 0; i < element.partpropertiesarray.length; i++) {
      element.partpropertiesarray[i]['visible'] = false;
    }
  }

  public createOutworkTechParts(element: threedanimation) {
    for (let i = 0; i < element.partpropertiesarray.length; i++) {
      element.partpropertiesarray[i]['position'].x = i;
    }
  }

  resetPostionRotation() {
    let element = this.selectedelement as threedanimation;
    this.canvas.camerarotationx = 0;
    this.canvas.camerarotationy = 0;
    this.canvas.camerarotationz = 0;
  }

  saveImage() {
    this.screenshottoggle = true;
    // let canvasArray = document.getElementsByTagName('canvas'); 
    // var strMime = "image/png";
    // let imgData = canvasArray[0].toDataURL(strMime);
    this.selectedelement = undefined;
    // check if name is set and check name duplicates 
    let name = this.name + '.png';
    if (!this.name) {
      name = Math.random().toString(36).substring(7) + '.png';
    }


    this.relationsApi.getFiles(this.option.id, { where: { name: name } })
      .subscribe(async (res) => {
        if (res.length > 0) {
          name = this.name + res.length + '.png'
        }

        //await new Promise(resolve => setTimeout(resolve, 400));
        window.scrollTo(0, 0); /* !important somehow domtoimage won't work  */
        var options = {
          quality: 0.90,
          width: parseInt(this.canvas.width),
          height: parseInt(this.canvas.height),
        };

        domtoimage.toPng(document.getElementById('videobox'), options).then(canvas => {
          this.screenshottoggle = false;
          const previewstring = '<div style="width: 100%; height: 100%;"><img style="width: auto;" src="' + canvas + '" /></div>';
          const previewhtml = [];
          previewhtml.push(this.sanitizer.bypassSecurityTrustHtml(previewstring));
          this.dialogsService
            .confirm('Preview', 'Save Image and download?', previewhtml[0])
            .subscribe((res) => {
              if (res) {
                this.downloadAndUploadToServer(canvas, name);
              }
            });
        });
      });
  }

  saveImageJPEG() {
    this.screenshottoggle = true;
    this.selectedelement = undefined;
    // check if name is set and check name duplicates 
    let name = this.name + '.jpg';
    if (!this.name) {
      name = Math.random().toString(36).substring(7) + '.jpg';
    }

    this.relationsApi.getFiles(this.option.id, { where: { name: name } })
      .subscribe(async (res) => {
        if (res.length > 0) {
          name = this.name + res.length + '.jpg'
        }

        //await new Promise(resolve => setTimeout(resolve, 400));
        window.scrollTo(0, 0); /* !important somehow domtoimage won't work  */

        var options = {
          quality: 0.90,
          width: parseInt(this.canvas.width),
          height: parseInt(this.canvas.height),
        };

        domtoimage.toJpeg(document.getElementById('videobox'), options).then(canvas => {
          this.screenshottoggle = false;

          const previewstring = '<div style="width: 400px; height: 400px;"><img src="' + canvas + '" /></div>';
          const previewhtml = [];
          previewhtml.push(this.sanitizer.bypassSecurityTrustHtml(previewstring));
          this.screenshottoggle = false;
          this.dialogsService
            .confirm('Preview', 'Save Image and download?', previewhtml[0])
            .subscribe((res) => {
              if (res) {
                this.downloadAndUploadToServer(canvas, name);
              }
            });
        });
      });
  }



  saveScreenShot() {
    this.deselectAll();
    this.screenshottoggle = true;
    window.scrollTo(0, 0);
    var options = {
      quality: 0.95,
      width: parseInt(this.canvas.width),
      height: parseInt(this.canvas.height),
    };

    domtoimage.toJpeg(document.getElementById('videobox'), options).then(canvas => {
      this.screenshottoggle = false;
      let filename = this.name + '-screenshot.jpg';
      let blob: Blob = this.dataURItoBlob(canvas);
      let date: number = new Date().getTime();
      let file = new File([blob], filename, { type: "image/jpg", lastModified: date });
      // let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
      const urluse = "https://app.enydea.com/api/Containers/" + this.option.id + "/upload";
      this.uploader = new FileUploader({ url: urluse });
      let fileItem = new FileItem(this.uploader, file, {});
      this.uploader.queue.push(fileItem);
      fileItem.upload();
      //this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {};
    });

  }

  async blurBackground() {
    let imgs = document.getElementById(this.selectedelement.id).getElementsByTagName("img");
    if (imgs.length > 0) {
      let img = imgs[0]
      this.systembusy = true;
      let dataurl = await this.tensorflowService.blurBackgroundImage(img, this.selectedelement.id);
      console.log(dataurl);
      let blob: Blob = this.dataURItoBlob(dataurl);
      let urlCreator = window.URL;
      let url = urlCreator.createObjectURL(blob);
      // upload image
      this.uploadNewImage(this.selectedelement.src, blob);
      this.selectedelement.blobsrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      //this.selectedelement.src = dataurl;
      this.systembusy = false;
    }

  }

  async ObjectDetection(){
    this.setImageSize();
    let imgs = document.getElementById(this.selectedelement.id).getElementsByTagName("img");
    if (imgs.length > 0) {
      let img = imgs[0]
      this.systembusy = true;
      let data = await this.tensorflowService.objectDetection(
        img, this.selectedelement.id,
        parseInt(this.selectedelement.style.width),
      parseInt(this.selectedelement.style.height)
      );
      console.log(data);
      this.systembusy = false;
    }
  }

  setImageSize() {

    let imgs = document.getElementById(this.selectedelement.id).getElementsByTagName("img");
    if (imgs.length === 0) { return }

    let img = imgs[0];
    if (this.selectedelement.style.height === 'auto') {
      this.selectedelement.style.height = img.clientHeight + 'px';
    }

    if (this.selectedelement.style.width === 'auto') {
      this.selectedelement.style.width = img.clientWidth + 'px';
    }
  }

  async removeBackground(blurline) {
    this.setImageSize()
    let imgs = document.getElementById(this.selectedelement.id).getElementsByTagName("img");
    if (imgs.length > 0) {
      let img = imgs[0]; // --> img from enydea are set to 100% as size is set in higher level div
      // let img_n = img.cloneNode(true) as HTMLImageElement;
      img.setAttribute('height', this.selectedelement.style.height)
      img.setAttribute('width', this.selectedelement.style.width)
      // img_n.width = this.selectedelement.style.width;
      // img_n.height = this.selectedelement.style.height;
      this.systembusy = true;


      if (blurline) {
        const dataurl = await this.tensorflowService.
          removeBackgroundImageBlurEdges(
            img,
            parseInt(this.selectedelement.style.width),
            parseInt(this.selectedelement.style.height), this.selectedelement.id, img);

            if (dataurl === 'failed'){
              this.openSnackbar('No person found in the picture or picture incompatible');
              this.systembusy = false;
            } else {
              this.setNewPicture(dataurl)
            }

        this.setNewPicture(dataurl)
      } else {
        const dataurl = await this.tensorflowService.
          removeBackgroundImage(
            img,
            parseInt(this.selectedelement.style.width),
            parseInt(this.selectedelement.style.height), this.selectedelement.id, img);

            if (dataurl === 'failed'){
              this.openSnackbar('No person found in the picture or picture incompatible');
              this.systembusy = false;
            } else {
              this.setNewPicture(dataurl)
            }
       
      }
    }
  }

  setNewPicture(blob) {
    // set new picture file
    let urlCreator = window.URL;
    let url = urlCreator.createObjectURL(blob);
    this.selectedelement.blobsrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.systembusy = false;
    this.uploadNewImage(this.selectedelement.src, blob); // turn off for testing
  }


  async uploadNewImage(url: string, blob: Blob) {
    let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    let newname = url.split('/').pop();
    let ext = '.' + newname.split('.').pop();
    newname = newname.replace(ext, '');
    let random = Math.random().toString(36).substring(7);
    let name = newname + random + '-blur.png';
    name = name.replace(/,/g, '')

    let date: number = new Date().getTime();
    let file = new File([blob], name, { type: 'image/png', lastModified: date });

    const data = new FormData()
    data.append('file', file)

    
    // now upload
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    // https://michael-holstein.medium.com/remove-background-from-person-by-using-ai-and-javascript-eb85674f9e8d
    axios.post(urluse, data, config).then(response => {
      console.log(response.data);
      //let setimgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
      let setimgurl = 'https://app.enydea.com/api/Containers/' + this.option.id + '/download/' + name;
      let size = blob.size;
  
      let newFiles = new Files();
      newFiles.name = name;
      newFiles.url = setimgurl;
      newFiles.createdate = new Date();
      newFiles.type = 'image';
      newFiles.companyId = this.Account.companyId;
      newFiles.size = size;
      newFiles.relationsId = this.option.id;
      this.selectedelement.src = setimgurl;
  
      this.relationsApi.createFiles(this.option.id, newFiles)
        .subscribe(res => {
          this.openSnackbar('Image Saved!');
        });
    })



  }

  // createThreedScreenshot() {
  //   this.selectedelement.createScreenshot = true;
  //   this.detectchange();
  // }


  Screenshot(canvas) {
    const previewstring = '<div style="width: 400px; height: 400px;"><img style="width: 400px;" src="' + canvas + '" /></div>';
    const previewhtml = [];
    previewhtml.push(this.sanitizer.bypassSecurityTrustHtml(previewstring));
    this.dialogsService
      .confirm('Preview', 'Save Image and download?', previewhtml[0])
      .subscribe((res) => {
        if (res) {
          this.downloadAndUploadToServer(canvas, name);
        }
      });
  }

  downloadAndUploadToServer(canvas, name) {
    let blob: Blob = this.dataURItoBlob(canvas);
    let date: number = new Date().getTime();
    let file = new File([blob], name, { type: "image/jpeg", lastModified: date });
    let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    this.uploader = new FileUploader({ url: urluse });
    let fileItem = new FileItem(this.uploader, file, {});
    this.uploader.queue.push(fileItem);
    fileItem.upload();
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      // set download url or actual url for publishing
      let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
      imgurl = imgurl.replace(/ /g, '-');
      // define the file settings
      let imagefile = new Files();
      imagefile.name = name,
        imagefile.url = imgurl,
        imagefile.createdate = new Date(),
        imagefile.type = 'image',
        imagefile.companyId = this.Account.companyId;

      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      element.setAttribute('href', seturl);
      element.setAttribute('download', name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);


      this.relationsApi.createFiles(this.option.id, this.newFiles)
        .subscribe(res => {
          this.openSnackbar('Image Saved!');
        });
    };
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  setBusyFromThreed(e) {
    this.systembusy = e;
  }

  createNewID(): string {
    let newelnr;
    let r = Math.random().toString(36).substring(7); // add random sring
    newelnr = r + '-el';

    // if (this.animationarray.length === -1) {
    //   newelnr = r + '-el';
    // } else {
    //   newelnr = this.animationarray.length + '-el';
    // }
    return newelnr;
  }

  async addNewVector(src?, height?, width?, svgcombi?, posx?, posy?, pathidar?, originalsize?, whiteboard?, character?): Promise<void> { //, originid?
    let svgc = '';
    let newsrc = '';
    let newheight = '300px';
    let newwidth = '300px';
    let posxset = 0;
    let posyset = 0;
    let vectanim = [];
    let nr = this.animationarray.length;
    if (posx) { posyset = posy };
    if (posy) { posxset = posx };
    if (height) { newheight = height };
    if (width) { newwidth = width };

    let newelnr = this.createNewID();
    let vectorid = newelnr + 'vec-' + 1;
    this.newz = this.newz + 1;
    // let anim: animationtype[] = [];
    // only add if new svg not split from
    if (!svgcombi || character) {
      vectanim = factoryVectorAnimationLayer();
    }

    // do not add animation from beginning with morph SVG's
    let vectors: vectorelement[] = [factoryVectorelement(src, vectorid, pathidar)];
    let motionpath = [];
    let vector = factoryVectoranimation(newwidth, newheight, motionpath, posxset, posyset, newelnr, vectors, svgcombi, vectanim, this.newz, character);
    this.animationarray.push(vector);
    nr = -1;

    this.onSelectElement(null, vector, nr);

    if (!src && !character) {
      if (!whiteboard) {
        this.deleteWhitespaceSVG();
      } else {
        this.deleteWhitespaceSVGWhite();
      }
    }

    // if (!svgcombi) {
    this.detectchange(); // detect change when seperating entire svg
    // trigger initvector because svgcombi does not trigger init
    if (character) {
      // let e = document.getElementById(this.selectedelement.id);
      // this.initVectors(e, this.selectedelement.index - 1, 0, this.selectedelement.vectors[0].idx, )
      setTimeout(() => {
        let i = this.animationarray.length - 1;
        let idx = 0;
        let vectorid = this.animationarray[i].vectors[0].idx;
        let e = document.getElementById(vectorid);
        //console.log(i, idx, vectorid, e)
        this.initVectors(e, i, idx, vectorid, true);
      }, 500);
    }
    // }
    return
  }

  async seperatePaths() {
    let pathpar = await this.vectorService.seperatePaths(this.selectedelement, this.animationarray);
    await this.addNewVector(null, pathpar.newheight + 'px', pathpar.newwidth + 'px', pathpar.svgstring, pathpar.posx, pathpar.posy);

    setTimeout(() => {
      let i = this.animationarray.length - 1;
      let idx = 0;
      let vectorid = this.animationarray[i].vectors[0].idx;
      let e = document.getElementById(vectorid);
      //console.log(i, idx, vectorid, e)
      this.initVectors(e, i, idx, vectorid,);
    }, 500);
  }

  openDialogCharacter() {
    const dialogRef = this.dialog.open(CharactercreatorComponent, {
      width: '800px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addNewVector(null, '180px', '180px', result, null, null, null, null, null, true);
      }
    });
  }

  openDialogSettings() {
    let context = {
      name: this.name,
      counter: this.counter,
      canvas: this.canvas,
      selectedvideoformat: this.selectedvideoformat,
      snaptogrid: this.snaptogrid,
      snaptogridwidth: this.snaptogridwidth,
      snaptogridheight: this.snaptogridheight
    }
    const dialogRef = this.dialog.open(GeneralsettingsComponent, {
      width: '800px',
      data: context
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('The dialog was closed', result);
        this.name = result.name;
        this.counter = result.counter;
        this.canvas = result.canvas;
        this.selectedvideoformat = result.selectedvideoformat;
        this.snaptogridwidth = context.snaptogridwidth;
        this.snaptogridheight = context.snaptogridheight;
        this.snaptogrid = context.snaptogrid;

        this.detectchange();
        this.onchangecanvas();
        if (this.selectedvideoformat) {
          this.changevideoformat()
        }
      }
    });
  }


  openDialogBackgroundSettings() {
    let context = {
      account: this.Account,
      canvas: this.canvas,
      option: this.option,
      // videourl: this.videourl,
      company: this.company
    }
    const dialogRef = this.dialog.open(BackgroundsettingsComponent, {
      width: '800px',
      data: context
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const oldvideourl = JSON.stringify(this.canvas.videourl); // check if videourl changes 

        console.log('The dialog was closed', result);
        this.canvas = result.canvas;

        if (this.canvas.videourl !== oldvideourl) {
          this.videourl = undefined;
          await this.setVideobgBlob(); //set convert to this.videourl as blob preventing reloading file after render
          this.detectchange();
          this.onchangecanvas();
        } else {
          this.detectchange();
          this.onchangecanvas();
        }
      }
    });
  }

  setVideobgBlob(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.systembusy = true;
      this.http.get(this.canvas.videourl, { responseType: 'blob' }).subscribe(blob => {
        let urlCreator = window.URL;
        this.videourl = urlCreator.createObjectURL(blob);
        this.systembusy = false;
        resolve();
      })
    });
  }

  openSplitScreen() {
    if (this.splitscreen) {
      this.splitscreen = false
    } else {
      this.splitscreen = true;
      // this.zoomfactor = 0.7;
      // this.zoomForm.setValue('0.7');
      this.onchangecanvas();
    }

  }

  async createElementHTML() {
    let snippet = await this.codegeneratorService.createHTML(this.canvas, this.selectedelement);
    this.codesnippetService.confirm('Copy Code', 'Copy code and input in your website', snippet).subscribe()
  }


  convertSVGtoThreed() {
    // console.log(this.selectedelement);
    if (this.selectedelement.svgcombi) {
      this.addNewThreed(this.selectedelement.svgcombi, this.selectedelement.vectors[0].src)
    }

  }

  convertImagatoThreed() {
    if (this.selectedelement.src && this.selectedelement.type === 'image') {
      let img = document.getElementById(this.selectedelement.id);

      //or however you get a handle to the IMG
      const width = img.clientWidth;
      const height = img.clientHeight;
      this.addNewThreed(null, null, this.selectedelement.src, null, width, height);
      //this.selectedelement.style.width, this.selectedelement.style.height
    }
  }

  convertVideotoThreed() {
    if (this.selectedelement.src && this.selectedelement.type === 'embeddedvideo') {

      this.addNewThreed(null, null, null, this.selectedelement.src, parseInt(this.selectedelement.style.width),
        parseInt(this.selectedelement.style.height));
      //this.selectedelement.style.width, this.selectedelement.style.height
    }
  }

  // double remove!
  // TextToSvg(element: textanimation) {
  //   this.filesApi.convertTextToSVG(element).subscribe((svg: string) => {
  //     //console.log(svg);
  //     // let w = svg.match('width="(.*?)"');
  //     // console.log(w)
  //     // svg = svg.replace(w[0], 'width="100%" viewBox="0 0 260 260"');
  //     // let h = svg.match('height="(.*?)"')
  //     // svg = svg.replace(h[0], 'height="100%"')
  //     svg = svg.replace(`<path fill=${element.style.color} d=""/>`, "");
  //     svg = svg.replace(`<path fill=${element.style.color} d/>`, "");
  //     svg = svg.replace(`<path fill=${element.style.color} d/>`, "");

  //     this.addNewVector(undefined, element.style.height, element.style.width, svg, element.posx, element.posy);
  //   })
  // }

  // let paths = e.getElementsByTagName("path");
  // for (let y = 0; y < path.length; y++){
  //   let path = paths[y] as SVGPathElement;
  //   let d = path.getAttribute('d');
  //   if (d === undefined){
  //     path.remove();
  //   }
  // }

  setPrimaryTimeline(e) {
    console.log(e)
    this.primairytimeline = e;
  }



}