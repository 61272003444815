<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">
    <mat-button-toggle matTooltip="Local stored vectors" style="width: 33%" value="fromaccount">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Standard vectors" [checked]="true"  style="width: 33%" value="standardvectors">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Bubble vectors" [checked]="true"  style="width: 33%" value="bubblevectors">
      <mat-icon>question_answer</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="group.value == 'standardvectors'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let vector of stockvectors; let i = index" fxFlex>
      <div [inlineSVG]="vector.url" [onSVGLoaded]="handleSVG" (click)="selectedimage(vector.url)"
        [class.selected]="vector.url === data.selected" style="max-width: 100px; max-height: 100px; margin: 10px">
      </div>
    </div>
  </div>
  <div *ngIf="group.value == 'standardvectors'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{stockvectorsSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{stockvectorsSlice}}</label>&nbsp; of &nbsp;
    <label>{{stockvectorsArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'bubblevectors'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let vector of bubblevectors; let i = index" fxFlex>
      <div [inlineSVG]="vector.url" [onSVGLoaded]="handleSVG" (click)="selectedimage(vector.url)"
        [class.selected]="vector.url === data.selected" style="max-width: 100px; max-height: 100px; margin: 10px">
      </div>
    </div>
  </div>
  <div *ngIf="group.value == 'bubblevectors'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{bubblevectorsSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{bubblevectorsSlice}}</label>&nbsp; of &nbsp;
    <label>{{bubblevectorsArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'fromaccount'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let vector of fromfile; let i = index" fxFlex>
      <div [inlineSVG]="vector.url" [onSVGLoaded]="handleSVG" (click)="selectedimage(vector.url)"
        [class.selected]="vector.url === data.selected" style="max-width: 100px; max-height: 100px; margin: 10px">
      </div>
    </div>
  </div>
  <div *ngIf="group.value == 'fromaccount'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    
    <label>{{fromfileSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{fromfileSlice}}</label>&nbsp; of &nbsp;
    <label>{{fromfileArray.length}}</label>

  </div>
  


  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>