import { Injectable } from '@angular/core';

@Injectable()
export class colorset {
    private min = 0.1;
    private max = 0.8;
    constructor() { }

    R() { return (this.min + Math.random() * (this.max - this.min)).toFixed(2) };
    

    public setColor(color) {

        if (color === 'greycolors') {
            return {
                backgroundColor: 'rgba(148,159,177, '+this.R()+')',
                borderColor: 'rgba(148,159,177,1)',
                pointBackgroundColor: 'rgba(148,159,177,1)',
                pointBorderColor: '#23549D',
                pointHoverBackgroundColor: '#23549D',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            };
        }

        if (color === 'bluecolors') {
            return {
                backgroundColor: 'rgba(50,70,168,'+this.R()+')',
                borderColor: 'rgba(50,70,168,1)',
                pointBackgroundColor: 'rgba(50,70,168,1)',
                pointBorderColor: '#23549D',
                pointHoverBackgroundColor: '#23549D',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            };
        }

        if (color === 'redcolors') {
            return {
                backgroundColor: 'rgba(242,7,46,'+this.R()+')',
                borderColor: 'rgba(242,7,46,1)',
                pointBackgroundColor: 'rgba(242,7,46,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'greencolors') {
            return {
                backgroundColor: 'rgba(43,255,0,'+this.R()+')',
                borderColor: 'rgba(43,255,0,1)',
                pointBackgroundColor: 'rgba(43,255,0,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'pinkcolors') {
            return {
                backgroundColor: 'rgba(255,0,221,'+this.R()+')',
                borderColor: 'rgba(255,0,221,1)',
                pointBackgroundColor: 'rgba(255,0,221,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'yellowcolors') {
            return {
                backgroundColor: 'rgba(252, 211, 3,'+this.R()+')',
                borderColor: 'rgba(252, 211, 3,1)',
                pointBackgroundColor: 'rgba(252, 211, 3,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'azurecolors') {
            return {
                backgroundColor: 'rgba(66, 245, 224,'+this.R()+')',
                borderColor: 'rgba(66, 245, 224,1)',
                pointBackgroundColor: 'rgba(66, 245, 224,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'purplecolors') {
            return {
                backgroundColor: 'rgba(173, 66, 245,'+this.R()+')',
                borderColor: 'rgba(173, 66, 245,1)',
                pointBackgroundColor: 'rgba(173, 66, 245,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }

        if (color === 'darkbluecolors') {
            return {
                backgroundColor: 'rgba(25, 26, 71,'+this.R()+')',
                borderColor: 'rgba(25, 26, 71,1)',
                pointBackgroundColor: 'rgba(25, 26, 71,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,159,177,0.8)'
            }
        }
    }
}