import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  Translation,
  TranslationApi,
  Relations,
  RelationsApi,
  Account,
  AccountApi,
  CompanyApi,
  Company,
} from '../shared/sdk'
import { WordpressUploadDialogComponent } from '../dialogsservice/wordpressupload-dialog.component';

import { DialogsService } from './../dialogsservice/dialogs.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadComponent } from './upload/upload.component'

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})

export class TranslationsComponent implements OnInit {

  @ViewChild(UploadComponent, { static: false })
  private uploadComponent: UploadComponent;

  public filteredRelations: Relations[];
  public MarkTranJobs;
  public MarkTranJobRes;
  public translationJob = {};
  public urlparameter: string;
  public selectedIndex = 0;
  public selectedTranslation: Translation;

  public newTranslation = new Translation();
  public jobs = [];
  public Translation: Translation[];
  selectedOption = false;
  public showconfirmation = false;
  public option: Relations = new Relations();
  public Account: Account = new Account();
  public languages;
  public listviewxsshow = false;
  public Relations: Relations[];
  public options = [];
  public company: Company;
  newexcludeitem: any;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public TranslationApi: TranslationApi,
    public dialogsService: DialogsService,
    public RelationsApi: RelationsApi,
    public snackBar: MatSnackBar,
    public dialogWordpress: MatDialog,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi
    //public WordpressUploadDialogComponent: WordpressUploadDialogComponent
  ) { }

 
  ngOnInit(): void {
    
  }

  getTranslations(){

  }

  onSelectTranslation(Translation){
    
  }


}
