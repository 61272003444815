<div style="height: 500px" fxLayout="column">
    
    <div  class="gallery" fxFlex="80%" fxLayout="row wrap">
        <div *ngFor="let image of accountimages; let i = index" fxFlex>
          <img *ngIf="image.thumb" src="{{ image.thumb }}" class="image" (click)="selectedimage(image)"
            [class.selected]="image === data.selected" />
          <div *ngIf="!image.thumb" class="image" (click)="selectedimage(image)"
            [class.selected]="image === data.selected">
            <label>{{image.name}}</label>
        </div>
        </div>
      </div>
    
      <div  style="margin-top: 5px; margin-bottom: 5px;">
        <button a mat-raised-button (click)="before()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button a mat-raised-button (click)="next()">
          <mat-icon>navigate_next</mat-icon>
        </button>
        <label>{{fromaccountsliceMin + 1}}</label>&nbsp;-&nbsp;
        <label>{{fromaccountslice + 1}}</label>&nbsp; of&nbsp;
        <label>{{fromaccountarray.length}}</label>
      </div>
  
    <div class="gallery-bottom" fxFlex="10%">
      <button a mat-raised-button (click)="dialogRef.close()" i18n>Cancel</button>
      <button a mat-raised-button [mat-dialog-close]="data.selected" i18n>
        Import
      </button>
    </div>
  </div>
  