/* tslint:disable */
import {
  Relations,
  Company,
  Marketingplanner,
  Channels,
  Forms
} from '../index';

declare var Object: any;
export interface FormentriesInterface {
  "date": Date;
  "ip"?: string;
  "processed"?: boolean;
  "url"?: string;
  "companyname"?: string;
  "hostname"?: string;
  "city"?: string;
  "region"?: string;
  "country"?: string;
  "loc"?: string;
  "org"?: string;
  "postal"?: string;
  "street"?: string;
  "lat"?: string;
  "lon"?: string;
  "ispname"?: string;
  "isp"?: boolean;
  "formcontent"?: any;
  "id"?: any;
  "relationsId"?: any;
  "companyId"?: any;
  "marketingplannerId"?: any;
  "formsId"?: any;
  relations?: Relations;
  company?: Company;
  marketingplanner?: Marketingplanner;
  channels?: Channels[];
  forms?: Forms;
}

export class Formentries implements FormentriesInterface {
  "date": Date;
  "ip": string;
  "processed": boolean;
  "url": string;
  "companyname": string;
  "hostname": string;
  "city": string;
  "region": string;
  "country": string;
  "loc": string;
  "org": string;
  "postal": string;
  "street": string;
  "lat": string;
  "lon": string;
  "ispname": string;
  "isp": boolean;
  "formcontent": any;
  "id": any;
  "relationsId": any;
  "companyId": any;
  "marketingplannerId": any;
  "formsId": any;
  relations: Relations;
  company: Company;
  marketingplanner: Marketingplanner;
  channels: Channels[];
  forms: Forms;
  constructor(data?: FormentriesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Formentries`.
   */
  public static getModelName() {
    return "Formentries";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Formentries for dynamic purposes.
  **/
  public static factory(data: FormentriesInterface): Formentries{
    return new Formentries(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Formentries',
      plural: 'Formentries',
      path: 'Formentries',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "ip": {
          name: 'ip',
          type: 'string'
        },
        "processed": {
          name: 'processed',
          type: 'boolean',
          default: true
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "companyname": {
          name: 'companyname',
          type: 'string'
        },
        "hostname": {
          name: 'hostname',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "region": {
          name: 'region',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "loc": {
          name: 'loc',
          type: 'string'
        },
        "org": {
          name: 'org',
          type: 'string'
        },
        "postal": {
          name: 'postal',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "lat": {
          name: 'lat',
          type: 'string'
        },
        "lon": {
          name: 'lon',
          type: 'string'
        },
        "ispname": {
          name: 'ispname',
          type: 'string'
        },
        "isp": {
          name: 'isp',
          type: 'boolean',
          default: false
        },
        "formcontent": {
          name: 'formcontent',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "relationsId": {
          name: 'relationsId',
          type: 'any'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
        "marketingplannerId": {
          name: 'marketingplannerId',
          type: 'any'
        },
        "formsId": {
          name: 'formsId',
          type: 'any'
        },
      },
      relations: {
        relations: {
          name: 'relations',
          type: 'Relations',
          model: 'Relations',
          relationType: 'belongsTo',
                  keyFrom: 'relationsId',
          keyTo: 'id'
        },
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        marketingplanner: {
          name: 'marketingplanner',
          type: 'Marketingplanner',
          model: 'Marketingplanner',
          relationType: 'belongsTo',
                  keyFrom: 'marketingplannerId',
          keyTo: 'id'
        },
        channels: {
          name: 'channels',
          type: 'Channels[]',
          model: 'Channels',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'publicationsId'
        },
        forms: {
          name: 'forms',
          type: 'Forms',
          model: 'Forms',
          relationType: 'belongsTo',
                  keyFrom: 'formsId',
          keyTo: 'id'
        },
      }
    }
  }
}
