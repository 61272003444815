<div class="addtobutton">
    <button mat-fab color="primary" matTooltip-i18n matTooltip="Create new Form" (click)="newForm()">
        <mat-icon>add</mat-icon>
    </button>
    <button mat-fab color="primary" matTooltip-i18n matTooltip="Get implementation code" (click)="createCode()">
        <mat-icon>code</mat-icon>
    </button>
    <button mat-fab color="primary" matTooltip-i18n matTooltip="Save form" (click)="saveForm()">
        <mat-icon>save</mat-icon>
    </button>
</div>
<div class="listshowcontainer" fxShow fxHide.gt-xs>
    <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>



<div class="card-left-menu" style="width: 100%;" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">

    <div cdkDropListGroup fxLayout="row" (blur)="saveForm()">
        <div fxFlex="100%" class="template" fxLayout="row">

            <div class="formmenu" (click)="sideToggle()">
                <mat-icon>menu_open</mat-icon>
            </div>

            <mat-drawer-container style="width: 100%">
                <mat-drawer #drawer mode="over" [opened]="sideopen" class="sitebar">
                    <div class="card-left-menu" fxLayout="column">

                        <h4>Search</h4>
                        <mat-form-field appearance="outline">
                            <input autocomplete="false" matInput #searchTerm id="seach-box"
                                (keyup.enter)="searchForms(searchTerm.value)" i18n-placeholder
                                placeholder="Name/Address" />
                        </mat-form-field>

                        <div fxFlex fxLayout="column">
                            <button a mat-raised-button color="primary" (click)="searchForms(searchTerm.value)">
                                <mat-icon>search</mat-icon>
                                <label i18n>Search Result</label>
                            </button>
                            <button a mat-raised-button (click)="getForms()" i18n>
                                Show All
                            </button>
                            <div *ngFor="let form of Forms" (click)="onSelectForm(form)">
                                <span [class.selected]="form === selectedForm" class="listbox">{{ form.title }}</span>
                            </div>
                        </div>

                        <mat-select i18n-placeholder placeholder="Max number of results" [(ngModel)]="limitresult"
                            name="limitresult">
                            <mat-option *ngFor="let number of numbers" [value]="number.value">
                                {{ number.viewValue }}
                            </mat-option>
                        </mat-select>
                    </div>
                </mat-drawer>
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Edit form" fxLayout="column">

                        <mat-accordion *ngIf="selectedForm">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <label i18n>Form settings</label>
                                </mat-expansion-panel-header>
                                <div fxLayout="column">
                                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around start">
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Form Box color</mat-label>
                                            <input matInput [style.background]="selectedForm.color"
                                                [(colorPicker)]="selectedForm.color" [cpCancelButton]="true"
                                                [(ngModel)]="selectedForm.color" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [cpOutputFormat]="hex"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Text color</mat-label>
                                            <input matInput [style.background]="selectedForm.textcolor"
                                                (colorPickerChange)="setFormStyle()"
                                                [(colorPicker)]="selectedForm.textcolor" [cpCancelButton]="true"
                                                [cpOutputFormat]="hex" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [(ngModel)]="selectedForm.textcolor"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Highlight color</mat-label>
                                            <input matInput [style.background]="selectedForm.highlight"
                                                (colorPickerChange)="setFormStyle($event)"
                                                [(colorPicker)]="selectedForm.highlight" [cpCancelButton]="true"
                                                [cpOutputFormat]="hex" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [(ngModel)]="selectedForm.highlight"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>suggestion color</mat-label>
                                            <input matInput [style.background]="selectedForm.suggestioncolor"
                                                (colorPickerChange)="setFormStyle($event)"
                                                [(colorPicker)]="selectedForm.suggestioncolor" [cpCancelButton]="true"
                                                [cpOutputFormat]="hex" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [(ngModel)]="selectedForm.suggestioncolor"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>
                                        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="end start">
                                            <button mat-icon-button [matMenuTriggerFor]="menumarketing" class="menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menumarketing="matMenu">
                                                <button mat-menu-item (click)="openDialogDelete()">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Delete</span>
                                                </button>
                                                <button mat-menu-item (click)="newForm()">
                                                    <mat-icon>note_add</mat-icon>
                                                    <span>New</span>
                                                </button>
                                                <button mat-menu-item (click)="copyForm()">
                                                    <mat-icon>content_copy</mat-icon>
                                                    <span>Copy</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <br>
                                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
                                        <!-- <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <input [(ngModel)]="selectedForm.width" matInput type="number" name="width"
                                                matInput>
                                            <mat-label i18n>Width</mat-label>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <mat-select [(ngModel)]="selectedForm.wform">
                                                <mat-option value="px">px</mat-option>
                                                <mat-option value="%">%</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <input [(ngModel)]="selectedForm.height" matInput type="number"
                                                name="height" matInput>
                                            <mat-label i18n>Height</mat-label>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <mat-select [(value)]="selectedForm.hform">
                                                <mat-option value="px">px</mat-option>
                                                <mat-option value="%">%</mat-option>
                                            </mat-select>
                                        </mat-form-field> -->
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Select font</mat-label>
                                            <mat-select [(ngModel)]="selectedForm.font"
                                                (selectionChange)="setFormStyle()">
                                                <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                    <label [style.font-family]="font.value">{{font.name}}</label>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" (change)="setFontSize()">
                                            <input [(ngModel)]="fontsize" matInput type="number" name="width">
                                            <mat-hint i18n>fontsize</mat-hint>
                                        </mat-form-field>

                                    </div>
                                    <br>
                                    <div fxLayout="row" fxLayoutGap="20px">
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Select action after send</mat-label>
                                            <mat-select i18n-placeholder placeholder="Select action after send"
                                                name="item" [(ngModel)]="selectedForm.function">
                                                <mat-option *ngFor="let item of formfunctions" [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="width: 300px;">
                                            <mat-label i18n>Thank you note</mat-label>
                                            <input matInput i18n-placeholder
                                                placeholder="Thank you we willl get back to you as as possible."
                                                [(ngModel)]="selectedForm.thankyoutext" name="thankyoutext">
                                        </mat-form-field>
                                        <label i18n>Thank you image:</label>
                                        <app-fileupload *ngIf="!selectedForm.thankyouimgsrc" [account]="Account"
                                            [option]="option" (imgurl)="setThankImage($event)">
                                        </app-fileupload>
                                        <div *ngIf="selectedForm.thankyouimgsrc">
                                            <img src="{{selectedForm.thankyouimgsrc}}" width="50" height="auto">
                                            <mat-icon class="iconsize-10" matTooltip-i18n matTooltip="Delete image"
                                                (click)="selectedForm.thankyouimgsrc = '' ">delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <br>
                        <div fxLayout="row">
                            <div fxFlex="20%" *ngIf="selectedForm" class="tools">
                                <div cdkDropList #toolsetList="cdkDropList" [cdkDropListData]="toolset"
                                    class="tools-list" (cdkDropListDropped)="drop($event)" id="toolboxid"
                                    *ngIf="toolset">
                                    <span i18n>Tools</span>
                                    <br style="margin: 5px">
                                    <div class="tool-box" *ngFor="let item of toolset" cdkDrag id="toolboxid">
                                        <div *ngIf="item.type === 'formimage'" matTooltip-i18n matTooltip="add image">
                                            <mat-icon class="iconsize-18" matSuffix>add_photo_alternate</mat-icon>
                                            <label i18n>Image</label>
                                        </div>
                                        <div *ngIf="item.type === 'formtext'" matTooltip-i18n matTooltip="add text">
                                            <mat-icon class="iconsize-18" matSuffix>text_fields</mat-icon>
                                            <label i18n>Text block</label>
                                        </div>
                                        <div *ngIf="item.type === 'formbutton'" matTooltip-i18n matTooltip="add button">
                                            <mat-icon class="iconsize-18" matSuffix>add_box</mat-icon>
                                            <label i18n>Button</label>
                                        </div>
                                        <div *ngIf="item.type === 'formdivider'" matTooltip-i18n
                                            matTooltip="add divider">
                                            <mat-icon class="iconsize-18" matSuffix>line_style</mat-icon>
                                            <label i18n>Divider</label>
                                        </div>
                                        <div *ngIf="item.type === 'formcheckbox'" matTooltip-i18n
                                            matTooltip="add checkbox">
                                            <mat-icon class="iconsize-18" matSuffix>check_box</mat-icon>
                                            <label i18n>Checkbox</label>
                                        </div>
                                        <div *ngIf="item.type === 'forminput'" matTooltip-i18n
                                            matTooltip="add input form">
                                            <mat-icon class="iconsize-18" matSuffix>input</mat-icon>
                                            <label i18n>Inputfield</label>
                                        </div>
                                        <div *ngIf="item.type === 'formselectionfield'" matTooltip-i18n
                                            matTooltip="add selection field">
                                            <mat-icon class="iconsize-18" matSuffix>fact_check</mat-icon>
                                            <label i18n>Selectionbox</label>
                                        </div>
                                        <div *ngIf="item.type === 'forminputtextfield'" matTooltip-i18n
                                            matTooltip="add input text form">
                                            <mat-icon class="iconsize-18" matSuffix>edit_note</mat-icon>
                                            <label i18n>Input Text Box</label>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                                <br>
                                <div *ngIf="selectedelement" class="tools-list" fxLayout="column" fxLayoutGap="20px">
                                    <span style="margin: 10px 10px;">Customize Element</span>

                                    <div *ngIf="selectedelement.type === 'formcheckbox'" class="formelement">
                                        <mat-form-field appearance="outline">
                                            <input matInput i18n-placeholder placeholder="checkbox text" value="value"
                                                [(ngModel)]="selectedelement.text" name="checktext">
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="selectedelement.type === 'formbutton'" class="formselectedelement">
                                        <mat-form-field appearance="outline">
                                            <input matInput i18n-placeholder placeholder="formbutton text" value="value"
                                                [(ngModel)]="selectedelement.text" name="buttontext">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <input [(ngModel)]="selectedelement.width" matInput type="number"
                                                name="width">
                                            <mat-hint i18n>Width</mat-hint>
                                        </mat-form-field>
                                        <br>


                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Text Color</mat-label>
                                            <input matInput [style.background]="selectedelement.textcolor"
                                                [(colorPicker)]="selectedelement.textcolor" [cpCancelButton]="true"
                                                [(ngModel)]="selectedelement.textcolor"
                                                [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                [cpOutputFormat]="hex"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Button Color</mat-label>
                                            <input matInput [style.background]="selectedelement.color"
                                                [(colorPicker)]="selectedelement.color" [cpCancelButton]="true"
                                                [(ngModel)]="selectedelement.color" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [cpOutputFormat]="hex"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-form-field>


                                        <br>
                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="selectedelement.align"
                                                (selectionChange)="saveForm()">
                                                <mat-option value="center" i18n>Center</mat-option>
                                                <mat-option value="start" i18n>Left</mat-option>
                                                <mat-option value="end" i18n>Right</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <br>
                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="selectedelement.action"
                                                (selectionChange)="saveForm()">
                                                <mat-option value="send form" i18n>Send form</mat-option>
                                                <mat-option value="to url" i18n>Navigate to URL</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <br>
                                        <mat-form-field appearance="outline"
                                            *ngIf="selectedelement.action === 'to url'">
                                            <input matInput i18n-placeholder placeholder="URL"
                                                [(ngModel)]="selectedelement.url" name="url">
                                        </mat-form-field>
                                    </div>
                                    <br>
                                    <div *ngIf="selectedelement.type === 'formtext'" class="formselectedelement">
                                        <mat-form-field appearance="outline">
                                            <input matInput i18n-placeholder placeholder="Plan text box" value="value"
                                                [(ngModel)]="selectedelement.text" name="textbox">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" style="max-width: 80px;">
                                            <input matInput type="number" name="width"
                                                (change)="setFontSize(selectedelement)"
                                                [(ngModel)]="formelementfontsize">
                                            <mat-label i18n>fontsize</mat-label>
                                        </mat-form-field>

                                    </div>

                                    <div *ngIf="selectedelement.type === 'forminput'" class="formselectedelement">
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Input text header</mat-label>
                                            <input matInput i18n-placeholder placeholder="Input text header"
                                                value="value" [(ngModel)]="selectedelement.text" name="inputtextheader">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Input type</mat-label>
                                            <mat-select i18n-placeholder placeholder="Select input type"
                                                [(ngModel)]="selectedelement.valuetype" name="type">
                                                <mat-option *ngFor="let type of inputtypes" [value]="type">
                                                    {{type}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Input box appearance</mat-label>
                                            <mat-select i18n-placeholder placeholder="Select appearance"
                                                [(ngModel)]="selectedelement.appearance" name="appearance">
                                                <mat-option *ngFor="let appear of appearance" [value]="appear">
                                                    {{appear}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>



                                        <br>
                                        <mat-checkbox [(ngModel)]="selectedelement.required"
                                            i18n>required</mat-checkbox>
                                    </div>

                                    <div *ngIf="selectedelement.type === 'forminputtextfield'"
                                        class="formselectedelement">
                                        <mat-form-field appearance="outline">
                                            <input matInput i18n-placeholder placeholder="checkbox text" value="value"
                                                [(ngModel)]="selectedelement.text" name="checkboxtext">
                                        </mat-form-field>
                                        <!-- <mat-form-field appearance="outline">
                                            <mat-select i18n-placeholder placeholder="Select input type"
                                                [(ngModel)]="selectedelement.valuetype" name="type">
                                                <mat-option *ngFor="let type of inputtypes" [value]="type">
                                                    {{type}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field> -->

                                        <mat-form-field appearance="outline">
                                            <mat-select i18n-placeholder placeholder="Select appearance"
                                                [(ngModel)]="selectedelement.appearance" name="appearance">
                                                <mat-option *ngFor="let appear of appearance" [value]="appear">
                                                    {{appear}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <br>
                                        <mat-checkbox [(ngModel)]="selectedelement.required"
                                            i18n>required</mat-checkbox>
                                    </div>

                                    <div *ngIf="selectedelement.type === 'formselectionfield'"
                                        class="formselectedelement">
                                        <mat-form-field appearance="outline">
                                            <input matInput i18n-placeholder placeholder="selectionbox text"
                                                value="value" [(ngModel)]="selectedelement.text" name="chkboxtext">
                                        </mat-form-field>
                                        <div fxLayout="column" fxLayoutAlign="space-around">
                                            <label i18n>Selection options</label>
                                            <br>
                                            <div
                                                *ngFor="let option of selectedelement.selectionoptions; let i = index;">
                                                <div fxLayout="row">
                                                    <mat-form-field appearance="outline">
                                                        <input matInput [value]="option" (blur)="saveOption($event, i)">
                                                    </mat-form-field>
                                                    <mat-icon (click)="deleteSelectionOption(i)" class="iconsize-10"
                                                        i18n>
                                                        delete
                                                    </mat-icon>
                                                </div>
                                            </div>
                                            <button a mat-raised-button
                                                (click)="selectedelement.selectionoptions.push('new')" mat-button
                                                i18n>Add
                                                option</button>
                                            <button a mat-raised-button (click)="selectedelement.selectionoptions = []"
                                                mat-button i18n>Reset</button>
                                        </div>
                                        <br>
                                        <mat-select i18n-placeholder placeholder="Select appearance"
                                            [(ngModel)]="selectedelement.appearance" name="appearance">
                                            <mat-option *ngFor="let appear of appearance" [value]="appear">
                                                {{appear}}
                                            </mat-option>
                                        </mat-select>
                                        <br>
                                        <mat-checkbox [(ngModel)]="selectedelement.required"
                                            i18n>required</mat-checkbox>
                                    </div>

                                    <div *ngIf="selectedelement.type === 'formimage'" class="formselectedelement">
                                        <app-fileupload *ngIf="!selectedelement.src" [account]="Account"
                                            [option]="option" (imgurl)="setImage($event)">
                                        </app-fileupload>
                                        <img *ngIf="selectedelement.src" src="{{selectedelement.src}}" width="50"
                                            height="auto">
                                        <mat-icon (click)="selectedelement.src = ''" class="icon-label"
                                            class="iconsize-12">
                                            delete
                                        </mat-icon>
                                        <div fxLayout="row">
                                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                                <input [(ngModel)]="selectedelement.height" matInput type="number"
                                                    name="height">
                                                <mat-hint i18n>Height</mat-hint>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" style="max-width: 80px;">
                                                <input [(ngModel)]="selectedelement.width" matInput type="number"
                                                    name="width">
                                                <mat-hint i18n>Width</mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="selectedelement.type === 'divider'" class="formselectedelement">
                                        <mat-divider></mat-divider>
                                    </div>

                                    <mat-checkbox [(ngModel)]="selectedelement.optional">Show only
                                        when:</mat-checkbox>

                                    <mat-form-field appearance="outline" *ngIf="selectedelement.optional">
                                        <mat-select i18n-placeholder placeholder="This field:"
                                            [(ngModel)]="selectedelement.optionalfield">
                                            <ng-container *ngFor="let row of selectedForm.template">
                                                <mat-option *ngFor="let fieldel of row" [value]="fieldel.fieldid">
                                                    <div *ngIf="fieldel.type !== 'text' &&  fieldel.type !== 'divider'">
                                                        {{fieldel.text}}
                                                    </div>
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Contains value</mat-label>
                                        <input matInput i18n-placeholder placeholder="Contains value" value="value"
                                            [(ngModel)]="selectedelement.optionfieldvalue" name="value">
                                    </mat-form-field>

                                </div>
                            </div>
                            <div *ngIf="selectedForm" on-focusout="saveForm()" fxLayout="column" class="formeditfield">

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Form name</mat-label>
                                    <input matInput i18n-placeholder placeholder="Title" value="value"
                                        [(ngModel)]="selectedForm.title" name="title">
                                </mat-form-field>


                                <!-- [style.height]="selectedForm.height + selectedForm.hform" [style.width]="selectedForm.width + selectedForm.wform" -->
                                <div id="formroot" [style.color]="selectedForm.textcolor"
                                    [style.background-color]="selectedForm.color" class="formstyle rootenydea"
                                    [style.font-family]="selectedForm.font">
                                    <!-- cdkDropList #formtemplateList="cdkDropList" [cdkDropListData]="selectedForm.template" (cdkDropListDropped)="drop($event, i)" cdkDrag -->
                                    <div *ngFor="let row of selectedForm.template; let i = index;"
                                        style="min-height: 50px;">

                                        <div *ngIf="row.length === 0" (cdkDropListDropped)="dropRow($event)"
                                            style="width: 100%; height: 50px" id="template" [cdkDropListData]="row"
                                            cdkDropList #formtemplateList="cdkDropList"></div>

                                        <div cdkDropList fxLayout="row" fxLayoutGap="px" #formtemplateList="cdkDropList"
                                            [cdkDropListData]="row" cdkDropListOrientation="horizontal"
                                            (cdkDropListDropped)="drop($event)" id="element">
                                            <div *ngFor="let element of row; let i2 = index" cdkDrag
                                                (click)="onSelectElement(element)" style="width: 100%">
                                                <div fxFlex *ngIf="element.type === 'formcheckbox'" class="formelement">
                                                    <mat-checkbox>{{element.text}}</mat-checkbox>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'formbutton'" class="formelement"
                                                    [fxLayoutAlign]="element.align">
                                                    <button [style.color]="element.textcolor"
                                                        [style.background-color]="element.color"
                                                        [style.width]="element.width + 'px'"
                                                        mat-raised-button>{{element.text}}</button>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'formtext'" class="formelement">
                                                    <label [style.font-size]="element.fontsize">{{element.text}}</label>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'forminput'" class="formelement">
                                                    <mat-form-field appearance="outline" style="width: 100%;"
                                                        [appearance]="element.appearance">
                                                        <mat-label>{{element.text}}</mat-label>
                                                        <input matInput [placeholder]="element.text"
                                                            [type]="element.valuetype">
                                                    </mat-form-field>
                                                    <label>{{element.description}}</label>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'forminputtextfield'"
                                                    class="formelement">
                                                    <mat-form-field [appearance]="element.appearance"
                                                        style="width: 100%;">
                                                        <mat-label>{{element.text}}</mat-label>
                                                        <textarea matInput [placeholder]="element.text" type="text"
                                                            cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                            cdkAutosizeMinRows="1"></textarea>
                                                    </mat-form-field>
                                                    <label>{{element.description}}</label>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'formselectionfield'"
                                                    class="formelement">
                                                    <mat-form-field appearance="outline" style="width: 100%;"
                                                        [appearance]="element.appearance">
                                                        <mat-label>{{element.text}}</mat-label>
                                                        <mat-select name="item" [placeholder]="element.text">
                                                            <mat-option *ngFor="let item of element.selectionoptions"
                                                                [value]="item">
                                                                <label>{{item}}</label>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'formimage'" class="formelement"
                                                    fxLayoutAlign="center center">
                                                    <img [src]="element.src" [width]="element.width"
                                                        [height]="element.height">
                                                </div>

                                                <div fxFlex *ngIf="element.type === 'divider'" class="formelement">
                                                    <mat-divider></mat-divider>
                                                </div>



                                                <div fxLayout="column">
                                                    <mat-icon class="iconsize-10 deleteicon" (click)="deleteItem(i, i2)"
                                                        matTooltip="delete element">
                                                        delete
                                                    </mat-icon>
                                                    <mat-icon class="iconsize-10 deleteicon" (click)="itemCopy(i, i2)"
                                                        matTooltip="Copy element">content_copy</mat-icon>
                                                    <mat-icon *ngIf="element.optional" color="primary"
                                                        class="iconsize-10 deleteicon"
                                                        matTooltip={{element.optionfieldvalue}}>quiz</mat-icon>
                                                </div>


                                            </div>
                                        </div>
                                        <mat-icon class="iconsize-10 deleteicon" (click)="deleteRow(i)"
                                            matTooltip="delete row">delete
                                        </mat-icon>
                                        <mat-icon class="iconsize-10 deleteicon" (click)="rowUp(i)"
                                            matTooltip="move row up">arrow_drop_up</mat-icon>
                                        <mat-icon class="iconsize-10 deleteicon" (click)="rowDown(i)"
                                            matTooltip="move row down">arrow_drop_down</mat-icon>
                                    </div>
                                    <div class="dropnewrow" cdkDropList #formtemplateList="cdkDropList"
                                        [cdkDropListData]="selectedForm.template" fxLayout="row"
                                        style="font-family: Roboto;" (cdkDropListDropped)="dropNew($event)" id="element"
                                        fxLayoutAlign="center center">
                                        <label style="color: grey;" i18n>DROP NEW</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Form entries">
                        <app-formentries [Account]="Account" [SelectedRelation]="SelectedRelation" [option]="option"
                            [company]="company" [selectedForm]="selectedForm">
                        </app-formentries>
                    </mat-tab>
                </mat-tab-group>
            </mat-drawer-container>
        </div>
        <!-- Formcheckbox, Formbutton, Formtext, Forminputfield, Formselectionfield, FormImage, FormDivider  -->
    </div>


</div>