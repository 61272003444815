import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  LoopBackAuth,
  Contactpersons,
  ContactpersonsApi,
  CallsApi,
  Calls,
  RelationsApi,
  Relations,
  Company,
  CompanyApi,
  Account,
  AccountApi,
  Googleanalytics,
  GoogleanalyticsApi,
  Twitter,
  TwitterApi,
  Linkedin,
  LinkedinApi,
  Facebook,
  FacebookApi,
  Pinterest,
  PinterestApi,
  Container,
  ContainerApi,
  Files,
  FilesApi,
  Adwords,
  AdwordsApi,
  MailingApi,
  ContainersecureApi,
  Opportunities,
  OpportunitiesApi,
  MailinglistApi,
  Mailinglist,
  LinkApi,
  Link,
  Emailhandler,
  EmailhandlerApi,
  UnsortedcallsApi,
  FireLoopRef
} from '../shared/sdk';
import { BASE_URL, API_VERSION } from '../shared/base.api';
import { DialogsService } from './../dialogsservice/dialogs.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LinkedinService } from '../shared/socialservice';
import { MatDialog } from '@angular/material/dialog';
import { fonts } from '../shared/listsgeneral/fonts';
import { GoogleMapService } from '../shared/googlemapservice/googlemap.service';
import { Observable } from 'rxjs';
import { map, startWith } from "rxjs/operators";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OnDestroy } from '@angular/core';
import { SpeechRecognitionService } from '../shared/speechservice/speechservice';
import { fontoptions } from './../settings/google-fonts-list';
import { DomSanitizer } from '@angular/platform-browser';
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
import { TextEditorDialog } from '../marketing/maileditor/texteditordialog.component';
import { MailingSelectDialog } from '../marketing/maileditor/mailingselectdialog.component';
import { FileUploader } from 'ng2-file-upload';
import { FileItem, FileUploaderOptions } from 'ng2-file-upload';
const URL = "https://app.enydea.com/api/Containers/images/upload";
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrawlerComponent } from './crawler/crawler.component';
import { GraphService } from '../settings/graph365/graph.service';
import { AuthService } from '../settings/auth365.service';
import { send } from 'process';
import { industries } from '../shared/listsgeneral/industries';
import domtoimage from 'dom-to-image-improved';
import { promises } from 'dns';
import { ElementRef } from '@angular/core';
import { VideoPlayerConfig } from '../imaging/videoeditor/videoplayer/models/ngx-thumbnail-video.models';
import { ChecknameService } from '../shared/checkname.service';
import { AppserviceService } from '../appservice.service';


class Tasklist {
  public task: string;
}

class Notes {
  public note: string;
}

class Attendee {
  public attendent: string;
}

class Item {
  quantity: number;
  description: string;
  price: string;
  VAT: number;
  itemtotal: number;
  totalVAT: number;
}

class Member {
  accountId: string;
  username: string;
}


@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.scss']
})
export class RelationComponent implements OnInit {

  @ViewChild(CrawlerComponent, { static: false })
  private CrawlerComponent: CrawlerComponent;

  @ViewChild('relationname') relationnameElement: ElementRef;
  @ViewChild('contactpersonfirstname') contactpersonfirstnameElement: ElementRef;

  // public compannyseccolor = 'white';
  // public companypricolor = 'white';
  public fontlist: string[] = fontoptions;
  showSearchButton: boolean;
  speechData: string;
  public AccessToken: any;
  searchOpportunity = '';
  public Link: Link[];
  public listname = ''
  public suggestedSocialMedia = [];
  public Relations: Relations[] = [];
  public Contactpersons: Contactpersons[];

  public Calls: Calls[];
  public Company: Company;
  public Account: Account = new Account();
  public Container: Container[];
  public Files: Files[];
  public newFiles: Files = new Files();
  public newSecFiles: Files = new Files();
  public attachmentFiles: Files[];

  public selectedRelation: Relations;
  public selectedContactperson: Contactpersons;
  public selectedCall: Calls;
  public Fonts = fonts;

  public searchboxFiles: string;
  public publisher: any;
  public errorMessage;
  public selectedOption = false;
  public error;
  public Opportunities: Opportunities[] = [];
  public selectedOpportunity: Opportunities;

  public result: any;
  public data
  public time: Date;

  public callindex: any;
  public contactpersonindex: any;
  public relationindex: any;

  public tasklist: Tasklist[] = [];
  public tasklistOp: Tasklist[] = [];
  public task: string;

  public attendeelist = [];
  public notes: Notes[] = [];
  public notesOp: Notes[] = [];
  public note: string;
  public attendee: Attendee[] = [];
  public attendeeOp: Attendee[] = [];
  public attendent: string;

  public address: string;
  public lat: number;
  public lng: number;

  public options = [];
  public option: Contactpersons = new Contactpersons();
  public relationsOptions = [];
  public relationsOption: Relations = new Relations();
  //public readytosend = false;



  public opportunityCount = 0;

  public newURL: string;
  public urldownload;
  public searchterm = "";
  public mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#ff4400"
        },
        {
          "saturation": -100
        },
        {
          "lightness": -2
        },
        {
          "gamma": 0.72
        }
      ]
    }];

  //standard limit for pagination
  public skip = 0;
  public limit = 20;
  public skipCalls = 0;
  public limitCalls = 20;
  public totalrelationcount: number = 0;
  public totalCallcount: number;
  public selectstatus: string;
  public selecttype: string;
  public selectconst1: string;
  public selectconst2: string;
  public selectconst3: string;
  public selectconst4: string;
  public selectmarketingrelation = false;
  public orderbyselect: 'relationname ASC';
  public orderby = [
    { value: 'relationname ASC', name: $localize`Ascending Relations Name` },
    { value: 'relationname DESC', name: $localize`Descending Relations Name` },
    { value: 'status ASC', name: $localize`Ascending Status` },
    { value: 'status DESC', name: $localize`Descending Status` },
    { value: 'updatedate DESC', name: $localize`Most recent updated` },
    { value: 'updatedate ASC', name: $localize`Least recent updated` }
  ]

  public mailtolink;
  public emailtosendto: Contactpersons[];
  public emailtosendcc: Contactpersons[];
  public emailtosendbcc: Contactpersons[];
  public cc;
  public bcc;

  public recordactive = false;
  public videolist = [];
  public imagelist = [];
  public misclist = [];
  public seclist = [];
  public selectedfile: Files;

  public Emailhandler: Emailhandler;
  public Team: Member[];
  public mailto = new UntypedFormControl();
  public mailcc = new UntypedFormControl();
  public mailbcc = new UntypedFormControl();
  public industryselect = new UntypedFormControl();
  public showprogressbarbusy = false;
  videooptions: VideoPlayerConfig = {
    width: '500px',
    height: '500px'
  };

  calltype = [
    { value: 'PhoneCall', viewValue: $localize`Phone Call` },
    { value: 'E-mail', viewValue: $localize`E-mail` },
    { value: 'Meeting', viewValue: $localize`Meeting` },
    { value: 'ConferenceCall', viewValue: $localize`Conference Call` },
    { value: 'LinkedinMessage', viewValue: $localize`Linkedin Message` },
    { value: 'Other', viewValue: $localize`Other` }
  ];

  statustype = [
    { viewValue: 'All' },
    { value: 'not attempted', viewValue: $localize`Not attempted` },
    { value: 'attempted', viewValue: $localize`Attempted` },
    { value: 'contacted', viewValue: $localize`Contacted` },
    { value: 'schedule call', viewValue: $localize`Schedule Call` },
    { value: 'schedule meeting', viewValue: $localize`Schedule Meeting` },
    { value: 'new opportunity', viewValue: $localize`New opportunity` },
    { value: 'additional contact', viewValue: $localize`Additional contact` },
    { value: 'disqualified', viewValue: $localize`Disqualified` }
  ];

  public dynamic = [
    '%recipient_name%',
    '%recipient.company%',
    '%recipient%',
    '%recipient.firstname%',
    '%recipient.lastname%',
    '%recipient.lastname%',
    '%unsubscribe_url%',
    '%unsubscribe%',
    '%signature%'
  ]

  public attachmentUploader: FileUploader = new FileUploader({ disableMultipart: true });
  public uploader: FileUploader = new FileUploader({
    url: URL
  });
  public uploaderSec: FileUploader = new FileUploader({
    url: BASE_URL + "/api/containersecures/uploadFiles"
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  public pluginurl = BASE_URL + "/api/Containers/standardtracker/download/enydea-plugin.zip"

  public togglesearch = false;
  public listviewxsshow = false;
  public trackingcode: string;
  public selectedTab = 0;
  public sendfromaccount: string;
  public relationowner: Member;
  public industries = industries;
  public selectedindustries = [];
  public itemid: string;
  public emailaccounttype = 'none';

  public tmpselectedHTML: string;
  public selectedHTML: string;
  public selectedAttachments: Array<object>;

  contactperson = new UntypedFormGroup({
    contactpersonEmail: new UntypedFormControl('', [
      // Validators.required,
      // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      Validators.email
    ])
  });
  selectedcount: number;
  suggestedcontacts: any[];
  preferedtitle: '';
  searchingonline: boolean;
  searchrelationowner: string;
  mailingdefaultcontact: boolean = false;
  mailinggeneralemail: boolean = false;
  mailingallcontacts: boolean = true;
  changerelationownerto: any;
  changerelationstatus: any;
  domain: Relations;
  domains = [];
  searching: boolean;
  searchcountry: any;
  videotemplate: any[];
  newFilesArray = [];
  selectedfiletype: string;
  filetypes = ['image', 'video', 'vector'];
  public RelationReference: FireLoopRef<Relations>;
  public CompanyReference: FireLoopRef<Company>;



  get contactpersonEmail() {
    return this.contactperson.get('contactpersonEmail');
  }

  constructor(
    public appservice: AppserviceService,
    private checknameService: ChecknameService,
    private UnsortedcallsApi: UnsortedcallsApi,
    private EmailhandlerApi: EmailhandlerApi,
    private graphService: GraphService,
    private AuthService: AuthService,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    public http: HttpClient,
    public dialog: MatDialog,
    private speechRecognitionService: SpeechRecognitionService,
    public snackBar: MatSnackBar,
    public router: Router,
    public route: ActivatedRoute,

    public FilesApi: FilesApi,
    public ContainerApi: ContainerApi,
    public ContainersecureApi: ContainersecureApi,

    public googleMapService: GoogleMapService,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi,
    public LinkedinService: LinkedinService,
    public dialogsService: DialogsService,
    public RelationsApi: RelationsApi,

    public ContactpersonsApi: ContactpersonsApi,
    public CallsApi: CallsApi,
    public MailingApi: MailingApi,
    public loopBackAuth: LoopBackAuth,
    public OpportunitiesApi: OpportunitiesApi,
    public MailinglistApi: MailinglistApi,
    public LinkedinApi: LinkedinApi,
  ) {
    this.showSearchButton = true;
    this.speechData = "";

  }

  public setParameters() {
    this.itemid = this.route.snapshot.queryParamMap.get("itemid");
    let tab = this.route.snapshot.queryParamMap.get("tab");
    let relationid = this.route.snapshot.queryParamMap.get("relation");


    if (relationid) {
      this.CompanyApi.findByIdRelations(this.Account.companyId, relationid)
        .subscribe((relation: Relations) => {
          console.log(relation)
          this.onSelect(relation);
          if (tab === 'calls') {
            this.selectedTab = 1;
            this.RelationsApi.findByIdCalls(relation.id, this.itemid).subscribe((Call: Calls) => {
              this.onSelectCall(Call, null);
            })

          }
          if (tab === 'files') {
            this.selectedTab = 4;
            this.RelationsApi.findByIdFiles(relation.id, this.itemid).subscribe(res2 => {
              this.onselectfile(res2);
            })
          }
        });
    } else {
      if (tab === 'connectedaccounts') {
        this.selectedTab = 6;

      }
    }
  }


  swiperight(e?) {
    this.listviewxsshow = true;
    //console.log(e)
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
    //console.log(e)
  }

  myControlAttendeeOp: UntypedFormControl = new UntypedFormControl();
  myControlAttendee: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  myControlRelations = new UntypedFormControl();
  filteredOptionsRelations: Observable<string[]>;
  filteredRelations: Relations[];
  myControlfont = new UntypedFormControl(); 1
  filteredfonts: Observable<string[]>;


  ngOnInit() {
    if (this.AccountApi.isAuthenticated() == false) { this.router.navigate(['login']) }
    this.setFilter();
    this.getRelations();
    this.uploader.clearQueue();
    // filter contactperson
    this.setFilteredOptions();
    this.filteredfonts = this.myControlfont.valueChanges.pipe(
      startWith(''),
      map(value => this._filterfont(value))
    );


    this.uploader.onAfterAddingAll = (files) => {
      console.log(files)
      files.forEach(fileItem => {
        fileItem.file.name = fileItem.file.name.replace(/ /g, '-');     //import to clear spaces azure does not handle these well

        let imgurl = BASE_URL + '/api/Containers/' + this.selectedRelation.id + '/download/' + fileItem.file.name
        imgurl = imgurl.replace(/ /g, '-');

        let newFiles = new Files;
        newFiles.size = fileItem.file.size;
        newFiles.name = fileItem.file.name;
        newFiles.url = imgurl;
        newFiles.createdate = new Date();
        newFiles.type = 'general';

        newFiles.companyId = this.Account.companyId;
        this.newFilesArray.push(newFiles);

      });
    };


  }

  setFilteredOptions() {
    this.filteredOptions = this.myControlAttendee.valueChanges
      .pipe(
        startWith(''),
        //map(options => options && typeof options === 'object' ? options.relationname : options),
        map(lastname => lastname ? this.filter(lastname) : this.options.slice())
      );

  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: "snackbar-class"
    });
  }

  getPublisherId() {
    if (this.selectedRelation.publisherId) {
      this.AccountApi.publisher(this.Account.id, this.Account.companyId, this.selectedRelation.publisherId)
        .subscribe(res => {
          this.publisher = res;
        });
    }
  }

  setIndustries(e) {
    //console.log(e)
    this.selectedRelation.industries = e.value;//this.selectedindustries;
    this.industryselect.setValue(e.value);
    this.saveRelation();
  }

  compareFnIndustries(industry1, industry2) {
    return industry1 && industry2 ? industry1.industry === industry2.industry : industry1 === industry2;
  }

  compareFnContacts(contact1, contact2) {
    return contact1 && contact2 ? contact1.email === contact2.email : contact1 === contact2;
  }

  sendMessageToContactperson(contactperson) {
    //set index tab
    this.selectedTab = 1;
    //send message

    this.createNewMessage(contactperson);
  }

  sendMessageFromTemplateToContactperson(contactperson) {
    //set index tab
    this.selectedTab = 1;
    //send message
    this.SendMessageFromTemplate(contactperson);
  }

  setOwner(e) {
    this.selectedRelation.ownerId = e.value.accountId;
    this.saveRelation();
  }


  getOwner() {
    if (this.selectedRelation.ownerId && this.Team) {
      let member = this.Team.filter((member: Member) => {
        return member.accountId == this.selectedRelation.ownerId;
      });
      this.relationowner = member[0];
    }
  }

  getTeam() {
    this.AccountApi.getTeam(this.Account.id, this.Account.companyId).subscribe((team: Member[]) => {
      this.Team = team;
      if (this.selectedRelation) {
        this.getOwner();
      }
      //console.log(this.Team);

    })
  }

  //get filter calls selections
  filter(contactperson: Contactpersons): Contactpersons[] {
    if (!contactperson.lastname) { return null } else {
      const filterValue = contactperson.lastname.toLowerCase();

      let filtered = this.options.filter(option => {
        if (option.lastname) {
          let lowercaselastname = option.lastname.toLowerCase();
          if (lowercaselastname.indexOf(filterValue) === 0) {
            return option
          }
        } else {
          return null
        }
        //option.lastname.toLowerCase().indexOf(filterValue) === 0);
      });
      return filtered;
    }
  }

  private _filterfont(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.fontlist.filter(font => font.toLowerCase().includes(filterValue));
  }

  displayFn(options): string {
    //console.log(options)
    return options ? options.firstname + " " + options.lastname : options;
  }

  getcontactpersonsEntry(): void {
    this.options = []
    for (let contactpersons of this.Contactpersons) {
      this.options.push(contactpersons);
    }
  }

  // compare filter search Relations
  setFilter(): void {
    this.filteredOptionsRelations = this.myControlRelations.valueChanges
      .pipe(
        startWith(''),
        startWith<string | Relations>(''),
        map(value => typeof value === 'string' ? value : value.relationname),
        map(relationname => relationname ? this.filterRelations(relationname) : this.options.slice())
      );
  }

  // filter and to lower case for search
  private filterRelations(relationname: string): Relations[] {
    const filterValue = relationname.toLowerCase();
    return this.relationsOptions.filter(option => option.relationname.toLowerCase().indexOf(filterValue) === 0);
  }

  //display name in searchbox
  displayFnRelation(relation?: Relations): string | undefined {
    return relation ? relation.relationname : undefined;
  }

  searchGoQuick(value): void {
    let searchterm = value.trim();
    this.CompanyApi.getRelations(this.Account.companyId,
      {
        where: { "relationname": { "regexp": searchterm + '/i' } },
        //{
        //  or: [{ "relationname": { "regexp": searchterm + '/i' } },
        // { "address1": { "regexp": searchterm + '/i' } },
        // { "city": { "regexp": searchterm + '/i' } },
        // { "stateprovince": { "regexp": searchterm + '/i' } },
        // { "country": { "regexp": searchterm + '/i' } },
        // { "status": { "regexp": this.selectstatus + '/i' } },
        // { "type": { "regexp": searchterm + '/i' } },
        // ]
        // },
        order: 'relationname ASC'
      })
      .subscribe((Relations: Relations[]) => {
        this.filteredRelations = Relations
        //console.log(this.filteredRelations)
      });
  }


  // getRelationsEntry(): void {
  //   this.relationsOptions = []
  //   for (let relations of this.Relations) {
  //     this.relationsOptions.push(relations);
  //   }
  // }

  checkMailaccount() {
    this.AccountApi.getEmailhandler(this.Account.id).subscribe((emailhandler: Emailhandler[]) => {
      this.Emailhandler = emailhandler[0];
    })
  }


  //get currentuserinfo for api
  getCurrentUserInfo(): void {
    //  this.AccountApi.getCurrent().subscribe((Account: Account) => {
    this.Account = this.appservice.Account;
    this.checkMailaccount();

    this.setParameters();
    //   this.CompanyApi.findById(this.Account.companyId).subscribe((company: Company) => {
    //this.Company = this.appservice.company;
    this.check365Account();
    this.getTeam();
    this.getDomains();

    this.CompanyApi.countRelations(this.Account.companyId).subscribe(res => {
      this.selectedcount = res.count
      this.totalrelationcount = res.count
    });
    if (this.Account.standardrelation && !this.itemid) {
      this.RelationsApi.findById(this.Account.standardrelation)
        .subscribe((relations: Relations) => {
          this.onSelect(relations);
          //this.setIO();
        });
    }
    // });
    // });
  }


  // need extra 
  getLinkedinAccount(relationname) {
    this.RelationsApi.getLinkedin(this.Account.standardrelation)
      .subscribe((Linkedin: Linkedin[]) => {
        this.LinkedinApi.getCompanyByName(Linkedin[0].accesstoken, relationname).subscribe();
      });

  }

  async check365Account() {
    if (this.Account["365account"]) {
      this.emailaccounttype = 'office365';
      // if (this.graphService.authenticated) {
      let user = await this.graphService.getUser();
      this.sendfromaccount = user.email;
      if (this.sendfromaccount !== this.Account["365account"]) {
        this.openSnackBar('New Email address please verify you are logged into the right account')
      }
      //  } 
      // else {
      //   await this.graphService.signIn();
      //   this.sendfromaccount = this.graphService.user.email;
      //   if (this.sendfromaccount !== this.Account["365account"]) {
      //     this.openSnackBar('New Email address please verify you are logged into the right account')
      //   }
      // }
    } else if (this.Emailhandler) {
      this.emailaccounttype = 'popimap'
      this.sendfromaccount = this.Emailhandler.username
    } else if (this.Company.companywebsite) {
      this.emailaccounttype = 'mailing'
      const matches = this.Company.companywebsite.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
      // extract hostname (will be null if no match is found
      if (matches) {
        this.sendfromaccount = this.Account.email.split('@')[0] + '@' + matches[1];
      }
    } else (
      this.sendfromaccount = "Undefined"
    )


  }

  onSelect(Relation: Relations): void {
    this.Contactpersons = [];
    this.suggestedcontacts = [];
    this.selectedContactperson = undefined;
    this.selectedCall = undefined;
    this.attendee = [];
    if (this.selectedRelation) { this.saveRelation(); }
    this.selectedRelation = undefined;
    this.notes = [];
    this.note = "";
    this.task = "";
    this.tasklist = [];
    this.option = undefined;
    this.address = undefined;
    this.selectedRelation = Relation;
    this.industryselect.setValue(Relation.industries);
    // if (Relation.companysecondarycolor) { this.compannyseccolor = Relation.companysecondarycolor }
    // if (Relation.companyprimairycolor) { this.companypricolor = Relation.companyprimairycolor }
    this.relationowner = undefined;
    this.selectedindustries = this.selectedRelation.industries;
    //this.relationindex = i;

    this.getCalls();
    this.getContactpersons();

    this.getFiles();
    this.setFileParameter();
    this.setFileParameterSec();

    this.getPublisherId();
    this.getOpportunities();
    this.getOwner();
    //create search string google maps
    this.showAddress();




    this.myControlRelations.reset();
    this.RelationsApi.countCalls(this.selectedRelation.id).subscribe(res => {
      this.totalCallcount = res.count
    });
  }


  setFileParameter(): void {
    this.ContainerApi.findById(this.selectedRelation.id)
      .subscribe(res => { },
        error =>
          this.ContainerApi.createContainer({ name: this.selectedRelation.id })
            .subscribe(res2 => { }));
    this.newURL = BASE_URL + "/api/Containers/" + this.selectedRelation.id + "/upload";
    this.uploader.setOptions({
      url: this.newURL,
      headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
    });
    this.uploader.clearQueue();
  }

  setFileParameterSec(): void {
    let secUrl = BASE_URL + "/api/containersecures/uploadfiles/" + this.selectedRelation.id + "?filename=";
    this.uploaderSec.setOptions({
      url: secUrl,
      headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
    });
    this.uploaderSec.clearQueue();

  }

  //check url and open in new window/tab
  openUrl(): void {
    var res = this.selectedRelation.website.substring(0, 3);
    res = res.toLowerCase();
    if (res === "www") { window.open("http://" + this.selectedRelation.website, "_blank"); }
    else if (res === "htt") { window.open(this.selectedRelation.website, "_blank"); }
    else (this.selectedRelation.website = 'https://' + this.selectedRelation.website)
    //else { this.dialogsService.confirm('Not a valid URL', 'Please edit'); };
  }

  openUrlPhone(phone) {
    window.open('tel:' + phone);
  }

  getRelationsnextpage(): void {
    if (this.limit < this.totalrelationcount) {
      //this.limit = this.limit += 20;
      this.skip = this.skip += 20;
      this.searchGo();
    }
  }

  getRelationsbackpage(): void {
    if (this.skip > 0) {
      this.skip = this.skip -= 20;
      //this.limit = this.limit -= 20,
      this.searchGo();
    }
  }

  getCallsnextpage(): void {
    if (this.limit < this.totalCallcount) {
      //this.limitCalls = this.limitCalls += 20;
      this.skipCalls = this.skipCalls += 20;
      this.getCalls();
    }
  }

  getCallsbackpage(): void {
    if (this.skip > 0) {
      this.skipCalls = this.skipCalls -= 20;
      //this.limitCalls = this.limitCalls -= 20,
      this.getCalls();
    }
  }

  resetFilters() {
    this.searchterm = '';
    this.selectstatus = '';
    this.selecttype = '';
    this.selectconst1 = '';
    this.selectconst2 = '';
    this.selectconst3 = '';
    this.selectconst4 = '';
    this.selectmarketingrelation = false;
    this.searchcountry = '';
  }

  getRelations(): void {

    this.resetFilters();
    this.selectedcount = this.totalrelationcount;
    this.skip = 0;
    this.Relations = [];

    this.appservice.isCompanyAvailable.subscribe((ready) => {
      //console.log(ready);
      this.Company = this.appservice.company;
      if (ready) {
        this.CompanyReference = this.appservice.realTime.FireLoop.ref<Company>(Company);
        this.RelationReference = this.CompanyReference.make(this.Company).child<Relations>('relations');
        this.RelationReference.on('value', {
          order: this.orderbyselect,
          limit: this.limit,
          skip: this.skip,
        }).subscribe((Relations: Relations[]) => {
          this.Relations = Relations;
          this.filteredRelations = Relations;
          this.relationsOptions = Relations;// this.getRelationsEntry();
          this.getCurrentUserInfo();
        });
      }
    });


    // this.CompanyApi.getRelations(this.Account.companyId,
    //   {
    //     order: this.orderbyselect,
    //     limit: this.limit,
    //     skip: this.skip,
    //   }
    // ).subscribe((Relations: Relations[]) => {
    //   this.Relations = Relations,
    //     this.filteredRelations = Relations,
    //     this.getRelationsEntry();
    // });
  }

  getContactpersons(): void {
    this.RelationsApi.getContactpersons(this.selectedRelation.id)
      .subscribe((Contactpersons: Contactpersons[]) => {
        this.Contactpersons = Contactpersons,
          this.getcontactpersonsEntry();
        this.setFilter();
        this.setFilteredOptions();
        this.saveRelation();
      });
  }

  getAllContactpersons(): void {
    this.RelationsApi.getContactpersons(this.selectedRelation.id)
      .subscribe((Contactpersons: Contactpersons[]) => this.Contactpersons = Contactpersons);
  }

  getCalls(): void {
    this.RelationsApi.getCalls(this.selectedRelation.id,
      {
        order: 'date DESC',
        limit: this.limitCalls,
        skip: this.skipCalls,
      })
      .subscribe((Calls: Calls[]) => this.Calls = Calls);
  }

  setMarketingRelation() {
    if (!this.Account.companyadmin) { return }
    if (this.selectedRelation.marketingrelation === false) {
      this.AccountApi.getsub(this.Account.id)
        .subscribe((subscr) => {
          //console.log(subscr)
          if (subscr.length > 0) {
            let maxnumber = subscr[0].marketingrelationaccounts;
            this.CompanyApi.countRelations(this.Company.id, { marketingrelation: true }).subscribe(res => {
              //console.log(res.count, maxnumber)
              if (res.count < maxnumber) {
                this.selectedRelation.marketingrelation = true;
                this.CompanyApi.updateByIdRelations(this.selectedRelation.companyId,
                  this.selectedRelation.id, this.selectedRelation)
                  .subscribe(Relations => {
                    this.selectedRelation = Relations;
                    this.Account.standardrelation = this.selectedRelation.id;
                  });
              } else {
                this.selectedRelation.marketingrelation = false;
                this.openSnackBar('To many Marketing Relations');
              }
            })
          }
        });
    } else {
      this.selectedRelation.marketingrelation = false
    }
  }

  openSocialMedia(url) {
    window.open(url, '_blank');
  }

  saveSocialMedia(suggestion) {
    switch (suggestion.type) {
      case 'linkedin': {
        this.selectedContactperson.linkedin = suggestion.url;
        break;
      }
      case 'twitter': {
        this.selectedContactperson.twitter = suggestion.url;
        break;
      }
      case 'facebook': {
        this.selectedContactperson.facebook = suggestion.url;
        break;
      }
      case 'instagram': {
        this.selectedContactperson.instagram = suggestion.url;
        break;
      }
      case 'pinterest': {
        this.selectedContactperson.pinterest = suggestion.url;
        break;
      }
    }
  }

  getSuggestedContacts() {
    if (this.selectedRelation.city || this.selectedRelation.country || this.selectedRelation.relationname) {
      this.searchingonline = true;
      this.suggestedcontacts = [];
      this.ContactpersonsApi.searchnewcontacts(
        this.selectedRelation.relationname,
        this.selectedRelation.city,
        this.selectedRelation.country,
        this.preferedtitle).subscribe(contacts => {
          this.suggestedcontacts = contacts;
          this.searchingonline = false;
        })

    } else {
      this.openSnackBar('Cannot search missing relation name, city or country')
    }
  }

  createNewFromSuggested(suggestion: any) {
    console.log(suggestion);
    let contact = new Contactpersons();
    contact.linkedin = suggestion.url;
    contact.companyId = this.Account.companyId;
    contact.relationsId = this.selectedRelation.id;
    contact.firstname = suggestion.title;
    this.RelationsApi.createContactpersons(this.selectedRelation.id, contact).subscribe(contact => {
      this.selectedContactperson = contact;
    })
  }


  getSuggestedSocialMedia() {
    this.suggestedSocialMedia = [];
    this.ContactpersonsApi.socialmediasuggestions(this.selectedContactperson.firstname,
      this.selectedContactperson.lastname, this.selectedRelation.relationname)
      .subscribe(suggestedSocialMedia => {
        this.suggestedSocialMedia = suggestedSocialMedia;
      })
  }

  deleteAllRelations() {
    this.CompanyApi.deleteRelations(this.Company.id).subscribe(res => { })

  }

  searchGoBox() {
    this.skip = 0;
    this.searchGo();
  }

  getRelationsEmptyCalls() {
    this.searching = true;
    this.RelationsApi.findRelationsEmptyCalls(this.Account.companyId).subscribe((relations: Relations[]) => {
      this.Relations = relations;
      this.searching = false;
    })
  }


  // this.RelationReference.on('value', {
  //   order: this.orderbyselect,
  //   limit: this.limit,
  //   skip: this.skip,
  // }).subscribe((Relations: Relations[]) => {
  //   this.Relations = Relations,
  //     this.filteredRelations = Relations,
  //     this.getRelationsEntry();
  // });

  searchGo(): void {
    // check if searchterm add selectin criteria
    this.searchterm = this.searchterm.trim();
    let selectionArray = [];
    //this.skip = 0;
    //console.log(this.searchrelationowner)
    if (this.searchrelationowner) { selectionArray.push({ 'ownerId': this.searchrelationowner }) }
    if (this.selectstatus) { selectionArray.push({ "status": this.selectstatus }) }
    if (this.selecttype) { selectionArray.push({ "type": this.selecttype }) }
    if (this.selectconst1) { selectionArray.push({ "const1": this.selectconst1 }) }
    if (this.selectconst2) { selectionArray.push({ "const2": this.selectconst2 }) }
    if (this.selectconst3) { selectionArray.push({ "const3": this.selectconst3 }) }
    if (this.selectconst4) { selectionArray.push({ "const4": this.selectconst4 }) }
    if (this.selectmarketingrelation) { selectionArray.push({ "marketingrelation": true }) }
    if (this.searchcountry) { selectionArray.push({ "country": this.searchcountry }) }

    if (selectionArray.length < 2 && this.searchterm === '') {
      this.RelationReference.on('value',
        {
          where: selectionArray[0],
          order: this.orderbyselect,
          limit: this.limit,
          skip: this.skip,
        })
        .subscribe((Relations: Relations[]) => { this.Relations = Relations, this.countSelectedRelations(selectionArray) },
          error => this.errorMessage = <any>error);
    } else if (this.searchterm === '') {
      this.RelationReference.on('value',
        {
          where:
          {
            and: selectionArray
          },
          order: this.orderbyselect,
          limit: this.limit,
          skip: this.skip,
        })
        .subscribe((Relations: Relations[]) => {
          this.Relations = Relations,
            this.countSelectedRelations(selectionArray)
        },
          error => this.errorMessage = <any>error);
    } else {
      selectionArray.push({ "relationname": { "regexp": this.searchterm + '/i' } });
      selectionArray.push({ "address1": { "regexp": this.searchterm + '/i' } });
      selectionArray.push({ "city": { "regexp": this.searchterm + '/i' } });
      selectionArray.push({ "stateprovince": { "regexp": this.searchterm + '/i' } });
      selectionArray.push({ "country": { "regexp": this.searchterm + '/i' } });
      selectionArray.push({ "type": { "regexp": this.searchterm + '/i' } });
      this.RelationReference.on('value',
        {
          where:
          {
            or: selectionArray
          },
          order: this.orderbyselect,
          limit: this.limit,
          skip: this.skip,
        })
        .subscribe((Relations: Relations[]) => {
          this.Relations = Relations,
            this.countSelectedRelations(selectionArray)
        },
          error => this.errorMessage = <any>error);
    }
  }

  // get counter check if free search, selection array or none
  countSelectedRelations(selectionArray: Relations[]) {
    if (this.searchterm === '' && selectionArray.length === 0) {
      this.CompanyApi.countRelations(this.Account.companyId).subscribe(res => {
        this.selectedcount = res.count
      });
    }
    else if (this.searchterm === '') {
      this.CompanyApi.countRelations(this.Account.companyId,
        { and: selectionArray }
      ).subscribe(res => {
        this.selectedcount = res.count
      });
    } else {
      this.CompanyApi.countRelations(this.Account.companyId,
        { or: selectionArray }
      ).subscribe(res => {
        this.selectedcount = res.count
      });
    }
  }

  saveRelation(): void {
    if (this.selectedRelation.relationname !== undefined) {
      //   this.dialogsService.confirm('missing info', 'please enter Relation name');
      this.selectedRelation.relationname = this.selectedRelation.relationname.trim();
    }
    //else {

    this.CompanyApi.updateByIdRelations(this.selectedRelation.companyId,
      this.selectedRelation.id, this.selectedRelation)
      .subscribe(res => {
        // console.log(res)
      }, error => {
        console.error(error)
      });
    // }
  }

  addTask(): void {
    this.tasklist.push({ task: this.task })
  }

  deleteTask(i): void {
    this.tasklist.splice(i);
  }

  addNote(): void {
    this.selectedCall.notes.push({ note: this.note })
  }

  deleteNote(i): void {
    this.selectedCall.notes.splice(i, 1);
  }


  addTaskOp(): void {
    this.selectedOpportunity.tasks.push({ task: this.task })
  }

  deleteTaskOp(i): void {
    this.selectedOpportunity.tasks.splice(i, 1);
  }

  addNoteOp(): void {
    this.selectedOpportunity.notes.push({ note: this.note })
  }

  deleteNoteOp(i): void {
    this.selectedOpportunity.notes.splice(i, 1);
  }


  addItemOp(): void {
    let item = new Item();
    item.VAT = 0;
    item.price = '';
    item.quantity = 1;
    item.itemtotal = 0;
    item.totalVAT = 0
    this.selectedOpportunity.items.push(item);
  }

  async onChangeItemOp(item) {
    item.itemtotal = item.quantity * item.price;
    item.totalVAT = item.itemtotal / 100 * item.VAT;
    this.recalculateValue();
  }

  recalculateValue() {
    let total = 0;
    for (let i = 0; i < this.selectedOpportunity.items.length; i++) {
      let testitem = this.selectedOpportunity.items[i];
      if (testitem.itemtotal) {
        total = total + testitem.itemtotal;
      }
    }
    this.selectedOpportunity.value = total;
  }

  deleteItemOp(i): void {
    this.selectedOpportunity.items.splice(i, 1);
    this.recalculateValue();
  }

  includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  addAttendent(attendeeInput): void {
    //console.log(attendeeInput)
    let attendent;
    if (!attendeeInput) { return }
    if (!attendeeInput.lastname && !attendeeInput.firstname) { attendent = attendeeInput }
    else if (!attendeeInput.lastname) { attendent = attendeeInput.firstname }
    else if (!attendeeInput.firstname) { attendent = attendeeInput.lastname }
    else { attendent = attendeeInput.firstname + ' ' + attendeeInput.lastname };

    let exist = this.includes(this.attendeelist, attendent)
    if (exist === false) {
      this.attendeelist.push({ attendent: attendent })
    }

  }

  addAttendentOp(attendeeInput): void {
    let attendent;
    if (!attendeeInput) { return }
    if (!attendeeInput.lastname && !attendeeInput.firstname) { attendent = attendeeInput }
    else if (!attendeeInput.lastname) { attendent = attendeeInput.firstname }
    else if (!attendeeInput.firstname) { attendent = attendeeInput.lastname }
    else { attendent = attendeeInput.firstname + ' ' + attendeeInput.lastname };
    this.selectedOpportunity.attendee.push({ attendent: attendent })
  }

  deleteAttendentOp(i): void {
    this.selectedOpportunity.attendee.splice(i, 1);
  }

  deleteAttendent(i): void {
    this.attendeelist.splice(i, 1);
  }

  saveCall(): void {
    if (this.sendfromaccount && this.selectedCall.calltype === 'E-mail') {
      this.selectedCall.mailfrom = { email: this.sendfromaccount, name: this.Account.username }
    }
    this.selectedCall.tasks = this.tasklist,
      this.selectedCall.notes = this.notes,
      this.selectedCall.attendee = this.attendeelist,
      this.RelationsApi.updateByIdCalls(this.selectedRelation.id, this.selectedCall.id, this.selectedCall)
        .subscribe(Calls => this.Calls[this.callindex] = this.selectedCall);
  }

  saveContactperson(): void {
    this.selectedContactperson.companyId = this.Account.companyId,
      this.RelationsApi.updateByIdContactpersons(this.selectedRelation.id, this.selectedContactperson.id, this.selectedContactperson)
        .subscribe(() => {
          this.getContactpersons();

        });
    //Contactpersons => this.Contactpersons.push(this.selectedContactperson));
  }

  deleteAllCalls(selectedOption) {
    if (selectedOption) {
      this.RelationsApi.deleteCalls(this.selectedRelation.id).subscribe(res => {
        this.openSnackBar('Calls deleted')
      })
    }
  }

  public openDialogDeleteAllCalls() {
    this.dialogsService
      .confirm('Delete all Calls', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteAllCalls(this.selectedOption)
        // this.Relations.splice(this.relationindex);
      });
  }

  public openDialogDelete() {
    this.dialogsService
      .confirm('Delete Relation', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteRelation(this.selectedOption)
        // this.Relations.splice(this.relationindex);
      });
  }

  public openDialogDeleteCall() {
    this.dialogsService
      .confirm('Delete Call', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteCall(this.selectedOption);
      });
  }

  public openDialogDeleteContactperson() {
    this.dialogsService
      .confirm('Delete Call', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteContactperson(this.selectedOption);
      });
  }

  //Create New Relation add to array, get index nr for selection and send to api
  public openDialogNewRelation() {
    this.dialogsService
      .confirm('Create new relation', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {
          this.newRelation();
        }
      });

  }

  public newRelation() {
    let relation = new Relations({ companyId: this.Account.companyId, ownerId: this.Account.id })
    this.RelationReference.upsert(relation).subscribe((relation: Relations) => {
      // console.log(res);
      this.onSelect(relation);
      document.getElementById("relationname").focus();
    })

    // this.CompanyApi.createRelations(this.Account.companyId,
    //   { companyId: this.Account.companyId, ownerId: this.Account.id }).subscribe(res => {
    //     this.data = res;
    //     //this.relationindex = this.Relations.push(this.data) - 1,
    //     this.onSelect(this.data);
    //     //this.relationnameElement.nativeElement.focus();
    //     document.getElementById("relationname").focus();
    //     // this.ContainerApi.createContainer({ name: this.data.id }).subscribe(res => {
    //     // }, error => {
    //     //   console.log(error)
    //     // });
    //   });
  }

  //Create new Call
  public openDialogNewCall() {
    this.newCall();
  }

  public newCall() {
    this.RelationsApi.createCalls(this.selectedRelation.id, {
      date: new Date(),
      // title: "New",
      accountId: this.Account.id,
      companyId: this.Account.companyId
    }) //add companyId for statistics
      .subscribe(res => {
        this.data = res,
          this.callindex = this.Calls.push(this.data) - 1,
          this.onSelectCall(this.data, this.callindex)
      });
  }

  //create new Contactperson
  public openDialogNewContactperson() {
    this.newContactperson();
  }

  public newContactperson() {
    this.RelationsApi.createContactpersons(this.selectedRelation.id, { "companyId": this.Account.companyId })
      .subscribe(res => {
        this.data = res;
        this.contactpersonindex = this.Contactpersons.push(this.data) - 1;
        this.onSelectContactperson(this.data, this.contactpersonindex);
        setTimeout(() => {
          this.contactpersonfirstnameElement.nativeElement.focus();
        }, 10);
      });
  }

  deleteRelation(selectedOption): void {
    if (selectedOption == true) {
      if (this.selectedRelation.status === 'Owner') { this.openSnackBar('You can not delete owner relation'); return; }
      this.RelationsApi.deleteById(this.selectedRelation.id).subscribe(res => {
        this.error = res,
          this.selectedRelation = null
        this.getRelations();
      })
    }
  }

  deleteCall(selectedOption): void {
    if (selectedOption == true) {
      if (this.selectedCall.attachments) {
        this.ContainersecureApi.deleteContainer(this.selectedCall.id, this.Company.id).subscribe();
      }
      this.RelationsApi.destroyByIdCalls(this.selectedRelation.id, this.selectedCall.id)
        .subscribe(res => {
          this.error = res,
            this.selectedCall = null,
            this.getCalls();
          this.Calls.splice(this.callindex, 1)
        })
    }
  }

  deleteContactperson(selectedOption): void {
    if (selectedOption == true) {
      this.RelationsApi.destroyByIdContactpersons(this.selectedRelation.id, this.selectedContactperson.id).subscribe(res => {
        this.error = res,
          this.selectedContactperson = null
        this.getContactpersons();
      })
    }
  }

  remove_tags(html) {
    html = html.replace(/<br>/g, "$br$");
    html = html.replace(/(?:\r\n|\r|\n)/g, '$br$');
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    html = tmp.textContent || tmp.innerText;
    html = html.replace(/\$br\$/g, "<br>");
    return html;
  }

  onSelectCall(Calls: Calls, i): void {
    this.tmpselectedHTML = undefined;
    if (Calls.emailid) {
      this.selectedHTML = this.remove_tags(Calls.html); // show text onluy
    }
    //this.readytosend = false;
    this.myControlAttendee.reset('', { emitEvent: false });
    this.attendeelist = [];
    this.option = undefined;
    this.tasklist = [];
    this.notes = [];
    this.task = "";
    this.note = "";
    this.selectedCall = Calls;
    this.callindex = i;
    this.emailtosendto = Calls.mailto;
    this.emailtosendcc = Calls.mailcc;
    this.emailtosendbcc = Calls.mailbcc;
    this.attachmentUploader.clearQueue();
    this.attachmentFiles = undefined;
    this.selectedAttachments = [];
    if (this.selectedCall.attendee !== undefined) {
      this.attendeelist = this.selectedCall.attendee
    }
    if (this.selectedCall.tasks !== undefined) { this.tasklist = this.selectedCall.tasks };
    if (this.selectedCall.notes !== undefined) { this.notes = this.selectedCall.notes };
    if (this.selectedCall.attachments) {
      this.replaceCidTags();
    } else {
      this.selectedHTML = this.selectedCall.html;
    }
    this.getLinkInfo();
  }

  onSelectContactperson(Contactpersons: Contactpersons, i): void {
    this.selectedContactperson = Contactpersons;
    this.contactpersonindex = i;
    this.suggestedSocialMedia = undefined;
  }

  searchCallGo(name: string): void {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    name = name.trim();
    this.RelationsApi.getCalls(this.selectedRelation.id,
      {
        where:
        {
          or:
            [
              { "title": { "regexp": name + '/i' } },
              { "html": { "regexp": name + '/i' } }
            ]
        },
      })
      .subscribe((Calls: Calls[]) => this.Calls = Calls,
        error => this.errorMessage = <any>error);
  }

  //check related linkedin accounts
  getRelatLinkedin(): void {
    this.LinkedinService.restoreCredentials
    if (this.AccessToken !== undefined) {
      //show message and redirect to settings
    }
  }

  getAddress() {
    //console.log(this.address)
    this.googleMapService.getLatLan(
      this.address
    )
      .subscribe(
        result => {
          this.lat = result.lat();
          this.lng = result.lng();
        },
        error => {
          console.log(error),
            this.lat = 0,
            this.lng = 0;
        },
      );
  }

  //file upload 1
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  //file upload 2
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  getFiles(): void {
    let searchpar = {}
    if (this.searchboxFiles) {
      searchpar = {
        where:
          { or: [{ "name": { "regexp": this.searchboxFiles + '/i' } }, { "url": { "regexp": this.searchboxFiles + '/i' } }] },
        fields: { id: true, name: true, createdate: true, type: true, url: true, companyId: true, relationsId: true },
        order: 'name ASC',
      }
    } else {
      searchpar = {
        fields: { id: true, name: true, createdate: true, type: true, url: true, companyId: true, relationsId: true, size: true }
        // order: 'name ASC',
      }
    }

    this.RelationsApi.getFiles(this.selectedRelation.id, searchpar)
      .subscribe((Files: Files[]) => {
        // console.log(Files)
        this.Files = Files,
          this.imagelist = [];
        this.videolist = [];
        this.misclist = [];
        this.seclist = [];
        this.videotemplate = [];
        this.Files.forEach((file, index) => {
          // console.log(file, index);
          let ext = file.name.split('.').pop();
          // console.log(ext, file);
          file['selected'] = false;
          if (file.type === 'video-scene' || file.type === 'video-edit') {
            this.videotemplate.push(file);
          }
          else if (file.type === "secure") {
            this.seclist.push(file)
          }
          else if (ext === "gif" || ext === "jpeg" || ext === "jpg" || ext === "bmp" || ext === "png" || ext === "svg" || ext === "ai" || ext === "eps") {
            this.imagelist.push(file);
          }
          else if (ext === "mp4" || ext === "mpeg4" || ext === "mov" || ext === "avi" || ext === "wmv") {
            this.videolist.push(file);
          }
          else {
            this.misclist.push(file)
          }
        });
      });
  }

  onselectfile(file): void {
    this.selectedfile = undefined;
    setTimeout(() => {
      this.selectedfile = file;
    }, 100)
  }

  deleteSelectedImageList() {
    this.dialogsService
      .confirm('Delete Files' + Files.name, 'Are you sure you want to do this?').subscribe(res => {
        if (res) {
          this.imagelist.forEach(image => {
            if (image.selected) {
              this.deleteFiles(true, image)
            }
          })
          // this.getFiles();
        }
      })

  }

  dialogDeleteAllFile(list) {
    this.dialogsService
      .confirm('Delete ALL misc Files ' + list.length, 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res === true) {
          this.dialogsService
            .confirm('Delete misc ALL Files ' + list.length, 'Are you really sure?')
            .subscribe(res => {
              for (let i = 0; i < list.length; i++) {
                this.RelationsApi.destroyByIdFiles(list[i].relationsId, list[i].id).subscribe(
                  res => this.getFiles());
              }
            });
        }
      });
  }

  dialogDeleteFile(Files: Files): void {
    //console.log(Files);
    this.dialogsService
      .confirm('Delete Files' + Files.name, 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteFiles(this.selectedOption, Files)
      });
  }

  deleteFiles(selectedOption, Files): void {
    if (selectedOption == true) {
      this.ContainerApi.removeFile(this.selectedRelation.id, Files.name).subscribe(res => this.getFiles());

      // delete thumb
      const exten = Files.name.split('.').pop();
      let thumbname = Files.name;
      let tmpname = thumbname.replace('.' + exten, '') // .webm
      //let thumburl =  BASE_URL + '/api/Containers/' + this.option.id + '/download/' + tmpname + '-thumb.jpg';

      this.ContainerApi.removeFile(this.selectedRelation.id, tmpname + '-thumb.jpg').subscribe(res => this.getFiles());
      this.RelationsApi.destroyByIdFiles(Files.relationsId, Files.id).subscribe(
        () => this.getFiles());

    }
  }

  dialogDeleteFileSec(Files: Files): void {
    //console.log(Files);
    this.dialogsService
      .confirm('Delete Files' + Files.name, 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteFilesSec(this.selectedOption, Files)
      });
  }

  deleteFilesSec(selectedOption, Files): void {
    if (selectedOption == true) {
      this.ContainersecureApi.deleteFile(this.selectedRelation.id, Files.name, this.Company.id).subscribe(
        () => {
          this.RelationsApi.destroyByIdFiles(Files.relationsId, Files.id).subscribe(
            () => { this.getFiles(), this.selectedfile = undefined });
        });

    }
  }

  onDownload(Files): void {
    this.urldownload = BASE_URL + "/api/Containers/" + this.selectedRelation.id + "/download/" + Files.name
    this.urldownload.replace(/ /g, "%20")
  }

  async replaceCidTags() {
    this.tmpselectedHTML = this.selectedCall.html;
    this.selectedAttachments = [];
    for (let i = 0; i < this.selectedCall.attachments.length; i++) {
      let file = this.selectedCall.attachments[i];
      this.setFile(file, i, this.selectedCall.attachments.length);
    };

  }

  async setFile(file, k, l): Promise<any> {
    this.ContainersecureApi.getFilesByFilename(this.Company.id, this.selectedCall.id, file.filename)
      .subscribe(res => {
        //console.log(res)
        const byteCharacters = atob(res.$data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        let urlCreator = window.URL;
        let url = urlCreator.createObjectURL(blob);

        // if cid from office365 attachment
        let cid = 'cid:' + file.contentId;
        if (this.tmpselectedHTML) {
          this.tmpselectedHTML = this.tmpselectedHTML.replace(cid, url);
        }

        this.selectedAttachments.push(file);
        // if (this.selectedCall.html.indexOf(cid) == -1) {
        //   //console.log('add file to attachments')
        //   this.selectedAttachments.push(file);
        // }
        if (k === l - 1) {
          this.selectedHTML = this.tmpselectedHTML;
        }
        return
      }, error => {
        console.log(error)
      })
  }

  deleteAttachment(file, i) {
    this.ContainersecureApi.deleteFile(this.selectedRelation.id, file.name, this.Company.id)
      .subscribe(res => {
        this.openSnackBar('Delete File');
        this.selectedAttachments.splice(i, 1);
        this.selectedCall.attachments.splice(i, 1);
        this.saveCall();
      });
  }

  downloadAttachment(file) {
    // get stream to download
    this.ContainersecureApi.getFilesByFilename(this.Company.id, this.selectedCall.id, file.filename)
      .subscribe(res => {
        //console.log(res);
        const byteCharacters = atob(res.$data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]); //, { type: 'application/pdf' }
        var urlCreator = window.URL;
        let seturl = urlCreator.createObjectURL(blob);
        var element = document.createElement('a');
        element.setAttribute('href', seturl);
        element.setAttribute('download', file.filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      );
  }

  download(selectedfile) {
    this.http.get(selectedfile.url, { responseType: 'blob' }).subscribe(blob => {
      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      element.setAttribute('href', seturl);
      element.setAttribute('download', selectedfile.name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }

  downloadPlugin() {
    this.http.get(this.pluginurl, { responseType: 'blob' }).subscribe(blob => {
      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      //console.log(blob.type)
      element.setAttribute('href', seturl);
      element.setAttribute('download', 'enydea-plugin');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

    })
  }

  //set variable and upload + save reference in Publications
  async setuploadSec(name, id?) {
    name = await this.checknameService.checkName(name, this.selectedRelation.id);
    //define the file settings

    this.newFiles.name = name,
      //this.newFiles.url = `${BASE_URL} /api/containersecures/getfilesbyfilename/${this.Company.id}?itemName${name}&containerid=${containerId}`;
      //this.newFiles.url = BASE_URL + "/api/containersecures/getfilesbyfilename/:companyId?companyId=" + this.selectedRelation.id + "&itemName=" + name,
      this.newFiles.createdate = new Date(),
      this.newFiles.type = "secure",
      this.newFiles.companyId = this.Account.companyId,
      this.newFiles.size = this.uploaderSec.queue[0].file.size / 1024 / 1024;
    this.uploadFileSec(id);
  }

  uploadFileSec(id): void {
    let containerId = this.selectedRelation.id;
    if (id) {
      containerId = id
    }

    let filename = this.uploaderSec.queue[0].file.name;
    filename = filename.replace(/ /g, '-');
    let secUrl = BASE_URL + "/api/containersecures/uploadfiles/" +
      containerId + "?filename=" + filename + "&companyId=" + this.Company.id;

    this.uploaderSec.setOptions({
      url: secUrl,
      headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
    });
    this.uploaderSec.uploadAll();

    this.newFiles.url =  BASE_URL + "/api/containersecures/getFilesByFilename/" +
    containerId + "?itemName=" + filename + "&companyId=" + this.Company.id;
    // if id is set file is attachment create file reference as call relation. 
    if (!id) {
      this.RelationsApi.createFiles(containerId, this.newFiles)
        .subscribe(res => {
          this.getFiles();
          this.openSnackBar('File Uploaded')
          this.uploaderSec.clearQueue()
        }
        );
    } else {
      //   this.CallsApi.createFiles(id, this.newFiles).subscribe(res => {
      this.openSnackBar('File Uploaded');

      this.uploaderSec.clearQueue();
      if (!this.selectedCall.attachments) {
        this.selectedCall.attachments = [];
      }
      this.selectedCall.attachments.push({ filename: filename });
      this.saveCall();
      //  })
    }

  }

  downloadSec(filename): void {

    this.openSnackBar('Download started')
    // get stream and encode base64 to pdf
    console.log(this.selectedRelation.companyId, filename, this.selectedRelation.id)
    this.ContainersecureApi.getFilesByFilename(this.selectedRelation.companyId, this.selectedRelation.id, filename)
      .subscribe(res => {
        const byteCharacters = atob(res.$data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        var urlCreator = window.URL;
        let seturl = urlCreator.createObjectURL(blob);
        var element = document.createElement('a');
        //console.log(blob.type)
        element.setAttribute('href', seturl);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      );
  }

  //set variable and upload + save reference in Publications
  setupload(name): void {
    //define the file settings
    this.newFiles.name = name,
      this.newFiles.url = BASE_URL + "/api/Containers/" + this.selectedRelation.id + "/download/" + name,
      this.newFiles.createdate = new Date(),
      this.newFiles.type = "general",
      this.newFiles.companyId = this.Account.companyId,
      this.newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
    this.uploadFile();
  }

  uploadFile(): void {
    this.uploader.uploadAll();
    console.log(this.selectedfiletype, this.newFilesArray)
    this.newFilesArray.forEach(async file => {

      file.name = await this.checknameService.checkName(file.name, this.selectedRelation.id);
      if (this.selectedfiletype) {
        file.type = this.selectedfiletype;

        if (this.selectedfiletype === 'image') {
          const exten = file.name.split('.').pop();
          if (exten.toLowerCase() !== 'jpg' || 'jpeg' || 'png' || 'tif') {
            return
          }
          let thumbname = file.name;
          let tmpname = thumbname.replace('.' + exten, ''); // .webm
          let thumburl = BASE_URL + '/api/Containers/' + this.selectedRelation.id + '/download/' + tmpname + '-thumb.jpg';
          file.thumb = thumburl;
        }
      }
      this.RelationsApi.createFiles(this.selectedRelation.id, file)
        .subscribe(res => {
          this.FilesApi.createThumb(this.selectedRelation.id, this.Account.companyId, this.newFiles.url, this.newFiles.name).subscribe();
        });
    });
    this.getFiles();
  }

  // link to local  installed mail client
  sendMailTo(): void {
    if (this.selectedCall.html == undefined) {
      this.mailtolink = "mailto:" + this.selectedCall.email + "?subject=" +
        this.selectedCall.title + "&body=" + this.selectedCall.content
    } else {
      this.mailtolink = "mailto:" + this.selectedCall.email + "?subject=" +
        this.selectedCall.title + "&body=" + this.selectedCall.html
    }
    window.open(this.mailtolink, "_self");
  }

  // link to local  installed mail client
  sendMailToContact(): void {
    this.mailtolink = "mailto:" + this.selectedContactperson.email
    window.open(this.mailtolink, "_self");
  }

  setSearchCountry(addrObj) {
    this.searchcountry = addrObj.country;
  }

  clearSearch() {
    this.resetFilters();
    this.getRelations();
  }

  //Method to be invoked everytime we receive a new instance
  //of the address object from the onSelect event emitter.
  setAddress(addrObj) {
    //console.log(addrObj);
    if (addrObj.name !== undefined) {
      this.selectedRelation.relationname = addrObj.name;
    }
    if (addrObj.route !== undefined) {

      if (addrObj.street_number) {
        this.selectedRelation.address1 = addrObj.route + " " + addrObj.street_number;
      } else {
        this.selectedRelation.address1 = addrObj.route;
      }
      //this.selectedRelation.address1 = addrObj.route + " " + addrObj.street_number;
    } else {
      this.selectedRelation.address1 = '';
    }
    if (addrObj.phone !== undefined && !this.selectedRelation.generalphone) {
      this.selectedRelation.generalphone = addrObj.phone;
    } else {
      this.selectedRelation.generalphone = '';
    }
    if (addrObj.country !== undefined) {
      this.selectedRelation.country = addrObj.country;
    } else {
      this.selectedRelation.country = '';
    }
    if (addrObj.locality !== undefined) {
      this.selectedRelation.city = addrObj.locality;
    } else {
      this.selectedRelation.city = '';
    }
    if (addrObj.admin_area_l1 !== undefined) {
      this.selectedRelation.stateprovince = addrObj.admin_area_l1;
    } else {
      this.selectedRelation.stateprovince = '';
    }
    if (addrObj.postal_code !== undefined) {
      this.selectedRelation.zipcode = addrObj.postal_code;
    } else {
      this.selectedRelation.zipcode = '';
    }
    if (addrObj.website !== undefined && !this.selectedRelation.website) {
      this.selectedRelation.website = addrObj.website;
    } else {
      this.selectedRelation.website = '';
    }
    this.showAddress();
  }

  setCity(addrObj) {
    if (addrObj.locality !== undefined) {
      this.selectedRelation.city = addrObj.locality;
      this.saveRelation();
    }
  }

  setCountry(addrObj) {
    console.log(addrObj)
    if (addrObj.country !== undefined) {
      this.selectedRelation.country = addrObj.country;
      this.saveRelation();
    }
  }

  showAddress() {
    this.address = '';
    if (this.selectedRelation.address1) { this.address = this.selectedRelation.address1 + ', ' }
    if (this.selectedRelation.address2) { this.address = this.selectedRelation.address2 + ', ' }
    if (this.selectedRelation.city) { this.address = this.address + this.selectedRelation.city + ', ' }
    if (this.selectedRelation.stateprovince) { this.address = this.address + this.selectedRelation.stateprovince + ', ' }
    if (this.selectedRelation.zipcode) { this.address = this.address + this.selectedRelation.zipcode + ', ' }
    if (this.selectedRelation.country) { this.address = this.address + this.selectedRelation.country }
    //get geo location
    if (this.address){
      this.getAddress();
    }
   
    this.saveRelation();
  }

  ngOnDestroy() {
    this.speechRecognitionService.DestroySpeechObject();
  }


  activateSpeech(): void {
    //load in service speech service
    // connected class to recordactive
    //
    //see manual --> https://hassantariqblog.wordpress.com/2016/12/04/angular2-web-speech-api-speech-recognition-in-angular2/
    if (this.recordactive === false) {
      this.recordactive = true;
      this.speechRecognitionService.record()
        .subscribe(
          //listener
          (value) => {
            this.speechData = value;
            //console.log(value);
            if (this.selectedCall.content !== undefined) {
              this.selectedCall.content = this.selectedCall.content + ". " + value
            }
            else this.selectedCall.content = value
          },
          //errror
          (err) => {
            //console.log(err.error, "--restarting service--");
            this.activateSpeech();
          },
          //completion
          () => {
            //console.log("--complete--");
            //this.activateSpeech();
          });
    } else {
      this.speechRecognitionService.speechRecognition.stop()
      this.speechRecognitionService.DestroySpeechObject();
      this.recordactive = false;
    }

  }

  replyNewMessage(): void {
    let title = 'RE:' + this.selectedCall.title;
    let html = this.selectedCall.html;
    this.RelationsApi.createCalls(this.selectedRelation.id, {
      "title": this.selectedCall.title,
      "accountId": this.Account.id,
      "companyId": this.Account.companyId,
      "html": html,
      date: new Date()
    }) //add companyId for statistics
      .subscribe(res => {
        this.data = res,
          this.callindex = this.Calls.push(this.data) - 1,
          this.onSelectCall(this.data, this.callindex),
          this.replyMessage(title, html);
        this.saveRelation();
      });
  }

  setCC() {

  }

  setBCC() {

  }

  createNewMessage(contactperson?): void {
    let call = new Calls();
    let signature = '';
    if (this.Account.signature) { signature = this.Account.signature; }
    this.RelationsApi.createCalls(this.selectedRelation.id, {
      html: '<p><span style="font-family:' + this.Company.companyfont + '; font-size:' + this.Company.companyfontsize + 'px;">Dear %recipient.firstname%, </span></p>' +
        '<br><p><span style="font-family:' + this.Company.companyfont + '; font-size:' + this.Company.companyfontsize + 'px;">&nbsp;</span></p>' + signature,
      title: "New",
      accountId: this.Account.id,
      companyId: this.Account.companyId,
      date: new Date()
    }) //add companyId for statistics
      .subscribe(res => {
        this.data = res,
          this.callindex = this.Calls.push(this.data) - 1,
          this.onSelectCall(this.data, this.callindex);
        this.SendMessageDialog(contactperson);
        this.saveRelation();
      });
  }


  replyMessage(title, html): void {
    let signature = '';
    if (this.Account.signature) { signature = this.Account.signature; }
    this.RelationsApi.createCalls(this.selectedRelation.id, {
      "html": '<p><span style="font-family:' + this.Company.companyfont + '; font-size:' + this.Company.companyfontsize + 'px;">Dear %recipient.firstname%, </span></p>' +
        '<br><p><span style="font-family:' + this.Company.companyfont + '; font-size:' + this.Company.companyfontsize + 'px;">&nbsp;</span></p>' + signature +
        '<br><hr><blockquote>&nbsp;</blockquote>' + html,
      title: 'RE:' + title,
      accountId: this.Account.id,
      companyId: this.Account.companyId,
      date: new Date()
    }) //add companyId for statistics
      .subscribe(res => {
        this.data = res,
          this.callindex = this.Calls.push(this.data) - 1,
          this.onSelectCall(this.data, this.callindex);
        this.SendMessageDialog();
        this.saveRelation();
      });
  }

  SendMessageFromTemplate(contactperson?): void {
    this.RelationsApi.createCalls(this.selectedRelation.id, {
      html: '',
      title: '',
      accountId: this.Account.id,
      companyId: this.Account.companyId,
      calltype: 'E-mail',
      date: new Date()
    }) //add companyId for statistics
      .subscribe(res => {
        this.data = res,
          this.callindex = this.Calls.push(this.data) - 1,
          this.onSelectCall(this.data, this.callindex);

        const dialogRef = this.dialog.open(MailingSelectDialog, {
          width: '80%',
          height: '80%',
          data: this.Company.id // changingThisBreaksApplicationSecurity,
        });

        dialogRef.afterClosed().subscribe(result => {
          //console.log(result)
          if (result !== undefined) {
            if (result.linkedin) {
              this.selectedCall.content = result.html;
              this.selectedCall.title = result.subject; // or title
              this.selectedCall.preview = result.preview;
              this.setNamesValuesLinkedin(contactperson);
              this.selectedCall.calltype = 'LinkedinMessage';
              this.saveCall();
              this.saveRelation();
              this.selectedTab = 1;
            } else {
              this.selectedCall.html = result.html;
              this.selectedCall.title = result.subject; // or title
              this.selectedCall.preview = result.preview
              if (contactperson) {
                this.emailtosendto = [contactperson];
                this.setNameValues();
              }
              this.selectedTab = 1;
              this.saveCall();
              this.selectedCall.readytosend = true;
              this.setNameValues();
              this.saveRelation();
            }

          }
        });
      });
  }

  SendMessageDialog(contactperson?): void {

    const dialogRef = this.dialog.open(TextEditorDialog, {
      width: '80%',
      height: '80%',
      data: this.selectedCall.html // changingThisBreaksApplicationSecurity,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.length > 0) {
          if (result.linkedin) {
            this.selectedCall.content = result.html;
            this.selectedCall.title = result.subject; // or title
            this.selectedCall.preview = result.preview;
            this.selectedCall.calltype = 'LinkedinMessage';
            this.setNamesValuesLinkedin(contactperson);
            this.saveCall();
            this.saveRelation();
          } else {
            this.selectedCall.html = result;
            if (contactperson) {
              this.emailtosendto = [contactperson];
              this.selectedTab = 1;
              this.setNameValues();
            }
            this.saveCall();
            this.selectedCall.readytosend = true;
          }
        };  //
      }
    });
  }

  setNamesValuesLinkedin(contactperson: any) {
    let replacedynamic = [
      contactperson.firstname + ' ' + contactperson.lastname,
      this.selectedRelation.relationname,
      contactperson.email,
      contactperson.firstname,
      contactperson.lastname,
      '',
      '',
      this.Account.signature
    ]
    for (let i = 0; i < this.dynamic.length; i++) {
      this.selectedCall.content = this.selectedCall.content.replace(this.dynamic[i], replacedynamic[i]);
    }
  }

  setNameValues() {
    if (this.emailtosendto.length === 0) {
      this.openSnackBar('No Recipient');
      return;
    }

    if (!this.selectedCall.html) {
      this.openSnackBar('No text selected');
      return;
    }
    //console.log(this.emailtosendto)

    let replacedynamic = [
      this.emailtosendto[0].firstname + ' ' + this.emailtosendto[0].lastname,
      this.selectedRelation.relationname,
      this.emailtosendto[0].email,
      this.emailtosendto[0].firstname,
      this.emailtosendto[0].lastname,
      'mailto:' + this.Account.email + '?subject=please unsubscribe me&body=Please unsubscribe me: ' + this.emailtosendto[0].email,
      'mailto:' + this.Account.email + '?subject=please unsubscribe me&body=Please unsubscribe me: ' + this.emailtosendto[0].email,
      this.Account.signature
    ]
    for (let i = 0; i < this.dynamic.length; i++) {
      this.selectedCall.html = this.selectedCall.html.replace(this.dynamic[i], replacedynamic[i]);
    }

    this.selectedCall.mailto = this.emailtosendto;
    this.selectedCall.mailbcc = this.emailtosendbcc;
    this.selectedCall.mailcc = this.emailtosendcc;
  }


  async sendNewMessage() {
    let mailto = [];
    let mailcc = [];
    let mailbcc = [];

    if (this.emailtosendto.length < 1) {
      this.openSnackBar('missing to fiedl');
      return
    }

    this.emailtosendto.forEach(contact => {
      mailto.push(contact.email)
    })
    let mailtostring = mailto.join(', ');

    if (this.emailtosendcc) {
      this.emailtosendcc.forEach(contact => {
        mailcc.push(contact.email)
      })
    }
    let mailccstring = mailcc.join(', ');

    if (this.emailtosendbcc) {
      this.emailtosendbcc.forEach(contact => {
        mailbcc.push(contact.email)
      })
    }
    let mailbccstring = mailbcc.join(', ');


    //let newHTML = await this.replaceURLs(this.selectedCall.html);
    this.CallsApi.replaceLinksHTML(this.selectedCall.id, this.Company.id, { html: this.selectedCall.html })
      .subscribe((newHTML: string) => {
        console.log(this.graphService.authenticated);
        if (this.graphService.authenticated) {
          this.sendGraphMail(newHTML, mailto, mailcc, mailbcc, this.selectedCall.preview);
        } else if (this.Emailhandler) {
          this.sendNodeMail(newHTML, mailtostring, mailccstring, mailbccstring)
        } else if (this.Company.companywebsite) {
          this.sendMailgunMail(newHTML, mailtostring, mailccstring, mailbccstring)
        } else {
          this.openSnackBar('No email account or domain set');
        }
      });
  }

  sendNodeMail(newHTML, mailtostring, mailccstring, mailbccstring) {
    this.EmailhandlerApi.sendMail(
      this.Company.id,
      this.Emailhandler,
      this.Account.email,
      mailtostring,
      this.selectedCall.title,
      newHTML, null).subscribe(res => { this.selectedCall.messageid = res }),
      error => { this.openSnackBar(error) }
  }

  getLinkInfo() {
    this.CallsApi.getLink(this.selectedCall.id,
      {
        include: {
          relation: 'linktracker'
        }
      }
    )
      .subscribe((links: Link[]) => {
        this.Link = links;
      });
  }

  sendMailgunMail(newHTML, mailtostring, mailccstring, mailbccstring) {
    let message = {
      from: this.sendfromaccount,
      to: mailtostring,
      subject: this.selectedCall.title,
      html: newHTML,
      bcc: mailbccstring,
      cc: mailccstring,
      relationsId: this.selectedRelation.id, //Import for look up domain
      companyId: this.Company.id //Import for look up domain
    };
    this.MailingApi.sendmail(this.Company.id, message).subscribe(res => {
      //console.log(res);
      if (res.message !== 'domain not allowed') {
        //let attendent = this.emailtosendto.firstname + ' ' + this.emailtosendto.lastname;
        //let attenobj = { "attendent": attendent };
        //this.selectedCall.attendee = [];
        //this.selectedCall.attendee.push(attenobj);
        this.selectedCall.readytosend = false;
        this.selectedCall.calltype = 'E-mail';
        this.selectedCall.date = new Date();
        this.saveCall();
        this.getCalls();
        this.openSnackBar(res.message);
      } else {
        this.openSnackBar(res.message);
      }
    });
  }


  async sendGraphMail(newHTML, mailto, cc2, bcc2, preview) {
    this.showprogressbarbusy = true;
    let mail;

    /* file uploader needs to be set to stream not multipart */
    if (this.attachmentUploader.queue.length > 0) {
      mail = await this.graphService.sendMailWithAttachments(
        this.selectedCall.title,
        newHTML,
        this.attachmentUploader,
        mailto,
        this.selectedCall.id,
        cc2,
        bcc2,
        preview,
      )
      if (mail.id) {
        console.log(mail)
        // get attachment IDs need to get unique GUID set in service mail.id == GUID here

        //let filter = `singleValueExtendedProperties/Any(ep:ep/id eq 'String {${mail.id}} Name callId' and ep/value eq '${this.selectedCall.id}')`;
        //let filter = `singleValueExtendedProperties/Any(ep:ep/id eq 'String {${mail.id}} Name callId' and ep/value eq ${this.selectedCall.id})`;
        let expand = `singleValueExtendedProperties($filter=id eq '${mail.singlevalue}')`;
        let currentmail = await this.graphService.getMailsFiltered(mail.id, expand);
        console.log(currentmail);
        let mailID = currentmail.internetMessageId;
        let AttachmentsList = [];
        let token = await this.graphService.getAccessToken();
        let attachedlist = await this.graphService.getAttachment(mailID)
        for (let i = 0; i < attachedlist.length; i++) {
          let file = attachedlist[i];
          AttachmentsList.push({ filename: file.name, contentId: file.contentId });
          this.UnsortedcallsApi.downloadAttachment(
            mailID, file.id, file.name, this.selectedCall.id, token, this.Account.id, this.Company.id)
            .subscribe()
        }

        this.selectedCall.attachments = AttachmentsList
      }
    } else {
      mail = await this.graphService.sendMail(
        this.selectedCall.title,
        newHTML,
        mailto,
        this.selectedCall.id,
        cc2,
        bcc2,
        preview
      );
    }
    if (mail.id) {
      console.log(mail);
      let expand = `singleValueExtendedProperties($filter=id eq '${mail.singlevalue}')`;
      let currentmail = await this.graphService.getMailsFiltered(mail.id, expand);
      console.log(currentmail);
      let mailID = currentmail.internetMessageId;
      this.selectedCall.mailto = this.emailtosendto;
      this.selectedCall.mailbcc = this.emailtosendbcc;
      this.selectedCall.mailcc = this.emailtosendcc;
      this.selectedCall.emailid = mailID;
      //this.selectedCall.RID = mail.singleValueExtendedProperties[0].id;
      this.selectedCall.calltype = 'E-mail';
      this.selectedCall.date = new Date();
      this.selectedCall.readytosend = false;
      this.selectedCall.attendee = [];
      this.saveCall();
      this.getCalls();
      this.openSnackBar('Email Send');
    } else {
      this.openSnackBar('Check your Office account');
    }
    this.showprogressbarbusy = false;
  }





  copyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar("Copied")
  }

  getOpportunities() {
    this.RelationsApi.getOpportunities(this.selectedRelation.id, { where: { removed: false } })
      .subscribe((opportunities: Opportunities[]) => {
        this.Opportunities = opportunities;
        this.opportunityCount = this.Opportunities.length;
      });
  }

  createSelectionArray(): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {

      // check if searchterm add selectin criteria
      this.searchterm = this.searchterm.trim();
      let selectionArray = [];
      //console.log(this.searchrelationowner)

      if (this.searchrelationowner) { selectionArray.push({ 'ownerId': this.searchrelationowner }) }
      if (this.selectstatus) { selectionArray.push({ "status": this.selectstatus }) }
      if (this.selecttype) { selectionArray.push({ "type": this.selecttype }) }
      if (this.selectconst1) { selectionArray.push({ "const1": this.selectconst1 }) }
      if (this.selectconst2) { selectionArray.push({ "const2": this.selectconst2 }) }
      if (this.selectconst3) { selectionArray.push({ "const3": this.selectconst3 }) }
      if (this.selectconst4) { selectionArray.push({ "const4": this.selectconst4 }) }
      if (this.selectmarketingrelation) { selectionArray.push({ "marketingrelation": true }) }
      if (this.searchcountry) { selectionArray.push({ "country": this.searchcountry }) }

      if (this.searchterm !== '') {
        selectionArray.push({ "relationname": { "regexp": '/' + this.searchterm + '/i' } });
        selectionArray.push({ "address1": { "regexp": '/' + this.searchterm + '/i' } });
        selectionArray.push({ "city": { "regexp": '/' + this.searchterm + '/i' } });
        selectionArray.push({ "stateprovince": { "regexp": '/' + this.searchterm + '/i' } });
        selectionArray.push({ "country": { "regexp": '/' + this.searchterm + '/i' } });
        selectionArray.push({ "type": { "regexp": '/' + this.searchterm + '/i' } });
      }
      resolve(selectionArray);
    });
  }


  async createMailingList() {
    let selectionArray = await this.createSelectionArray();
    this.createMailingL(selectionArray);
  }



  public createMailingL(selectionArray): void {
    if (!this.domain) { this.openSnackBar('Please select the domain name'); return; }
    if (!this.listname) { this.openSnackBar('Please fill in listname'); return; }
    let newMailinglist: Mailinglist = new Mailinglist();
    newMailinglist.relationsId = this.domain.id;
    newMailinglist.description = 'List from Relations';
    newMailinglist.listname = this.listname;
    this.RelationsApi.createMailinglist(this.domain.id, newMailinglist)
      .subscribe(async (resmailinglist: Mailinglist) => {
        console.log(newMailinglist)
        //await this.createMailingListMG(newMailinglist, this.domain.domain);
        let mailguninfo = await this.MailinglistApi.createmailinglist(resmailinglist, this.domain.domain).toPromise();
        console.log(mailguninfo)
        this.RelationsApi.updateByIdMailinglist(this.domain.id, resmailinglist.id, { mailgunid: mailguninfo.list.address }).toPromise();
        this.MailinglistApi.uploadMailingListFromRelations(
          this.Account.companyId,
          selectionArray,
          this.mailingdefaultcontact,
          this.domain.domain,
          resmailinglist.id,
          this.searchterm
        )
          .subscribe(() => {
            this.openSnackBar('Mailing list creation in progress')
          });
      });
  }

  getDomains() {
    this.CompanyApi.getRelations(this.Company.id, { where: { marketingrelation: true } })
      .subscribe((relations: Relations[]) => {
        //console.log(relations);
        relations.forEach(element => {
          if (element.domain) {
            this.domains.push(element);
          }
        });
      })
  }

  async setNewOwner() {
    if (!this.changerelationownerto) { this.openSnackBar('select new owner'); return; }
    console.log(this.changerelationownerto)
    this.dialogsService
      .confirm('Update Owner', `You are updating ${this.selectedcount} records to ${this.changerelationownerto.username}`)
      .subscribe(async (res) => {
        if (res) {
          let selectionArray = await this.createSelectionArray();
          this.CompanyApi.changeOwner(this.Account.companyId, selectionArray, this.changerelationownerto.accountId, this.searchterm).subscribe()
          this.searchGo();
          this.selectedRelation = undefined;
        }
      });

  }

  async setNewStatus() {
    this.dialogsService
      .confirm('Update status', `You are updating ${this.selectedcount} records`)
      .subscribe(async (res) => {
        if (res) {
          this.selectedRelation = undefined;
          let selectionArray = await this.createSelectionArray();
          //console.log(selectionArray)
          this.CompanyApi.changeStatus(this.Account.companyId, selectionArray, this.changerelationstatus, this.searchterm).subscribe()
          this.searchGo();

        }
      })
  }

  // create mailgun open/clicked/main lists
  public createMailingListMG(newMailinglist, domain): void {
    this.MailinglistApi.createmailinglist(newMailinglist, domain)
      .subscribe(res => { console.log(res) });
  }


  createNewOpportunity() {
    let countOp;
    this.OpportunitiesApi.count({ companyId: this.Account.companyId }).subscribe(res => {
      countOp = res.count
      let newOp: Opportunities = new Opportunities();
      if (this.Company.Opportunitystatus.length > 0) {
        newOp.status = this.Company.Opportunitystatus[0]
      }
      newOp.title = 'New';
      newOp.companyId = this.selectedRelation.companyId;
      newOp.currency = 'EUR';
      newOp.language = this.Account.language;
      newOp.date = new Date();
      newOp.relationsId = this.selectedRelation.id;
      newOp.invoicenumber = countOp + 1;
      this.RelationsApi.createOpportunities(this.selectedRelation.id, newOp) //add companyId for statistics
        .subscribe(res => {
          let data = res;
          this.Opportunities.push(data);
          this.onSelectOpportunity(data)
        });
    });
  }

  saveOpportunity() {
    this.selectedOpportunity.tasks = this.tasklistOp,
      this.selectedOpportunity.notes = this.notesOp,
      this.selectedOpportunity.attendee = this.attendeeOp,
      this.RelationsApi.updateByIdOpportunities(this.selectedRelation.id, this.selectedOpportunity.id, this.selectedOpportunity)
        .subscribe(Opportunity => { });
  }

  //this.selectedOpportunity = Opportunity
  // Calls => this.Calls[this.callindex] = this.selectedCall

  openDialogDeleteOpportunity() {
    this.dialogsService
      .confirm('Delete Opportunity', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res === true) {
          this.deleteOpportunity();
        }
        // this.Relations.splice(this.relationindex);
      });
  }

  deleteOpportunity() {
    this.selectedOpportunity.removed = true;
    this.RelationsApi.updateByIdOpportunities(this.selectedRelation.id, this.selectedOpportunity.id, this.selectedOpportunity)
      .subscribe(Opportunity => {
        this.getOpportunities();
        this.selectedOpportunity = undefined;
      });
  }


  searchOpportunitiesGo() {
    this.RelationsApi.getOpportunities(this.selectedRelation.id,
      {
        where: { "title": { "regexp": this.searchOpportunity + '/i' }, removed: false }
      })
      .subscribe((opportunities: Opportunities[]) => {
        this.Opportunities = opportunities;
      });
  }

  onSelectOpportunity(opportunity) {
    this.selectedOpportunity = opportunity;

  }

  printQuote() {
    let d = new Date();
    let date = d.toLocaleDateString();
    date = date.replace(/\//g, '');
    let transid = date + '-' + this.selectedOpportunity.invoicenumber;
    let language = 'en';
    if (this.selectedOpportunity.language) {
      language = this.selectedOpportunity.language
    }


    this.OpportunitiesApi.createquote(this.selectedOpportunity.id, this.selectedOpportunity,
      this.Account.companyId, this.selectedRelation.id,
      transid, this.Company.companylogo, false, language
    ).subscribe(res => {
      const byteCharacters = atob(res.$data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      var urlCreator = window.URL;
      let seturl = urlCreator.createObjectURL(blob);
      var element = document.createElement('a');
      //console.log(blob.type)
      element.setAttribute('href', seturl);
      element.setAttribute('download', transid + '.pdf');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    )


    // let d = new Date();
    // let date = d.toLocaleDateString();
    // date = date.replace(/\//g, '');
    // let url = 'http://localhost:3000/api/opportunities/createquote/';
    // let access_token = this.AccountApi.getCurrentToken();
    // let transid = date + '-' + this.selectedOpportunity.invoicenumber;
    // const headers = {
    //   'Accept': 'application/json',
    //   'Authorization': access_token.id
    // };

    // var element = document.createElement('a');
    // let params = new HttpParams()
    //   .set("companyid", this.Account.companyId)
    //   .set("relationid", this.selectedRelation.id)
    //   .set("opportunity", JSON.stringify(this.selectedOpportunity))
    //   .set("transid", transid)
    //   .set("companylogourl", this.Company.companylogo)
    //   .set("send", 'false')
    //   .set("language", language)
    // this.http.get(url, { headers: headers, params: params, responseType: 'blob', }
    // ).subscribe(blob => {
    //   var urlCreator = window.URL;
    //   let seturl = urlCreator.createObjectURL(blob);
    //   element.setAttribute('href', seturl);
    //   element.setAttribute('download', transid);
    //   element.style.display = 'none';
    //   document.body.appendChild(element);
    //   element.click();
    //   document.body.removeChild(element)
    // }, error => console.log(error)
    // )
  }



  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }


  createScreenshot() {
    let source = document.getElementById('screenshotframe');
    if (source) {
      domtoimage.toJpeg(source, { quality: 0.95 })
        .then((dataUrl) => {
          let name = this.selectedRelation.website + '-screenshot.jpeg'
          let blob: Blob = this.dataURItoBlob(dataUrl);
          let date: number = new Date().getTime();
          let file = new File([blob], name, { type: "image/jpeg", lastModified: date });
          let urluse = BASE_URL + '/api/Containers/' + this.selectedRelation.id + '/upload';
          this.uploader = new FileUploader({ url: urluse });
          let fileItem = new FileItem(this.uploader, file, {});
          this.uploader.queue.push(fileItem);
          fileItem.upload();
          this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            // set download url or actual url for publishing
            let imgurl = BASE_URL + '/api/Containers/' + this.Company.id + '/download/' + name;
            imgurl = imgurl.replace(/ /g, '-'),
              // define the file settings
              this.newFiles.name = name,
              this.newFiles.url = imgurl,
              this.newFiles.createdate = new Date(),
              this.newFiles.type = 'image',
              this.newFiles.companyId = this.Account.companyId;
            this.selectedRelation.screenshotwebsite = imgurl;
            this.saveRelation();
            this.CompanyApi.createFiles(this.Company.id, this.newFiles)
              .subscribe(res => { });
          };
        });
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }



}



