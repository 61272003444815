import { EventEmitter, HostListener, Injectable, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SavehistoryService implements OnDestroy {
  public destroy = false;
  //public mailtemplateArray = []; // array with style and position settings;
  public history = ['[]'];
  public currenthistoryversion = 0; // count not index number!! 

  @Input() debounceTime = 50;
  @Output() debounceClick = new EventEmitter();
  public clicks = new Subject();
  public subscriptionclick: Subscription;
  versionsaved: boolean;


  constructor(
  ) {
    /* Save history  */
    /* Retrieve one step history  */
    this.subscriptionclick = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => {
      //console.log(e)
      this.saveToLocalStorageHistory(e)
      // console.log('create history')
    }
      //console.log("Match:", this.arraysEqual(this.animationarray, ar2))
    );
  }


  ngOnDestroy() {
    this.destroy = true;
  }

  async KeyPress(evtobj): Promise<Array<any>> {

    //console.log(evtobj)
    if (evtobj.keyCode == 90 && evtobj.ctrlKey) {
      return await this.historyBack(); 
    } // windows
    else if (evtobj.keyCode == 89 && evtobj.ctrlKey) {
      return await this.historyForward(); 
    } // windows
    else if (evtobj.keyCode == 90 && evtobj.metaKey && !evtobj.shiftKey) {
      return await this.historyBack(); 
    } // mac
    else if (
      evtobj.keyCode == 90 && evtobj.metaKey && evtobj.shiftKey) {
      return await this.historyForward(); 
    } // mac
    else { return }
  }



  async saveToLocalStorageHistory(mailtemplateArray) {
    if (!this.destroy) { // check destroy is not triggered same time as save to storage

      this.versionsaved = false;
      // check if is actually a newer version check last with new is similar
      // triggers on mouseup event set debounce time if triggered to fast see ngoninit
      // clean chart area for JSON
      let jsonaniarray = JSON.stringify(mailtemplateArray); // ,this.getCircularReplacer()

      // check if array already exist if not set to 1 and add to array
      if (this.currenthistoryversion !== 0) {

        // grap last version
        let jsonaniarraylast = this.history[(this.currenthistoryversion - 2)];
        if (jsonaniarray !== jsonaniarraylast) {

          //console.log(this.currenthistoryversion < this.history.length)
          if (this.currenthistoryversion < this.history.length) {
           // console.log('delete: ', (this.currenthistoryversion -2), this.history.length)
            this.history.splice((this.currenthistoryversion), this.history.length)
          }
          this.history.push(jsonaniarray);
          ++this.currenthistoryversion;
          //console.log(this.history.length, this.currenthistoryversion)
        }
      } else {
        this.history.push(jsonaniarray);
        ++this.currenthistoryversion;
      }

    }
  }



  async historyBack(): Promise<Array<Object>> {
    // console.log('ctrl-z -1 ', this.currenthistoryversion, this.history)
    // if not last or not empty and if is new;
    if (this.currenthistoryversion > 1) {
      --this.currenthistoryversion; 
      let storedback = this.history[(this.currenthistoryversion -1)];
      let mailtemplateArray = JSON.parse(storedback);
      return mailtemplateArray;
    }
  }

  async historyForward(): Promise<Array<Object>> {
    // console.log('ctrl-y', this.currenthistoryversion)
    // if not latest or not empty
    if (this.currenthistoryversion < this.history.length) {
      let storedback = this.history[this.currenthistoryversion]
      let mailtemplateArray = JSON.parse(storedback);
      ++this.currenthistoryversion;
      //this.detectchange();
      return mailtemplateArray;
    }
  }

  objectsEqual = (o1, o2) => {
    let match = false
    if (typeof o1 === 'object' && Object.keys(o1).length > 0) {
      match = (Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p])))
    } else {
      match = (o1 === o2)
    }
    return match
  }

  arraysEqual = (a1, a2) => {
    let finalMatch = []
    let itemFound = []

    if (a1.length === a2.length) {
      finalMatch = []
      a1.forEach(i1 => {
        itemFound = []
        a2.forEach(i2 => {
          itemFound.push(this.objectsEqual(i1, i2))
        })
        finalMatch.push(itemFound.some(i => i === true))
      })
    }
    return finalMatch.every(i => i === true)
  }

}
