<div fxLayout="column">
  <br>
  <div fxLayout="row" id="subjectlines" style="margin: 0px 30px 0px 20px;">
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <mat-form-field style="max-width: 200px;" appearance="outline">
        <mat-label>Subject Line</mat-label>
        <input #inputsubject [(ngModel)]=subject type="text" matInput i18n-placeholder i18n-placeholder
          placeholder="Subject Line">
        <mat-icon i18n-matTooltip matTooltip="select Emoji" (click)="onshowemoji('subject')"
          matSuffix>insert_emoticon</mat-icon>
        <mat-hint align="end">{{inputsubject.value?.length || 0}}/78</mat-hint>
      </mat-form-field>
      <div *ngIf="showemoji">
        <div style="max-width: 300px; height: 100%; position: absolute; top: 8; left: 12; z-index: 20;">
          <emoji-mart (emojiClick)="setemoji($event)" set="apple" title="enydea"></emoji-mart>
        </div>
      </div>
      <mat-form-field style="max-width: 300px;" appearance="outline">
        <mat-label>Preview Text</mat-label>
        <input i18n-matTooltip matTooltip="First lines shown in email client" #inputpreview [(ngModel)]=preview
          type="text" matInput i18n-placeholder placeholder="Preview">
        <mat-icon i18n-matTooltip matTooltip="select Emoji" (click)="onshowemoji('preview')"
          matSuffix>insert_emoticon</mat-icon>
        <mat-hint align="end">{{inputpreview.value?.length || 0}}/90</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100px;">
        <mat-label i18n>View width</mat-label>
        <mat-select i18n-placeholder i18n-placeholder placeholder="Width" [(ngModel)]="setwidth" name="width">
          <mat-option *ngFor="let wid of widths" [value]="wid">
            {{wid}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="column">
        <mat-slide-toggle [checked]="responsivemail" (change)="onTogglePlainMail()" i18n>Responsive</mat-slide-toggle>
        <mat-slide-toggle [checked]="linkedinmessage" (change)="onToggleLinkedin()"
          i18n>Linkedin/Facebook</mat-slide-toggle>
      </div>



      <mat-form-field appearance="outline">
        <mat-label i18n>Select font</mat-label>
        <mat-select [(ngModel)]="generalfont" (selectionChange)="detectchange()">
          <mat-option *ngFor="let font of Fonts" [value]="font.value">
            <label [style.font-family]="font.value">{{font.name}}</label>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="company">
        <mat-label [style.color]="fontcolor">Font Color</mat-label>
        <input style="width: 200px;" name="fontcolor" [(ngModel)]="fontcolor" [(colorPicker)]="fontcolor" type="text"
          matInput i18n-placeholder
          [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]" />
      </mat-form-field>
    </div>

    <div id="dotsmenu">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Mailmenu a menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="ConvertToMail()" *ngIf="!updatemail">
          <mat-icon>save</mat-icon>
          <span>Convert to Email</span>
        </button>
        <button mat-menu-item (click)="CreateNewMail()">
          <mat-icon>refresh</mat-icon>
          <span>Start new Email</span>
        </button>
        <button mat-menu-item (click)="ConvertToMail()" *ngIf="updatemail">
          <mat-icon>save</mat-icon>
          <span>Update Template</span>
        </button>
        <button mat-menu-item (click)="ConvertAndSaveAsNewToMail()" *ngIf="updatemail">
          <mat-icon>save</mat-icon>
          <span>Save as New</span>
        </button>
        <button mat-menu-item (click)="downloadAsFile()">
          <mat-icon>download</mat-icon>
          <span>Download as File</span>
        </button>
        <button mat-menu-item (click)="openTemplates()">
          <mat-icon>add</mat-icon>
          <span>New Template</span>
        </button>
      </mat-menu>
    </div>

  </div>

  <mat-divider>
  </mat-divider>

  <div fxLayout="row" id="maintemplate">
    <div class="savetobutton">
      <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="New Template" (click)="openTemplates()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div>
      <label *ngIf="!option" i18n>Please select Relation</label>
      <div *ngIf="showprogressbarbusy" class="showprogressbardiv">
        <div style="position: absolute; left: 50%; top: 50%;">
          <mat-spinner mode="indeterminate"></mat-spinner>
          <br>
          <h1 i18n>Creating your awesome mail template one moment please... </h1>
        </div>
      </div>
    </div>

    <div cdkDropListGroup style="width: 100%; margin: 10px;" fxLayout="row">
      <!-- class="maintemplatecontainer" class="tools" class="settingsset" -->
      <div id="tools" fxFlex="20%">
        <div cdkDropList #toolsetList="cdkDropList" *ngIf="responsivemail" [cdkDropListData]="toolset"
          (cdkDropListDropped)="drop($event)" id="toolboxid">
          <span i18n style="margin-left: 10px;">Tools / Drag and Drop</span>
          <br style="margin: 5px">
          <mat-progress-bar *ngIf="showprogressbarbusy" mode="indeterminate"></mat-progress-bar>
          <div class="tool-box" *ngFor="let item of toolset" cdkDrag id="toolboxid">
            <div *ngIf="item.type === 'Image'" i18n-matTooltip matTooltip="add image">
              <mat-icon class="iconsize-18" matSuffix>add_photo_alternate</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Text'" i18n-matTooltip matTooltip="add text">
              <mat-icon class="iconsize-18" matSuffix>text_fields</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Button'" i18n-matTooltip matTooltip="add button">
              <mat-icon class="iconsize-18" matSuffix>add_box</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Divider'" i18n-matTooltip matTooltip="blank divider">
              <mat-icon class="iconsize-18" matSuffix>line_style</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Accordion'" i18n-matTooltip matTooltip="text Accordion">
              <mat-icon class="iconsize-18" matSuffix>view_day</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Carousel'" i18n-matTooltip matTooltip="image carousel">
              <mat-icon class="iconsize-18" matSuffix>view_carousel</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Social'" i18n-matTooltip matTooltip="share buttons">
              <mat-icon class="iconsize-18" matSuffix>thumb_up_alt</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Footer'" i18n-matTooltip matTooltip="add Footer">
              <mat-icon class="iconsize-18" matSuffix>fingerprint</mat-icon> {{item.type}}
            </div>
            <div *ngIf="item.type === 'Dynamic'" i18n-matTooltip matTooltip="add Dynamic content">
              <mat-icon class="iconsize-18" matSuffix>dynamic_form</mat-icon> {{item.type}}
            </div>
          </div>
          <br>

        </div>
        <div fxLayout="column" class="bottom-bar">
          <!-- <mat-accordion *ngIf="responsivemail">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Reusable Components
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let components of option.standardcomponents; let i1 = index;" fxLayout="row">
                <div *ngFor="let items of components">
                  <div style="border: lightgray; border-style: solid; border-width: 1px;" *ngFor="let parts of items">
                    <label style="font-size: 0.2vw"> {{parts.type}}</label>
                  </div>
                </div>
                <mat-icon i18n-matTooltip matTooltip="add to mail" (click)="onAddStandardSectionPart(i1)"
                  class="sectionlabelbuttons iconsize-10" matSuffix>add</mat-icon>
                <mat-icon i18n-matTooltip matTooltip="delete from profile" (click)="onDeleteStandardSectionPart(i1)"
                  class="sectionlabelbuttons iconsize-10" matSuffix>delete</mat-icon>
              </div>
              <button (click)="deleteAllStandardComp()" mat-button i18n>Delete All</button>
            </mat-expansion-panel>
          </mat-accordion> -->

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Dynamic input
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column" style="width: 200px;" i18n-matTooltip
                matTooltip="Click to copy, insert in text to retreive mailinglist information">
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%recipient_name%')">%recipient_name%</label>
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%recipient_company%')">%recipient_company%</label>
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%recipient_title%')">%recipient_title%</label>
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%recipient_firstname%')">%recipient_firstname%</label>
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%recipient_lastname%')">%recipient_lastname%</label>
                <label (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                  i18n-matTooltip matTooltip="Copied!" (click)="tooltip.toggle()" #tooltip="matTooltip"
                  (click)="copyMessage('%signature%')">'%signature%'</label>

              </div>
            </mat-expansion-panel>
          </mat-accordion>


        </div>
      </div>

      <div fxLayout="row" *ngIf="option" fxFlex style="max-height: 80vh;">
        <div [fxFlex]="setwidth" fxLayout="column" class="start-container">
          <div *ngIf="!responsivemail && !linkedinmessage">
            <mat-card>
              <text-editor-dialog [(dataset)]="mailcontent" [idset]="option.id"
                (textChangedEvent)="textChangedEvent($event)">
              </text-editor-dialog>
            </mat-card>
          </div>
          <div *ngIf="!responsivemail && linkedinmessage">
            <mat-card fxLayout="row">
              <mat-form-field fxFlex appearance="outline">
                <mat-label i18n>Message</mat-label>
                <textarea matInput #message i18n-placeholder placeholder="Message" [(ngModel)]="mailcontent"
                  maxlength="299" style="min-height: 300px;">
                </textarea>

                <mat-hint align="end">{{message.value.length}} / 299</mat-hint>
              </mat-form-field>
            </mat-card>
          </div>
          <div class="template-container-cover" style="max-height: 80vh; 
          overflow: scroll;" id="templatecontainercover">
            <div *ngIf="changenow && responsivemail" class="template-container-main" id="templatecontainermain"
              [style.font-family]="generalfont" [style.color]="fontcolor">
              <div *ngFor="let section of mailtemplateArray; let i1 = index" id="template-container-main-content"
                fxLayout="row">
                <!-- start section -->
                <div class="template-container" fxFlex [fxLayout]=sectionStyleArray[i1].boxalignment
                  [ngStyle]="sectionStyleArray[i1].style"
                  [class.fullwidthsection]="sectionStyleArray[i1].style['full-width'] === 'full-width'">


                  <!-- !!!!!! only for blur -->
                  <div *ngIf="sectionStyleArray[i1].imgblur" [class.imgblursection]="sectionStyleArray[i1].imgblur"
                    [style.background-image]="sectionStyleArray[i1].style['background-image']"
                    style="background-size: 100%; background-repeat: no-repeat;" style="margin-right: 15px;"></div>
                  <!-- cdkDropList #listSection="cdkDropList" (cdkDropListDropped)="drop($event, i1, i2)" [cdkDropListData]="mailtemplateArray" -->

                  <div class="sectionlabel">
                    <label color="primary" i18n>Section</label>
                    <mat-icon i18n-matTooltip matTooltip="save to profile" (click)="onSaveSectionPart(i1)"
                      color="primary" class="iconsize-10 sectionlabelbuttons" matSuffix>save</mat-icon>
                    <mat-icon i18n-matTooltip matTooltip="edit section" (click)="onSelectSectionPart(i1)"
                      color="primary" class="iconsize-10 sectionlabelbuttons" matSuffix
                      color="primary">mode_edit</mat-icon>
                    <mat-icon i18n-matTooltip matTooltip="up" (click)="moveSectionUp(i1)" color="primary"
                      class="iconsize-10 sectionlabelbuttons" matSuffix>
                      arrow_drop_up</mat-icon>
                    <mat-icon i18n-matTooltip matTooltip="down" (click)="moveSectionDown(i1)" color="primary"
                      class="iconsize-10 sectionlabelbuttons" matSuffix>
                      arrow_drop_down</mat-icon>
                    <mat-icon i18n-matTooltip matTooltip="delete" (click)="onDeleteSectionPart(i1)" color="primary"
                      class="sectionlabelbuttons iconsize-10" matSuffix>delete</mat-icon>
                  </div>


                  <!-- start column  -->
                  <div class="section-box full-width-item" *ngFor="let column of section; let i2 = index"
                    fxLayout="column">
                    <div class="column-box" cdkDropList #mailtemplateList="cdkDropList"
                      [cdkDropListData]="mailtemplateArray[i1][i2]" (cdkDropListDropped)="drop($event, i1, i2)">
                      <!-- [style.border]="columnStyleArray[i1][i2].style.border" -->
                      <div [ngStyle]="columnStyleArray[i1][i2].style" style="cursor: move;">

                        <div class="columnlabel" i18n>
                          <label>Column</label>
                          <mat-icon (click)="onSelectColumnPart(i1, i2)" class="iconsize-10 sectionlabelbuttons"
                            matSuffix>
                            mode_edit
                          </mat-icon>
                          <mat-icon (click)="onDeleteColumnPart(i1, i2)" class="iconsize-10 sectionlabelbuttons"
                            matSuffix>
                            delete
                          </mat-icon>
                        </div>

                        <!-- start items from columns -->
                        <div class="item-box" *ngFor="let item of column; let i3 = index;"
                          (click)="onSelectTemplatePart(item, i1, i2, i3)" cdkDrag
                          [fxLayoutAlign]="columnStyleArray[i1][i2].setflexalign">

                          <div *ngIf="item.type === 'Text'" (click)="onSelectTemplatePart(item, i1, i2, i3)"
                            class="full-width-item elementselect" [style.text-align]="item.style.align"
                            [ngStyle]="item.style">
                            <!--  (click)="openDialog()" -->
                            <div [innerHTML]="item.content | safeHtml" (dblclick)="openDialog()"></div>
                            <!-- <mat-icon class="textbox-edit-button" class="iconsize-10 text-edit-icon"
                              (click)="openDialog()">
                              mode_edit</mat-icon> -->
                          </div>

                          <div *ngIf="item.type === 'Image'" class="full-width-item elementselect">
                            <app-fileupload *ngIf="!item.url" [account]="account" [option]="option"
                              (imgurl)="setimgurl($event, item)">
                            </app-fileupload>
                            <img *ngIf="item.url" src={{item.url}} [ngStyle]="item.style"
                              [class.imggreyclass]="item.imggrey">
                          </div>


                          <div *ngIf="item.type === 'Dynamic'" class="full-width-item elementselect"
                            fxLayoutAlign="center center">
                            <img src="https://app.enydea.com/api/Containers/standardisometric/download/71.png"
                              [ngStyle]="item.style" matTooltip="Click to edit dynamic content"
                              [class.imggreyclass]="item.imggrey" (click)="opendDynamicWindow()"
                              style="max-width: 100px; height: auto;">
                          </div>

                          <div *ngIf="item.type === 'Divider'" [ngStyle]="item.style"
                            class="full-width-item elementselect">
                          </div>

                          <div *ngIf="item.type === 'Accordion'" [ngStyle]="item.style"
                            class="full-width-item elementselect">
                            <mat-accordion>
                              <div *ngFor="let elementAccordion of item.elements">
                                <mat-expansion-panel>
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                      {{elementAccordion.title.content}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  {{elementAccordion.text.content}}
                                </mat-expansion-panel>
                              </div>
                            </mat-accordion>
                          </div>

                          <div *ngIf="item.type === 'Carousel'" [ngStyle]="item.style"
                            class="full-width-item elementselect">
                            <div class="slideshow-container">
                              <div *ngFor="let image of item.images">
                                <img class="mySlides fade" *ngIf="image.style.src" src={{image.style.src}}
                                  style="width:100%">
                              </div>
                              <img (click)="plusSlides(-1)" [src]="item.style['right-icon']">
                              <img (click)="plusSlides(1)" [src]="item.style['left-icon']">
                              <div *ngFor="let imagethumb of item.images; let i = index" style="display: inline-block;">
                                <img *ngIf="imagethumb.style.src" (click)="currentSlide(i)" src={{imagethumb.style.src}}
                                  class="thumbnails">
                              </div>
                            </div>
                          </div>

                          <div *ngIf="item.type === 'Social'" class="full-width-item elementselect">
                            <div class="social-container" [ngStyle]="item.style">
                              <div *ngFor="let socialelement of item.elements" [style.text-align]="item.style.align"
                                [ngClass]="{ verticalSocial: item.style.mode === 'horizontal', horizontalSocial: item.style.mode === 'vertical' }">
                                <div [ngStyle]="socialelement.style">
                                  <img src={{socialelement.iconlocation}}
                                    [style.width]="socialelement.style['icon-size']">
                                  <label [style.padding]="socialelement.style.padding"
                                    (click)="onSelectTemplatePart(item, i1, i2, i3)">{{socialelement.content}}</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="item.type === 'Button'" class="full-width-item elementselect" fxLayout="columns"
                            fxLayoutAlign="center center">
                            <div (click)="onSelectTemplatePart(item, i1, i2, i3)" [style.border]="item.style.border"
                              [style.align]="item.style.align" [ngStyle]="item.style" fxLayout="columns"
                              fxLayoutAlign="center center">
                              <label>{{item.buttontext}}</label>
                            </div>
                          </div>

                          <div *ngIf="item.type === 'Footer'" (click)="onSelectTemplatePart(item, i1, i2, i3)"
                            class="full-width-item elementselect">
                            <p [ngStyle]="item.style" [innerHTML]="item.content"></p>
                          </div>

                          <mat-icon (click)="onDeleteItemPart(i1, i2, i3)" class="iconsize-10 icon-label"
                            matSuffix>delete
                          </mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addcolumnbox" fxLayoutAlign="center center">
                  <mat-icon i18n-matTooltip matTooltip="Add column" color="primary" style="float: right;"
                    (click)="addToSectionArray(i1)">
                    add</mat-icon>
                </div>
              </div>
            </div>
            <div fxLayoutAlign="center start" class="addsectionbutton">
              <mat-icon *ngIf="responsivemail" color="primary" i18n-matTooltip matTooltip="Add sections"
                (click)="addToMailTemplateArray()">add
              </mat-icon>
            </div>

          </div>

        </div>
      </div>

      <div id="editwindow" class="editwindow" style="max-height: 80vh; overflow: scroll; max-width: 20%;">
        <!-- || Text -->

        <div *ngIf="Column" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Column</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>

          <mat-form-field appearance="outline">
            <input [(ngModel)]=maileditorColumn.style.width type="text" matInput i18n-placeholder placeholder="width"
              (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>%/px</mat-hint>
          </mat-form-field>

          <div fxLayout="row">
            <span fxFlex="25%">
              <mat-icon>border_top</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_right</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_bottom</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_left</mat-icon>
            </span>
          </div>

          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                (change)="onChangePadding(maileditorColumn, 'padding-top', selectedPadding['padding-top']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                (change)="onChangePadding(maileditorColumn, 'padding-right', selectedPadding['padding-right']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                (change)="onChangePadding(maileditorColumn, 'padding-bottom', selectedPadding['padding-bottom']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                (change)="onChangePadding(maileditorColumn, 'padding-left', selectedPadding['padding-left']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Vertical Align</mat-label>
            <mat-select [(ngModel)]="maileditorColumn.style['vertical-align']" i18n-placeholder
              placeholder="Vertical align" (selectionChange)="changeverticalalign()">
              <mat-option value="middle" i18n>middle</mat-option>
              <mat-option value="top" i18n>top</mat-option>
              <mat-option value="bottom" i18n>bottom</mat-option>
            </mat-select>
          </mat-form-field>

          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border radius</mat-label>
            <input [(ngModel)]="maileditorColumn.style['border-radius']" type="text" matInput i18n-placeholder
              placeholder="Border Radius" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
          <br>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Select Background Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>

              <span [style.background]="maileditorColumn.style['background-color']"
                [(colorPicker)]="maileditorColumn.style['background-color']" [cpToggle]="true"
                (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>

            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Border Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <!-- <span [style.background]="selectedborder.color" [cpToggle]="true" [cpDialogDisplay]="'inline'"
            [cpCancelButton]="true" [(colorPicker)]="selectedborder.color" (colorPickerChange)="detectchange()"></span> -->
              <!-- <button mat-button (click)="onChangeBorder(maileditorColumn)">confirm</button> -->

              <span [style.background]="selectedborder.color" [(colorPicker)]="selectedborder.color" [cpToggle]="true"
                (colorPickerChange)="onChangeBorder(maileditorColumn)" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>

            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border width</mat-label>
            <input [(ngModel)]="selectedborder.width" type="number" matInput i18n-placeholder placeholder="border width"
              (change)="onChangeBorder(maileditorColumn)">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>px</mat-hint>
          </mat-form-field>


          <mat-form-field appearance="outline">
            <mat-label>Border Style</mat-label>
            <mat-select [(ngModel)]="selectedborder.style" i18n-placeholder placeholder="style"
              (selectionChange)="onChangeBorder(maileditorColumn)">
              <mat-option value="none">None</mat-option>
              <mat-option value="solid">Solid</mat-option>
              <mat-option value="dotted">Dotted</mat-option>
              <mat-option value="dashed">Dashed</mat-option>
              <mat-option value="double">Double</mat-option>
              <mat-option value="groove">Groove</mat-option>
              <mat-option value="ridge">Ridge</mat-option>
              <mat-option value="inset">Inset</mat-option>
              <mat-option value="outset">Outset</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div *ngIf="Section" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Section</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <br>
          <div fxLayout="row">
            <span fxFlex="25%">
              <mat-icon>border_top</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_right</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_bottom</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_left</mat-icon>
            </span>
          </div>

          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                (change)="onChangePadding(maileditorSection, 'padding-top', selectedPadding['padding-top']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                (change)="onChangePadding(maileditorSection, 'padding-right', selectedPadding['padding-right']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                (change)="onChangePadding(maileditorSection, 'padding-bottom', selectedPadding['padding-bottom']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                (change)="onChangePadding(maileditorSection, 'padding-left', selectedPadding['padding-left']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
          </div>
          <br>
          <mat-slide-toggle [(ngModel)]="fullwidth" (change)="changefullwidthSection()">Full width</mat-slide-toggle>
          <br>
          <mat-slide-toggle [(ngModel)]="columnverticalalign" (change)="setcolumnverticalalign(maileditorSection)" i18n>
            Align Vertical </mat-slide-toggle>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Select Background Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>

              <span [style.background]="maileditorSection.style['background-color']"
                [(colorPicker)]="maileditorSection.style['background-color']" [cpToggle]="true"
                (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Select Background Image
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="maileditorSection.style['background-url']">
                <img [src]="maileditorSection.style['background-url']" class="carouselimages">
                <mat-icon
                  (click)="maileditorSection.style['background-url'] = ''; maileditorSection.style['background-image'] =''"
                  class="icon-label" class="iconsize-12">
                  delete</mat-icon>
              </div>
              <app-fileupload *ngIf="!maileditorSection.style['background-url']" [account]="account" [option]="option"
                (imgurl)="setbackgroundImageSection($event)">
              </app-fileupload>

              <mat-form-field appearance="outline">
                <input [(ngModel)]="maileditorSection.style['background-url']" type="text" matInput i18n-placeholder
                  placeholder="Image url" (change)="detectchange()">
                <mat-icon matSuffix>mode_edit</mat-icon>
              </mat-form-field>


              <!-- <mat-form-field appearance="outline">
                <input [(ngModel)]="maileditorSection.style['background-size']" type="text" matInput i18n-placeholder
                  placeholder="Image size" (change)="detectchange()">
                <mat-icon matSuffix>mode_edit</mat-icon>
              </mat-form-field> -->

              <mat-slide-toggle
                (change)="setbackgroundImageSection(maileditorSection.style['background-url'], maileditorSection.imggrey, maileditorSection.imgblur)"
                [(ngModel)]="maileditorSection.imggrey" i18n>Greyscale</mat-slide-toggle>

              <mat-slide-toggle
                (change)="setbackgroundImageSection(maileditorSection.style['background-url'], maileditorSection.imggrey, maileditorSection.imgblur)"
                [(ngModel)]="maileditorSection.imgblur" i18n>Blur</mat-slide-toggle>

              <div fxLayout="row" fxLayoutGap="5px">
                <button fxFlex="50%" mat-raised-button (click)="setBGsectionImageSize( maileditorSection, 'cover')">Cover</button>
                <button fxFlex="50%" mat-raised-button (click)="setBGsectionImageSize( maileditorSection, 'contain')">Contain</button>
              </div>
              <mat-form-field appearance="outline">
                <input [(ngModel)]="maileditorSection.style['background-size']" type="text" matInput i18n-placeholder
                  placeholder="Background size" (change)="detectchange()">
                <mat-icon matSuffix>mode_edit</mat-icon>
                <mat-hint>%/px</mat-hint>
              </mat-form-field>
              <mat-slide-toggle [(ngModel)]="backgroundrepeat" (change)="togglebackgroundrepeat()" i18n>Background Image
                Repeat</mat-slide-toggle>



            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border width</mat-label>
            <input [(ngModel)]="selectedborder.width" type="number" matInput i18n-placeholder placeholder="border width"
              (change)="onChangeBorder(maileditorSection)">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>px</mat-hint>
          </mat-form-field>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border Style</mat-label>
            <mat-select [(ngModel)]="selectedborder.style" i18n-placeholder placeholder="style"
              (selectionChange)="onChangeBorder(maileditorSection)">
              <mat-option value="none">None</mat-option>
              <mat-option value="solid">Solid</mat-option>
              <mat-option value="dotted">Dotted</mat-option>
              <mat-option value="dashed">Dashed</mat-option>
              <mat-option value="double">Double</mat-option>
              <mat-option value="groove">Groove</mat-option>
              <mat-option value="ridge">Ridge</mat-option>
              <mat-option value="inset">Inset</mat-option>
              <mat-option value="outset">Outset</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Border Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <span [style.background]="selectedborder.color" [(colorPicker)]="selectedborder.color" [cpToggle]="true"
                (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
              <button mat-button (click)="onChangeBorder(maileditorSection)" i18n>confirm</button>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <!-- <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="maileditorSection.style['text-align']" i18n-placeholder placeholder="Vertical align"
              (selectionChange)="detectchange()">
              <mat-option value="left">left</mat-option>
              <mat-option value="center">center</mat-option>
              <mat-option value="right">right</mat-option>
              <mat-option value="justify">justify</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>

        <div *ngIf="Image" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Image</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorImage.style.width" type="text" matInput i18n-placeholder placeholder="width"
              (change)="detectchange()">
            <label matSuffix>px/%</label>
          </mat-form-field>
          <mat-slide-toggle [(ngModel)]="maileditorImage.style['fluid-on-mobile']">Fluid size on mobile devices
          </mat-slide-toggle>

          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorImage.style.height" type="text" matInput i18n-placeholder placeholder="height"
              (change)="detectchange()">
            <label matSuffix>px/%</label>
          </mat-form-field>
          <mat-slide-toggle [(ngModel)]="maileditorImage.imggrey">Greyscale</mat-slide-toggle>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorImage.style.href" type="text" matInput i18n-placeholder
              placeholder="link to website" (change)="detectchange()">
          </mat-form-field>
          <label i18n>
            Padding
          </label>
          <div fxLayout="row">
            <span fxFlex="25%">
              <mat-icon>border_top</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_right</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_bottom</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_left</mat-icon>
            </span>
          </div>
          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                (change)="onChangePadding(maileditorImage, 'padding-top', selectedPadding['padding-top']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                (change)="onChangePadding(maileditorImage, 'padding-right', selectedPadding['padding-right']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                (change)="onChangePadding(maileditorImage, 'padding-bottom', selectedPadding['padding-bottom']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                (change)="onChangePadding(maileditorImage, 'padding-left', selectedPadding['padding-left']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>


          </div>
          <mat-form-field appearance="outline">
            <mat-label>Image align</mat-label>
            <mat-select [(ngModel)]="maileditorImage.style['align']" i18n-placeholder placeholder="Align"
              (selectionChange)="changetextalign(maileditorImage.style['align'])">
              <mat-option value="left" i18n>left</mat-option>
              <mat-option value="center" i18n>center</mat-option>
              <mat-option value="right" i18n>right</mat-option>
              <mat-option value="justify" i18n>justify</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="Divider" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Divider</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Border width</mat-label>
            <input [(ngModel)]="maileditorDivider.style['border-width']" type="text" matInput i18n-placeholder
              placeholder="Border Width" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>%/px</mat-hint>
          </mat-form-field>
          <br>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorDivider.style.width" type="text" matInput i18n-placeholder
              placeholder="height" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>%/px</mat-hint>
          </mat-form-field>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Border Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <span [style.background]="maileditorDivider.style['border-color']"
                [(colorPicker)]="maileditorDivider.style['border-color']" [cpToggle]="true"
                (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
              <button mat-button (click)="onChangeBorder(maileditorDivider)">confirm</button>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <label i18n>
            Padding
          </label>
          <div fxLayout="row">
            <span fxFlex="25%">
              <mat-icon>border_top</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_right</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_bottom</mat-icon>
            </span>
            <span fxFlex="25%">
              <mat-icon>border_left</mat-icon>
            </span>
          </div>
          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                (change)="onChangePadding(maileditorDivider, 'padding-top', selectedPadding['padding-top']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                (change)="onChangePadding(maileditorDivider, 'padding-right', selectedPadding['padding-right']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                (change)="onChangePadding(maileditorDivider, 'padding-bottom', selectedPadding['padding-bottom']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                (change)="onChangePadding(maileditorDivider, 'padding-left', selectedPadding['padding-left']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
          </div>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border style</mat-label>
            <mat-select [(ngModel)]="maileditorDivider.style['border-style']" i18n-placeholder placeholder="style"
              (selectionChange)="detectchange()">
              <mat-option value="none" i18n>None</mat-option>
              <mat-option value="solid" i18n>Solid</mat-option>
              <mat-option value="dotted" i18n>Dotted</mat-option>
              <mat-option value="dashed" i18n>Dashed</mat-option>
              <mat-option value="double" i18n>Double</mat-option>
              <mat-option value="groove" i18n>Groove</mat-option>
              <mat-option value="ridge" i18n>Ridge</mat-option>
              <mat-option value="inset" i18n>Inset</mat-option>
              <mat-option value="outset" i18n>Outset</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="Button" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Button</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Button label</mat-label>
            <input [(ngModel)]="maileditorButton.buttontext" type="text" matInput i18n-placeholder i18n-placeholder
              placeholder="Button Text" (change)="detectchange()">
            <mat-icon i18n-matTooltip matTooltip="select Emoji" (click)="onshowemojiButton()"
              matSuffix>insert_emoticon</mat-icon>
          </mat-form-field>
          <div *ngIf="showemojibutton">
            <div style="width: 100%;
          height: 100%; position: absolute; top: 8;
          left: 20; z-index: 20;">
              <emoji-mart (emojiClick)="setemojibutton($event)" set="apple" title="enydea"></emoji-mart>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="50%">
              <mat-label>Height</mat-label>
              <input [(ngModel)]="maileditorButton.style.height" type="text" matInput i18n-placeholder
                placeholder="Height" (change)="detectchange()">
              <mat-hint matSuffix>px</mat-hint>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" fxFlex="50%">
              <mat-label>Width</mat-label>
              <input [(ngModel)]="maileditorButton.style.width" type="text" matInput i18n-placeholder
                placeholder="Width" (change)="detectchange()">
              <mat-hint matSuffix>px</mat-hint>
            </mat-form-field>
          </div>

          <br>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40%">
              <mat-label>Font size</mat-label>
              <input [(ngModel)]="maileditorButton.style['font-size']" matInput i18n-placeholder placeholder="Font size"
                (change)="detectchange()">
              <mat-hint matSuffix>px</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="60%">
              <mat-label i18n>Select font</mat-label>
              <mat-select [(ngModel)]="maileditorButton.style['font-family']" (selectionChange)="detectchange()">
                <mat-option *ngFor="let font of Fonts" [value]="font.value">
                  <label [style.font-family]="font.value">{{font.name}}</label>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Destination URL</mat-label>
            <input [(ngModel)]="maileditorButton.buttonurl" type="text" matInput i18n-placeholder
              placeholder="Button URL" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Background Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <span [style.background]="maileditorButton.style['background-color']"
                [(colorPicker)]="maileditorButton.style['background-color']" [cpToggle]="true"
                (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Text Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <span [style.background]="maileditorButton.style.color" [(colorPicker)]="maileditorButton.style.color"
                [cpToggle]="true" (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Border radius</mat-label>
            <input [(ngModel)]="maileditorButton.style['border-radius']" type="text" matInput i18n-placeholder
              placeholder="Border Radius" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
          <br>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="50%">
              <mat-label>Border width</mat-label>
              <input [(ngModel)]="maileditorButton.style['border-width']" matInput i18n-placeholder
                placeholder="border width" (change)="onChangeBorderButton(maileditorButton)">
              <mat-hint matSuffix>px</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50%">
              <mat-label>Border Style</mat-label>
              <mat-select [(ngModel)]="maileditorButton.style['border-style']" i18n-placeholder placeholder="style"
                (selectionChange)="onChangeBorderButton(maileditorButton)">
                <mat-option value="none">None</mat-option>
                <mat-option value="solid">Solid</mat-option>
                <mat-option value="dotted">Dotted</mat-option>
                <mat-option value="dashed">Dashed</mat-option>
                <mat-option value="double">Double</mat-option>
                <mat-option value="groove">Groove</mat-option>
                <mat-option value="ridge">Ridge</mat-option>
                <mat-option value="inset">Inset</mat-option>
                <mat-option value="outset">Outset</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <br>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title i18n>
                  Border Color
                </mat-panel-title>
              </mat-expansion-panel-header>
              <br>
              <span [(colorPicker)]="maileditorButton.style['border-color']" [cpToggle]="true"
                (colorPickerChange)="onChangeBorderButton(maileditorButton)" *ngIf="company"
                [cpDialogDisplay]="'inline'" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                name="buttonbordercolor"
                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
              </span>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Text Align</mat-label>
            <mat-select [(ngModel)]="maileditorButton.style['text-align']" i18n-placeholder placeholder="Text align"
              (selectionChange)="detectchange()">
              <mat-option value="left" i18n>left</mat-option>
              <mat-option value="center" i18n>center</mat-option>
              <mat-option value="right" i18n>right</mat-option>
              <mat-option value="justify" i18n>justify</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <!-- <mat-form-field appearance="outline">
          <mat-label>Horizontal align</mat-label>
          <mat-select [(ngModel)]="maileditorButton.style['align']" i18n-placeholder placeholder="Horizontal align"
            (selectionChange)="changetextalign(maileditorButton.style['align'])">
            <mat-option value="left" i18n>left</mat-option>
            <mat-option value="center" i18n>center</mat-option>
            <mat-option value="right" i18n>right</mat-option>
            <mat-option value="justify" i18n>justify</mat-option>
          </mat-select>
        </mat-form-field> -->
        </div>

        <div *ngIf="Carousel" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Carousel</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>

          <div fxLayout="row">
            <mat-form-field appearance="outline" fxLayoutGap="5px">
              <mat-label>Width</mat-label>
              <input [(ngModel)]="maileditorCarousel.style['tb-width']" type="text" matInput i18n-placeholder
                placeholder="width" (change)="detectchange()">
              <label matSuffix>px/%</label>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline">
              <mat-label>Height</mat-label>
              <input [(ngModel)]="maileditorCarousel.style['tb-height']" type="text" matInput i18n-placeholder
                placeholder="height" (change)="detectchange()">
              <label matSuffix>px/%</label>
            </mat-form-field>
          </div>
          <br>
          <div *ngFor="let image of maileditorCarousel.images; let i = index">
            <div *ngIf="image.style.src">
              <img src={{image.style.src}} class="carouselimages">
              <mat-icon (click)="image.style.src = ''" class="icon-label" class="iconsize-12">delete</mat-icon>
            </div>
            <app-fileupload *ngIf="!image.style.src" [account]="account" [option]="option"
              (imgurl)="setCarouselImage($event, i)">
            </app-fileupload>
          </div>
          <mat-icon (click)="addImageToCarouselArray()">add</mat-icon>
        </div>

        <div *ngIf="Accordion" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Accordion</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <mat-form-field appearance="outline">
            <mat-label i18n>Width</mat-label>
            <input [(ngModel)]="maileditorAccordion.style['icon-width']" type="text" matInput i18n-placeholder
              placeholder="width" (change)="detectchange()">
            <label matSuffix>px/%</label>
          </mat-form-field>
          <br>
          <mat-form-field appearance="outline">
            <mat-label i18n>Height</mat-label>
            <input [(ngModel)]="maileditorAccordion.style['icon-height']" type="text" matInput i18n-placeholder
              placeholder="height" (change)="detectchange()">
            <label matSuffix>px/%</label>
          </mat-form-field>
          <br>
          <div *ngFor="let elementAcc of maileditorAccordion.elements">
            <mat-form-field appearance="outline">
              <mat-label i18n>Title</mat-label>
              <input [(ngModel)]="elementAcc.title.content" type="text" matInput i18n-placeholder placeholder="Title"
                (change)="detectchange()">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label i18n>Content Text</mat-label>
              <input [(ngModel)]="elementAcc.text.content" type="text" matInput i18n-placeholder placeholder="Text"
                (change)="detectchange()">
            </mat-form-field>
          </div>
          <mat-icon (click)="addElementToAccordionArray()">add</mat-icon>
        </div>

        <div *ngIf="Social" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Social Media</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <speed-dial-fab i18n-matTooltip matTooltip="Add social media link" class="speed-dial-container"
            [reverse-column-direction]="true" (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons"
            direction="column">
          </speed-dial-fab>
          <mat-form-field appearance="outline">
            <mat-label>Icon directions</mat-label>
            <mat-select [(ngModel)]="maileditorSocial.style.mode" i18n-placeholder placeholder="Direction Icons"
              (selectionChange)="detectchange()">
              <mat-option value="horizontal" i18n>horizontal</mat-option>
              <mat-option value="vertical" i18n>vertical</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Outline</mat-label>
            <mat-select [(ngModel)]="maileditorSocial.style['align']" i18n-placeholder placeholder="Align"
              (selectionChange)="changetextalign(maileditorSocial.style['align'])">
              <mat-option value="left" i18n>left</mat-option>
              <mat-option value="center" i18n>center</mat-option>
              <mat-option value="right" i18n>right</mat-option>
              <mat-option value="justify" i18n>justify</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-accordion>
            <div *ngFor="let socialelement of maileditorSocial.elements; let i = index;">
              <mat-expansion-panel style="max-width: 270px;">
                <mat-expansion-panel-header (click)="onSelectPadding(socialelement)">
                  <mat-panel-title>
                    <div fxLayout="row">
                      <img src={{socialelement.iconlocation}} style="width: 20px;">
                      <label i18n> Social link: {{socialelement.style.name}}</label>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" style="max-width: 200px;">
                  <input [(ngModel)]="socialelement.content" type="text" matInput i18n-placeholder placeholder="Title"
                    (change)="detectchange()">
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 200px;">
                  <input [(ngModel)]="socialelement.style.href" type="text" matInput i18n-placeholder
                    placeholder="Link to company page " (change)="detectchange()">
                </mat-form-field>
                <div fxLayout="row">
                  <span fxFlex="25%">
                    <mat-icon>border_top</mat-icon>
                  </span>
                  <span fxFlex="25%">
                    <mat-icon>border_right</mat-icon>
                  </span>
                  <span fxFlex="25%">
                    <mat-icon>border_bottom</mat-icon>
                  </span>
                  <span fxFlex="25%">
                    <mat-icon>border_left</mat-icon>
                  </span>
                </div>
                <div fxLayout="row">
                  <mat-form-field appearance="outline" fxFlex="25%">
                    <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                      (change)="onChangePadding(socialelement, 'padding-top', selectedPadding['padding-top']);">
                    <mat-hint>px</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="25%">
                    <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                      (change)="onChangePadding(socialelement, 'padding-right', selectedPadding['padding-right']);">
                    <mat-hint>px</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="25%">
                    <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                      (change)="onChangePadding(socialelement, 'padding-bottom', selectedPadding['padding-bottom']);">
                    <mat-hint>px</mat-hint>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="25%">
                    <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                      (change)="onChangePadding(socialelement, 'padding-left', selectedPadding['padding-left']);">
                    <mat-hint>px</mat-hint>
                  </mat-form-field>
                </div>
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Select font color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <span [style.background]="socialelement.style.color" [(colorPicker)]="socialelement.style.color"
                      [cpToggle]="true" (colorPickerChange)="detectchange()" *ngIf="company"
                      [cpDialogDisplay]="'inline'" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                      [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                    </span>

                  </mat-expansion-panel>
                </mat-accordion>

                <mat-form-field appearance="outline" style="max-width: 200px;">
                  <input [(ngModel)]="socialelement.style['icon-size']" type="text"
                    (change)="socialelement.style['icon-height'] = socialelement.style['icon-size']" matInput
                    i18n-placeholder placeholder="Icon Height" (change)="detectchange()">
                  <mat-icon matSuffix>mode_edit</mat-icon>
                  <mat-hint>%/px</mat-hint>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="socialelement.style.name" i18n-placeholder placeholder="Socialnetwork"
                    (selectionChange)="onChangeSocial(maileditorSocial, i)">
                    <mat-option value="facebook">facebook</mat-option>
                    <mat-option value="twitter">twitter</mat-option>
                    <mat-option value="pinterest">pinterest</mat-option>
                    <mat-option value="linkedin">linkedin</mat-option>
                    <mat-option value="tumblr">tumblr</mat-option>
                    <mat-option value="xing">xing</mat-option>
                    <mat-option value="github">github</mat-option>
                    <mat-option value="instagram">instagram</mat-option>
                    <mat-option value="web">web</mat-option>
                    <mat-option value="snapchat">snapchat</mat-option>
                    <mat-option value="youtube">youtube</mat-option>
                    <mat-option value="vimeo">vimeo</mat-option>
                  </mat-select>
                </mat-form-field>  -->

                <!-- <mat-form-field appearance="outline" style="max-width: 200px;">
                  <mat-select [(ngModel)]="socialelement.style.mode" i18n-placeholder placeholder="Direction Icons">
                    <mat-option value="horizontal">horizontal</mat-option>
                    <mat-option value="vertical">vertical</mat-option>
                  </mat-select>
                </mat-form-field> -->
                <mat-icon i18n-matTooltip matTooltip="delete" (click)="deletesocial(i)">delete</mat-icon>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorSocial.style.width" type="text" matInput i18n-placeholder placeholder="width"
              (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>%/px</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="maileditorSocial.style.height" type="text" matInput i18n-placeholder
              placeholder="height" (change)="detectchange()">
            <mat-icon matSuffix>mode_edit</mat-icon>
            <mat-hint>%/px</mat-hint>
          </mat-form-field>

        </div>

        <div *ngIf="Footer" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Footer</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <div fxlayout="column" style="width: 100%;">
            <mat-form-field appearance="outline" style="width: 90%;">
              <mat-label>Footer Text</mat-label>
              <textarea style="width: 90%;" [(ngModel)]="maileditorFooter.content" [matTextareaAutosize] type="text"
                matInput i18n-placeholder placeholder="Footer Text"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Font Size</mat-label>
              <input [(ngModel)]="maileditorFooter.style['font-size']" type="text" matInput i18n-placeholder
                placeholder="font size" (change)="detectchange()">
            </mat-form-field>

            <br>
            <mat-form-field appearance="outline">
              <mat-label i18n>Select font</mat-label>
              <mat-select [(ngModel)]="maileditorFooter.style['font-family']" (selectionChange)="detectchange()">
                <mat-option *ngFor="let font of Fonts" [value]="font.value">
                  <label [style.font-family]="font.value">{{font.name}}</label>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Text Position</mat-label>
              <mat-select [(ngModel)]="maileditorFooter.style['align']" i18n-placeholder placeholder="Align"
                (selectionChange)="changetextalign(maileditorFooter.style['align'])">
                <mat-option value="left">left</mat-option>
                <mat-option value="center">center</mat-option>
                <mat-option value="right">right</mat-option>
                <mat-option value="justify">justify</mat-option>
              </mat-select>
            </mat-form-field>

            <br>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Text Color
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <br>

                <span [style.background]="maileditorFooter.style['color']"
                  [(colorPicker)]="maileditorFooter.style['color']" [cpToggle]="true"
                  (colorPickerChange)="detectchange()" *ngIf="company" [cpDialogDisplay]="'inline'"
                  [cpAlphaChannel]="'disabled'" [cpOutputFormat]="hex"
                  [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                </span>
              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>

        <div *ngIf="Text" fxLayout="column" [@fadeInOut]>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 i18n>Edit Text</h3>
            <mat-icon (click)="onSettingsClose()">close</mat-icon>
          </div>
          <br>
          <button (click)="openDialog()" mat-raised-button>Edit Text</button>
          <br>
          <mat-form-field appearance="outline">
            <mat-label>Align Text</mat-label>
            <mat-select [(ngModel)]="maileditorText.style['align']" i18n-placeholder placeholder="Align"
              (selectionChange)="changetextalign(maileditorText.style['align'])">
              <mat-option value="left" i18n>left</mat-option>
              <mat-option value="center" i18n>center</mat-option>
              <mat-option value="right" i18n>right</mat-option>
              <mat-option value="justify" i18n>justify</mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayout="row">
            <span fxFlex="24%">
              <mat-icon>border_top</mat-icon>
            </span>
            <span fxFlex="24%">
              <mat-icon>border_right</mat-icon>
            </span>
            <span fxFlex="24%">
              <mat-icon>border_bottom</mat-icon>
            </span>
            <span fxFlex="24%">
              <mat-icon>border_left</mat-icon>
            </span>
          </div>
          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-top']" type="number" matInput
                (change)="onChangePadding(maileditorText, 'padding-top', selectedPadding['padding-top']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-right']" type="number" matInput
                (change)="onChangePadding(maileditorText, 'padding-right', selectedPadding['padding-right']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-bottom']" type="number" matInput
                (change)="onChangePadding(maileditorText, 'padding-bottom', selectedPadding['padding-bottom']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="25%">
              <input [(ngModel)]="selectedPadding['padding-left']" type="number" matInput
                (change)="onChangePadding(maileditorText, 'padding-left', selectedPadding['padding-left']);">
              <mat-hint>px</mat-hint>
            </mat-form-field>
          </div>
        </div>


      </div>
    </div>


  </div>
</div>