
// import { BASE_URL } from '../../shared/base.api';


let BASE_URL = 'https://app.enydea.com'

export const StockVideo = [
    BASE_URL + '/api/Containers/standardvideo/download/gold-lights-screen-display.mov',
    BASE_URL + '/api/Containers/standardvideo/download/motion-graphic-circular-holographic-display.mov',
    BASE_URL + '/api/Containers/standardvideo/download/road-city.mov',
    BASE_URL + '/api/Containers/standardvideo/download/river.mov',
    BASE_URL + '/api/Containers/standardvideo/download/old-paper.mov',
    BASE_URL + '/api/Containers/standardvideo/download/wheat-field.mov',
    BASE_URL + '/api/Containers/standardvideo/download/hongkong.mov',
    BASE_URL + '/api/Containers/standardvideo/download/mountains.mov',
    BASE_URL + '/api/Containers/standardvideo/download/kitsrunning.mov',
    BASE_URL + '/api/Containers/standardvideo/download/champagne-pick-up-glas.mov',
    BASE_URL + '/api/Containers/standardvideo/download/financial-data-on-tablet.mov',
    BASE_URL + '/api/Containers/standardvideo/download/bbq-meat.mov'
]