import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  LoggerApi,
  Logger,
  Account,
  AccountApi,
  CallsApi,
  Calls,
  CompanyApi,
  Company,
  RelationsApi,
  Relations,
  PublicationsApi,
  Googleanalytics,
  GoogleanalyticsApi,
  MailingApi,
  Websitetracker,
  WebsitetrackerApi,
  MarketingplannereventsApi,
  Marketingplannerevents,
  Twitter,
  TwitterApi,
  OpportunitiesApi,
  Opportunities,
  LoopBackFilter
} from '../shared/sdk';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ChartType, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogGetname } from './../dialogsservice/dialog.getname';
import { GoogleMapService } from '../shared/googlemapservice/googlemap.service';
import { colorset } from '../shared/colorsets';
import { DialogsService } from '../dialogsservice/dialogs.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CheckRelationDialogComponent } from './dialog/check-relation-dialog/check-relation-dialog.component';

export class Chart {
  data: Array<any>;
  label: string;
}

export interface cityresult {
  lat: string,
  lng: string
}

export interface DataObject {
  type: string;
  startdate: Date;
  enddate: Date;
  dyndate: string;
  dimension: string;
  data_set: Array<Chart>;
  data_labels: Array<Object>;
  charttype: string;
  colorscheme: Array<Object>;
  chartsize: string;
  data_object: Array<Object>;
  color: string;
  showlabels: boolean;
  showlegend: boolean;
  metric: string;
}


export function createDataObject(type, startdate, enddate, charttype, dimension, id): {
  id: string;
  type: string;
  startdate: Date;
  enddate: Date;
  dyndate: string;
  dimension: string;
  data_set: Array<Chart>;
  data_labels: Array<Object>;
  charttype: string;
  colorscheme: Array<Object>;
  chartsize: string;
  data_object: Array<Object>;
  color: string;
  showlabels: boolean;
  showlegend: boolean;
  currentlabel: string;
} {
  let newDataObject = {
    id: id,
    type: type,
    startdate: startdate,
    enddate: enddate,
    dyndate: '',
    dimension: dimension,
    data_set: [],
    data_labels: [],
    charttype: charttype,
    colorscheme: undefined,
    chartsize: '47%',
    data_object: [],
    color: 'auto',
    showlabels: true,
    showlegend: true,
    currentlabel: ''
  }
  return newDataObject;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  myControlRelations = new UntypedFormControl();
  myControlDash = new UntypedFormControl();
  filteredRelations: Relations[];

  view: any[] = [600, 400];
  colorScheme = {
    domain: ['#e1f5fe', '#b3e5fc', '#81d4fa', '#4fc3f7', '#29b6f6', '#03a9f4', '#039be5', '#0288d1', '#0277bd', '#01579b']
  };

  private analytics_ids; //'ga:154403562'; // add user to analytics account
  //private analytics_metrics = [{ expression: 'ga:users' }];
  //private analytics_metrics = [{ name: 'activeUsers' }];
  public Company = new Company;
  public dashboardsavetocompany = false;
  public dashboardsetup = [];
  public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaLegend = true;
  public RelationsNum: any;
  public CallsNum: any;
  public PublicationsNum: any;
  public Calls: Calls[];
  public errorMessage
  public Account: Account;
  public Relations: Relations[];
  public logger: Logger[];
  public Mailing = [];
  public mailstatsspinner = false;
  public GoogleanalyticsModel: Googleanalytics[];
  public Websitetracker: Websitetracker[];
  public WebsitetrackerFullList: Websitetracker[];
  public analytics_startdate: string;
  public analytics_enddate: string;
  public analytics_dimensions: string;
  public selectedanalytics: Googleanalytics;
  public options = [];
  public option: Relations;
  public mailStatsTimeSelected;
  public storedNumbers = [];
  public failedNumbers = [];
  public twitterselected;
  public Twitter: Twitter[];


  //  Doughnut
  public doughnutChartLabels: string[];
  public doughnutChartType: string = 'doughnut';
  public doughnutChartData: any[] = [];
  public barChart2Labels: string[];
  public barChart2Type: string = 'bar';
  public barChart2Legend: boolean = true;
  public barChart2Data: any[];
  public minDate = new Date(2017, 0, 1);
  public maxDate = Date.now();

  filteredOptionsRelations: Observable<string[]>;

  mailStatsTime = [
    { value: '12m', viewValue: 'Year/months' },
    { value: '365d', viewValue: 'Year/days' },
    { value: '30d', viewValue: 'Month' },
    { value: '7d', viewValue: 'Week' },
    { value: '1d', viewValue: 'Day' }
  ];

  // chart 1 Activities
  public chartOptions: ChartOptions = {
    //scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }
  public barChartLabels: string[] = ['Total Activities'];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#ff4400"
        },
        {
          "saturation": -100
        },
        {
          "lightness": -2
        },
        {
          "gamma": 0.72
        }
      ]
    }];

  public selectcolor = '';
  barChartManagerData: any[] = [];
  barChartManagerLabels: any[] = ['Relations', 'Calls'];
  public barChartDataChannel: any[] = [];
  public barChartDataChannelLabels: any[] = ['linkedin', 'twitter', 'instagram', 'facebook', 'pinterest'];
  public barChartData: any[] = [
    { data: [], labels: 'Publications' },
    { data: [], labels: 'Mailings' },
    { data: [], labels: 'Videos/Animations' },
    { data: [], labels: 'Images' }
  ];

  //  lineChart
  public lineChartData: Array<any> = [{ data: [], label: 'Website Visitors' }];
  public lineChartLabels: Array<any>;
  public lineChartOptions: any = { responsive: true };
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';

  // chart2 website analytics bar chart
  public barChart2Options: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }

  public barChartChannelLabels: string[] = ['Social Messages'];
  public Marketingplannerevents: Marketingplannerevents[];
  public changenow = false;
  public latitude = 52.374828;
  public longitude = 7.005030;
  public zoom = 2;
  public editmode = false;
  public stdDash = 0;
  public mapLabelName: string;
  public dashboardtype: string;
  busygettingtrackerlist: boolean = false;
  dashname: any;

  constructor(
    public dialogsService: DialogsService,
    public colorset: colorset,
    public googleMapService: GoogleMapService,
    public dialog: MatDialog,
    public loggerApi: LoggerApi,
    public TwitterApi: TwitterApi,
    public MarketingplannereventsApi: MarketingplannereventsApi,
    public WebsitetrackerApi: WebsitetrackerApi,
    public MailingApi: MailingApi,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi,
    public RelationsApi: RelationsApi,
    public CallsApi: CallsApi,
    public PublicationsApi: PublicationsApi,
    public GoogleanalyticsApi: GoogleanalyticsApi,
    public OpportunitiesApi: OpportunitiesApi,
    public router: Router,
    public snackBar: MatSnackBar
  ) {

  }

  public speedDialFabButtons = [
    {
      icon: 'web',
      tooltip: 'Add Website leads'
    },
    {
      icon: 'web',
      tooltip: 'Add new Website data'
    },
    {
      icon: 'show_chart',
      tooltip: 'Add google analytics'
    },
    {
      icon: 'mail',
      tooltip: 'Add mailing data'
    },
    {
      icon: 'contact_mail',
      tooltip: 'Add CRM data'
    },
    {
      icon: 'chat',
      tooltip: 'Add channel data'
    },
    {
      icon: 'toc',
      tooltip: 'Add follow up list'
    },
    {
      icon: 'perm_media',
      tooltip: 'Add publication data'
    },
    {
      icon: 'schedule',
      tooltip: 'Add scheduled events'
    },
    {
      icon: 'emoji_events',
      tooltip: 'Add Salespipe'
    },


  ];

  // {
  //   icon: 'refresh',
  //   tooltip: 'Reload'
  // }

  // {
  //   icon: 'save',
  //   tooltip: 'Save as new Dashboard'
  // },
  // {
  //   icon: 'save',
  //   tooltip: 'Save current Dashboard'
  // },
  // {
  //   icon: 'delete',
  //   tooltip: 'Delete current Dashboard'
  // },
  // {
  //   icon: 'done_outline',
  //   tooltip: 'Set as Standard Dashboard'
  // },


  onSpeedDialFabClicked(btn) {
    let id = this.dashboardsetup.length + '-dash-item';
    // console.log(btn.tooltip);
    if (btn.tooltip === 'Add Salespipe') { this.addNewSalesPipe(id) }
    if (btn.tooltip === 'Add new Website data') { this.addNewWebsiteData(id) }
    if (btn.tooltip === 'Add Website leads') { this.addNewWebsiteLeads(id) }
    if (btn.tooltip === 'Add google analytics') { this.addNewAnalyticsData(id) }
    if (btn.tooltip === 'Add mailing data') { this.addNewMailingData(id) }
    if (btn.tooltip === 'Add CRM data') { this.addNewCRMData(id) }
    if (btn.tooltip === 'Add channel data') { this.addNewChannelData(id) }
    if (btn.tooltip === 'Add follow up list') { this.addFollowUpData(id) }
    if (btn.tooltip === 'Add publication data') { this.addPublicationData(id) }
    if (btn.tooltip === 'Add scheduled events') { this.addScheduledData(id) }
    if (btn.tooltip === 'Save as new Dashboard') { this.saveDashboard() }
    if (btn.tooltip === 'Save current Dashboard') { this.saveCurrentDashboard() }
    if (btn.tooltip === 'Delete current Dashboard') { this.deleteDashboard() }
    if (btn.tooltip === 'Set as Standard Dashboard') { this.setStandardDashboard(this.stdDash) }
    if (btn.tooltip === 'Reload') { this.detectchange() }
  }

  moveSectionUp(i1): void {
    if (i1 !== 0) {
      const tmp = this.dashboardsetup[i1];
      this.dashboardsetup[i1] = this.dashboardsetup[i1 - 1];
      this.dashboardsetup[i1 - 1] = tmp;
    }
  }

  moveSectionDown(i1): void {
    if (i1 !== this.dashboardsetup.length - 1) {
      const tmp = this.dashboardsetup[i1];
      this.dashboardsetup[i1] = this.dashboardsetup[i1 + 1];
      this.dashboardsetup[i1 + 1] = tmp;
    }
  }

  editmodeChange() {
    if (this.editmode) { this.editmode = false; this.detectchange(); } else {
      this.editmode = true;
    }
  }

  addNewWebsiteData(id) {
    let newData = createDataObject('Website data', '30daysAgo', 'today', 'map', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewWebsiteLeads(id) {
    let newData = createDataObject('Website leads', '30daysAgo', 'today', 'table', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewAnalyticsData(id) {
    let newData = createDataObject('Google Analytics', '30daysAgo', 'today', 'line', 'date', id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewMailingData(id) {
    let newData = createDataObject('Mailing statistics', '30daysAgo', 'today', 'line', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewCRMData(id) {
    let newData = createDataObject('CRM statistics', '1-1-2018', new Date(), 'bar', 'relations-calls', id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewSalesPipe(id) {
    let newData = createDataObject('Salespipe', '1-1-2018', new Date(), 'bar', 'salespipe-status', id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addNewChannelData(id) {
    let newData = createDataObject('Channels', '30daysAgo', 'today', 'bar', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addFollowUpData(id) {
    let newData = createDataObject('Followups', '30daysAgo', 'today', 'table', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addPublicationData(id) {
    let newData = createDataObject('Publications', '30daysAgo', 'today', 'bar', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  addScheduledData(id) {
    let newData = createDataObject('Scheduled', '30daysAgo', 'today', 'table', undefined, id);
    this.dashboardsetup.push(newData);
    this.detectchange();
  }

  newDashboard() {
    this.dashboardsetup = [];
    this.detectchange();
  }

  deleteDashboard() {

    this.dialogsService
      .confirm('Delete Dashboard', 'Are you sure you want to do this?')
      .subscribe(res => {
        if (res) {

          if (!this.Account.stddashboard) { this.Account.stddashboard = 0 }
          this.dashboardsetup = [];
          if (this.dashboardtype === 'company') {
            this.Company.dashboards.splice(this.Account.stddashboard, 1)
            this.CompanyApi.patchAttributes(this.Company.id, this.Company).subscribe();
          }
          if (this.dashboardtype === 'account') {
            this.Account.dashboards.splice(this.Account.stddashboard, 1);
            this.AccountApi.patchAttributes(this.Account.id, this.Account).subscribe();

          }
        }
      });

    this.detectchange();
  }

  saveCurrentDashboard() {
    if (this.Account.stddashboard !== undefined) {
      let newDash = this.dashboardsetup;
      newDash.forEach((element) => {
        element.data_labels = [];
        element.colorscheme = [];
        element.data_object = [];
        element.data_labels = [];
        element.data_set = [];
      });

      if (this.dashboardtype === 'company') {
        this.Company.dashboards[this.Account.stddashboard].dashboard = newDash;
        console.log(this.Company)
        this.CompanyApi.patchAttributes(this.Company.id, this.Company).subscribe(() => {
          //this.AccountApi.updateByIdCompany(this.Account.id, this.Company.id, this.Company).subscribe(() => {
          this.detectchange()
          this.snackBar.open('Dashboard saved', null, {
            duration: 3000,
          });
        });
      } else {
        this.Account.dashboards[this.Account.stddashboard].dashboard = newDash;
        this.AccountApi.patchAttributes(this.Account.id, this.Account).subscribe(() => {
          this.detectchange()
          this.snackBar.open('Dashboard saved', null, {
            duration: 3000,
          });
        });
      }


    } else { this.saveDashboard(); }
  }

  saveDashboard() {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'Dashboard1' }
    });

    dialogRef.afterClosed().subscribe(result => {
      let name = result;
      if (name) {
        let newDash = this.dashboardsetup;

        newDash.forEach((element) => {
          element.data_labels = [];
          element.colorscheme = [];
          element.data_object = [];
          element.data_labels = [];
          element.data_set = [];
        });

        if (!this.dashboardsavetocompany) {
          if (this.Account.dashboards === undefined) { this.Account.dashboards = []; this.Account.stddashboard = 0 }
          this.Account.dashboards.push({ name: name, dashboard: newDash });
          this.AccountApi.patchAttributes(this.Account.id, this.Account).subscribe(() => {
            this.detectchange();
            this.snackBar.open('Dashboard saved', null, {
              duration: 3000,
            });
          });
        } else {
          if (this.Company.dashboards === undefined) { this.Company.dashboards = []; this.Account.stddashboard = 0 }
          this.Company.dashboards.push({ name: name, dashboard: newDash });
          console.log(this.Company)
          this.CompanyApi.updateAttributes(this.Account.companyId, this.Company).subscribe(() => {
            this.detectchange();
            this.snackBar.open('Dashboard saved', null, {
              duration: 3000,
            });
          });
        }
      }
    });
  }

  mouseOverMapMarker(e, i) {
    // console.log(e)
    this.dashboardsetup[i].currentlabel = e;
  }

  setStandardDashboard(nr) {
    console.log(nr, this.Account)
    this.Account.stddashboard = nr;
    this.Account.dashboardtype = this.dashboardtype;
    this.AccountApi.addStdDashboard(this.Account.id, nr, this.dashboardtype).subscribe(res => {
      console.log(res)
    }, error => { console.log });
  }

  loadDashboard(i, type) {
    console.log(i, type)
    this.stdDash = i.value;
    if (type === 'company') {
      this.dashboardsetup = this.Company.dashboards[i.value].dashboard;
      this.dashname = this.Company.dashboards[i.value];
      this.myControlDash.reset();
    }
    if (type === 'account') {
      this.dashboardsetup = this.Account.dashboards[i.value].dashboard;
      this.dashname = this.Account.dashboards[i.value];
      this.myControlDash.reset();
    }
    this.Account.dashboardtype = type;
    this.dashboardtype = type;
    this.detectchange();
  }

  detectchange() {
    this.changenow = false;

    //for (let dashitem of this.dashboardsetup) {
    this.dashboardsetup.forEach(async (dashitem) => {
      if (dashitem.dyndate) {
        await this.setDynDate(dashitem)
      }
      let dashitem1 = await this.buildDashboard(dashitem);
      let dashitem2 = await this.setColor(dashitem1);
      dashitem = dashitem2;
      // }
    });

    setTimeout(() => {
      //console.log('detect change', this.dashboardsetup)
      this.changenow = true
    }, 200);
  }

  setDynDate(dashitem: DataObject) {
    return new Promise(async (resolve, reject) => {
      if (dashitem.dyndate === "today") {
        dashitem.startdate = moment().toDate();
        dashitem.enddate = moment().toDate();
      }
      if (dashitem.dyndate === "yesterday") {
        dashitem.startdate = moment().add(-1, 'd').toDate();
        dashitem.enddate = moment().toDate();
      }
      if (dashitem.dyndate === "lastweek") {
        dashitem.startdate = moment().add(-7, 'd').toDate();
        dashitem.enddate = moment().toDate();
      }
      if (dashitem.dyndate === "30daysAgo") {
        dashitem.startdate = moment().add(-30, 'd').toDate();
        dashitem.enddate = moment().toDate();
      }
      if (dashitem.dyndate === "365daysAgo") {
        dashitem.startdate = moment().add(-1, 'y').toDate();
        dashitem.enddate = moment().toDate();
      }
      resolve(dashitem)

    });
  }

  ngOnInit(): void {
    // if (this.AccountApi.isAuthenticated() === false) { this.router.navigate(['login']) }
    // if (this.AccountApi.getCurrentToken() === undefined) { this.router.navigate(['login']) }
    this.setFilter();
    this.getCurrentUserInfo();

  }


  // compare filter search Relations
  setFilter(): void {
    this.filteredOptionsRelations = this.myControlRelations.valueChanges
      .pipe(
        startWith(''),
        startWith<string | Relations>(''),
        map(value => typeof value === 'string' ? value : value.relationname),
        map(relationname => relationname ? this.filterRelations(relationname) : this.options.slice())
      );
  }

  // filter and to lower case for search
  private filterRelations(relationname: string): Relations[] {
    const filterValue = relationname.toLowerCase();
    return this.options.filter(option => option.relationname.toLowerCase().indexOf(filterValue) === 0);
  }


  async setColor(dashitem) {
    return new Promise(async (resolve, reject) => {
      dashitem.colorscheme = [];
      let colorscheme;

      switch (dashitem.color) {
        case 'grey': {
          colorscheme = this.colorset.setColor('greycolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'blue': {
          colorscheme = this.colorset.setColor('bluecolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'red': {
          colorscheme = this.colorset.setColor('redcolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'green': {
          colorscheme = this.colorset.setColor('greencolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'pink': {
          colorscheme = this.colorset.setColor('pinkcolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'yellow': {
          colorscheme = this.colorset.setColor('yellowcolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'darkblue': {
          colorscheme = this.colorset.setColor('darkbluecolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'azure': {
          colorscheme = this.colorset.setColor('azurecolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'purple': {
          colorscheme = this.colorset.setColor('purplecolors');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        case 'auto': {
          colorscheme = 'auto';
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
          break
        }
        default: {
          colorscheme = 'auto'
          console.error('Color not found');
          let dashitem1 = this.setColorScheme(dashitem, colorscheme);
          resolve(dashitem1);
        }
      }
    });
  }

  async setColorScheme(dashitem: DataObject, colorscheme) {
    return new Promise(async (resolve, reject) => {
      let count = dashitem.data_set.length
      let colorarray = ['greycolors', 'bluecolors', 'redcolors', 'greencolors', 'pinkcolors',
        'yellowcolors', 'azurecolors', 'purplecolors', 'darkbluecolors'];

      for (let i = 0; i < count; i++) {
        if (colorscheme === 'auto') {
          let randomnum = i;
          if (i > 8) {
            randomnum = this.RandomInt(0, 5);
          }

          let newcolorscheme = this.colorset.setColor(colorarray[randomnum]);
          dashitem.colorscheme.push(newcolorscheme);
        } else {
          dashitem.colorscheme.push(colorscheme);
        }
      }
      resolve(dashitem);
    });
  }

  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    let newset = Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    return newset;
  }

  //  get currentuserinfo for api
  getCurrentUserInfo(): void {
    this.AccountApi.getCurrent().subscribe((account: Account) => {
      this.Account = account;
      this.getAnalyticsAccounts(); // see below

      this.CompanyApi.findById(this.Account.companyId, {
        include: {
          relation: 'relations',
          scope: {
            where: { marketingrelation: true },
            include: {
              relation: 'googleanalytics',
              where: { id: this.Account.standardGa }
            }
          }
        }
      }).subscribe((company: Company) => {
        this.Company = company;
        this.Relations = company.relations;

        let rel = this.Relations.filter((relationFil, index, self) =>
          index === self.findIndex((t) => (
            t.id === relationFil.id
          )));
        this.option = rel[0];
        // console.log(this.option);
        this.myControlRelations.reset();
        if (this.option && this.option.googleanalytics.length > 0) {
          if (this.option.googleanalytics[0].gaclientid) {
            this.selectedanalytics = this.option.googleanalytics[0];
            this.analytics_ids = this.option.googleanalytics[0].gaclientid;
          }

        }
        this.checkstandardDashboard();
      });
    })
  }

  checkstandardDashboard() {
    if (this.Account.stddashboard !== undefined) {
      this.dashboardtype = this.Account.dashboardtype;
      if (this.dashboardtype === "company") {
        if (this.Account.stddashboard !== undefined) { //&& this.Account.stddashboard >= this.Company.dashboards.length - 1
          if (this.Company.dashboards.length > this.Account.stddashboard) {
            this.dashboardsetup = this.Company.dashboards[this.Account.stddashboard].dashboard;
            this.dashname = this.Company.dashboards[this.Account.stddashboard];
            this.myControlDash.reset();
          }

          this.detectchange();
        }
      } else {
        if (this.Account.stddashboard !== undefined) { //&& this.Account.stddashboard >= this.Account.dashboards.length - 1
          this.dashboardsetup = this.Account.dashboards[this.Account.stddashboard].dashboard;
          this.dashname = this.Account.dashboards[this.Account.stddashboard];
          this.myControlDash.reset();
          this.detectchange();
        }
      }
    } else { this.changenow = true }
  }

  checkstandardGa() {
    if (this.Account.standardGa) {
      this.GoogleanalyticsApi.findById(this.Account.standardGa)
        .subscribe((googleanalytics: Googleanalytics) => {
          this.selectedanalytics = googleanalytics;
        })
    }
    if (!this.Account.standardGa) {
      this.selectedanalytics = undefined;
    }
  }

  getAddress(address, graph) {
    console.log(address.label);
    this.googleMapService.getLatLan(address.label)
      .subscribe((result) => {
        console.log(result)
        address.lat = result.lat();
        address.lng = result.lng();
      });
  }

  async getCityAddress(city): Promise<cityresult> {
    //console.log(city)
    let result: cityresult = { lat: undefined, lng: undefined };

    try {
      let resp = await this.googleMapService.getLatLan(
        city
      ).toPromise();

      result.lat = resp.lat();
      result.lng = resp.lng();

      return result
    } catch (error) {
      console.log(error)
    }



    // .subscribe(
    //     resp => {
    //       result.lat = resp.lat();
    //       result.lng = resp.lng();
    //       return result
    //     },
    //     error => {
    //       console.log(error)
    //         // this.lat = 0,
    //         // this.lng = 0;
    //         return result
    //     },
    //   );
  }

  // this.selectedanalytics.id, this.analytics_ids2, this.analytics_startdate2 this.analytics_enddate2, this.analytics_dimensions2, this.analytics_metrics2
  getAnalyticsData(startdate, enddate, dimension, metrics) {
    return new Promise(async (resolve, reject) => {

      let filters = undefined;
      //  let filters: [{
      //   dimension_name: filters,
      //   operator: "EXACT",
      //   expressions: ["Firefox"] }];

      let startdateset = startdate;
      let enddateset = enddate;
      // console.log(metrics)

      if (startdateset === '30daysAgo' || startdateset === '365daysAgo') [
        enddateset = 'today'
      ]

      if (startdate instanceof Date) {
        startdateset = moment(startdate).utc().format('YYYY-MM-DD');
      }
      if (enddate instanceof Date) {
        enddateset = moment(enddate).utc().format('YYYY-MM-DD');
      }
      //console.log(startdateset, enddateset);
      this.GoogleanalyticsApi.getanalyticsreport(this.selectedanalytics.id, this.selectedanalytics.gaclientid, startdateset,
        enddateset, dimension, metrics)
        .subscribe(data => {
          resolve(data);
        }, error => console.error(error));
    });
  }

  async setChartDateData(resdata, graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      // console.log(resdata);
      const newset: Chart = { data: [], label: '' };
      graph.data_labels = [];
      let rows = resdata.rows;
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i]
        const dateset = item.dimensionValues[0].value;
        const txt2 = dateset.slice(0, 4) + '-' + dateset.slice(4, 12);
        const txt3 = txt2.slice(0, 7) + '-' + txt2.slice(7, 13);
        let date = new Date(txt3);
        let datestring = date.toDateString();
        graph.data_labels.push(datestring);
        newset.data.push(item.metricValues[0].value)
        graph.data_object.push({ Date: datestring, Visitors: item.metricValues[0].value })
      }

      graph.data_set = [newset]
      // console.log(graph)
      resolve(graph);
    });
  }


  async setMapAnalytics(resdata, graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      graph.data_set = [];
      graph.data_labels = [];
      graph.data_object = [];
      let rows = resdata.rows;
      //console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        let nr = parseInt(item.metricValues[0].value, 10);
        if (item.dimensionValues[0].value !== '(not set)') {
          let latlon = await this.getCityAddress(item.dimensionValues[0].value)
          const newset: any = { data: [nr], city: item.dimensionValues[0].value, lat: latlon.lat, lon: latlon.lng }
          graph.data_object.push(newset)
          graph.data_set.push(newset);
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      };
      resolve(graph);
    });
  }

  async setChartAnalytics(resdata, graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      graph.data_set = [];
      graph.data_labels = [];
      graph.data_object = [];
      let rows = resdata.rows;
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        let nr = parseInt(item.metricValues[0].value, 10);
        const newset: Chart = { data: [nr], label: item.dimensionValues[0].value }
        graph.data_object.push({ Medium: item.dimensionValues[0].value, Visitors: nr })
        graph.data_set.push(newset)
      };
      console.log(graph)

      resolve(graph);
    });
  }

  async setChartType(resdata, graph: DataObject, label) {
    return new Promise(async (resolve, reject) => {
      if (resdata.length > 0) {
        graph.data_set = [];
        graph.data_labels = [];
        graph.data_object = [];
        let labels = Object.keys(resdata[0]);
        const newset: Chart = { data: [resdata.length], label: label }
        graph.data_labels = label;
        graph.data_set.push(newset);
        graph.data_object = resdata;
        resolve(graph);
      } else {
        resolve(graph)
      }
    });
  }


  async buildDashboard(dashitem) {
    return new Promise(async (resolve, reject) => {
      dashitem.data_set = [];
      dashitem.data_labels = [];
      dashitem.data_object = [];

      switch (dashitem.type) {
        case 'Google Analytics': {
          if (this.selectedanalytics) {

            if (!dashitem.metric) { dashitem.metric = 'activeUsers' }
            let metric = [{ name: dashitem.metric }]

            // set correct dimension format, needed here as dimnsion let can contain more then one
            let dimensions = [];

            if (dashitem.charttype === 'map') {
              dimensions = [{ name: 'city' }] // always location country shows same data need setup a svg overlay for maps
            } else {
              dimensions = [{ name: dashitem.dimension }]
            }

            let resdata: any = await this.getAnalyticsData(dashitem.startdate, dashitem.enddate, dimensions, metric);
            let dashitem1;

            if (dashitem.charttype === 'map') {
              dashitem1 = await this.setMapAnalytics(resdata, dashitem);
            } else if (dashitem.dimension === 'date' && dashitem.charttype !== 'map') {
              dashitem1 = await this.setChartDateData(resdata, dashitem);
            }
            // else if (dashitem.charttype === 'table') {
            //   dashitem1 = await this.setChartDateData(resdata, dashitem);
            //   console.log(dashitem1)
            // }
            else {
              dashitem1 = await this.setChartAnalytics(resdata, dashitem);
            }
            resolve(dashitem1);
          }
          break
        }

        case 'Followups': {
          let resdata = await this.getFollowups();
          let dashitem1 = await this.setChartType(resdata, dashitem, ['Follow up']);
          //console.log(dashitem1)
          resolve(dashitem1);
          break
        }
        case 'Mailing statistics': {
          let dashitem1 = await this.getMailStats(dashitem);
          resolve(dashitem1);
          break
        }
        case 'Publications': {
          let dashitem1: any = await this.countPublications(dashitem);
          resolve(dashitem1);
          break
        }
        case 'Salespipe': {
          let dashitem1;
          let resdata;
          let labels = ['Salespipe'];
          if (dashitem.dimension === 'salespipe-status') {
            resdata = await this.salespipeByStatus(dashitem);
            dashitem1 = await this.setChartType(resdata, dashitem, labels);
            dashitem1.data_set = resdata;
            resolve(dashitem1);
          }
          if (dashitem.dimension === 'salespipe-type') {
            resdata = await this.salespipeByType(dashitem);
            dashitem1 = await this.setChartType(resdata, dashitem, labels);
            dashitem1.data_set = resdata;
            resolve(dashitem1);
          }
          if (dashitem.dimension === 'salespipe-successrate') {
            resdata = await this.salespipeBySuccessrate(dashitem);
            dashitem1 = await this.setChartType(resdata, dashitem, labels);
            dashitem1.data_set = resdata;
            resolve(dashitem1);
          }
        }
        case 'CRM statistics': {
          //console.log('CRM')
          let resdata;
          let labels = ['CRM data'];
          let dashitem1;
          if (dashitem.dimension === 'calls-date') {
            resdata = await this.countManagerData(dashitem);
            dashitem1 = await this.setChartType(resdata, dashitem, labels);
            dashitem1.data_set = resdata;
          }
          if (dashitem.dimension === 'relations-status') {
            resdata = await this.relationsByStatus(dashitem);
            dashitem1 = await this.setChartType(resdata, dashitem, labels);
            dashitem1.data_set = resdata;
          }
          if (dashitem.dimension === 'relations-calls') {
            resdata = await this.callsByDate(dashitem);
            dashitem1 = resdata;
          }
          resolve(dashitem1);
          break
        }
        case 'Channels': {
          let dashitem1 = await this.countChannels(dashitem);
          resolve(dashitem1);
          break
        }
        case 'Website data': {
          let resdata = await this.getWebsiteTracker();
          let dashitem1: any = await this.setChartType(resdata, dashitem, ['Website Visitors']);
          dashitem.data_object = resdata;
          resolve(dashitem1);
          break
        }

        case 'Website leads': {
          let resdata = await this.getWebsiteLeadsTracker();
          let dashitem1: any = await this.setChartType(resdata, dashitem, ['Website Visitors']);
          dashitem.data_object = resdata;
          resolve(dashitem1);
          break
        }

        case 'Scheduled': {
          let resdata = await this.getAdsMailing(dashitem);
          let dashitem1: any = await this.setChartType(resdata, dashitem, ['marketingplannereventsIds', 'subject', 'from', 'title', 'date']);
          resolve(dashitem1);
          break
        }

        default: {
          console.error('no chart type or incorrect value');
          resolve(dashitem);
        }
      }
    });
  }

  getTwitterAccount(): void {
    this.RelationsApi.getTwitter(this.Account.standardrelation)
      .subscribe((Twitter: Twitter[]) => {
        if (Twitter.length > 0) {
          this.Twitter = Twitter;
          if (Twitter[0].screenname === undefined) {
            this.TwitterApi.verifycredentials(this.Twitter[0].AccessToken, this.Twitter[0].AccessTokenSecret)
              .subscribe(res => {
                res = JSON.parse(res);
                this.twitterselected = { sourceType: 'url', url: 'https://twitter.com/' + res.screen_name };
                console.log(this.twitterselected, res);
                this.Twitter[0].screenname = res.screen_name;
                this.RelationsApi.updateByIdTwitter(this.Account.standardrelation, this.Twitter[0].id, this.Twitter[0]).subscribe();
              });
          } else { this.twitterselected = { sourceType: 'url', url: 'https://twitter.com/' + this.Twitter[0].screenname }; }
        }
      });
  }

  //  select relation --> get info for all tabs
  onSelectRelation(option, i): void {
    this.AccountApi.addStdRelation(this.Account.id, option.id).subscribe();
    //this.option = option;
    this.detectchange();
  }

  getLogs(): void {
    this.AccountApi.getLogger(this.Account.companyId,
      { order: 'id DESC' }).subscribe((logger: Logger[]) => {
        this.logger = logger;
      })
  }

  deleteLog(i): void {
    this.CompanyApi.destroyByIdLogger(this.Account.companyId, this.logger[i].id)
      .subscribe(res => { this.getLogs(); });
  }

  getAdsMailing(graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      //  get the planned mailings looks shitty because the mailings of are
      //  part of the marketingplannerevents
      //  and are not directly related to the Relation.id itself
      //  used include to get the related mailings and then run foreach on the events and a foreach for all the mailings
      let Mailing = [];
      this.RelationsApi.getMarketingplannerevents(this.Account.standardrelation,
        {
          where: { scheduled: true },
          //where: { scheduled: true, mailing: { "neq": null }},

          include: {
            relation: 'mailing',
            scope:
            {
              where: { and: [{ send: false }, { scheduled: true }, { done: false }] },
              // where: {send: false}
              // fields: {
              //   title: true, to: true, from: true, subject: true, date: true, id: true
              // }
            },
          },
          order: 'date ASC',
          // fields: {
          //title: true, to: true, from: true, subject: true, date: true, id: true
          //}
        })
        .subscribe((Marketingplannerevents: Marketingplannerevents[]) => {
          Marketingplannerevents.forEach((item) => {
            const mailingsub = item.mailing;
            mailingsub.forEach((itemMailing) => {
              itemMailing.marketingplannereventsIds = item.name;
              Mailing.push(itemMailing)
            })
          })
          //  objects are being sorted
          Mailing = Mailing.sort((n1, n2) => {
            return this.naturalCompare(n1.date, n2.date)
          });
          resolve(Mailing);
        });
    });
  }

  //  don't try to understand this method, just use it as it is and you'll get the result
  naturalCompare(a, b) {
    const ax = [], bx = [];
    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || '']) });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || '']) });

    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
      if (nn) { return nn };
    }
    return ax.length - bx.length;
  }

  async getWebsiteTracker() {
    return new Promise(async (resolve, reject) => {
      // this.Websitetracker = [];
      //  limit is total entries, order is is important as first entry is in array ES6 move to server.

      let filter: LoopBackFilter = {
        where: { and: [{ 'blacklisted': false }, { 'hosting': false }] },
        order: 'date DESC',
        limit: 1000,
      }

      this.RelationsApi.getWebsitetracker(this.Account.standardrelation, filter)
        .subscribe((websitetracker: Websitetracker[]) => {
          websitetracker = websitetracker.filter((WebsitetrackerFil, index, self) =>
            index === self.findIndex((t) => (
              t.TRid === WebsitetrackerFil.TRid
            )));
          resolve(websitetracker)
        });
    });
  }

  async getWebsiteLeadsTracker() {
    return new Promise(async (resolve, reject) => {
      //this.Websitetracker = [];
      //  limit is total entries, order is is important as first entry is in array ES6 move to server.

      //{ "currencyCode": { "neq":  null } }

      let filter: LoopBackFilter = {
        where: { and: [{ 'blacklisted': false }, { 'hosting': false }, { 'isp': false }, { 'proxy': false }, 
        { "companyname": { "neq":  "" }},
        { "companyname": { "neq":  null }}] },
        order: 'date DESC',
        limit: 1000,
      }

      this.RelationsApi.getWebsitetracker(this.Account.standardrelation, filter)
        .subscribe(async (websitetracker: Websitetracker[]) => {
          //console.log(websitetracker)
          let finalwebsitetracker = await websitetracker.filter((WebsitetrackerFil, index, self) =>
            index === self.findIndex((t) => (
              t.TRid === WebsitetrackerFil.TRid
            ))
          );
          // find empty values in the trackers
          for (let i = 0; i < finalwebsitetracker.length; i++) {
            let web = finalwebsitetracker[i];
            //console.log(web)
            if (web.city === undefined || web.city === "") {
              // reversed to the last entry show first
              for (let y = websitetracker.length - 1; y > 0; y--) {
                if (websitetracker[y].city !== undefined && websitetracker[y].city.length > 0) {
                  //console.log(web.city)
                  web.city = websitetracker[y].city;
                  web.country = websitetracker[y].country;
                  web.region = websitetracker[y].region;
                  web.street = websitetracker[y].street;
                }
              }
            }
          }
          resolve(finalwebsitetracker)
        });
    });
  }




  checkCompanyLead(Websitetracker: Websitetracker) {

    const dialogRef = this.dialog.open(CheckRelationDialogComponent, {
      width: '900px',
      data: {
        Websitetracker: Websitetracker,
        Account: this.Account
      }
    });

    dialogRef.afterClosed().subscribe((Websitetracker: Websitetracker) => {

      if (Websitetracker) {
        let relation: Relations = new Relations();
        relation.relationname = Websitetracker.companyname;
        relation.companyId = this.Account.companyId;
        relation.ownerId = this.Account.id;
        relation.address1 = Websitetracker.street;
        relation.city = Websitetracker.city;
        relation.ip = [Websitetracker.ip];
        relation.zipcode = Websitetracker.postal;
        relation.address2 = Websitetracker.region;
        relation.website = Websitetracker.website;

        if (Websitetracker.imgurl) {
          relation.companyimageurl = Websitetracker.imgurl;
        }
        if (Websitetracker.description) {
          relation.description = Websitetracker.description;
        }


        this.CompanyApi.createRelations(this.Account.companyId,
          relation).subscribe(res => {
            this.snackBar.open('New Relation Created')
          });
      }
    });
  }

  remove_tags(html) {
    html = html.replace(/<br>/g, "$br$");
    html = html.replace(/(?:\r\n|\r|\n)/g, '$br$');
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    html = tmp.textContent || tmp.innerText;
    html = html.replace(/\$br\$/g, "<br>");
    return html;
  }

  getFollowups() {
    return new Promise(async (resolve, reject) => {
      this.CompanyApi.getCalls(
        this.Account.companyId,
        {
          where: { and: [{ followup: true }, { followupdone: false }] },
          include: {
            relation: 'relations',
            scope: { fields: 'relationname' }
          },
          order: 'callbackdate ASC',
          fields: {
            content: false, notes: false,
            tasks: false, companyId: false, accountId: false
          }
        })
        .subscribe(async (Calls: Calls[]) => {
          for (let i = 0; i < Calls.length; i++) {
            let call = Calls[i];
            if (call.html) {
              call.html = await this.remove_tags(call.html);
            }
          }
          resolve(Calls)
        });
    });
  }

  changeFollowUp(i): void {
    this.CompanyApi.updateByIdCalls(this.Account.companyId, this.Calls[i].id, { followupdone: true })
      .subscribe();
  }

  public getAnalyticsAccounts(): void {
    this.RelationsApi.getGoogleanalytics(this.Account.standardrelation)
      .subscribe((GoogleanalyticsModel: Googleanalytics[]) => {
        this.GoogleanalyticsModel = GoogleanalyticsModel;
        // if (this.GoogleanalyticsModel.length === 1){
        //   this.analytics_ids = this.GoogleanalyticsModel[0];
        // }
      });
  }

  public countChannels(graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      let barChartDataChannel = [];

      for (let i = 0; i < this.barChartDataChannelLabels.length; i++) {
        const typeCh = this.barChartDataChannelLabels[i];
        const res: any = await this.getChannel(typeCh);
        barChartDataChannel.push({ data: [res.count], label: typeCh });
      }
      graph.data_set = barChartDataChannel;
      graph.data_object = barChartDataChannel;
      resolve(graph);
    });
  }

  public async getChannel(typeCh) {
    return new Promise(async (resolve, reject) => {
      this.RelationsApi.countChannels(this.Account.standardrelation, { 'type': typeCh }).subscribe(res => {
        resolve(res);
      });
    });
  };

  public async countManagerData(graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      this.CompanyApi.countRelations(this.Account.companyId, { status: { neq: 'Owner' } }).subscribe(res => {
        TotalNumber.push({ data: [res.count], label: 'Relations' }),
          this.CompanyApi.countCalls(this.Account.companyId).subscribe(res => {
            TotalNumber.push({ data: [res.count], label: 'Calls' }),
              resolve(TotalNumber);
          });
      });
    });
  }

  salespipeByStatus(dashitem) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      this.Company.Opportunitystatus.forEach(element => {
        this.OpportunitiesApi.count({ status: element, companyId: this.Account.companyId, removed: false }).subscribe(res => {
          TotalNumber.push({ data: [res.count], label: element });
        })
      });
      resolve(TotalNumber);
    });
  }

  salespipeByType(dashitem) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      this.Company.Opportunitytypes.forEach(element => {
        this.OpportunitiesApi.count({ status: element, companyId: this.Account.companyId, removed: false }).subscribe(res => {
          TotalNumber.push({ data: [res.count], label: element });
        })
      });
      resolve(TotalNumber);
    });
  }


  salespipeBySuccessrate(dashitem) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      this.OpportunitiesApi.find({ where: { removed: false } })
        .subscribe((opportunities: Opportunities[]) => {
          let successrate = opportunities.map(item => item.successrate).filter((value, index, self) => self.indexOf(value) === index);
          successrate.forEach((elvalue: number) => {
            const successcount = opportunities.filter((obj) => obj.successrate === elvalue).length;
            TotalNumber.push({ data: [successcount], label: elvalue });
          });
        })
      resolve(TotalNumber);
    });
  }

  relationsByStatus(dashitem) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      this.CompanyApi.getRelations(this.Account.companyId,
        {
          where: { status: { neq: 'Owner' } },
          fields: { status: true, type: true, id: true }
        }
      ).subscribe((relations: Relations[]) => {
        let status = relations.map(item => item.status).filter((value, index, self) => self.indexOf(value) === index);
        let type = relations.map(item => item.type).filter((value, index, self) => self.indexOf(value) === index);
        status.forEach(elvalue => {
          const statuscount = relations.filter((obj) => obj.status === elvalue).length;
          TotalNumber.push({ data: [statuscount], label: elvalue });
        });
        type.forEach(elvalue => {
          const typecount = relations.filter((obj) => obj.type === elvalue).length;
          TotalNumber.push({ data: [typecount], label: elvalue });
        });
        resolve(TotalNumber);
      });
    });
  }

  async callsByDate(graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      let a = moment(graph.startdate).toISOString();
      let b = moment(graph.enddate).toISOString();
      const newset: Chart = { data: [], label: '' };
      //console.log(a, b)
      this.CompanyApi.getRelations(this.Account.companyId,
        {
          fields: { status: true, type: true, id: true },
          include: {
            relation: 'calls',
            scope: {
              fields: { date: true, id: true },
              where: {
                date:
                  { between: [a, b] }
              }
              // where: {
              //   date_field: {
              //     gte: a,
              //     lt: b
              //   }
              // }
            }
          }
        }
      ).subscribe((relations: Relations[]) => {
        // console.log(relations);
        let callsdatesset = [];
        relations.forEach(rel => {
          callsdatesset.push(rel.calls)
        })

        let callsdates = [].concat.apply([], callsdatesset);
        //console.log(callsdates);
        // If you want an exclusive end date (half-open interval)
        for (let m = moment(a); m.isBefore(b); m.add(1, 'days')) {
          let date = m.format('LL');
          let callscount = 0;
          for (let i = 0; i < callsdates.length; i++) {
            let date2 = moment(callsdates[i].date).format('LL');
            if (date === date2) {
              ++callscount;
            }
          }
          //const callscount = callsdates.filter((obj) => {moment(obj.date).format('LL') === m.format('LL')}).length;
          graph.data_labels.push(date);
          newset.data.push(callscount)
          graph.data_object.push({ Date: date, Calls: callscount });
        }
        graph.data_set = [newset]
        resolve(graph);

      });
    });
  }

  public async countPublications(graph: DataObject) {
    return new Promise(async (resolve, reject) => {
      let TotalNumber = [];
      // use count include?? open issue for loopback --> create hook instead to package as one call or move to automation
      this.CompanyApi.countPublications(this.Account.companyId).subscribe(res => {
        TotalNumber.push({ data: [res.count], label: 'Publications' }),
          this.RelationsApi.countMarketingplannerevents(this.Account.standardrelation).subscribe(res => {
            TotalNumber.push({ data: [res.count], label: 'Mailings' }),
              this.RelationsApi.countFiles(this.Account.standardrelation, { 'type': 'video' }).subscribe(res => {
                TotalNumber.push({ data: [res.count], label: 'Videos' }),
                  this.RelationsApi.countFiles(this.Account.standardrelation, { 'type': 'image' }).subscribe(res => {
                    TotalNumber.push({ data: [res.count], label: 'Images' });
                    graph.data_set = TotalNumber;
                    graph.data_object = TotalNumber;
                    resolve(graph);
                  })
              })
          }, error => console.log('Could not load Marketing', error));
      });
    });

  }


  async getMailStats(graph1, domain?) {
    return new Promise(async (resolve, reject) => {
      console.log(graph1)
      let SDomain;
      if (domain) {
        SDomain = domain
        //console.log(domain)
      } else {
        SDomain = this.option.domain
      }
      //this.mailstatsspinner = true;
      //  set d/m/h
    
      // if (this.mailStatsTimeSelected == undefined) {
      //   data = '7d';
      // } else { data = this.mailStatsTimeSelected.value }
      if (graph1.startdate === '30daysAgo'){
        graph1.startdate = '30d'; 
      }

      let data = graph1.startdate;

      this.MailingApi.getstats(this.Account.companyId, SDomain, data).subscribe(res => {
        const mailingstats = res.res;
        //console.log('mailingstats:', mailingstats)
        let dataset = [];
        let delivered = { data: [], label: 'Delivered' };
        let opened = { data: [], label: 'Opened' };
        let clicked = { data: [], label: 'Clicked' };
        let unsubscribed = { data: [], label: 'Unsubscribed' };
        let complained = { data: [], label: 'Complained' };
        let stored = { data: [], label: 'Stored' };
        let failed = { data: [], label: 'Failed' };
        let labelset = [];
        // set accepted mails
        for (let i = 0; i < mailingstats.stats.length; i++) {
          const element = mailingstats.stats[i];
          dataset.push(element.accepted.outgoing);
          const time = element.time.slice(0, 16)
          labelset.push(time);
          delivered.data.push(element.delivered.total); // smtp/html
          opened.data.push(element.opened.total);
          clicked.data.push(element.clicked.total);
          unsubscribed.data.push(element.unsubscribed.total);
          complained.data.push(element.complained.total);
          stored.data.push(element.stored.total);
          failed.data.push(element.failed.permanent.total);

        };
        let graph = graph1;
        graph.data_set = [];
        graph.data_set.push(delivered);
        graph.data_set.push(opened);
        graph.data_set.push(clicked);
        graph.data_set.push(unsubscribed);
        graph.data_set.push(complained);
        graph.data_set.push(stored);
        graph.data_set.push(failed);
        graph.data_labels = labelset;

        graph.data_object = [
          { data: [delivered.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Delivered' },
          { data: [opened.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Opened' },
          { data: [clicked.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Clicked' },
          { data: [unsubscribed.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Unsubscribed' },
          { data: [complained.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Complained' },
          { data: [stored.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Stored' },
          { data: [failed.data.reduce(function (a, b) { return a + b; }, 0)], label: 'Failed' }
        ];
        resolve(graph)
      });
    });
  }

  deleteDashItem(i) {
    this.dashboardsetup.splice(i, 1)
  }

  searchGoQuick(value): void {
    let searchterm = value.trim();
    this.CompanyApi.getRelations(this.Account.companyId,
      {
        where:
        {
          or: [{ "relationname": { "regexp": searchterm + '/i' } },
          { "address1": { "regexp": searchterm + '/i' } },
          { "city": { "regexp": searchterm + '/i' } }
          ],
          marketingrelation: true
        },
        order: 'relationname ASC'
      })
      .subscribe((Relations: Relations[]) => {
        this.filteredRelations = Relations;
        //console.log(this.filteredRelations)
      });
  }

  //display name in searchbox
  displayFnRelation(relation?: Relations): string | undefined {
    return relation ? relation.relationname : undefined;
  }


  //  events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  openFollowUp(item) {
    this.router.navigate(['/relation'], { queryParams: { itemid: item.id, relation: item.relationsId, tab: 'calls' } })
  }


}