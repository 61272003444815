<div id="canvasthreed"></div>

<div *ngIf="selectedelement && selectedelement.type === 'threed'"
    style="position: fixed; top: 2px; left: 2px; z-index: 10000;">
    <mat-button-toggle (click)="checkDragCamera('drag')" [checked]="globalThreedControls.dragcontrol"
        matTooltip="Drag object">
        <mat-icon>swipe_up</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('draggroup')" [checked]="globalThreedControls.dragcontrolgroup"
        matTooltip="Drag object group">
        <mat-icon>swipe</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('rotatex')" [checked]="globalThreedControls.rotatecontrolx"
        matTooltip="Rotate Control horizontal">
        <mat-icon>360</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('rotatey')" [checked]="globalThreedControls.rotatecontroly"
        matTooltip="Rotate Control Vertical">
        <mat-icon style="transform: rotate(90deg)">360</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('rotatez')" [checked]="globalThreedControls.rotatecontrolz"
        matTooltip="Rotate Control around Axes">
        <mat-icon>flip_camera_android</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('camera')" [checked]="canvas.cameracontrol" matTooltip="Camera Control">
        <mat-icon>cameraswitch</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle (click)="checkDragCamera('walkcontrol')" [checked]="canvas.walkcontrol"
        matTooltip="Move Camera Control">
        <mat-icon>cameraswitch</mat-icon>
    </mat-button-toggle>
    <button mat-raised-button style="margin-left: 10px;" (click)="fixCameraZX()" matTooltip="Camera from top">
        <mat-icon>zoom_out_map</mat-icon>
    </button>
    <button mat-raised-button (click)="fixCameraY()" matTooltip="Camera from side Y">
        <mat-icon>fullscreen</mat-icon>
    </button>
    <button mat-raised-button (click)="fixCameraX()" matTooltip="Camera from side X">
        <mat-icon>fullscreen</mat-icon>
    </button>
    <button mat-raised-button (click)="zoomIn()" matTooltip="Zoom in">
        <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-raised-button (click)="zoomOut()" matTooltip="Zoom out">
        <mat-icon>zoom_out</mat-icon>
    </button>

</div>

<div *ngIf="canvas.walkcontrol" style="position: fixed; bottom: 0; left: 5; opacity: 0.5; background-color: white;">
    <label style="font:x-small; color: black">up: Q down: E front: W/Up-arrow back: S/Back-arrow left: A/Left-arrow,
        right: D/Right-arrow</label>
    <!-- <button mat-icon-button><mat-icon>radio_button_checked</mat-icon>record</button> -->
</div>

<div *ngIf="selectedthreedpart" style="position: fixed; bottom: 0; left: 0; z-index: 1000; overflow: visible;">
    <mat-card fxLayoutGap="20px" fxLayout="row">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Color
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- cpDialogDisplay="inline" -->
                <div fxLayout="column">
                    <input i18n-placeholder placeholder="Background color" [(ngModel)]="selectedcolor" [cpOutputFormat]="hex"
                    [cpAlphaChannel]="disabled"
                        style="width: 60px; height: 30px; z-index: 10" name="bgcolor" [style.background]="selectedcolor"
                        [cpToggle]="true" [(colorPicker)]="selectedcolor" cpDialogDisplay="inline" [cpPosition]="'auto'"
                        (colorPickerChange)="onchangethreedcolorpart(selectedthreedpart)" [cpCancelButton]="false"
                        [cpPresetLabel]="'Company colors'">
                    <button mat-raised-button (click)="selectedcolor = ''">Clear</button>
   
                </div>
                <!-- *ngIf="company" [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]" -->
            </mat-expansion-panel>
        </mat-accordion>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                Texture file
            </mat-expansion-panel-header>
            <label *ngIf="selectedthreedpartElement.texture">{{ selectedthreedpartElement.texture | fileName }}</label>
            <button mat-raised-button (click)="selectedthreedpartElement.texture = ''">Delete Texture</button>
            <div *ngIf="!selectedtexture">
                <app-fileupload *ngIf="!selectedtexture" [account]="account" [option]="option"
                    (imgurl)="setImage(selectedthreedpart, $event)">
                </app-fileupload>
            </div>
        </mat-expansion-panel>

        <mat-checkbox [(ngModel)]="selectedthreedpartElement.illuminated">Illuminated</mat-checkbox>

        <mat-checkbox [(ngModel)]="selectedthreedpartElement.visible">Visible</mat-checkbox>

    </mat-card>
</div>