import { fonts }  from './listsgeneral/fonts';
import { BASE_URL } from './base.api';



let stringcss = BASE_URL + 'assets/fonts/fonts.css';

// fonts.forEach(font => {
//     if (font.src !== '')
//     stringcss.push(font.src);
// })

export const ckconfig = {
    extraPlugins: 'emoji,font,spacingsliders,colorbutton,colordialog,justify',
    colorButton_enableMore: 'true',
    toolbarCanCollapse: 'true',
    font_names: 'Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, '+
    'cursive;Courier New/Courier New, Courier, monospace;Georgia/Georgia, '+
    'serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, '+
    'sans-serif;Tahoma/Tahoma, Geneva, sans-serif;Times New Roman/Times New Roman, '+
    'Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, '+
    'Geneva, sans-serif;Roboto/Roboto;Baloo Bhai/Baloo Bhai;Open Sans/Open Sans,' +
    'Shipwreck/Shipwreck;SandBrush/SandBrush;WesternOld/WesternOld;IndustrialOld/IndustrialOld;' +
    'Hillpark/Hillpark;Northead/Northead;Woodpecker/Woodpecker;',
    contentsCss: ['assets/fonts/fonts.css', 'https://fonts.googleapis.com/css?family=Baloo+Bhai&display=swap', 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap'],
    //contentsCss: stringcss,
    toolbarStartupExpanded: 'false'};