<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">

    <mat-button-toggle matTooltip="Open stored" style="width: 50%" value="stored" [checked]="true">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Templates" style="width: 50%" value="templates">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>


  <div *ngIf="group.value == 'templates'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let template of templates; let i = index" fxFlex (click)="selectedimage(template)">
      <div fxLayout="column" fxLayoutGap="5px" class="imagebox" fxFlex="90%">
        <img src="{{ template.url }}" class="image" [class.selected]="template === data.selected" />
        <label fxFlex="10%">{{template.name}}</label>
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'templates'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{templatesSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{templatesSlice}}</label>&nbsp; of &nbsp;
    <label>{{templatesArray.length}}</label>
  </div>

  <div *ngIf="group.value == 'stored'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let store of stored; let i = index" fxFlex>
      <div fxLayout="column" fxLayoutGap="5px" (click)="selectedimage(store)" class="imagebox">
          <img *ngIf="store.screenshot" src="{{ store.screenshot }}" class="image" fxFlex="90%"
            [class.selected]="store === data.selected" />
          <div *ngIf="!store.screenshot" style="width: 150px; height: 150px; background-color: aliceblue;"
            [class.selected]="store === data.selected">
          </div>
        <label  [class.textselected]="store === data.selected" fxFlex="10%">{{store.name}}</label>
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'stored'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{storedSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{storedSlice}}</label>&nbsp; of &nbsp;
    <label>{{storedArray.length}}</label>
  </div>

  <!-- <div *ngIf="group.value == 'fromfile'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let file of data.fromfile; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(file)" fxLayoutAlign="space-around start" class="imagebox">
        <img src="{{ file.url }}" class="image" [class.selected]="file === data.selected" />
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'fromfile'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{fromfileSliceMin + 1}}</label>&nbsp;-&nbsp;
    <label>{{fromfileSlice + 1}}</label>&nbsp; of &nbsp;
    <label>{{fromfileArray.length}}</label>
  </div> -->

  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()" i18n>Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected" i18n>
      Import
    </button>
  </div>
</div>