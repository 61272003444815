import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Application, Container, Sprite, Loader, Rectangle, WRAP_MODES, Texture, BaseTexture, Renderer } from 'pixi.js';
import { SVG } from '@svgdotjs/svg.js';
import { gsap } from 'src/assets/js/all';
import { CustomBounce, CustomEase, PixiPlugin, Physics2DPlugin, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper, Draggable } from 'src/assets/js/all';
import { GlitchFilter } from '@pixi/filter-glitch';
import { ShockwaveFilter } from '@pixi/filter-shockwave';
import { CrossHatchFilter } from '@pixi/filter-cross-hatch';
import { OldFilmFilter } from '@pixi/filter-old-film';
import { DisplacementFilter } from '@pixi/filter-displacement';

@Injectable({
  providedIn: 'root'
})
export class BackgroundeffectsService {
  public app: Application;

  constructor(
    public snackBar: MatSnackBar,
  ) { }

  async addWeatherEffect(canvas, primairytimeline, videourl, currenttime) {
    // flame idea https://codepen.io/ste-vg/pen/oZXmdL

    if (this.app){
      this.app.destroy();
    }

    let type = canvas.weather;
    let classtype;
    let total = 30;
    let totalfallingstars = 4;
    let aggrenr = parseInt(canvas.width) * parseInt(canvas.height);
    let averagesize = Math.round(aggrenr / (600 * 500));
    if (type === 'flies') { total = 40 * averagesize }
    if (type === 'stars') { total = 100 * averagesize; }
    if (type === 'snow') { total = 60 * averagesize }
    if (type === 'celebrations') { total = 60 * averagesize }
    if (type === 'rain') { total = 60 * averagesize }
    if (type === 'leaves') { total = 50 * averagesize }
    if (type === 'sun') { total = 90 * averagesize } // also depends on the angle this case is 90 degrees
    if (type === 'clouds') { total = 20 * averagesize }
    if (type === 'butterfly') { total = 15 * averagesize }
    let container = document.getElementById("weathercontainer");
    let starscontainer = document.getElementById('starscontainer');
    // container.removeChild   ---> ??
    starscontainer.innerHTML = '';
    container.innerHTML = '';
    let randomcolors = ['dodgerblue', 'red', 'yellow', 'green', 'purple']
    let w = container.offsetWidth;
    let h = container.offsetHeight;
    let canvasposR = container.offsetWidth;
    let heightani = h * -1;
    let heightanibottom = heightani - 100; // total area from above the square to lower edge
    let heightanitop = heightanibottom * 2;
    let widthaniside = (w * -1) * 2;

    if (type === 'sun') {
      let sun = document.createElement('div');
      primairytimeline.set(sun, { attr: { class: 'sun' }, x: w - 70, y: -30 }, 0);
      container.appendChild(sun);
    }
    if (!canvas.imageurl && !videourl) {
      if (type === 'shockwave' || type === 'glitch' || type === 'matrix' || type === 'oldfilm' || type === 'pond') {
        this.snackBar.open('No background image or video selected', undefined, {
          duration: 2000,
          panelClass: 'snackbar-class'
        });
      }
    } else {
      if (type === 'shockwave') {
        let pixiloader1 = new Loader()
          //.add("bgurl", canvas["background-image"])
          // .add("bgurl", canvas.imageurl)
          .load((loader, resources) => {
            this.shockwaveLoad(loader, resources, w, h, primairytimeline, canvas, "starscontainer");
            //console.log(loader, resources)
            //this.hideImageVideo();
          });
      }

      if (type === 'glitch') {
        let vidcont = document.getElementById('videobgcontainer');
        let pixiloader1 = new Loader()
          //  .add("bgurl", canvas.imageurl)
          .load((loader, resources) => {
            this.glitchLoad(loader, resources, w, h, primairytimeline, canvas, "starscontainer");
            //this.hideImageVideo();
          });
      }

      if (type === 'matrix') {
        let vidcont = document.getElementById('videobgcontainer');
        let pixiloader1 = new Loader()
          //  .add("bgurl", canvas.imageurl)
          .load((loader, resources) => {
            this.matrixLoad(loader, resources, w, h, primairytimeline, canvas, "starscontainer");
            //this.hideImageVideo();
          });
      }


      if (type === 'oldfilm') {
        let vidcont = document.getElementById('videobgcontainer');
        let pixiloader1 = new Loader()
          //   .add("bgurl", canvas.imageurl)
          .load((loader, resources) => {
            this.oldFilmLoad(loader, resources, w, h, primairytimeline, canvas, "starscontainer");
            //this.hideImageVideo();
          });
      }

      if (type === 'pond') {
        let pixiloader1 = new Loader()
          .add("displacementMap", "assets/displacementmap.png")
          //   .add("bgurl", canvas.imageurl)
          //.add("bgvid", canvas.videourl)
          .load((loader, resources) => {
            this.pondLoad(loader, resources, w, h, primairytimeline, canvas, "starscontainer");
            //this.hideImageVideo();
          });
      }
    }

    if (type === 'water') {
      let points = Math.round(w / 100);
      let wavepath = ['M 0 ' + h * 0.2 + ' ']; // start high 0.1 is start low (on inverted view)
      for (let i = 0; i < points; i++) {
        let sh = h * 0.2; // next point height
        let sw = (i + 1) * 100; // next point width
        let scw = (i * 100) + 50; // curve point width (usually in the middle)
        let sch = 200; // curve point height
        let oneven_sh = sh - (50 + this.R(0, 50)); //add this.R(0,50)
        let even_sh = sh + (50 + this.R(0, 50));
        if (this.isEven(i + 1)) {
          sch = even_sh;
        } else { sch = oneven_sh }
        let point = 'Q ' + scw + ' ' + sch + ' ' + sw + ' ' + sh + ' ';
        wavepath.push(point);
      }
      wavepath.push('V ' + h + ' H 0 z');
      let wavepathstr = wavepath.join('');
      let water = SVG().addTo(container).size(w, h);
      water.id('watereffect')
      water.path(wavepathstr).attr({ fill: 'rgba(97,160,226,0.8)', id: 'watereffectpath' });

      // create morph path only changes the even and oneven value
      let wavepathmorph = ['M 0 ' + h * 0.2 + ' '];
      for (let i = 0; i < points; i++) {
        let sh = h * 0.2; // next point height
        let sw = (i + 1) * 100; // next point width
        let scw = (i * 100) + 50; // curve point width (usually in the middle)
        let sch = 200; // curve point height
        let oneven_sh = sh + (50 + this.R(0, 50)); //reverse +/-
        let even_sh = sh - (50 + this.R(0, 50)); //reverse +/-
        if (this.isEven(i + 1)) {
          sch = even_sh;
        } else { sch = oneven_sh }

        let point = 'Q ' + scw + ' ' + sch + ' ' + sw + ' ' + sh + ' ';
        wavepathmorph.push(point);
      }
      wavepathmorph.push('V ' + h + ' H 0 z');
      let wavepathmorphstr = wavepathmorph.join('');

      let wavepathmorph1 = document.getElementById('watereffectpath');
      //console.log(wavepathmorph1, wavepathmorphstr)
      primairytimeline.to(wavepathmorph1,
        { morphSVG: wavepathmorphstr, duration: 2, repeat: -1, yoyo: true, ease: 'sine.easeInOut' }, 0)
    }

    if (type === 'rain') {

      for (let i = 0; i < 5; i++) {
        let clouds = document.createElement('div');
        primairytimeline.set(clouds, { attr: { class: 'half-circle' }, x: this.R(-200, w), y: this.R(-30, 0) }, 0);
        starscontainer.appendChild(clouds);
        let rantim = this.R(0, 20);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "#FFFFE0", ease: 'elastic.out(1, 0.3)' }, rantim);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "", ease: 'elastic.out(1, 0.3)' }, rantim + 0.5);
      }
      for (let i = 0; i < 5; i++) {
        let clouds = document.createElement('div');
        primairytimeline.set(clouds, { attr: { class: 'half-circle-dark' }, x: this.R(-200, w), y: this.R(-30, 0) }, 0);
        starscontainer.appendChild(clouds);
        let rantim = this.R(0, 20);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "#FFFFE0", ease: 'elastic.out(1, 0.3)' }, rantim);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "", ease: 'elastic.out(1, 0.3)' }, rantim + 0.5);
      }
      for (let i = 0; i < 5; i++) {
        let clouds = document.createElement('div');
        primairytimeline.set(clouds, { attr: { class: 'half-circle-light' }, x: this.R(-200, w), y: this.R(-30, 0) }, 0);
        starscontainer.appendChild(clouds);
        let rantim = this.R(0, 20);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "#FFFFE0", ease: 'elastic.out(1, 0.3)' }, rantim);
        primairytimeline.to(clouds, { duration: 1, backgroundColor: "", ease: 'elastic.out(1, 0.3)' }, rantim + 0.5);
      }


    }

    if (type === 'stars') {
      let moon = document.createElement('div');
      moon.classList.add('moon')
      primairytimeline.set(moon, { attr: { class: 'moon' }, x: 0, y: 0 }, 0);
      let UL = document.createElement('div');
      for (let i = 1; i < 8; i++) {
        let li = document.createElement('div');
        li.classList.add("limoon" + i);
        UL.appendChild(li);
      }
      primairytimeline.to(moon, { duration: 60, x: 1200, rotationZ: 50, repeat: -1, yoyo: true, ease: 'none' }, 0);
      moon.appendChild(UL)
      starscontainer.appendChild(moon);
    }

    //primairytimeline.set('#starContainer', { rotation: -30 }); // you can set rotation via css too
    // if (type === 'stars') {
    //   for (let i = 0; i < totalfallingstars; i++) {
    //     let FallingStars = document.createElement('div');
    //     let setid = 'fallingStar' + i;
    //     FallingStars.setAttribute('id', setid);
    //     primairytimeline.set(FallingStars, { attr: { class: 'starshooting' }, y: this.R(0, w), x: this.R(0, h) });

    //     primairytimeline.from(FallingStars, .5, { transformOrigin: "right", scaleX: 0 });
    //     primairytimeline.to(FallingStars, .5, { scaleX: 0, transformOrigin: "left", delay: .5, repeat: -1  });
    //     container.appendChild(FallingStars);
    //   }
    // }

    //const svgurl = 'https://app.enydea.com/api/Containers/5a2a4e745c2a7a06c443533f/download/2x0vzs.svg';

    for (let i = 0; i < total; i++) {

      let Div = document.createElement('div');
      let setid = 'divPar' + i;
      Div.setAttribute('id', setid);

      if (canvas.hovereffect) {
        Div.addEventListener("mouseenter", (e) => {
          //console.log(e);
          primairytimeline.to(e.target, { duration: '5', y: this.R(0, w), x: this.R(0, h), ease: 'none' }, currenttime); //repeat: -1,  yoyo: true
        })
      }

      if (type === 'snow') {
        gsap.set(Div, { attr: { class: 'snow' }, x: this.R(0, canvasposR), y: -100, z: this.R(-200, 200), rotationZ: this.R(0, 180), rotationX: this.R(0, 360) });
      }
      if (type === 'celebrations') {
        //let yset = h * -1; // this.R(yset, 0)
        let randomy = this.RandomInt(0, 5);
        let color = randomcolors[randomy];
        Div.setAttribute('style', 'background-color: ' + color);
        gsap.set(Div, { attr: { class: 'celebrations' }, x: this.R(0, canvasposR), y: -100, z: this.R(-200, 200), rotationZ: this.R(0, 180), rotationX: this.R(0, 360) });
      }
      if (type === 'rain') {
        gsap.set(Div, { attr: { class: 'rain' }, x: this.R(0, canvasposR), y: this.R(heightanibottom, heightanitop), z: this.R(-200, 200), rotation: "-20_short", });
      }
      if (type === 'leaves') {
        classtype = 'leaves' + Math.floor(this.R(1, 4));
        gsap.set(Div, { attr: { class: classtype }, x: this.R(0, canvasposR), y: this.R(h, heightanitop), z: this.R(-200, 200), rotationZ: this.R(0, 180), rotationX: this.R(0, 360) });
      }
      if (type === 'clouds') {
        gsap.set(Div, { attr: { class: 'cloud' }, x: this.R(w, widthaniside), y: this.R(0, h * 0.1), z: this.R(-200, 200), scale: this.R(0.5, 1.5), yoyo: true });
      }
      if (type === 'stars') {
        let scale = this.R(0.2, 1.5);
        gsap.set(Div, { attr: { class: 'stars' }, scale: scale, x: this.R(0, parseInt(canvas.width, 10)), y: this.R(0, parseInt(canvas.height, 10)) });
        canvas["background-color"] = 'transparent';
      }
      if (type === 'flies') {
        let scale = this.R(0.2, 1.5);
        gsap.set(Div, { attr: { class: 'flies' }, scale: scale, x: this.R(0, parseInt(canvas.width, 10)), y: this.R(0, parseInt(canvas.height, 10)) });
      }

      if (type === "butterfly") {
        // butterfly
        let butterfly = document.createElement('div');
        butterfly.className = "butterfly";
        Div.appendChild(butterfly);
        let wing1 = document.createElement('div');
        wing1.className = "wing";
        butterfly.appendChild(wing1);
        let bit1 = document.createElement('div');
        bit1.className = "bit";
        wing1.appendChild(bit1);
        let bit2 = document.createElement('div');
        bit2.className = "bit";
        wing1.appendChild(bit2);
        let wing2 = document.createElement('div');
        wing2.className = "wing";
        butterfly.appendChild(wing2);
        let bit3 = document.createElement('div');
        bit3.className = "bit";
        wing2.appendChild(bit3);
        let bit4 = document.createElement('div');
        bit4.className = "bit";
        wing2.appendChild(bit4);

        let randomy = this.RandomInt(0, 5);
        let color = randomcolors[randomy];
        let bits = Div.getElementsByClassName('bit')
        for (let i = 0; i < bits.length; i++) {
          bits[i].setAttribute('style', 'background-color: ' + color);
        }
        let scale = this.R(0.1, 0.3);
        let calcscale = (scale - 1);
        let w2 = parseInt(canvas.width) / 2;
        let h2 = parseInt(canvas.height) / 2;
        let mw = w2 * calcscale;
        let mh = h2 * calcscale;

        gsap.set(Div, { scale: scale, x: this.R(mw, (w + mw)), y: this.R(mh, h) });   //  compensate for scale
        let movex = '+=' + this.R((mw * 2), (w + mw)); // mw * 2 increases travellenght
        let movey = '+=' + this.R((mh * 2), h);
        primairytimeline.to(Div, { duration: this.R(5, 15), scale: scale, ease: 'none', repeat: -1, yoyo: true }, this.R(0, 10));
        primairytimeline.to(Div, { duration: this.R(5, 15), y: movey, ease: 'none', repeat: -1, yoyo: true }, 0);
        primairytimeline.to(Div, { duration: this.R(5, 15), x: movex, rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'none' }, 0);
      }

      if (type === 'clouds') { this.animclouds(Div, h, w, primairytimeline); }
      if (type === 'snow') { this.animsnow(Div, h, primairytimeline); }
      if (type === 'celebrations') { this.animceleb(Div, h, w, primairytimeline); }
      if (type === 'rain') { this.animrain(Div, h, primairytimeline); }
      if (type === 'leaves') { this.animleaves(Div, h, primairytimeline); }
      if (type === 'sun') {
        let angle = (270 + i) * (Math.PI / 180);
        let xa = w - (Math.cos(angle) * w); // w
        let ya = -1 * (Math.sin(angle) * h); // h
        gsap.set(Div, { attr: { class: 'sunray' }, x: w + 10, y: -10, rotation: i + "_short", });
        this.animsun(Div, ya, xa, primairytimeline);
      }
      if (type === 'flies') {
        this.animflies(Div, h, w, primairytimeline);
      }
      if (type === 'stars') { this.animstars(Div, primairytimeline); }
      if (type === 'butterfly') { this.animbutterfly(Div, h, w, primairytimeline); }

      if (type === 'stars') {
        starscontainer.appendChild(Div);
      } else {
        container.appendChild(Div);
      }

    }
  }


  animbutterfly(elm: HTMLDivElement, h, w, primairytimeline) {
    let customease = CustomEase.create("custom", "M0,0,C0,0,0.256,0.014,0.432,0.176,0.608,0.338,0.436,0.638,0.638,0.842,0.792,0.998,1,1,1,1");
    let leftwing = elm.getElementsByClassName('wing')[0];
    let rightwing = elm.getElementsByClassName('wing')[1];
    primairytimeline.fromTo(leftwing, { rotationY: -20 }, {
      rotationY: 90, duration: 0.25, transformOrigin: '700% 50%', repeat: -1, yoyo: true,
      ease: customease
    }, 0);
    primairytimeline.fromTo(rightwing, { rotationY: 200 }, {
      rotationY: 90, duration: 0.25, repeat: -1, yoyo: true,
      ease: customease
    }, 0);
    let butterfly = elm.getElementsByClassName('butterfly')[0];
    primairytimeline.fromTo(butterfly, { y: 0 }, { y: -5, duration: 0.25, repeat: -1, yoyo: true, ease: customease }, 0); //set body animation
  }

  animflies(elm, h, w, primairytimeline) {
    let minw = (w * -1) / 2;
    let minh = (h * -1) / 2;
    let movex = '+=' + this.R(minw, (w / 2));
    let movey = '+=' + this.R(minh, (h / 2));
    let scale = this.R(0.2, 1.5);
    primairytimeline.to(elm, { duration: this.R(5, 20), scale: scale, ease: 'none', repeat: -1, yoyo: true }, this.R(0, 10));
    primairytimeline.to(elm, { duration: this.R(0, 20), autoAlpha: 0.1, ease: 'none', repeat: -1, yoyo: true }, this.R(0, 10));
    primairytimeline.to(elm, { duration: this.R(5, 20), y: movey, ease: 'none', repeat: -1, yoyo: true }, 0);
    primairytimeline.to(elm, { duration: this.R(5, 20), x: movex, rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'none' }, 0);
  }


  animstars(elm, primairytimeline) {
    let scale = this.R(0.2, 1.5);
    primairytimeline.to(elm, { duration: 10, scale: scale, ease: 'none', repeat: -1, yoyo: true, delay: this.R(0, 10) }, this.R(0, 10));
    primairytimeline.to(elm, { duration: 5, autoAlpha: 0, ease: 'none', repeat: -1, yoyo: true, delay: this.R(0, 10) }, this.R(0, 10));
  }

  animsun(elm, h, w, primairytimeline) {
    primairytimeline.to(elm, { duration: 10, y: h, x: w, ease: 'linear.none', repeat: -1, delay: 0 }, this.R(0, 10));
  }

  animclouds(elm, h, w, primairytimeline) {
    primairytimeline.to(elm, { duration: 15, x: '+=200', ease: 'linear.none', repeat: -1, delay: 0, yoyo: true }, 0);
  } // y: h, '+=' + w

  animsnow(elm, h, primairytimeline) {
    let randomstart = this.R(0, 25)
    primairytimeline.to(elm, { duration: this.R(15, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, randomstart);
    primairytimeline.to(elm, { duration: this.R(8, 8), x: '+=100', rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
    primairytimeline.to(elm, { duration: this.R(2, 8), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
  };

  animceleb(elm, h, w, primairytimeline) {
    let minw = (w * -1) / 2;
    let movex = '+=' + this.R(minw, (w / 2));
    let randomstart = this.R(0, 25)
    primairytimeline.to(elm, { duration: this.R(20, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, randomstart);
    primairytimeline.to(elm, { duration: this.R(8, 15), x: movex, rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
    primairytimeline.to(elm, { duration: this.R(8, 15), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
  };

  // element, time(speed),
  animrain(elm, h, primairytimeline) {
    primairytimeline.to(elm, { duration: this.R(2, 4), y: h, x: '+=100', ease: 'linear.none', repeat: -1, delay: 0 }, 0);
  };

  animleaves(elm, h, primairytimeline) {
    primairytimeline.to(elm, { duration: this.R(20, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, 0);
    primairytimeline.to(elm, { duration: this.R(4, 8), x: '+=100', rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, 0);
    primairytimeline.to(elm, { duration: this.R(2, 8), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, 0);
  }


  async glitchLoad(loader, resources, w, h, primairytimeline, canvas, id) {
    let uppercontainer = document.getElementById(id);
    let x = document.createElement("canvas");
    x.setAttribute("id", "canvaseffectlayer");
    x.width = w;
    x.height = h;
    uppercontainer.appendChild(x);

    this.app = new Application({
      width: w, height: h,
      view: x, transparent: false
    });

    let background = await this.setBGPixi(resources, w, h, primairytimeline, canvas) as Sprite;
    background.width = w;
    background.height = h;

    let container = new Container();
    let glitchFilter = new GlitchFilter();

    glitchFilter.blue.y = 0;
    glitchFilter.blue.x = 0;
    glitchFilter.red.y = 0;
    glitchFilter.red.x = 0;
    glitchFilter.green.x = 0;
    glitchFilter.green.y = 0;
    glitchFilter.offset = 0;
    glitchFilter.seed = 0;

    container.filters = [glitchFilter];
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(glitchFilter, { seed: Math.random() }, 0);
    primairytimeline.to(glitchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 0.1,
      red: { x: 5, y: 6 },
    }, 0);
    primairytimeline.to(glitchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 0.5,
      repeatDelay: 1,
      offset: 100,
      fillMode: 2,
      slice: 10
    }, 0);

    // const video = document.getElementById('videobgcontainer') as HTMLVideoElement;
    // video.parentNode.removeChild(video);
  }

  async pondLoad(loader, resources, w, h, primairytimeline, canvas, id) {
    let uppercontainer = document.getElementById(id);
    let x = document.createElement("canvas");
    x.setAttribute("id", "canvaseffectlayer");
    x.width = w;
    x.height = h;
    uppercontainer.appendChild(x);
    this.app = new Application({
      width: w, height: h,
      view: x, transparent: true
    });

    let container = new Container();
    //container.position.set(-10);
    //let background = new Sprite(resources.bgurl.texture);
    let background = await this.setBGPixi(resources, w, h, primairytimeline, canvas) as Sprite;
    background.width = w;
    background.height = h;
    let displacementSprite = new Sprite(resources.displacementMap.texture);
    let displacementFilter = new DisplacementFilter(displacementSprite);

    container.filterArea = new Rectangle(-10, -10, w + 10, h + 10);
    container.filters = [displacementFilter];
    displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;

    container.addChild(background);
    container.addChild(displacementSprite);
    this.app.stage.addChild(container);

    primairytimeline.to(displacementSprite, {
      ease: 'none',
      repeat: -1,
      duration: 2,
      yoyo: true,
      x: w,
      y: h
    }, 0);
  }

  async shockwaveLoad(loader, resources, w, h, primairytimeline, canvas, id) {
    // console.log(loader, resources, w, h, this.app);
    let uppercontainer = document.getElementById(id);
    let x = document.createElement("canvas");
    x.setAttribute("id", "canvaseffectlayer");
    x.width = w;
    x.height = h;
    uppercontainer.appendChild(x);

    this.app = new Application({
      width: w, height: h,
      view: x, transparent: true
    }
    );
    let container = new Container();
    let shockwaveFilter = new ShockwaveFilter();
    shockwaveFilter.center = [w / 2, h / 2];
    shockwaveFilter.options = { brightness: 1.5, radius: 0.0, speed: (w / 3) };

    container.filters = [shockwaveFilter];
    let background = await this.setBGPixi(resources, w, h, primairytimeline, canvas) as Sprite;

    background.width = w + 100;
    background.height = h + 100;
    //container.position.set(-10);
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(shockwaveFilter, {
      ease: 'linear.easeNone',
      time: 2,
      repeat: -1,
      duration: 3,
    }, 0);
  }

  async matrixLoad(loader, resources, w, h, primairytimeline, canvas, id) {
    let uppercontainer = document.getElementById(id);
    let x = document.createElement("canvas");
    x.setAttribute("id", "canvaseffectlayer");
    x.width = w;
    x.height = h;
    uppercontainer.appendChild(x);

    this.app = new Application({
      width: w, height: h,
      view: x, transparent: true
    }
    );
    let container = new Container();
    let crossHatchFilter = new CrossHatchFilter();
    container.filters = [crossHatchFilter];
    let background = await this.setBGPixi(resources, w, h, primairytimeline, canvas) as Sprite;

    background.width = w;
    background.height = h;
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(crossHatchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 3,
    }, 0);
  }

  async oldFilmLoad(loader, resources, w, h, primairytimeline, canvas, id) {
    let uppercontainer = document.getElementById(id);
    let x = document.createElement("canvas");
    x.setAttribute("id", "canvaseffectlayer");
    x.width = w;
    x.height = h;
    uppercontainer.appendChild(x);

    this.app = new Application({
      width: w, height: h,
      view: x, transparent: true
    }
    );
    let container = new Container();
    let oldFilmFilter = new OldFilmFilter();
    // oldFilmFilter.sepia = 0.3,
    //   oldFilmFilter.noise = 0.3,
    //   oldFilmFilter.noiseSize = 2,
    //   oldFilmFilter.scratch = 0.5,
    //   oldFilmFilter.scratchDensity = 0.3,
    //   oldFilmFilter.scratchWidth = 10,
    //   oldFilmFilter.vignetting = 0.3,
    //   oldFilmFilter.vignettingAlpha = 0.3,
    //   oldFilmFilter.vignettingBlur = 0.3,
    //   oldFilmFilter.seed = 0.3;


    container.filters = [oldFilmFilter];
    let background = await this.setBGPixi(resources, w, h, primairytimeline, canvas) as Sprite;

    background.width = w;
    background.height = h;
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(oldFilmFilter, {
      vignetting: 0.3,
      vignettingAlpha: 0.3,
      vignettingBlur: 0.3,
      duration: 1,
    }, 0);
    primairytimeline.to(oldFilmFilter, {
      ease: 'none',
      sepia: 0.3,
      seed: Math.random(),
      noise: 0.3,
      noiseSize: 1,
      scratch: 0.5,
      scratchDensity: 0.3,
      scratchWidth: 3,
      //vignetting: 0.3,
      //vignettingAlpha: 0.3,
      //vignettingBlur: 0.3,
      repeat: -1,
      duration: 3,
    }, 0);
  }


  R(min, max) { return min + Math.random() * (max - min) };

  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }

  isEven(n) {
    return n % 2 == 0;
  }


  async setBGPixi(resources, w, h, primairytimeline, canvas) {
    return new Promise((resolve, reject) => {
      if (canvas.videourl) {
        const video = document.getElementById('videobgcontainer') as HTMLVideoElement;
        let videoRen = Texture.from(video);
        let background = new Sprite(videoRen) as any;
        background.preload = 'auto';
        background.autoplay = false;
        let rscVid = background.texture.baseTexture.resource as any;
        rscVid.autoPlay = false;
        const videoControler = rscVid.source;
        videoControler.pause();
        primairytimeline.call(this.playPixiVideo, [videoControler], 0);
        resolve(background);
      } else if (canvas["background-image"]) {
        //console.log('load image', resources.bgurl);
        // let image = document.getElementById('imagebgcontainer') as HTMLImageElement;
        let imageRen = Texture.from(canvas.imageurl);
        // let background = new Sprite(imageRen);
        let background = new Sprite(imageRen);
        resolve(background);
      } else {
        let background = new Sprite(Texture.WHITE);
        //background.tint = this.canvas["background-color"]; //Change with the color wanted
        background.width = w;
        background.height = h;
        primairytimeline.set(background, { pixi: { tint: canvas["background-color"] } }, 0);
        resolve(background);
      }

    });
  }

  playPixiVideo(videoControler) {
    videoControler.play();
  }

}
