<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">
    <mat-button-toggle matTooltip="Open stored" style="width: 50%" value="stored">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Standard threedfiles" style="width: 50%" value="standardthreedfiles">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="group.value == 'standardthreedfiles'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let threedfile of stockthreed; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(threedfile)" fxLayoutAlign="start start">
        <img src="{{ threedfile.thumburl }}" class="image"
          [class.selected]="threedfile === data.selected" />
        <!-- <div *ngIf="!threedfile.thumburl" style="width: 150px; height: 150px; background-color: aliceblue;"
          [class.selected]="threedfile === data.selected">
        </div> -->
        <!-- <label>{{threedfile.name}}</label> -->
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'standardthreedfiles'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{stockthreedSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{stockthreedSlice}}</label>&nbsp; of &nbsp;
    <label>{{stockthreedArray.length}}</label>
  </div>


  <div *ngIf="group.value == 'stored'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let store of stored; let i = index" fxFlex>
      <div fxLayout="column" (click)="selectedimage(store)">
        <img *ngIf="store.screenshot" src="{{ store.screenshot }}" class="image"
          [class.selected]="store === data.selected" />
        <div *ngIf="!store.screenshot" style="width: 150px; height: 150px; background-color: aliceblue;"
          [class.selected]="store === data.selected">
        </div>
        <h5>{{store.name}}</h5>
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'stored'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{storedSliceMin}}</label>&nbsp;-&nbsp;
    <label>{{storedSlice}}</label>&nbsp; of &nbsp;
    <label>{{storedArray.length}}</label>
  </div>

  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>