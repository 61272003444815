/* tslint:disable */
import {
  Company,
  Unsortedcalls,
  Emailhandler,
  Logger,
  Chatmessages,
  Pushsubscriptions
} from '../index';

declare var Object: any;
export interface AccountInterface {
  "language"?: string;
  "country"?: string;
  "companyId"?: any;
  "companyname": string;
  "marketingoption"?: string;
  "marketingoptionId"?: string;
  "companyadmin": boolean;
  "active": boolean;
  "standardrelation"?: string;
  "standardGa"?: string;
  "lastlogin"?: Date;
  "signature"?: string;
  "dashboards"?: any;
  "stddashboard"?: number;
  "bccemail"?: string;
  "bccid"?: string;
  "365account"?: string;
  "lastupdate365account"?: Date;
  "autoassign": boolean;
  "autoassigninbox"?: any;
  "dashboardtype"?: string;
  "avatarurl"?: string;
  "startwalkthrough"?: boolean;
  "notificationform"?: boolean;
  "notificationchat"?: boolean;
  "logform"?: boolean;
  "logchat"?: boolean;
  "pushform"?: boolean;
  "pushchat"?: boolean;
  "pushnotifications"?: boolean;
  "darktheme"?: boolean;
  "vatnr"?: string;
  "two_factor_enabled"?: boolean;
  "two_factor_temp_secret"?: string;
  "emailVerified"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "id"?: any;
  "password"?: string;
  accessTokens?: any[];
  company?: Company[];
  unsortedcalls?: Unsortedcalls[];
  emailhandler?: Emailhandler[];
  logger?: Logger[];
  subscription?: any[];
  chatmessages?: Chatmessages[];
  pushsubscriptions?: Pushsubscriptions[];
}

export class Account implements AccountInterface {
  "language": string;
  "country": string;
  "companyId": any;
  "companyname": string;
  "marketingoption": string;
  "marketingoptionId": string;
  "companyadmin": boolean;
  "active": boolean;
  "standardrelation": string;
  "standardGa": string;
  "lastlogin": Date;
  "signature": string;
  "dashboards": any;
  "stddashboard": number;
  "bccemail": string;
  "bccid": string;
  "365account": string;
  "lastupdate365account": Date;
  "autoassign": boolean;
  "autoassigninbox": any;
  "dashboardtype": string;
  "avatarurl": string;
  "startwalkthrough": boolean;
  "notificationform": boolean;
  "notificationchat": boolean;
  "logform": boolean;
  "logchat": boolean;
  "pushform": boolean;
  "pushchat": boolean;
  "pushnotifications": boolean;
  "darktheme": boolean;
  "vatnr": string;
  "two_factor_enabled": boolean;
  "two_factor_temp_secret": string;
  "emailVerified": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "id": any;
  "password": string;
  accessTokens: any[];
  company: Company[];
  unsortedcalls: Unsortedcalls[];
  emailhandler: Emailhandler[];
  logger: Logger[];
  subscription: any[];
  chatmessages: Chatmessages[];
  pushsubscriptions: Pushsubscriptions[];
  constructor(data?: AccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Account`.
   */
  public static getModelName() {
    return "Account";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Account for dynamic purposes.
  **/
  public static factory(data: AccountInterface): Account{
    return new Account(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Account',
      plural: 'Accounts',
      path: 'Accounts',
      idName: 'id',
      properties: {
        "language": {
          name: 'language',
          type: 'string',
          default: 'en'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "companyId": {
          name: 'companyId',
          type: 'any'
        },
        "companyname": {
          name: 'companyname',
          type: 'string'
        },
        "marketingoption": {
          name: 'marketingoption',
          type: 'string'
        },
        "marketingoptionId": {
          name: 'marketingoptionId',
          type: 'string'
        },
        "companyadmin": {
          name: 'companyadmin',
          type: 'boolean',
          default: true
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "standardrelation": {
          name: 'standardrelation',
          type: 'string'
        },
        "standardGa": {
          name: 'standardGa',
          type: 'string'
        },
        "lastlogin": {
          name: 'lastlogin',
          type: 'Date'
        },
        "signature": {
          name: 'signature',
          type: 'string'
        },
        "dashboards": {
          name: 'dashboards',
          type: 'any',
          default: <any>null
        },
        "stddashboard": {
          name: 'stddashboard',
          type: 'number'
        },
        "bccemail": {
          name: 'bccemail',
          type: 'string'
        },
        "bccid": {
          name: 'bccid',
          type: 'string'
        },
        "365account": {
          name: '365account',
          type: 'string'
        },
        "lastupdate365account": {
          name: 'lastupdate365account',
          type: 'Date'
        },
        "autoassign": {
          name: 'autoassign',
          type: 'boolean',
          default: true
        },
        "autoassigninbox": {
          name: 'autoassigninbox',
          type: 'any',
          default: <any>null
        },
        "dashboardtype": {
          name: 'dashboardtype',
          type: 'string'
        },
        "avatarurl": {
          name: 'avatarurl',
          type: 'string'
        },
        "startwalkthrough": {
          name: 'startwalkthrough',
          type: 'boolean',
          default: true
        },
        "notificationform": {
          name: 'notificationform',
          type: 'boolean',
          default: true
        },
        "notificationchat": {
          name: 'notificationchat',
          type: 'boolean',
          default: true
        },
        "logform": {
          name: 'logform',
          type: 'boolean',
          default: true
        },
        "logchat": {
          name: 'logchat',
          type: 'boolean',
          default: true
        },
        "pushform": {
          name: 'pushform',
          type: 'boolean',
          default: true
        },
        "pushchat": {
          name: 'pushchat',
          type: 'boolean',
          default: true
        },
        "pushnotifications": {
          name: 'pushnotifications',
          type: 'boolean',
          default: true
        },
        "darktheme": {
          name: 'darktheme',
          type: 'boolean',
          default: true
        },
        "vatnr": {
          name: 'vatnr',
          type: 'string'
        },
        "two_factor_enabled": {
          name: 'two_factor_enabled',
          type: 'boolean',
          default: false
        },
        "two_factor_temp_secret": {
          name: 'two_factor_temp_secret',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean',
          default: false
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        company: {
          name: 'company',
          type: 'Company[]',
          model: 'Company',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        unsortedcalls: {
          name: 'unsortedcalls',
          type: 'Unsortedcalls[]',
          model: 'Unsortedcalls',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        emailhandler: {
          name: 'emailhandler',
          type: 'Emailhandler[]',
          model: 'Emailhandler',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        logger: {
          name: 'logger',
          type: 'Logger[]',
          model: 'Logger',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        subscription: {
          name: 'subscription',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        chatmessages: {
          name: 'chatmessages',
          type: 'Chatmessages[]',
          model: 'Chatmessages',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        pushsubscriptions: {
          name: 'pushsubscriptions',
          type: 'Pushsubscriptions[]',
          model: 'Pushsubscriptions',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
      }
    }
  }
}
