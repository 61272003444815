<div class="twofactorcontainer" fxLayoutAlign="center start" fxLayout="column">
    <h3 i18n>Fill in you token code from your authentication app</h3>
    <br>
    <div fxLayoutGap="10px" fxLayoutAlign="center start">
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode1" maxlength="1" id="token1" (keyup)="focusNext(1)" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode2" maxlength="1" id="token2" (keyup)="focusNext(2)" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode3" maxlength="1" id="token3" (keyup)="focusNext(3)" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode4" maxlength="1" id="token4" (keyup)="focusNext(4)" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode5" maxlength="1" id="token5" (keyup)="focusNext(5)" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="tokencode6" maxlength="1" id="token6" (keyup)="focusNext(6)" >
        </mat-form-field>
    </div>
    <br>
    <button mat-raised-button (click)="submit()" i18n>Submit</button>
</div>