<div *ngIf="formEntries" style="margin: 5px;">
    <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" width="100%" *ngIf="formEntries.length > 0">
            <div *ngFor="let content of formEntries[0].formcontent | slice:0:5;" fxLayoutGap="5px"
                [fxFlex]="(100 / (formEntries[0].formcontent.length -1 ))">
                <label *ngIf="content.text" style="font-weight: bold;">{{content.text | slice: 0:50}}: </label>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div *ngFor="let entry of formEntries; let i = index" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" width="100%">
            <div *ngFor="let content of entry.formcontent" fxLayoutGap="2px" (click)="openInDialog(entry)"
                [fxFlex]="(100 / (entry.formcontent.length - 1))">
                <div fxLayoutGap="2px" *ngIf="content.type !== 'formcheckbox'" style="overflow: hidden;">
                    <label *ngIf="content.value">{{content.value}}</label> 
                    <mat-icon *ngIf="content.valuetype === 'email'" class="iconsize-12" (click)="replyToFormEntry(content.value, entry)">email</mat-icon>
                    <mat-icon *ngIf="content.valuetype === 'date'" class="iconsize-12">calendar</mat-icon>
                </div>
                <div fxLayoutGap="2px" *ngIf="content.type === 'formcheckbox'">
                    <mat-icon>check_box</mat-icon>
                </div>
            </div>
       
        </div>
        <div fxLayoutGap="10px" fxLayout="row" style="font-size: 8pt;" width="100%">
            <mat-icon *ngIf="entry.processed" class="iconsize-12">done</mat-icon>
            <mat-icon *ngIf="!entry.processed" class="iconsize-12">pending_actions</mat-icon>
            <label fxFlex>{{entry.date | date: "medium" }}</label>
            <label fxFlex>{{entry.city}}</label>
            <label fxFlex>{{entry.country}}</label>
            <label fxFlex>{{entry.companyname}}</label>
            <mat-icon class="iconsize-10" (click)="deleteEntry(i)">delete</mat-icon>
        </div>
        <mat-divider></mat-divider>
    </div>
</div>