import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Relations } from '../../shared/sdk';
import { BASE_URL, API_VERSION } from '../../shared/base.api';
import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { timeInterval } from 'rxjs/operators';
import { fonts }  from '../../shared/listsgeneral/fonts';


@Component({
  selector: 'text-editor-dialog',
  templateUrl: 'text-editor-dialog.html',
})

export class TextEditorDialog implements OnInit {
  public showckeditor2 = false;
  public CKEDITOR: any;
  public ckconfig = {
    extraPlugins: 'emoji,font,spacingsliders,colorbutton,colordialog,pastebase64,scayt',
    colorButton_enableMore: true,
    scayt_autoStartup: true,
    toolbarCanCollapse: true,
    allowedContent: true,
    width: '100%',
    font_names: 'Baloo Bhai;SandBrush/Sandbrush;Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, cursive;' +
      'Courier New/Courier New, Courier, monospace;Georgia/Georgia, serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;Tahoma/Tahoma, Geneva, sans-serif;' +
      'Times New Roman/Times New Roman, Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, Geneva, sans-serif;Roboto/Roboto;Open Sans/Open Sans;' +
      'Shipwreck/Shipwreck;WesternOld/WesternOld;IndustrialOld/IndustrialOld;Hillpark/Hillpark;',
    toolbarStartupExpanded: 'false',
    contentsCss: './assets/fonts/fonts.css',
  }
  

  @Input() public dataset: string; //embedded editor
  @Input() public idset: string;  //embedded editor
  @Output()
  textChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<TextEditorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(MAT_DIALOG_DATA) public id: string) {

  }

  onChangeText(){
    this.textChangedEvent.emit(this.dataset);
  }


  ngOnInit(): void {
      if (this.dataset){
        this.showckeditor2 = true;
      } 

      CKEDITOR.addCss('.cke_editable { background-color: #eeeded; }');
  }

  onRequestCkEvent(evt): void {
    evt.stop(); // stop event and set data manual see above
    const url = BASE_URL + '/api/Containers/' + this.id + '/download/'
    const data1 = evt.data;
    data1.url = url + data1.fileLoader.fileName;
    this.CKEDITOR.tools.callFunction(1, data1.url);
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

}