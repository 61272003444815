import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { DomElementSchemaRegistry } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Console } from 'console';
import { Observable } from 'rxjs';
import { BASE_URL } from 'src/app/shared/base.api';
import { VectorService } from '../../supportfunctions/vector.service';
import { Female, Male, Femalehair, Malehair, Maleheadcodestart, Maleheadcodeend } from './characters';
import { MotionPathPlugin, MotionPathHelper, MorphSVGPlugin } from 'src/assets/js/all';
const plugins = [MotionPathPlugin, MotionPathHelper, MorphSVGPlugin];

@Component({
  selector: 'app-charactercreator',
  templateUrl: './charactercreator.component.html',
  styleUrls: ['./charactercreator.component.scss']
})
export class CharactercreatorComponent implements OnInit {
  female = Female;
  male = Male;
  selected: any;
  selectedSafe: any;
  selectedstring: any;
  svgcombi: any;
  femalehair = Femalehair;
  malehair = Malehair;
  selectedHairstyle: any;

  skincolors = [
    { name: 'white', hex: '#fdb490', hex1: '#472734,', hex2: '#fdc498', hex3: '#ffd4b3', hex4: '#fd9f79', hex5: '#fdb693'},
    { name: 'black', hex: '#3e2302', hex1: '#2f1a01', hex2: '#2f1a01', hex3: '#0f0800', hex4: '#331c02', hex5: '#3b2104' },
    { name: 'brown', hex: '#643805', hex1: '#5d3404', hex2: '#5d3404', hex3: '#3e2302', hex4: '#633b0a', hex5: '#4d3212' },
    { name: 'asian', hex: '#fab464', hex1: '#f9ac55', hex2: '#f9a545', hex3: '#f8a13d', hex4: '#f0952e', hex5: '#de831b' },
    { name: 'brown2', hex: '#fde2c3', hex1: '#fcdab3', hex2: '#fcd3a4', hex3: '#fbcf9c', hex4: '#f7c68d', hex5: '#f5cfa4' },
    { name: 'brown3', hex: '#c56c06', hex1: '#bd6806', hex2: '#ad5f05', hex3: '#9e5605', hex4: '#9c570b', hex5: '#965711' },
    { name: 'alien1', hex: '#000075', hex1: '#0000A3', hex2: '#0000FF', hex3: '#5C5CFF', hex4: '#6262f5', hex5: '#6868ed' },
    { name: 'alien2', hex: '#007500', hex1: '#00D100', hex2: '#5CFF5C', hex3: '#4ae84a', hex4: '#5fed5f', hex5: '#66de66' }
  ]
  selectedskincolor: any;
  hiderow: boolean;


  constructor(
    private vectorservice: VectorService,
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<CharactercreatorComponent>,
    // @Inject(MAT_DIALOG_DATA) public context: context
  ) { }

  ngOnInit(): void {
  }

  async import() {
    await MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");
    await MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");
    await new Promise(resolve => setTimeout(resolve, 100));
    let docvec = document.getElementById('characterset') as any; //group position
    let svg = docvec.querySelector('svg');
    let svgstring = svg.outerHTML;
    this.dialogRef.close(svgstring);
  }

  cancel() {
    this.dialogRef.close();
  }

  selectedvector(url) {
    this.svgcombi = undefined;
    this.selected = url;
    this.downloadFile(url).subscribe(async event => {
      if (event.type === HttpEventType.DownloadProgress) {
        // this.bufferValue = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        //console.log("donwload completed");
        let blobstring = await event.body.text();
        this.selectedstring = blobstring;
      }
    });
    // this.selectedSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  handleSVG(svg: SVGElement, parent): SVGElement {
    //console.log('Loaded SVG: ', svg, parent);
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    return svg;
  }

  handleSVGSmall(svg: SVGElement, parent): SVGElement {
    //console.log('Loaded SVG: ', svg, parent);
    svg.setAttribute('width', '80');
    svg.setAttribute('height', '80');
    return svg;
  }

  async changeHead() {
    this.hiderow = true;
    // convert all to paths
    let docvec = document.getElementById('characterset') as any; //group position
    let doc = docvec.querySelector('g');
    let box = doc.getBBox();
    // get size
    let height = box.height;
    let width = box.width;
    // get location
    let x = box.x;
    let y = box.y;

    this.downloadFile(this.selectedHairstyle.url).subscribe(async event => {
      if (event.type === HttpEventType.Response) {
        let blobstring = await event.body.text();

        // get scale
        let div = document.createElement('div');
        div.innerHTML = blobstring;
        let svghead = div.querySelector('svg');
        let headviewbox = svghead.getAttribute('viewBox');
        let origarray2 = headviewbox.split(' ');
        let orginalsize = { x: origarray2[0], y: origarray2[1], width: origarray2[2], height: origarray2[3] }
   
        await MorphSVGPlugin.convertToPath("circle, rect, ellipse, line, polygon, polyline");
        let resvg = await this.vectorservice.resizeVector(orginalsize, orginalsize, null, svghead); // also removes transforms on Paths!!
        svghead.outerHTML = resvg

        // new svg
        let grouphead = document.getElementById('head') as any;
        console.log(grouphead)
        let mainhead = grouphead.getBBox();
        let newx = mainhead.x;
        let newy = mainhead.y;

        let headcircle = svghead.getElementsByTagName('circle');
        for (let c = 0; c < headcircle.length; c++) {
          await MorphSVGPlugin.convertToPath(headcircle[c]);
          await new Promise(resolve => setTimeout(resolve, 20));
        }

        headcircle = svghead.getElementsByTagName('circle');
        for (let c = 0; c < headcircle.length; c++) {
          await MorphSVGPlugin.convertToPath(headcircle[c]);
          await new Promise(resolve => setTimeout(resolve, 20));
        }

        headcircle = svghead.getElementsByTagName('circle');
        for (let c = 0; c < headcircle.length; c++) {
          await MorphSVGPlugin.convertToPath(headcircle[c]);
          await new Promise(resolve => setTimeout(resolve, 20));
        }


        console.log(headcircle)
        // get the paths
        let headpaths = svghead.getElementsByTagName('path');
        // let headcircle = svghead.getElementsByTagName('circle');

        

        //console.log(paths)
        let combihead = ['<g id="head">'];
        // scale and transform paths
        for (let i = 0; i < headpaths.length; i++) {
          let path = headpaths[i].cloneNode() as SVGPathElement;
          let rawpath1 = await MotionPathPlugin.getRawPath(path);
          let rawpath = await MotionPathPlugin.transformRawPath(rawpath1, 1, 0, 0, 1, newx, newy);
          let stringd = await MotionPathPlugin.rawPathToString(rawpath);
          path.setAttribute('d', stringd);
          combihead.push(path.outerHTML);
          // add to curent svg
        }
        combihead.push('</g>');

        let fstring = combihead.join('');
        grouphead.outerHTML = fstring;
        this.changeSkinColor();
        this.hiderow = false;
      }
    });



    // let docstring = this.selectedstring;

    // let start = docstring.indexOf(Maleheadcodestart);
    // let end = docstring.indexOf(Maleheadcodeend);
    // // console.log(docstring)
    // console.log(start, end)
    // // console.log(Maleheadcodestart)
    // // console.log(Maleheadcodeend)

    // let SubString = docstring.substring(
    //   start + 1,
    //   end
    // );

    //console.log(SubString);
    //docstring = docstring.replace(SubString, '');
    //this.svgcombi = docstring.replace(SubString, '');
    //doc.innerHTML = docstring;
  }


  downloadFile(url): Observable<HttpEvent<any>> {
    return this.http.get(url, {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders()
    });
  }

  changeSkinColor() {
    if (!this.selectedskincolor) { return }
    let docvec = document.getElementById('characterset') as any; //group position
    let svg = docvec .querySelector('svg');
    let svgstring = svg.outerHTML;

    // find current color hex and replace
    for (let i = 0; i < this.skincolors.length; i++) {
      if (svgstring.indexOf(this.skincolors[i].hex) !== -1) {
        let figc = this.skincolors[i]; 
        svgstring = svgstring.replaceAll(figc.hex, this.selectedskincolor.hex);
        svgstring = svgstring.replaceAll(figc.hex1, this.selectedskincolor.hex1);
        svgstring = svgstring.replaceAll(figc.hex2, this.selectedskincolor.hex2);
        svgstring = svgstring.replaceAll(figc.hex3, this.selectedskincolor.hex3);
        svgstring = svgstring.replaceAll(figc.hex4, this.selectedskincolor.hex4);
        svgstring = svgstring.replaceAll(figc.hex5, this.selectedskincolor.hex5);
      }
    }
   // console.log(svgstring)
    svg.outerHTML = svgstring;
  }

  }
