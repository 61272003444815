<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <label>Upload Relation</label>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Company" [(ngModel)]="relationHeaders.relationnameheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Address 1" [(ngModel)]="relationHeaders.address1header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Address 2" [(ngModel)]="relationHeaders.address2header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select ZipCode" [(ngModel)]="relationHeaders.zipcodeheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select City" [(ngModel)]="relationHeaders.cityheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select State/Province" [(ngModel)]="relationHeaders.stateheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Country" [(ngModel)]="relationHeaders.countryheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Industry" [(ngModel)]="relationHeaders.industryheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Website" [(ngModel)]="relationHeaders.websiteheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Phone" [(ngModel)]="relationHeaders.phoneheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Status" [(ngModel)]="relationHeaders.statusheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div fxLayout="column">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select 3rd party Relation ID" [(ngModel)]="relationHeaders.ridheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <label style="color: red;">Important! if you need add calls and contactpersons to the relation
                        later</label>
                </div>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Succesreate" [(ngModel)]="relationHeaders.successheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Optional field" [(ngModel)]="relationHeaders.const1header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Optional field" [(ngModel)]="relationHeaders.const2header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Optional field" [(ngModel)]="relationHeaders.const3header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Optional field" [(ngModel)]="relationHeaders.const4header">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <mat-checkbox [(ngModel)]="addcontact">Add Contact</mat-checkbox>
        <div *ngIf="addcontact">

            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Firstname" [(ngModel)]="relationHeaders.firstnameheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select lastname" [(ngModel)]="relationHeaders.lastnameheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Email" [(ngModel)]="relationHeaders.emailheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select officephone" [(ngModel)]="relationHeaders.officephoneheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select cellphone" [(ngModel)]="relationHeaders.cellphoneheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Social media" [(ngModel)]="relationHeaders.socialmediaheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select IM" [(ngModel)]="relationHeaders.imheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select IM type" [(ngModel)]="relationHeaders.imtypeheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Linkedin" [(ngModel)]="relationHeaders.linkedinheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Twitter" [(ngModel)]="relationHeaders.twitterheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Facebook" [(ngModel)]="relationHeaders.facebookheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Instagram" [(ngModel)]="relationHeaders.instagramheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Pinterest" [(ngModel)]="relationHeaders.pinterestheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select function" [(ngModel)]="relationHeaders.functionheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Mr/Mrs/Mx" [(ngModel)]="relationHeaders.salutationheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select Direct Manager"
                            [(ngModel)]="relationHeaders.directmanagerheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="45%">
                    <mat-form-field style="width: 98%;">
                        <mat-select placeholder="Select level likeable" [(ngModel)]="relationHeaders.likeheader">
                            <mat-option *ngFor="let header of headers" [value]="header">
                                {{ header }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div>
            <button mat-raised-button (click)="uploadRelations()">Upload to Database</button>
        </div>


        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload small file (less then 500 entries)</h3>
                    <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)" (uploadedFile)="uploaderFileSet($event)">
                        <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                            {{ uploaderContent | async }}
                        </div>
                    </xlsx-file-upload>

                </div>
            </div>
        </div>
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <input type="file" style="display: inline-block;" (change)="xlsxUploaded($event)"
                    placeholder="Upload file" accept=".xlsx" />
            </div>
        </div> -->
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload large list</h3>
                    <div style="background-color:beige">
                        <div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>Please use standard xlsx format with the following header:</label>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>company, firstname, lastname, title, website, mailing</label>
                            </div>
                        </div>
                    </div>
                    <div ng2FileDrop [ngClass]="{ 'drop-zone-over': hasBaseDropZoneOver }"
                        (fileOver)="fileOverAnother($event)" [uploader]="uploader" class="drop-zone">
                        Drop Zone
                    </div>
                    <button mat-button (click)="fileInput.click()">Choose file</button>
                    <span
                        style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
                    </span>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div class="righ-upload-block">
                    <div>
                        <thead>
                            <div fxLayout="row" fxLayout.xs="column">
                                <th width="50%">Name</th>
                                <th>Progress</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </div>
                        </thead>
                        <tbody>
                            <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploader.queue">
                                <div fxFlex="45%">
                                    <strong>{{ item?.file?.name }}</strong>
                                </div>
                                <div fxFlex="45%" >
                                    <div style="margin-bottom: 0;">
                                        <mat-progress-bar color="primary" mode="determinate"
                                            [value]="item.progress">
                                        </mat-progress-bar>
                                    </div>
                                </div>
                                <div fxFlex="45%">
                                    <mat-icon *ngIf="item.isSuccess">done</mat-icon>
                                    <mat-icon *ngIf="item.isCancel">cancel</mat-icon>
                                    <mat-icon *ngIf="item.isError">remove_circle</mat-icon>
                                </div>
                                <div fxFlex="45%"nowrap>
                                    <button mat-button (click)="setupload(item?.file?.name)"
                                        [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                        <mat-icon>file_upload</mat-icon>Upload
                                    </button>
                                    <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                        <mat-icon>cancel</mat-icon> Cancel
                                    </button>
                                    <button mat-button (click)="item.remove()">
                                        <mat-icon class="iconsize-10">remove_circle</mat-icon> Remove
                                    </button>
                                </div>
                            </div>
                        </tbody>
                    </div>

                    <div>
                        <div>
                            Queue progress:
                            <div class="progress">
                                <div>
                                    <mat-progress-bar color="primary" mode="determinate" [value]="uploader.progress">
                                    </mat-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                   <button mat-button (click)="uploadFilelarge()" [disabled]="!newFiles.url">Start
                        Import</button>
                </div>
            </div>
        </div> -->
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <label>Upload Calls</label>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select title" [(ngModel)]="callsHeaders.titleheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Attendee" [(ngModel)]="callsHeaders.attendeeheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Call Type" [(ngModel)]="callsHeaders.calltypeheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Call Date" [(ngModel)]="callsHeaders.calldateheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Tasks" [(ngModel)]="callsHeaders.taskheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Notes" [(ngModel)]="callsHeaders.noteheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Follow up" [(ngModel)]="callsHeaders.followupheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Callback date" [(ngModel)]="callsHeaders.callbackheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Follow up done" [(ngModel)]="callsHeaders.followupdoneheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select done" [(ngModel)]="callsHeaders.doneheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select content" [(ngModel)]="callsHeaders.contentheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Goodcall" [(ngModel)]="callsHeaders.goodcallheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select 3rd party Relation ID" [(ngModel)]="callsHeaders.ridheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload small file (less then 500 entries)</h3>
                    <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)" (uploadedFile)="uploaderFileSet($event)">
                        <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                            {{ uploaderContent | async }}
                        </div>
                    </xlsx-file-upload>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button (click)="uploadCalls()">Upload to Database</button>
        </div>
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <input type="file" style="display: inline-block;" (change)="xlsxUploaded($event)"
                    placeholder="Upload file" accept=".xlsx" />
            </div>
        </div> -->
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload large list</h3>
                    <div style="background-color:beige">
                        <div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>Please use standard xlsx format with the following header:</label>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>company, firstname, lastname, title, website, mailing</label>
                            </div>
                        </div>
                    </div>
                    <div ng2FileDrop [ngClass]="{ 'drop-zone-over': hasBaseDropZoneOver }"
                        (fileOver)="fileOverAnother($event)" [uploader]="uploader" class="drop-zone">
                        Drop Zone
                    </div>
                    <button mat-button (click)="fileInput.click()">Choose file</button>
                    <span
                        style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
                    </span>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div class="righ-upload-block">
                    <div>
                        <thead>
                            <div fxLayout="row" fxLayout.xs="column">
                                <th width="50%">Name</th>

                                <th>Progress</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </div>
                        </thead>
                        <tbody>
                            <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploader.queue">
                                <div fxFlex="45%">
                                    <strong>{{ item?.file?.name }}</strong>
                                </div>
                                <div fxFlex="45%" >
                                    <div style="margin-bottom: 0;">
                                        <mat-progress-bar color="primary" mode="determinate"
                                            [value]="item.progress">
                                        </mat-progress-bar>
                                    </div>
                                </div>
                                <div fxFlex="45%">
                                    <mat-icon *ngIf="item.isSuccess">done</mat-icon>
                                    <mat-icon *ngIf="item.isCancel">cancel</mat-icon>
                                    <mat-icon *ngIf="item.isError">remove_circle</mat-icon>
                                </div>
                                <div fxFlex="45%"nowrap>
                                    <button mat-button (click)="setupload(item?.file?.name)"
                                        [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                        <mat-icon>file_upload</mat-icon>Upload
                                    </button>
                                    <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                        <mat-icon>cancel</mat-icon> Cancel
                                    </button>
                                    <button mat-button (click)="item.remove()">
                                        <mat-icon class="iconsize-10">remove_circle</mat-icon> Remove
                                    </button>
                                </div>
                            </div>
                        </tbody>
                    </div>

                    <div>
                        <div>
                            Queue progress:
                            <div class="progress">
                                <div>
                                    <mat-progress-bar color="primary" mode="determinate" [value]="uploader.progress">
                                    </mat-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
             <button mat-button (click)="uploadFilelarge()" [disabled]="!newFiles.url">Start
                        Import</button>
                </div>
            </div>
        </div> -->
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <label>Upload Contactperson</label>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Firstname" [(ngModel)]="contactpersonHeaders.firstnameheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select lastname" [(ngModel)]="contactpersonHeaders.lastnameheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Email" [(ngModel)]="contactpersonHeaders.emailheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select officephone" [(ngModel)]="contactpersonHeaders.officephoneheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select cellphone" [(ngModel)]="contactpersonHeaders.cellphoneheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Social media" [(ngModel)]="contactpersonHeaders.socialmediaheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select IM" [(ngModel)]="contactpersonHeaders.imheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select IM type" [(ngModel)]="contactpersonHeaders.imtypeheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Linkedin" [(ngModel)]="contactpersonHeaders.linkedinheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Twitter" [(ngModel)]="contactpersonHeaders.twitterheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Facebook" [(ngModel)]="contactpersonHeaders.facebookheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Instagram" [(ngModel)]="contactpersonHeaders.instagramheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Pinterest" [(ngModel)]="contactpersonHeaders.pinterestheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select function" [(ngModel)]="contactpersonHeaders.functionheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Mr/Mrs/Mx" [(ngModel)]="contactpersonHeaders.salutationheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select Direct Manager"
                        [(ngModel)]="contactpersonHeaders.directmanagerheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select level likeable" [(ngModel)]="contactpersonHeaders.likeheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="45%">
                <mat-form-field style="width: 98%;">
                    <mat-select placeholder="Select 3rd party Relation ID" [(ngModel)]="contactpersonHeaders.ridheader">
                        <mat-option *ngFor="let header of headers" [value]="header">
                            {{ header }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload small file (less then 500 entries)</h3>
                    <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)" (uploadedFile)="uploaderFileSet($event)">
                        <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                            {{ uploaderContent | async }}
                        </div>
                    </xlsx-file-upload>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button (click)="uploadContactpersons()">Upload to Database</button>
        </div>
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <input type="file" style="display: inline-block;" (change)="xlsxUploaded($event)"
                    placeholder="Upload file" accept=".xlsx" />
            </div>
        </div> -->
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div>
                    <h3>Upload large list</h3>
                    <div style="background-color:beige">
                        <div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>Please use standard xlsx format with the following header:</label>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column">
                                <label>company, firstname, lastname, title, website, mailing</label>
                            </div>
                        </div>
                    </div>
                    <div ng2FileDrop [ngClass]="{ 'drop-zone-over': hasBaseDropZoneOver }"
                        (fileOver)="fileOverAnother($event)" [uploader]="uploader" class="drop-zone">
                        Drop Zone
                    </div>
                    <button mat-button (click)="fileInput.click()">Choose file</button>
                    <span
                        style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
                    </span>
                </div>
            </div>
        </div> -->
        <!-- <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="45%">
                <div class="righ-upload-block">
                    <div>
                        <thead>
                            <div fxLayout="row" fxLayout.xs="column">
                                <th width="50%">Name</th>
                                <th>Progress</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </div>
                        </thead>
                        <tbody>
                            <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploader.queue">
                                <div fxFlex="45%">
                                    <strong>{{ item?.file?.name }}</strong>
                                </div>
                                <div fxFlex="45%" >
                                    <div style="margin-bottom: 0;">
                                        <mat-progress-bar color="primary" mode="determinate"
                                            [value]="item.progress">
                                        </mat-progress-bar>
                                    </div>
                                </div>
                                <div fxFlex="45%">
                                    <mat-icon *ngIf="item.isSuccess">done</mat-icon>
                                    <mat-icon *ngIf="item.isCancel">cancel</mat-icon>
                                    <mat-icon *ngIf="item.isError">remove_circle</mat-icon>
                                </div>
                                <div fxFlex="45%"nowrap>
                                    <button mat-button (click)="setupload(item?.file?.name)"
                                        [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                        <mat-icon>file_upload</mat-icon>Upload
                                    </button>
                                    <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                        <mat-icon>cancel</mat-icon> Cancel
                                    </button>
                                    <button mat-button (click)="item.remove()">
                                        <mat-icon class="iconsize-10">remove_circle</mat-icon> Remove
                                    </button>
                                </div>
                            </div>
                        </tbody>
                    </div>

                    <div>
                        <div>
                            Queue progress:
                            <div class="progress">
                                <div>
                                    <mat-progress-bar color="primary" mode="determinate" [value]="uploader.progress">
                                    </mat-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button mat-button (click)="uploadFilelarge()" [disabled]="!newFiles.url">Start
                        Import</button>
                </div>
            </div>
        </div> -->
    </mat-expansion-panel>
</mat-accordion>