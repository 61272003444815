
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompanyApi, Relations, RelationsApi, Calls, Contactpersons, Formentries, FormentriesApi, FormsApi } from 'src/app/shared/sdk';

@Component({
  selector: 'app-formentrydetails',
  templateUrl: './formentrydetails.component.html',
  styleUrls: ['./formentrydetails.component.scss']
})
export class FormentrydetailsComponent implements OnInit {
  entry: Formentries;
  message: any;
  newrelation: Relations = new Relations();
  newcall: Calls = new Calls();
  newcontactperson: Contactpersons = new Contactpersons();
  myControl = new UntypedFormControl();
  public option: Relations;
  public options = [];
  filteredOptions: Observable<string[]>;
  company: any;
  addContactperson = false;
  addCall = false;
  addRelation = false;
  copyformtocontent: false;

  constructor(
    private formApi: FormsApi,
    private formEntriesApi: FormentriesApi,
    private matDialog: MatDialog,
    private companyApi: CompanyApi,
    private relationsApi: RelationsApi,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.entry = this.data.entry;
    this.message = this.data.message;
    //this.options = this.data.options;
    this.company = this.data.company;
    this.companyApi.getRelations(this.company.id, {
      fields: { id: true, relationname: true }
    }
    ).subscribe((relations: Relations[]) => {
      this.options = relations;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          //map(options => options && typeof options === 'object' ? options.relationname : options),
          map(relationname => relationname ? this.filter(relationname) : this.options.slice())
        );
    })

  }

  checkRelation() {
    this.entry.formcontent.forEach(element => {
      let elementtext = element.text.toLowerCase();
      if (elementtext.indexOf('company') !== -1) { this.newrelation.relationname = element.value }
      if (elementtext.indexOf('date') !== -1) { this.newcall.date = element.value; }
      if (elementtext.indexOf('email') !== -1) { this.newcall.email = element.value; this.newcontactperson.email = element.value; }

      if (elementtext.indexOf('firstname') !== -1) { this.newcontactperson.firstname = element.value; }
      if (elementtext.indexOf('lastname') !== -1) { this.newcontactperson.lastname = element.value; }
      if (elementtext.indexOf('firstname') === -1 && elementtext.indexOf('name') !== -1 && elementtext.indexOf('lastname') === -1) { this.newcontactperson.lastname = element.value; }
      if (elementtext.indexOf('phone') !== -1) { this.newcontactperson.cellphone = element.value; }

      if (elementtext.indexOf('comment') !== -1) { this.newcall.content = element.value; }
      if (elementtext.indexOf('content') !== -1) { this.newcall.content = element.value; }
      if (elementtext.indexOf('question') !== -1) { this.newcall.date = element.value; }

      // element.text;
      // element.value;
    });
  }


  save() {
    this.newrelation.companyId = this.company.id;
    this.newcall.companyId = this.company.id;
    this.newcontactperson.companyId = this.company.id;

    if (this.addRelation) {
      this.companyApi.createRelations(this.company.id, this.newrelation).subscribe((relation: Relations) => {
        this.newrelation = relation;
        this.createCallsContact();
        this.updateFormEntry()
      })
    } else {
      this.createCallsContact();
      this.updateFormEntry()
    }

  }

  createCallsContact() {
    if (this.newrelation.id) {
      if (this.addCall) { this.createCall(this.newrelation.id) }
      if (this.addContactperson) { this.createContactperson(this.newrelation.id) }
    } else {
      console.error('missing relationId');
    }
  }

  createCall(id) {
    this.newcall.calltype = 'form entry';
    this.newcall.title = 'form entry ' + this.entry.date;

    if (this.copyformtocontent) {

      let message = '';
      this.entry.formcontent.forEach(element => {
        message = message + '<label class="mat-typography">' + element.text + ': ' + element.value + '</label><br>';
      });
      this.newcall.html = message;
    }
    this.relationsApi.createCalls(id, this.newcall).subscribe(res => { }, error => console.log(error))
  }

  createContactperson(id) {
    this.relationsApi.createContactpersons(id, this.newcontactperson).subscribe(res => { }, error => console.log(error))
  }

  updateFormEntry() {
    this.entry.processed = true;
    this.formApi.updateByIdFormentries(this.entry.formsId, this.entry.id, this.entry).subscribe(res => { }, error => console.log(error));
    this.matDialog.closeAll();
  }


  displayFn(options): string {
    return options ? options.relationname : options;
  }

  filter(relationname: string) {
    if (relationname) {
      return this.options.filter(option =>
        option.relationname.toLowerCase().indexOf(relationname.toLowerCase()) === 0);
    }
  }


  setAddress(addrObj) {
    console.log(addrObj);
    if (addrObj.name !== undefined) {
      this.newrelation.relationname = addrObj.name;
    }
    if (addrObj.route !== undefined) {
      this.newrelation.address1 = addrObj.route + " " + addrObj.street_number;
    } else {
      this.newrelation.address1 = '';
    }
    if (addrObj.phone !== undefined) {
      this.newrelation.generalphone = addrObj.phone;
    } else {
      this.newrelation.generalphone = '';
    }
    if (addrObj.country !== undefined) {
      this.newrelation.country = addrObj.country;
    } else {
      this.newrelation.country = '';
    }
    if (addrObj.locality !== undefined) {
      this.newrelation.city = addrObj.locality;
    } else {
      this.newrelation.city = '';
    }
    if (addrObj.admin_area_l1 !== undefined) {
      this.newrelation.stateprovince = addrObj.admin_area_l1;
    } else {
      this.newrelation.stateprovince = '';
    }
    if (addrObj.postal_code !== undefined) {
      this.newrelation.zipcode = addrObj.postal_code;
    } else {
      this.newrelation.zipcode = '';
    }
    if (addrObj.website !== undefined) {
      this.newrelation.website = addrObj.website;
    } else {
      this.newrelation.website = '';
    }
    //this.showAddress();
  }


}
