<div class="listshowcontainer" fxShow fxHide.gt-xs>
    <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
</div>

<div class="speedsidebuttoncontainer" [class.speedsidebuttoncontainersideopen]="sideopen === true">
    <speed-dial-fab class="speed-dial-video" [reverse-column-direction]="false"
        (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons" direction="row">
    </speed-dial-fab>
    <!-- i18n-matTooltip matTooltip="Add new item" -->
</div>

<div class="leftsidebuttoncontainer">
    <mat-icon (click)="sideToggle()" *ngIf="!sideopen" class="menubutton" i18n-matTooltip
        matTooltip="Open Elements Menu">view_in_ar_new
    </mat-icon>
</div>


<div class="videocontrols">
    <br>
    <div fxLayout="row">
        <label fxFlex="50px">{{ currenttime | number : '1.2-2'}}</label>
        <div class="timelinesmall-container" [style.width]="'600px'">
            <div class="timelinesmall-active" [style.width]="counter + 'px'" #timelinebound id="timelinebound">
                <!-- [style.transform]="'translate(' + currenttime + 'px, 0px)'" -->
                <div class="timelinesmall-setter" id="timelinesmallsetter" #timelinesmallsetter
                    (movingOffset)="onMovingTimeline($event)" [inBounds]="true" [bounds]="timelinebound"
                    [lockAxis]="'y'" ngDraggable [scale]="'1'"></div>
            </div>
        </div>
        <label fxFlex="50px">{{counter | number : '1.2-2'}}</label>
    </div>
    <br>
    <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center start">

        <button mat-mini-fab color="accent" [disabled]="systembusy" (click)="detectchange()" i18n-matTooltip
            matTooltip="Render">
            <mat-icon>published_with_changes</mat-icon>
        </button>
        <button mat-mini-fab color="primary" [disabled]="systembusy" (click)="playFunc()" i18n-matTooltip
            matTooltip="Play">
            <mat-icon>play_circle_outline</mat-icon>
        </button>
        <button mat-mini-fab color="primary" [disabled]="systembusy" (click)="stopFunc()" i18n-matTooltip
            matTooltip="Stop">
            <mat-icon>stop</mat-icon>
        </button>
        <button mat-mini-fab color="primary" [disabled]="systembusy" (click)="pauseFunc()" i18n-matTooltip
            matTooltip="Pause">
            <mat-icon>pause_circle_outline</mat-icon>
        </button>
        <button mat-mini-fab color="primary" [disabled]="systembusy" (click)="reverseFunc()" i18n-matTooltip
            matTooltip="Fast rewind">
            <mat-icon>fast_rewind</mat-icon>
        </button>
        <button mat-mini-fab color="primary" [disabled]="systembusy" (click)="fastforwardFunc()" i18n-matTooltip
            matTooltip="Fast forware">
            <mat-icon>fast_forward</mat-icon>
        </button>
        <button *ngIf="setreplay === false" mat-mini-fab color="primary" [disabled]="systembusy" (click)="setReplay()"
            i18n-matTooltip matTooltip="Toggle replay" color="primary">
            <mat-icon>replay</mat-icon>
        </button>
        <button *ngIf="setreplay === true" mat-mini-fab color="primary" [disabled]="systembusy" (click)="setReplay()"
            i18n-matTooltip matTooltip="Toggle replay" color="accent">
            <mat-icon>replay</mat-icon>
        </button>
        <button mat-mini-fab color="primary" (click)="deselectAll()" i18n-matTooltip matTooltip="deselect all"
            (click)="fastforwardFunc()">
            <mat-icon> check_box_outline_blank</mat-icon>
        </button>
        <!-- <mat-form-field appearance="outline" style="width: 100px;">
            <mat-select [(ngModel)]="zoomfactor" (selectionChange)="onchangecanvas()" i18n-placeholder placeholder="Zoom"
                [formControl]="zoomForm">
                <mat-option value="1">100%</mat-option>
                <mat-option value="0.9">90%</mat-option>
                <mat-option value="0.8">80%</mat-option>
                <mat-option value="0.7">70%</mat-option>
                <mat-option value="0.6">60%</mat-option>
                <mat-option value="0.5">50%</mat-option>
                <mat-option value="0.4">40%</mat-option>
                <mat-option value="0.3">30%</mat-option>
                <mat-option value="0.2">20%</mat-option>
                <mat-option value="0.1">10%</mat-option>
                <mat-option value="1.5">150%</mat-option>
                <mat-option value="2">200%</mat-option>
                <mat-option value="4">400%</mat-option>
                <mat-option value="6">600%</mat-option>
            </mat-select>
        </mat-form-field> -->

    </div>
    <br>
</div>

<!-- TOP BANNER -->
<div class="toolsbanner" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start start">
    <button *ngIf="name" mat-button disabled="true" i18n>Project name: {{name}}</button>
    <button *ngIf="!name" mat-button disabled="true" i18n>Project name: Unknown</button>
    <button mat-button (click)="openDialogSettings()" i18n>General Settings</button>
    <button mat-button (click)="openDialogBackgroundSettings()" i18n>Background/Audio Settings</button>
    <button mat-icon-button i18n-matTooltip matTooltip="Open animation screen" (click)="openSplitScreen()">
        <mat-icon>vertical_split</mat-icon>
    </button>
    <!-- class="generalprogressbar" -->
    <div *ngIf="systembusy">
        <mat-icon class="iconsize-10">hourglass_empty</mat-icon>
        <label i18n>Working on it...</label>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>


    <div fxLayout="row wrap" style="overflow: visible;">



        <!-- <div *ngIf="selectedelement && selectedelement.type === 'threed'" fxLayout="row" fxLayoutGap="10px">
            <mat-slide-toggle (click)="checkDragCamera('drag')"
            [checked]="globalThreedControls.dragcontrol">
                Drag Control</mat-slide-toggle>
            <mat-slide-toggle (click)="checkDragCamera('draggroup')"
            [checked]="globalThreedControls.dragcontrolgroup">
                Drag Control Group</mat-slide-toggle>
            <mat-slide-toggle (click)="checkDragCamera('rotate')"
            [checked]="globalThreedControls.rotatecontrol">
                Rotate Control</mat-slide-toggle>
            <mat-slide-toggle (click)="checkDragCamera('camera')" 
            [checked]="canvas.cameracontrol">
                Camera Control</mat-slide-toggle>

               
        </div> -->

        <div *ngIf="selectedelement && selectedelement.type === 'vector'" fxLayout="row">
            <!-- selectedVecPath &&  -->
            <!-- <mat-slide-toggle [(ngModel)]="vectorService.editsvg" i18n-matTooltip matTooltip="Edit Image">Edit mode</mat-slide-toggle> -->

            <button mat-raised-button (click)="toggleVectorImageEdit()" *ngIf="!vectorService.editsvg" i18n-matTooltip
                matTooltip="Edit Image">Edit mode</button>
            <button mat-raised-button (click)="toggleVectorImageEdit()" color="primary" *ngIf="vectorService.editsvg"
                i18n-matTooltip matTooltip="Edit Image off">Edit mode</button>

            <div *ngIf="vectorService.editsvg" class="vectorcontrols">

                <button mat-stroked-button (click)="deleteSelectedVectorPath(selectedelement)" i18n-matTooltip
                    matTooltip="Delete section">
                    <mat-icon class="iconsize-18"> delete</mat-icon>
                </button>
                <button mat-stroked-button (click)="deleteWhitespaceSVG()" i18n-matTooltip
                    matTooltip="delete whitespace">
                    <mat-icon class="icon-label" class="iconsize-18">check_box_outline_blank</mat-icon>
                </button>
                <button mat-stroked-button (click)="seperatePaths()" i18n-matTooltip
                    matTooltip="Create seperate section">
                    <mat-icon class="iconsize-18">image</mat-icon>
                </button>
                <button mat-stroked-button (click)="saveAsNewVector()" i18n-matTooltip
                    matTooltip="save to file as new image">
                    <mat-icon class="icon-label" class="iconsize-18">add_to_photos</mat-icon>
                </button>
                <button mat-stroked-button *ngIf="vectorService.editsvg" (click)="saveFigurePath(selectedelement)"
                    i18n-matTooltip matTooltip="save Figure">
                    <mat-icon class="icon-label" class="iconsize-20">save</mat-icon>
                </button>
                <button mat-stroked-button (click)="vectorService.removeVectorPathMultiSelection(selectedelement)"
                    i18n-matTooltip matTooltip="Remove selection">
                    <mat-icon class="icon-label" class="iconsize-18">indeterminate_check_box</mat-icon>
                </button>

                <button mat-stroked-button (click)="vectorService.editFigurePath(selectedelement)" i18n-matTooltip
                    matTooltip="Change shape path"
                    [style.background-color]="vectorService.editfigure ? 'lightblue' : null">
                    <mat-icon class="iconsize-18">format_shapes</mat-icon>
                </button>

                <button mat-stroked-button (click)="vectorService.toggleRotatePath(selectedelement)" i18n-matTooltip
                    matTooltip="Rotate path" [style.background-color]="vectorService.rotatepath ? 'lightblue' : null">
                    <mat-icon class="iconsize-18">rotate_90_degrees_cw</mat-icon>
                </button>
                <button mat-stroked-button (click)="vectorService.setDragSelect(selectedelement)" i18n-matTooltip
                    matTooltip="drag selection tool"
                    [style.background-color]="vectorService.dragselectvectpath ? 'lightblue' : null">
                    <mat-icon class="iconsize-18"> select_all</mat-icon>
                </button>
                <button mat-stroked-button i18n-matTooltip matTooltip="selected multiple sections"
                    (click)="vectorService.selectMultiplePaths(selectedelement)"
                    [style.background-color]="vectorService.selectmultiplepaths ? 'lightblue' : null">
                    <mat-icon class="iconsize-18">dynamic_feed</mat-icon>
                </button>
                <button mat-stroked-button i18n-matTooltip matTooltip="Automate change path color"
                    (click)="vectorService.createColorPathAnimation(selectedelement)">
                    <mat-icon class="iconsize-18">format_paint</mat-icon>
                </button>
                <button mat-stroked-button i18n-matTooltip matTooltip="Create Path Animation"
                    (click)="createPathAnimation(selectedelement)">
                    <mat-icon class="iconsize-18">animation</mat-icon>
                </button>
                <button mat-stroked-button i18n-matTooltip matTooltip="Bring selected path to front"
                    (click)="vectorService.moveNodeUp(selectedelement)">
                    <mat-icon class="iconsize-18">keyboard_double_arrow_up</mat-icon>
                </button>
                <button mat-stroked-button i18n-matTooltip matTooltip="Bring selected path to front"
                    (click)="vectorService.moveNodeDown(selectedelement)">
                    <mat-icon class="iconsize-18">keyboard_double_arrow_down</mat-icon>
                </button>


            </div>

            <div *ngIf="!vectorService.selectmultiplepaths && vectorService.selectedVecPath && vectorService.editsvg"
                fxLayout="row">

                <mat-form-field appearance="outline" appearance="outline" style="width: 40px; height: 20px;">
                    <input matInput autocomplete="off" style="width: 20px; height: 20px;" i18n-matTooltip
                        matTooltip="Linewidth" [(ngModel)]="linewidth"
                        (change)="vectorService.setlinewidth(linewidth)" />
                </mat-form-field>
                <input style="width: 20px; height: 20px;" [style.background]="vectorService.colorpickline"
                    [(colorPicker)]="vectorService.colorpickline"
                    (colorPickerChange)="vectorService.setColorPathLine(vectorService.colorpickline)" *ngIf="company"
                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]" />
                <input style="width: 20px; height: 20px;" [style.background]="vectorService.colorpick"
                    [(colorPicker)]="vectorService.colorpick"
                    (colorPickerChange)="vectorService.setColorPath(vectorService.colorpick)" *ngIf="company"
                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]" />
            </div>
        </div>

        <div *ngIf="editpath && selectedelement.type !== 'threed'" class="pathcontrols" i18n-matTooltip
            matTooltip="
                Hold Alt and click on the path to add points.
                Hold Alt and click on a point to toggle it between being smooth or a corner. 
                Hold Shift and click on a point to toggle a point's selection. Click Delete with an anchor selected to delete it.">
            <mat-form-field appearance="outline">
                <!-- (selectionChange)=" resetPath(selectedanimation)" -->
                <mat-select [(ngModel)]="standardpath" i18n-placeholder placeholder="Animation">
                    <mat-option value="horizontal-line" i18n>Horizontal Line</mat-option>
                    <mat-option value="vertical-line" i18n>Vertical Line</mat-option>
                    <mat-option value="circle" i18n>Circle</mat-option>
                    <mat-option value="square" i18n>Square</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <button mat-button (click)="editMotionPath(selectedanimation)">Edit path</button> -->
            <button mat-button (click)="resetPath(selectedanimation)" i18n>Set path</button>
            <button mat-button (click)="saveNewMotionPath()" i18n>Save path</button>
            <button mat-icon-button (click)="enlargePath()" i18n-matTooltip matTooltip="enlarge size path">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-icon-button (click)="reduceSizePath()" i18n-matTooltip matTooltip="reduce size path">
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>


        <div *ngIf="editpath && selectedelement.type === 'threed'" class="pathcontrols" i18n-matTooltip
            matTooltip="
                Hold Alt and click on the path to add points.
                Hold Alt and click on a point to toggle it between being smooth or a corner. 
                Hold Shift and click on a point to toggle a point's selection. Click Delete with an anchor selected to delete it.">
            <mat-form-field appearance="outline">
                <!-- (selectionChange)="resetPath(selectedanimation)" -->
                <mat-select [(ngModel)]="standardpath" i18n-placeholder placeholder="Animation">
                    <mat-option value="horizontal-line" i18n>Horizontal Line</mat-option>
                    <mat-option value="vertical-line" i18n>Vertical Line</mat-option>
                    <mat-option value="circle" i18n>Circle</mat-option>
                    <mat-option value="square" i18n>Square</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <button mat-button (click)="editMotionPath(selectedanimation)">Edit path</button> -->

            <button mat-button (click)="resetPath(selectedanimation)" i18n>Set path</button>
            <button mat-button (click)="saveNewThreedMotionPath()" i18n>Save path</button>
            <button mat-button (click)="createYmotionPath()" i18n>Set Height path</button>
            <button mat-icon-button (click)="enlargePath()" i18n-matTooltip matTooltip="enlarge size path">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-icon-button (click)="reduceSizePath()" i18n-matTooltip matTooltip="reduce size path">
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>



        <div *ngIf="selectedelement">
            <div class="vectorcontrols"
                *ngIf="selectedelement.type === 'vectorcombi' || selectedelement.groupmember || vectorcombiedit">
                <mat-slide-toggle [(ngModel)]="vectorcombiedit" i18n-matTooltip matTooltip="Move seperate sections"
                    i18n>Edit
                </mat-slide-toggle>
            </div>
        </div>
    </div>


    <div>
        <button mat-icon-button [matMenuTriggerFor]="menumarketing" class="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menumarketing="matMenu">
            <!-- <button mat-menu-item (click)="resetVideo()">
                <mat-icon>delete</mat-icon>
                <span>Reset</span>
            </button> -->
            <button mat-menu-item (click)="addSceneToVideo()">
                <mat-icon>note_add</mat-icon>
                <span i18n>Add scene to video</span>
            </button>
            <button mat-menu-item (click)="newVideo()">
                <mat-icon>note_add</mat-icon>
                <span i18n>New</span>
            </button>
            <app-fromfile *ngIf="!canvas.videourl || !canvas['background-image']" [account]="Account" [option]="option"
                (seteditvideo)="loadVideoGallery($event)" (templateurl)="setTemplate($event)">
            </app-fromfile>

            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Convert to video"
                (click)="converttovideo()">
                <mat-icon>video_library</mat-icon>
                <span i18n>Convert to video</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Get embedded code"
                (click)="createVideoCodeSnippet()">
                <mat-icon>code</mat-icon>
                <span i18n>Embedded Code</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Save" (click)="saveVideo()">
                <mat-icon>save</mat-icon>
                <span i18n>Save</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Save as" (click)="saveVideoAs()">
                <mat-icon>save</mat-icon>
                <span i18n>Save As</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Save image as PNG" (click)="saveImage()">
                <mat-icon>image</mat-icon>
                <span i18n>Create PNG Image</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Save image as JPEG"
                (click)="saveImageJPEG()">
                <mat-icon>image</mat-icon>
                <span i18n>Create JPEG Image</span>
            </button>

            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Convert to GIF"
                (click)="converttogif(false)" [disabled]="counter > 61">
                <mat-icon>gif</mat-icon>
                <span i18n>Convert to GIF</span>
            </button>
            <button mat-menu-item color="primary" i18n-matTooltip matTooltip="Convert to GIF (reduced size)"
                (click)="converttogif(true)" [disabled]="counter > 61">
                <mat-icon>gif</mat-icon>
                <span i18n>Convert to small GIF</span>
            </button>
            <button mat-menu-item (click)="downloadAsJSON()">
                <mat-icon>code</mat-icon>
                <span i18n>Download as File</span>
            </button>
            <button mat-menu-item (click)="loadAsJSON()">
                <mat-icon>code</mat-icon>
                <span i18n>Load from local File</span>
            </button>
            <!-- <mat-form-field appearance="outline">
                <mat-label>Edit video</mat-label>
                <mat-select [(ngModel)]="editablevideo" (selectionChange)="loadVideo()">
                    <mat-option *ngFor="let editvideo of editablevideos" [value]="editvideo">
                        {{editvideo.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <!-- <button mat-menu-item (click)="loadEditableVideo()">Start edit</button> -->
        </mat-menu>
    </div>
</div>

<!-- MAIN -->
<div class="tabcontainer" cdkDropListGroup (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
    <!-- to do: add magic wand toosl -->

    <mat-drawer-container>
        <mat-drawer #drawer mode="side" [opened]="sideopen" class="sitebar">
            <div fxLayout="row" fxLayoutAlign="space-between start" class="sidebar-title">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>view_in_ar_new</mat-icon>
                    <h2 i18n>Elements</h2>
                </div>


                <mat-icon style="margin-top: 20px;" (click)="sideToggle()" i18n>close</mat-icon>
            </div>

            <div fxLayout="column" style="height: 70vh; overflow: scroll; margin-top: 65px;">
                <!-- <label>Edit</label> -->

                <div fxLayout="column" fxLayout.xs="column">
                    <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
                        <mat-expansion-panel *ngFor="let element of animationarray; let i = index" cdkDrag
                            (click)="onSelectElement($event, element)" [expanded]="element === selectedelement">
                            <mat-expansion-panel-header *ngIf="element.type === 'image' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">
                                    <img *ngIf="element.src" src={{element.src}} class="elementsedit"
                                        style="height: 30px; width: auto;">
                                    <mat-icon *ngIf="!element.src">image</mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                        <button mat-menu-item (click)="convertImagatoThreed()" i18n-matTooltip
                                            matTooltip="Convert to 3D image">
                                            <mat-icon>3d_rotation</mat-icon>
                                            <span i18n>Convert to 3D object</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'image' && !element.groupmember" fxLayout="column">
                                <!-- <mat-checkbox [(ngModel)]="cropimages"
                                (change)="element.style['clip-path'] = ''"
                                    i18n-matTooltip matTooltip="Crop image to size">Crop</mat-checkbox> -->
                                <app-fileupload *ngIf="!element.src" [account]="Account" [option]="option"
                                    (imgurl)="setBlob($event, i)">
                                </app-fileupload>

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select crop shape</mat-label>
                                    <mat-select [(ngModel)]="standardpath" (selectionChange)="resetImageCropPath()"
                                        i18n-placeholder placeholder="Crop shape">
                                        <mat-option value="none" i18n>None</mat-option>
                                        <mat-option value="circle" i18n>Circle</mat-option>
                                        <mat-option value="square" i18n>Square</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div fxLayout="row wrap" fxLayoutGap="20px" *ngIf="standardpath">
                                    <button mat-raised-button (click)="imageCropPath()" i18n>Crop/Edit</button>
                                    <button mat-raised-button (click)="imageSaveCropPath()">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button mat-raised-button (click)="imageRemoveCrop()">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around start">
                                    <mat-slide-toggle (change)="detectchange()" [(ngModel)]="element.grey"
                                        i18n>Greyscale
                                    </mat-slide-toggle>
                                    <mat-slide-toggle (change)="detectchange()" [(ngModel)]="element.blur" i18n>Blur
                                    </mat-slide-toggle>
                                    <mat-slide-toggle (change)="setBoxShadow()" [(ngModel)]="boxshadow" i18n>Shadow
                                    </mat-slide-toggle>
                                </div>
                                <br>
                                <div fxLayout="row" fxLayoutAlign="space-around start">
                                    <button mat-raised-button (click)="removeBackground()">Remove Background</button>
                                    <button mat-raised-button (click)="blurBackground()">Blur Background</button>
                                </div>
                                <br>
                                <button mat-raised-button (click)="removeBackground(true)">Remove Background
                                    smooth</button>
                                <button mat-raised-button (click)="ObjectDetection()">Object Detection</button>
                                <br>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label>Y |</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.posy" type="number"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label>X -</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.posx" type="number"
                                            matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Height</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.style.height" matInput>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Width</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.style.width" matInput>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="outline">
                                    <input (change)="detectchange()" type="number" [(ngModel)]="element.opacity"
                                        matInput min="0" max="1" step="0.1">
                                    <mat-label i18n>opacity</mat-label>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select hover animation</mat-label>
                                    <mat-select [(ngModel)]="element.onhoveraction">
                                        <mat-option *ngFor="let action of hoveractions" [value]="action.value">
                                            <label>{{action.name}}</label>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onclickurl" matInput>
                                    <mat-label i18n>Navigation URL</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onhovershow" matInput>
                                    <mat-label i18n>Show Text on hover</mat-label>
                                </mat-form-field>

                                <div *ngIf="element.src">
                                    <img style="max-width: 100%;" src={{element.src}} class="elementsedit">
                                    <mat-icon (click)="element.src = ''" class="icon-label" class="iconsize-12">
                                        delete
                                    </mat-icon>
                                </div>
                            </div>
                            <mat-expansion-panel-header *ngIf="element.type === 'embeddedvideo' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">

                                    <mat-icon>video_library</mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                        <button mat-menu-item (click)="convertVideotoThreed()" i18n-matTooltip
                                            matTooltip="Convert to 3D image">
                                            <mat-icon>3d_rotation</mat-icon>
                                            <span i18n>Convert to 3D object</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'embeddedvideo' && !element.groupmember" fxLayout="column">

                                <app-videoupload *ngIf="!element.src" [account]="Account" [option]="option"
                                    (imgurl)="setblobEmbeddedvideo($event)">
                                </app-videoupload>


                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select Effects</mat-label>
                                    <mat-select [(ngModel)]="element.effect" i18n-placeholder
                                        placeholder="Video Effects">
                                        <mat-option value="none">None</mat-option>
                                        <mat-option value="shockwave">Shockwave</mat-option>
                                        <mat-option value="glitch">Glitch</mat-option>
                                        <mat-option value="matrix">Matrix</mat-option>
                                        <mat-option value="pond">Pond</mat-option>
                                        <mat-option value="oldfilm">Old Movie</mat-option>

                                    </mat-select>
                                </mat-form-field>


                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select crop shape</mat-label>
                                    <mat-select [(ngModel)]="standardpath" (selectionChange)="resetImageCropPath()"
                                        i18n-placeholder placeholder="Crop shape">
                                        <mat-option value="none" i18n>None</mat-option>
                                        <mat-option value="circle" i18n>Circle</mat-option>
                                        <mat-option value="square" i18n>Square</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div fxLayout="row wrap" fxLayoutGap="20px" *ngIf="standardpath">
                                    <button mat-raised-button (click)="imageCropPath()">Crop/Edit</button>
                                    <button mat-raised-button (click)="imageSaveCropPath()">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button mat-raised-button (click)="imageRemoveCrop()">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around start">
                                    <mat-slide-toggle (change)="detectchange()" [(ngModel)]="element.grey"
                                        i18n>Greyscale
                                    </mat-slide-toggle>
                                    <mat-slide-toggle (change)="detectchange()" [(ngModel)]="element.blur" i18n>Blur
                                    </mat-slide-toggle>
                                    <mat-slide-toggle (change)="setBoxShadow()" [(ngModel)]="boxshadow" i18n>Shadow
                                    </mat-slide-toggle>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label>Y |</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.posy" type="number"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label>X -</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.posx" type="number"
                                            matInput>
                                    </mat-form-field>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Height</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.style.height" matInput>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Width</mat-label>
                                        <input (change)="detectchange()" [(ngModel)]="element.style.width" matInput>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="outline">
                                    <input (change)="detectchange()" type="number" [(ngModel)]="element.style.opacity"
                                        matInput min="0" max="1" step="0.1">
                                    <mat-label i18n>opacity</mat-label>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select hover animation</mat-label>
                                    <mat-select [(ngModel)]="element.onhoveraction">
                                        <mat-option *ngFor="let action of hoveractions" [value]="action.value">
                                            <label>{{action.name}}</label>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onclickurl" matInput>
                                    <mat-label i18n>Navigation URL</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onhovershow" matInput>
                                    <mat-label i18n>Show Text on hover</mat-label>
                                </mat-form-field>

                                <!-- <div *ngIf="element.src">
                                    <img style="max-width: 100%;" src={{element.src}} class="elementsedit">
                                    <mat-icon (click)="element.src = ''" class="icon-label" class="iconsize-12">
                                        delete
                                    </mat-icon>
                                </div> -->
                            </div>
                            <mat-expansion-panel-header *ngIf="element.type === 'counter' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">
                                    Counter {{element.maxcount}}
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'counter' && !element.groupmember" fxLayout="column">
                                <div fxLayoutGap="20px">
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.maxcount" type="number"
                                            matInput>
                                        <mat-label i18n>Value</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.countertype.startvalue"
                                            type="number" matInput>
                                        <mat-label i18n>Start value</mat-label>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column">
                                    <mat-form-field appearance="outline">
                                        <input [(ngModel)]="element.style['font-size']" type="text" matInput
                                            i18n-placeholder placeholder="font size" (change)="detectchange()">
                                    </mat-form-field>
                                </div>
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Background color
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <span style="width: 60px;" [style.background]="element.style['color']"
                                            cpDialogDisplay="inline" [(colorPicker)]="element.style['color']"
                                            (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                            *ngIf="company" [cpToggle]="true"
                                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </span>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <br>
                                <div fxLayout="row" fxLayout.xs="column">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Select font</mat-label>
                                        <mat-select [(ngModel)]="element.style['font-family']"
                                            (selectionChange)="detectchange()">
                                            <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                <label [style.font-family]="font.value">{{font.name}}</label>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayoutGap="20px">
                                    <mat-form-field appearance="outline">
                                        <input [(ngModel)]="element.prefix" matInput>
                                        <mat-label i18n>Prefix</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input [(ngModel)]="element.suffix" matInput>
                                        <mat-label i18n>Suffix</mat-label>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="element.separator" i18n-placeholder
                                        placeholder="Seperator">
                                        <mat-option value="," i18n>Comma</mat-option>
                                        <mat-option value="." i18n>Dot</mat-option>
                                        <mat-option value="" i18n>None</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="element.decimal" i18n-placeholder placeholder="Seperator">
                                        <mat-option value="," i18n>Comma</mat-option>
                                        <mat-option value="." i18n>Dot</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <mat-slide-toggle [(ngModel)]="element.useGrouping" i18n>Grouping
                                    </mat-slide-toggle>
                                    <mat-slide-toggle [(ngModel)]="element.useEasing" i18n>
                                        Easing
                                    </mat-slide-toggle>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input [(ngModel)]="element.posy" type="number" matInput>
                                        <mat-label>Y |</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input [(ngModel)]="element.posx" type="number" matInput>
                                        <mat-label>X -</mat-label>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <input [(ngModel)]="element.style.height" matInput>
                                        <mat-label i18n>Height</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input [(ngModel)]="element.style.width" matInput>
                                        <mat-label i18n>Width</mat-label>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <label i18n>Shadow </label>
                                    <mat-checkbox color="primary" (change)="setBoxShadow()" [(ngModel)]="boxshadow">
                                    </mat-checkbox>
                                </div>

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select animation</mat-label>
                                    <mat-select [(ngModel)]="element.onhoveraction">
                                        <mat-option *ngFor="let action of hoveractions" [value]="action.value">
                                            <label>{{action.name}}</label>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex>
                                    <input [(ngModel)]="element.onclickurl" matInput>
                                    <mat-label i18n>Navigation URL</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex>
                                    <input [(ngModel)]="element.onhovershow" matInput>
                                    <mat-label i18n>Show Text on hover</mat-label>
                                </mat-form-field>

                                <!-- <div *ngIf="element.src">
                                    <img style="max-width: 100%;" src={{element.src}} class="elementsedit">
                                    <mat-icon (click)="element.src = ''" class="icon-label" class="iconsize-12">
                                        delete
                                    </mat-icon>
                                </div> -->
                            </div>

                            <mat-expansion-panel-header *ngIf="element.type === 'text' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">
                                    <label style="overflow: hidden; white-space:nowrap;">{{element.content |
                                        slice:0:28}}</label>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="element.type === 'text' && !element.groupmember">
                                <div fxLayout="column">
                                    <br>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field appearance="outline" style="width: 95%;" appearance="outline">
                                            <textarea i18n-matTooltip matTooltip="insert text" #inputpreview
                                                [(ngModel)]="element.content" type="text" matInput i18n-placeholder
                                                placeholder="element text"></textarea>
                                            <mat-icon i18n-matTooltip matTooltip="select Emoji" (click)="onshowemoji(i)"
                                                matSuffix>
                                                insert_emoticon</mat-icon>
                                            <mat-label align="end">{{inputpreview.value?.length || 0}}/90</mat-label>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" *ngIf="showemoji">
                                        <div class="textemoji">
                                            <emoji-mart (emojiClick)="setemoji($event, i, element)" set="apple"
                                                title="enydea">
                                            </emoji-mart>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field fxFlex="45%" appearance="outline">
                                            <mat-label i18n>Font size</mat-label>
                                            <input [(ngModel)]="element.style['font-size']" type="text" matInput
                                                i18n-placeholder placeholder="font size">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="45%" appearance="outline">
                                            <mat-label i18n>Line Height</mat-label>
                                            <input [(ngModel)]="element.style['line-height']" type="text" matInput
                                                i18n-placeholder placeholder="font size">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field fxFlex="45%" appearance="outline">
                                            <mat-label i18n>Letter spacing</mat-label>
                                            <input [(ngModel)]="element.style['letter-spacing']" type="text" matInput
                                                i18n-placeholder placeholder="font size">
                                        </mat-form-field>

                                    </div>
                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title i18n>
                                                    Color
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <input style="width: 60px;" [style.background]="element.style['color']"
                                                cpDialogDisplay="inline" [(colorPicker)]="element.style['color']"
                                                (colorPickerChange)="detectchange()" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company" [cpToggle]="true"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                    <br>
                                    <div fxLayout="row" fxLayout.xs="column">
                                        <mat-form-field appearance="outline">
                                            <mat-label i18n>Select font</mat-label>
                                            <mat-select [(ngModel)]="element.style['font-family']"
                                                (selectionChange)="detectchange()">
                                                <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                    <label [style.font-family]="font.value">{{font.name}}</label>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <mat-button-toggle-group #grouptext="matButtonToggleGroup"
                                        [(ngModel)]="element.style['text-align']">
                                        <mat-button-toggle value="left" aria-label="Text align left">
                                            <mat-icon>format_align_left</mat-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="center" aria-label="Text align center">
                                            <mat-icon>format_align_center</mat-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="right" aria-label="Text align right">
                                            <mat-icon>format_align_right</mat-icon>
                                        </mat-button-toggle>
                                        <mat-button-toggle value="justify" aria-label="Text align justify">
                                            <mat-icon>format_align_justify</mat-icon>
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <br>
                                    <div fxLayoutGap="20px" fxLayout="row">
                                        <mat-button-toggle (change)="setbold(element)"
                                            [checked]="element.style['font-weight'] === 'bold'" i18n>Bold
                                        </mat-button-toggle>
                                        <mat-button-toggle (change)="setitalic(element)"
                                            [checked]="element.style['font-style'] === 'italic'" i18n>Italic
                                        </mat-button-toggle>
                                    </div>
                                </div>
                                <br>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input (change)="detectchange()" [(ngModel)]="element.posy" type="number"
                                            matInput>
                                        <mat-label>Y |</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input (change)="detectchange()" [(ngModel)]="element.posx" type="number"
                                            matInput>
                                        <mat-label>X -</mat-label>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.height" matInput>
                                        <mat-label>Height</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.width" matInput>
                                        <mat-label>Width</mat-label>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div fxLayout="row">
                                    <label i18n>Shadow </label>
                                    <mat-checkbox color="primary" (change)="setBoxShadow()" [(ngModel)]="boxshadow">
                                    </mat-checkbox>
                                </div>


                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Select animation</mat-label>
                                    <mat-select [(ngModel)]="element.onhoveraction">
                                        <mat-option *ngFor="let action of hoveractions" [value]="action.value">
                                            <label>{{action.name}}</label>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onclickurl" matInput>
                                    <mat-label i18n>Navigation URL</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="element.onhovershow" matInput>
                                    <mat-label i18n>Show Text on hover</mat-label>
                                </mat-form-field>

                                <button mat-raised-button (click)="textToSVG(element)" i18n>Convert to Vector</button>

                            </div>

                            <mat-expansion-panel-header *ngIf="element.type === 'shape' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start" fxLayout="row">

                                    <div [style.background]="element.style['background-color']"
                                        [class]='element.style.class' style="width: 50px; height: 40px;"></div>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'shape' && !element.groupmember" fxLayout="column">
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field fxFlex="50%" appearance="outline">
                                        <mat-label i18n>Shape</mat-label>
                                        <mat-select [(ngModel)]="element.shape"
                                            (selectionChange)="onChangeShape(element)">
                                            <mat-option value="square" i18n>Square</mat-option>
                                            <mat-option value="round" i18n>Round</mat-option>
                                            <!-- <mat-option value="heart" i18n>Heart</mat-option> -->
                                            <!-- <mat-option value="star" i18n>Star</mat-option> -->
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="50%" appearance="outline">
                                        <mat-label>Color Layout</mat-label>
                                        <mat-select [(ngModel)]="element.coloreffect"
                                            (selectionChange)="onChangeShape(element)">
                                            <mat-option value="" i18n>None</mat-option>
                                            <mat-option value="stripes" i18n>Stripes</mat-option>
                                            <mat-option value="gradient" i18n>Gradient</mat-option>
                                            <mat-option value="radial-gradient" i18n>Radial gradient</mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>

                                <mat-form-field fxFlex="50%" appearance="outline" *ngIf="element.shape === 'round'">
                                    <mat-label i18n>Rounding Pecentage</mat-label>
                                    <input matInput i18n-placeholder placeholder="Rounding Pecentage" type="number"
                                        (change)="onChangeShape(element)" [(ngModel)]="element.rounding" max="50"
                                        min="1">
                                    <span matSuffix>%</span>
                                </mat-form-field>

                                <div fxLayout="column" *ngIf="element.shape === 'square'">
                                    <div fxLayout="row" fxLayoutGap="20px">
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Rounding top left</mat-label>
                                            <input matInput i18n-placeholder placeholder="Rounding Pecentage"
                                                type="number" (change)="onChangeShape(element)"
                                                [(ngModel)]="element.roundingTL" max="10000" min="0">
                                            <span matSuffix>px</span>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Rounding top right</mat-label>
                                            <input matInput i18n-placeholder placeholder="Rounding Pecentage"
                                                type="number" (change)="onChangeShape(element)"
                                                [(ngModel)]="element.roundingTR" max="10000" min="0">
                                            <span matSuffix>px</span>
                                        </mat-form-field>
                                    </div>


                                    <div fxLayout="row" fxLayoutGap="20px">
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Rounding bottom left</mat-label>
                                            <input matInput i18n-placeholder placeholder="Rounding Pecentage"
                                                type="number" (change)="onChangeShape(element)"
                                                [(ngModel)]="element.roundingBL" max="10000" min="0">
                                            <span matSuffix>px</span>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="inputbox-50">
                                            <mat-label i18n>Rounding bottom right</mat-label>
                                            <input matInput i18n-placeholder placeholder="Rounding Pecentage"
                                                type="number" (change)="onChangeShape(element)"
                                                [(ngModel)]="element.roundingBR" max="10000" min="0">
                                            <span matSuffix>px</span>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" fxFlex="50%">
                                        <input (change)="detectchange()" [(ngModel)]="element.posy" type="number"
                                            matInput>
                                        <mat-label>Y |</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50%">
                                        <input (change)="detectchange()" [(ngModel)]="element.posx" type="number"
                                            matInput>
                                        <mat-label>X -</mat-label>
                                    </mat-form-field>
                                </div>
                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" fxFlex="50%">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.height" matInput>
                                        <mat-label i18n>Height</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="50%">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.width" matInput>
                                        <mat-label i18n>Width</mat-label>
                                    </mat-form-field>
                                </div>
                                <mat-accordion *ngIf="element.coloreffect !== 'gradient'">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Color
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <span [style.background]="element.color1" cpDialogDisplay="inline"
                                            [(colorPicker)]="element.color1"
                                            (colorPickerChange)="onChangeShape(element)"
                                            [cpPresetLabel]="'Company colors'" *ngIf="company" [cpToggle]="true"
                                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </span>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <div *ngIf="element.coloreffect === 'stripes'">
                                    <mat-form-field appearance="outline">
                                        <input (change)="onChangeShape(element)" [(ngModel)]="element.stripewidth"
                                            matInput type="number">
                                        <mat-label i18n>Stripe width</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input (change)="onChangeShape(element)" [(ngModel)]="element.stripedirection"
                                            matInput type="number">
                                        <mat-label i18n>Stripe direction deg</mat-label>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="outline" *ngIf="element.coloreffect === 'radial-gradient'">
                                    <mat-select [(ngModel)]="element.position"
                                        (selectionChange)="onChangeShape(element)">
                                        <mat-option value="circle at top right," i18n>top right</mat-option>
                                        <mat-option value="circle at top left," i18n>top left</mat-option>
                                        <mat-option value="circle at bottom right," i18n>bottom right</mat-option>
                                        <mat-option value="circle at bottom left," i18n>bottom left</mat-option>
                                        <mat-option value="" i18n>center</mat-option>
                                        <!-- <mat-option value="star">Star</mat-option> -->
                                        <!-- <mat-option value="triangle">Triangle</mat-option> -->
                                    </mat-select>
                                </mat-form-field>

                                <div
                                    *ngIf="element.coloreffect === 'gradient' || element.coloreffect === 'radial-gradient'">
                                    <!-- <mat-form-field appearance="outline">
                                        <input (change)="onChangeShape(element)" [(ngModel)]="element.direction"
                                            matInput type="number">
                                        <mat-label>Gradient direction deg</mat-label>
                                    </mat-form-field> -->
                                    <label i18n>Gradient direction deg</label>
                                    <mat-slider max="360" min="0" step="1" [(ngModel)]="element.direction"
                                        [thumbLabel]="element.direction" (input)="onChangeShape(element)">
                                    </mat-slider>
                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title i18n>
                                                    Gradient color
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <label i18n>Color 1</label>
                                            <span style="width: 60px;" [style.background]="element.color1"
                                                cpDialogDisplay="inline" [(colorPicker)]="element.color1"
                                                (colorPickerChange)="onChangeShape(element)"
                                                [cpPresetLabel]="'Company colors'" *ngIf="company" [cpToggle]="true"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                            <label i18n>Color 2</label>
                                            <span style="width: 60px;" [style.background]="element.color2"
                                                cpDialogDisplay="inline" [(colorPicker)]="element.color2"
                                                (colorPickerChange)="onChangeShape(element)"
                                                [cpPresetLabel]="'Company colors'" *ngIf="company" [cpToggle]="true"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                    <div fxLayout="row">
                                        <mat-slider max="100" min="0" step="1" [(ngModel)]="element.percentagecolor1"
                                            [thumbLabel]="element.percentagecolor1" (input)="onChangeShape(element)">
                                        </mat-slider>

                                        <mat-slider max="100" min="0" step="1" [(ngModel)]="element.percentagecolor2"
                                            [thumbLabel]="element.percentagecolor2" (input)="onChangeShape(element)">
                                        </mat-slider>

                                        <mat-slider max="100" min="0" step="1" [(ngModel)]="element.percentagecolor3"
                                            [thumbLabel]="element.percentagecolor3" (input)="onChangeShape(element)">
                                        </mat-slider>
                                    </div>

                                </div>

                                <br>

                                <mat-checkbox color="primary" (change)="setBoxShadow()" [(ngModel)]="boxshadow" i18n>
                                    Shadow
                                </mat-checkbox>
                            </div>


                            <mat-expansion-panel-header *ngIf="element.type === 'vector' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between start">
                                    <div *ngIf="element.vectors[0].src" [inlineSVG]="element.vectors[0].src"
                                        [onSVGLoaded]="previewSVG" [attr.id]="'previewboxtitle' + i"
                                        class="elementsedit" style="height: 30px; width: auto;">
                                    </div>
                                    <div *ngIf="!element.vectors[0].src" [innerHTML]="element.svgcombi  | safeHtml"
                                        [attr.id]="'previewboxtitle'" style="height: 30px; width: 30px;">
                                    </div>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="convertSVGtoThreed()">
                                            <mat-icon>3d_rotation</mat-icon>
                                            <span i18n>Create 3D</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'vector' && !element.groupmember">
                                <div *ngFor="let vector of element.vectors; let idx = index;">
                                    <mat-expansion-panel *ngIf="element.morph">

                                        <mat-expansion-panel-header>
                                            <div *ngIf="vector.src || vector.svgcombi !== 'undefined'"
                                                [attr.id]="vector.idx" [inlineSVG]="vector.src"
                                                [onSVGLoaded]="previewSVG" class="elementsedit"
                                                style="height: 30px; width: auto;">
                                            </div>
                                        </mat-expansion-panel-header>


                                        <div *ngIf="vector.src" [inlineSVG]="vector.src" id="{{vector.idx}}"
                                            (onSVGInserted)="initVectors($event, i, idx, vector.idx)">
                                        </div>


                                        <div *ngIf="!vector.src" [inlineSVG]="vector.object" id="{{vector.idx}}"
                                            (onSVGInserted)="initVectors($event, i, idx, vector.idx)">
                                        </div>

                                        <app-vectorupload *ngIf="!vector.src" [account]="Account" [option]="option"
                                            (imgurl)="setVector($event, i, idx, true)">
                                        </app-vectorupload>

                                        <mat-select (selectionChange)="setExistingVector($event, i, idx)"
                                            i18n-placeholder placeholder="Add vector">
                                            <mat-option *ngFor="let potpart of selectablevectorarray" [value]="potpart">
                                                <div [innerHTML]="potpart.svgcombi  | safeHtml"
                                                    style="height: 20px; width: 20px;">
                                                </div>
                                            </mat-option>
                                        </mat-select>

                                        <mat-icon (click)="deleteVectorSrc(i, idx, element)" class="icon-label"
                                            class="iconsize-14"
                                            (click)="vectorService.removeVectorPathMultiSelection(selectedelement)"
                                            (click)="vectorService.removeVectorPathSelection(selectedelement)">
                                            delete
                                        </mat-icon>
                                    </mat-expansion-panel>
                                    <div *ngIf="!element.morph">
                                        <div *ngIf="vector.src" [inlineSVG]="vector.src" [onSVGLoaded]="previewSVGBig"
                                            (onSVGInserted)="initVectors($event, i, idx, vector.idx)"
                                            [attr.id]="vector.idx">
                                        </div>
                                        <div *ngIf="!vector.src && element.svgcombi" [attr.id]="vector.idx"
                                            [innerHTML]="element.svgcombi | safeHtml"
                                            (click)="vectorService.clickVectorPaths($event, element)"
                                            (onSVGInserted)="initVectors($event, i, idx, vector.idx)">
                                        </div>
                                        <app-vectorupload *ngIf="!vector.src && !element.svgcombi" [account]="Account"
                                            [option]="option" (imgurl)="setVector($event, i, idx)">
                                        </app-vectorupload>

                                        <div fxLayoutGap="20px" fxLayout="row">
                                            <mat-form-field appearance="outline" class="inputbox-50">
                                                <input (change)="detectchange()" [(ngModel)]="element.posy"
                                                    type="number" matInput>
                                                <mat-label>Y |</mat-label>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="inputbox-50">
                                                <input (change)="detectchange()" [(ngModel)]="element.posx"
                                                    type="number" matInput>
                                                <mat-label>X -</mat-label>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayoutGap="20px" fxLayout="row">
                                            <mat-form-field appearance="outline" class="inputbox-50">
                                                <input (change)="detectchange()" [(ngModel)]="element.style.height"
                                                    matInput>
                                                <mat-label i18n>Height</mat-label>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="inputbox-50">
                                                <input (change)="detectchange()" [(ngModel)]="element.style.width"
                                                    matInput>
                                                <mat-label i18n>Width</mat-label>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <mat-slide-toggle color="primary" [(ngModel)]="boxshadow" (change)="setBoxShadow()"
                                        i18n>
                                        Shadow</mat-slide-toggle>
                                </div>
                                <button mat-mini-fab *ngIf="element.morph" class="addbutton" i18n-matTooltip
                                    matTooltip="Add vector" (click)="addNewVectorSrc(i, element)" color="primary">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <!-- <button mat-mini-fab class="addbutton" i18n-matTooltip matTooltip="Add to Animation Group"
                                    (click)="addTooVectorCombi(element)" color="primary">
                                    <mat-icon>collections</mat-icon>
                                </button> -->
                            </div>
                            <mat-expansion-panel-header *ngIf="element.type === 'threed' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">

                                    <label *ngIf="element.src">3D object - {{element.src | fileName}}</label>
                                    <label *ngIf="element.imageurl">3D object - {{element.imageurl | fileName}}</label>
                                    <label *ngIf="element.svgurl">3D object - {{element.svgurl | fileName}}</label>

                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>

                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'threed' && !element.groupmember" fxLayout="column">
                                <!-- <button mat-raised-button (click)="detectchange()" *ngIf="element.blobsrc">Save</button> -->
                                <app-threedfile *ngIf="!element.blobsrc && !element.svg" [account]="Account"
                                    [option]="option" (imgurl)="setThreed($event)">
                                </app-threedfile>
                                <!-- *ngIf="!element.blobsrc && !element.svg" -->

                                <mat-slide-toggle [(ngModel)]="element.center" i18n>Center</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="element.wireframe" i18n>Wire</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="element.shadows" i18n>Show Shadows</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="element.transparent" i18n>Transparent</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="element.bevel" i18n>Bevel</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="element.illuminated" i18n>Illuminated</mat-slide-toggle>
                                <br>




                                <br>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Object depth</mat-label>
                                        <input matInput i18n-placeholder placeholder="Object depth"
                                            [(ngModel)]="element.exdepth" type="number" step="1" name="expodex">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Opacity</mat-label>
                                        <input matInput i18n-placeholder placeholder="Opacity"
                                            [(ngModel)]="element.opacity" max="1" min="0" type="number" step="0.01"
                                            name="opacity">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Shininess</mat-label>
                                        <input matInput i18n-placeholder placeholder="Shininess"
                                            [(ngModel)]="element.shininess" max="1" min="0" type="number" step="0.1"
                                            name="shininess">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Roughness</mat-label>
                                        <input matInput i18n-placeholder placeholder="Roughness"
                                            [(ngModel)]="element.roughness" max="1" min="0" type="number" step="0.1"
                                            name="roughness">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Scale</mat-label>
                                        <input matInput i18n-placeholder placeholder="Scale" [(ngModel)]="element.scale"
                                            max="100" min="0.1" type="number" step="0.1" name="Scale">
                                    </mat-form-field>
                                    <!-- <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label>Roughness</mat-label>
                                        <input matInput i18n-placeholder placeholder="Roughness" [(ngModel)]="element.roughness" max="1"
                                            min="0" type="number" step="0.1" name="roughness">
                                    </mat-form-field> -->
                                </div>

                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxLayout="column">
                                        <!-- <mat-slide-toggle [(ngModel)]="canvas.plane">Show plane</mat-slide-toggle>
                                        <mat-slide-toggle [(ngModel)]="element.materialtype">Material type 2 -->
                                        <!-- </mat-slide-toggle> -->
                                    </div>

                                </div>
                                <br>
                                <mat-slide-toggle [(ngModel)]="element.explode">Explode</mat-slide-toggle>
                                <br>
                                <label *ngIf="element.explode">Explosion coördinates</label>
                                <div fxLayout="row" fxLayoutGap="5px" *ngIf="element.explode">
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>X-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth X"
                                            [(ngModel)]="element.explodedepthx" type="number" step="1" name="expodex">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Y-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth Y"
                                            [(ngModel)]="element.explodedepthy" type="number" step="1" name="explodey">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Z-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth Z"
                                            [(ngModel)]="element.explodedepthz" type="number" step="1" name="explodez">
                                    </mat-form-field>
                                </div>
                                <br>
                                <button mat-raised-button (click)="resetPostionRotation()">reset positions</button>
                                <br>
                                <div fxLayout="row" fxLayoutGap="5px">
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>X-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="X" [(ngModel)]="element.positionx"
                                            type="number" step="1" name="expodex">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Y-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="Y" [(ngModel)]="element.positiony"
                                            type="number" step="1" name="explodey">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Z-axe</mat-label>
                                        <input matInput i18n-placeholder placeholder="Z" [(ngModel)]="element.positionz"
                                            type="number" step="1" name="explodez">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutGap="5px">
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>X-axe rotation</mat-label>
                                        <input matInput i18n-placeholder placeholder="X" [(ngModel)]="element.rotationx"
                                            type="number" step="1" name="expodex">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Y-axe rotation</mat-label>
                                        <input matInput i18n-placeholder placeholder="Y" [(ngModel)]="element.rotationy"
                                            type="number" step="1" name="explodey">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-30">
                                        <mat-label i18n>Z-axe rotation</mat-label>
                                        <input matInput i18n-placeholder placeholder="Z" [(ngModel)]="element.rotationz"
                                            type="number" step="1" name="explodez">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerarotationx">
                                        <label i18n>Camera rotation X</label>
                                        <label>{{element.camerarotationx | number:'1.0-3'}}</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerarotationy">
                                        <label i18n>Camera rotation Y</label>
                                        <label>{{element.camerarotationy | number:'1.0-3'}}</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerarotationz">
                                        <label i18n>Camera rotation Z</label>
                                        <label>{{element.camerarotationz | number:'1.0-3'}}</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerapositionx">
                                        <label i18n>Camera position X</label>
                                        <label>{{element.camerapositionx | number:'1.0-3'}}</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerapositiony">
                                        <label i18n>Camera position Y</label>
                                        <label>{{element.camerapositiony | number:'1.0-3'}}</label>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="10px" *ngIf="element.camerapositionz">
                                        <label i18n>Camera position Z</label>
                                        <label>{{element.camerapositionz | number:'1.0-3'}}</label>
                                    </div>
                                </div>
                                <br>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header i18n>
                                        Material File
                                    </mat-expansion-panel-header>
                                    <label *ngIf="element.materialsrcfile">{{element.materialsrcfile}}</label>
                                    <div *ngIf="!element.materialsrcfile">
                                        <app-threedmaterial [account]="Account" [option]="option"
                                            (imgurl)="setThreedMaterial($event)"></app-threedmaterial>
                                    </div>
                                </mat-expansion-panel>
                                <br>

                                <mat-expansion-panel>
                                    <mat-expansion-panel-header i18n>
                                        Seperate Components: {{element.partpropertiesarray.length}}
                                    </mat-expansion-panel-header>
                                    <div style="height: 100%;">
                                        <button mat-raised-button (click)="hideAllTechParts(element)" i18n>Hide
                                            all</button>
                                        <div *ngFor="let techpart of element.partpropertiesarray; let y = index;"
                                            fxLayout="row" fxLayoutGap="10px">
                                            <mat-checkbox [(ngModel)]="techpart.visible"></mat-checkbox>
                                            <label>{{y}}. </label>
                                            <label>{{techpart.name}}</label>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header i18n>
                                        Object Color
                                    </mat-expansion-panel-header>
                                    <input style="width: 60px;" [style.background]="element.color"
                                        cpDialogDisplay="inline" [(colorPicker)]="element.color" name="colorpicker"
                                        [cpCancelButton]="true" [cpPresetLabel]="'Company colors'" *ngIf="company"
                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor,
                                    company.companyprimairycolor, company.companysecondarycolor]">
                                    <button mat-raised-button (click)="element.color = ''" i18n>Remove</button>
                                </mat-expansion-panel>

                                <!-- <mat-slide-toggle [(ngModel)]="element.illuminated">Illumited</mat-slide-toggle> -->
                                <br>
                                <h3>General 3D settings</h3>
                                <br>
                                <mat-slide-toggle [(ngModel)]="canvas.light1" i18n>Light</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.light2" i18n>light2</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.sky" i18n>Sky</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.grid" i18n>Show Grid</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.helper" i18n>Show Helper</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.shadows" i18n>Show Shadows on
                                    Plane</mat-slide-toggle>
                                <mat-slide-toggle [(ngModel)]="canvas.cloud" i18n>Clouds</mat-slide-toggle>
                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Landscape</mat-label>
                                    <mat-select i18n-placeholder placeholder="Set plane" [(ngModel)]="canvas.plane"
                                        name="Plane">
                                        <mat-option *ngFor="let plane of planes" [value]="plane">
                                            {{plane}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-select i18n-placeholder placeholder="Pre created animation"
                                        [(ngModel)]="canvas.pretheme" name="pretheme">
                                        <mat-option *ngFor="let pre of prethemes" [value]="pre">
                                            {{pre}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <mat-expansion-panel *ngIf="canvas.plane">
                                    <mat-expansion-panel-header i18n>
                                        Plane Color
                                    </mat-expansion-panel-header>
                                    <input style="width: 60px;" [style.background]="canvas.planecolor"
                                        cpDialogDisplay="inline" [(colorPicker)]="canvas.planecolor" name="colorpicker"
                                        [cpCancelButton]="true" [cpPresetLabel]="'Company colors'" *ngIf="company"
                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor,
                                    company.companyprimairycolor, company.companysecondarycolor]">
                                    <button mat-raised-button (click)="canvas.planecolor = ''" i18n>Remove</button>
                                </mat-expansion-panel>

                                <div>
                                    <div>
                                        <label i18n>Sun location: {{canvas.sunlocation}}</label>
                                        <mat-slider [(ngModel)]="canvas.sunlocation" [step]="1" [max]="180"
                                            [min]="-180">
                                        </mat-slider>
                                    </div>
                                    <div>
                                        <label i18n>Time of day: {{canvas.sunheight}}</label>
                                        <mat-slider [(ngModel)]="canvas.sunheight" [step]="1" [max]="90" [min]="0">
                                        </mat-slider>
                                    </div>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <mat-label i18n>Light intensity</mat-label>
                                        <input matInput [(ngModel)]="canvas.light" max="2" min="0" type="number"
                                            step="0.1" name="light">
                                    </mat-form-field>
                                </div>


                            </div>
                            <mat-expansion-panel-header *ngIf="element.type === 'form' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">
                                    <mat-icon>call_to_action</mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'form' && !element.groupmember">
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Edit video</mat-label>
                                    <mat-select (selectionChange)="setForm($event)">
                                        <mat-option *ngFor="let form of Forms" [value]="form">
                                            {{form.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div fxLayoutGap="20px" fxLayout="row">
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input (change)="detectchange()" [(ngModel)]="element.posy" type="number"
                                            matInput name="posy">
                                        <mat-label>Y |</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="inputbox-50">
                                        <input (change)="detectchange()" [(ngModel)]="element.posx" type="number"
                                            matInput name="posx">
                                        <mat-label>X -</mat-label>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.height" matInput
                                            name="styleheight">
                                        <mat-label i18n>Height</mat-label>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <input (change)="detectchange()" [(ngModel)]="element.style.width" matInput
                                            name="stylewidth">
                                        <mat-label i18n>Width</mat-label>
                                    </mat-form-field>
                                </div>
                                <mat-checkbox color="primary" (change)="setBoxShadow()" [(ngModel)]="boxshadow"
                                    i18n>Shadow
                                </mat-checkbox>
                            </div>

                            <mat-expansion-panel-header *ngIf="element.type === 'chart' && !element.groupmember"
                                cdkDragHandle [class.selected]="element === selectedelement">
                                <mat-panel-title fxLayoutAlign="space-between start">
                                    <mat-icon>show_chart</mat-icon>
                                    <button mat-button [matMenuTriggerFor]="menuimage">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuimage="matMenu">
                                        <button mat-menu-item (click)="deleteitem(i)">
                                            <mat-icon>delete</mat-icon>
                                            <span i18n>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="copyElement(i, element)" i18n-matTooltip
                                            matTooltip="Duplicate effect">
                                            <mat-icon>filter_none</mat-icon>
                                            <span i18n>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="createElementHTML()" i18n-matTooltip
                                            matTooltip="Export as plain code">
                                            <mat-icon>code</mat-icon>
                                            <span i18n>Export as code</span>
                                        </button>
                                    </mat-menu>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="element.type === 'chart' && !element.groupmember">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Colors
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngFor="let colors of element.colors" fxLayout="column">
                                            <!-- *ngIf="element.charttype === 'pie' || element.charttype ===  'doughnut' || element.charttype ===  'polarArea'" -->
                                            <label i18n>Color Specification</label>
                                            <div>
                                                <div *ngFor="let bgcolor of colors.backgroundColor; let ic1 = index;">
                                                    <span [cpToggle]="true" [cpDialogDisplay]="'inline'"
                                                        [cpCancelButton]="true"
                                                        [style.background]="colors.backgroundColor[ic1]"
                                                        [(colorPicker)]="colors.backgroundColor[ic1]"
                                                        [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                        *ngIf="company"
                                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor,
                                                         company.companyprimairycolor, company.companysecondarycolor]"></span>
                                                    <mat-icon class="iconsize-12" (click)="colors.backgroundColor = ''">
                                                    </mat-icon>
                                                </div>
                                            </div>
                                            <div>
                                                <!-- *ngIf="element.charttype !== 'pie' || 'doughnut' || 'polarArea'" -->
                                                <div *ngIf="colors.backgroundColor">
                                                    <span [cpDialogDisplay]="'inline'"
                                                        [style.background]="colors.borderColor"
                                                        [(colorPicker)]="colors.backgroundColor" [cpCancelButton]="true"
                                                        [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                        [cpToggle]="true" [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor,
                                                         company.companyprimairycolor, company.companysecondarycolor]">
                                                    </span>
                                                    <mat-icon class="iconsize-12" (click)="colors.backgroundColor = ''">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                                <div *ngIf="colors.borderColor">
                                                    <span style="width: 60px;" [style.background]="colors.borderColor"
                                                        cpDialogDisplay="inline" [(colorPicker)]="colors.borderColor"
                                                        [cpPresetLabel]="'Company colors'" [cpToggle]="true"
                                                        [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                    </span>
                                                    <mat-icon class="iconsize-12" (click)="colors.borderColor = ''">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                                <div *ngIf="colors.pointBackgroundColor">
                                                    <span style="width: 60px;"
                                                        [style.background]="colors.pointBackgroundColor"
                                                        cpDialogDisplay="inline"
                                                        [(colorPicker)]="colors.pointBackgroundColor"
                                                        [cpPresetLabel]="'Company colors'"
                                                        [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]"></span>
                                                    <mat-icon class="iconsize-12"
                                                        (click)="colors.pointBackgroundColor = ''">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                                <div *ngIf="colors.pointBorderColor">
                                                    <span [style.background]="colors.pointBorderColor"
                                                        cpDialogDisplay="inline"
                                                        [(colorPicker)]="colors.pointBorderColor" [cpToggle]="true"
                                                        [cpPresetLabel]="'Company colors'"
                                                        [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                    </span>
                                                    <mat-icon class="iconsize-12"
                                                        (click)="colors.pointBorderColor = ''">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                            </div>

                                        </div>

                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title i18n>
                                                Chart type
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="element.charttype"
                                                (selectionChange)="changeChartType()">
                                                <mat-option value="line" i18n>line</mat-option>
                                                <mat-option value="bar" i18n>bar</mat-option>
                                                <mat-option value="radar" i18n>radar</mat-option>
                                                <mat-option value="pie" i18n>pie</mat-option>
                                                <mat-option value="polarArea" i18n>Polararea</mat-option>
                                                <mat-option value="doughnut" i18n>Doughnut</mat-option>
                                                <!-- <mat-option value="bubble">Bubble</mat-option> -->
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="element.lineChartOptions.scales && company" fxLayout="column">
                                            <label i18n> X-Axe
                                                Gridline color</label>
                                            <span
                                                [style.background]="element.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                [cpToggle]="true" [cpDialogDisplay]="'inline'"
                                                [(colorPicker)]="element.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                            <mat-icon class="iconsize-12"
                                                (click)="element.lineChartOptions.scales.xAxes[0].gridLines.color = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n>X-Axe
                                                Font color</label>
                                            <span
                                                [style.background]="element.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                [cpToggle]="true" [cpDialogDisplay]="'inline'"
                                                [(colorPicker)]="element.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                            <mat-icon class="iconsize-12"
                                                (click)="element.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n> Y-axe
                                                Gridline color</label>
                                            <span
                                                [style.background]="element.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                [cpToggle]="true" [cpDialogDisplay]="'inline'"
                                                [(colorPicker)]="element.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                            <mat-icon class="iconsize-12"
                                                (click)="element.lineChartOptions.scales.yAxes[0].gridLines.color = ''">
                                                delete
                                            </mat-icon>
                                            <label i18n>Y-axe
                                                Font color</label>
                                            <span
                                                [style.background]="element.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                [cpToggle]="true" [cpDialogDisplay]="'inline'"
                                                [(colorPicker)]="element.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                [cpPresetLabel]="'Company colors'"
                                                [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                            <mat-icon class="iconsize-12"
                                                (click)="element.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                delete
                                            </mat-icon>
                                            <mat-form-field appearance="outline">
                                                <mat-label i18n>Select font lables</mat-label>
                                                <mat-select
                                                    [(ngModel)]="element.lineChartOptions.legend.labels.fontFamily"
                                                    (selectionChange)="detectchange()">
                                                    <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                        <label [style.font-family]="font.value">{{font.name}}</label>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label i18n>Select font X-axe</mat-label>
                                                <mat-select
                                                    [(ngModel)]="element.lineChartOptions.scales.xAxes[0].ticks.fontFamily"
                                                    (selectionChange)="detectchange()">
                                                    <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                        <label [style.font-family]="font.value">{{font.name}}</label>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label i18n>Select font Y-axe</mat-label>
                                                <mat-select
                                                    [(ngModel)]="element.lineChartOptions.scales.yAxes[0].ticks.fontFamily"
                                                    (selectionChange)="detectchange()">
                                                    <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                        <label [style.font-family]="font.value">{{font.name}}</label>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <input (change)="detectchange()"
                                                    [(ngModel)]="element.lineChartOptions.legend.labels.fontSize"
                                                    matInput>
                                                <mat-label i18n>Label -</mat-label>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <input (change)="detectchange()"
                                                    [(ngModel)]="element.lineChartOptions.scales.yAxes[0].ticks.fontSize"
                                                    matInput>
                                                <mat-label>X -</mat-label>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <input (change)="detectchange()"
                                                    [(ngModel)]="element.lineChartOptions.scales.xAxes[0].ticks.fontSize"
                                                    matInput>
                                                <mat-label>Y -</mat-label>
                                            </mat-form-field>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>



                            </div>
                            <!-- <mat-expansion-panel-header *ngIf="element.type === 'vectorcombi'" cdkDragHandle
                                [class.selected]="element === selectedelement" cdkDropList
                                (cdkDropListDropped)="dropVectorGroup($event, element, i)">
                                <div fxLayout="row">
                                    <label *ngIf="!element.groupname">Group</label> &nbsp;
                                    <label *ngIf="element.groupname">{{element.groupname}}</label> &nbsp;
                                    <label [innerHTML]="element.vectors.length"></label>
                                </div>
                                <mat-icon (click)="deletevcgroup(i)" class="iconsize-12">delete</mat-icon>
                            </mat-expansion-panel-header>

                            <div *ngIf="element.type === 'vectorcombi'" cdkDragHandle>
                                <mat-form-field appearance="outline" style="width: 95%;" appearance="outline">
                                    <input i18n-matTooltip matTooltip="groupname" [(ngModel)]="element.groupname" type="text" matInput
                                        i18n-placeholder placeholder="Group name">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Add element</mat-label>
                                    <mat-select (selectionChange)="dropVectorGroup($event, element, i)">
                                        <mat-option *ngFor="let potpart of selectableanimationarray" [value]="potpart">
                                            <div *ngIf="potpart.type === 'vector'"
                                                [innerHTML]="potpart.svgcombi  | safeHtml"
                                                style="height: 20px; width: 20px;">
                                            </div>
                                            <img *ngIf="potpart.type === 'image'" src={{potpart.src}}
                                                style="height: 20px; width: 20px;">
                                            <div *ngIf="potpart.type === 'shape'" style="height: 20px; width: 20px;"
                                                [style.background-color]="potpart.style['background-color']"
                                                [className]="potpart.style.class">
                                            </div>
                                            <div *ngIf="potpart.type === 'chart'">
                                                <mat-icon>graph</mat-icon>
                                            </div>
                                            <div *ngIf="potpart.type === 'text'">
                                                <label>{{potpart.content}}</label>
                                            </div>

                                            <button mat-raised-button (click)="TextToSvg(potpart)">Convert to
                                                Vector</button>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-accordion multi="true">
                                    <mat-expansion-panel *ngFor="let part of element.vectors; let idy = index;"
                                        (click)="onSelectElement($event, part)">
              
                                        <mat-expansion-panel-header *ngIf="part.type === 'image'" cdkDragHandle>
                                            <mat-panel-title>
                                                <img *ngIf="part.src" src={{part.src}} class="elementsedit"
                                                    style="height: 30px; width: auto;">
                                                <mat-icon (click)="deleteitemvcgroup(i, idy)"
                                                    i18n-matTooltip matTooltip="Remove from group" class="iconsize-12">delete
                                                </mat-icon>
                                                <mat-icon (click)="copyElement(i, part)" i18n-matTooltip matTooltip="Duplicate element"
                                                    class="iconsize-10">
                                                    filter_none</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="up" (click)="movePartUp(idy, element.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_up</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="down" (click)="movePartDown(idy, element.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_down</mat-icon>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'image'" fxLayout="column">
                                            <app-fileupload *ngIf="!part.src" [account]="Account" [option]="option"
                                                (imgurl)="setImage($event, i)">
                                            </app-fileupload>
                                            <div fxLayout="row wrap" fxLayoutGap="20px">
                                                <button mat-button (click)="imageCropPath()">edit crop</button>
                                                <button mat-button (click)="imageSaveCropPath()">
                                                    <mat-icon>save</mat-icon>
                                                </button>
                                                <button mat-button (click)="imageRemoveCrop()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                <mat-slide-toggle (change)="detectchange()" [(ngModel)]="part.grey">
                                                    Greyscale
                                                </mat-slide-toggle>
                                                <mat-slide-toggle (change)="detectchange()" [(ngModel)]="part.blur">
                                                    Blur
                                                </mat-slide-toggle>
                                            </div>
                                            <div fxLayoutGap="20px" fxLayout="row">
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posy"
                                                        type="number" matInput>
                                                    <mat-label>Y |</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posx"
                                                        type="number" matInput>
                                                    <mat-label>X -</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.height"
                                                        matInput>
                                                    <mat-label>Height</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.width"
                                                        matInput>
                                                    <mat-label>Width</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <mat-form-field appearance="outline">
                                                <input (change)="detectchange()" type="number"
                                                    [(ngModel)]="part.opacity" matInput min="0" max="1" step="0.1">
                                                <mat-label>opacity</mat-label>
                                            </mat-form-field>

                                            <div *ngIf="part.src">
                                                <img style="max-width: 100%;" src={{part.src}} class="elementsedit">
                                                <mat-icon (click)="part.src = ''" class="icon-label"
                                                    class="iconsize-12">
                                                    delete
                                                </mat-icon>
                                            </div>

                                        </div>
                                        <mat-expansion-panel-header *ngIf="part.type === 'counter' && part.groupmember">
                                            <mat-panel-title>
                                                Counter {{part.maxcount}}
                                                <mat-icon (click)="deleteitem(i)" class="iconsize-12">delete
                                                </mat-icon>
                                                <mat-icon (click)="copyElement(i, part)" i18n-matTooltip matTooltip="Duplicate effect"
                                                    class="iconsize-10">
                                                    filter_none</mat-icon>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'counter' && part.groupmember" fxLayout="column">
                                            <div fxLayoutGap="20px">
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.maxcount"
                                                        type="number" matInput>
                                                    <mat-label>Value</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()"
                                                        [(ngModel)]="part.countertype.startvalue" type="number"
                                                        matInput>
                                                    <mat-label>Start value</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row" fxLayout.xs="column">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="part.style['font-size']" type="text" matInput
                                                        i18n-placeholder placeholder="font size" (change)="detectchange()">
                                                </mat-form-field>
                                            </div>
                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Background color
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <span style="width: 60px;" [style.background]="part.style['color']"
                                                        cpDialogDisplay="inline" [(colorPicker)]="part.style['color']"
                                                        (colorPickerChange)="detectchange()" [cpToggle]="true"
                                                        [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                    </span>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                            <br>
                                            <div fxLayout="row" fxLayout.xs="column">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Select font</mat-label>
                                                    <mat-select [(ngModel)]="part.style['font-family']"
                                                        (selectionChange)="detectchange()">
                                                        <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                            <label
                                                                [style.font-family]="font.value">{{font.name}}</label>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayoutGap="20px">
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="part.prefix" matInput>
                                                    <mat-label>Prefix</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="part.suffix" matInput>
                                                    <mat-label>Suffix</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <mat-form-field appearance="outline">
                                                <mat-select [(ngModel)]="part.separator" i18n-placeholder placeholder="Seperator">
                                                    <mat-option value=",">Comma</mat-option>
                                                    <mat-option value=".">Dot</mat-option>
                                                    <mat-option value="">None</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-select [(ngModel)]="part.decimal" i18n-placeholder placeholder="Seperator">
                                                    <mat-option value=",">Comma</mat-option>
                                                    <mat-option value=".">Dot</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div>
                                                <mat-slide-toggle [(ngModel)]="part.useGrouping">Grouping
                                                </mat-slide-toggle>
                                                <mat-slide-toggle [(ngModel)]="part.useEasing">
                                                    Easing
                                                </mat-slide-toggle>
                                            </div>
                                            <div fxLayoutGap="20px" fxLayout="row">
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input [(ngModel)]="part.posy" type="number" matInput>
                                                    <mat-label>Y |</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input [(ngModel)]="part.posx" type="number" matInput>
                                                    <mat-label>X -</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="part.style.height" matInput>
                                                    <mat-label>Height</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input [(ngModel)]="part.style.width" matInput>
                                                    <mat-label>Width</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <label>Shadow </label>
                                                <mat-checkbox color="primary" (change)="setBoxShadow()"
                                                    [(ngModel)]="boxshadow">
                                                </mat-checkbox>
                                            </div>

                                            <div *ngIf="part.src">
                                                <img style="max-width: 100%;" src={{part.src}} class="elementsedit">
                                                <mat-icon (click)="part.src = ''" class="icon-label"
                                                    class="iconsize-12">
                                                    delete
                                                </mat-icon>
                                            </div>
                                        </div>
                                        <mat-expansion-panel-header *ngIf="part.type === 'text'" cdkDragHandle>
                                            <mat-panel-title>
                                                <label
                                                    style="overflow: hidden; white-space:nowrap;">{{part.content}}</label>
                                                <mat-icon (click)="deleteitemvcgroup(i, idy)"
                                                    i18n-matTooltip matTooltip="Remove from group" class="iconsize-12">delete
                                                </mat-icon>
                                                <mat-icon (click)="copyElement(i, part)" i18n-matTooltip matTooltip="Duplicate effect"
                                                    class="iconsize-10">
                                                    filter_none</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="up" (click)="movePartUp(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_up</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="down" (click)="movePartDown(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_down</mat-icon>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'text'">
                                            <div fxLayoutGap="20px" fxLayout="row">
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posy"
                                                        type="number" matInput>
                                                    <mat-label>Y |</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posx"
                                                        type="number" matInput>
                                                    <mat-label>X -</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.height"
                                                        matInput>
                                                    <mat-label>Height</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.width"
                                                        matInput>
                                                    <mat-label>Width</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="column">
                                                <div fxLayout="row" fxLayout.xs="column">
                                                    <mat-form-field appearance="outline" style="width: 95%;"
                                                        appearance="outline">
                                                        <textarea i18n-matTooltip matTooltip="insert text" #inputpreview
                                                            [(ngModel)]="part.content" type="text" matInput
                                                            i18n-placeholder placeholder="element text"></textarea>
                                                        <mat-icon i18n-matTooltip matTooltip="select Emoji" (click)="onshowemoji(i)"
                                                            matSuffix>
                                                            insert_emoticon</mat-icon>
                                                        <mat-label align="end">{{inputpreview.value?.length || 0}}/90
                                                        </mat-label>
                                                    </mat-form-field>
                                                </div>
                                                <div fxLayout="row" fxLayout.xs="column" *ngIf="showemoji">
                                                    <div fxFlex>
                                                        <div style="width: 100%;
                                                          height: 100%;
                                                          position: absolute;
                                                          top: 8;
                                                          left: 20;
                                                          z-index: 20;">
                                                            <emoji-mart (emojiClick)="setemoji($event, i, part)"
                                                                set="apple" title="enydea">
                                                            </emoji-mart>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayout.xs="column">
                                                    <mat-form-field appearance="outline">
                                                        <input [(ngModel)]="part.style['font-size']" type="text"
                                                            matInput i18n-placeholder placeholder="font size" (change)="detectchange()">
                                                    </mat-form-field>
                                                </div>
                                                <mat-accordion>
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                Background color
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <span style="width: 60px;"
                                                            [style.background]="part.style['color']"
                                                            cpDialogDisplay="inline"
                                                            [(colorPicker)]="part.style['color']"
                                                            (colorPickerChange)="detectchange()" [cpToggle]="true"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                        </span>
                                                    </mat-expansion-panel>
                                                </mat-accordion>

                                                <br>
                                                <div fxLayout="row" fxLayout.xs="column">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>Select font</mat-label>
                                                        <mat-select [(ngModel)]="part.style['font-family']"
                                                            (selectionChange)="detectchange()">
                                                            <mat-option *ngFor="let font of Fonts" [value]="font.value">
                                                                <label
                                                                    [style.font-family]="font.value">{{font.name}}</label>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div fxLayout="row">
                                                    <mat-button-toggle fxFlex (click)="setbold(part)" value="bold">
                                                        Bold
                                                    </mat-button-toggle>
                                                    <mat-button-toggle fxFlex (click)="setitalic(part)" value="italic">
                                                        Italic
                                                    </mat-button-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-expansion-panel-header *ngIf="part.type === 'shape'" cdkDragHandle>
                                            <mat-panel-title>

                                                <div [style.width]="'50px'" [style.height]="'50px'"
                                                    [className]="part.style.class">
                                                </div>
                                                <div style="height: 30px; width: 30px"
                                                    [style.background-color]="part.style['background-color']"></div>


                                                <mat-icon (click)="deleteitemvcgroup(i, idy)"
                                                    i18n-matTooltip matTooltip="Remove from group" class="iconsize-12">delete
                                                </mat-icon>
                                                <mat-icon (click)="copyElement(i, part)" i18n-matTooltip matTooltip="Duplicate effect"
                                                    class="iconsize-10">
                                                    filter_none</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="up" (click)="movePartUp(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_up</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="down" (click)="movePartDown(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_down</mat-icon>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'shape'">
                                            <mat-form-field appearance="outline">
                                                <mat-select [(ngModel)]="part.shape"
                                                    (selectionChange)="onChangeShape(part)">
                                                    <mat-option value="square">Square</mat-option>
                                                    <mat-option value="round">Round</mat-option>
                                                    <mat-option value="heart">Heart</mat-option>

                                                </mat-select>
                                            </mat-form-field>
                                            <div fxLayoutGap="20px" fxLayout="row">
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posy"
                                                        type="number" matInput>
                                                    <mat-label>Y |</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="inputbox-50">
                                                    <input (change)="detectchange()" [(ngModel)]="part.posx"
                                                        type="number" matInput>
                                                    <mat-label>X -</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayoutGap="20px">
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.height"
                                                        matInput>
                                                    <mat-label>Height</mat-label>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline">
                                                    <input (change)="detectchange()" [(ngModel)]="part.style.width"
                                                        matInput>
                                                    <mat-label>Width</mat-label>
                                                </mat-form-field>
                                            </div>
                                            <mat-accordion *ngIf="part.shape !== 'heart'">
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Background color
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <span style="width: 60px;"
                                                        [style.background]="part.style['background-color']"
                                                        cpDialogDisplay="inline" [cpToggle]="true"
                                                        [(colorPicker)]="part.style['background-color']"
                                                        (colorPickerChange)="detectchange()"
                                                        [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                        [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                    </span>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </div>
                                        <mat-expansion-panel-header *ngIf="part.type === 'vector'" cdkDragHandle>
                                            <mat-panel-title>

                                                <div *ngIf="part.vectors[0].src" [inlineSVG]="part.vectors[0].src"
                                                    [onSVGLoaded]="previewSVG"
                                                    [attr.id]="'previewboxtitle'+ i + part.idx" class="elementsedit"
                                                    style="height: 30px; width: auto;">
                                                </div>
                                                <div *ngIf="!part.vectors[0].src"
                                                    [innerHTML]="part.svgcombi  | safeHtml"
                                                    [attr.id]="'previewboxtitle'+ i + part.idx"
                                                    style="height: 30px; width: 30px;">
                                                </div>
                                                <mat-icon (click)="deleteitemvcgroup(i, idy)" class="iconsize-12">
                                                    delete
                                                </mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="up" (click)="movePartUp(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_up</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="down" (click)="movePartDown(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_down</mat-icon>
 
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'vector'">
                                            <mat-slide-toggle [(ngModel)]="part.animate"
                                                (change)="setVectorToAnimate(idy)">
                                                Animate</mat-slide-toggle>

                                            <div *ngFor="let vector of part.vectors; let idx = index;">
                                                <mat-expansion-panel *ngIf="part.morph">

                                                    <mat-expansion-panel-header>
                                                        <div *ngIf="vector.src || vector.svgcombi !== 'undefined'"
                                                            [attr.id]="vector.idx" [inlineSVG]="vector.src"
                                                            [onSVGLoaded]="previewSVG" class="elementsedit"
                                                            style="height: 30px; width: auto;">
                                                        </div>
                                                    </mat-expansion-panel-header>

                                                    <div *ngIf="vector.src" [inlineSVG]="vector.src"
                                                        style="width: 100px; height: 200px;" id="{{vector.idx}}"
                                                        (onSVGInserted)="initVectors($event, i, idx, vector.idx)">
                                                    </div>
                                                    <app-vectorupload *ngIf="!vector.src" [account]="Account"
                                                        [option]="option" (imgurl)="setVector($event, i, idx)">
                                                    </app-vectorupload>
                                                    <mat-icon (click)="deleteVectorSrc(i, idx, part)" class="icon-label"
                                                        class="iconsize-14"
                                                        (click)="vectorService.removeVectorPathMultiSelection(selectedelement)"
                                                        (click)="vectorService.removeVectorPathSelection(selectedelement)">
                                                        delete
                                                    </mat-icon>
                        
                                                </mat-expansion-panel>
                                                <div *ngIf="!part.morph">
                                                    <div *ngIf="vector.src" [inlineSVG]="vector.src"
                                                        [onSVGLoaded]="previewSVGBig"
                                                        (onSVGInserted)="initVectors($event, i, idx, vector.idx)"
                                                        [attr.id]="vector.idx" style="height: 100px; width: 100px;">
                                                    </div>
               

                                                    <div *ngIf="!vector.src" style="height: 100px; width: 100px;"
                                                        [attr.id]="vector.idx" [innerHTML]="part.svgcombi | safeHtml"
                                                        (click)="vectorService.clickVectorPaths($event, part)"
                                                        (onSVGInserted)="initVectors($event, i, idx, vector.idx)">
                                                    </div>

                                                    <app-vectorupload *ngIf="!vector.src && !part.svgcombi"
                                                        [account]="Account" [option]="option"
                                                        (imgurl)="setVector($event, i, idx)">
                                                    </app-vectorupload>
                                                    <mat-icon (click)="deleteVectorSrc(i, idx, part)" class="icon-label"
                                                        class="iconsize-14"
                                                        (click)="vectorService.removeVectorPathMultiSelection(selectedelement)"
                                                        (click)="vectorService.removeVectorPathSelection(selectedelement)">
                                                        delete
                                                    </mat-icon>

                                                    <div fxLayoutGap="20px" fxLayout="row">
                                                        <mat-form-field appearance="outline" class="inputbox-50">
                                                            <input (change)="detectchange()" [(ngModel)]="part.posy"
                                                                type="number" matInput>
                                                            <mat-label>Y |</mat-label>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline" class="inputbox-50">
                                                            <input (change)="detectchange()" [(ngModel)]="part.posx"
                                                                type="number" matInput>
                                                            <mat-label>X -</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxLayoutGap="20px">
                                                        <mat-form-field appearance="outline">
                                                            <input (change)="detectchange()"
                                                                [(ngModel)]="part.style.height" matInput>
                                                            <mat-label>Height</mat-label>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <input (change)="detectchange()"
                                                                [(ngModel)]="part.style.width" matInput>
                                                            <mat-label>Width</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <button mat-mini-fab *ngIf="part.morph" class="addbutton"
                                                i18n-matTooltip matTooltip="Add vector" (click)="addNewVectorSrc(i, part)"
                                                color="primary">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                        <mat-expansion-panel-header *ngIf="part.type === 'chart'" cdkDragHandle>
                                            <mat-panel-title>
                                                <mat-icon>show_chart</mat-icon>
                                                <mat-icon (click)="deleteitemvcgroup(i, idy)"
                                                    i18n-matTooltip matTooltip="Remove from group" class="iconsize-12">delete
                                                </mat-icon>
                                                <mat-icon (click)="copyElement(i, part)" i18n-matTooltip matTooltip="Duplicate element"
                                                    class="iconsize-10">filter_none
                                                </mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="up" (click)="movePartUp(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_up</mat-icon>
                                                <mat-icon i18n-matTooltip matTooltip="down" (click)="movePartDown(idy, part.vectors)"
                                                    class="iconsize-10 sectionlabelbuttons" matSuffix>
                                                    arrow_drop_down</mat-icon>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="part.type === 'chart'">
                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Data
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div *ngFor="let label of part.label; let i1 = index;">
                                                        <mat-form-field appearance="outline" fxflex>
                                                            <input (change)="detectchangeLabel(i, i1)"
                                                                [(ngModel)]="part.label[i1]" type="text" matInput
                                                                i18n-placeholder placeholder="label">
                                                        </mat-form-field>
                                                        <mat-icon (click)="deletelabel(i)" class="iconsize-12">
                                                            delete
                                                        </mat-icon>
                                                    </div>


                                                    <mat-icon (click)="addLabel()">add</mat-icon>
                                                    <div *ngFor="let row of part.originaldata; let i1 = index;"
                                                        fxLayout="column">

                                                        <mat-form-field appearance="outline" fxflex>
                                                            <input (change)="detectchangerowlabel(i, i1)"
                                                                [(ngModel)]="row.label" type="text" matInput>
                                                        </mat-form-field>
                                                        <div fxFlex fxLayout="row wrap">
                                                            <div *ngFor="let cell of row.data; let i2 = index;">
                                                                <mat-form-field appearance="outline" fxflex
                                                                    style="width: 20px;">
                                                                    <input (change)="detectchangerowcell(i, i1, i2)"
                                                                        [(ngModel)]="row.data[i2]" type="text" matInput>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <mat-icon (click)="deletecell(i)" class="iconsize-12">delete
                                                        </mat-icon>
                                                        <mat-icon (click)="addcell(i)">add</mat-icon>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>

                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Colors
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div *ngFor="let colors of part.colors" fxLayout="column">
                                                        Color Specification
                                                        <span [style.background]="colors.backgroundColor"
                                                            [cpDialogDisplay]="inline" [cpToggle]="true"
                                                            [(colorPicker)]="colors.backgroundColor"
                                                            [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                            *ngIf="company"
                                                            [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                        </span>
                                                        <mat-icon class="iconsize-12"
                                                            (click)="colors.backgroundColor = ''">
                                                            delete
                                                        </mat-icon>
                                                        <span [style.background]="colors.borderColor"
                                                            cpDialogDisplay="inline"
                                                            [(colorPicker)]="colors.borderColor" *ngIf="company"
                                                            [cpPresetLabel]="'Company colors'" [cpToggle]="true"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        </span>
                                                        <mat-icon class="iconsize-12" (click)="colors.borderColor = ''">
                                                            delete
                                                        </mat-icon>
                                                        <span [style.background]="colors.pointBackgroundColor"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="colors.pointBackgroundColor"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        </span>
                                                        <mat-icon class="iconsize-12"
                                                            (click)="colors.pointBackgroundColor = ''">
                                                            delete
                                                        </mat-icon>
                                                        <span [style.background]="colors.pointBorderColor"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="colors.pointBorderColor"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        </span>
                                                        <mat-icon class="iconsize-12"
                                                            (click)="colors.pointBorderColor = ''">
                                                            delete
                                                        </mat-icon>
                                                    </div>

                                                </mat-expansion-panel>
                                            </mat-accordion>

                                            <mat-accordion>
                                                <mat-expansion-panel>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            Chart type
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <mat-form-field appearance="outline">
                                                        <mat-select [(ngModel)]="part.charttype"
                                                            (selectionChange)="detectchangetype(i)">
                                                            <mat-option value="line">line</mat-option>
                                                            <mat-option value="bar">bar</mat-option>
                                                            <mat-option value="radar">radar</mat-option>
                                                            <mat-option value="pie">pie</mat-option>
                                                            <mat-option value="polarArea">Polararea</mat-option>
                                                            <mat-option value="doughnut">Doughnut</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div fxLayout="column">
                                                        X-Axe
                                                        Gridline color
                                                        <input style="width: 60px"
                                                            [style.background]="part.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="part.lineChartOptions.scales.xAxes[0].gridLines.color"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        <mat-icon class="iconsize-12"
                                                            (click)="part.lineChartOptions.scales.xAxes[0].gridLines.color = ''">
                                                            delete
                                                        </mat-icon>
                                                        X-Axe
                                                        Font color
                                                        <input style="width: 60px"
                                                            [style.background]="part.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="part.lineChartOptions.scales.xAxes[0].ticks.fontColor"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        <mat-icon class="iconsize-12"
                                                            (click)="part.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                            delete
                                                        </mat-icon>
                                                        Y-axe
                                                        Gridline color
                                                        <input style="width: 60px"
                                                            [style.background]="part.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="part.lineChartOptions.scales.yAxes[0].gridLines.color"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        <mat-icon class="iconsize-12"
                                                            (click)="part.lineChartOptions.scales.yAxes[0].gridLines.color = ''">
                                                            delete
                                                        </mat-icon>
                                                        Y-axe
                                                        Font color
                                                        <input style="width: 60px"
                                                            [style.background]="part.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                            cpDialogDisplay="inline" [cpToggle]="true"
                                                            [(colorPicker)]="part.lineChartOptions.scales.yAxes[0].ticks.fontColor"
                                                            [cpPresetLabel]="'Company colors'" *ngIf="company"
                                                            [cpPresetColors]="[company.companyprimairycolor, company.companysecondarycolor]">
                                                        <mat-icon class="iconsize-12"
                                                            (click)="part.lineChartOptions.scales.xAxes[0].ticks.fontColor = ''">
                                                            delete
                                                        </mat-icon>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Select font lables</mat-label>
                                                            <mat-select
                                                                [(ngModel)]="part.lineChartOptions.legend.labels.fontFamily"
                                                                (selectionChange)="detectchange()">
                                                                <mat-option *ngFor="let font of Fonts"
                                                                    [value]="font.value">
                                                                    <label
                                                                        [style.font-family]="font.value">{{font.name}}</label>
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Select font X-axe</mat-label>
                                                            <mat-select
                                                                [(ngModel)]="part.lineChartOptions.scales.xAxes[0].ticks.fontFamily"
                                                                (selectionChange)="detectchange()">
                                                                <mat-option *ngFor="let font of Fonts"
                                                                    [value]="font.value">
                                                                    <label
                                                                        [style.font-family]="font.value">{{font.name}}</label>
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>Select font Y-axe</mat-label>
                                                            <mat-select
                                                                [(ngModel)]="part.lineChartOptions.scales.yAxes[0].ticks.fontFamily"
                                                                (selectionChange)="detectchange()">
                                                                <mat-option *ngFor="let font of Fonts"
                                                                    [value]="font.value">
                                                                    <label
                                                                        [style.font-family]="font.value">{{font.name}}</label>
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <input (change)="detectchange()"
                                                                [(ngModel)]="part.lineChartOptions.legend.labels.fontSize"
                                                                matInput>
                                                            <mat-label>Label -</mat-label>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <input (change)="detectchange()"
                                                                [(ngModel)]="part.lineChartOptions.scales.yAxes[0].ticks.fontSize"
                                                                matInput>
                                                            <mat-label>X -</mat-label>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline">
                                                            <input (change)="detectchange()"
                                                                [(ngModel)]="part.lineChartOptions.scales.xAxes[0].ticks.fontSize"
                                                                matInput>
                                                            <mat-label>Y -</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>

                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div> -->
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion *ngIf="whiteboard === true">
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title i18n>
                                    Draw
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="column">
                                <div fxLayout="row">
                                    <button mat-raised-button i18n-matTooltip matTooltip="Start Draw"
                                        (click)="startDraw()" color="primary">
                                        <mat-icon>create</mat-icon>
                                        <label i18n>Start Draw</label>
                                    </button>
                                    <button mat-raised-button i18n-matTooltip matTooltip="Rearrange"
                                        (click)="startDraggable()" color="primary">
                                        <mat-icon>open_with</mat-icon>
                                        <label i18n>Rearrange</label>
                                    </button>
                                </div>
                                <br>
                                <div fxLayout="row">
                                    <button mat-raised-button i18n-matTooltip matTooltip="Save drawing"
                                        (click)="savewhiteboard()">
                                        <mat-icon>save</mat-icon>
                                        <label i18n>Save</label>
                                    </button>
                                    <button mat-raised-button i18n-matTooltip matTooltip="Delete drawing"
                                        (click)="deletewhiteboard()">
                                        <mat-icon>delete</mat-icon>
                                        <label i18n>Delete</label>
                                    </button>
                                </div>
                                <br>

                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Shape</mat-label>
                                    <mat-select [(ngModel)]="shapedraw" i18n-placeholder placeholder="shape">
                                        <mat-option value="" i18n>Draw</mat-option>
                                        <mat-option value="rectangle" i18n>Rectangle</mat-option>
                                        <mat-option value="circle" i18n>Circly</mat-option>
                                        <mat-option value="figure" i18n>Figure</mat-option>
                                        <mat-option value="line" i18n>Line</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header i18n>
                                            Line Color
                                        </mat-expansion-panel-header>
                                        <div fxLayout="column">
                                            <mat-checkbox [(ngModel)]="patheditorService.line" i18n>Line</mat-checkbox>
                                            <input style="width: 60px;" [style.background]="whiteboardcolor"
                                                cpDialogDisplay="inline" [(colorPicker)]="whiteboardcolor"
                                                [cpPresetLabel]="'Company colors'" *ngIf="company" [cpToggle]="true"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header i18n>
                                            Fill Color
                                        </mat-expansion-panel-header>
                                        <div fxLayout="column">
                                            <mat-checkbox [(ngModel)]="patheditorService.fill" i18n>Fill</mat-checkbox>
                                            <input style="width: 60px;" [style.background]="whiteboardfillcolor"
                                                cpDialogDisplay="inline" [(colorPicker)]="whiteboardfillcolor"
                                                [cpPresetLabel]="'Company colors'" *ngIf="company" [cpToggle]="true"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="whiteboardstokewidth" type="number" matInput i18n-placeholder
                                        placeholder="Stroke">
                                </mat-form-field>


                                <mat-label i18n>Smoothness</mat-label>
                                <mat-slider horizontal min="1" max="20" step="step" [(ngModel)]="whiteboardsmoothing"
                                    value="value"></mat-slider>

                                <mat-checkbox [(ngModel)]="closewhiteboard" i18n-matTooltip
                                    matTooltip="Connect end-points" i18n>Close
                                </mat-checkbox>
                                <br>

                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </div>
        </mat-drawer>

        <!-- VIDEO PART ****************_______________________________ -->
        <div fxLayout="row" style="min-height: 500px;">
            <div id="scrollcontainer" class="scrollcontainer">

                <!-- <div style="width: 80px; height: 100%;"> -->

                <!-- </div> -->

                <div fxLayout="column" id="containernormal" style="width: 4000px; height: 4000px;"
                    fxLayoutAlign="center center">

                    <!-- START VIDEO SCREEN ****************_______________________________ -->

                    <div [style.width]="canvas.width" [style.height]="canvas.height" class="template-container-main"
                        id="mainvideocontainer">
                        <div *ngIf="changevideo" [attr.id]="'videobox'" style="position: relative;"
                            [class.starbackground]="canvas.weather === 'stars'"
                            [class.playvideoscreen]="playing || screenshottoggle"
                            [style.transform]="'scale('+zoomfactor+')'" [ngStyle]="canvas">
                            <!-- TEST EDIT OUT PUT BACK FOR DLCR!> overflow:hidden; -->

                            <div [style.background-image]="canvas['background-image-gradient']"
                                style="height: 100%; width: 100%; position: absolute; pointer-events: none;"></div>

                            <div *ngIf="!playing && !screenshottoggle" class="outlinevideo"></div>

                            <!-- selectedVecPath &&  -->
                            <video *ngIf="canvas.videourl" class="video-background" no-controls
                                [src]="videourl | safe: 'url'" #videoPlayer (click)="deSelectAll()"
                                id="videobgcontainer">
                            </video>

                            <audio *ngIf="canvas.audio" [attr.id]="'canvassound'">
                                <source type="audio/mp3" [src]="canvas.audio">
                            </audio>

                            <img *ngIf="canvas.videourl === '' && canvas.imageurl" id="imagebgcontainer"
                                style="position: absolute; pointer-events: none;" src={{canvas.imageurl}}
                                [style.width]="canvas.width" [style.height]="canvas.height">


                            <div style="height: 100%; width: 100%; position: absolute; z-index: 1000; pointer-events: none;"
                                id="weathercontainer"></div>

                            <!-- Stars -->
                            <div style="height: 100%; width: 100%; position: absolute; pointer-events: none;"
                                id="starscontainer">
                            </div>

                            <!-- Whiteboard -->
                            <div *ngIf="whiteboard === true" class="whiteboard" id="svgElementParent">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" id="svgElement" width="100%" height="100%"
                                    style="fill:transparent; stroke:black; stroke-width: 2px;"
                                    viewBox="0 0 500 500"></svg>
                            </div>

                            <!-- grid -->
                            <div fxLayout="row wrap"
                                style="height: 100%; width: 100%; position: absolute; z-index: 1; pointer-events: none;"
                                id="snapgrid">
                            </div>

                            <div *ngIf="animationarray && changenow" #myBounds id="myBounds"
                                style="overflow: hidden; background-color: transparent;">
                                <!-- 
                                <div *ngIf="element.type === 'threed' && !element.groupmember"
                                    [style.width]="element.style.width" [style.height]="element.style.height"
                                    [ngStyle]="element.style" (rzResizing)="onResizing($event, i)"
                                    [ngResizable]="element === selectedelement" [rzAspectRatio]="false"
                                    id="{{element.id}}" class="elementanimationarray"
                                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }">

                                    <div *ngIf="!element.src && !element.svg && !element.pretheme"
                                        style="height: 100%; width: 100%;" fxLayout="center center">
                                        <mat-progress-bar mode="determinate" color="blue" [value]="bufferValue">
                                        </mat-progress-bar>

                                    </div>
                                    <app-threed *ngIf="element.blobsrc || element.svg || element.pretheme"
                                        [element]="element" [primairytimeline]="primairytimeline" [account]="Account"
                                        [option]="option" [ngStyle]="element.style" [(systembusy)]="systembusy"
                                        [attr.id]="element.id + 'threed'" (emitter)="receiveDataFromThreed($event)"
                                        (emitterScreenshot)="threedScreenshot($event)">
                                    </app-threed>
                                    <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                        class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                    </mat-icon>
                                </div> -->

                                <div id="threedcontainer" style="width: 100%; height: 100%; position: absolute;">
                                    <app-threed [primairytimeline]="primairytimeline" [account]="Account"
                                        [option]="option" [systembusy]="systembusy" [canvas]="canvas"
                                        [selectedelement]="selectedelement"
                                        [globalThreedControls]="globalThreedControls" [animationarray]="animationarray"
                                        [selectanimationindex]="selectanimationindex"
                                        (emitterScreenshot)="threedScreenshot($event)"
                                        (emitterSystemBusy)="setBusyFromThreed($event)"
                                        (emitterGlobalControls)="setGlobalThreedControls($event)"
                                        (emitterCanvas)="setCanvas($event)"
                                        (emitterElement)="setSelectedElement($event)"
                                        (emitterPrimairytimeline)="setPrimaryTimeline($event)">
                                    </app-threed>
                                </div>


                                <div *ngFor="let element of animationarray; let i = index"
                                    (click)="onSelectElement($event, element)"
                                    (mousedown)="setDraggable($event, element)">

                                    <div *ngFor="let motion of element.motionpath; let im = index;">
                                        <div class="path-edit" [id]="element.id + '-' + im"
                                            [innerHTML]="motion | safeHtml" [style.width]="canvas.width"
                                            [style.height]="canvas.height"
                                            [ngStyle]="{'z-index':editpath === true && im === selectanimationindex ? 1000 : -1 }">
                                        </div>
                                    </div>

                                    <div *ngIf="element.type === 'image' && !element.groupmember" id="{{element.id}}"
                                        [attr.id]="element.id" [ngStyle]="element.style"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        (rzResizing)="onResizing($event, i)"
                                        [ngResizable]="element === selectedelement && !cropimages"
                                        [rzAspectRatio]="true" class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement">

                                        <!-- edit path crop/clip -->
                                        <div [ngStyle]="{'z-index':cropimages === true && element === selectedelement ? 1000 : -1 }"
                                            class="cropboard">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                [attr.id]="element.id + 'crop'" width="100%" height="100%"
                                                style="fill:transparent; stroke:black; stroke-width: 2px;"
                                                viewBox="0 0 500 500">
                                                <defs>
                                                    <!-- d={{element.clippath}}  d="M100,100 L150,100 L150,150 z" -->
                                                    <clipPath [attr.id]="element.id + 'cropclip'">
                                                        <path [attr.d]="element.clippath"
                                                            [attr.id]="element.id + 'croppath'" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <!-- 
                                        <img *ngIf="element.src" style="width: 100%; height: 100%;"
                                            [ngStyle]="{'opacity': element.opacity}" src={{element.src}}
                                            [class.imggreyclass]="element.grey" [class.imgblur]="element.blur"
                                            [style.clip-path]="element['clip-path']"> -->

                                        <img *ngIf="element.blobsrc && element.cliparray.length == 0"
                                            style="width: 100%; height: 100%;" [ngStyle]="{'opacity': element.opacity}"
                                            [src]="element.blobsrc" [class.imggreyclass]="element.grey"
                                            [class.imgblur]="element.blur" [style.clip-path]="element['clip-path']">
                                        <div *ngIf="element.cliparray.length > 0">
                                            <div *ngFor="let clippart of element.cliparray"
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                                <img [src]="element.blobsrc" [ngStyle]="clippart.style"
                                                    [style.clip-path]="element.style['clip-path']" id="{{clippart.id}}">
                                            </div>
                                        </div>

                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>


                                    <div *ngIf="element.type === 'embeddedvideo' && !element.groupmember"
                                        id="{{element.id}}" [attr.id]="element.id" [ngStyle]="element.style"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        (rzResizing)="onResizing($event, i)"
                                        [ngResizable]="element === selectedelement && !cropimages"
                                        class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement">

                                        <!-- edit path crop/clip -->
                                        <div [ngStyle]="{'z-index':cropimages === true && element === selectedelement ? 1000 : -1 }"
                                            class="cropboard">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                [attr.id]="element.id + 'crop'" width="100%" height="100%"
                                                style="fill:transparent; stroke:black; stroke-width: 2px;"
                                                viewBox="0 0 500 500">
                                                <defs>
                                                    <!-- d={{element.clippath}}  d="M100,100 L150,100 L150,150 z" -->
                                                    <clipPath [attr.id]="element.id + 'cropclip'">
                                                        <path [attr.d]="element.clippath"
                                                            [attr.id]="element.id + 'croppath'" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>

                                        <canvas style="width: 100%; height: 100%;" [class.hide]="!playing"
                                            [attr.id]="element.id + 'canvaseffectlayer'"
                                            *ngIf="element.effect"></canvas>

                                        <video [src]="element.blobsrc" [class.hide]="element.effect && playing"
                                            *ngIf="element.blobsrc && element.cliparray.length == 0"
                                            style="width: 100%; height: 100%;" [class.imggreyclass]="element.grey"
                                            [class.imgblur]="element.blur"
                                            [style.clip-path]="element['clip-path']"></video>

                                        <div *ngIf="element.cliparray.length > 0">
                                            <div *ngFor="let clippart of element.cliparray"
                                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                                <img [src]="element.blobsrc" [ngStyle]="clippart.style"
                                                    [style.clip-path]="element.style['clip-path']" id="{{clippart.id}}">
                                            </div>
                                        </div>

                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>

                                    <!-- <div [innerHTML]="element.content | safeHtml" (dblclick)="setEditText()"
                                        [ngResizable]="element === selectedelement"
                                        *ngIf="element.type === 'text' && !edittext && !element.groupmember"
                                        id="{{element.id}}" [attr.id]="element.id" (rzResizing)="onResizing($event, i)"
                                        [ngStyle]="element.style" [rzAspectRatio]="false" class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement">
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div> -->

                                    <p (dblclick)="setEditText()" [ngResizable]="element === selectedelement"
                                        *ngIf="element.type === 'text' && !edittext && !element.groupmember"
                                        id="{{element.id}}" [attr.id]="element.id" (rzResizing)="onResizing($event, i)"
                                        [ngStyle]="element.style" [rzAspectRatio]="false" class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement">
                                        <label>{{element.content}}</label>
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </p>
                                    <mat-form-field appearance="outline"
                                        *ngIf="element.type === 'text' && edittext && element === selectedelement && !element.groupmember"
                                        id="{{element.id}}" [attr.id]="element.id" [ngStyle]="element.style"
                                        class="elementanimationarray">
                                        <textarea matInput [(ngModel)]="element.content"
                                            style="width: 100%; height: 100%;"></textarea>
                                        <mat-icon (click)="setEditTextClose()" class="iconsize-12">save</mat-icon>
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </mat-form-field>

                                    <!-- https://stackoverflow.com/questions/43511437/how-to-use-ng2-ckeditor-as-inline -->

                                    <div *ngIf="element.type === 'shape' && !element.groupmember" id="{{element.id}}"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        (rzResizing)="onResizing($event, i)" [ngResizable]="element === selectedelement"
                                        [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                                        [class]='element.style.class' [rzAspectRatio]="element.shape === 'heart'"
                                        [ngStyle]="element.style">
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>

                                    <div *ngIf="element.type === 'form' && !element.groupmember" id="{{element.id}}"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        (rzResizing)="onResizing($event, i)" [ngResizable]="element === selectedelement"
                                        [rzAspectRatio]="false" [ngStyle]="element.style">
                                        <iframe
                                            style="border:0; height:100%; left:0; position:absolute; top:0; width:100%; pointer-events: none;"
                                            scrolling="no" frameborder="0" allowfullscreen
                                            [src]="element.formsrc | safe: 'resourceUrl'"></iframe>

                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>

                                    <div *ngIf="element.type === 'counter' && !element.groupmember" id="{{element.id}}"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        (rzResizing)="onResizing($event, i)" [ngResizable]="element === selectedelement"
                                        [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                                        [class]='element.style.class' [ngStyle]="element.style">

                                        <div *ngFor="let counteranim of element.countertype; let c = index;"
                                            class="counter-animation" [style.width]="element.style.width"
                                            [style.height]="element.style.height">
                                            <svg width="100%" height="100%" viewBox="0 0 240 240">
                                                <circle *ngIf="counteranim.counterstyle == 'circle'"
                                                    [attr.id]="element.id + 'svgcircle' + c" fill="transparent" r="100"
                                                    cx="120" cy="120" />
                                                <rect *ngIf="counteranim.counterstyle == 'square'" width="240"
                                                    height="240" [attr.id]="element.id + 'svgcircle' + c"
                                                    fill="transparent" />
                                                <line *ngIf="counteranim.counterstyle == 'linear'" x1="120" y1="240"
                                                    x2="120" y2="0" [attr.id]="element.id + 'svgcircle' + c"
                                                    fill="transparent" />
                                            </svg>
                                            <!-- [stroke]="countertype.drawcolor" [stroke-width]="countertype.linethickness" -->
                                        </div>
                                        <div class="counter-container" [attr.id]="element.id + 'num'"
                                            [style.line-height]="element.style.height"></div>
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>
                                    <!--   [style.transform]="'translate(' + element.posx + 'px, ' + element.posy +'px) rotate(' + element.rotation + 'deg)' | safe: 'style'"                    -->
                                    <div *ngIf="element.type === 'chart' && !element.groupmember"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        [ngStyle]="element.style" class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement"
                                        (rzResizing)="onResizing($event, i)" [ngResizable]="element === selectedelement"
                                        [rzAspectRatio]="false" id="{{element.id}}">
                                        <canvas baseChart width="element.style.width" height="element.style.height"
                                            [datasets]="element.productiondata" [labels]="element.label"
                                            [colors]="element.colors" [legend]="element.legend"
                                            [options]="element.lineChartOptions" [chartType]="element.charttype"
                                            [attr.id]="'chart' + element.id" (chartClick)="chartClicked($event, this)">
                                        </canvas>
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>
                                    <div *ngIf="element.type === 'vector' && !element.groupmember" style.filter="none"
                                        [style.width]="element.style.width" [style.height]="element.style.height"
                                        [ngStyle]="element.style" (rzResizing)="onResizing($event, i)"
                                        [ngResizable]="element === selectedelement" [rzAspectRatio]="false"
                                        id="{{element.id}}" class="elementanimationarray"
                                        [class.elementanimationarrayselected]="element === selectedelement">
                                        <div style="height: 100%; width: 100%; "
                                            [innerHTML]="element.svgcombi | safeHtml"
                                            (click)="vectorService.clickVectorPaths($event, element)">
                                        </div>
                                        <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                            class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                        </mat-icon>
                                    </div>



                                    <!-- __________________________________Start Combibox  (put in on own component)?_________________________________ -->
                                    <!-- element.groupmember === part.groupmember -->
                                    <!-- Create combi box by tracking drag on related items?  -->
                                    <!-- <div *ngIf="element.type === 'vectorcombi'" [style.width]="element.style.width"
                                        [style.height]="element.style.height" id="{{element.id}}"
                                        [ngStyle]="element.style"
                                        [ngClass]="{elementanimationarrayselected: element === selectedelement, editcombi: vectorcombiedit, elementanimationarray: true}">
                                        <div *ngFor="let part of element.vectors; let idy = index;"
                                            (click)="onSelectElement($event, part)"
                                            (mousedown)="setDraggable($event, part)">

                                            <div *ngIf="part.type === 'image' && part.groupmember" [attr.id]="part.id"
                                                [ngStyle]="part.style" [style.width]="part.style.width"
                                                [style.height]="part.style.height" (rzResizing)="onResizing($event, i)"
                                                [ngResizable]="part === selectedelement" [rzAspectRatio]="true"
                                                class="elementanimationarray"
                                                [class.elementanimationarrayselected]="part === selectedelement && vectorcombiedit">
                                                <div [ngStyle]="{'z-index':cropimages === true && part === selectedelement ? 1000 : -1 }"
                                                    class="whiteboard">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                        [attr.id]="part.id + 'crop'" width="100%" height="100%"
                                                        style="fill:transparent; stroke:black; stroke-width: 2px;"
                                                        viewBox="0 0 500 500">
                                                        <defs>
                                                            <clipPath [attr.id]="part.id + 'cropclip'">
                                                                <path [attr.d]="part.clippath"
                                                                    [attr.id]="part.id + 'croppath'" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <img *ngIf="part.src && part.cliparray.length === 0"
                                                    style="width: 100%; height: 100%;"
                                                    [ngStyle]="{'opacity': part.opacity}" src={{part.src}}
                                                    [class.imggreyclass]="part.grey" [class.imgblur]="part.blur">
                                                <div *ngIf="part.cliparray.length > 0">
                                                    <div *ngFor="let clippart of part.cliparray"
                                                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                                        <img src={{part.src}} [ngStyle]="clippart.style"
                                                            id="{{clippart.id}}">
                                                    </div>
                                                </div>
                                                <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                    class="iconsize-4" class="rotatehandle"
                                                    [attr.id]="part.id + 'rotatehandle'">replay
                                                </mat-icon>
                                            </div>
                                            <label (dblclick)="setEditText()" [ngResizable]="part === selectedelement"
                                                *ngIf="part.type === 'text' && !edittext && part.groupmember"
                                                id="{{part.id}}" [attr.id]="part.id"
                                                (rzResizing)="onResizing($event, i)" [ngStyle]="part.style"
                                                [rzAspectRatio]="false" class="elementanimationarray"
                                                [class.elementanimationarrayselected]="part === selectedelement">{{part.content}}
                                                <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                    class="iconsize-4" class="rotatehandle"
                                                    [attr.id]="part.id + 'rotatehandle'">replay
                                                </mat-icon>
                                            </label>
                                            <mat-form-field appearance="outline"
                                                *ngIf="part.type === 'text' && edittext && part === selectedelement && part.groupmember"
                                                id="{{part.id}}" [attr.id]="part.id" [ngStyle]="part.style"
                                                class="elementanimationarray">
                                                <textarea matInput [(ngModel)]="part.content"
                                                    style="width: 100%; height: 100%;"></textarea>
                                                <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                    class="iconsize-4" class="rotatehandle"
                                                    [attr.id]="part.id + 'rotatehandle'">replay
                                                </mat-icon>
                                            </mat-form-field>
                                            <div *ngIf="part.type === 'shape' && part.groupmember" id="{{part.id}}"
                                                [style.width]="part.style.width" [style.height]="part.style.height"
                                                (rzResizing)="onResizing($event, i)"
                                                [ngResizable]="part === selectedelement"
                                                [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                                                [class]='part.style.class' [rzAspectRatio]="part.shape === 'heart'"
                                                [ngStyle]="part.style">
                                                <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                    class="iconsize-4" class="rotatehandle"
                                                    [attr.id]="part.id + 'rotatehandle'">replay
                                                </mat-icon>
                                            </div>
                                            <div *ngIf="part.type === 'counter' && !part.groupmember" id="{{part.id}}"
                                                [style.width]="part.style.width" [style.height]="part.style.height"
                                                (rzResizing)="onResizing($event, i)"
                                                [ngResizable]="part === selectedelement"
                                                [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                                                [class]='part.style.class' [ngStyle]="part.style">

                                                <div *ngFor="let counteranim of part.countertype; let c = index;"
                                                    class="counter-animation" [style.width]="part.style.width"
                                                    [style.height]="part.style.height">
                                                    <svg width="100%" height="100%" viewBox="0 0 240 240">
                                                        <circle *ngIf="counteranim.counterstyle == 'circle'"
                                                            [attr.id]="part.id + 'svgcircle' + c" fill="transparent"
                                                            r="100" cx="120" cy="120" />
                                                        <rect *ngIf="counteranim.counterstyle == 'square'" width="240"
                                                            height="240" [attr.id]="part.id + 'svgcircle' + c"
                                                            fill="transparent" />
                                                        <line *ngIf="counteranim.counterstyle == 'linear'" x1="120"
                                                            y1="240" x2="120" y2="0"
                                                            [attr.id]="part.id + 'svgcircle' + c" fill="transparent" />
                                                    </svg>
                                                </div>
                                                <div *ngIf="part.type === 'chart' && part.groupmember"
                                                    [style.width]="part.style.width" [style.height]="part.style.height"
                                                    [ngStyle]="part.style" class="elementanimationarray"
                                                    [class.elementanimationarrayselected]="part === selectedelement"
                                                    (rzResizing)="onResizing($event, i)"
                                                    [ngResizable]="part === selectedelement" [rzAspectRatio]="false"
                                                    id="{{part.id}}">
                                                    <canvas baseChart width="part.style.width"
                                                        height="part.style.height" [datasets]="part.productiondata"
                                                        [labels]="part.label" [colors]="part.colors"
                                                        [legend]="part.legend" [options]="part.lineChartOptions"
                                                        [chartType]="part.charttype" [attr.id]="'chart' + part.id">
                                                    </canvas>
                                                    <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                        class="iconsize-4" class="rotatehandle"
                                                        [attr.id]="part.id + 'rotatehandle'">replay
                                                    </mat-icon>
                                                </div>
                                                <div *ngIf="part.type === 'vector' && part.groupmember"
                                                    [style.width]="part.style.width" [style.height]="part.style.height"
                                                    [ngStyle]="part.style" (rzResizing)="onResizing($event, i)"
                                                    [ngResizable]="vectorcombiedit && part === selectedelement"
                                                    [rzAspectRatio]="false" id="{{part.id}}"
                                                    class="elementanimationarray"
                                                    [ngStyle]="{'z-index':vectorcombiedit === true && element === selectedelement ? 1000 : -1 }"
                                                    [class.elementanimationarrayselected]="part === selectedelement && vectorcombiedit">
                                                    <div style="height: 100%; width: 100%;"
                                                        [innerHTML]="part.svgcombi | safeHtml"
                                                        (click)="vectorService.clickVectorPaths($event, element)">
                                                    </div>
                                                    <mat-icon *ngIf="vectorcombiedit && part === selectedelement"
                                                        class="iconsize-4" class="rotatehandle"
                                                        [attr.id]="part.id + 'rotatehandle'">replay
                                                    </mat-icon>
                                                </div>
                                            </div>
                                            <div *ngFor="let partpart of part.animation; let i = index;">
                                                <audio *ngIf="partpart.audioeffectsrc" [attr.id]="part.id + i + 's'">
                                                    <source type="audio/mp3" [src]="partpart.audioeffectsrc">
                                                </audio>
                                            </div>
                                            <mat-icon *ngIf="element === selectedelement" class="iconsize-4"
                                                class="rotatehandle" [attr.id]="element.id + 'rotatehandle'">replay
                                            </mat-icon>

                                        </div>

                                    </div> -->
                                    <!-- style="overflow: hidden;"  -->
                                    <!-- not visible object to connect sound to  -->
                                    <div *ngFor="let part of element.animation; let i = index;">
                                        <audio *ngIf="part.audioeffectsrc" [attr.id]="element.id + i + 's'">
                                            <source type="audio/mp3" [src]="part.audioeffectsrc">
                                        </audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END VIDEO SCREEN ****************_______________________________ -->
                </div>
            </div>

            <!-- SPLIT SCREEN ANIMATION OVERVIEW -->
            <mat-card *ngIf="splitscreen" style="height: 80vh; z-index: 10; width: 400px;" class="splitscreen">
                <div fxLayout="row" fxLayoutAlign="space-between start">

                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-icon color="primary">rotate_90_degrees_cw</mat-icon>
                        <h2 i18n>Animations</h2>
                    </div>


                    <button mat-mini-fab *ngIf="selectedelement" i18n-matTooltip matTooltip="Add animation to selection"
                        (click)="addNewEffectTopBar()">
                        <mat-icon>add</mat-icon>
                    </button>
                    <mat-icon (click)="openSplitScreen()" i18n>close</mat-icon>
                </div>

                <div *ngFor="let element of animationarray; let i = index;" style="overflow: scroll">
                    <div *ngFor="let animation of element.animation; let i = index; let $last = last" [isLast]='$last'
                        (ngInit)="initTimelineSidebar(element.animation, i)">
                        <label fxFlex="50px">{{animation.anim_type}}</label>
                        <mat-icon fxFlex="20px" (click)="deleteEffect(i)" i18n-matTooltip matTooltip="Delete effect"
                            class="iconsize-10">delete
                        </mat-icon>
                        <mat-icon fxFlex="20px" (click)="copyEffect(i)" i18n-matTooltip matTooltip="Duplicate effect"
                            class="iconsize-10">
                            filter_none</mat-icon>

                        <!-- container == 100% == counter / 6000 * 100   -->

                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound" i18n-matTooltip matTooltip="starttime: {{animation.start_time}}">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'" [inBounds]="true"
                                    [bounds]="timelinebound" [lockAxis]="'y'" ngDraggable
                                    (movingOffset)="onMovingTimeline($event)" [scale]="'1'"></div>

                                <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                    (movingOffset)="onMovingAnimationEl($event, animation)" [lockAxis]="'y'" ngResizable
                                    [inBounds]="true" [bounds]="timelinebound" [rzHandles]="'e,w'"
                                    (rzResizing)="onResizeAnimationEl($event, i, animation)" [id]="'timeline-side' + i"
                                    [style.width]="animation.duration * 10 + 'px'">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let vectoranimation of element.vectoranimation; let iv = index; let $last = last"
                        [isLast]='$last' (ngInit)="initTimelineSidebar(element.vectoranimation)"
                        (click)="setSelectedAnimation(vectoranimation, iv)">
                        <label fxFlex="50px">{{vectoranimation.svganimationtype}}</label>
                        <mat-icon fxFlex="20px"
                            (click)="vectorService.deleteVectorAnimation(iv, selectedelement.vectoranimation, selectedelement)"
                            class="iconsize-10">delete</mat-icon>
                        <mat-icon fxFlex="20px" (click)="copyVectorEffect(iv)" i18n-matTooltip
                            matTooltip="Duplicate effect" class="iconsize-10">
                            filter_none</mat-icon>
                        <div class="timeline-container" [style.width]="'6000px'" style="overflow: scroll">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound" i18n-matTooltip
                                matTooltip="starttime: {{vectoranimation.start_time}}">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'" [inBounds]="true"
                                    i18n-matTooltip matTooltip="{{currenttime}}" [bounds]="timelinebound"
                                    [lockAxis]="'y'" ngDraggable (movingOffset)="onMovingTimeline($event)">
                                </div>
                                <!-- i18n-matTooltip matTooltip="{{currenttime.start_time}}" -->
                                <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                    (movingOffset)="onMovingAnimationEl($event, vectoranimation)" [lockAxis]="'y'"
                                    ngResizable [rzHandles]="'e,w'" [inBounds]="true" [bounds]="timelinebound"
                                    (rzResizing)="onResizeAnimationEl($event, iv, vectoranimation)"
                                    [id]="'timeline-side' + i" [style.width]="vectoranimation.duration * 10 + 'px'">
                                </div>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="element.type === 'chart'">
                        <label fxFlex="50px" i18n>chart</label>
                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                </div>
                                <div class="timeline-currentelement" ngDraggable
                                    (movingOffset)="onMovingAnimationChart($event, element)" [bounds]="timelinebound"
                                    [inBounds]="true" [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'"
                                    [id]="'timeline-side' + iv" (rzResizing)="onResizeAnimationChart($event, element)"
                                    [style.width]="element.lineChartOptions.animation.duration / 100 + 'px'">
                                    <!--   [style.left]="element.lineChartOptions.animation.start_time * 10 + 'px'" -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="element.type === 'embeddedvideo'">
                        <label fxFlex="50px" i18n>Video</label>
                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                </div>
                                <div class="timeline-currentelement" ngDraggable
                                    (movingOffset)="onMovingAnimationEl($event, element)" [bounds]="timelinebound"
                                    [inBounds]="true" [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'"
                                    [id]="'timeline-side' + iv" (rzResizing)="onResizeAnimationEl($event, element)"
                                    [style.width]="element.duration / 100 + 'px'">
                                    <!--   [style.left]="element.lineChartOptions.animation.start_time * 10 + 'px'" -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let counteranimation of element.countertype; let iv = index let $last = last"
                        [isLast]='$last' (ngInit)="initTimelineSidebar(element.countertype)">
                        <label fxFlex="50px">{{counteranimation.countertype}}</label>
                        <mat-icon fxFlex="20px" (click)="deletecounteranimation(iv)" class="iconsize-10">delete
                        </mat-icon>
                        <mat-icon fxFlex="20px" (click)="copyCounterEffect(iv)" i18n-matTooltip
                            matTooltip="Duplicate effect" class="iconsize-10">
                            filter_none</mat-icon>
                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                </div>

                                <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                    (movingOffset)="onMovingAnimationEl($event, counteranimation)" [lockAxis]="'y'"
                                    ngResizable [rzHandles]="'e,w'" [inBounds]="true" [bounds]="timelinebound"
                                    (rzResizing)="onResizeAnimationEl($event, iv, counteranimation)"
                                    [id]="'timeline-side' + iv" [style.width]="counteranimation.duration * 10 + 'px'">
                                </div>

                            </div>
                        </div>

                    </div>

                    <div *ngFor="let textanimation of element.splittextanimation; let iv = index; let $last = last"
                        [isLast]='$last' (ngInit)="initTimelineSidebar(element.splittextanimation)">
                        <label fxFlex="50px">{{textanimation.textanimationtype}}</label>
                        <mat-icon fxFlex="20px" (click)="deleteTextAnimation(iv)" class="iconsize-10">delete</mat-icon>
                        <mat-icon fxFlex="20px" (click)="copyEffect(iv)" i18n-matTooltip matTooltip="Duplicate effect"
                            class="iconsize-10">
                            filter_none</mat-icon>
                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                </div>
                                <div class="timeline-currentelement" ngDraggable
                                    (movingOffset)="onMovingAnimationEl($event, textanimation)" [lockAxis]="'y'"
                                    ngResizable [rzHandles]="'e, w'" [inBounds]="true" [bounds]="timelinebound"
                                    (rzResizing)="onResizeAnimationEl($event, iv, textanimation)"
                                    [id]="'timeline-side' + iv" [style.width]="textanimation.duration * 10 + 'px'">
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngFor="let threedanimationcontrol of element.threedanimations; let iv = index; let $last = last"
                        [isLast]='$last' (ngInit)="initTimelineSidebar(element.threedanimations)">
                        <label fxFlex="50px">{{threedanimationcontrol.textanimationtype}}</label>
                        <mat-icon fxFlex="20px" (click)="deletethreedanimationcontrol(iv)" class="iconsize-10">delete
                        </mat-icon>
                        <mat-icon fxFlex="20px" (click)="copyEffect(iv)" i18n-matTooltip matTooltip="Duplicate effect"
                            class="iconsize-10">
                            filter_none</mat-icon>
                        <div class="timeline-container" [style.width]="'6000px'">
                            <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                id="timelinebound">
                                <div class="timeline-setter"
                                    [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                </div>
                                <div class="timeline-currentelement" ngDraggable
                                    (movingOffset)="onMovingAnimationEl($event, threedanimationcontrol)"
                                    [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'" [inBounds]="true"
                                    [bounds]="timelinebound"
                                    (rzResizing)="onResizeAnimationEl($event, iv, threedanimationcontrol)"
                                    [id]="'timeline-side' + iv"
                                    [style.width]="threedanimationcontrol.duration * 10 + 'px'">
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </mat-card>
        </div>
    </mat-drawer-container>


</div>

<!-- ANIMATIONS/LOWER BANNER -->
<button mat-raised-button color="primary" (click)="addNewEffect()" style="width:100%;"
*ngIf="selectedelement && selectedelement?.type !== 'threed'" i18n>Add new effect</button>
<div class="lower-banner-container" id="lowerbannercontainer" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
    <div *ngIf="selectedelement" fxLayout="column">


        <div class="animation-container">


            <div *ngFor="let animation of selectedelement.animation; let i = index; let $last = last" [isLast]='$last'
                (ngInit)="initTimeline(selectedelement.animation)" (click)="setSelectedAnimation(animation, i)">
                <mat-accordion style="width: 100%">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <label fxFlex="100px">{{animation.anim_type}}</label>
                                <mat-icon fxFlex="20px" (click)="deleteEffect(i)" i18n-matTooltip
                                    matTooltip="Delete effect" class="iconsize-10">delete
                                </mat-icon>
                                <mat-icon fxFlex="20px" (click)="copyEffect(i)" i18n-matTooltip
                                    matTooltip="Duplicate effect" class="iconsize-10">
                                    filter_none</mat-icon>

                                <div class="timeline-container" [style.width]="'6000px'">
                                    <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                        id="timelinebound">
                                        <div class="timeline-setter"
                                            [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'"></div>

                                        <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                            (movingOffset)="onMovingAnimationEl($event, animation)" [lockAxis]="'y'"
                                            ngResizable [inBounds]="true" [bounds]="timelinebound" [rzHandles]="'e,w'"
                                            (rzResizing)="onResizeAnimationEl($event, i, animation)"
                                            [id]="'timeline' + i"
                                            [style.transform]="'translate(' + animation.start_time * 10 + 'px, 0px)'"
                                            [style.width]="animation.duration * 10 + 'px'">
                                            <!--  [style.left]="animation.start_time * 10 + 'px'" -->
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div fxLayout="row wrap" fxLayoutGap="20px">
                            <!-- <label>Effects &nbsp;</label> -->
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Animation type</mat-label>
                                <mat-select [(ngModel)]="animation.anim_type" (selectionChange)="detectchange()">
                                    <mat-option value="rotation" i18n>Rotate</mat-option>
                                    <!-- <mat-option value="translate">Translate</mat-option> -->
                                    <mat-option value="scale" i18n>Scale</mat-option>
                                    <mat-option value="skew" i18n>Skew</mat-option>
                                    <mat-option value="appear" i18n>Appear</mat-option>
                                    <mat-option value="3drotate" i18n>3drotate</mat-option>
                                    <mat-option value="bounce" i18n>Bounce</mat-option>
                                    <mat-option value="move" i18n>Move</mat-option>
                                    <mat-option value="fountain" i18n>Fountain</mat-option>
                                    <mat-option value="followminions" i18n>Minions</mat-option>
                                    <mat-option value="fly-in" i18n>Fly-in</mat-option>
                                    <mat-option *ngIf="selectedelement.type === 'image'" value="shatter" i18n>Shatter
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Ease type</mat-label>
                                <mat-select [(ngModel)]="animation.easetype" (selectionChange)="detectchange()">
                                    <mat-option value="">None</mat-option>
                                    <mat-option value="power1">Force 1</mat-option>
                                    <mat-option value="power1in">Force 1 start</mat-option>
                                    <mat-option value="power2">Force 2</mat-option>
                                    <mat-option value="power3">Force 3</mat-option>
                                    <mat-option value="bounce">Bounce</mat-option>
                                    <mat-option value="bouncein">Bounce Start</mat-option>
                                    <mat-option value="elastic">Elastic</mat-option>
                                    <mat-option value="elasticin">Elastic Start</mat-option>
                                    <mat-option value="circle">Circle</mat-option>
                                    <mat-option value="circlein">Circle In</mat-option>
                                    <mat-option value="sine">Sine</mat-option>
                                    <mat-option value="sinein">Sine Start</mat-option>
                                    <mat-option value="over">Over</mat-option>
                                    <mat-option value="easy">Easy</mat-option>
                                    <mat-option value="rough">Rough</mat-option>
                                    <mat-option value="steps">Steps</mat-option>
                                    <mat-option value="slowmotion">Slowmotion</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label i18n>Animate to our from</mat-label>
                                <mat-select [(ngModel)]="animation.fromto" (selectionChange)="detectchange()">
                                    <mat-option value="from" i18n>From</mat-option>
                                    <mat-option value="to" i18n>To</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="animation.duration" type="number" step="0.1" min="0" matInput
                                    (change)="detectchange()" name="animationduration">
                                <mat-label i18n>Duration</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="animation.start_time" type="number" step="0.1" min="0" matInput
                                    (change)="detectchange()" name="starttime">
                                <mat-label i18n>Start</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="animation.repeat" type="number" step="1" min="-1" matInput
                                    (change)="detectchange()" name="">
                                <mat-label i18n>repeat</mat-label>
                            </mat-form-field>
                            <mat-form-field *ngIf="animation.repeat === -1" appearance="outline" i18n-matTooltip
                                matTooltip="delay for infinite repeat">
                                <input [(ngModel)]="animation.delay" type="number" step="0.1" min="0" matInput>
                                <mat-label i18n>delay</mat-label>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="animation.yoyo" (change)="detectchange()" i18n-matTooltip
                                matTooltip="Reset when repeated">Fluent</mat-checkbox>
                            <div *ngIf="animation.anim_type === 'rotation'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.rotationcycle" type="number" step="0.1" min="0"
                                        matInput (change)="detectchange()">
                                    <mat-label i18n>Rotation</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginX" matInput (change)="detectchange()">
                                    <mat-label>%/px top</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginY" matInput (change)="detectchange()">
                                    <mat-label>%/px bottom</mat-label>
                                </mat-form-field>
                            </div>
                            <div *ngIf="animation.anim_type === 'scale'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.scalesize" type="number" step="0.1" min="0" matInput>
                                    <mat-label i18n>Scale</mat-label>
                                </mat-form-field>
                            </div>

                            <div *ngIf="animation.anim_type === 'bounce'" fxLayoutGap="20px">


                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.rotationcycle" matInput
                                        [disabled]="animation.rotationkeeppos">
                                    <mat-label i18n>Motion direction</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginX" matInput (change)="detectchange()">
                                    <mat-label i18n>%/px right</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginY" matInput (change)="detectchange()">
                                    <mat-label i18n>%/px bottom</mat-label>
                                </mat-form-field>
                                <mat-checkbox [(ngModel)]="animation.rotationkeeppos" i18n-matTooltip
                                    matTooltip="Keep position" i18n>Don't
                                    rotate</mat-checkbox>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="standardpath" (selectionChange)="resetPath(animation)"
                                        i18n-placeholder placeholder="Animation">
                                        <mat-option value="linear" i18n>Linear</mat-option>
                                        <mat-option value="circle" i18n>Circle</mat-option>
                                        <mat-option value="square i18n">Square</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <button mat-button (click)="editMotionPath(animation, i)" i18n>Edit path</button>
                            </div>

                            <div *ngIf="animation.anim_type === 'skew'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.skewY" type="number" matInput>
                                    <mat-label i18n>Y-axe</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.skewX" type="number" matInput>
                                    <mat-label i18n>X-axe</mat-label>
                                </mat-form-field>
                                <button mat-button
                                    (click)="animation.travellocY = selectedelement.posy; animation.travellocX = selectedelement.posx"
                                    i18n>Reset</button>
                            </div>

                            <div *ngIf="animation.anim_type === '3drotate' || animation.anim_type === 'shatter'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginY" type="number" matInput>
                                    <mat-label i18n>Y-axe</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginX" type="number" matInput>
                                    <mat-label i18n>X-axe</mat-label>
                                </mat-form-field>
                            </div>

                            <div *ngIf="animation.anim_type === 'move'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.travellocY" type="number" matInput>
                                    <mat-label i18n>Y-offset</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.travellocX" type="number" matInput>
                                    <mat-label i18n>X-offset</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.rotationcycle" matInput
                                        [disabled]="animation.rotationkeeppos">
                                    <mat-label i18n>Motion direction</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginX" matInput>
                                    <mat-label i18n>%/px right</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginY" matInput>
                                    <mat-label i18n>%/px bottom</mat-label>
                                </mat-form-field>
                                <mat-checkbox [(ngModel)]="animation.rotationkeeppos" i18n-matTooltip
                                    matTooltip="Keep position" i18n>Don't
                                    rotate</mat-checkbox>
                                <!-- <button mat-button
                                    (click)="animation.travellocY = selectedelement.posy; animation.travellocX = selectedelement.posx">Reset</button> -->


                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="standardpath" (selectionChange)="resetPath(animation)"
                                        i18n-placeholder placeholder="Animation">
                                        <mat-option value="linear" i18n>Linear</mat-option>
                                        <mat-option value="circle" i18n>Circle</mat-option>
                                        <mat-option value="square" i18n>Square</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <!-- <button mat-button (click)="resetPath()">Set path</button> -->
                                <button mat-button (click)="editMotionPath(animation, i)">Edit path</button>
                                <!-- <button mat-button (click)="saveNewMotionPath(animation)">Save path</button> -->
                            </div>

                            <div *ngIf="animation.anim_type === 'followminions'">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.travellocY" type="number" matInput>
                                    <mat-label i18n>Y-axe</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.travellocX" type="number" matInput>
                                    <mat-label i18n>X-axe</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginX" matInput (change)="detectchange()">
                                    <mat-label i18n>%/px right</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="animation.transformOriginY" matInput (change)="detectchange()">
                                    <mat-label i18n>%/px bottom</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="standardpath" (selectionChange)="resetPath(animation)"
                                        i18n-placeholder placeholder="Animation">
                                        <mat-option value="linear" i18n>Linear</mat-option>
                                        <mat-option value="circle" i18n>Circle</mat-option>
                                        <mat-option value="square" i18n>Square</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-button (click)="editMotionPath(animation, i)" i18n>Edit path</button>
                            </div>

                            <div>
                                <app-audioupload *ngIf="!animation.audioeffectsrc" [account]="Account" [option]="option"
                                    (imgurl)="setAudio($event, animation)">
                                </app-audioupload>
                                <label *ngIf="animation.audioeffectsrc">{{ animation.audioeffectsrc | fileName
                                    }}</label>
                                <mat-icon *ngIf="animation.audioeffectsrc" (click)="animation.audioeffectsrc = ''"
                                    class="iconsize-10" i18n>delete
                                </mat-icon>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>


            <div *ngIf="selectedelement.type === 'vector' && selectedelement.animate" fxLayout="column">
                <div *ngFor="let vectoranimation of selectedelement.vectoranimation; let iv = index; let $last = last"
                    [isLast]='$last' (ngInit)="initTimeline(selectedelement.vectoranimation, iv)"
                    (click)="setSelectedAnimation(vectoranimation, iv)">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title fxLayout="row">
                                    <label fxFlex="100px">{{vectoranimation.svganimationtype}}</label>
                                    <mat-icon fxFlex="20px"
                                        (click)="vectorService.deleteVectorAnimation(iv, selectedelement.vectoranimation, selectedelement)"
                                        class="iconsize-10">delete</mat-icon>
                                    <mat-icon fxFlex="20px" (click)="copyVectorEffect(iv)" i18n-matTooltip
                                        matTooltip="Duplicate effect" class="iconsize-10">
                                        filter_none</mat-icon>
                                    <div class="timeline-container" [style.width]="'6000px'">
                                        <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                            id="timelinebound">
                                            <div class="timeline-setter"
                                                [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                            </div>

                                            <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                                (movingOffset)="onMovingAnimationEl($event, vectoranimation)"
                                                [lockAxis]="'y'" ngResizable [rzHandles]="'e,w'" [inBounds]="true"
                                                [bounds]="timelinebound"
                                                (rzResizing)="onResizeAnimationEl($event, iv, vectoranimation)"
                                                [id]="'timeline' + iv"
                                                [style.transform]="'translate(' + vectoranimation.start_time * 10 + 'px, 0px)'"
                                                [style.width]="vectoranimation.duration * 10 + 'px'">
                                            </div>

                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="row wrap" fxLayoutGap="20px">
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="vectoranimation.duration" type="number" step="0.1" min="0"
                                        matInput>
                                    <mat-label i18n>Duration</mat-label>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input [(ngModel)]="vectoranimation.start_time" type="number" step="0.1" min="0"
                                        matInput>
                                    <mat-label i18n>Start</mat-label>
                                </mat-form-field>


                                <mat-form-field appearance="outline"
                                    *ngIf="vectoranimation.svganimationtype !== 'color' && vectoranimation.svganimationtype !== 'changepath'">
                                    <!--  (selectionChange)="detectchange()" -->
                                    <mat-select [(ngModel)]="vectoranimation.svganimationtype"
                                        (selectionChange)="detectMorph(vectoranimation.svganimationtype)"
                                        i18n-placeholder placeholder="Animation">
                                        <mat-option value="" i18n>None</mat-option>
                                        <mat-option value="draw" i18n>Draw</mat-option>
                                        <mat-option value="morph" i18n>Morph</mat-option>
                                        <!-- <mat-option value="liquid">Liquid fill</mat-option>  NEEDS UPDATING!! -->
                                        <!-- <mat-option value="color">Color fill</mat-option> -->
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" i18n-matTooltip
                                    matTooltip="set to -1 for infinite repeat">
                                    <input [(ngModel)]="vectoranimation.repeat" type="number" step="1" min="-1" matInput
                                        (change)="detectchange()">
                                    <mat-label i18n>repeat</mat-label>
                                </mat-form-field>

                                <mat-form-field *ngIf="vectoranimation.repeat === -1" appearance="outline"
                                    i18n-matTooltip matTooltip="delay for infinite repeat">
                                    <input [(ngModel)]="vectoranimation.delay" type="number" step="0.1" min="0"
                                        matInput>
                                    <mat-label i18n>delay</mat-label>
                                </mat-form-field>

                                <mat-checkbox [(ngModel)]="vectoranimation.yoyo" (change)="detectchange()"
                                    i18n-matTooltip matTooltip="Reset when repeated">Fluent</mat-checkbox>
                                <!-- [disabled]="vectoranimation.repeat !== -1 -->
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="vectoranimation.easetype"
                                        (selectionChange)="detectchange()" i18n-placeholder placeholder="Animation">
                                        <mat-option value="">None</mat-option>
                                        <mat-option value="power1">Force 1</mat-option>
                                        <mat-option value="power1in">Force 1 start</mat-option>
                                        <mat-option value="power2">Force 2</mat-option>
                                        <mat-option value="power3">Force 3</mat-option>
                                        <mat-option value="bounce">Bounce</mat-option>
                                        <mat-option value="bouncein">Bounce Start</mat-option>
                                        <mat-option value="elastic">Elastic</mat-option>
                                        <mat-option value="elasticin">Elastic Start</mat-option>
                                        <mat-option value="circle">Circle</mat-option>
                                        <mat-option value="circlein">Circle In</mat-option>
                                        <mat-option value="sine">Sine</mat-option>
                                        <mat-option value="sinein">Sine Start</mat-option>
                                        <mat-option value="over">Over</mat-option>
                                        <mat-option value="easy">Easy</mat-option>
                                        <mat-option value="rough">Rough</mat-option>
                                        <mat-option value="steps">Steps</mat-option>
                                        <mat-option value="slowmotion">Slowmotion</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="vectoranimation.fromto" (selectionChange)="detectchange()">
                                        <mat-option value="from" i18n>From</mat-option>
                                        <mat-option value="to" i18n>To</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div
                                    *ngIf="vectoranimation.svganimationtype !== 'color' && vectoranimation.svganimationtype !== 'changepath'">
                                    <mat-checkbox [(ngModel)]=vectoranimation.hideimage i18n>Hide Image</mat-checkbox>
                                </div>
                                <!-- (click)="setSvgOpacity(selectedelement, idx)" -->
                                <div *ngIf="vectoranimation.svganimationtype">

                                    <div *ngIf="vectoranimation.svganimationtype === 'draw'" fxLayout="row">

                                        <!-- <mat-slide-toggle [(ngModel)]=vectoranimation.yoyo fxFlex (change)="detectchange()">Yoyo
                                    effect</mat-slide-toggle> -->

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.repeat" type="number" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Repeat</mat-label>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="vectoranimation.repeat === -1" appearance="outline"
                                            i18n-matTooltip matTooltip="delay for infinite repeat">
                                            <input [(ngModel)]="vectoranimation.delay" type="number" step="0.1" min="0"
                                                matInput>
                                            <mat-label i18n>delay</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.linethickness" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Thickness</mat-label>
                                        </mat-form-field>

                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title i18n>
                                                        Draw Color
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <span (change)="detectchange()"
                                                    [style.background]="vectoranimation.drawcolor" [cpToggle]="true"
                                                    cpDialogDisplay="inline" [(colorPicker)]="vectoranimation.drawcolor"
                                                    [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                    *ngIf="company"
                                                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                </span>
                                            </mat-expansion-panel>
                                        </mat-accordion>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.fillleft" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>End fill left</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.fillright" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>End fill right</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.drawleft" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Start from left</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.drawright" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Start from right</mat-label>
                                        </mat-form-field>

                                        <!-- deduct percentage from draw  percentage  {drawSVG:"100%"}, {drawSVG:"50% 50%"}, 0.1) -->

                                    </div>

                                    <div *ngIf="vectoranimation.svganimationtype === 'color'" fxLayout="row">
                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title i18n>
                                                        Draw Color
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <span (change)="detectchange()"
                                                    [style.background]="vectoranimation.colorpath" [cpToggle]="true"
                                                    cpDialogDisplay="inline" [(colorPicker)]="vectoranimation.colorpath"
                                                    [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                    *ngIf="company"
                                                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                </span>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>

                                    <div *ngIf="vectoranimation.svganimationtype === 'morph'" fxLayout="row">

                                    </div>

                                    <div *ngIf="vectoranimation.svganimationtype === 'liquid'" fxLayout="row">
                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title i18n>
                                                        Fill Color
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <span (change)="detectchange()"
                                                    [style.background]="vectoranimation.drawcolor" [cpToggle]="true"
                                                    cpDialogDisplay="inline" [(colorPicker)]="vectoranimation.drawcolor"
                                                    [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                    *ngIf="company"
                                                    [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                                </span>
                                            </mat-expansion-panel>
                                        </mat-accordion>

                                        <mat-checkbox [(ngModel)]="vectoranimation.waves">Waves</mat-checkbox>


                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.fillleft" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Start Y</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.fillright" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>Start X</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.drawleft" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>To Y</mat-label>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" fxFlex>
                                            <input [(ngModel)]="vectoranimation.drawright" matInput
                                                (change)="detectchange()">
                                            <mat-label i18n>To X</mat-label>
                                        </mat-form-field>

                                    </div>

                                    <div *ngIf="vectoranimation.svganimationtype === 'changepath'">
                                        <mat-checkbox [(ngModel)]="vectoranimation.keepshape" i18n-matTooltip
                                            matTooltip="Keep shape while transforming" i18n>Keep shape</mat-checkbox>

                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header i18n>
                                                    Paths
                                                </mat-expansion-panel-header>
                                                <div *ngFor="let item of vectoranimation.svganimationpaths[0]; let f = index;"
                                                    fxLayout="column">
                                                    Path {{f}}
                                                </div>
                                            </mat-expansion-panel>

                                        </mat-accordion>



                                        <button mat-raised-button (click)="vectorService.AddAnimationPath()" i18n>Add
                                            animation
                                            path</button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <button mat-button (click)="vectorService.addVectorAnimation(selectedelement)" i18n>Add new Vector
                    effect</button>
            </div>

            <div *ngIf="selectedelement.type === 'counter'" fxLayout="column">
                <div *ngFor="let counteranimation of selectedelement.countertype; let iv = index; let $last = last"
                    [isLast]='$last' (ngInit)="initTimeline(selectedelement.countertype, iv)">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <label style="width: 300px;">{{counteranimation.countertype}}</label>
                                    <mat-icon (click)="deletecounteranimation(iv)" class="iconsize-10">delete</mat-icon>
                                    <mat-icon (click)="copyCounterEffect(iv)" i18n-matTooltip
                                        matTooltip="Duplicate effect" class="iconsize-10">
                                        filter_none</mat-icon>
                                    <div class="timeline-container" [style.width]="'6000px'">
                                        <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                            id="timelinebound">
                                            <div class="timeline-setter"
                                                [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                            </div>

                                            <div class="timeline-currentelement" ngDraggable [scale]="'1'"
                                                (movingOffset)="onMovingAnimationEl($event, counteranimation)"
                                                [lockAxis]="'y'" ngResizable [rzHandles]="'e,w'" [inBounds]="true"
                                                [bounds]="timelinebound"
                                                (rzResizing)="onResizeAnimationEl($event, iv, counteranimation)"
                                                [id]="'timeline' + iv"
                                                [style.width]="counteranimation.duration * 10 + 'px'">
                                            </div>

                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="counteranimation.counterstyle" i18n-placeholder
                                    placeholder="Counter style">
                                    <mat-option value="linear" i18n>Linear</mat-option>
                                    <mat-option value="circle" i18n>Circle</mat-option>
                                    <mat-option value="square" i18n>Square</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="counteranimation.duration" type="number" step="0.1" min="0"
                                    matInput>
                                <mat-label i18n>Duration</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="counteranimation.start_time" type="number" step="0.1" min="0"
                                    matInput>
                                <mat-label i18n>Start</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="counteranimation.fromr" type="number" step="1" min="1" matInput>
                                <mat-label i18n>From radius</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="counteranimation.tor" type="number" step="1" min="1" matInput>
                                <mat-label i18n>To Radius</mat-label>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" >
                            <mat-select [(ngModel)]="counteranimation.svganimationtype"
                                (selectionChange)="detectchange()"
                                (selectionChange)="detectMorph(counteranimation.svganimationtype)"
                                i18n-placeholder placeholder="Animation">
                                <mat-option value="">None</mat-option>
                                <mat-option value="draw">Draw</mat-option>
                                <mat-option value="morph">Morph</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="counteranimation.repeat" type="number" step="1" min="-1" matInput
                                    (change)="detectchange()">
                                <mat-label i18n>repeat</mat-label>
                            </mat-form-field>
                            <mat-form-field *ngIf="counteranimation.repeat === -1" appearance="outline" i18n-matTooltip
                                matTooltip="delay for infinite repeat">
                                <input [(ngModel)]="counteranimation.delay" type="number" step="0.1" min="0" matInput>
                                <mat-label i18n>delay</mat-label>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="counteranimation.yoyo" (change)="detectchange()" i18n-matTooltip
                                matTooltip="Reset when repeated" i18n>Fluent</mat-checkbox>
                            <!-- [disabled]="counteranimation.repeat !== -1 -->
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="counteranimation.easetype" (selectionChange)="detectchange()"
                                    i18n-placeholder placeholder="Animation">
                                    <mat-option value="">None</mat-option>
                                    <mat-option value="power1">Force 1</mat-option>
                                    <mat-option value="power1in">Force 1 start</mat-option>
                                    <mat-option value="power2">Force 2</mat-option>
                                    <mat-option value="power3">Force 3</mat-option>
                                    <mat-option value="bounce">Bounce</mat-option>
                                    <mat-option value="bouncein">Bounce Start</mat-option>
                                    <mat-option value="elastic">Elastic</mat-option>
                                    <mat-option value="elasticin">Elastic Start</mat-option>
                                    <mat-option value="circle">Circle</mat-option>
                                    <mat-option value="circlein">Circle In</mat-option>
                                    <mat-option value="sine">Sine</mat-option>
                                    <mat-option value="sinein">Sine Start</mat-option>
                                    <mat-option value="over">Over</mat-option>
                                    <mat-option value="easy">Easy</mat-option>
                                    <mat-option value="rough">Rough</mat-option>
                                    <mat-option value="steps">Steps</mat-option>
                                    <mat-option value="slowmotion">Slowmotion</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" >
                            <mat-select [(ngModel)]="counteranimation.fromto" (selectionChange)="detectchange()">
                                <mat-option value="from">From</mat-option>
                                <mat-option value="to">To</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                            <!-- (click)="setSvgOpacity(selectedelement, idx)" -->
                            <!-- <mat-checkbox [(ngModel)]=counteranimation.hideimage>Hide Line</mat-checkbox> -->

                            <div *ngIf="counteranimation.svganimationtype">
                                <div fxLayout="row">
                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.repeat" type="number" step="1" min="-1"
                                            matInput (change)="detectchange()">
                                        <mat-label i18n>Repeat</mat-label>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="counteranimation.repeat === -1" appearance="outline"
                                        i18n-matTooltip matTooltip="delay for infinite repeat">
                                        <input [(ngModel)]="vectoranimation.delay" type="number" step="0.1" min="0"
                                            matInput>
                                        <mat-label i18n>delay</mat-label>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.linethickness" matInput
                                            (change)="detectchange()">
                                        <mat-label i18n>Thickness</mat-label>
                                    </mat-form-field>

                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title i18n>
                                                    Background color
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <span style="width: 60px; height: 30px;"
                                                [style.background]="counteranimation.drawcolor" [cpToggle]="true"
                                                cpDialogDisplay="inline" [(colorPicker)]="counteranimation.drawcolor"
                                                [cpCancelButton]="true" [cpPresetLabel]="'Company colors'"
                                                *ngIf="company"
                                                [cpPresetColors]="[option.companyprimairycolor, option.companysecondarycolor, company.companyprimairycolor, company.companysecondarycolor]">
                                            </span>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.fillleft" matInput
                                            (change)="detectchange()">
                                        <mat-label i18n>End fill left</mat-label>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.fillright" matInput
                                            (change)="detectchange()">
                                        <mat-label i18n>End fill right</mat-label>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.drawleft" matInput
                                            (change)="detectchange()">
                                        <mat-label i18n>Start from left</mat-label>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <input [(ngModel)]="counteranimation.drawright" matInput
                                            (change)="detectchange()">
                                        <mat-label i18n>Start from right</mat-label>
                                    </mat-form-field>

                                    <!-- deduct percentage from draw  percentage  {drawSVG:"100%"}, {drawSVG:"50% 50%"}, 0.1) -->
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <button mat-button (click)="addCounterAnimation(selectedelement)" i18n>Add new Vector effect</button>
            </div>

            <div *ngIf="selectedelement.type === 'text'" fxLayout="column">
                <div *ngFor="let textanimation of selectedelement.splittextanimation; let iv = index; let $last = last"
                    [isLast]='$last' (ngInit)="initTimeline(selectedelement.splittextanimation, iv)">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <label style="width: 300px;">{{textanimation.textanimationtype}}</label>
                                    <mat-icon (click)="deleteTextAnimation(iv)" class="iconsize-10">delete</mat-icon>
                                    <mat-icon (click)="copyEffect(iv)" i18n-matTooltip matTooltip="Duplicate effect"
                                        class="iconsize-10">
                                        filter_none</mat-icon>
                                    <div class="timeline-container" [style.width]="'6000px'">
                                        <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                            id="timelinebound">
                                            <div class="timeline-setter"
                                                [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                            </div>
                                            <div class="timeline-currentelement" ngDraggable
                                                (movingOffset)="onMovingAnimationEl($event, textanimation)"
                                                [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'" [inBounds]="true"
                                                [bounds]="timelinebound"
                                                (rzResizing)="onResizeAnimationEl($event, iv, textanimation)"
                                                [id]="'timeline' + iv"
                                                [style.width]="textanimation.duration * 10 + 'px'">
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.duration" type="number" step="0.1" min="0" matInput>
                                <mat-label i18n>Duration</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.start_time" type="number" step="0.1" min="0" matInput>
                                <mat-label i18n>Start</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="textanimation.textanimationtype"
                                    (selectionChange)="detectchange()" i18n-placeholder placeholder="Animation">
                                    <mat-option value="chars" i18n>By character</mat-option>
                                    <mat-option value="chars" i18n>By character</mat-option>
                                    <mat-option value="words" i18n>By word</mat-option>
                                    <mat-option value="lines" i18n>By line</mat-option>
                                    <!-- <mat-option value="all">All</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="textanimation.easetype" (selectionChange)="detectchange()"
                                    i18n-placeholder placeholder="Animation">
                                    <mat-option value="">None</mat-option>
                                    <mat-option value="power1">Force 1</mat-option>
                                    <mat-option value="power1in">Force 1 start</mat-option>
                                    <mat-option value="power2">Force 2</mat-option>
                                    <mat-option value="power3">Force 3</mat-option>
                                    <mat-option value="bounce">Bounce</mat-option>
                                    <mat-option value="bouncein">Bounce Start</mat-option>
                                    <mat-option value="elastic">Elastic</mat-option>
                                    <mat-option value="elasticin">Elastic Start</mat-option>
                                    <mat-option value="circle">Circle</mat-option>
                                    <mat-option value="circlein">Circle In</mat-option>
                                    <mat-option value="sine">Sine</mat-option>
                                    <mat-option value="sinein">Sine Start</mat-option>
                                    <mat-option value="over">Over</mat-option>
                                    <mat-option value="easy">Easy</mat-option>
                                    <mat-option value="rough">Rough</mat-option>
                                    <mat-option value="steps">Steps</mat-option>
                                    <mat-option value="slowmotion">Slowmotion</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="textanimation.fromto" (selectionChange)="detectchange()">
                                    <mat-option value="from" i18n>From</mat-option>
                                    <mat-option value="to" i18n>To</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.repeat" type="number" step="1" min="-1" matInput
                                    (change)="detectchange()">
                                <mat-label i18n>Repeat</mat-label>
                            </mat-form-field>
                            <mat-form-field *ngIf="textanimation.repeat === -1" appearance="outline" i18n-matTooltip
                                matTooltip="delay for infinite repeat">
                                <input [(ngModel)]="textanimation.delay" type="number" step="0.1" min="0" matInput>
                                <mat-label i18n>delay</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.x" type="number" step="1" min="0" matInput
                                    (change)="detectchange()">
                                <mat-label>X</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.y" type="number" step="1" min="0" matInput
                                    (change)="detectchange()">
                                <mat-label>Y</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.rotationX" type="number" step="1" min="-360" max="360"
                                    matInput (change)="detectchange()">
                                <mat-label i18n>rotatet Deg Y</mat-label>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <input [(ngModel)]="textanimation.rotationY" type="number" step="1" min="-360" max="360"
                                    matInput (change)="detectchange()">
                                <mat-label i18n>rotate Deg Y</mat-label>
                            </mat-form-field>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div *ngIf="selectedelement.type === 'embeddedvideo' && selectedelement.src" fxLayout="column">

                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title i18n>
                                Video
                                <div class="timeline-container" [style.width]="'6000px'">
                                    <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                        id="timelinebound">
                                        <div class="timeline-setter"
                                            [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                        </div>
                                        <div class="timeline-currentelement" ngDraggable
                                            (movingOffset)="onMovingAnimationEl($event, selectedelement)"
                                            [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'" [inBounds]="true"
                                            [bounds]="timelinebound"
                                            (rzResizing)="onResizeAnimationEl($event, iv, selectedelement)"
                                            [id]="'timeline' + iv" [style.width]="selectedelement.duration * 10 + 'px'">
                                        </div>
                                    </div>
                                </div>

                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field appearance="outline">
                            <mat-label i18n>Duration</mat-label>
                            <input [(ngModel)]="selectedelement.duration" type="number" step="0.1" min="0" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]="selectedelement.start_time" type="number" step="0.1" min="0" matInput>
                            <mat-label i18n>Start</mat-label>
                        </mat-form-field>


                    </mat-expansion-panel>
                </mat-accordion>

            </div>

            <div *ngIf="selectedelement.type === 'chart'" fxLayout="column">

                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title i18n>
                                Charts
                                <div class="timeline-container" [style.width]="'6000px'">
                                    <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                        id="timelinebound">
                                        <div class="timeline-setter"
                                            [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                        </div>
                                        <div class="timeline-currentelement" ngDraggable
                                            (movingOffset)="onMovingAnimationChart($event, selectedelement)"
                                            [bounds]="timelinebound" [inBounds]="true" [lockAxis]="'y'" ngResizable
                                            [rzHandles]="'e, w'"
                                            (rzResizing)="onResizeAnimationChart($event, selectedelement)"
                                            [id]="'timelinechart'"
                                            [style.width]="selectedelement.lineChartOptions.animation.duration / 100 + 'px'">
                                        </div>
                                    </div>
                                </div>

                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field appearance="outline" style="max-width: 100px;"
                            *ngIf="selectedelement.lineChartOptions.animation">
                            <input [(ngModel)]="selectedelement.lineChartOptions.animation.duration" type="number"
                                step="1" min="0" matInput>
                            <mat-label i18n>Duration</mat-label>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]="selectedelement.start_time" type="number" step="0.1" min="0" matInput>
                            <mat-label i18n>Start</mat-label>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="selectedelement.lineChartOptions.animation">
                            <mat-select [(ngModel)]="selectedelement.lineChartOptions.animation.easing"
                                (selectionChange)="detectchange()" i18n-placeholder placeholder="Animation">
                                <mat-option value="linear">Linear</mat-option>
                                <mat-option value="easeInCubic">easeInCubic</mat-option>
                                <mat-option value="easeInQuart">easeInQuart</mat-option>
                                <mat-option value="easeInExpo">easeInExpo</mat-option>
                                <mat-option value="easeInElastic">easeInElastic</mat-option>
                                <mat-option value="easeInBounce">easeInBounce</mat-option>
                                <mat-option value="easeOutSine">easeOutSine</mat-option>
                                <mat-option value="easeInOutCirc">easeInOutCirc</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title i18n>
                                Data
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div fxLayout="column">
                            <div fxFlex fxLayout="row wrap">
                                <div *ngFor="let label of selectedelement.label; let i1 = index;">
                                    <mat-form-field appearance="outline" fxflex>
                                        <input [(ngModel)]="selectedelement.label[i1]" type="text" matInput
                                            i18n-placeholder placeholder="label">
                                    </mat-form-field>
                                    <mat-icon (click)="deletelabel(i1)" class="iconsize-12">delete</mat-icon>
                                </div>
                            </div>
                            <div>

                                <mat-icon (click)="addLabel()">add</mat-icon>
                            </div>
                            <div *ngFor="let row of selectedelement.originaldata; let i1 = index;" fxLayout="column">

                                <mat-form-field appearance="outline" fxflex>
                                    <input [(ngModel)]="row.label" type="text" matInput>
                                </mat-form-field>
                                <div fxFlex fxLayout="row wrap">
                                    <div *ngFor="let cell of row.data; let i2 = index;">
                                        <mat-form-field appearance="outline" fxflex style="width: 60px;">
                                            <input (change)="detectchangerowcell(i1, i2, $event)"
                                                [(ngModel)]="row.data[i2]" type="number" matInput>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-icon (click)="deletecell(i1)" class="iconsize-12">delete</mat-icon>
                                <mat-icon (click)="addcell(i1)">add</mat-icon>
                                <!-- <mat-icon (click)="deleteseries()" class="iconsize-12">delete</mat-icon> -->
                            </div>

                            <div fxLayout="row">
                                <button (click)="addgraph()" mat-raised-button>
                                    <mat-icon>add</mat-icon>
                                    <label i18n>Add Serie</label>
                                </button>
                                <button (click)="deletegraph()" mat-raised-button>
                                    <mat-icon>delete</mat-icon>
                                    <label i18n>Delete Serie</label>
                                </button>
                            </div>
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header i18n>
                                        Upload from Excel
                                    </mat-expansion-panel-header>
                                    <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)">
                                        <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                                            {{ uploaderContent | async }}
                                        </div>
                                    </xlsx-file-upload>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div *ngIf="selectedelement.type === 'threed'" fxLayout="column">
                <div *ngFor="let threedanimationcontrol of selectedelement.threedanimations; let iv = index; let $last = last"
                    [isLast]='$last' (ngInit)="initTimeline(threedanimationcontrol.threedanimations, iv)">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <!-- <label style="width: 300px;">{{threedanimationcontrol.textanimationtype}}</label> -->
                                    <mat-icon (click)="deletethreedanimationcontrol(iv)" class="iconsize-10">delete
                                    </mat-icon>
                                    <mat-icon (click)="copyEffect(iv)" i18n-matTooltip matTooltip="Duplicate effect"
                                        class="iconsize-10">
                                        filter_none</mat-icon>
                                    <div class="timeline-container" [style.width]="'6000px'">
                                        <div class="timeline-active" [style.width]="counter * 10 + 'px'" #timelinebound
                                            id="timelinebound">
                                            <div class="timeline-setter"
                                                [style.transform]="'translate(' + currenttime * 10 + 'px, 0px)'">
                                            </div>
                                            <div class="timeline-currentelement" ngDraggable
                                                (movingOffset)="onMovingAnimationEl($event, threedanimationcontrol)"
                                                [lockAxis]="'y'" ngResizable [rzHandles]="'e, w'" [inBounds]="true"
                                                [bounds]="timelinebound"
                                                (rzResizing)="onResizeAnimationEl($event, iv, threedanimationcontrol)"
                                                [id]="'timeline' + iv"
                                                [style.width]="threedanimationcontrol.duration * 10 + 'px'">
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="row wrap" fxLayoutGap="5px">
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Animation type</mat-label>
                                    <mat-select [(ngModel)]="threedanimationcontrol.type" aria-i18n-placeholder
                                        placeholder="type">
                                        <mat-option value="explode" i18n>explode</mat-option>
                                        <mat-option value="rotate" i18n>rotate</mat-option>
                                        <mat-option value="rotatecamera" i18n>rotate Camera</mat-option>
                                        <mat-option value="scale" i18n>scale</mat-option>
                                        <mat-option value="changeposition" i18n>change position</mat-option>
                                        <mat-option value="motionpath" i18n>motion</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Duration</mat-label>
                                    <input [(ngModel)]="threedanimationcontrol.duration" type="number" step="0.1"
                                        min="0" matInput>
                                </mat-form-field>
                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Start Time</mat-label>
                                    <input [(ngModel)]="threedanimationcontrol.start_time" type="number" step="0.1"
                                        min="0" matInput>
                                </mat-form-field>

                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>From/To</mat-label>
                                    <mat-select [(ngModel)]="threedanimationcontrol.fromto">
                                        <mat-option value="from">From</mat-option>
                                        <mat-option value="to">To</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <div *ngIf="threedanimationcontrol.type === 'scale'" fxLayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>scale X</mat-label>
                                        <input matInput i18n-placeholder placeholder="scale X"
                                            [(ngModel)]="threedanimationcontrol.scalex" type="number" step="0.1">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>scale Y</mat-label>
                                        <input matInput i18n-placeholder placeholder="scale Y"
                                            [(ngModel)]="threedanimationcontrol.scaley" type="number" step="0.1">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label>scale Z</mat-label>
                                        <input matInput i18n-placeholder placeholder="scale Z"
                                            [(ngModel)]="threedanimationcontrol.scalez" type="number" step="0.1">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="threedanimationcontrol.type === 'rotatecamera'" fxLayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Rotate Degrees</mat-label>
                                        <input matInput i18n-placeholder placeholder="rotate degrees around object"
                                            [(ngModel)]="threedanimationcontrol.rotationx" type="number" step="10"
                                            min="-360" max="360">
                                    </mat-form-field>
                                </div>
                                <br>
                                <div *ngIf="threedanimationcontrol.type === 'rotate'" fxLayout="row" fxLayoutGap="5px">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Rotate X</mat-label>
                                        <input matInput i18n-placeholder placeholder="rotate X"
                                            [(ngModel)]="threedanimationcontrol.rotationx" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Rotate Y</mat-label>
                                        <input matInput i18n-placeholder placeholder="rotate Y"
                                            [(ngModel)]="threedanimationcontrol.rotationy" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Rotate Z</mat-label>
                                        <input matInput i18n-placeholder placeholder="rotate Z"
                                            [(ngModel)]="threedanimationcontrol.rotationz" type="number" step="10">
                                    </mat-form-field>
                                </div>
                                <br>
                                <div *ngIf="threedanimationcontrol.type === 'changeposition'" fxLayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Postion X</mat-label>
                                        <input matInput i18n-placeholder placeholder="position X"
                                            [(ngModel)]="threedanimationcontrol.positionx" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Position Y</mat-label>
                                        <input matInput i18n-placeholder placeholder="position Y"
                                            [(ngModel)]="threedanimationcontrol.positiony" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Position Z</mat-label>
                                        <input matInput i18n-placeholder placeholder="position Z"
                                            [(ngModel)]="threedanimationcontrol.positionz" type="number" step="10">
                                    </mat-form-field>
                                </div>
                                <br>
                                <div *ngIf="threedanimationcontrol.type === 'explode'" fxLayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Explode depth X</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth X"
                                            [(ngModel)]="threedanimationcontrol.explodedepthx" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Explode depth Y</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth Y"
                                            [(ngModel)]="threedanimationcontrol.explodedepthy" type="number" step="10">
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field appearance="outline">
                                        <mat-label i18n>Explode depth Z</mat-label>
                                        <input matInput i18n-placeholder placeholder="Explode depth Z"
                                            [(ngModel)]="threedanimationcontrol.explodedepthz" type="number" step="10"
                                            min="-1">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="threedanimationcontrol.type === 'motionpath'" fxLayout="row">
                                    <button mat-raised-button (click)="editThreedMotionPath(threedanimationcontrol, iv)"
                                        i18n>Edit Path</button>

                                    <mat-checkbox [(ngModel)]="threedanimationcontrol.adjustrotation" i18n-matTooltip
                                        matTooltip="rotate with direction" i18n>Rotate</mat-checkbox>
                                </div>

                                <br>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Animation</mat-label>
                                    <mat-select [(ngModel)]="threedanimationcontrol.easetype" i18n-placeholder
                                        placeholder="Animation">
                                        <mat-option value="">None</mat-option>
                                        <mat-option value="power1">Force 1</mat-option>
                                        <mat-option value="power1in">Force 1 start</mat-option>
                                        <mat-option value="power2">Force 2</mat-option>
                                        <mat-option value="power3">Force 3</mat-option>
                                        <mat-option value="bounce">Bounce</mat-option>
                                        <mat-option value="bouncein">Bounce Start</mat-option>
                                        <mat-option value="elastic">Elastic</mat-option>
                                        <mat-option value="elasticin">Elastic Start</mat-option>
                                        <mat-option value="circle">Circle</mat-option>
                                        <mat-option value="circlein">Circle In</mat-option>
                                        <mat-option value="sine">Sine</mat-option>
                                        <mat-option value="sinein">Sine Start</mat-option>
                                        <mat-option value="over">Over</mat-option>
                                        <mat-option value="easy">Easy</mat-option>
                                        <mat-option value="rough">Rough</mat-option>
                                        <mat-option value="steps">Steps</mat-option>
                                        <mat-option value="slowmotion">Slowmotion</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Repeat</mat-label>
                                    <input matInput i18n-placeholder placeholder="repeat"
                                        [(ngModel)]="threedanimationcontrol.repeat" type="number" step="1" min="-1">
                                </mat-form-field>
                                <mat-form-field *ngIf="threedanimationcontrol.repeat === -1" appearance="outline"
                                    i18n-matTooltip matTooltip="delay for infinite repeat">
                                    <input [(ngModel)]="threedanimationcontrol.delay" type="number" step="0.1" min="0"
                                        matInput>
                                    <mat-label i18n>delay</mat-label>
                                </mat-form-field>
                                <mat-checkbox [(ngModel)]="threedanimationcontrol.yoyo" i18n>Yoyo</mat-checkbox>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <button mat-button (click)="addthreedanimation(selectedelement)" i18n>Add new 3D effect</button>
            </div>
        </div>
    </div>
    <!-- <mat-card fxLayout="column">


    </mat-card> -->
</div>