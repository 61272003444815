<div fxLayout="column" fxLayoutWrap="wrap" fxLayoutAlign="space-around">
    <div class="topbanner">
        <div class="selectrelation" fxFlex fxShow.xs="false" fxLayout="row">
            <mat-form-field appearance="outline" style="width: 400px;">
                <mat-label color="accent" *ngIf="SelectedRelation">{{SelectedRelation.relationname}}</mat-label>
                <input type="text" i18n-placeholder placeholder="Select Relation" aria-label="relation" matInput name="selectrelation"
                    (keyup)="searchGoQuick($event.srcElement.value)" [matAutocomplete]="auto" 
                    [formControl]="myControlRelations">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnRelation">
                    <mat-option *ngFor="let option of filteredRelations; let i = index" [value]="option"
                        (blur)="onSelectRelation(option)">
                        {{ option.relationname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>


    <mat-tab-group class="relations"
        [(selectedIndex)]="selectedTab" (selectedTabChange)="tabChange($event)">

        <!-- Video Editor__________________________________ -->
        <mat-tab label="Scene Creator">
            <ng-template mat-tab-label class="mat-tab-labels" >
                <div (click)="setScroll()" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>videocam</mat-icon>
                    <div fxShow.xs="false" i18n>Scene Creator</div>
                </div>

            </ng-template>

            <app-videocreator [company]="company" [Account]="Account" [SelectedRelation]="SelectedRelation"
                [option]="option" (newItemEvent)="addItem($event)">
            </app-videocreator>

        </mat-tab>

        <mat-tab label="Video Editor">
            <ng-template mat-tab-label class="mat-tab-labels">
                <mat-icon>movie</mat-icon>
                <div fxShow.xs="false" i18n>Video Editor</div>
            </ng-template>

            <app-videoeditor [company]="company" [Account]="Account" [SelectedRelation]="SelectedRelation"
                [option]="option" [newscene]="newScene.asObservable()">
            </app-videoeditor>

        </mat-tab>

        <!-- Image Editor__________________________________ -->
        <mat-tab label="Image Editor">
            <ng-template mat-tab-label class="mat-tab-labels">
                <mat-icon>image</mat-icon>
                <div fxShow.xs="false" i18n>Image Editor</div>
            </ng-template>

            <app-imagecreator [company]="company" [Account]="Account" [SelectedRelation]="SelectedRelation"
                [option]="option">
            </app-imagecreator>

        </mat-tab>
    </mat-tab-group>





</div>