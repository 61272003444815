
import { Component, ViewChild, OnInit, HostBinding, Input } from '@angular/core';
import {
  Relations,
  RelationsApi,
  Account,
  Channels,
  Twitter,
  TwitterApi,
  Linkedin,
  LinkedinApi,
  Facebook,
  FacebookApi,
  Pinterest,
  PinterestApi
} from '../../shared/sdk';
import { timezones, Timezones_List } from '../../shared/timezones';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import { DialogsService } from './../../dialogsservice/dialogs.service';
import { timeconv } from '../../shared/timeconv';
import { AppserviceService } from 'src/app/appservice.service';
// import { MarketingComponent } from '../marketing.component'
'../../shared/speed-dial-fab/speed-dial-fab.component';

@Component({
  selector: 'app-marketingchannels',
  templateUrl: './marketingchannels.component.html',
  styleUrls: ['./marketingchannels.component.scss']
})
export class MarketingchannelsComponent implements OnInit {

  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() selectedChannel: Channels;

  public minDate = new Date();
  public maxDate = new Date(2030, 0, 1);

  public options = [];
  public timezones = Timezones_List;
  public Channels: Channels[];
  public newChannel: Channels = new Channels();
  //public selectedChannel: Channels;
  selectedOption = false;
  public companypage = [];
  public selectcompanypage;
  public selectpinterestboard;
  public pinterestboard = [];

  public errorMessage;
  public twitteroption: Twitter;
  public linkedinoption: Linkedin;
  public facebookoption: Facebook;
  public pinterestoption: Pinterest;

  public Twitter: Twitter[] = [];
  public Linkedin: Linkedin[] = [];
  public Facebook: Facebook[] = [];
  public Pinterest: Pinterest[] = [];

  public convertdate;
  public date;
  public time;
  public localdate;
  public toggletextview = false;
  public waitingforfbpages = false;
  public listviewxsshow = false;
  public showemoji = false;
  facebookPages: any;
  instagramAccounts: any;

  constructor(
    public appservice: AppserviceService,
    // public MarketingComponent: MarketingComponent,
    public timeconv: timeconv,
    public dialogsService: DialogsService,
    public snackBar: MatSnackBar,
    public RelationsApi: RelationsApi,
    public TwitterApi: TwitterApi,
    public LinkedinApi: LinkedinApi,
    public FacebookApi: FacebookApi,
    public PinterestApi: PinterestApi
  ) { }

  ngOnInit() {
    this.appservice.isAccounAvailable.subscribe((ready) => {
      if (ready) {
        this.Account = this.appservice.Account;
      }
    });

    this.appservice.isOptionAvailable.subscribe((ready) => {
      if (ready) {
        this.option = this.appservice.option;
        this.getChannels();
        this.getTwitter();
        this.getLinkedin();
        this.getFacebook();
        this.getPinterest();
      }
    });

    this.appservice.isOptionsAvailable.subscribe((ready) => {
      if (ready) {
        this.options = this.appservice.options;
      }
    });


  }

  swiperight(e?) {
    console.log('swipe right')
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    this.listviewxsshow = false;
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: "snackbar-class"
    });
  }

  newlinkedin(): void {
    this.RelationsApi.createChannels(this.option.id, { type: "linkedin", primairychannel: true, timezone: moment.tz.guess() })
      .subscribe((channel: Channels) => { this.onSelectChannels(channel), this.Channels.push(channel) })
  }

  newtwitter() {
    this.RelationsApi.createChannels(this.option.id, { type: "twitter", 'primairychannel': true, timezone: moment.tz.guess() })
      .subscribe((channel: Channels) => {
        console.log(channel);
        this.onSelectChannels(channel);
        this.Channels.push(channel);
        this.getChannels();
      });
  }

  newfacebook(): void {
    this.RelationsApi.createChannels(this.option.id, { type: "facebook", 'primairychannel': true, timezone: moment.tz.guess() })
      .subscribe((channel: Channels) => { this.onSelectChannels(channel), this.Channels.push(channel) })
  }

  newinstagram(): void {
    this.RelationsApi.createChannels(this.option.id, { type: "instagram", 'primairychannel': true, timezone: moment.tz.guess() })
      .subscribe((channel: Channels) => { this.onSelectChannels(channel), this.Channels.push(channel) })
  }

  newpinterest(): void {
    this.RelationsApi.createChannels(this.option.id, { type: "pinterest", 'primairychannel': true, timezone: moment.tz.guess() })
      .subscribe((channel: Channels) => { this.onSelectChannels(channel), this.Channels.push(channel) })
  }

  // toggle different media forms
  onSelectChannels(Channels: Channels): void {
    this.selectedChannel = Channels;
    // this.twitteroption = null;
    if (this.selectedChannel.type === "linkedin") {
      this.selectedChannel.channelsendaccountid
      if (this.selectedChannel.userid) {
        this.linkedinoption = this.findLinkedin(this.Linkedin, this.selectedChannel.userid);
      } else {
        this.linkedinoption = new Linkedin();
        ;
      }
      if (this.selectedChannel.channelsendaccountid) {
        this.getLinkedinCompany(this.selectedChannel.channelsendaccountid);
      } else { this.selectcompanypage = ''; }
    }

    if (this.selectedChannel.type === "pinterest") {

      // if (this.selectedChannel.channelsendaccountid) {
      //   this.pinterestoption = this.findPinterest(this.Pinterest, this.selectedChannel.userid);
      //   this.getPinterestBoard()
      // } else {
      //   this.pinterestoption = new Pinterest();
      //   ;
      // }
      if (this.selectedChannel.channelsendaccountid) {
        this.getPinterestBoard();
      }
    }

    if (this.selectedChannel.type === "facebook") {

      if (this.selectedChannel.userid) {
        this.facebookoption = this.findFacebook(this.Facebook, this.selectedChannel.userid);
      }
      if (this.selectedChannel.companypage) {
        this.getFacebookCompany()
      } else { this.selectcompanypage = ''; }
    }

    if (this.selectedChannel.type === "twitter") {

      if (this.selectedChannel.userid) {
        this.twitteroption = this.findTwitter(this.Twitter, this.selectedChannel.userid);
        console.log(this.twitteroption);
      } else { this.twitteroption = new Twitter(); }
      if (this.selectedChannel.send) {
        this.TwitterApi.gettweetinfo(
          this.twitteroption.AccessToken, this.twitteroption.AccessTokenSecret,
          this.selectedChannel.channelsendid).subscribe(res => {
            if (res.errors) {
              console.log(res.errors)
            } else {
              this.selectedChannel.shared = res[0].retweet_count;
              this.selectedChannel.likes = res[0].favorite_count;
            }
          })
      }
    }

    if (this.selectedChannel.type === "instagram") { }
  }

  findLinkedinComp(companypages, id) {
    for (const item of companypages) {
      if (item.$URN === id) {
        return item
      }
    }
  }

  findLinkedin(Linkedin, id) {
    for (const item of Linkedin) {
      if (item.id === id) {
        return item
      }
    }
  }

  findFacebook(Facebook, id) {
    for (const item of Facebook) {
      if (item.id === id) {
        return item
      }
    }
  }

  findPinterest(Pinterest, id) {
    for (const item of Pinterest) {
      if (item.id === id) {
        return item
      }
    }
  }

  findTwitter(Twitter, id) {
    for (const item of Twitter) {
      if (item.id === id) {
        return item
      }
    }
  }

  getChannels(): void {
    this.RelationsApi.getChannels(this.option.id,
      {
        order: 'id DESC',
        where: { 'primairychannel': true }
      })
      .subscribe((Channels: Channels[]) => this.Channels = Channels);

  }

  scheduleChannel() {
    if (this.selectedChannel.recurrence) {
      if (!this.selectedChannel.endday) {
        this.openSnackBar('Missing end date')
        return
      }
      if (!this.selectedChannel.interval) {
        this.openSnackBar('Missing Interval')
        return
      }
      if (!this.selectedChannel.date) {
        this.openSnackBar('Missing start date')
        return
      }

      if (this.selectedChannel.interval === 'month') {
        if (!this.selectedChannel.daymonthinterval) {
          this.openSnackBar('Missing day of the month')
          return
        }
        if (!this.selectedChannel.weekmonthinterval) {
          this.openSnackBar('Missing week of the month')
          return
        }
      }
      if (this.selectedChannel.interval === 'week') {
        if (!this.selectedChannel.dayinterval) {
          this.openSnackBar('Missing end date')
          return
        }

      }
      if (this.selectedChannel.interval === 'year') {
        if (!this.selectedChannel.dayofmonth) {
          this.openSnackBar('Missing day of month')
          return
        }
        if (!this.selectedChannel.monthinterval) {
          this.openSnackBar('Missing month')
          return
        }
      }

      this.selectedChannel.scheduled = true;
      this.openSnackBar('Scheduled message')
      this.saveChannel();
    } else {
      this.selectedChannel.scheduled = true;
      this.openSnackBar('Scheduled message')
      this.saveChannel();
    }

  }


  saveChannel(): void {
    if (this.selectedChannel.date == null) {
      //let date = moment().format();
      this.selectedChannel.date = new Date();
    }
    // timezone
    if (this.selectedChannel.timezone == null) {
      this.selectedChannel.timezone = moment.tz.guess();
    }

    // time
    if (this.selectedChannel.timeinterval == null) {
      this.time = moment().format("hh:mm")
      this.selectedChannel.timeinterval = this.time;
    }

    this.selectedChannel.date = this.timeconv.convertTime(this.selectedChannel.date, this.selectedChannel.timeinterval, this.selectedChannel.timezone);
    // this.date = moment().format();
    // set ID to sendaccountid for reference auth token
    // if (this.selectedChannel.type === 'twitter') { this.selectedChannel.channelsendaccountid = this.twitteroption.id }
    // if (this.selectedChannel.type === 'facebook') { this.selectedChannel.channelsendaccountid = this.facebookoption.id }
    // if (this.selectedChannel.type === 'linkedin') { this.selectedChannel.channelsendaccountid = this.selectedChannel.channelsendaccountid } //set to companyId.. 
    // if (this.selectedChannel.type === 'instagram') {this.selectedChannel.channelsendaccountid = this.instagramoption.id}
    if (this.selectedChannel.recurrence === true) { // moved server side
      //  if (this.selectedChannel.date === undefined) { this.selectedChannel.date = this.date; }
      //  this.selectedChannel.date = this.timeconv.convertTime(this.selectedChannel.date, this.selectedChannel.timeinterval, this.selectedChannel.timezone);
      //  this.selectedChannel.channelsendaccountid
      //  console.log(this.selectedChannel.date)
    }
    //console.log(this.selectedChannel)


    this.RelationsApi.updateByIdChannels(this.option.id, this.selectedChannel.id, this.selectedChannel)
      .subscribe();

    //this.openSnackBar("Saved")
  }




  public openDialogDeleteChannel() {
    this.dialogsService
      .confirm('Delete scheduled Post', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.selectedOption = res, this.deleteChannel(this.selectedOption);
      });
  }

  public deleteChannel(selectedOption): void {
    // delete channel --> related channels will be deleted on server side
    if (selectedOption) {
      this.RelationsApi.destroyByIdChannels(this.option.id, this.selectedChannel.id)
        .subscribe(res => {
          this.getChannels();
          this.selectedChannel = undefined;
          this.openSnackBar("Message deleted");
        })
    }
  }


  getTwitter(): void {
    this.RelationsApi.getTwitter(this.option.id)
      .subscribe((Twitter: Twitter[]) => this.Twitter = Twitter)
  }

  postToTwitter(): void {
    if (this.selectedChannel.videourl) {
      this.TwitterApi.postVideo(
        this.twitteroption.AccessToken,
        this.twitteroption.AccessTokenSecret,
        this.selectedChannel.text,
        this.selectedChannel.title,
        this.selectedChannel.videourl
      ).subscribe(res => {
        console.log(res);
        this.selectedChannel.send = true;
        this.saveChannel();
      });
    } else if (this.selectedChannel.pictureurl) {
      this.TwitterApi.postImage(
        this.twitteroption.AccessToken,
        this.twitteroption.AccessTokenSecret,
        this.selectedChannel.text,
        this.selectedChannel.title,
        this.selectedChannel.pictureurl
      ).subscribe(res => {
        console.log(res);
        this.selectedChannel.send = true;
        this.saveChannel();
      });
    } else {
      this.TwitterApi.tweet(
        this.twitteroption.AccessToken,
        this.twitteroption.AccessTokenSecret,
        this.selectedChannel.text
      ).subscribe(res => {
        console.log(res);
        // this.selectedChannel.userid = this.twitteroption.id;
        // this.selectedChannel.channelsendid = res.id_str,
        this.selectedChannel.send = true;
        this.saveChannel();
      });
    }

  }

  getPinterest(): void {
    this.RelationsApi.getPinterest(this.option.id)
      .subscribe((Pinterest: Pinterest[]) => this.Pinterest = Pinterest);
  }

  getPinterestBoard(): void {
    // if (!this.pinterestboard) {
    //   this.waitingforfbpages = true;
    let accesstoken = this.Pinterest.filter((pinterest) => pinterest.id === this.selectedChannel.channelsendaccountid)[0].AccessToken;
    //   setTimeout(() => {
    console.log(this.selectedChannel.channelsendaccountid);
    this.PinterestApi.getboards(accesstoken)
      .subscribe(res => {
        console.log(res)
        this.pinterestboard = res.res.items;
        this.waitingforfbpages = false;
      });
    //     }, 500);
    //  }
  }

  getPinterstPins(): void {
    let url = this.selectpinterestboard.url;
    url = url.replace('https://www.pinterest.com/', '');
    let name = url.substring(0, url.indexOf("/"));
    this.PinterestApi.getpins(this.pinterestoption.AccessToken, name, this.selectpinterestboard.name).subscribe(
      res => { console.log(res) }
    );
  }

  posttoPinterestBoard(): void {
    let accesstoken = this.Pinterest.filter((pinterest) => pinterest.id === this.selectedChannel.channelsendaccountid)[0].AccessToken;
    if (this.selectedChannel.videourl) {
      this.PinterestApi.videopin(
        accesstoken,
        this.selectedChannel.board,
        this.selectedChannel.boardsection,
        this.selectedChannel.parentid,
        this.selectedChannel.text,
        this.selectedChannel.shareurl,
        this.selectedChannel.title,
        this.selectedChannel.videourl,
        this.selectedChannel.pictureurl
      ).subscribe(res => {
        console.log(res)
      })
    } else if (this.selectedChannel.pictureurl) {
      this.PinterestApi.pin(
        accesstoken,
        this.selectedChannel.board,
        this.selectedChannel.boardsection,
        this.selectedChannel.parentid,
        this.selectedChannel.text,
        this.selectedChannel.shareurl,
        this.selectedChannel.title,
        this.selectedChannel.pictureurl
      ).subscribe(res => {
        console.log(res)
      })
    }
    // let url = this.selectpinterestboard.url;
    // url = url.replace('https://www.pinterest.com/', '');
    // let name = url.substring(0, url.indexOf("/"));
    // console.log(this.selectpinterestboard, name);
    // this.PinterestApi.pin(this.pinterestoption.AccessToken,
    //   name, this.selectpinterestboard.name, this.selectedChannel.text, this.selectedChannel.title,
    //   null, null, this.selectedChannel.pictureurl).subscribe(
    //     res => { console.log(res) }
    //   )
  }

  getLinkedin(): void {
    this.RelationsApi.getLinkedin(this.option.id)
      .subscribe((Linkedin: Linkedin[]) => this.Linkedin = Linkedin);
  }

  getFacebookCompany(): void {
    if (this.selectedChannel.channelsendaccountid) {
      let accesstoken = this.Facebook.filter((facebook) => facebook.id === this.selectedChannel.channelsendaccountid)[0].AccessToken;
      this.FacebookApi.me(accesstoken).subscribe((res) => {
        this.facebookPages = res.data;
      })
    }
  }

  setFacebookCompanyToken() {
    if (this.selectedChannel.companypage) {
      this.selectedChannel.pageaccess_token = this.facebookPages.filter((facebook) => facebook.id === this.selectedChannel.companypage)[0].access_token;
      console.log(this.selectedChannel.pageaccess_token)
    }
  }

  getLinkedinCompany(id): void {
    let accesstoken = this.Linkedin.filter((linkedin) => linkedin.id === id)[0].accesstoken;
    if (accesstoken) {
      this.LinkedinApi.linkedinadmincompanypage(accesstoken)
        .subscribe(res => {
          if (res.errorCode !== undefined) {
            this.openSnackBar(res.message + ' please renew account');
          } else {
            this.companypage = res;
            // if (this.selectedChannel.companypage) {
            //   this.selectcompanypage = this.findLinkedinComp(this.companypage, this.selectedChannel.companypage);
            // }
          }
        });
    }
  }

  getLinkedinAccount(): void {
    let accesstoken = this.Linkedin.filter((linkedin) => linkedin.id === this.selectedChannel.channelsendaccountid)[0].accesstoken;
    if (accesstoken) {
      this.LinkedinApi.linkedinme(accesstoken)
        .subscribe(res => {
          if (res.errorCode !== undefined) {
            this.openSnackBar(res.message + ' please renew account');
          } else {
            this.openSnackBar('User ' + res.localizedFirstName + ' ' + res.localizedLastName + ' verified') // res. localizedHeadline)
            console.log(res);
          }
        });
    } else { console.log('token missing') }
  }

  // share to company linkedin page
  postToLinkedinCompanyPage(): void {
    const accesstoken = this.Linkedin.filter((linkedin) => linkedin.id === this.selectedChannel.channelsendaccountid)[0].accesstoken;
    //this.selectedChannel.channelsendaccountid = this.selectcompanypage.URN;
    // console.log(this.selectedChannel.channelsendaccountid);
    this.saveChannel();

    if (this.selectedChannel.videourl) {
      this.LinkedinApi.postVideo(
        accesstoken,
        this.selectedChannel.videourl,
        this.selectedChannel.companypage,
        this.selectedChannel.channelsendid,
        this.selectedChannel.title,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });

    } else if (this.selectedChannel.pictureurl) {
      this.LinkedinApi.postImage(
        accesstoken,
        this.selectedChannel.pictureurl,
        this.selectedChannel.companypage,
        this.selectedChannel.channelsendid,
        this.selectedChannel.title,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });
    } else {
      this.LinkedinApi.post(
        accesstoken,
        this.selectedChannel.companypage,
        this.selectedChannel.channelsendid,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });
    }


  }

  updateChannel(): void {
    if (this.selectedChannel.type === 'linkedin') {
      this.updateLinkedinMessage();
    }
    if (this.selectedChannel.type === 'twitter') {
      this.updateTwitterMessage();
    }
    if (this.selectedChannel.type === 'facebook') {
      this.updateFacebookMessage();
    }
  }

  updateTwitterMessage(): void {

  }

  updateFacebookMessage(): void {

  }

  updatePinterestMessage(): void {

  }

  updateLinkedinMessage(): void {
    this.LinkedinApi.findById(this.selectedChannel.userid).subscribe((linkedin: Linkedin) => {
      this.linkedinoption = linkedin;
      this.LinkedinApi.updateshare(this.linkedinoption.accesstoken, this.selectedChannel.channelsendid,
        this.selectedChannel.text)
        .subscribe(res => {
          this.saveChannel();
          console.log(res)
        });
    });
  };


  postToLinkedinProfile(): void {

  }


  postToFacebookPage(): void {
    console.log(this.selectedChannel)
    const accesstoken = this.Facebook.filter((linkedin) => linkedin.id === this.selectedChannel.channelsendaccountid)[0].AccessToken;
    this.saveChannel();

    if (this.selectedChannel.videourl) {
      this.FacebookApi.postVideoPage(
        this.selectedChannel.pageaccess_token,
        this.selectedChannel.companypage,
        this.selectedChannel.videourl,
        this.selectedChannel.companypage,
        this.selectedChannel.title,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });

    } else if (this.selectedChannel.pictureurl) {
      this.FacebookApi.postImagePage(
        accesstoken,
        this.selectedChannel.pictureurl,
        this.selectedChannel.companypage,
        this.selectedChannel.title,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });
    } else {
      this.FacebookApi.postpage(
        accesstoken,
        this.selectedChannel.companypage,
        //this.selectedChannel.channelsendid,
        this.selectedChannel.text).subscribe((res) => {
          console.log(res);
          if (res === 'failed') {
            this.openSnackBar('Upload failed check video file format is mp4')
          } else {
            this.selectedChannel.send = true;
            this.saveChannel();
          }
        });
    }
  }


  getFacebook(): void {
    this.RelationsApi.getFacebook(this.option.id)
      .subscribe((Facebook: Facebook[]) => this.Facebook = Facebook);
  }


  // getFBAccountInfo(): void {
  //   //this.waitingforfbpages = true;
  //   setTimeout(() => {
  //     this.FacebookApi.me(this.facebookoption.AccessToken).subscribe(
  //       res => {
  //         console.log(res.data),
  //           this.companypage = res.data
  //        // this.waitingforfbpages = false;
  //       }
  //     )
  //   }, 500);
  // }


  postToInstagram(): void {
    this.FacebookApi.posttoinstagram(
      this.selectcompanypage.access_token,
      this.selectcompanypage.id,
      this.selectedChannel.pictureurl,
      this.selectedChannel.text,
      this.selectedChannel.usertags)
      .subscribe(
        res => {
          console.log(res.id);
          this.selectedChannel.companypage = res.id;
          this.selectedChannel.userid = this.facebookoption.id;
          this.selectedChannel.send = true;
          this.saveChannel();
        }
      );
  }

  postVideoToInstragram(): void {
    this.FacebookApi.postvideoinstagram(
      this.selectcompanypage.access_token,
      this.selectcompanypage.id,
      this.selectedChannel.videourl,
      this.selectedChannel.text,
      null,
      null
    ).subscribe(res => { console.log(res) })
  }

  getInstagramMedia(): void {
    this.FacebookApi.getinstagrammedia(this.selectcompanypage.access_token, this.selectcompanypage.id)
      .subscribe(res => {
        console.log(res);
      })
  }

  getInstagramAccounts(): void {
    const accesstoken = this.Facebook.filter((linkedin) => linkedin.id === this.selectedChannel.channelsendaccountid)[0].AccessToken;
   if (accesstoken){
    this.FacebookApi.getInstagramAccounts(accesstoken).subscribe(res => {
      console.log(res);
      this.instagramAccounts = res;
    })
   }

  }

  searchChannels(searchterm): void {
    // name = name.charAt(0).toUpperCase() + name.slice(1);
    // name = name.trim();
    this.RelationsApi.getChannels(this.option.id,
      {
        where:
        {
          or: [{ "title": { "regexp": searchterm + '/i' } },
          { "text": { "regexp": searchterm + '/i' } }
          ]
        },
        order: 'title ASC'
      }
      // { where: { or: [{ title: name }, { text: name }] } }
    )
      .subscribe((Channels: Channels[]) => { this.Channels = Channels },
        error => this.errorMessage = <any>error);
  }

  public speedDialFabButtons = [
    {
      svgIcon: 'enydea_facebook',
      tooltip: 'facebook'
    },
    {
      svgIcon: 'enydea_twitter',
      tooltip: 'twitter'
    },
    {
      svgIcon: 'enydea_linkedin',
      tooltip: 'linkedin'
    },
    {
      svgIcon: 'enydea_instagram',
      tooltip: 'instagram'
    },
    {
      svgIcon: 'enydea_pinterest',
      tooltip: 'pinterest'
    },
    // {
    //   svgIcon: 'enydea_youtube',
    //   tooltip: 'youtube'
    // },
    // {
    //   svgIcon: 'enydea_web',
    //   tooltip: 'web'
    // },
    // {
    //   svgIcon: 'enydea_snapchat',
    //   tooltip: 'snapchat'
    // },

  ];

  onSpeedDialFabClicked(btn) {
    //console.log(btn.tooltip);
    if (btn.tooltip === 'linkedin') { this.newlinkedin() }
    if (btn.tooltip === 'pinterest') { this.newpinterest() }
    if (btn.tooltip === 'twitter') { this.newtwitter() }
    if (btn.tooltip === 'facebook') { this.newfacebook() }
    if (btn.tooltip === 'instagram') { this.newinstagram() }

  }

  setImage(event) {
    console.log(event);
    this.selectedChannel.pictureurl = event;
  }

  setVideo(event) {
    //console.log(event);
    this.selectedChannel.videourl = event;
  }

  onshowemoji() {
    if (this.showemoji) { this.showemoji = false } else {
      this.showemoji = true;
    }
  }

  setemoji(event) {
    //const bufStr = String.fromCodePoint(parseInt(event.emoji.unified, 16));
    if (this.selectedChannel.text) {
      this.selectedChannel.text = this.selectedChannel.text + event.emoji.native;
    } else {
      this.selectedChannel.text = event.emoji.native;
    }

    this.onshowemoji()
  }


}
