import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import {
  Twitter,
  Facebook,
  Linkedin,
  Pinterest,
  PublicationsApi,
  Publications,
  Relations,
  RelationsApi,
  Account,
  Company,
  Files,
  CrawlwebApi,
  ArticlereposterApi,
  Channels,
  ContainersecureApi,
  FilesApi
} from '../../shared/sdk';
import { Timezones_List } from '../../shared/timezones';
import { DialogsService } from './../../dialogsservice/dialogs.service';
import { languages } from '../../shared/listsgeneral/lang-bingcodes';
import { countrylist } from '../../shared/listsgeneral/countrylist';
import { HttpClient } from '@angular/common/http';
import { fonts } from '../../shared/listsgeneral/fonts';
import { ckconfig } from '../../shared/ckconfig';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketingchannelsComponent } from '../marketingchannels/marketingchannels.component';
import { timeconv } from '../../shared/timeconv';
import * as moment from 'moment-timezone';
import { Stream } from 'stream';
import { DialogGetname } from 'src/app/dialogsservice/dialog.getname';
import { MatDialog } from '@angular/material/dialog';
import domtoimage from 'dom-to-image-improved';
import { BASE_URL } from 'src/app/shared/base.api';
import { FileItem, FileUploader } from 'ng2-file-upload';

export class tone { score: number; tone_name: string; }

export class Dynatext {
  abstract: string;
  keywords: string;
  tag: string;
  text: string;
  url: string;
  title: string;
  tone: Array<tone>;
}

export interface Block {
  type: string;
  id: string;
  content: string;
  style: {
    'z-index': number,
    width: string;
    height: string;
    opacity: 1;
    margin: string;
    padding: string;
    'box-shadow': string;
    'background-color': string;
    'border-radius': string;
  };
  edit: boolean;

}

export function createBlock(type, pubLen): {
  type: string;
  id: string;
  content: string;
  style: {
    'z-index': number,
    width: string;
    height: string;
    opacity: 1;
    margin: string;
    padding: string;
    'box-shadow': string;
    'background-color': string;
    'border-radius': string;
  };
  edit: boolean;
} {
  let newid = 'bl' + pubLen;
  let newblock: Block = {
    type: type,
    id: newid,
    content: '',
    style: {
      'z-index': 0,
      width: '100%',
      height: '100%',
      opacity: 1,
      margin: '10px',
      padding: '10px',
      'box-shadow': '',
      'background-color': '',
      'border-radius': ''
    },
    edit: true
  }

  return newblock;
}


@Component({
  selector: 'app-marketingpublications',
  templateUrl: './marketingpublications.component.html',
  styleUrls: ['./marketingpublications.component.scss']
})
export class MarketingpublicationsComponent implements OnInit {

  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;
  @Output() channelselect = new EventEmitter(); //send url img back

  //@Input() selectedPublications: Publications;
  public aspectratio;
  public editablevideo: Files;
  public editablevideos: Files[];
  public Publications: Publications[];
  public selectedPublications: Publications;
  public limitresult: 30;
  public numbers = [
    { value: '1', viewValue: '1' },
    { value: '20', viewValue: '20' },
    { value: '30', viewValue: '30' }
  ];
  public listviewxsshow = false;
  public languages = languages;
  public countrylist = countrylist;
  public dynatext: Dynatext[];
  public searchdynatext = false;
  public timeframes = [
    { name: 'None', value: '' },
    { name: 'Day', value: 'Day' },
    { name: 'Week', value: 'Week' },
    { name: 'Month', value: 'Month' }
  ];
  public minDate = new Date();
  public maxDate = new Date(2030, 0, 1);
  selectedPublicationsChannels: Channels[];

  public website: string;
  public username: string;
  public password: string;
  public template: 'default';
  public changenow = true;
  public Ckconfig = ckconfig;
  public Twitter: Twitter[];
  public Linkedin: Linkedin[];
  public Facebook: Facebook[];
  public Pinterest: Pinterest[];
  public Channels: Channels[];
  public timezones = Timezones_List;
  public channelAccount = [];
  public channelaccountSel: any;
  public hide = false;
  public panelOpenState = false;
  public searchstring: string;
  resultstring: string;
  maxlength: number = 40
  temp: number = 0
  files: any;
  skip: number = 0;
  search: any;
  selectedcount: any;
  totalpublicationcount: any;
  limit: number;
  uploader: any;

  constructor(
    private relationsApi: RelationsApi,
    private filesApi: FilesApi,
    public dialog: MatDialog,
    public ContainersecureApi: ContainersecureApi,
    public timeconv: timeconv,
    public snackBar: MatSnackBar,
    public http: HttpClient,
    public articlereposterApi: ArticlereposterApi,
    public crawlwebapi: CrawlwebApi,
    public PublicationsApi: PublicationsApi,
    public RelationsApi: RelationsApi,
    public dialogsService: DialogsService
  ) { }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000,
      panelClass: "snackbar-class"
    });
  }

  ngOnInit() {

  }


  async OnSaveImage(bloburl) {
    // hide resize handles

    //   if (this.imagename === undefined) {
    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: 'new-image' }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.saveImage(res, bloburl)
    });
  }


  saveImage(imagename, bloburl) {
    // check if name is set and check name duplicates 
    let name = imagename + '.png';
    if (!imagename) {
      name = Math.random().toString(36).substring(7) + '.png';
    }

    this.relationsApi.getFiles(this.option.id, { where: { name: name } })
      .subscribe(async (res) => {
        if (res.length > 0) {
          name = imagename + res.length + '.png'
        }

        this.uploadToBack(name, bloburl);
      });
  }

  // dataURItoBlob(dataURI) {
  //   // convert base64 to raw binary data held in a string
  //   var byteString = atob(dataURI.split(',')[1]);
  //   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  //   var ab = new ArrayBuffer(byteString.length);
  //   var ia = new Uint8Array(ab);
  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   var blob = new Blob([ab], { type: mimeString });
  //   return blob;
  // }


  async uploadToBack(newname, bloburl): Promise<string> {
    return new Promise(async (fullfill, reject) => {
      //console.log(newname)
      let blob = await fetch(bloburl).then(r => r.blob());
      let name = Math.random().toString(36).substring(7) + '-AI.png';
      if (newname){
        name = newname + '-AI.png';
      }
     
      let date: number = new Date().getTime();
      let file = new File([blob], name, { type: "image/png", lastModified: date });
      let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
      this.uploader = new FileUploader({ url: urluse });
      let fileItem = new FileItem(this.uploader, file, {});
      this.uploader.queue.push(fileItem);
      fileItem.upload();
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        // set download url or actual url for publishing
        let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name;
        imgurl = imgurl.replace(/ /g, '-');
          // define the file settings
          let newFiles = new Files();
          newFiles.name = name;
          newFiles.url = imgurl;
          newFiles.createdate = new Date();
          newFiles.type = 'image';
          newFiles.companyId = this.Account.companyId;
        newFiles.size = this.uploader.queue[0].file.size / 1024 / 1024;
        this.relationsApi.createFiles(this.option.id, newFiles)
          .subscribe(res => {
            this.snackBar.open("Image saved!", undefined, {
              duration: 2000,
              panelClass: 'snackbar-class'
            });
            fullfill(imgurl);
            //console.log(res);
          });
      }
    });
  }


  getAnimationFiles() {
    this.RelationsApi.getFiles(this.option.id, {
      where: { template: { "neq": null }, type: 'video' },
      fields: { id: true, url: true, name: true }
    })
      .subscribe((files: Files[]) => {
        this.editablevideos = files;
      });
  }


  swiperight(e?) {
    console.log(e);
    this.listviewxsshow = true;
  }

  swipeleft(e?) {
    console.log(e);
    this.listviewxsshow = false;
  }

  // test selection criteria
  // getPublicationsList(): void {
  //   this.RelationsApi.findById(this.option.id, {
  //     where: {
  //       date: {
  //         // gt: Date.now() - this.onemonth,
  //         limit: 20,
  //         order: 'date'
  //       }
  //     }
  //   }).subscribe((publications: Publications[]) => this.Publications = publications);
  // }

  // search
  searchGo(name: string): void {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    name = name.trim();
    this.PublicationsApi.find({ where: { or: [{ newstitle: name }, { newstext: name }] } })
      .subscribe((publications: Publications[]) => this.Publications = publications);
  }

  // select and set parameters Publications
  async onSelect(publications: Publications): Promise<void> {
    if (publications.template === undefined) {
      publications.template = [];
    }
    this.selectedPublications = publications;
    this.files = [];

    for (let i = 0; i < this.selectedPublications.futuredimages.length; i++) {
      let name = this.selectedPublications.futuredimages[i].filename
      let bloburl = await this.getFile(name);
      this.files.push({ name: name, source: bloburl })
    }

    // this.ContainersecureApi.getFilesByContainer(this.Account.companyId, this.selectedPublications.id).subscribe(async (res: Array<string>) => {
    //   for (let i = 0; i < res.length; i++) {
    //     let bloburl = await this.getFile(res[i].name);
    //     this.files.push({ name: res[i], source: bloburl })
    //   }
    //   console.log(this.files);
    // });

    this.PublicationsApi.getChannels(this.selectedPublications.id)
      .subscribe((channels: Channels[]) => {
        this.selectedPublicationsChannels = channels;
        console.log(this.selectedPublicationsChannels);
      });
  }



  async getFile(name) {
    return new Promise(async (fullfill, reject) => {
      // get stream and encode base64 to pdf
      this.ContainersecureApi.getFilesByFilename(this.Account.companyId, this.selectedPublications.id, name)
        .subscribe(res => {
          console.log(res)
          const byteCharacters = atob(res.$data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });
          const url = window.URL.createObjectURL(blob);
          fullfill(url);
        })
    });
  }

  // dialog delete on yes activates deletePublications
  public openDialogDelete() {
    this.dialogsService
      .confirm('Delete Publication', 'Are you sure you want to do this?')
      .subscribe(res => {
        this.deletePublications(res);
        this.selectedPublications = undefined;
      });
  }

  // save entry
  savePublication(): void {
    this.RelationsApi.updateByIdPublications(this.option.id, this.selectedPublications.id, this.selectedPublications)
      .subscribe();
  }



  // publish to apps part
  public newpublication(i): void {
    // const publication = this.Translationjob[i];
    const publicationdata: Publications = new Publications();
    // publicationdata.text = publication.translation
    this.RelationsApi.createPublications(this.option, publicationdata)
      .subscribe(res => {
        console.log(res),
          this.selectedPublications = res
      });
    // this.selectedIndex = 0;
  }

  newItem(): void {
    this.RelationsApi.createPublications(this.option.id, { 'companyId': this.Account.companyId, 'title': 'New Item' })
      .subscribe(result => {
        this.selectedPublications = result
      });
  }

  // delete Publications -> check container?
  deletePublications(selectedOption): void {
    if (selectedOption === true) {
      this.PublicationsApi.deleteById(this.selectedPublications.id)
        .subscribe(res => {
          this.getPublications();
        })
    }
  }

  getPublications(value?): void {

    let request = {
      order: 'id DESC',
      limit: this.limitresult,
      skip: this.skip,
    }

    if (value || this.search) {
      request['where'] = { question: { "regexp": value + '/i' } }
      this.search = value;
    }

    this.RelationsApi.getPublications(this.option.id, request

    ).subscribe((publications: Publications[]) => {
      this.Publications = publications;
      this.RelationsApi.countPublications(this.option.id).subscribe(res => {
        this.selectedcount = this.Publications.length;
        this.totalpublicationcount = res.count
      });
    });
  }

  getnextpage(): void {
    if (this.limitresult < this.totalpublicationcount) {
      this.limit = this.limitresult += 20;
      this.skip = this.skip += 20;
      this.getPublications();
    }
  }

  getbackpage(): void {
    if (this.skip > 0) {
      this.skip = this.skip -= 20;
      this.limit = this.limit -= 20,
        this.getPublications();
    }
  }

  createDynaContent(): void {
    let language = this.languages.find(language => language.language === this.selectedPublications.language);
    let timeframe = this.timeframes.find(timeframe => timeframe.name === this.selectedPublications.timeframe);
    let time = '';
    if (timeframe) { time = timeframe.value }

    this.searchdynatext = true;
    this.articlereposterApi.repost(
      this.option.id,
      this.option.website,
      this.selectedPublications.keywords,
      this.selectedPublications.country,
      language.code, //'lang_' +  delete not for bing
      this.selectedPublications.location,
      time,
      this.selectedPublications.negativekeywords
    )
      .subscribe((res: Dynatext[]) => {
        this.searchdynatext = false;
        this.dynatext = res;
      });
  }


  setTextPublication(dynatext: Dynatext): void {
    let pubLen = this.selectedPublications.template.length;
    let textblock = createBlock('text', pubLen);
    textblock.content =
      '<h1>' + dynatext.title + '</h1><br>' +
      dynatext.text + '<br> &nbsp;' +
      'Orginal sources: ' + dynatext.url;
    this.selectedPublications.template.push(textblock);
  }

  updateWordpressPost(date?) {
    this.convertToHTML();
    let media;
    console.log(this.selectedPublications);
    if (this.selectedPublications.videourl) {
      media = this.selectedPublications.videourl
    } else {
      media = this.selectedPublications.pictureurl
    }
    this.PublicationsApi.wordpressUpdatePost(
      this.option.id, this.option.companyId, this.selectedPublications.website, this.selectedPublications.username,
      this.password, this.selectedPublications.cmsid, this.selectedPublications.title, this.selectedPublications.text,
      date, this.template, media)
      .subscribe(res => {
        console.log(res.res)
        this.selectedPublications.cmsid = res.res.id;
        this.selectedPublications.url = res.res.link;
        this.openSnackBar('Published to website');
        this.savePublication();
      }, error => {
        let errortext = error.message.replace(/<\/?[^>]+(>|$)/g, "");
        this.openSnackBar(errortext);
      })
  }


  postToWordPress(date?) {
    this.convertToHTML();
    let media;
    // console.log(this.selectedPublications);
    if (this.selectedPublications.videourl) {
      media = this.selectedPublications.videourl
    } else {
      media = this.selectedPublications.pictureurl
    }
    if (!date) { date = null } // wp no date wil publish direct
    this.PublicationsApi.wordpressCreatePost(
      this.option.id, this.option.companyId, this.selectedPublications.website, this.selectedPublications.username,
      this.password, this.selectedPublications.title, this.selectedPublications.text,
      date, this.template, media)
      .subscribe(res => {
        console.log(res)
        this.selectedPublications.cmsid = res.res.id;
        this.selectedPublications.url = res.res.link;
        this.openSnackBar('Published to website');
        this.savePublication();
      }, error => {
        let errortext = error.message.replace(/<\/?[^>]+(>|$)/g, "");
        this.openSnackBar(errortext);
      })
  }

  public openUrl(link) {
    window.open(link, '_blank');
  }

  public speedDialFabButtons = [
    {
      icon: 'subject',
      tooltip: 'Add new Text Block'
    },
    {
      icon: 'movie',
      tooltip: 'Add new Video Block'
    },
    {
      icon: 'emoji_nature',
      tooltip: 'Add new Animation Block'
    },
    {
      icon: 'image',
      tooltip: 'Add new Image Block'
    },
    {
      icon: 'check_box_outline_blank',
      tooltip: 'Add Empty Space'
    }
  ];

  onSpeedDialFabClicked(btn) {
    // console.log(btn.tooltip);
    let pubLen = this.selectedPublications.template.length;
    if (btn.tooltip === 'Add new Text Block') { this.selectedPublications.template.push(createBlock('text', pubLen)) }
    if (btn.tooltip === 'Add new Video Block') { this.selectedPublications.template.push(createBlock('video', pubLen)) }
    if (btn.tooltip === 'Add new Animation Block') { this.selectedPublications.template.push(createBlock('animation', pubLen)) }
    if (btn.tooltip === 'Add new Image Block') { this.selectedPublications.template.push(createBlock('image', pubLen)) }
    if (btn.tooltip === 'Add Empty Space') { this.selectedPublications.template.push(createBlock('emptyspace', pubLen)) }
    this.detectChange();
  }

  public speedDialFabButtonsSocialMedia = [
    {
      icon: 'Twitter',
      tooltip: 'Add new Twitter message'
    },
    {
      icon: 'Linkedin',
      tooltip: 'Add new Linkedin message'
    },
    {
      icon: 'facebook',
      tooltip: 'Add new Facebook message'
    },
    {
      icon: 'instagram',
      tooltip: 'Add new Instagram message'
    },
    {
      icon: 'pinterest',
      tooltip: 'Add new Pinterest message'
    }
  ];

  onSpeedDialFabClickedSocialMedia(btn) {
    // console.log(btn.tooltip);
    let pubLen = this.selectedPublications.template.length;
    if (btn.tooltip === 'Add new Text Block') { this.selectedPublications.template.push(createBlock('text', pubLen)) }
    if (btn.tooltip === 'Add new Video Block') { this.selectedPublications.template.push(createBlock('video', pubLen)) }
    if (btn.tooltip === 'Add new Animation Block') { this.selectedPublications.template.push(createBlock('animation', pubLen)) }
    if (btn.tooltip === 'Add new Image Block') { this.selectedPublications.template.push(createBlock('image', pubLen)) }
    if (btn.tooltip === 'Add Empty Space') { this.selectedPublications.template.push(createBlock('emptyspace', pubLen)) }
  }

  async convertToHTML() {
    /*
    Convert Template array to string removing all dynamic content and convert to static
    Set container DIV
    Add style 
    include external style and fonts
    */
    let htmltemp = [];
    htmltemp.push(`<style>
    
    </style>`)
    htmltemp.push('<div style="flex-flow: row wrap; box-sizing: border-box; display: flex;">');
    for (let i = 0; i < this.selectedPublications.template.length; i++) {
      let type = this.selectedPublications.template[i].type;
      let json = JSON.stringify(this.selectedPublications.template[i].style);
      let style = json.replace(/['"]+/g, '')
      style = style.replace(/,/g, '; ');
      style = style.replace('}', '');
      style = style.replace('{', '');
      let content = this.selectedPublications.template[i].content;
      switch (type) {
        case 'text':
          let text = '<div style="' + style + '">' + content + '</div>';
          htmltemp.push(text);
          break;
        case 'image':
          let image = '<div style="' + style + '">' +
            '<img style="max-width:100%; height:auto;" src="' + content + '">' +
            '</div>';
          htmltemp.push(image);
          break;
        case 'video':
          let video = '<div style="' + style + '">' +
            '<video style="height: 100%; width: 100%;" preload="auto" controls src="' + content + '"></video>' +
            '</div>';
          htmltemp.push(video);
          break;
        case 'animation':
          let animation = '<div style="' + style + '">' + content + '</div>'
          htmltemp.push(animation);
          break;
        default:
          break;
      }
    }
    htmltemp.push('<script></script>')
    htmltemp.push('</div>');

    let finalhtmltemp = htmltemp.join('');
    let stylefont = this.addFonts(finalhtmltemp);
    //htmltemp.unshift(stylefont);
    htmltemp.push(stylefont);
    let finalhtml = htmltemp.join('');
    this.selectedPublications.text = finalhtml;
    //console.log(finalhtml);
    return
  }

  addFonts(stringhtml) {
    let fontarray = [' <style type="text/css">'];
    fonts.forEach(font => {
      let fontfound = stringhtml.indexOf(font.value);
      if (fontfound !== -1) {
        fontarray.push('@font-face {font-family: "' + font.value + '";src: url(' + font.src + ') format("truetype");}');
      }
    })
    fontarray.push(' </style>');
    let stylefont = fontarray.join('');
    return stylefont
  }

  onSelectImage(SelectedImage): void {
    this.selectedPublications.pictureurl = encodeURI(SelectedImage);
    this.detectChange()
  }

  setVideo(event) {
    this.http.get(event, { responseType: 'blob' }).subscribe(blob => {
      var urlCreator = window.URL;
      this.selectedPublications.videourl = urlCreator.createObjectURL(blob);
      this.detectChange();
    })
    this.detectChange()
  }

  onSelectImageBlock(SelectedImage, i): void {
    this.selectedPublications.template[i].content = encodeURI(SelectedImage);
    this.detectChange()
  }

  setVideoBlock(event, i) {
    this.http.get(event, { responseType: 'blob' }).subscribe(blob => {
      var urlCreator = window.URL;
      this.selectedPublications.template[i].content = urlCreator.createObjectURL(blob);
      this.detectChange();
    });
  }

  deleteBlockItem(i) {
    //console.log(i)
    this.selectedPublications.template.splice(i, 1);
    this.detectChange()
  }

  async detectChange() {
    this.changenow = false;
    setTimeout(() => { this.changenow = true }, 10);
  }

  moveSectionUp(i1): void {
    if (i1 !== 0) {
      const tmp = this.selectedPublications.template[i1];
      this.selectedPublications.template[i1] = this.selectedPublications.template[i1 - 1];
      this.selectedPublications.template[i1 - 1] = tmp;
    }
  }

  moveSectionDown(i1): void {
    if (i1 !== this.selectedPublications.template.length - 1) {
      const tmp = this.selectedPublications.template[i1];
      this.selectedPublications.template[i1] = this.selectedPublications.template[i1 + 1];
      this.selectedPublications.template[i1 + 1] = tmp;
    }
  }

  toggleBlockEdit(block) {
    console.log(block)
    if (block.edit === true) {
      block.edit = false
    } else { block.edit = true }
  }

  loadVideo(block: Block) {
    let canvas = this.editablevideo.canvas[0];
    let myJSON = JSON.stringify(canvas);
    let canvasjson = encodeURIComponent(myJSON);
    let w = parseInt(canvas.width);
    let h = parseInt(canvas.height);
    this.aspectratio = (h / w) * 100;
    let containerstyle = 'overflow:hidden; padding-top:' + this.aspectratio + '%; position: relative;';
    let iframestyle = 'border:0; height:100%; left:0; position:absolute; top:0; width:100%;';
    let url = 'https://dlcr.enydea.com?id=' + this.editablevideo.id + '&canvas=' + canvasjson + '&repeat=false&remote=true';
    block.content = '<div style="' + containerstyle + '">' +
      '<iframe style="' + iframestyle + '" scrolling="no" frameborder="0" allowfullscreen src="' + url +
      '"></iframe></div>';
  }

  getKeywords(): any {
    return new Promise(async (fullfil, reject) => {
      let text = this.selectedPublications.text;
      let plaintext = text.replace(/<\/?[^>]+(>|$)/g, "");
      this.articlereposterApi.getkeywords(plaintext).subscribe(keywords => {
        fullfil(keywords);
      });
    });
  }

  getHashtags(keywords: string): any {
    //console.log(keywords);
    let hasharray = keywords.split(', ');
    let newhash = [];
    hasharray.forEach(key => {
      newhash.push('#' + key);
    });
    let hash = newhash.join(' ');
    return hash
  }

  copyHTMLtoClipboard() {
    const el = document.createElement('textarea');
    el.value = this.selectedPublications.text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.openSnackBar('HTML copied to Clipboard');
  };


  async downloadAsHTML() {
    await this.convertToHTML();
    let keywords = this.getKeywords();
    let filename = this.selectedPublications.title + '.html';
    let htmltext = `<!doctype html>
    <html lang="en">
    <head>
      <meta charset="utf-8">
      <title>`+
      this.selectedPublications.title
      + `></title>
      <meta name="description" content=`+
      this.selectedPublications.description
      + `>
      <meta name="author" content=`+
      this.Account.username
      + `
      content="`+
      keywords
      + `">
    
      <link rel="stylesheet" href="css/styles.css?v=1.0">
    </head>
    <body>
    `+
      this.selectedPublications.text
      + `
    </body>
    </html>`

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(htmltext));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.openSnackBar('Download HTML file')
  }

  getRelatedChannels() {

  }


  getChannels() {
    this.RelationsApi.getChannels(this.option.id,
      {
        order: 'id DESC',
      })
      .subscribe((Channels: Channels[]) => this.Channels = Channels);
    this.channelAccount = [];
    this.getTwitter();
    this.getLinkedin();
    this.getFacebook();
    this.getPinterest();
    // this.getInstagram(); use facebook graph

  }

  getTwitter(): void {
    this.RelationsApi.getTwitter(this.option.id)
      .subscribe((Twitter: Twitter[]) => {
        this.Twitter = Twitter;
        Twitter.forEach(twit => {
          this.channelAccount.push({ name: twit.name, type: 'Twitter', id: twit.id })
        });
      });
  }

  getLinkedin(): void {
    this.RelationsApi.getLinkedin(this.option.id)
      .subscribe((Linkedin: Linkedin[]) => {
        this.Linkedin = Linkedin;
        Linkedin.forEach(link => {
          this.channelAccount.push({ name: link.name, type: 'Linkedin', id: link.id })
        })
      });
  }


  getFacebook(): void {
    this.RelationsApi.getFacebook(this.option.id)
      .subscribe((Facebook: Facebook[]) => {
        this.Facebook = Facebook;
        Facebook.forEach(face => {
          this.channelAccount.push({ name: face.name, type: 'Facebook', id: face.id })
        })
      });
  }

  getPinterest(): void {
    this.RelationsApi.getPinterest(this.option.id)
      .subscribe((Pinterest: Pinterest[]) => {
        this.Pinterest = Pinterest;
        Pinterest.forEach(pin => {
          this.channelAccount.push({ name: pin.name, type: 'Pinterest', id: pin.id })
        })

      });
  }

  async autoGenerateChannels() {
    /* ideal post lenght
    1.get summary from enydea-dlcr 
    2.get keywords
    3.transform to hashtags
    4.create channels 
    twitter 71-100
    linkeidn 50-100
    facebook 40-80
    instagram 138-150
    pinterest 500
    */
    let keywords = await this.getKeywords();
    let hashtags = this.getHashtags(keywords);
    let hasharray = hashtags.split(' ');
    let plaintext = this.selectedPublications.text.replace(/<\/?[^>]+(>|$)/g, "");
    let text = await this.getSummary(plaintext);
    let messagetext;

    // timezone
    if (this.selectedPublications.timezone == null) {
      this.selectedPublications.timezone = moment.tz.guess();
    }

    let date = this.timeconv.convertTime(this.selectedPublications.date, this.selectedPublications.timeinterval, this.selectedPublications.timezone);

    if (this.Linkedin.length > 0) {
      messagetext = text.slice(0, 80);
      let url = this.trackUrl(this.selectedPublications.url, 'LinkedIn');
      this.newChannel(messagetext, 'linkedin', hasharray, url, hashtags, messagetext, date, this.Linkedin[0].id);
    }
    if (this.Twitter.length > 0) {
      messagetext = text.slice(0, 80);
      let url = this.trackUrl(this.selectedPublications.url, 'Twitter');
      this.newChannel(messagetext, 'twitter', hasharray, url, hashtags, messagetext, date, this.Twitter[0].id);
    }
    if (this.Pinterest.length > 0) {
      messagetext = text.slice(0, 200);
      let url = this.trackUrl(this.selectedPublications.url, 'Pinterest');
      this.newChannel(messagetext, 'pinterest', hasharray, url, hashtags, messagetext, date, this.Pinterest[0].id);
    }
    if (this.Facebook.length > 0) {
      messagetext = text.slice(0, 60);
      let url = this.trackUrl(this.selectedPublications.url, 'Facebook');
      this.newChannel(messagetext, 'facebook', hasharray, url, hashtags, messagetext, date, this.Facebook[0].id);
    }
    if (this.Facebook.length > 0) {
      messagetext = text.slice(0, 60);
      let url = this.trackUrl(this.selectedPublications.url, 'Instagram');
      this.newChannel(messagetext, 'instagram', hasharray, url, hashtags, messagetext, date, this.Facebook[0].id), date;
    }
  }

  async createChannelSel(account) {
    console.log(account, this.channelaccountSel)
    let keywords = await this.getKeywords();
    let hashtags = this.getHashtags(keywords);
    let hasharray = hashtags.split(' ');
    let plaintext = this.selectedPublications.text.replace(/<\/?[^>]+(>|$)/g, "");
    let text = await this.getSummary(plaintext);
    let messagetext;

    // timezone
    if (this.selectedPublications.timezone == null) {
      this.selectedPublications.timezone = moment.tz.guess();
    }

    let date = this.timeconv.convertTime(this.selectedPublications.date, this.selectedPublications.timeinterval, this.selectedPublications.timezone);
    let url;

    switch (this.channelaccountSel.type) {
      case 'Linkedin':
        messagetext = text.slice(0, 80);
        url = this.trackUrl(this.selectedPublications.url, 'LinkedIn');
        this.newChannel(messagetext, 'linkedin', hasharray, url, hashtags, messagetext, date, this.channelaccountSel.id);
        break;
      case 'Twitter':
        messagetext = text.slice(0, 80);
        url = this.trackUrl(this.selectedPublications.url, 'Twitter');
        this.newChannel(messagetext, 'twitter', hasharray, url, hashtags, messagetext, date, this.channelaccountSel.id);
        break;
      case 'Pinterest':
        messagetext = text.slice(0, 200);
        url = this.trackUrl(this.selectedPublications.url, 'Pinterest');
        this.newChannel(messagetext, 'pinterest', hasharray, url, hashtags, messagetext, date, this.channelaccountSel.id);
        break;
      case 'Facebook':
        messagetext = text.slice(0, 60);
        url = this.trackUrl(this.selectedPublications.url, 'Facebook');
        this.newChannel(messagetext, 'facebook', hasharray, url, hashtags, messagetext, date, this.channelaccountSel.id);
        break;
      case 'Instagram':
        messagetext = text.slice(0, 60);
        url = this.trackUrl(this.selectedPublications.url, 'Instagram');
        this.newChannel(messagetext, 'instagram', hasharray, url, hashtags, messagetext, date, this.channelaccountSel.id), date;
        break;
      default:
      // code block
    }
  }

  newChannel(messagetext, type, hashtagsarray, url, hashtags, text, date, id): void {
    // create channel
    this.PublicationsApi.createChannels(this.selectedPublications.id,
      {
        relationsId: this.option.id,
        type: type,
        hashtags: hashtagsarray,
        shareurl: url,
        date: date,
        title: this.selectedPublications.title,
        scheduled: true,
        pictureurl: this.selectedPublications.pictureurl,
        videourl: this.selectedPublications.videourl,
        userid: id
      })
      .subscribe((channel: Channels) => {
        // update channel with text and redirect link
        messagetext = text + '.. ' + channel.redirecturl +
          ' ' + hashtags;
        channel.text = messagetext;
        this.selectedPublicationsChannels.push(channel);
        this.saveChannel(channel);
      });
  }

  saveChannel(channel) {
    this.RelationsApi.updateByIdChannels(this.option.id, channel.id, channel)
      .subscribe();
  }

  deleteChannel(i) {
    this.PublicationsApi.destroyByIdChannels(
      this.selectedPublications.id,
      this.selectedPublicationsChannels[i].id).subscribe();
    this.selectedPublicationsChannels.splice(i, 1);
  }

  trackUrl(url, source) {
    let newURL = url +
      "?utm_source=" + this.option.relationname +
      "&utm_medium=" + source +
      "&utm_campaign=enydea-publication" +
      "&utm_term=" + this.selectedPublications.keywords +
      "&utm_content=" + this.selectedPublications.id;
    newURL = newURL.replace(/ /g, "_");
    return newURL
  }

  getSummary(text): any {
    return new Promise((fullfil, reject) => {
      this.articlereposterApi.getsummary(text).subscribe(summary => {
        fullfil(summary)
      });
    });
  }

  gotToChannel(channel) {
    this.channelselect.emit(channel);
  }

}
