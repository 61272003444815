<div>
    <h1 mat-dialog-title i18n>Add Mail: {{data.templatemailing.title}}</h1>


    <mat-button-toggle-group #group="matButtonToggleGroup" style="width: 100%" fxFlexAlign="start" [vertical]="false">

        <mat-button-toggle style="width: 50%" matTooltip="Add to Existing Campaigns" value="randomcampaign"
            (click)="toggleaddtomailing(false)">
            <mat-icon>today</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle style="width: 50%" matTooltip="Create Campaign based on list" value="randommaillist"
            (click)="toggleaddtomailing(true)">
            <mat-icon>list</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <div fxLayout="row">

        <div *ngIf="group.value == 'randommaillist'" mat-dialog-content class="boxdialog1" fxFlex="50%"
            fxLayout="column">


            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Filter on category</mat-label>
                    <mat-select (selectionChange)="setCatFilter($event.value)" name="categoryselect">
                        <mat-option *ngFor="let category of Category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <button mat-button (click)="resetfilter()" i18n>reset</button>
            </div>


            <div style="height: 400px; overflow: scroll;">
                <div *ngFor="let mailingList of mailinglistselection; let i1 = index ">

                    <div fxLayout="row" style="width: 90%;" fxLayoutGap="10px">
                        <mat-checkbox [(ngModel)]="checkboxlistmail[i1]" (click)="onAddMailinglist(i1)">
                        </mat-checkbox>
                        <span class="listbox">{{mailingList.listname}} | {{mailingList.total}} </span>
                    </div>


                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="20px">
                <button mat-button (click)="selectAllMail()" i18n>Select All</button>
                <button mat-button (click)="deselectAllMail()" i18n>Deselect All</button>
            </div>
        </div>

        <div *ngIf="group.value == 'randomcampaign'" mat-dialog-content class="boxdialog1" fxFlex="50%">

            <button mat-button (click)="selectAllCamp()" i18n>Select All</button>

            <button mat-button (click)="deselectAllCamp()" i18n>Deselect All</button>


            <div *ngFor="let campaignList of data.campaignLists; let i2 = index ">

                <mat-checkbox [(ngModel)]="checkboxlistcamp[i2]" (click)="onAddCampaignlist(i2)">
                </mat-checkbox>
                <span class="listbox">{{campaignList.name}}</span>

            </div>
        </div>

        <div class="boxdialog2" fxFlex="50%">
            <!-- 
            <mat-checkbox [(ngModel)]="data.randomize" i18n>Create Random dates and Times</mat-checkbox>
            <br> -->

            <div *ngIf="!data.randomize" fxLayout="row wrap" fxLayoutGap="20px">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Date</mat-label>
                    <mat-datepicker #picker2 class="form-half-width"></mat-datepicker>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="date" [(ngModel)]=data.date
                        i18n-placeholder name="date" placeholder="Send Date" (change)="changeDate()">
                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label i18n>Time</mat-label>
                    <input matInput [(ngModel)]=data.time i18n-placeholder placeholder="Time" class="InputField"
                        name="time" type="time" (change)="changeDate()">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label i18n>Timezone</mat-label>
                    <mat-select i18n-placeholder placeholder="Timezone" [(ngModel)]="data.timezone" name="timezone"
                        (selectionChange)="changeDate()">
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                            {{ timezone.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <!-- <div *ngIf="data.randomize" fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Date</mat-label>
                    <mat-datepicker #picker2 class="form-half-width"></mat-datepicker>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="randomstartdate" [(ngModel)]=data.startdate name="maxdate"
                        i18n-placeholder placeholder="Send Date">
                    <mat-datepicker-toggle matSuffix [for]="randomstartdate"></mat-datepicker-toggle>
                    <mat-datepicker #randomstartdate></mat-datepicker>
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label i18n>End date</mat-label>
                    <mat-datepicker #picker2 class="form-half-width"></mat-datepicker>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="randomenddate" [(ngModel)]=data.enddate name="randomdates"
                        i18n-placeholder placeholder="Send Date">
                    <mat-datepicker-toggle matSuffix [for]="randomenddate"></mat-datepicker-toggle>
                    <mat-datepicker #randomenddate></mat-datepicker>
                </mat-form-field>



                <mat-form-field appearance="outline">
                    <mat-label i18n>Day of the Week</mat-label>
                    <mat-select i18n-placeholder placeholder="Days for sending" [formControl]="daysform" multiple name="daysforsending"
                        [(ngModel)]=data.dayoftheweek>
                        <mat-option *ngFor="let day of days" [value]="day.value">{{day.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label i18n>starthour</mat-label>
                    <mat-select i18n-placeholder placeholder="Start Time" [(ngModel)]=data.starthour name="starttime">
                        <mat-option *ngFor="let hour of hours" [value]="hour.value">{{hour.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label i18n>endhour</mat-label>
                    <mat-select i18n-placeholder placeholder="End Time" [(ngModel)]="data.endhour" name="endtime">
                        <mat-option *ngFor="let hour of hours" [value]="hour.value">{{hour.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label i18n>Timezone</mat-label>
                    <mat-select i18n-placeholder placeholder="Timezone" [(ngModel)]="data.timezone" name="timezone">
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                            {{ timezone.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div> -->
        </div>

    </div>

    <div class="followupmail" fxLayout="column">

        <h3 i18n>Add Follow up Mail</h3>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Select Follow up Mail</mat-label>
            <mat-select i18n-placeholder placeholder="Select Follow up Mail" [(ngModel)]="data.followupmailing"
                name="folloupmail">
                <mat-option *ngFor="let Mailing of data.Mailing" [value]='Mailing.id'>
                    {{ Mailing.subject }} {{ Mailing.date }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline">
            <mat-label i18n>How many days after first email (the first consequent day available)</mat-label>
            <mat-select i18n-placeholder placeholder="Send follow up days after" [(ngModel)]="data.followupdays"
                name="folluwupdasys">
                <mat-option *ngFor="let followupDay of followupdays" [value]="followupDay.value">
                    {{followupDay.value}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Add all/clicked/opened</mat-label>
            <mat-select [(ngModel)]="data.openclickedorall" i18n-placeholder placeholder="Add all/clicked/opened">
                <mat-option value="all" i18n>All</mat-option>
                <mat-option value="clicked" i18n>Clicked</mat-option>
                <mat-option value="opened" i18n>Opened</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()" i18n>Cancel</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial i18n>Add Mailing</button>
    </div>
</div>