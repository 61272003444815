/* tslint:disable */
import {
  Link,
  Mailinglistentries
} from '../index';

declare var Object: any;
export interface LinktrackerInterface {
  "date": Date;
  "ip"?: string;
  "type"?: string;
  "url"?: string;
  "companyname"?: string;
  "hostname"?: string;
  "city"?: string;
  "region"?: string;
  "country"?: string;
  "loc"?: string;
  "org"?: string;
  "postal"?: string;
  "street"?: string;
  "lat"?: string;
  "lon"?: string;
  "ispname"?: string;
  "isp"?: boolean;
  "TRid"?: string;
  "enydea_source"?: string;
  "enydea_medium"?: string;
  "enydea_campaign"?: string;
  "enydea_term"?: string;
  "enydea_content"?: string;
  "linkId"?: any;
  "id"?: any;
  "mailinglistentriesId"?: any;
  link?: Link;
  mailinglistentries?: Mailinglistentries;
}

export class Linktracker implements LinktrackerInterface {
  "date": Date;
  "ip": string;
  "type": string;
  "url": string;
  "companyname": string;
  "hostname": string;
  "city": string;
  "region": string;
  "country": string;
  "loc": string;
  "org": string;
  "postal": string;
  "street": string;
  "lat": string;
  "lon": string;
  "ispname": string;
  "isp": boolean;
  "TRid": string;
  "enydea_source": string;
  "enydea_medium": string;
  "enydea_campaign": string;
  "enydea_term": string;
  "enydea_content": string;
  "linkId": any;
  "id": any;
  "mailinglistentriesId": any;
  link: Link;
  mailinglistentries: Mailinglistentries;
  constructor(data?: LinktrackerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Linktracker`.
   */
  public static getModelName() {
    return "Linktracker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Linktracker for dynamic purposes.
  **/
  public static factory(data: LinktrackerInterface): Linktracker{
    return new Linktracker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Linktracker',
      plural: 'Linktrackers',
      path: 'Linktrackers',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "ip": {
          name: 'ip',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "companyname": {
          name: 'companyname',
          type: 'string'
        },
        "hostname": {
          name: 'hostname',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "region": {
          name: 'region',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "loc": {
          name: 'loc',
          type: 'string'
        },
        "org": {
          name: 'org',
          type: 'string'
        },
        "postal": {
          name: 'postal',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "lat": {
          name: 'lat',
          type: 'string'
        },
        "lon": {
          name: 'lon',
          type: 'string'
        },
        "ispname": {
          name: 'ispname',
          type: 'string'
        },
        "isp": {
          name: 'isp',
          type: 'boolean',
          default: false
        },
        "TRid": {
          name: 'TRid',
          type: 'string'
        },
        "enydea_source": {
          name: 'enydea_source',
          type: 'string'
        },
        "enydea_medium": {
          name: 'enydea_medium',
          type: 'string'
        },
        "enydea_campaign": {
          name: 'enydea_campaign',
          type: 'string'
        },
        "enydea_term": {
          name: 'enydea_term',
          type: 'string'
        },
        "enydea_content": {
          name: 'enydea_content',
          type: 'string'
        },
        "linkId": {
          name: 'linkId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "mailinglistentriesId": {
          name: 'mailinglistentriesId',
          type: 'any'
        },
      },
      relations: {
        link: {
          name: 'link',
          type: 'Link',
          model: 'Link',
          relationType: 'belongsTo',
                  keyFrom: 'linkId',
          keyTo: 'id'
        },
        mailinglistentries: {
          name: 'mailinglistentries',
          type: 'Mailinglistentries',
          model: 'Mailinglistentries',
          relationType: 'belongsTo',
                  keyFrom: 'mailinglistentriesId',
          keyTo: 'id'
        },
      }
    }
  }
}
