import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Account, Company, Files, FilesApi, Relations, RelationsApi } from 'src/app/shared/sdk';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { BASE_URL } from 'src/app/shared/base.api';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogGetname } from 'src/app/dialogsservice/dialog.getname';
import { VideoPlayerConfig } from './videoplayer/models/ngx-thumbnail-video.models';
import { DialogImageEditgalleryComponent } from './dialog-image-editgallery/dialog-image-editgallery.component';

export interface Videosegment {
  videourl: string;
  audiosource: string;
  startime: number;
  endtime: number;
  transitiontype: string;
  blobvideo: string;
  blobaudio: string;
  screenshot: string;
  transition: number;
  transitiontime: number;
  offset: number;
  videototaltime: number;
}

export interface Videospec {
  name: string;
  videourl: string;
  audiosource: string;
  segments: Array<Videosegment>;
}




@Component({
  selector: 'app-videoeditor',
  templateUrl: './videoeditor.component.html',
  styleUrls: ['./videoeditor.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0.4, transform: 'scale(0.7)' }),
        animate(500, style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      // transition(':leave', [   // :leave is alias to '* => void'
      //   animate(300, style({opacity:0})) 
      // ]) 
    ])
  ]
})
export class VideoeditorComponent implements OnInit, OnDestroy {

  @Input() Account: Account;
  @Input() SelectedRelation: Relations;
  @Input() option: Relations;
  @Input() company: Company;


  @Input() newscene: Observable<Files>;
  private eventsSubscription: Subscription;

  videooptions: VideoPlayerConfig = {
    width: '500px',
    height: '500px'
  };

  public videospec: Videospec = {
    name: '',
    videourl: '',
    audiosource: '',
    segments: []
  };

  fadeEffects = [
    { value: 'fade', name: $localize`Fade` },
    { value: 'fadeblack', name: $localize`Fade Black` },
    { value: 'fadewhite', name: $localize`Fade White` },
    { value: 'dissolve', name: $localize`Dissolve` },
    { value: 'pixelize', name: $localize`Pixel effect` },
    { value: 'fadegrays', name: $localize`Fade Grey` },
    { value: 'hblur', name: $localize`Blur` },
    // { value: 'distance', name: '' },
    { value: 'circleclose', name: $localize`Circle effect` },
    { value: 'zoomin', name: $localize`Zoom in` },
    { value: 'diagbl', name: $localize`Bottom Left Fade` },
    { value: 'wipeleft', name: $localize`Wipe left` },
    { value: 'wiperight', name: $localize`Wipe right` },
    { value: 'wipeup', name: $localize`Wipe up` },
    { value: 'wipedown', name: $localize`Wipe down` },
    { value: 'slideleft', name: $localize`Slide left` },
    { value: 'slideright', name: $localize`Slide right` },
    { value: 'slideup', name: $localize`Slide up` },
    { value: 'slidedown', name: $localize`Slide down` },
    { value: 'smoothleft', name: $localize`Smooth left` },
    { value: 'smoothright', name: $localize`Smooth right` },
    { value: 'smoothup', name: $localize`Smooth up` },
    { value: 'smoothdown', name: $localize`Smooth down` },
    { value: 'circlecrop', name: $localize`Circle crop` },
    { value: 'rectcrop', name: $localize`Rectangle crop` },
    { value: 'circleopen', name: $localize`Circle open` },
    { value: 'horzclose', name: $localize`Horizontal close` },
    { value: 'horzopen', name: $localize`Horzontal open` },
    { value: 'vertclose', name: $localize`Vertical close` },
    { value: 'vertopen', name: $localize`Vertcial close` },
    { value: 'diagbr', name: $localize`Bottom right fade` },
    { value: 'diagtl', name: $localize`Top left fade` },
    { value: 'diagtr', name: $localize`Top right fade` },
    { value: 'hlslice', name: $localize`Horizontal slice from left` },
    { value: 'hrslice', name: $localize`Horizontal slice from right` },
    { value: 'vuslice', name: $localize`Vertical slice up` },
    { value: 'vdslice', name: $localize`Vertical slice down` },
    { value: 'radial', name: $localize`Radial fade` },
    { value: 'wipetl', name: $localize`Wipe top left` },
    { value: 'wipetr', name: $localize`Wipe top right` },
    { value: 'wipebl', name: $localize`Wipe bottom left` },
    { value: 'wipebr', name: $localize`Wipe bottom right` },
    { value: 'squeezev', name: $localize`Squeeze vertical` },
    { value: 'squeezeh', name: $localize`Squeeze horizontal` },

  ]

  bufferValue: number;
  selectedvideosegment: any;
  selectedvideo: any;
  loadedfile: Files;
  // name: string;

  constructor(
    private filesApi: FilesApi,
    public dialog: MatDialog,
    private relationsApi: RelationsApi,
    public http: HttpClient,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    let segment = this.factoryVideosegment();
    this.videospec = {
      name: '',
      videourl: '',
      audiosource: '',
      segments: [segment],
    };
    this.onselectedvideosegment(segment);
    this.eventsSubscription = this.newscene.subscribe((file) => this.addNewScene(file));
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  addNewScene(file: Files) {
    let segment = this.addSegment();
    this.setVideo(file.url, segment)
  }

  setVideo(e, segment: Videosegment) {
    console.log(e);
    let url = e;
    this.downloadFile(url).subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.bufferValue = (event.loaded / event.total) * 100;
      }
      if (event.type === HttpEventType.Response) {
        //console.log("donwload completed");
        let urlCreator = window.URL;
        segment.blobvideo = urlCreator.createObjectURL(event.body);
        segment.videourl = e;
      }
    });
  }

  downloadFile(url): Observable<HttpEvent<any>> {
    return this.http.get(url, {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders()
    });
  }

  addSegment() {
    let segment = this.factoryVideosegment();
    this.videospec.segments.push(segment);
    this.onselectedvideosegment(segment);
    return segment;
  }

  deleteSegment(i) {
    this.videospec.segments.splice(i, 1);
  }

  onselectedvideosegment(segment) {
    this.selectedvideosegment = segment;
  }

  moveright(i) {
    var element = this.videospec.segments[i];
    this.videospec.segments.splice(i, 1);
    this.videospec.segments.splice(i + 1, 0, element);
  }

  moveleft(i) {
    var element = this.videospec.segments[i];
    this.videospec.segments.splice(i, 1);
    this.videospec.segments.splice(i - 1, 0, element);
  }

  factoryVideosegment(): Videosegment {
    let videosegment: Videosegment = {
      videourl: '',
      audiosource: '',
      startime: undefined,
      endtime: undefined,
      blobvideo: '',
      blobaudio: '',
      transitiontype: 'fade',
      screenshot: '',
      transition: 1,
      transitiontime: 1,
      offset: 1,
      videototaltime: undefined
    }
    return videosegment;

  }

  setDuration(e, videosegment: Videosegment) {
    let time = this.strToSeconds(e)
    videosegment.videototaltime = time;
    console.log(time);
    if (time > 1) {
      videosegment.offset = time - 1;
    } else {
      videosegment.offset = time;
    }

  }

  strToSeconds(stime) {
    var tt = stime.split(':').reverse();
    return ((tt.length >= 3) ? (+tt[2]) : 0) * 60 * 60 +
      ((tt.length >= 2) ? (+tt[1]) : 0) * 60 +
      ((tt.length >= 1) ? (+tt[0]) : 0);
  }


  justSave() {
    if (this.videospec.name) {
      this.save();
    } else {
      const dialogRef = this.dialog.open(DialogGetname, {
        width: '250px',
        data: { name: this.videospec.name }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.videospec.name = result;
        this.save();
      });
    }
  }

  save() {
    if (this.loadedfile.id) {
      this.loadedfile.videospec = this.videospec;
      this.relationsApi.updateByIdFiles(this.option.id, this.loadedfile.id, this.loadedfile).subscribe(file => {
        this.openSnackbar('Video saved')
      });
    } else {
      this.saveNew();
    }
  }

  saveNew() {
    if (this.videospec.name) {
      this.save();
    } else {
      const dialogRef = this.dialog.open(DialogGetname, {
        width: '250px',
        data: { name: this.videospec.name }
      });

      dialogRef.afterClosed().subscribe(async result => {
        result = await this.checkName(result);
        this.videospec.name = result
        let newfile = new Files({
          companyId: this.Account.companyId,
          url: 'https://app.enydea.com/api/Containers/' + this.SelectedRelation.id + '/download/' + this.videospec.name + '.mp4',
          name: this.videospec.name,
          type: 'video-edit',
          relationsId: this.option.id,
          videospec: this.videospec,
          createdate: new Date(),
        });
        this.relationsApi.createFiles(this.option.id, newfile).subscribe(file => {
          this.openSnackbar('Video Saved')
        })
      });
    }
  }

  saveAndConvert() {
    if (this.videospec.name) {
      this.save();
    } else {
      const dialogRef = this.dialog.open(DialogGetname, {
        width: '250px',
        data: { name: this.videospec.name }
      });

      dialogRef.afterClosed().subscribe(async result => {
        result = await this.checkName(result);
        this.videospec.name = result;
        this.saveNewConvert();
      });
    }
  }

  saveNewConvert() {
    this.videospec.segments.forEach((segment, index) => {
      // check if all segments have a video
      if (segment.videourl === undefined) {
        this.videospec.segments.splice(index, 1)
      }
    });

    if (this.videospec.segments.length === 0) { return; }
    let newfile = new Files({
      companyId: this.Account.companyId,
      url: 'https://app.enydea.com/api/Containers/' + this.SelectedRelation.id + '/download/' + this.videospec.name + '.mp4',
      name: this.videospec.name,
      type: 'video-edit',
      relationsId: this.option.id,
      videospec: this.videospec,
      createdate: new Date(),
    });
    this.relationsApi.createFiles(this.option.id, newfile).subscribe(file => {
      this.combineVideos(file);
    });
  }

  saveAs(){

    const dialogRef = this.dialog.open(DialogGetname, {
      width: '250px',
      data: { name: '' }
    });

    dialogRef.afterClosed().subscribe(async result => {
      result = await this.checkName(result);
      this.videospec.name = result;
  
    let newfile = new Files({
      companyId: this.Account.companyId,
      url: 'https://app.enydea.com/api/Containers/' + this.SelectedRelation.id + '/download/' + this.videospec.name + '.mp4',
      name: this.videospec.name,
      type: 'video-edit',
      relationsId: this.option.id,
      videospec: this.videospec,
      createdate: new Date(),
    });
    this.relationsApi.createFiles(this.option.id, newfile).subscribe((file: Files) => {
      this.openSnackbar('Saved');
      this.loadedfile = file;
    });
  });
  }

  async combineVideos(file) {
    this.videospec.name = await this.checkName(this.videospec.name);
    this.filesApi.combineVideo(this.option.id, this.Account.companyId, file.id, this.videospec).subscribe(() => {
      this.openSnackbar('Creating video');
    })
  }

  async checkName(name): Promise<string> {
    let newname = name;
    let namescount = await this.relationsApi.countFiles(this.option.id, { name: name }).toPromise();
    console.log(namescount)
    if (namescount.count > 0) {
      let numfiles = namescount.count + 1;
      if (name.indexOf('.') !== -1){
      let ext = '.' + name.split('.').pop();
      let newname = name.replace(ext, '');
      console.log(newname, numfiles, ext)
      newname = newname + numfiles + ext;
    } else {
      newname = newname + numfiles;
    }
      return newname;
    } else {
      return newname;
    }
  }

  openSnackbar(message) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: 'snackbar-class'
    });
  }

  setAudio(e) {
    this.videospec.audiosource = e;
  }

  update() {

  }

  async openGallery() {
    //console.log(this.stored)
    let filter = { fields: { template: false }, where: { type: 'video-edit' } }
    let files = await this.relationsApi.getFiles(this.SelectedRelation.id, filter).toPromise();
    const dialogRef = this.dialog.open(DialogImageEditgalleryComponent, {
      width: '600px',
      data: { img: files, selected: this.selectedvideo }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
      this.loadFromFile(result)
    });
  }

  loadFromFile(result: Files) {
    console.log(result);
    this.videospec = result.videospec;
    this.loadedfile = result;
  }

}
