<div id="fab-dismiss" 
     *ngIf="fabTogglerState==='active'" 
     (click)="onToggleFab()">
</div>
<!-- class='fab-container' -->
<div [ngClass]="{
  'fab-container':direction === 'column',
  'fab-container-horizontal':direction === 'row'
}" [style.flexDirection]="reverseColumnDirection?'column-reverse':'column'">
  <button mat-fab class="fab-toggler"
          color="primary"
          (click)="onToggleFab()">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}"  i18n>add</i>
  </button>
  <div [className]="reverseColumnDirection?'column-reverse':'column'" [@speedDialStagger]="buttons.length">
    <button *ngFor="let btn of buttons"
            mat-mini-fab
            matTooltip="{{btn.tooltip}}"
            class="fab-secondary"
            color="primary"
            (click)="onClickFab(btn)">
      <i *ngIf="btn.icon" class="material-icons">{{btn.icon}}</i>
      <mat-icon *ngIf='btn.svgIcon' [svgIcon]="btn.svgIcon"></mat-icon>
      
    </button>
  </div>
</div>