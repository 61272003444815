import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Account,
  Company,
  LoopBackAuth,
  Publications,
  PublicationsApi,
  Relations,
  TranslationApi,
} from '../../../shared/sdk';
import { SpeechRecognitionService } from '../../../shared/speechservice/speechservice';
import { sourcelang, targetlang } from 'src/app/translations/languages';
import { FileUploader } from 'ng2-file-upload';
import { BASE_URL } from 'src/app/shared/base.api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppserviceService } from 'src/app/appservice.service';


@Component({
  selector: 'app-aitool',
  templateUrl: './aitool.component.html',
  styleUrls: ['./aitool.component.scss']
})
export class AitoolComponent implements OnInit {
  @Input() Account: Account;
  @Input() selectedPublications: Publications;
  @Input() option: Relations;
  @Input() company: Company;

  public recordactive = false;

  public searchstring: string;
  public resultstring: string;
  public maxlength: number = 40
  public temp: number = 0
  public waiting = false;

  public searchstringtranslate: string;
  public resultstringtranslate: string;
  public maxlengthtranslate: number = 4000
  public sourceLang: any = 'EN';
  public targetLang: any = 'NL';

  public sourcelanguages = sourcelang;
  public targetlanguages = targetlang;


  busyconverting: boolean;
  oldxliff: string;
  allowedMimeType = ['application/vnd.openxmlformats', 'officedocument.presentationml.presentatio', 'application/x-xliff+xml',
  'officedocument.wordprocessingml.document', 'application/vnd.openxmlformats', 'text/plain', 'text/xlif', 'text/xml', 'text/html'];
  maxFileSize = 5 * 1024 * 1024;
  public uploaderSec: FileUploader = new FileUploader({
    url: BASE_URL + "/api/translations/xlifftranslate",
    allowedMimeType: this.allowedMimeType,
    maxFileSize: this.maxFileSize,
  });
  
  errorMessage: string;
  xliff: boolean;

  constructor(
    public dialogRef: MatDialogRef<AitoolComponent>,
    public appservice: AppserviceService,
    public snackBar: MatSnackBar,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    public translationApi: TranslationApi,
    @Inject(MAT_DIALOG_DATA) public dialogaccount: Account,
    private speechRecognitionService: SpeechRecognitionService,
    public PublicationsApi: PublicationsApi
  ) { }

  ngOnInit(): void {
    if (!this.Account) {
      this.Account = this.dialogaccount;
    }

    console.log(this.Account, this.dialogaccount)
    this.uploaderSec.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploaderSec.onAfterAddingAll = (fileItems) => this.afterAddFile(fileItems);
  }

  afterAddFile(item){
    console.log(item[0])
    if (item[0].file.type === 'application/x-xliff+xml'){
      this.xliff = true;
    }
  }

  onWhenAddingFileFailed(item, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }

  getSuggestions() {
    this.waiting = true;
    this.busyconverting = true;
    this.temp = 0;
    this.maxlength = 2048;

    
    let id = this.Account.standardrelation;
    if (this.appservice.option.id) {
      id = this.appservice.option.id;
    }

    console.log(this.temp)
    this.PublicationsApi.aiRequest(id, this.Account.companyId, this.Account.id, this.searchstring, this.maxlength, this.temp)
      .subscribe((resultstring: string) => {
        this.resultstring = resultstring;
        this.waiting = false
        this.busyconverting = false;
      })
  }


  copy(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  activateSpeech(): void {
    //load in service speech service
    // connected class to recordactive
    //
    //see manual --> https://hassantariqblog.wordpress.com/2016/12/04/angular2-web-speech-api-speech-recognition-in-angular2/
    if (this.recordactive === false) {
      this.recordactive = true;
      this.speechRecognitionService.record()
        .subscribe(
          //listener
          (value) => {

            this.searchstring = value;

          },
          //errror
          (err) => {
            //console.log(err.error, "--restarting service--");
            this.activateSpeech();
          },
          //completion
          () => {
            //console.log("--complete--");
            //this.activateSpeech();
          });
    } else {
      this.speechRecognitionService.speechRecognition.stop()
      this.speechRecognitionService.DestroySpeechObject();
      this.recordactive = false;
    }

  }

  activateSpeechTranslate() {
    if (this.recordactive === false) {
      this.recordactive = true;
      this.speechRecognitionService.record()
        .subscribe(
          (value) => {
            this.searchstringtranslate = value;
          },
          (err) => {
            console.error(err)
            this.activateSpeechTranslate();
          })
    } else {
      this.speechRecognitionService.speechRecognition.stop()
      this.speechRecognitionService.DestroySpeechObject();
      this.recordactive = false;
    }

  }


  // TODO add options for translation like formality etc.. 
  getTranslation() {
    console.log()
    this.translationApi.autoTranslate(undefined, this.Account.id, this.Account.companyId,
      this.searchstringtranslate, this.sourceLang, this.targetLang).subscribe(res => {
        console.log(res)
        this.resultstringtranslate = res;
      })
  }


  //set variable and upload + save reference in Publications
  async setuploadSec() {
    console.log(this.Account)
    if (!this.targetLang) { this.openSnackbar('Missing Target Language') }
    this.busyconverting = true;
    // let filename = this.uploaderSec.queue[0].file.name;
    // filename = filename.replace(/ /g, '-');
    let secUrl = BASE_URL + "/api/translations/translatefile/" +
    this.Account.standardrelation + 
    "?accountId=" + this.Account.id + 
    "&companyId=" + this.Account.companyId + 
    "&sourcelang=" + this.sourceLang + 
    "&targetlang=" + this.targetLang +
    "&oldxliff=" + this.oldxliff

    this.uploaderSec.setOptions({
      url: secUrl,
      headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
    });

    let name = this.uploaderSec.queue[0].file.name
    let ext = name.split('.').pop();
    let newext = this.targetLang.toLowerCase() + '.' + ext;
    let newname = name.replace(ext, newext);

    this.uploaderSec.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
       // console.log(response)
       this.busyconverting = false;
        this.downloadToFile(response, newname);

        this.uploaderSec.clearQueue();

        this.uploaderSec.setOptions({
          url: secUrl,
          headers: [{ name: 'Authorization', value: this.auth.getAccessTokenId() }]
        });
      }
    }

    this.uploaderSec.uploadAll();
    //this.downloadToFile(this.getTestString(), newname);


  }

  downloadToFile(res, filename) {
    // let blob: Blob = this.dataURItoBlob(res);
    let file = JSON.parse(res);
    //console.log(file)
    const byteCharacters = atob(file.$data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    let urlCreator = window.URL;
    let url = urlCreator.createObjectURL(blob);
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.openSnackbar('Translation downloaded');
    this.dialogRef.close();
  }

  openSnackbar(message) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: 'snackbar-class'
    });
  }

};