
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ContainerApi, Files, Relations, RelationsApi, Company, Account, FilesApi } from '../sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Stockthreedfiles } from './stockthreedfiles'
//import { templatescreenshots, templates } from './stocktemplates';
import { BASE_URL, API_VERSION } from '../base.api'
import { FileUploader } from 'ng2-file-upload';

const URL = 'http://localhost:3000/api/containers/tmp/upload';
export interface DialogData {
  img;
  selected;
  storedtemplate;
  fromfile;
}

@Component({
  selector: 'app-threedfile',
  templateUrl: './threedfile.component.html',
  styleUrls: ['./threedfile.component.scss']
})
export class ThreedfileComponent implements OnInit {

  uploader: FileUploader;
  errorMessage: string;
  allowedMimeType = ['application/object', 'application/STEP', 'application/sla', 'application/glb',
  'model/gltf+json', 'application/octet-stream', 'model/gltf', 'application/gltf+json', 'model/gltf-binary', 'model/gltf.binary'];
  maxFileSize = 200 * 1024 * 1024;
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  public Files: Files[];
  public newFiles: Files = new Files();
  public showdropbox = true;
  public showgallery = false;
  public selectedthreedfile;
  public threedfile = [];
  public images = [];
  public stored = [];

  @Input('option') option: Relations; //get id for image gallery
  @Input('account') account: Account;
  @Output() seteditvideo = new EventEmitter();
  @Output() imgurl = new EventEmitter(); //send url img back
  // @Input('stored') stored = [];
  @Input('files') files: Files;
  loading: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public ContainerApi: ContainerApi,
    public relationsApi: RelationsApi,
    public fileApi: FilesApi
  ) { }

  ngOnInit() {
    // Clear the item queue (somehow they will upload to the old URL)
    this.uploader = new FileUploader({
      url: URL,
      //allowedMimeType: this.allowedMimeType,
      maxFileSize: this.maxFileSize,
    });
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploader.clearQueue();


    this.uploader.onAfterAddingAll = (files) => {
      files.forEach(fileItem => {
        fileItem.file.name = fileItem.file.name.replace(/ /g, '-');
      });
    };
  }

  // set constiable and upload + save reference in Publications
  setupload(name, size): void {
    // set upload url
    let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    this.uploader.setOptions({ url: urluse });

    // set download url or actual url for publishing
    let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name
    imgurl = imgurl.replace(/ /g, '-'),
      // imgurl = encodeURI(imgurl);
      // define the file settings
      this.newFiles.size = size,
      this.newFiles.name = name,
      this.newFiles.url = imgurl,
      this.newFiles.createdate = new Date(),
      this.newFiles.type = 'threed',
      this.newFiles.companyId = this.account.companyId,
      // check if container exists and create
      this.ContainerApi.findById(this.option.id)
        .subscribe(res => { this.uploadFile() },
          error =>
            this.ContainerApi.createContainer({ name: this.option.id })
              .subscribe(res => this.uploadFile()));
  }

  uploadFile(): void {
    this.loading = true;
    this.uploader.uploadAll();
    this.uploader.onCompleteAll = () => {
      this.relationsApi.createFiles(this.option.id, this.newFiles)
        .subscribe((file: Files )=> {
          let ext = this.newFiles.name.split('.').pop();
          console.log(ext)
          if (ext === 'step' || ext === 'STEP' || ext === 'stp') {
            this.fileApi.convertthreed(this.option.id, this.newFiles.url, this.newFiles.name, ext, this.account.companyId).subscribe(res2 => {
              let resurls = {
                fileurl: res2,
                mtlurl: ''
              }
              this.returnFileUrl(resurls)
            })
          } else {
            let resurls = {
              fileurl: file.url,
              mtlurl: ''
            }
            this.returnFileUrl(resurls);
            this.loading = false;
          }
        });
    }
  }

  returnFileUrl(res) {
    console.log(res),
      setTimeout(() => {
        //console.log(res)
        this.setimage(res);
      }, 1000);
  }

  onWhenAddingFileFailed(item, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        console.log(item)
        this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }

  // file upload 1
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  // file upload 2
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  onOpenGallery() {
    this.images = [];
    this.relationsApi.getFiles(this.option.id,
      { where: // remember to sort below file ext
        {or: 
          [
            { "name": { "regexp": '.glb' + '/i' }},  
            { "name": { "regexp": '.gltf' + '/i' }},  
            { "name": { "regexp": '.obj' + '/i' }},
            { "name": { "regexp": '.object' + '/i' }},
            { "name": { "regexp": '.stl' + '/i' }}
        ]}}
    ).subscribe((files: Files[]) => {
      this.Files = files,
        this.Files.forEach((file, index) => {
          let ext = file.name.split('.').pop();
          if (ext === 'obj' || ext === 'gltf' || ext === 'glb' || ext === 'object' || ext === 'stl') {
            const modalImage = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + file.name;

            let png = file.name.replace(ext, 'png')
            const thumburl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + png;
            this.images.push({ url: modalImage, name: file.name, thumburl: thumburl })
          }
        })

      //console.log(this.stored)
      const dialogRef = this.dialog.open(dialogthreedfilegallerycomponent, {
        width: '600px',
        data: { img: this.threedfile, selected: this.selectedthreedfile, fromfile: this.images }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.animal = result;
        //console.log(result)
        if (result) {
          let resurls = {
            fileurl: result.url,
            mtlurl: ''
          }
          this.setimage(resurls)
        }
      });
    });
  }


  setimage(url) {
    this.showdropbox = false;
    this.showgallery = false;
    this.imgurl.emit(url);
  }
}

@Component({
  selector: 'dialog-threedfilegallery',
  templateUrl: 'dialog-threedfilegallery.html',
  styleUrls: ['./threedfile.component.scss']
})

export class dialogthreedfilegallerycomponent implements OnInit {

  public threedfilesSet = Stockthreedfiles;
  public stockthreed = [];
  public stockthreedArray = [];
  public stockthreedSlice = 12;
  public stockthreedSliceMin = 1;

  public storedset = []
  public stored = [];
  public storedArray = [];
  public storedSlice = 12;
  public storedSliceMin = 1;


  public URL = 'http://localhost:3000/api/containers/tmp/upload';

  constructor(
    public dialogRef: MatDialogRef<dialogthreedfilegallerycomponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.threedfilesSet.forEach((element, index) => {
      const url = element;
      var filename = url.replace(/^.*[\\\/]/, '')
      let ext = filename.split('.').pop();
      let thumburl = url.replace(ext, 'png');

      if (index < 12) { this.stockthreed.push({ url: url, name: filename, thumburl: thumburl }); }
      //const thumburl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + png;
      this.stockthreedArray.push({ url: url, name: filename, thumburl: thumburl });
    });

    this.storedset.forEach((element, index) => {
      // !!! using the entire file object
      if (index < 12) { this.stored.push(element) }
      this.storedArray.push(element);
    });
    
    this.data.fromfile.forEach((element, index) => {
      if (index < 12) { this.stored.push(element) }
      this.storedArray.push(element);
      // const url = element.screenshot;
      // if (index < 12) { this.stored.push({ url: url, name: element.name, id: element.id }); }
      // this.storedArray.push({ url: url, name: element.name, id: element.id });
    });
   // console.log(this.stockthreedArray)
  }

  next(galtype) {
    switch (galtype) {

      case 'standardthreedfiles': {
        if (this.stockthreedSlice < this.stockthreedArray.length - 1) {
          this.stockthreedSlice = this.stockthreedSlice + 13;
          this.stockthreedSliceMin = this.stockthreedSliceMin + 13
          this.stockthreed = this.stockthreedArray.slice(this.stockthreedSliceMin -1, this.stockthreedSlice)
        }
      }
      case 'stored': {
        if (this.storedSlice < this.storedArray.length - 1) {
          this.storedSlice = this.storedSlice + 13;
          this.storedSliceMin = this.storedSliceMin + 13
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }
    }
  }

  before(galtype) {
    switch (galtype) {
      case 'standardthreedfiles': {
        if (this.stockthreedSliceMin > 1) {
          this.stockthreedSlice = this.stockthreedSlice - 13;
          this.stockthreedSliceMin = this.stockthreedSliceMin - 13
          this.stockthreed = this.stockthreedArray.slice(this.stockthreedSliceMin-1, this.stockthreedSlice)

        }
      }
      case 'stored': {
        if (this.storedSliceMin > 1) {
          this.storedSlice = this.storedSlice - 13;
          this.storedSliceMin = this.storedSliceMin - 13
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }
    }
  }


  onNoClick(): void {
    this.data.selected = '';
    this.dialogRef.close();
  }

  selectedimage(img): void {
    this.data.selected = img;
    //console.log(img)
  }

}