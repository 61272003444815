/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Unsortedcalls } from '../../models/Unsortedcalls';
import { SocketConnection } from '../../sockets/socket.connections';
import { Account } from '../../models/Account';


/**
 * Api services for the `Unsortedcalls` model.
 */
@Injectable()
export class UnsortedcallsApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation relations.
   *
   * @param {any} id unsortedcalls id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public getRelations(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/:id/relations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id unsortedcalls id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} accountId 
   *
   * @param {string} companyId 
   *
   * @param {string} token 
   *
   * @param {string} accountmail Owner email in case the sender is the owner
   *
   * @param {object} data Request data.
   *
   *  - `mailarray` – `{any}` - Array of office 365 email to process
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public sortmailOffice365(accountId: any = {}, companyId: any = {}, token: any = {}, accountmail: any = {}, mailarray: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/sortmailOffice365/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {
      mailarray: mailarray
    };
    let _urlParams: any = {};
    if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof accountmail !== 'undefined' && accountmail !== null) _urlParams.accountmail = accountmail;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} accountId 
   *
   * @param {string} companyId 
   *
   * @param {string} token 
   *
   * @param {string} accountmail Owner email in case the sender is the owner
   *
   * @param {string} date get mail greater then this date
   *
   * @param {any} inboxes Inboxes to search in
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public autoAssignMailOffice365(accountId: any = {}, companyId: any = {}, token: any = {}, accountmail: any = {}, date: any = {}, inboxes: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/autoAssignMailOffice365/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof accountmail !== 'undefined' && accountmail !== null) _urlParams.accountmail = accountmail;
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    if (typeof inboxes !== 'undefined' && inboxes !== null) _urlParams.inboxes = inboxes;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} mailId 365 mail id 
   *
   * @param {string} callId 
   *
   * @param {string} token 
   *
   * @param {string} accountId 
   *
   * @param {string} companyId 
   *
   * @param {object} data Request data.
   *
   *  - `call` – `{object}` - The entire call object
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public downloadAttachments(mailId: any = {}, callId: any = {}, token: any = {}, call: any = {}, accountId: any = {}, companyId: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/downloadAttachments/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {
      call: call
    };
    let _urlParams: any = {};
    if (typeof mailId !== 'undefined' && mailId !== null) _urlParams.mailId = mailId;
    if (typeof callId !== 'undefined' && callId !== null) _urlParams.callId = callId;
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} mailId 365 mail id 
   *
   * @param {string} attachmentId 
   *
   * @param {string} name filename
   *
   * @param {string} callId 
   *
   * @param {string} token 
   *
   * @param {string} accountId 
   *
   * @param {string} companyId 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public downloadAttachment(mailId: any = {}, attachmentId: any = {}, name: any = {}, callId: any = {}, token: any = {}, accountId: any = {}, companyId: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/downloadAttachment/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof mailId !== 'undefined' && mailId !== null) _urlParams.mailId = mailId;
    if (typeof attachmentId !== 'undefined' && attachmentId !== null) _urlParams.attachmentId = attachmentId;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof callId !== 'undefined' && callId !== null) _urlParams.callId = callId;
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} accountId 
   *
   * @param {string} companyId 
   *
   * @param {string} token 
   *
   * @param {number} iteration 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Unsortedcalls` object.)
   * </em>
   */
  public getUniqueRelations365(accountId: any = {}, companyId: any = {}, token: any = {}, iteration: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/unsortedcalls/getUniqueRelations365/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof iteration !== 'undefined' && iteration !== null) _urlParams.iteration = iteration;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Unsortedcalls`.
   */
  public getModelName() {
    return "Unsortedcalls";
  }
}
