import { Injectable } from '@angular/core';
import { RelationsApi } from './sdk';

@Injectable({
  providedIn: 'root'
})
export class ChecknameService {

  constructor(
    public relationsApi: RelationsApi,
  ) { }

  async checkName(name, id): Promise<string> {
    let newname = name;
    let namescount = await this.relationsApi.countFiles(id, { name: name }).toPromise();
    console.log(namescount)
    if (namescount.count > 0) {
      let numfiles = namescount.count + 1;
      if (name.indexOf('.') !== -1) {
        let ext = '.' + name.split('.').pop();
        let newname = name.replace(ext, '');
        console.log(newname, numfiles, ext)
        newname = newname + numfiles + ext;
      } else {
        newname = newname + numfiles;
      }
      return newname;
    } else {
      return newname;
    }
  }
}
