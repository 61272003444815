<div fxLayout="column" fxLayoutWrap="wrap" fxLayoutAlign="space-around">
  <div class="topbanner">
    <div class="selectrelation" fxFlex fxShow.xs="false" fxLayout="row">
      <mat-form-field appearance="outline">
        <mat-label color="accent" *ngIf="option">{{option.relationname}}</mat-label>
        <input type="text" i18n-placeholder placeholder="Select Relation" aria-label="relation" matInput
          name="selectrelation" (keyup)="searchGoQuick($event.srcElement.value)" [matAutocomplete]="auto"
          [formControl]="myControlRelations">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnRelation">
          <mat-option *ngFor="let option of filteredRelations; let i = index" [value]="option"
            (blur)="onSelectRelation(option)">
            {{ option.relationname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>




  <mat-tab-group fxLayout="column" fxLayoutWrap="wrap" fxFlexAlign="start" class="relations"
    [(selectedIndex)]="selectedTab" (selectedTabChange)="tabChange($event)">

    <!-- Mailing tab _____________________________ -->

    <mat-tab class="tabmenu" label="Mailing" fxFlex="100%" fxFlexAlign="start" label="New Publications">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon>email</mat-icon>
        <div fxShow.xs="false" i18n>Mailing</div>
      </ng-template>

      <label *ngIf="!option" i18n>Please select Relation</label>
      <div class="tabcontainer" fxLayout="column" *ngIf="option">

        <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabMailing">

          <!-- <div class="listshowcontainer" fxShow fxHide.gt-xs>
          <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right
          </mat-icon>
          <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
        </div> -->


          <!-- Mail Editor -->
          <mat-tab style="width: 100%;">
            <ng-template mat-tab-label class="mat-tab-labels">
              <mat-icon>edit</mat-icon>
              <div fxShow.xs="false" i18n>Template Editor</div>
            </ng-template>
            <!--[updateMailingObj]="updateMailingObj"   -->
            <app-maileditor #maileditor style="width: 100%;" [account]="Account" [option]="option" [company]="company"
              [setMailingTemplateWatcher]="setMailingTemplate">
            </app-maileditor>
          </mat-tab>

          <mat-tab fxLayout="column" style="width: 100%;" (swipeleft)="swipeleft($event)"
            (swiperight)="swiperight($event)">

            <ng-template mat-tab-label class="mat-tab-labels">
              <mat-icon>rate_review</mat-icon>
              <div fxShow.xs="false" i18n>Templates</div>
            </ng-template>

            <div class="addtobutton">
              <button mat-fab color="primary" i18n-matTooltip matTooltip="Create Mailing" (click)="createMailing()">
                <mat-icon>add</mat-icon>
              </button>
            </div>

            <div fxLayout="row">
              <div class="card-left-menu" fxLayout="column" ngClass.gt-xs="listview-normal"
                ngClass.lt-sm="listview-noshow" [class.listview-xs-show]="listviewxsshow">
                <mat-form-field appearance="outline">
                  <input matInput #searchTerm id="seach-box" (keyup.enter)="searchGoMailing(searchTerm.value)"
                    placeholder="Search" />
                </mat-form-field>
                <button a mat-raised-button color="primary" (click)="searchGoMailing(searchTerm.value)">
                  <mat-icon>search</mat-icon>
                  <label i18n>Search Results</label>
                </button>

                <button a mat-raised-button (click)="getMailing()" i18n>
                  Show All
                </button>
                <div class="listview">

                  <div *ngFor="let Mailing of Mailing" (click)="onSelectMailing(Mailing)">
                    <div class="listbox" [class.selected]="Mailing === selectedMailing">
                      <div>
                        <mat-icon *ngIf="Mailing.linkedin" class="iconsize-10" svgIcon="enydea_linkedin"></mat-icon>
                        <div fxLayout="row" fxLayout.xs="column" style="height: 30px; vertical-align: middle;">
                          <div fxFlex *ngIf="Mailing.mailinglist">
                            {{ Mailing.subject | slice: 0:30 }}
                          </div>
                        </div>

                        <div fxLayout="row" fxLayout.xs="column" style="height: 30px; vertical-align: middle;">
                          <div fxFlexcolspan="3" class="selectionboxmailing80">
                            {{ Mailing.date | date }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <mat-card ngClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow"
                [class.container-xs-show]="listviewxsshow" *ngIf="selectedMailing">
                <mat-card-content>
                  <div class="mailingform">
                    <div fxLayout="row" fxLayout.xs="column">

                      <mat-toolbar class="titlebox" fxLayoutAlign="space-between center">
                        <h2>{{selectedMailing.subject | uppercase | slice: 0:30}}</h2>
                        <mat-menu #menucall="matMenu">
                          <button mat-menu-item (click)="openDialogDeleteMailing()">
                            <mat-icon>delete</mat-icon>
                            <label i18n>Delete</label>
                          </button>
                          <button mat-menu-item (click)="createMailing()">
                            <mat-icon>note_add</mat-icon>
                            <label i18n>New</label>
                          </button>
                          <button mat-menu-item (click)="randomizeMailing()">
                            <mat-icon>merge_type</mat-icon>
                            <label i18n>Add to campaign</label>
                          </button>
                          <!-- <button *ngIf="!this.selectedMailing.templatearray" mat-menu-item (click)="editMailing()"
                          disabled>
                          <mat-icon>edit</mat-icon>
                          Edit in Maileditor
                          *ngIf="this.selectedMailing.templatearray"
                        </button> -->
                          <button (click)="selectedTabMailing = 0" mat-menu-item (click)="editMailing()">
                            <mat-icon>edit</mat-icon>
                            <label i18n>Edit in Maileditor</label>
                          </button>
                        </mat-menu>

                        <button mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                      </mat-toolbar>

                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                      <div fxFlex>
                        <mat-form-field appearance="outline" class="form-full-width">
                          <mat-label>Subject</mat-label>
                          <input matInput [(ngModel)]="selectedMailing.subject" placeholder="Subject" name="subject"
                            class="InputField" />
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <mat-form-field appearance="outline" class="form-full-width">
                          <mat-label>Title</mat-label>
                          <input matInput [(ngModel)]="selectedMailing.title" placeholder="Custom Titel/ID" name="title"
                            class="InputField" />
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                      <mat-form-field appearance="outline" class="form-full-width">
                        <mat-label>From Name</mat-label>
                        <input matInput [(ngModel)]="selectedMailing.sendname" placeholder="Jane Doe" name="sendname"
                          class="InputField" />
                      </mat-form-field>
                      <div fxLayout="row" class="form-full-width" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" class="form-full-width">
                          <mat-label>From Email</mat-label>
                          <input matInput [(ngModel)]="selectedMailing.from" placeholder="from@email.com" name="mail"
                            class="InputField" />
                        </mat-form-field>
                        <span *ngIf="option?.domain" matTextSuffix>@{{option.domain}}</span>
                      </div>

                    </div>

                    <div fxLayout="row" fxLayout.xs="column" *ngIf="!selectedMailing.linkedin" fxLayoutGap="20px">

                      <div fxFlex="50%">
                        <mat-form-field appearance="outline" style="width: 100%;" >
                          <mat-label i18n>Email or mailinglist (press enter to confirm)</mat-label>
                          <input matInput (keydown.enter)="onAddItemManual($event)" style="width: 100%;"
                            placeholder="Type and select or press enter" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            [matAutocomplete]="maillists" [formControl]="addItems" #chipInput name="mailings" />
                          <mat-autocomplete #maillists="matAutocomplete" (optionSelected)="onAddItemsMailing($event)">
                            <mat-option *ngFor="let mailingaddress of filteredItems | async" [value]="mailingaddress">
                              <div>{{ mailingaddress.listname }} | {{ mailingaddress.industry }}</div>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>

                      <div fxFlex="50%">
                        <mat-chip-list #chipList fxFlex="50%">
                          <mat-chip color="primary" *ngFor="let item of selectedItems; let i = index" ngDefaultControl>
                            {{ item }}
                            <mat-icon matChipRemove (click)="onRemoveItemsMailing(item, i)">cancel</mat-icon>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
    
   
                    </div>
                  </div>

                  <br />

                  <!-- <a *ngIf="!selectedMailing.linkedin" href="https://www.mail-tester.com" target="_blank" i18n>Check
                    spam
                    rating</a> -->
                  <br />

                  <!-- <mat-expansion-panel *ngIf="!selectedMailing.linkedin" class="max-expansion-panel">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mat-expansion-panel-header-title" i18n>
                      Schedule Mailing
                    </mat-panel-title>
                    <mat-panel-description i18n>
                      Schedule when your mail should be send
                      <mat-icon>schedule</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="max-div -content">
                    <div fxLayout="row" fxLayout.xs="column">
                      <div fxFlex>
                        <mat-form-field appearance="outline">
                          <input matInput [(ngModel)]="selectedMailing.time" placeholder="Time" class="InputField"
                            name="time" type="time" />
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <mat-form-field appearance="outline">
                          <mat-select placeholder="Timezone" [(ngModel)]="selectedMailing.timezone">
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezone">
                              {{ timezone.timezone }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div fxFlex>
                        <mat-datepicker #picker2 class="form-half-width"></mat-datepicker>
                        <input [min]="minDate" [max]="maxDate" [matDatepicker]="myDatepicker2"
                          [(ngModel)]="selectedMailing.date" placeholder="Send Date" name="date">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker2></mat-datepicker>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column">
                      <button a mat-raised-button (click)="openDialogScheduleMailing()" i18n>
                        Schedule
                        <mat-icon>schedule</mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
                <div *ngIf="!selectedMailing.linkedin"
                  style="background-color: whitesmoke; margin: 20px 2px 20px 20px; padding:20px 2px 20px 20px; width: 90%">
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-select placeholder="Copy from:" [(ngModel)]="copyfrommailing">
                      <mat-option *ngFor="let Mailing of Mailing; let i = index" [value]="Mailing">
                        {{ Mailing.subject }} {{ Mailing.date | date: "mediumDate" }}
                      </mat-option>
                    </mat-select>
                    <button a mat-raised-button (click)="copyFromMailing()" i18n>
                      Copy
                      <mat-icon>copy</mat-icon>
                    </button>
                  </div>
                </div> -->

                  <div [innerHTML]="previewstring" style="overflow: scroll;"></div>


                  <br/>
                  <div fxLayout="row" fxLayoutGap="20px">
                    <mat-checkbox [(ngModel)]="selectedMailing.send" *ngIf="!selectedMailing.linkedin" i18n>
                      Send
                    </mat-checkbox>
                    <button a mat-raised-button (click)="saveMailing('save')" i18n>
                      Save
                      <mat-icon>save</mat-icon>
                    </button>
                    <button a mat-raised-button (click)="sendMailing()" *ngIf="!selectedMailing.linkedin" i18n>
                      Send
                      <mat-icon>send</mat-icon>
                    </button>
                  </div>
                  <br/>
                  <!-- <div *ngIf="selectedMailing.send && !selectedMailing.linkedin">
                    <div style="margin: 20px 0px 40px 0px" class="max-div -content">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around">
                        <div fxFlexstyle="width: 25%;">
                          <label i18n>Opened </label>
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          <label i18n>Clicked </label>
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          <label i18n>Bounced</label>
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          <label>Unsubscribed</label>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around">
                        <div fxFlexstyle="width: 25%;">
                          {{ selectedMailing.totalopened }}
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          {{ selectedMailing.totalclicked }}
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          {{ selectedMailing.totalbounced }}
                        </div>
                        <div fxFlexstyle="width: 25%;">
                          {{ selectedMailing.totalunsubscribed }}
                        </div>
                      </div>
                    </div>

                    <mat-expansion-panel class="max-expansion-panel">
                      <mat-expansion-panel-header>
                        <mat-panel-title i18n>
                          Website Statistics
                        </mat-panel-title>
                        <mat-panel-description i18n>
                          Select your account to get Mailings website behavior
                          <mat-icon>insert_chart</mat-icon>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div class="max-div -content">
                        <div fxLayout="row" fxLayout.xs="column">
                          <mat-form-field appearance="outline" class="form-full-width">
                            <mat-select [(ngModel)]="selectedanalytics" placeholder="Google analytics Account"
                              class="InputField" name="GA">
                              <mat-option *ngFor="
                                    let selectedanalytics of Googleanalytics
                                  " [value]="selectedanalytics">
                                {{ selectedanalytics.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <button a mat-raised-button (click)="getAnalytics()" i18n>
                            Get Statistics
                            <mat-icon>insert_chart</mat-icon>
                          </button>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                          <div fxFlex>
                            <label i18n>Average Time on Website</label>
                          </div>
                          <label i18n>Average Bounce Rate</label>
                          <div fxFlex>
                            <label>Completed Goals</label>
                          </div>
                          <div fxFlex>
                            <label i18n>Page Views</label>
                          </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                          <div fxFlex i18n>{{ avgTimeOnPage | slice: 0:4 }} seconds</div>
                          <label>{{bounceRate | slice: 0:4}} %</label>
                          <div fxFlex i18n>{{ goalStartsAll }} goals</div>
                          <div fxFlex i18n>{{ pageview | slice: 0:4 }} views</div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </div> -->
                </mat-card-content>
              </mat-card>
            </div>


          </mat-tab>


          <!-- Mailing Campaign  -->
          <mat-tab style="width: 100%;">
            <ng-template mat-tab-label class="mat-tab-labels">
              <mat-icon>today</mat-icon>
              <div fxShow.xs="false" i18n>Campaigns</div>
            </ng-template>

            <app-campaignbuilder #mailcampaign style="width: 100%;" [account]="account" [option]="option"
              [company]="company" [Mailinglist]="Mailinglist"></app-campaignbuilder>
          </mat-tab>

          <!-- Mailing list  -->
          <mat-tab fxLayout="row" fxFlexOffset="10px" class="bottomlist">
            <ng-template mat-tab-label class="mat-tab-labels">
              <mat-icon>list</mat-icon>
              <div fxShow.xs="false" i18n>Mailing list</div>
            </ng-template>

            <div fxLayout="row" style="margin: 10px" fxLayoutGap="20px">
              <!-- ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
                [class.listview-xs-show]="listviewxsshow" -->
              <div fxFlex="20%" fxLayout="column">

                <div class="addtobutton">
                  <button mat-fab color="primary" i18n-matTooltip matTooltip="Upload to or create Multiple Lists"
                    (click)="createMailingList()">
                    <mat-icon>add</mat-icon>
                  </button>
                  <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="Delete selected list"
                    (click)="deleteMailingList()" (click)="togglemultiplelist()">
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button mat-mini-fab color="primary" i18n-matTooltip matTooltip="Upload to multiple lists"
                    (click)="togglemultiplelist()">
                    <mat-icon>cloud_upload</mat-icon>
                  </button>
                </div>


                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="searchboxMailinglist" (keyup.enter)="searchGoMailingList()"
                    name="searchmailig" placeholder="Name/Category" />
                </mat-form-field>

                <button a mat-raised-button color="primary" (click)="searchGoMailingList()">
                  <mat-icon>search</mat-icon>
                  <label i18n>Search Result</label>
                </button>

                <button a mat-raised-button (click)="getMailinglist()">
                  <label i18n>Show All</label>
                </button>

                <div class="listview">
                  <div *ngFor="let Mailinglist of Mailinglist" (click)="onSelectMailingList(Mailinglist)">
                    <div class="listbox" style="font-size: 8pt" [class.selected]="Mailinglist === selectedMailingList">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                        <!-- <mat-icon color="primary" class="iconsize-16">
                                {{Mailinglist.listname | slice: 0:1}}</mat-icon> -->
                        <!--  |slice: 1:22 -->
                        <label>{{Mailinglist.listname |slice: 0:30 }}</label>
                        <strong>{{ Mailinglist.total }}</strong>
                      </div>
                      <!-- <div fxLayout="row" fxLayout.xs="column">
                            
                              | Clicked:
                              {{Mailinglist.totalclicked}}
                              | Opened:
                              {{Mailinglist.totalopened}}
                            </div> -->
                    </div>
                  </div>
                </div>

                <div style="font-size: 8pt;">
                  <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                      <label i18n>Total Amount of Entries:</label>
                    </div>
                    <div fxFlex>
                      {{ mailinglisttotal }}
                    </div>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex i18n>
                      Total Amount of Lists:
                    </div>
                    <div fxFlex>
                      {{ mailinglistcount }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- gClass.gt-xs="container-normal" ngClass.lt-sm="container-noshow"
                [class.container-xs-show]="listviewxsshow"  -->
              <div fxFlex="80%" fxLayout="column" *ngIf="selectedMailingList">
                <mat-card>
                  <mat-card-content>
                    <div>
                      <h1 i18n>Edit maillist</h1>
                      <br>
                      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px">
                        <mat-form-field appearance="outline">
                          <mat-label>Listname</mat-label>
                          <input matInput [(ngModel)]="selectedMailingList.listname" placeholder="Name"
                            name="listname" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Category</mat-label>
                          <input matInput [(ngModel)]="selectedMailingList.category" placeholder="Category"
                            name="cat" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Industry</mat-label>
                          <input matInput [(ngModel)]="selectedMailingList.industry" placeholder="Industry/NAICS"
                            name="industry" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxLayoutGap="20px">
                          <mat-label>Location</mat-label>
                          <input matInput [(ngModel)]="selectedMailingList.geolocation" placeholder="Location"
                            name="location" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Description</mat-label>
                          <input matInput [(ngModel)]="selectedMailingList.description" placeholder="Description"
                            name="description" />
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column">

                        <button a mat-raised-button color="primary" (click)="saveMailingList()" i18n>
                          Save Mailinglist
                        </button>

                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>


                <div fxFlex class="relationcontainer" *ngIf="newmailinglisttoggle">
                  <mat-card>
                    <mat-card-content>
                      <div class="form-full-width">
                        <div fxLayout="row" fxLayout.xs="column">
                          <label i18n>Create new maillist</label>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                          <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="newMailinglist.listname" placeholder="Name" name="name"
                              class="InputField" />
                            <!-- <mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error> -->
                          </mat-form-field>
                          <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="newMailinglist.categorie" placeholder="Categorie" name="cat"
                              class="InputField" />
                          </mat-form-field>
                          <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="newMailinglist.industry" placeholder="Industry/NAICS"
                              name="industry" class="InputField" />
                          </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

                          <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="newMailinglist.geolocation" placeholder="Location"
                              name="location" class="InputField" />
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <input matInput [(ngModel)]="newMailinglist.description" placeholder="Description"
                              name="description" class="InputField" />
                          </mat-form-field>

                          <button a mat-raised-button (click)="createMailingList()" i18n>
                            Save Mailinglist
                          </button>
                        </div>
                      </div>

                    </mat-card-content>
                  </mat-card>
                </div>

                <mat-tab-group>
                  <mat-tab label="List">
                    <div *ngIf="selectedMailingList" style="padding: 10px">
                      <div style="font-weight: bold;">
                        <div fxFlex="5%">Title</div>
                        <div fxFlex="15%">Firstname</div>
                        <div fxFlex="15%">Lastname</div>
                        <div fxFlex="20%">Email</div>
                        <div fxFlex="20%">Company</div>
                        <div fxFlex="20%">Website</div>
                      </div>
                      <mat-divider>
                      </mat-divider>
                      <br>
                      <div *ngFor="let mailinglistdetails of mailinglistentries; let i = index">
                        <div style="width: 100%;">

                          <div fxLayout="row" fxLayout.xs="column" class="mailingentry">
                            <div fxFlex="5%">{{ mailinglistdetails.title }}</div>
                            <div fxFlex="15%"> {{ mailinglistdetails.firstname }}</div>
                            <div fxFlex="15%"> {{ mailinglistdetails.lastname }}</div>
                            <div fxFlex="20%">{{ mailinglistdetails.email }}</div>
                            <div fxFlex="20%">{{ mailinglistdetails.company }}</div>
                            <div fxFlex="20%" fxFlexstyle="color: blue;" class="mailinglistdetailscell"
                              (click)="openmailinglistwebsite(i)">
                              {{ mailinglistdetails.website }}
                            </div>
                            <div fxFlex="5%">
                              <mat-icon color="primary" class="iconsize-10"
                                (click)="deleteOneMailingList(mailinglistdetails.address)">
                                delete</mat-icon>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="column" style="width: 100%; margin-top: 50px;">
                        <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
                          <div fxFlexstyle="width: 50%">
                            <button (click)="getRelationsbackpage()" [disabled]="skiplist === 0">
                              <mat-icon>navigate_before</mat-icon>
                            </button>
                          </div>
                          <div fxFlexstyle="width: 50%">
                            <button (click)="getMailingEntriesNextpage()"
                              [disabled]="totalmaillistentries < 100 + skiplist">
                              <mat-icon>navigate_next</mat-icon>
                            </button>
                          </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                          <label>{{skiplist}} / {{(skiplist + 100)}}</label>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column">
                          <label>Total: {{totalmaillistentries}}</label>
                        </div>
                      </div>


                    </div>
                  </mat-tab>
                  <mat-tab label="Upload New">
                    <div fxFlex class="uploadcontainer" *ngIf="toggleuploadlist" style="padding: 10px">

                      <br>

                      <mat-card *ngIf="selectedMailingList">
                        <mat-card-content>
                          <label i18n>Upload new addresses </label>
                          <br />

                          <div *ngIf="selectedMailingList">
                            <br>
                            <label i18n>Upload to:</label>
                            <label>{{ selectedMailingList.listname }}</label>
                          </div>
                          <div fxLayout="column" fxLayout.xs="column">
                            <div fxLayoutGap="20px" fxLayout="row">
                              <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.email" placeholder="Email"
                                  class="InputField" name="address" type="email" />
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Title</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.title" placeholder="Title"
                                  class="InputField" name="title" />
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Firstname</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.firstname" placeholder="Firstname"
                                  class="InputField" name="firstname" />
                              </mat-form-field>
                            </div>
                            <div fxLayoutGap="20px" fxLayout="row">
                              <mat-form-field appearance="outline">
                                <mat-label>Lastname</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.lastname" placeholder="Lastname"
                                  class="InputField" name="lastname" />
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Companyname</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.companyname" placeholder="Company"
                                  class="InputField" name="company" />
                              </mat-form-field>
                            </div>
                            <label>Add optional information to use in Email campaigns as email value</label>
                            <div fxLayoutGap="20px" fxLayout="row">
                              <mat-form-field appearance="outline">
                                <mat-label>Info1</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.const1" placeholder="Info1"
                                  class="InputField" name="Info1" />
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Info2</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.const2" placeholder="Info2"
                                  class="InputField" name="Info2" />
                              </mat-form-field>
                            </div>
                            <div fxLayoutGap="20px" fxLayout="row">
                              <mat-form-field appearance="outline">
                                <mat-label>Info3</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.const3" placeholder="Info3"
                                  class="InputField" name="Info3" />
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Info4</mat-label>
                                <input matInput [(ngModel)]="newMailinglistEntry.const4" placeholder="Info4"
                                  class="InputField" name="Info4" />
                              </mat-form-field>
                            </div>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <button a mat-raised-button (click)="addOneMailingList()" color="primary" i18n>
                              Add Recipient
                            </button>
                          </div>
                        </mat-card-content>
                      </mat-card>
                      <br />
                      <mat-card>
                        <mat-card-content>
                          <div>
                            <label i18n>Check the following first: </label>
                            <li i18n>list is XLSX/XLS/CSV format</li>
                            <!-- <li i18n>
                              div starts with columns: address, company, firstname,
                              lastname, title
                            </li> -->
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <mat-form-field appearance="outline">
                              <mat-label>Email</mat-label>
                              <mat-select placeholder="Select Email" [(ngModel)]="emailheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                              <mat-label>Maillinglist</mat-label>
                              <mat-select placeholder="Select Mailinglist (if applicable)"
                                [(ngModel)]="mailinglistheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

                            <mat-form-field appearance="outline">
                              <mat-label>First name</mat-label>
                              <mat-select placeholder="Select First Name" [(ngModel)]="firstnameheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                              <mat-label>Last name</mat-label>
                              <mat-select placeholder="Select Last Name" [(ngModel)]="lastnameheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <mat-form-field appearance="outline">
                              <mat-label>Website</mat-label>
                              <mat-select placeholder="Select Website" [(ngModel)]="websiteheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                              <mat-label>Title</mat-label>
                              <mat-select placeholder="Select Title" [(ngModel)]="titleheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <mat-form-field appearance="outline">
                              <mat-label>Compamy</mat-label>
                              <mat-select placeholder="Select Company" [(ngModel)]="companyheader">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                              <mat-label>Info 1</mat-label>
                              <mat-select placeholder="Add additional info" [(ngModel)]="var1header">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

                            <mat-form-field appearance="outline">
                              <mat-label>Info 2</mat-label>
                              <mat-select placeholder="Add additional info" [(ngModel)]="var2header">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                              <mat-label>Info 3</mat-label>
                              <mat-select placeholder="Add additional info" [(ngModel)]="var3header">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                            <mat-form-field appearance="outline">
                              <mat-label>Info 4</mat-label>
                              <mat-select placeholder="Add additional info" [(ngModel)]="var4header">
                                <mat-option *ngFor="let header of headers" [value]="header">
                                  {{ header }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-slide-toggle [(ngModel)]="createnewmailinglistonfield" i18n>Create new Mailinglist
                              based
                              on
                              Mailinglist
                              field</mat-slide-toggle>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">

                            <button a mat-stroked-button (click)="runImportMailingList()" i18n>
                              Upload to Mailinglist
                            </button>

                            <!-- <button a mat-stroked-button (click)="runImportDeleteListMember()" i18n>
                                    Find and Delete from List
                                  </button> -->

                            <!-- <button a mat-stroked-button (click)="checkListOrCreate()" i18n>
                                    Verify List and Upload
                                  </button> -->
                          </div>
                          <br>
                          <div fxLayout="row" fxLayout.xs="column">
                            <div fxFlex>
                              <!-- <button a mat-stroked-button (click)="findEmailAdresses()">Find Email and Upload</button> -->
                            </div>
                          </div>
                          <div fxLayout="column">
                            <h3 i18n>Upload small file (less then 500 entries)</h3>
                            <xlsx-file-upload (uploadedXls)="xlsxUploaded($event)">
                              <div class="drop-zone" [ngClass]="{'drop-zone-over': hasBaseDropZoneOver}">
                                {{ uploaderContent | async }}
                              </div>
                            </xlsx-file-upload>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column">
                            <!-- 
                                  <input type="file" style="display: inline-block;" (change)="xlsxUploaded($event)"
                                    placeholder="Upload file" accept=".xlsx" /> -->
                            <!-- <button type="button" class="btn btn-info" (click)="Upload()">Upload</button> -->

                          </div>

                        </mat-card-content>
                      </mat-card>
                      <br>
                      <mat-card>
                        <mat-card-content>

                          <div fxLayout="column">
                            <h3 i18n>Upload large file (more then 500 entries)</h3>
                            <div>
                              <label i18n>Please use standard xlsx format with the following header:</label>
                              <label i18n>company, firstname, lastname, title, website, mailing</label>

                            </div>
                            <div ng2FileDrop [ngClass]="{ 'drop-zone-over': hasBaseDropZoneOver }"
                              (fileOver)="fileOverAnother($event)" [uploader]="uploader" class="drop-zone" i18n>
                              Drop Zone
                            </div>

                            <div fxLayout="row">
                              <button mat-button (click)="fileInput.click()" i18n>Choose file</button>
                            </div>

                            <span
                              style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
                              <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
                            </span>
                          </div>


                          <div fxLayout="row" fxLayout.xs="column">
                            <div fxFlexcolspan="2">
                              <div class="righ-upload-block">
                                <div>
                                  <thead>
                                    <div fxLayout="row" fxLayout.xs="column">
                                      <th width="50%" i18n>Name</th>
                                      <!-- <th>Size</th> -->
                                      <th i18n>Progress</th>
                                      <th i18n>Status</th>
                                      <th i18n>Actions</th>
                                    </div>
                                  </thead>
                                  <tbody>
                                    <div fxLayout="row" fxLayout.xs="column" *ngFor="let item of uploader.queue">
                                      <div fxFlex>
                                        <strong>{{ item?.file?.name }}</strong>
                                      </div>
                                      <!-- <div fxFlex  nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB
                                              </div> -->
                                      <div fxFlex *ngIf="item.isHTML5">
                                        <div style="margin-bottom: 0;">
                                          <mat-progress-bar color="primary" mode="determinate" [value]="item.progress">
                                          </mat-progress-bar>
                                        </div>
                                      </div>

                                      <div fxFlex>

                                        <mat-icon *ngIf="item.isSuccess">done</mat-icon>

                                        <mat-icon *ngIf="item.isCancel">cancel</mat-icon>

                                        <mat-icon *ngIf="item.isError">remove_circle</mat-icon>

                                      </div>
                                      <div fxFlexnowrap>
                                        <button mat-button (click)="setupload(item?.file?.name)"
                                          [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                          <mat-icon>file_upload</mat-icon>
                                          <label i18n>Upload</label>
                                        </button>
                                        <button mat-button (click)="item.cancel()" [disabled]="!item.isUploading">
                                          <mat-icon>cancel</mat-icon>
                                          <label i18n>Cancel</label>
                                        </button>
                                        <button mat-button (click)="item.remove()">
                                          <mat-icon class="iconsize-10">remove_circle</mat-icon>
                                          <label i18n>Remove</label>
                                        </button>
                                      </div>
                                    </div>
                                  </tbody>
                                </div>

                                <div>
                                  <div>
                                    <label i18n>Queue progress:</label>

                                    <div class="progress">
                                      <div>
                                        <mat-progress-bar color="primary" mode="determinate"
                                          [value]="uploader.progress">
                                        </mat-progress-bar>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br>
                                <mat-checkbox [(ngModel)]="createnewmailinglistlarge">Create new lists, mailing column
                                  should be present.</mat-checkbox>
                                <br>
                                <button mat-button (click)="uploadFilelarge()" [disabled]="!newFiles.url" i18n>Start
                                  Import</button>
                              </div>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </mat-tab>
                </mat-tab-group>


              </div>
            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </mat-tab>

    <!-- Channels/Social Media _______________________________ -->

    <mat-tab label="Social Media">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon>mode_comment</mat-icon>
        <div fxShow.xs="false" i18n>Channels/Social Media</div>
      </ng-template>

      <app-marketingchannels [Account]="Account" [SelectedRelation]="SelectedRelation" [option]="option"
        [selectedChannel]="selectedchannel">
      </app-marketingchannels>
    </mat-tab>

    <!-- Promotions_______________________________ -->

    <!-- <mat-tab label="Promotions">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon (click)="getAdwords()">add_shopping_cart</mat-icon>
        <div fxShow.xs="false">Promotions</div>
      </ng-template>

      <app-marketingpromotions [Account]="Account" [SelectedRelation]="SelectedRelation" [option]="option">
      </app-marketingpromotions>

    </mat-tab> -->

    <mat-tab label="Forms">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon>call_to_action</mat-icon>
        <div fxShow.xs="false" i18n>Forms</div>
      </ng-template>

      <app-marketingforms [Account]="Account" [SelectedRelation]="SelectedRelation" [option]="option"
        [company]="company" [selectedForm]="selectedform">
      </app-marketingforms>
    </mat-tab>

    <!-- Publications_______________________________ -->
    <mat-tab fxFlex="100%" label="New Publications" class="relations">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon>text_format</mat-icon>
        <div fxShow.xs="false">Publications</div>
      </ng-template>

      <app-marketingpublications [Account]="Account" [SelectedRelation]="option" [option]="option" [company]="company"
        (channelselect)="setChannel($event)">
      </app-marketingpublications>


    </mat-tab>

    <mat-tab label="Chats">
      <ng-template mat-tab-label class="mat-tab-labels">
        <mat-icon>message</mat-icon>
        <div fxShow.xs="false" i18n>Chats</div>
      </ng-template>

      <app-chats [Account]="Account" [SelectedRelation]="SelectedRelation" [option]="option" [company]="company">
      </app-chats>
    </mat-tab>

  </mat-tab-group>
</div>