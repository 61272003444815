/* tslint:disable */
import {
  Relations,
  Chatrooms
} from '../index';

declare var Object: any;
export interface ChatsInterface {
  "name"?: string;
  "screenname"?: string;
  "owners"?: any;
  "avatar"?: string;
  "thankyoutext"?: string;
  "width"?: number;
  "height"?: number;
  "color"?: string;
  "textcolor"?: string;
  "hform"?: string;
  "wform"?: string;
  "startIcon"?: string;
  "animationtype"?: string;
  "id"?: any;
  "relationsId"?: any;
  relations?: Relations;
  chatroom?: Chatrooms[];
}

export class Chats implements ChatsInterface {
  "name": string;
  "screenname": string;
  "owners": any;
  "avatar": string;
  "thankyoutext": string;
  "width": number;
  "height": number;
  "color": string;
  "textcolor": string;
  "hform": string;
  "wform": string;
  "startIcon": string;
  "animationtype": string;
  "id": any;
  "relationsId": any;
  relations: Relations;
  chatroom: Chatrooms[];
  constructor(data?: ChatsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chats`.
   */
  public static getModelName() {
    return "Chats";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Chats for dynamic purposes.
  **/
  public static factory(data: ChatsInterface): Chats{
    return new Chats(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Chats',
      plural: 'Chats',
      path: 'Chats',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "screenname": {
          name: 'screenname',
          type: 'string'
        },
        "owners": {
          name: 'owners',
          type: 'any'
        },
        "avatar": {
          name: 'avatar',
          type: 'string'
        },
        "thankyoutext": {
          name: 'thankyoutext',
          type: 'string'
        },
        "width": {
          name: 'width',
          type: 'number'
        },
        "height": {
          name: 'height',
          type: 'number'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "textcolor": {
          name: 'textcolor',
          type: 'string'
        },
        "hform": {
          name: 'hform',
          type: 'string',
          default: 'px'
        },
        "wform": {
          name: 'wform',
          type: 'string',
          default: 'px'
        },
        "startIcon": {
          name: 'startIcon',
          type: 'string',
          default: 'https://app.enydea.com/api/Containers/standardiconcolourcircle/download/talk.png'
        },
        "animationtype": {
          name: 'animationtype',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "relationsId": {
          name: 'relationsId',
          type: 'any'
        },
      },
      relations: {
        relations: {
          name: 'relations',
          type: 'Relations',
          model: 'Relations',
          relationType: 'belongsTo',
                  keyFrom: 'relationsId',
          keyTo: 'id'
        },
        chatroom: {
          name: 'chatroom',
          type: 'Chatrooms[]',
          model: 'Chatrooms',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chatsId'
        },
      }
    }
  }
}
