<div class="listview" class="mat-typography" >

  <div *ngFor="let Mailing of Mailing" (click)="onSelectMailing(Mailing)"
    [class.selected]="Mailing === data">
    <div class="listbox">
      <div fxLayout="row">
        <mat-icon fxFlex="10%" *ngIf="Mailing.linkedin" class="iconsize-10" svgIcon="enydea_linkedin"></mat-icon>
        <mat-icon fxFlex="10%" *ngIf="!Mailing.linkedin && !Mailing.responsive" class="iconsize-10">mail</mat-icon>
        <mat-icon fxFlex="10%" *ngIf="Mailing.responsive && !Mailing.linkedin">art_track</mat-icon>
        <label fxFlex="40%">{{ Mailing.subject | slice: 0:30 }}</label>
        <label fxFlex="40%">{{ Mailing.title | slice: 0:30 }}</label>
        <label fxFlex="10%"> {{ Mailing.date | date }}</label>
       
      </div>
    </div>
  </div>
</div>


<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial i18n>Ok</button>
</div>