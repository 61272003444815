import { Component, Inject, Injectable } from "@angular/core";
import * as THREE from "three";
import { EasetypesService } from "../supportfunctions/easetypes.service";
import { threedanimation, threedanimationcontrol } from "../videocreator.model";
import gsap from "gsap";
import { CustomBounce, CustomEase, PixiPlugin, Physics2DPlugin, ThreePlugin, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper, Draggable } from 'src/assets/js/all';
import { Mesh } from "three";
import { keyframes } from "@angular/animations";
import PathEditor from 'src/assets/js/utils/PathEditor';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
gsap.registerPlugin(Physics2DPlugin, PixiPlugin, CustomEase, CustomBounce, Draggable, ThreePlugin, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper);
const plugins = [PixiPlugin, Draggable, CustomEase, CustomBounce, InertiaPlugin, DrawSVGPlugin, MorphSVGPlugin, ScrambleTextPlugin, SplitText, Physics2DPlugin, MotionPathPlugin, MotionPathHelper]; //needed for GSAP

@Injectable({
    providedIn: 'root'
})


export class MovePathHelperService {
    private pathEditor: PathEditor;


    //  public ypath = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="800" height="400" viewBox="0 0 1000 1000">
    //  <path d="M0 0.5 199.68 0.5 400.56 0.5 600.56 0.5 796.17 0.5" style="fill: none;stroke: #000;stroke-miterlimit: 10"/>
    // </svg>`;

    // editpath = 'M790,507 C790,663.29658 663.29658,790 507,790 350.70342,790 224,663.29658 224,507 224,350.70342 350.70342,224 507,224 663.29658,224 790,350.70342 790,507 z';

    constructor(
        private easetypesService: EasetypesService,
        public dialog: MatDialog
    ) { }


    async createYmotionPath(animation: threedanimationcontrol): Promise<any> {
        const dialogRef = this.dialog.open(DialogSetpathDialog, { data: animation.ysvg });

        dialogRef.afterClosed().subscribe(result => {
            animation.ysvg = result;
            return animation;
        });
    }

    editThreedMotionPath(animation, i, id) {
        //console.log(animation)
        let idmotion = id + '-' + i + 'p';
        //console.log(idmotion)
        let svgpath = document.getElementById(idmotion) as unknown;
        //console.log(svgpath)

        this.pathEditor = PathEditor.create(svgpath, {
            handleSize: 2,
            selected: true,
            draggable: true,
            _selectionColor: '#A020F0'
        });
    }


    saveNewThreedMotionPath(id, canvas, selectedelement, i) {
        let stringpath: string = this.pathEditor.getString();//
        let newpath: string = MotionPathPlugin.stringToRawPath(stringpath);
        
        const collection = document.getElementsByClassName('path-editor-selection');
        let svgpath = collection[(collection.length - 1)]
        let transf = svgpath.getAttribute('transform'); // there is transform on the element we need remove it


        let style = transf;

        // just skip but needs fixing to matrix!!! 
        if (style.indexOf('rotate') > 0) {
            return
        }

        style = style.replace('matrix(', '');
        style = style.replace('matrix(', '');
        style = style.replace('translate(', '');
        style = style.replace(')', '');
        style = style.replace(/,/g, ' ');
        // console.log(style)
        let newmatrix = style.split(' ').map(Number);

        if (newmatrix.length > 2) {
            newpath = MotionPathPlugin.transformRawPath(newpath, newmatrix[0], newmatrix[1], newmatrix[2], newmatrix[3], newmatrix[4], newmatrix[5]);
        }

        let finalpath = MotionPathPlugin.rawPathToString(newpath);

        if (!newpath) { console.log('no path'); return; }
        //this.saveMotionPath(newpath, id, this.selectanimationindex);
        let newid = id + '-' + i;
        let w = canvas.width.replace('px', '');
        let h = canvas.height.replace('px', '');
        let newview = '0 0 ' + w + ' ' + h;
        let newsvgpath = '<svg id="' + newid + 'mp" viewBox="' + newview +
            '" class="path-edit"><path id="' + newid + 'p" style="opacity: 0; " d="' + finalpath + '" /></svg>';
        selectedelement.motionpath[i] = newsvgpath;
    }



    async setMotionPath(threedanimation: threedanimationcontrol, mesh, primairytimeline: GSAPTimeline, element, i, w, h): Promise < GSAPTimeline > {
            let ease = this.easetypesService.selectEaseType(threedanimation.easetype);
            let iset = mesh;

            let idmotion = element.id + '-' + i + 'p';
            let svgpath = document.getElementById(idmotion) as unknown;

            // align path with threed screen
            let compensatedw = parseInt(w) / 2;
            let compensatedh = parseInt(h) / 2;

            // console.log(svgpath, id, i)

            let rawpath = MotionPathPlugin.getRawPath(svgpath);
            // let rawpath = MotionPathPlugin.stringToRawPath(svgpath);
            MotionPathPlugin.cacheRawPathMeasurements(rawpath);

            let rawpathy;
            if(threedanimation.ysvg) {
            rawpathy = await MotionPathPlugin.getRawPath(threedanimation.ysvg);
            await MotionPathPlugin.cacheRawPathMeasurements(rawpathy);
        }


        let keyframes = [];
        //let keyframesrotation = [];
        let frcount = 30; // set amount of point changes 
        let ccount = threedanimation.duration * frcount;
        // let splineArray = [];

        //let rot1 = 90;
        //let rot2: number;

        for (let k = 0; k < ccount; k++) {
            let point = MotionPathPlugin.getPositionOnPath(rawpath, k / ccount, true); // calculate every point in time

            let pi = Math.PI;
            let radians = point.angle * (pi / 180);

            if (k === 0) {
                mesh.position.x = point.x - compensatedw;
                mesh.position.z = point.y - compensatedh;
                if (threedanimation.adjustrotation) {
                    mesh.rotation.y = radians;
                }

            }


            // add extra plugin gsap https://greensock.com/forums/topic/16993-threejs-properties/page/2/ 
            // let newrot = 0;
            // if (point.angle !== rot1 ){
            //         newrot = point.angle;
            // } 
            let keyframe = {
                three: {
                    // postion: {
                    z: (point.y - compensatedh),
                    x: (point.x - compensatedw),
                    //  rotationY: (180 - (point.angle - 90)) // -180 // compensate??
                }

            }; //  compensate and 400 baseline and revert postive negative 

            if (threedanimation.adjustrotation) {
                keyframe.three['rotationY'] = (180 - (point.angle - 90)) // -180 // compensate??
            }


            if (threedanimation.ysvg) {
                let pointy = MotionPathPlugin.getPositionOnPath(rawpathy, k / ccount, true);
                let pi2 = Math.PI;
                let radians2 = pointy.angle * (pi2 / 180);

                if (k === 0) {
                    mesh.position.y = (pointy.y - 400) * -1; // 400 is the baseline for the svg path

                    if (threedanimation.adjustrotation) {
                        mesh.rotation.y = radians2;
                    }
                }
                keyframe.three['y'] = (pointy.y - 400) * -1;

                if (threedanimation.adjustrotation) {
                    keyframe.three['rotationX'] = (360 - pointy.angle);
                }

            }
            keyframes.push(keyframe);
        }

        let sset: GSAPTimelineVars = {
            duration: threedanimation.duration,
            repeat: threedanimation.repeat,
            yoyo: threedanimation.yoyo,
            keyframes: keyframes,
            ease: ease,
        }

    
        if (threedanimation.fromto === 'from') {
            primairytimeline.from(iset, sset, threedanimation.start_time);
            //primairytimeline.from(iset.rotation, rset, threedanimation.start_time);
        } else {
            primairytimeline.to(iset, sset, threedanimation.start_time);
            //primairytimeline.from(iset.rotation, rset, threedanimation.start_time);
        }

        return primairytimeline;
    }


    setPosition(element, mesh: THREE.Object3D, primairytimeline) {
        //let tl = new TimelineMax();
        let aniset = {
            duration: element.duration,
            ease: element.easetype,
            repeat: element.repeat,
            z: (mesh.position.x + element.explodedepthx),
            y: (mesh.position.y + element.explodedepthy),
            x: (mesh.position.x + element.explodedepthx),
            yoyo: element.yoyo,
        }

        if (element.fromto === 'from') {
            primairytimeline.from(mesh.position, aniset, element.starttime);
        }
        if (element.fromto === 'to') {
            primairytimeline.to(mesh.position, aniset, element.starttime);
        }
        return;
    }


    async saveNewMotionPath(element: threedanimation, animation) {
        // console.log('save motion path')
        // delete copy motion path button --> standard gsap edit see plugin

        if (!element) { return; }

        let id = element.id;
        let newpath = this.pathEditor.getString();//
        if (!newpath) { console.log('no path'); return; }

        setTimeout(() => {
            let htmlelement = document.getElementById(id);
            let position = htmlelement.getBoundingClientRect();
            let boundelement = document.getElementById('myBounds');
            let boundposition = boundelement.getBoundingClientRect();
            element.posx = position.left - boundposition.left - (position.width * (animation.travellocX / 100));
            element.posy = position.top - boundposition.top - (position.height * (animation.travellocY / 100));

        }, 500)

        animation = undefined;
        // this.detectchange(); // remove as it is also fired from detectchange?? 
    }


}



@Component({
    selector: 'dialog-setpath',
    templateUrl: 'dialog-setpath.html',
})
export class DialogSetpathDialog {
    pathEditor: any;

    constructor(
        public dialogRef: MatDialogRef<DialogSetpathDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        let svgpath = document.getElementById('Ypathmotion') as unknown;
        let el = svgpath as HTMLElement;
        console.log(this.data);

        if (this.data) {
            el.setAttribute('d', this.data)
        }


        this.pathEditor = PathEditor.create(svgpath, {
            handleSize: 2,
            selected: true,
            draggable: false,
            _selectionColor: '#A020F0'
        });
    }

    save() {
        let svgpath = document.getElementById('Ypathmotion') as unknown;
        let pathstring = this.pathEditor.getString(svgpath);
        this.dialogRef.close(pathstring)
    }

    cancel() {
        this.dialogRef.close();
    }


}