import { Injectable } from '@angular/core';
import { SVG } from '@svgdotjs/svg.js';
import PathEditor from 'src/assets/js/utils/PathEditor';
import { FilesApi } from 'src/app/shared/sdk';
import svgDragSelect from "svg-drag-select";
import { vectoranimation, vectoranimationtype, vectorcombinator, vectorelement } from '../videocreator.model';
import { MotionPathPlugin, MotionPathHelper } from 'src/assets/js/all';
import { EasetypesService } from './easetypes.service';
import { Draggable } from 'src/assets/js/all';
import { DraggableService } from './draggable.service';

//import { VideocreatorComponent } from '../videocreator.component';

interface viewboxObject {
  x: number,
  y: number,
  width: number,
  height: number,
}

@Injectable({
  providedIn: 'root'
})
export class VectorService {

  // @ViewChild(VideocreatorComponent, { static: false })
  // private videocreatorComponent: VideocreatorComponent;
  public editfigure = false;
  selectmultiplepaths: boolean = false;
  dragselectvectpath: boolean = false;
  dragselectiontrue: boolean = false;
  selectedVecPathmultiple: any[];
  editsvg: boolean = false;
  selectedVecPath: any;
  colorpick: any;
  colorpickline: any;
  linewidth: any;
  //public cancelDragSelect?: () => void;

  public pathEditor: PathEditor;
  dragAreaOverlay: HTMLDivElement;
  public newz: number = 1;
  pathdrag: boolean;
  rotatepath: any;
  draginprogress: boolean = false;

  constructor(
    private filesApi: FilesApi,
    //private VideocreatorComponent: VideocreatorComponent,
    private easetypesService: EasetypesService,
    private draggableService: DraggableService,
  ) { }

  updateCombiBox(element, animationarray) {
    // filter elements for correct vectorcombi
    for (let i = 0; i < animationarray.length; i++) {
      let animation = animationarray[i];
      if (animation.type === 'vectorcombi') {
        this.combiBoxCalculator(animation);
      }
    }
    // calculate new position
  }

  onSetCombiBox(i, element, newel, animationarray) {
    let vectorcombi = animationarray[i];
    // this.combiBoxCalculator(vectorcombi);
    this.updateCombiBox(element, animationarray);
  }



  // dragselectvectpath, 
  // dragselectiontrue, editfigure, 
  // dragSelect, selectedelement, 
  // selectmultiplepaths, 
  // selectedVecPath,
  // selectedVecPathmultiple,

  async clickVectorPaths(e, selectedelement) {

    // console.log(e, selectedelement)

    if (!selectedelement.svgcombi) { return }


    //    console.log(this.editsvg, this.editfigure, this.rotatepath, this.dragselectiontrue)
    if (!this.editsvg) { return } // edit svg menu
    if (this.editfigure) { return } // change shape outline
    if (this.rotatepath) { return } //  rotate shpae
    if (this.dragselectiontrue) { return }; // drag selection active

    // console.log(this.dragselectvectpath)
    if (this.dragselectvectpath && !this.dragselectiontrue) { // && !this.editsvg
      this.dragSelect(selectedelement.id); // activate drag 
      return
    }

    // deactivate drag element add to selectiongroup
    let vecttmp = document.getElementById(selectedelement.id);
    let vect = vecttmp.getElementsByTagName('svg')[0];
    let svg = SVG(vect) as any; // SVG.js object
    let doc = vect.getElementById(e.target.id) // doc element

    // if (this.dragselectiontrue === false) { // check if drag is not in progress && 
    if (e.target.localName !== 'svg') {
      console.log(e.target.localName)
      if (this.selectmultiplepaths === false) {
        if (this.selectedVecPath === e.target) { //deselect
          this.removeVectorPathMultiSelection(selectedelement); // 
          let path = SVG(e.target) as any;
          path.draggable(false);
          this.selectedVecPathmultiple = [];
          this.selectedVecPath = undefined;
        } else { // select
          if (this.selectedVecPath) {
            await this.selectedVecPath.classList.remove('data-selected');
          }
          this.selectOnePath(e.target)
        }
      }
      if (this.selectmultiplepaths && !this.draginprogress) {
        this.selectedVecPath = e.target; // keep is connected to the ng view
        // check if already selected
        const exist = this.selectedVecPathmultiple.findIndex(x => x.id === e.target.id);
        if (exist !== -1) { // remove if exist
          //e.target.style.outline = null;
          if (this.selectedVecPath) {
            await this.selectedVecPath.classList.remove('data-selected');
          }
          this.selectedVecPathmultiple.splice(exist, 1);
          this.removeFromGroup(selectedelement.id, e.target.id);
        } else {
          // if not exists add To Group
          this.addToGroup(e.target, svg)
          doc.classList.add('data-selected');
          this.selectedVecPathmultiple.push(e.target.cloneNode(true));

        }
      }
    }
  }

  selectPathsFromAnimation(vectoranimation: vectoranimationtype, selectedelement) {

    this.selectedVecPathmultiple = [];
    for (let i = 0; i < vectoranimation.svganimationpaths[1].length; i++) {
      //const element = vectoranimation.svganimationpaths[i];
      //       console.log(vectoranimation.svganimationpaths)

      let id = vectoranimation.svganimationpaths[1][i];
      let vecttmp = document.getElementById(selectedelement.id);
      let vect = vecttmp.getElementsByTagName('svg')[0];
      let svg = SVG(vect) as any; // SVG.js object

      let doc = document.getElementById(id) // doc element
      doc.classList.add('data-selected');
      this.addToGroup(doc, svg);
    
      this.selectedVecPathmultiple.push(doc.cloneNode(true));
    }
    console.log(vectoranimation, selectedelement);
    console.log(this.selectedVecPathmultiple);
  }

  selectOnePath(target: any) {
    this.selectedVecPathmultiple = [];
    this.selectedVecPathmultiple.push(target.cloneNode(true)); // need to copy /// and clean every selection
    target.classList.add('data-selected');
    this.setVectorColor(target);
    let path = SVG(target) as any;
    path.draggable(true);
    this.selectedVecPath = target;
  }

  addToGroup(target, svg) {
    let path = SVG(target) as any;
    //if (this.selectedVecPathmultiple.length > 0){
    let group = document.getElementById('selectiongroupXL');
    let g;
    //console.log(group)
    if (!group) { // create new
      g = svg.group().id('selectiongroupXL');
      svg.add(g)
      g.add(path);
      g.draggable()
      g.dragstart = () => {
        this.draginprogress = true
      }
      g.dragsend = () => {
        this.draginprogress = false
      }
    } else { // set existing
      g = SVG(group) as any;
      g.add(path);
    }
    //}
  }

  removeFromGroup(id, pathid) {
    //console.log(id, pathid)
    let vecttmp = document.getElementById(id);
    let svg = vecttmp.getElementsByTagName('svg')[0];
    let path = svg.getElementById(pathid);
    svg.appendChild(path);

  }


  async removeVectorPathMultiSelection(selectedelement) {

    // check why need multiple runs for all selection to go..?
    let allselectedclass = document.getElementsByClassName('data-selected');
    for (let i = 0; i < allselectedclass.length; ++i) {
      allselectedclass[i].classList.remove('data-selected');
    }
    allselectedclass = document.getElementsByClassName('data-selected');
    for (let i = 0; i < allselectedclass.length; ++i) {
      allselectedclass[i].classList.remove('data-selected');
    }
    allselectedclass = document.getElementsByClassName('data-selected');
    for (let i = 0; i < allselectedclass.length; ++i) {
      allselectedclass[i].classList.remove('data-selected');
    }


    if (!selectedelement || !selectedelement.svgcombi) { return }

    if (selectedelement.characteri) { return }
    //    console.log('removeVectorPathMultiSelection', selectedelement.id)
    this.setDragSelect(selectedelement, false);
    //this.selectmultiplepaths = false;
    await new Promise(resolve => setTimeout(resolve, 100));
    let svgdiv = document.getElementById(selectedelement.id);
    //    console.log(svgdiv, selectedelement.id)
    let svg = svgdiv.querySelector('svg');
    await this.deleteVectorGroup(svg);

    //if (!selectedelement.character){
    svg = await this.sortVect(svg);
    // }

    selectedelement.svgcombi = svg.outerHTML;

    if (this.selectedVecPathmultiple) {
      for (let i = 0; i < this.selectedVecPathmultiple.length; i++) {
        let selectionvecpath = document.getElementById(this.selectedVecPathmultiple[i].id);
        if (selectionvecpath) {
          selectionvecpath.classList.remove('data-selected');
        }
      }


      this.selectedVecPathmultiple = [];

      // if (selectedelement) {
      //   if (selectedelement.svgcombi) {
      //     this.saveSVG(selectedelement);
      //   }
      // }
    }
  }

  setVectorColor(target) { // : SVGSVGElement
    if (target.style.fill.indexOf('url') === -1) {
      this.colorpick = target.style.fill;
    }
    if (target.style.stroke.indexOf('url') === -1) {
      this.colorpickline = target.style.stroke;
    }
    if (target.style['stroke-width'].indexOf('url') === -1) {
      this.linewidth = target.style['stroke-width'];
    }
  }


  setDragSelect(selectedelement, dragset?: boolean) {
    //    console.log(selectedelement, dragset)
    this.saveFigurePath(selectedelement);
    if (dragset !== undefined) { this.dragselectvectpath = dragset } else {
      if (this.dragselectvectpath === false) {
        this.dragselectvectpath = true
      } else {
        this.dragselectvectpath = false;
        this.dragselectiontrue = false;
        this.draggableService.cancelDragSelect();
      }
    }

    if (dragset === false && this.dragselectiontrue === true) {
      this.draggableService.cancelDragSelect();
      this.dragselectiontrue = false;
      this.dragselectvectpath = false
    }


  }

  saveSVG(selectedelement) {

    let idnew = document.getElementById(selectedelement.id); // get document
    if (idnew) {
      let vec = idnew.getElementsByTagName('svg');

      if (vec.length > 0) {
        let vectstring = vec[0].outerHTML;
        this.numberPaths(vec[0]);
        //console.log(vectstring)
        selectedelement.svgcombi = vectstring;
      }
    }
  }

  numberPaths(svg): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let paths = svg.getElementsByTagName("path");
      //console.log(paths.length);
      if (!paths[0].hasAttribute('loc')) {
        for (let p = 0; p < paths.length; p++) {
          //let nr = paths[p].id.replace(/[^0-9]/g, '');
          paths[p].setAttribute('loc', p);
        }
      }
      resolve();
    });
  }

  numberPathsNew(svg): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let paths = svg.getElementsByTagName("path");
      for (let p = 0; p < paths.length; p++) {
        paths[p].setAttribute('loc', p);
      }
      resolve();
    });
  }

  editFigurePath(): void {
    if (!this.editfigure) {
      if (this.selectedVecPath) {
        this.editfigure = true;
        this.editsvg = true;
        if (this.draggableService.draggableObject) {
          this.draggableService.draggableObject.disable();
        }
        this.pathEditor = PathEditor.create(this.selectedVecPath, {
          handleSize: 2,
          selected: true,
          draggable: true
        });
      }
    } else {
      this.editfigure = false;
    }
  }

  saveFigurePath(selectedelement): void {
    if (this.editsvg) {
      //  this.editsvg = false;
      this.removePathEditor();
      this.saveSVG(selectedelement);
    }
  }

  addNewFigure(): void {
    //this.cancelDragSelect();
    let docset = document.getElementById('svgElement');
    let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    
    let strPath = 'M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457 ';
    path.setAttribute("d", strPath);
    path.setAttribute("id", 'svgElementPath');
   // path.setAttribute('fill-rule', 'evenodd');
    docset.appendChild(path);
    let pathset = document.getElementById('svgElementPath');
    this.pathEditor = PathEditor.create(pathset);
  }


  removePathEditor() {
    //document.onkeydown = null
    PathEditor.removeListeneresEnydea();
    const patheditor = document.getElementsByClassName('path-editor'); // path-editor
    const patheditorsel = document.getElementsByClassName('path-editor-selection'); // path-editor
    const elements = document.getElementsByClassName('copy-motion-path');
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].parentNode.removeChild(elements[i]);
      }
    }

    if (patheditor.length > 0) {
      for (let i = 0; i < patheditor.length; i++) {
        patheditor[i].parentNode.removeChild(patheditor[i]);
      }
    }

    if (patheditorsel.length > 0) {
      for (let i = 0; i < patheditorsel.length; i++) {
        patheditorsel[i].parentNode.removeChild(patheditorsel[i]);
      }
    }
  }


  selectMultiplePaths(selectedelement) {
    //this.removeVectorPathSelection(selectedelement);

    // this.setDragSelect(false);
    this.saveFigurePath(selectedelement)
    if (this.selectmultiplepaths === false) {
      this.selectmultiplepaths = true;
      this.selectedVecPathmultiple = [];
      this.removeVectorPathMultiSelection(selectedelement);
    } else {
      this.selectmultiplepaths = false;
      this.removeVectorPathMultiSelection(selectedelement);
    }
  }


  async deleteSelectedVectorPath(selectedelement) {
    this.setDragSelect(selectedelement, false);
    for (let i1 = 0; i1 < this.selectedVecPathmultiple.length; i1++) {
      let selectionvecpath = document.getElementById(this.selectedVecPathmultiple[i1].id)
      selectionvecpath.remove();
    }
    let idnew = document.getElementById(selectedelement.id); // get document
    let vectstring = idnew.innerHTML;
    selectedelement.svgcombi = vectstring;
    this.removeVectorPathMultiSelection(selectedelement);

    this.saveSVG(selectedelement);
  }

  dragSelect(id) {
    this.dragselectiontrue = true;
    let svgel = document.getElementById(id);
    let svgset = svgel.getElementsByTagName("svg")[0];

    console.log(svgset)

    const {
      cancel, // cleanup funciton. please call `cancel()` when the select-on-drag behavior is no longer needed.
      dragAreaOverlay,
    } = svgDragSelect({
      svg: svgset,
      referenceElement: null,
      selector: "enclosure",

      onSelectionStart({ svg, pointerEvent, cancel }) {
        console.log(pointerEvent.target['nodeName'])
        // if (pointerEvent.target['nodeName'] === 'path') { // cancel if path so and need drag
        //   cancel();
        //   return
        // }
        if (pointerEvent.button !== 0) {
          cancel()
          return
        }

        const selectedElements = svg.querySelectorAll('[data-selected]');
        for (let i = 0; i < selectedElements.length; i++) {
          selectedElements[i].removeAttribute('data-selected');
          let elclass = selectedElements[i].getAttribute('class');
          elclass = elclass.replace('data-selected', '')
          selectedElements[i].setAttribute('class', elclass);
        }
      },

      onSelectionChange: ({
        newlySelectedElements,    // `selectedElements - previousSelectedElements`
        newlyDeselectedElements,  // `previousSelectedElements - selectedElements`
      }) => {

        newlyDeselectedElements.forEach(element => {
          element.removeAttribute('data-selected')
          let elclass = element.getAttribute('class');
          //console.log(elclass);
          elclass = elclass.replace('data-selected', '')
          element.setAttribute('class', elclass);
          element.parentNode.appendChild(element);
          //console.log(element)
        });
        newlySelectedElements.forEach(element => {
          element.setAttribute('data-selected', '');
          let elclass = element.getAttribute('class');
          if (elclass !== null) {
            element.setAttribute('class', 'data-selected ' + elclass);
          } else {
            element.setAttribute('class', 'data-selected')
          }
        });
      },

      onSelectionEnd: async (event) => {

        if (this.selectedVecPathmultiple) {

          for (let z = 0; z < this.selectedVecPathmultiple.length; z++) {
            let pathold = document.getElementById(this.selectedVecPathmultiple[z].id);
            svgset.appendChild(pathold);
          }

        }
        this.selectedVecPathmultiple = [];

        for (let y = 0; y < event.selectedElements.length; y++) {
          let el = event.selectedElements[y];
          this.selectedVecPathmultiple.push(el);
          let path = SVG(el) as any;
          let svg1 = SVG(el.parentElement) as any; // SVG.js object!! not svghtml object
          let group = document.getElementById('selectiongroupXL');
          let g;
          if (!group) { // create new group for drag select
            g = svg1.group().id('selectiongroupXL');
            svg1.add(g)
            g.add(path);
            g.draggable()
            g.dragstart = () => {
              this.draginprogress = true
            }
            g.dragsend = () => {
              this.draginprogress = false
            }
          } else { // set existing
            g = SVG(group) as any;
            g.add(path);
          }
        };
      }
    });

    // this.draggableService.cancelDragSelect = cancel;
    this.dragAreaOverlay = dragAreaOverlay;
  }



  // !! dependency in videocreator different then createnewsvg()
  async seperatePaths(selectedelement, animationarray): Promise<any> {
    let idsdel = [];
    let height = 500, width = 500, x = 0, y = 0;
    let originalsize = await this.getViewBox(selectedelement.id);
    //console.log(originalsize);
    if (originalsize) {
      x = originalsize.x;
      y = originalsize.y;
      width = originalsize.width; // * newscale1;
      height = originalsize.height; // * newscale1;
    }

    // set headers
    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute('viewBox', x + ' ' + y + ' ' + width + ' ' + height);
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');

    // remove defs.
    let currentdoc = document.getElementById(selectedelement.id);
    let defs = currentdoc.getElementsByTagName('defs');
    if (defs.length > 0) {
      for (let index = 0; index < defs.length; index++) {
        svg.appendChild(defs[index].cloneNode(true));
      }
    }
    let newheight = 0;
    let newwidth = 0;
    let newx;
    let newy;
    //console.log(this.selectedVecPathmultiple)
    for (let i = 0; i < this.selectedVecPathmultiple.length; i++) {
      currentdoc.classList.remove('data-selected');
      let svgel = document.getElementById(this.selectedVecPathmultiple[i].id);

      //console.log(svgel)

      let theB = await svgel.getBoundingClientRect();

      //calculate screen position
      // console.log(theB, svgel)
      if (theB.width > newwidth) { newwidth = theB.width }
      if (theB.height > newheight) { newheight = theB.height }
      if (theB.left < newx || newx == undefined) { newx = theB.left }
      if (theB.top < newy || newy == undefined) { newy = theB.top }
      let clone = await svgel.cloneNode(true);
      await svg.appendChild(clone);
      idsdel.push(svgel.id);
    }

    // calculate position relative the parent div
    let boundelement = document.getElementById('myBounds');
    let boundposition = boundelement.getBoundingClientRect();
    let posx = newx - boundposition.left;
    let posy = newy - boundposition.top;

    let s = new XMLSerializer(); // convert to string
    let svgstring = s.serializeToString(svg);

    for (let y = 0; y < idsdel.length; y++) {
      let oldid = idsdel[y];
      let idx = animationarray.length + 1;
      let ind = y + 1;
      let newid = idx + 'elvect-' + ind;
      svgstring = svgstring.replace(oldid, newid);
    }

    this.seperateDeletePaths(idsdel, selectedelement);
    let pathpar = { newheight, newwidth, svgstring, posx, posy };
    return pathpar;
  }


  // delete paths 
  async seperateDeletePaths(idsdel, selectedelement) {
    //console.log('delete paths', idsdel)
    for (let i = 0; i < this.selectedVecPathmultiple.length; i++) {
      let pid = idsdel[i];
      let svgel = document.getElementById(pid);
      //console.log(svgel)
      svgel.parentNode.removeChild(svgel);
    }
    this.removeVectorPathMultiSelection(selectedelement)
    //this.removeVectorPathSelection(selectedelement);
  }

  // DELETE replace with seperate paths
  saveAsSeperateVector(selectedelement, animationarray): any {
    //this.setDragSelect(false)
    let svgstring;
    let pathidar = [];

    if (this.selectmultiplepaths || this.dragselectvectpath) {
      // this.removeVectorPathMultiSelection();
      // console.log('seperate multipaths', this.selectedVecPathmultiple)
      let svgarray = [];
      let i = 0;
      let arraylength = this.selectedVecPathmultiple.length - 1;

      //this.selectedelement.id
      let svggetdefs = document.getElementById(selectedelement.id)
      let defs = svggetdefs.getElementsByTagName('defs');

      for (let y = 0; y < defs.length; y++) {
        let defstring = defs[y];
        svgarray.push(defstring.outerHTML)
      }
      this.selectedVecPathmultiple.forEach(element => {
        //console.log(element);
        let svg = element as unknown;
        let svg2 = svg as SVGAElement;
        let rect = svg2.getBBox();
        let height = rect.height + 'px';
        let width = rect.width + 'px';

        let idx = animationarray.length + 1;
        let ind = i + 1;
        let newid = idx + 'elvect-' + ind;
        let oldid = element.getAttribute('id');
        let svgel = element;
        //this.deletePathSelClass(svgel);

        let s = new XMLSerializer(); // convert to string
        let stringend = s.serializeToString(svgel);
        //let cleanstring = stringend.replace('outline: 1px dotted green;', '');

        let finalstring = stringend.replace(oldid, newid);
        svgarray.push(finalstring);
        pathidar.push(newid);
        if (i === arraylength) {
          svgstring = svgarray.join('');
          this.createnewsvg(svgstring, pathidar, rect, height, width, selectedelement);
          this.removeVectorPathMultiSelection(selectedelement);
        }
        ++i
      });

    } else {
      let svgel = this.selectedVecPath;

      let svg = svgel as unknown;
      let svg2 = svg as SVGAElement;
      let rect = svg2.getBBox();
      let height = rect.height + 'px';
      let width = rect.width + 'px';

      //this.deletePathSelClass(svgel);
      let oldid = svgel.getAttribute('id');
      let s = new XMLSerializer(); // convert to string
      svgstring = s.serializeToString(svgel);
      let idx = animationarray.length + 1;
      let ind = 0 + 1;
      let newid = idx + 'elvect-' + ind;
      let finalstring = svgstring.replace(oldid, newid);
      // let cleanstring = finalstring.replace('outline: 1px dotted green;', '');
      finalstring.replace(oldid, newid);
      pathidar.push(newid);
      this.createnewsvg(svgstring, pathidar, rect, height, width, selectedelement);
      //this.removeVectorPathSelection(selectedelement);
    }
  }

  setColorPath(color) {
    // colorpick
    if (this.selectmultiplepaths || this.dragselectvectpath) {
      this.selectedVecPathmultiple.forEach(element => {
        element.style.fill = color;
      })
    } else {
      this.selectedVecPath.setAttribute('fill', color);
      this.selectedVecPath.style.fill = color;
    }
  }

  setColorPathLine(color) {
    // colorpickline
    if (this.selectmultiplepaths || this.dragselectvectpath) {
      this.selectedVecPathmultiple.forEach(element => {
        element.style.stroke = color;
      })
    } else {
      this.selectedVecPath.setAttribute('stroke', color);
      this.selectedVecPath.style.stroke = color;
    }
  }

  setlinewidth(linewidth) {
    //console.log(linewidth, 'line w')
    if (this.selectmultiplepaths || this.dragselectvectpath) {
      this.selectedVecPathmultiple.forEach(element => {
        element.style['stroke-width'] = linewidth;
      })
    } else {
      this.selectedVecPath.setAttribute('stroke-width', linewidth);
      this.selectedVecPath.style['stroke-width'] = linewidth;
    }
  }

  async createnewsvg(svgstring, pathidar, bbox, height, width, selectedelement) {
    //console.log('start new svg')
    let h = 500, w = 500, x = 0, y = 0;
    let element = document.getElementById(selectedelement.id);
    let originalsize = await this.getViewBox(selectedelement.id);
    //console.log(originalsize);
    if (originalsize) {
      x = originalsize.x;
      y = originalsize.y;
      h = originalsize.width; // * newscale1;
      w = originalsize.height; // * newscale1;
    }

    let newsvgarray = [
      '<svg xmlns="http://www.w3.org/2000/svg" ' +
      'viewBox="' + x + ' ' + y + ' ' + h + ' ' + w + '" height="100%" width="100%"' +
      'id="svg2" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">',
      svgstring, '</svg>'
    ]
    let newsvg = newsvgarray.join('');
    //this.videocreatorComponent.addNewVector(null, height, width, newsvg, bbox.x, bbox.y, pathidar, originalsize); //, originid
    this.removeVectorPathMultiSelection(selectedelement)
    //this.removeVectorPathSelection(selectedelement);

    // let pathpar = {height, width, newsvg, paobbox.x, bbox.y, pathidar, originalsize};
    // return pathpar;
  }

  createNewID(): string {
    let newelnr;
    let r = Math.random().toString(36).substring(7); // add random sring
    newelnr = r + '-el';
    return newelnr;
  }


  addNewVectorCombi(animationarray, canvas) {
    this.newz = this.newz + 1;
    let newelnr = this.createNewID();
    let newvectorcombi: vectorcombinator = {
      type: 'vectorcombi',
      groupname: '',
      groupmember: false,
      vectors: [],
      animation: [],
      style: {
        'z-index': this.newz,
        width: canvas.width,
        height: canvas.height,
        position: 'absolute',
        opacity: 1
      },
      posx: 0,
      posy: 0,
      setpos: {},
      id: newelnr,
      transform: '',
      rotation: 0,
      motionrotation: 0,
      animate: false,
      motionpath: []//this.createMotionPath(newelnr, canvas)
    }
    animationarray.push(newvectorcombi);
  }

  async dropVectorGroup(value, element, i, animationarray) {
    let newel = value.value;
    //if (newel.type === 'vector') {
    let found = false;
    newel.groupmember = true;
    for (let i = 0; i > element.vectors.length; i++) {
      if (JSON.stringify(element.vectors[i]) === JSON.stringify(newel)) {
        found = true;
      }
    }

    if (found === false) {
      element.vectors.push(newel);
      this.onSetCombiBox(i, element, newel, animationarray);
    }
    //}
  }

  addVectorAnimation(element: vectoranimation) {
    let vectanim = {
      svganimationtype: '',
      drawcolor: 'blue',
      linethickness: '5px',
      repeat: 0,
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      start_time: 0, //delayt
      end_time: 10,
      delay: 0,
      duration: 3,
      hideimage: false,
      easetype: 'linear',
      fromto: 'to',
      colorpath: '',
      selectedpaths: [],
      svganimationpaths: undefined,
      keepshape: true
    }
    element.vectoranimation.push(vectanim);
  }

  deleteVectorAnimation(iv, vectoranimation, selectedelement) {
    // console.log(iv, vectoranimation, selectedelement);
    vectoranimation.splice(iv, 1);
    selectedelement.morph = false;
    selectedelement.vectors.splice(1, 1);
    //this.resetVectors(selectedelement);
  }

  // test ref videocreatorcomponent
  async resetVectors(selectedelement) {
    selectedelement.svgcombi = '';
    let vectorid = selectedelement.vectors[0].idx;
    let svgdiv = document.getElementById(vectorid);
    let svg = svgdiv.getElementsByTagName('svg')[0];
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');
    selectedelement.svgcombi = svg.outerHTML;
  }

  async drawVector(vectorelement, animation: vectoranimationtype, primairytimeline: GSAPTimeline): Promise<void> {
    //console.log(vector, animation)
    if (vectorelement.vectors.length > 0) {
      let list = vectorelement.vectors[0].pathids;
      let vecttmp = document.getElementById(vectorelement.id);
      let vect = vecttmp.getElementsByTagName('svg')[0];
      for (let i = 0; i < list.length; i++) {
        let frompath = vect.getElementById(list[i]);
        await this.setDrawAni(frompath, animation, primairytimeline);
      }
      return //resolve();
    } else {
      return //reject();
    }
  }

  getViewBox(vectid: string): viewboxObject {
    let getview = document.getElementById(vectid);
    //console.log(getview)
    if (getview) {
      let svgview = getview.getElementsByTagName('svg');
      let originalsizestring = svgview[0].getAttribute("viewBox");
      let origarray = originalsizestring.split(' ');
      let originalsize: viewboxObject = { x: parseInt(origarray[0]), y: parseInt(origarray[1]), width: parseInt(origarray[2]), height: parseInt(origarray[3]) }
      return originalsize;
    } else {
      console.log('error no viewbox', vectid)
      let originalsize: viewboxObject = { x: 0, y: 0, width: 500, height: 500 }
      return originalsize;
    }

  }


  // createMotionPath(id, canvas) {
  //   let newid = id + '-' + 0 + 'mp';
  //   let motionpath = '<svg id="' + newid + 'mp" style="width:' + canvas.width + ' height=' + canvas.height + ';" viewBox="0 0 ' + parseInt(canvas.width) + ' ' +
  //     parseInt(canvas.height) + '" class="path-edit"><path id="' + newid + 'p" style="opacity: 0;"' +
  //     ' d="M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457   " /></svg>';
  //   return [motionpath]
  // }

  addMotionPath(id, canvas, motionpath: Array<string>) {
    let newid = id + '-' + motionpath.length;
    let motion = '<svg id="' + newid + 'mp" style="width:' + canvas.width + ' height=' + canvas.height + ';" viewBox="0 0 ' +
      parseInt(canvas.width) + ' ' + parseInt(canvas.height) +
      '" class="path-edit"><path id="' + newid + 'p" style="opacity: 0;"' +
      ' d="M282.457,480.74 C282.457,480.74 280.457,217.529 279.888,139.457   " /></svg>';
    motionpath.push(motion);
    return motionpath;
  }


  async setDrawAni(frompath, animation, primairytimeline: GSAPTimeline): Promise<void> { // : vectoranimationtype remove coudd also be counteranimation
    //console.log(frompath, animation, primairytimeline)
    let animationdrawto = animation.fillleft + ' ' + animation.fillright;
    let animationdrawfrom = animation.drawleft + ' ' + animation.drawright;
    let hideelement = 0;
    //let ease = animation.easetype;
    let ease = this.easetypesService.selectEaseType(animation.easetype);


    if (animation.hideimage === true) {
      hideelement = 0;
    } else { hideelement = 1 }

    let fromset: GSAPTimelineVars =
    {
      drawSVG: animationdrawfrom,
      duration: animation.duration,
      repeat: animation.repeat,
      stroke: animation.drawcolor,
      strokeWidth: animation.linethickness,
      'fill-opacity': hideelement,
      ease: ease,
      yoyo: animation.yoyo,
      attr: undefined
    };

    let toset: GSAPTimelineVars =
    {
      drawSVG: animationdrawto,
      duration: animation.duration,
      repeat: animation.repeat,
      stroke: animation.drawcolor,
      strokeWidth: animation.linethickness,
      'fill-opacity': hideelement,
      ease: ease,
      yoyo: animation.yoyo,
      attr: undefined
    };

    // from-radius to-radius
    if (animation.tor && animation.fromr) {
      toset.attr = { r: animation.tor };
      fromset.attr = { r: animation.fromr }
    }

    if (animation.fromto === 'from') {
      primairytimeline.fromTo(frompath, fromset, toset, animation.start_time);
    } else {
      primairytimeline.fromTo(frompath, toset, fromset, animation.start_time);
    }
    return
  }


  async toSVGInlineStyle(element: vectoranimation) {
    let svgel = document.getElementById(element.vectors[0].idx) as any;
    let e = svgel.getElementsByTagName('svg')[0]
    let className = e.getElementsByTagName("style");
    //console.log(className)
    let svgstring = e.outerHTML;
    //console.log(className[0].sheet.cssRules);
    if (className.length > 0) {
      for (let i = 0; i < className[0].sheet.cssRules.length; i++) {
        //console.log(className[0].sheet.cssRules)
        let element = className[0].sheet.cssRules[i];
        let clname: string = element.selectorText; //CSSStyleRule
        let cssstyle: string = element.style.cssText;
        //console.log(clname)
        const clnamepure = clname.substring(1);
        let pathsel = svgel.getElementsByClassName(clnamepure); // get paths with class
        //console.log(pathsel )

        for (let pi = 0; pi < pathsel.length; pi++) {
          pathsel[pi].setAttribute('style', cssstyle);
        }
        //let newclname: string = clname + '-; enydea-' + vectorid;
        // element.selectorText = newclname;
        // let searchnewclname = newclname.substring(1);
        // let clsearchname = clname.substring(1);
        // let re = new RegExp(clsearchname, 'g');
        // svgstring = svgstring.replace(re, searchnewclname);
      }
      //e.outerHTML = svgstring;
    }
    element.svgcombi = e.outerHTML;

  }





  async combiBoxCalculator(vectorcombi: vectorcombinator): Promise<any> {
    //console.log('is combiboxcalculator')
    let vectors = vectorcombi.vectors;
    let boundposition = document.getElementById('myBounds').getBoundingClientRect();

    // get the actual position on the screen
    let x = document.getElementById(vectors[0].id).getBoundingClientRect().left - boundposition.left;
    let y = document.getElementById(vectors[0].id).getBoundingClientRect().top - boundposition.top;

    // calculate new width/height
    let widthcalc = document.getElementById(vectors[0].id).getBoundingClientRect().right;
    let heightcalc = document.getElementById(vectors[0].id).getBoundingClientRect().bottom;
    let resetvaluex = 0;
    let resetvaluey = 0;

    for (let k = 0; k < vectors.length; k++) {
      //console.log(vectors[k])
      let testx = document.getElementById(vectors[k].id).getBoundingClientRect().left - boundposition.left;
      let testy = document.getElementById(vectors[k].id).getBoundingClientRect().top - boundposition.top;
      let testright = document.getElementById(vectors[k].id).getBoundingClientRect().right;
      let testbottom = document.getElementById(vectors[k].id).getBoundingClientRect().bottom;
      //console.log('pos combibox', testright, widthcalc);
      if (testx < x) { x = testx; resetvaluex = k }
      if (testy < y) { y = testy; resetvaluey = k }
      if (testright > widthcalc) { widthcalc = testright }
      if (testbottom > heightcalc) { heightcalc = testbottom }
    }

    let widthcalcfin = (widthcalc - boundposition.left) - x;
    let heightcalcfin = (heightcalc - boundposition.top) - y;
    vectorcombi.style.width = widthcalcfin + 'px';
    vectorcombi.style.height = heightcalcfin + 'px';
    vectorcombi.posx = x;
    vectorcombi.posy = y;

    // new position combi
    let diffposx = vectors[resetvaluex].posx;
    let diffposy = vectors[resetvaluey].posy;
    //console.log('diff position', diffposx, diffposy);

    // compensate for new combi position.
    for (let k = 0; k < vectors.length; k++) {
      vectors[k].posx = vectors[k].posx - diffposx;
      vectors[k].posy = vectors[k].posy - diffposy;
    }
    return
  }



  getPath(vectorid) {
    let svgdiv = document.getElementById(vectorid);
    //let svg = svgdiv.getElementsByTagName('svg')[0];
    let paths = svgdiv.getElementsByTagName('path');
    //console.log(paths)
    let newpaths = [];
    for (let y = 0; y < paths.length; y++) {
      let ohtml = paths[y].outerHTML;
      newpaths.push(ohtml)
    };
    //console.log(clippaths);
    let pathjoin = newpaths.join('');
    return pathjoin;
  }

  async GrabPaths(vectstring, pathidar): Promise<string> {
    return new Promise((resolve, reject) => {
      let jsonidar = JSON.stringify(pathidar)
      let data = {
        vectstring: vectstring,
        jsonidar: jsonidar
      }
      this.filesApi.grabPaths(data).subscribe(res => {
        resolve(res);
      });
    });
  }


  getViewbox(animationelement) {
    let vector2 = animationelement.vectors[0] as vectorelement;
    let getview2 = document.getElementById(vector2.idx)
    let svgview2 = getview2.getElementsByTagName('svg');
    let originalsizestring2 = svgview2[0].getAttribute("viewBox");
    let origarray2 = originalsizestring2.split(' ');
    let originalsize = { x: origarray2[0], y: origarray2[1], width: origarray2[2], height: origarray2[3] }
    return originalsize;
  }

  async createNewPath(vectorelement, animation): Promise<string> {
    let path = `<path id="liquidfillpath${vectorelement.id}" fill="${animation.drawcolor}" d="M0.40625,105C122.15625,105,144.574,143.374,171.324,143.374,198.074,143.374,229.15625,105,255.90625,105,
    282.65625,105,307.849,143.645,334.599,143.645,361.349,143.645,389.65625,105,416.40625,105,443.15625,105,471.048,144.033,
    497.798,144.033,524.545,144.033,550.15625,105,576.90625,105,606.33125,105,638.141,147.573,667.569,147.573,691.641,147.573,713.33125,105,737.40625,
    105,737.40625,218,737.40625,331,737.40625,444,523.40625,444,309.40625,444,0.40625,444,0.40625,331,0.40625,218,0.40625,105"/>`
    let newFillPath = `
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" viewBox="0 0 260 260" height="100%" width="100%"> 
            <g mask="url(#liquidfillmask${vectorelement.id})">
              ${path}
            </g> 
            <defs><mask id="liquidfillmask${vectorelement.id}">`
    let originalsize = { x: 0, y: 0, width: 260, height: 260 };
    let newsize: viewboxObject = this.getViewBox(vectorelement.vectors[0].idx);

    var parser = new DOMParser();
    var doc = parser.parseFromString(newFillPath, "image/svg+xml");
    let pathdoc = doc.getElementById(`liquidfillpath${vectorelement.id}`)

    let newpath = await this.resizePath(newsize, originalsize, pathdoc);
    newFillPath = `
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" 
    viewBox="${newsize.x} ${newsize.y} ${newsize.width} ${newsize.height}" height="100%" width="100%"> 
            <g mask="url(#liquidfillmask${vectorelement.id})">
            <path id="liquidfillpath${vectorelement.id}" fill="${animation.drawcolor}" d=" ${newpath}"/>
            </g> 
            <defs><mask id="liquidfillmask${vectorelement.id}">`;
    return newFillPath

  }

  // create vector and set as combinesvg
  async createWaveVector(vectorelement, animation: vectoranimationtype, primairytimeline: GSAPTimeline): Promise<void> {
    if (vectorelement.vectors.length === 0) { return }

    let list = vectorelement.vectors[0].pathids;// get current d svg let vectorid = vectorelement.vectors[0].idx; //idx is clean svg 
    // create create fill path liquid
    // ${animation.drawcolor}
    let newFillPath = await this.createNewPath(vectorelement, animation);

    for (let i = 0; i < list.length; i++) {
      let frompath = document.getElementById(list[i]); // liquidfillpath0el
      //await new Promise(resolve => setTimeout(resolve, 100));
      if (frompath) {
        frompath.removeAttribute('class');
        frompath.setAttribute('fill', '#FFFFFF')
        let pathstring = frompath.outerHTML;            // get path string
        newFillPath = newFillPath + pathstring         // add path to mask g 
      } else {
        console.log('no path', list[i])
      }
    }

    newFillPath + newFillPath + '</g></defs>';
    vectorelement.svgcombi = newFillPath;

    //await new Promise(resolve => setTimeout(resolve, 20));
    const Liquid = document.getElementById('liquidfillpath' + vectorelement.id);

    //var Liquid = document.querySelector('.liquidfillpath' + vectorelement.id);
    let ease = this.easetypesService.selectEaseType(animation.easetype);
    let from: GSAPTimelineVars = {
      y: animation.fillleft, // 100
      x: animation.fillright, // 0
      duration: animation.duration,
      repeat: animation.repeat,
      ease: ease,
      yoyo: animation.yoyo,
    }

    let to: GSAPTimelineVars = {
      delay: animation.start_time,
      y: animation.drawleft, // -100
      x: animation.drawright, // -200
      duration: animation.duration,
      repeat: animation.repeat,
      ease: ease,
      yoyo: animation.yoyo,
    }

    // primairytimeline.set(Liquid, from, 0)
    // console.log(Liquid, to, from)
    // move horizontal liquid path
    // move vertical liquid path
    // get path hight 

    if (animation.fromto === 'from') {
      primairytimeline.fromTo(Liquid, to, from, animation.start_time)
    } else {
      primairytimeline.fromTo(Liquid, from, to, animation.start_time)
    }

    return
  }



  getPathLocations(vectorelement) {
    // 'liquidfillpath' + vectorelement.id

  }


  async resizePath(originalsize, newsize, path): Promise<viewboxObject> {
    // console.log(originalsize, newsize, path)
    if (!path) { console.error('Missing Path') }
    let scale;
    let newtranssize;
    if (newsize.height > newsize.width) {
      newtranssize = originalsize.height / newsize.height;
    } else {
      newtranssize = originalsize.width / newsize.width;
    }
    let x = parseInt(originalsize.x, 10);
    let y = parseInt(originalsize.y, 10);
    let x2 = parseInt(newsize.x, 10) * -1;
    let y2 = parseInt(newsize.y, 10) * -1;
    let newx = x; // - x2;
    let newy = y; // - y2;

    if (originalsize.x === newsize.x) {
      newx = 0;
    }
    if (originalsize.y === newsize.y) {
      newy = 0;
    }

    scale = Number((newtranssize).toFixed(8));
    //console.log(newx, newy, scale, p)
    let rawpath;

    // path.setAttribute("id", "child-wave"); // keep in case there is no ID set
    let rawpath1 = await MotionPathPlugin.getRawPath(path);
    if (newx === 0 && newy === 0) {
      rawpath = rawpath1;
    } else {
      rawpath = await MotionPathPlugin.transformRawPath(rawpath1, 1, 0, 0, 1, x2, y2); // remove viewport x and y of newpath otherwise it will scale on the wrong viewport
    }
    if (rawpath == undefined) {
      return;
    }
    //console.log(rawpath);
    let svgsizearray = [scale, 0, 0, scale, newx, newy];
    // console.log(svgsizearray)
    let testpath3 = await MotionPathPlugin.transformRawPath(rawpath, svgsizearray[0], svgsizearray[1], svgsizearray[2], svgsizearray[3], svgsizearray[4], svgsizearray[5]);
    let stringpath = await MotionPathPlugin.rawPathToString(testpath3);
    //path.setAttribute('d', stringpath);
    // console.log(stringpath)
    return stringpath;

  }


  async resizeVector(originalsize, newsize, idx, svg): Promise<string> {
    //let e = svg;

    let scale;
    let newtranssize;
    if (newsize.height > newsize.width) {
      newtranssize = originalsize.height / newsize.height;
    } else {
      newtranssize = originalsize.width / newsize.width;
    }
    let x = parseInt(originalsize.x, 10);
    let y = parseInt(originalsize.y, 10);
    let x2 = parseInt(newsize.x, 10) * -1;
    let y2 = parseInt(newsize.y, 10) * -1;
    let newx = x; // - x2;
    let newy = y; // - y2;

    if (originalsize.x === newsize.x) {
      newx = 0;
    }
    if (originalsize.y === newsize.y) {
      newy = 0;
    }

    scale = Number((newtranssize).toFixed(8));
    let p = svg.getElementsByTagName("path");
    //console.log(newx, newy, scale, p)
    let rawpath;
    for (let index = 0; index < p.length; index++) {
      if (!p[index]) { console.error('Missing Path') }
      let rawpath1 = await MotionPathPlugin.getRawPath(p[index]);
      if (newx === 0 && newy === 0) {
        rawpath = rawpath1;
      } else {
        rawpath = await MotionPathPlugin.transformRawPath(rawpath1, 1, 0, 0, 1, x2, y2); // remove viewport x and y of newpath otherwise it will scale on the wrong viewport
      }
      if (rawpath == undefined) {
        return;
      }
      //console.log(rawpath);
      let svgsizearray = [scale, 0, 0, scale, newx, newy];
      let newmatrix;
      let transf = p[index].getAttribute('transform'); // there is transform on the element we need remove it
      //console.log(svgsizearray)
      if (transf !== null) {
        let style = transf;

        // just skip but needs fixing to matrix!!! 
        if (style.indexOf('rotate') > 0) {
          return
        }

        style = style.replace('matrix(', '');
        style = style.replace('matrix(', '');
        style = style.replace('translate(', '');
        style = style.replace(')', '');
        style = style.replace(/,/g, ' ');
        // console.log(style)
        newmatrix = style.split(' ').map(Number);
        //        console.log(newmatrix);
        let testpath2: any;
        if (newmatrix.length === 2) {
          console.log(' translate')
          testpath2 = await MotionPathPlugin.transformRawPath(rawpath, 1, 0, 0, 1, newmatrix[0], newmatrix[1]);
        } else {
          testpath2 = await MotionPathPlugin.transformRawPath(rawpath, newmatrix[0], newmatrix[1], newmatrix[2], newmatrix[3], newmatrix[4], newmatrix[5]);
        }

        let testpath3 = await MotionPathPlugin.transformRawPath(testpath2, svgsizearray[0], svgsizearray[1], svgsizearray[2], svgsizearray[3], svgsizearray[4], svgsizearray[5]);
        let stringpath = await MotionPathPlugin.rawPathToString(testpath3);
        p[index].setAttribute('d', stringpath);
        p[index].removeAttribute("transform");
      } else {
        let testpath3 = await MotionPathPlugin.transformRawPath(rawpath, svgsizearray[0], svgsizearray[1], svgsizearray[2], svgsizearray[3], svgsizearray[4], svgsizearray[5]);
        let stringpath = await MotionPathPlugin.rawPathToString(testpath3);
        p[index].setAttribute('d', stringpath);
        p[index].removeAttribute("transform");
      }
    }


    return svg;

  }


  createColorPathAnimation(element: vectoranimation) {
    let vectanim = {
      svganimationtype: 'color',
      drawcolor: 'blue',
      linethickness: '5px',
      repeat: 0,
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      start_time: 0, //delayt
      end_time: 10,
      delay: 0,
      duration: 3,
      hideimage: false,
      easetype: 'linear',
      fromto: 'to',
      colorpath: 'blue',
      selectedpaths: [],
      svganimationpaths: undefined,
      keepshape: true
    }

    for (let i1 = 0; i1 < this.selectedVecPathmultiple.length; i1++) {
      vectanim.selectedpaths.push(this.selectedVecPathmultiple[i1].id)
    }
    element.vectoranimation.push(vectanim);
    this.scrollToBottom();
  }

  async createColorVector(vectorelement, animation: vectoranimationtype, primairytimeline: GSAPTimeline): Promise<void> {

    let ease = this.easetypesService.selectEaseType(animation.easetype);
    let vecttmp = document.getElementById(vectorelement.id);
    let vect = vecttmp.getElementsByTagName('svg')[0];

    for (let i1 = 0; i1 < animation.selectedpaths.length; i1++) {
      let frompath = vect.getElementById(animation.selectedpaths[i1]); //animation.selectedpaths

      let toset: GSAPTimelineVars =
      {
        duration: animation.duration,
        repeat: animation.repeat,
        repeatDelay: animation.delay,
        // stroke: animation.drawcolor,
        // strokeWidth: animation.linethickness,
        fill: animation.colorpath,
        ease: ease,
        yoyo: animation.yoyo,
        attr: undefined
      };

      if (animation.fromto === 'from') {
        primairytimeline.from(frompath, toset, animation.start_time);
      } else {
        primairytimeline.to(frompath, toset, animation.start_time);
      }
    }
    return
  }


  scrollToBottom(): void {
    let scrollContainer = document.getElementById('idmaincontainer') as HTMLElement;
    try {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    } catch (err) {
      console.log(err)
    }
  }

  createPathAnimation(element: vectoranimation) {
    if (this.selectedVecPathmultiple === undefined || this.selectedVecPathmultiple.length === 0) {
      console.log('please select path')
      return
    }

    let vectanim: vectoranimationtype = {
      svganimationtype: 'changepath',
      svganimationpaths: [[], []], // array 0 original 1 is morph 3 second morph etc.. 
      drawcolor: '',
      linethickness: '5px',
      repeat: 0,
      yoyo: false,
      fillright: '100%',
      fillleft: '0%',
      drawright: '0%',
      drawleft: '0%',
      start_time: 0, //delayt
      end_time: 10,
      delay: 0,
      duration: 3,
      hideimage: false,
      easetype: 'linear',
      fromto: 'to',
      colorpath: '',
      selectedpaths: [],
      keepshape: true
    }

    for (let i1 = 0; i1 < this.selectedVecPathmultiple.length; i1++) {
      this.copyPaths(element, this.selectedVecPathmultiple[i1].id)
      vectanim.svganimationpaths[0].push(this.selectedVecPathmultiple[i1].id);
      vectanim.svganimationpaths[1].push(this.selectedVecPathmultiple[i1].id + '-copy1');
    }
    element.vectoranimation.push(vectanim);
    this.scrollToBottom();
    this.saveSVG(element);
    this.removeVectorPathMultiSelection(element); // 
    this.selectPathsFromAnimation(vectanim, element);
  }


  copyPaths(element, pathid) {
    //if (pathid || element) { console.error('missing:', element, pathid); return }
    let idnew = document.getElementById(element.id); // get document
    if (idnew) {
      let vec = idnew.getElementsByTagName('svg')[0];
      //console.log(vec)
      let path = vec.getElementById(pathid);

      let newpath = path.cloneNode(true) as any;
      newpath.setAttribute('id', pathid + '-copy1');
      newpath.classList.add('copypath');
      // newpath.setAttribute('stroke', 'blue'); // copypath class instead 
      // newpath.setAttribute('opacity', '0.5');
      vec.appendChild(newpath);

      //this.selectOnePath(newpath)
    }
  }


  createChangePathVector(vectorelement, animation: vectoranimationtype, primairytimeline: GSAPTimeline): Promise<void> {
    //console.log(vectorelement, animation, primairytimeline)
    this.createChangePathVectorShow(vectorelement, animation, primairytimeline); // show all paths first
    let ease = this.easetypesService.selectEaseType(animation.easetype);
    let idnew = document.getElementById(vectorelement.id); // get document
    let vec = idnew.getElementsByTagName('svg')[0];
    vec.style.overflow = 'hidden';
    this.prepareVector(vectorelement, vec); //change transforms to actual vector coordinates

    let vids = animation.svganimationpaths;
    for (let i = 1; i < vids.length; i++) {

      for (let y = 0; y < vids[i].length; y++) {
        let fromel = vec.getElementById(vids[0][y]); // from first path 
        let toel = vec.getElementById(vids[i][y]);
        if (!toel) { return }
        // toel['style'].opacity = 1;
        // fromel['style'].opacity = 1;

        let vars: GSAPTimelineVars = {
          duration: animation.duration,
          morphSVG: {
            shape: toel,
            shapeIndex: undefined, // point 1 to point 1
            type: "rotational",
            //forigin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
          },
          ease: ease,
          repeat: animation.repeat,
          yoyo: animation.yoyo,
          repeatDelay: animation.delay
        }

        if (animation.keepshape) {
          vars.morphSVG.shapeIndex = 0;
        }

        if (animation.fromto === 'from') {
          toel['style'].opacity = 0;
          vars.morphSVG.shape = toel;
          // primairytimeline.set(toel, { duration: 0, opacity: 0 }, 0);
          primairytimeline.to(fromel, vars, animation.start_time);
        } else {
          fromel['style'].opacity = 0;
          vars.morphSVG.shape = fromel;
          //primairytimeline.set(fromel, { duration: 0, opacity: 0 }, 0);
          primairytimeline.to(toel, vars, animation.start_time);
        }

      }
    }
    // this.saveSVG(vectorelement);
    return
  }

  createChangePathVectorShow(vectorelement, animation: vectoranimationtype, primairytimeline: GSAPTimeline): Promise<void> {
    let idnew = document.getElementById(vectorelement.id); // get document
    let vec = idnew.getElementsByTagName('svg')[0];
    vec.style.overflow = 'visible';
    let vids = animation.svganimationpaths;
    for (let i = 1; i < vids.length; i++) {
      for (let y = 0; y < vids[i].length; y++) {
        let fromel = vec.getElementById(vids[0][y]); // from first path 
        let toel = vec.getElementById(vids[i][y]);
        if (!toel) { return }
        //    if (animation.fromto === 'from') {
        toel['style'].opacity = 1;
        //primairytimeline.set(toel, { duration: 0, opacity: 0 }, 0);
        //     } else {
        fromel['style'].opacity = 1;
        // primairytimeline.set(fromel, { duration: 0, opacity: 0 }, 0);
        // }
      }
    }
    //   this.saveSVG(vectorelement);
    return
  }



  async prepareVector(element, svg): Promise<any> {

    // get viewbox info to resize get new vector
    let originalsizestring = svg.getAttribute("viewBox");
    let origarray = originalsizestring.split(' ');
    let newsize = { x: origarray[0], y: origarray[1], width: origarray[2], height: origarray[3] }


    //console.log("vector groups deleted");
    await this.resizeVector(newsize, newsize, element.vectors[0].idx, svg);
    return svg;
  }

  AddAnimationPath() {

  }

  async deleteVectorGroup(svg): Promise<void> {
    // get all group tags and sort by id
    await this.removeHeadG(svg);
    // remove defs.
    let defs = svg.getElementsByTagName('defs');
    let r_defs = [];
    if (defs.length > 0) {
      for (let index = 0; index < defs.length; index++) {
        await r_defs.push(defs[index].cloneNode(true));
        await defs[index].parentNode.removeChild(defs[index]);
      }
    }
    let ga = svg.getElementsByTagName("g");
    let g1 = [];


    // set id and clone
    for (let pg = 0; pg < ga.length; pg++) {
      let clone = await ga[pg].cloneNode(true)
      await g1.push(clone);
    }

    //ungroup
    for (let index = 0; index < g1.length; index++) {  // ---> g.length
      let groupie = await svg.getElementById(g1[index].id);
      let groupElement = await SVG(groupie) as any;
      if (groupElement) {
        let childeren = groupie.childNodes;
        if (groupie.style) {
          for (let x = 0; x < childeren.length; x++) {
            let child = childeren[x];
            if (child.nodeName !== '#text') {
              if (child.style) {
                await child.setAttribute("style", groupie.style.cssText + ' ' + child.style.cssText)
              } else {
                await child.setAttribute("style", groupie.style.cssText)
              }
            }
          }
        }
        await groupElement.ungroup(groupElement.parent());
      }
    }

    if (r_defs.length > 0) {
      for (let r = 0; r < r_defs.length; r++) {
        await svg.appendChild(r_defs[r]); // set defs back
      }
    }

    return;

  }

  async sortVect(svg: SVGSVGElement): Promise<SVGSVGElement> {
    let lista, list;
    lista = svg.getElementsByTagName('path');
    list = Array.prototype.slice.call(lista);
    // sort on layered dom attribute
    list = list.sort(this.sortOnNum("attributes", "loc", "value"));
    // svg.innerHTML = '';
    for (let i = 0; i < list.length; i++) {
      svg.appendChild(list[i]);
    }
    return svg
  }


  sortOnNum(property1, property2, property3) {
    return function (a, b) {
      if (a === undefined || b === undefined) {
        return 0
      }

      if (a[property1] !== undefined && b[property1] !== undefined) {
        if (a[property1][property2] !== undefined && b[property1][property2] !== undefined) {
          if (a[property1][property2][property3] !== undefined && b[property1][property2][property3] !== undefined) {
            a = parseInt(a[property1][property2][property3]);
            b = parseInt(b[property1][property2][property3]);
          } else { return 0 }
        } else { return 0 }
      } else { return 0 }

      if (a.length < b.length) {
        return -1;
      } else if (a.length > b.length) {
        return 1;
      } else {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      }
    }

  }


  sortOn(property) {
    return function (a, b) {

      if (a[property].length < b[property].length) {
        return -1;
      } else if (a[property].length > b[property].length) {
        return 1;
      } else {
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }

  removeHeadG(svg): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let g, gg;
      g = svg.getElementsByTagName("g");
      if (g.length > 0) {
        gg = SVG(g[0]);
        gg.ungroup();
      }
      resolve();
    });
  }

  async removeSwitch(svg): Promise<any> {
    let switchclass = svg.getElementsByTagName("switch")[0];
    if (switchclass) {
      svg.append(...switchclass.childNodes);
    }

    return
  }


  setRotatePath(event, idel, node?) {
    console.log('roate path', this.selectedVecPathmultiple);
    let el;
    if (node) { el = node } else {
      node = document.getElementById(this.selectedVecPathmultiple[0].id) as unknown
    }
    if (this.selectmultiplepaths) {
      node = document.getElementById('selectiongroupXL') // get <g> with paths
    }
    let element = node;
    Draggable.create(element, {
      type: "rotation",
      onDragEnd:
        function (idl) {
          //this.disable();
        }
    })
  }

  moveNodeUp(element) {
    this.selectedVecPathmultiple.forEach((nodeEl) => {
      let node = document.getElementById(nodeEl.id),
        parent = node.parentNode,
        prev = node.previousSibling,
        oldChild = parent.removeChild(node);
      parent.insertBefore(oldChild, prev);
    });

    let svgparent = document.getElementById(element.id);
    let svg = svgparent.querySelector('svg');
    this.numberPathsNew(svg);
  }

  moveNodeDown(element) {
    this.selectedVecPathmultiple.forEach((nodeEl) => {
      let node = document.getElementById(nodeEl.id),
        parent = node.parentNode,
        prev = node.previousSibling,
        oldChild = parent.removeChild(node);
      parent.insertBefore(prev, oldChild);
    });

    let svgparent = document.getElementById(element.id);
    let svg = svgparent.querySelector('svg');
    this.numberPathsNew(svg);
  }


  async toggleRotatePath(selectedelement) {
    if (this.rotatepath) {
      // rotate is transform on group so we will need to remove group set transform to style and then set tranform to path coordinates
      let vecttmp = document.getElementById(selectedelement.id);
      let svg = vecttmp.getElementsByTagName('svg')[0];
      await this.deleteVectorGroup(svg);
      //this.removeVectorPathSelection(selectedelement);
      this.removeVectorPathMultiSelection(selectedelement)
      this.prepareVector(selectedelement, svg); // make coordinates from transform
      this.rotatepath = false;
    } else {
      let group = document.getElementById('selectiongroupXL');
      if (group) {
        let g = SVG(group) as any;
        g.draggable(false)
        this.rotatepath = true;
        this.setRotatePath(event, selectedelement, group)
      } else {
        this.setRotatePath(event, selectedelement);
        this.rotatepath = true;
      }

    }
  }


}