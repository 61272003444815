
import { BASE_URL } from '../../shared/base.api';

// encode 


export const StockAudio = [
    BASE_URL + '/api/Containers/standardaudio/download/Uplifting-Energetic-Summer-Pop.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/jingle-bells-swing-orchestra.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Beautiful.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Clock.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Corporate-presentation.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Death-Metal.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Happy-Birthday-Modern.mp3',
    BASE_URL + '/api/Containers/standardaudio/download/Christmas-Ident.mp3',
    //BASE_URL + '/api/Containers/standardaudio/download/',
]