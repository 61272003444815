import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleMapService } from 'src/app/shared/googlemapservice/googlemap.service';
import { Account, Websitetracker, RelationsApi } from 'src/app/shared/sdk';

@Component({
  selector: 'app-check-relation-dialog',
  templateUrl: './check-relation-dialog.component.html',
  styleUrls: ['./check-relation-dialog.component.scss']
})
export class CheckRelationDialogComponent implements OnInit {

  public edit = false;
  busygettingtrackerlist: boolean;
  WebsitetrackerFullList: Websitetracker[];
  Websitetracker: Websitetracker;
  Account: Account;

  public mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#ff4400"
        },
        {
          "saturation": -100
        },
        {
          "lightness": -2
        },
        {
          "gamma": 0.72
        }
      ]
    }];

  constructor(
    public dialogRef: MatDialogRef<CheckRelationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public RelationsApi: RelationsApi,
    public snackBar: MatSnackBar,
    public googleMapService: GoogleMapService,
  ) {

  }

  toggleEdit() {
    if (this.edit) {
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  save() {
    this.dialogRef.close(this.Websitetracker);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.data)
    this.Websitetracker = this.data.Websitetracker;
    this.Account = this.data.Account;
    this.findWebsiteTrackerByIP()


    if (!this.Websitetracker.lon || this.Websitetracker.lon === 'undefined')
      this.googleMapService.getLatLan(
        this.Websitetracker.city + ' ' + this.Websitetracker.country + ' ' + this.Websitetracker.street
      )
        .subscribe(
          result => {
            this.Websitetracker.lat = result.lat();
            this.Websitetracker.lon = result.lng();
          }
        );
  }

  findWebsiteTrackerByIP(): void {
    this.busygettingtrackerlist = true;
    //this.WebsitetrackerFullList = [];
    this.RelationsApi.getWebsitetracker(this.Account.standardrelation, {
      where: { ip: this.Websitetracker.ip },
      order: 'date DESC', limit: 20
    }).subscribe((filteredList: Websitetracker[]) => {
      //console.log(filteredList)
      this.WebsitetrackerFullList = filteredList;
      this.busygettingtrackerlist = false;
    })
  }


  markisp(websitetracker: Websitetracker): void {
    websitetracker.isp = true;
    this.RelationsApi.updateByIdWebsitetracker(this.Account.standardrelation, websitetracker.id, websitetracker)
      .subscribe(res => {
        // this.getWebsiteTracker();
        this.snackBar.open('Marked ISP');
      }
      );
    // this.WebsitetrackerApi.update({where: {IP: this.Websitetracker[i].IP}}, {isp: true})
    // .subscribe(res => this.getWebsiteTracker());
  }






}
