import { BASE_URL } from '../../shared/base.api';
export interface Font {
    name: string;
    value: string;
    src: string;
  }

export const fonts: Font[] = [
    {value: 'Arial, Helvetica, sans-serif', name: 'Arial', src: ''},
    {value: 'Arial Black, Gadget, sans-serif', name:'Arial Black', src: ''},
    {value: 'Tahoma, Geneva, sans-serif', name: 'Tahoma', src: ''},
    {value: 'Times New Roman, Times, serif', name: 'Times New Roman', src: ''},
    {value: 'Roboto', name: 'Roboto', src: 'https://fonts.googleapis.com/css?family=Roboto'},
    {value: 'Open Sans', name: 'Open Sans', src: 'https://fonts.googleapis.com/css?family=Open+Sans'},
    {value: 'Shipwreck', name: 'Shipwreck', src: BASE_URL+ '/assets/fonts/SchoonerScript.ttf'},
    {value: 'Baloo Bhai', name: 'Baloo Bhai', src: 'https://fonts.googleapis.com/css?family=Baloo+Bhai'},
    {value: 'Tomorrow', name: 'Tomorrow', src: '"https://fonts.googleapis.com/css2?family=Tomorrow'},
    {value: 'SandBrush', name: 'Sand Brush', src: BASE_URL+ '/assets/fonts/SandbrushPro.ttf'},
    {value: 'WesternOld', name: 'Western Oldies', src: BASE_URL+ '/assets/fonts/WesternOld.ttf'},
    {value: 'IndustrialOld', name: 'Industrial Oldies', src: BASE_URL+ '/assets/fonts/IndustrialOld.ttf'},
    {value: 'Hillpark', name: 'Hillpark', src: BASE_URL+ '/assets/fonts/Hillpark.ttf'},
    {value: 'Northead', name: 'Northead', src: BASE_URL+ '/assets/fonts/Northead.ttf'},
    {value: 'Woodpecker', name: 'Woodpecker', src: BASE_URL+ '/assets/fonts/Woodpecker.ttf'},
  ];

  