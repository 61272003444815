import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelationsApi, Mailing, CompanyApi, Relations } from '../../shared/sdk';
import { BASE_URL, API_VERSION } from '../../shared/base.api';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'mailing-select-dialog',
  templateUrl: 'mailing-select-dialog.html',
  styleUrls: ['./mailingselectdialog.component.scss']
})

export class MailingSelectDialog implements OnInit{

  public Mailing = [];

  constructor(
    public CompanyApi: CompanyApi,
    public RelationsApi: RelationsApi,
    public dialogRef: MatDialogRef<MailingSelectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Mailing,
    @Inject(MAT_DIALOG_DATA) public id: String) {
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getMailing();
  }

  getMailing(): void {
    // select only mailings that are asigned to campaign (marketingplannereventsids)
    this.CompanyApi.getRelations(this.id,
      {
        // where: {
        //   marketingrelation: true
        // },
        order: 'id DESC',
        include: {
          relation: 'mailing',
          where: {
            marketingplannereventsIds: {
              exists: false
            }
          },
        }
      })
      .subscribe((relations: Relations[]) => {
        relations.forEach((relation: Relations) => {
          relation.mailing.forEach((mailing: Mailing) => {
            this.Mailing.push(mailing);
          })
        });
      });
  }

  onSelectMailing(Mailing) {
    this.data = Mailing;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}