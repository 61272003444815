/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Linkedin } from '../../models/Linkedin';
import { SocketConnection } from '../../sockets/socket.connections';
import { Relations } from '../../models/Relations';


/**
 * Api services for the `Linkedin` model.
 */
@Injectable()
export class LinkedinApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation relations.
   *
   * @param {any} id linkedin id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public getRelations(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/:id/relations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id linkedin id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} domain 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinauth(id: any = {}, domain: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinauth/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof domain !== 'undefined' && domain !== null) _urlParams.domain = domain;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} code 
   *
   * @param {string} state 
   *
   * @param {string} domain 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `res` – `{object}` - 
   */
  public linkedinaccesstoken(id: any = {}, code: any = {}, state: any = {}, domain: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinaccesstoken/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof code !== 'undefined' && code !== null) _urlParams.code = code;
    if (typeof state !== 'undefined' && state !== null) _urlParams.state = state;
    if (typeof domain !== 'undefined' && domain !== null) _urlParams.domain = domain;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `res` – `{object}` - 
   */
  public refreshaccesstoken(id: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/refreshaccesstoken/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinme(token: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/me";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinconnections(token: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/connections";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinadmincompanypage(token: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinadmincompanypage";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinsearchcompany(token: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/searchcompany";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} sendaccountid 
   *
   * @param {string} comment 
   *
   * @param {string} title 
   *
   * @param {string} description 
   *
   * @param {string} submittedurl 
   *
   * @param {string} submittedimageurl 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinsharecompanyupdate(token: any = {}, sendaccountid: any = {}, comment: any = {}, title: any = {}, description: any = {}, submittedurl: any = {}, submittedimageurl: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinsharecompanyupdate";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof sendaccountid !== 'undefined' && sendaccountid !== null) _urlParams.sendaccountid = sendaccountid;
    if (typeof comment !== 'undefined' && comment !== null) _urlParams.comment = comment;
    if (typeof title !== 'undefined' && title !== null) _urlParams.title = title;
    if (typeof description !== 'undefined' && description !== null) _urlParams.description = description;
    if (typeof submittedurl !== 'undefined' && submittedurl !== null) _urlParams.submittedurl = submittedurl;
    if (typeof submittedimageurl !== 'undefined' && submittedimageurl !== null) _urlParams.submittedimageurl = submittedimageurl;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} url 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinreq(token: any = {}, url: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinreq/";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof url !== 'undefined' && url !== null) _urlParams.url = url;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} url 
   *
   * @param {string} content 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public linkedinpost (token: any = {}, url: any = {}, content: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/linkedinpost";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof url !== 'undefined' && url !== null) _urlParams.url = url;
    if (typeof content !== 'undefined' && content !== null) _urlParams.content = content;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} shareid 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public deleteshare(token: any = {}, shareid: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/deleteshare";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof shareid !== 'undefined' && shareid !== null) _urlParams.shareid = shareid;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} imageurl 
   *
   * @param {string} companyidlinkedin 
   *
   * @param {string} useridlinkedin 
   *
   * @param {string} title 
   *
   * @param {string} content 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public postImage(token: any = {}, imageurl: any = {}, companyidlinkedin: any = {}, useridlinkedin: any = {}, title: any = {}, content: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/postimage";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof imageurl !== 'undefined' && imageurl !== null) _urlParams.imageurl = imageurl;
    if (typeof companyidlinkedin !== 'undefined' && companyidlinkedin !== null) _urlParams.companyidlinkedin = companyidlinkedin;
    if (typeof useridlinkedin !== 'undefined' && useridlinkedin !== null) _urlParams.useridlinkedin = useridlinkedin;
    if (typeof title !== 'undefined' && title !== null) _urlParams.title = title;
    if (typeof content !== 'undefined' && content !== null) _urlParams.content = content;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} videourl 
   *
   * @param {string} companyidlinkedin 
   *
   * @param {string} useridlinkedin 
   *
   * @param {string} title 
   *
   * @param {string} content 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public postVideo(token: any = {}, videourl: any = {}, companyidlinkedin: any = {}, useridlinkedin: any = {}, title: any = {}, content: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/postvideo";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof videourl !== 'undefined' && videourl !== null) _urlParams.videourl = videourl;
    if (typeof companyidlinkedin !== 'undefined' && companyidlinkedin !== null) _urlParams.companyidlinkedin = companyidlinkedin;
    if (typeof useridlinkedin !== 'undefined' && useridlinkedin !== null) _urlParams.useridlinkedin = useridlinkedin;
    if (typeof title !== 'undefined' && title !== null) _urlParams.title = title;
    if (typeof content !== 'undefined' && content !== null) _urlParams.content = content;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} companyidlinkedin 
   *
   * @param {string} useridlinkedin 
   *
   * @param {string} content 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public post(token: any = {}, companyidlinkedin: any = {}, useridlinkedin: any = {}, content: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/post";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof companyidlinkedin !== 'undefined' && companyidlinkedin !== null) _urlParams.companyidlinkedin = companyidlinkedin;
    if (typeof useridlinkedin !== 'undefined' && useridlinkedin !== null) _urlParams.useridlinkedin = useridlinkedin;
    if (typeof content !== 'undefined' && content !== null) _urlParams.content = content;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} videourl 
   *
   * @param {string} companyidlinkedin 
   *
   * @param {string} useridlinkedin 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public initializeUploadRequest(token: any = {}, videourl: any = {}, companyidlinkedin: any = {}, useridlinkedin: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/initializeuploadrequest";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof videourl !== 'undefined' && videourl !== null) _urlParams.videourl = videourl;
    if (typeof companyidlinkedin !== 'undefined' && companyidlinkedin !== null) _urlParams.companyidlinkedin = companyidlinkedin;
    if (typeof useridlinkedin !== 'undefined' && useridlinkedin !== null) _urlParams.useridlinkedin = useridlinkedin;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} shareid 
   *
   * @param {string} comment 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public updateshare(token: any = {}, shareid: any = {}, comment: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/updateshare";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof shareid !== 'undefined' && shareid !== null) _urlParams.shareid = shareid;
    if (typeof comment !== 'undefined' && comment !== null) _urlParams.comment = comment;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} token 
   *
   * @param {string} name 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Linkedin` object.)
   * </em>
   */
  public getCompanyByName(token: any = {}, name: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/linkedins/getcompanybyname";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof token !== 'undefined' && token !== null) _urlParams.token = token;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Linkedin`.
   */
  public getModelName() {
    return "Linkedin";
  }
}
