import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ContainerApi, Files, Relations, RelationsApi, Company, Account, FilesApi } from '../sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
// import { materialthreedmaterials } from './materialthreedmaterials'
// import { templatescreenshots, templates } from './stocktemplates';
import { BASE_URL, API_VERSION } from '../base.api'
import { FileUploader } from 'ng2-file-upload';

const URL = 'http://localhost:3000/api/containers/tmp/upload';
export interface DialogData {
  img;
  selected;
  storedtemplate;
  fromfile;
}

@Component({
  selector: 'app-threedmaterial',
  templateUrl: './threedmaterial.component.html',
  styleUrls: ['./threedfile.component.scss']
})
export class ThreedmaterialComponent implements OnInit {

  uploader: FileUploader;
  errorMessage: string;
  allowedMimeType = ['application/object', ''];
  maxFileSize = 200 * 1024 * 1024;
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  public Files: Files[];
  public newFiles: Files = new Files();
  public showdropbox = true;
  public showgallery = false;
  public selectedthreedmaterial;
  public threedmaterial = [];
  public images = [];
  public stored = [];

  @Input('option') option: Relations; //get id for image gallery
  @Input('account') account: Account;
  @Output() seteditvideo = new EventEmitter();
  @Output() imgurl = new EventEmitter(); //send url img back
  // @Input('stored') stored = [];
  @Input('files') files: Files;
  newFilesArray: any;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public ContainerApi: ContainerApi,
    public relationsApi: RelationsApi,
    public fileApi: FilesApi
  ) { }

  ngOnInit() {
    // Clear the item queue (somehow they will upload to the old URL)
    this.uploader = new FileUploader({
      url: URL,
      allowedMimeType: this.allowedMimeType,
      maxFileSize: this.maxFileSize,
    });
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploader.clearQueue();


    this.uploader.onAfterAddingAll = (files) => {
      files.forEach(fileItem => {
        fileItem.file.name = fileItem.file.name.replace(/ /g, '-');

        let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + fileItem.file.name
        imgurl = imgurl.replace(/ /g, '-');

        let newFiles = new Files;
        newFiles.size = fileItem.file.size;
        newFiles.name = fileItem.file.name;
        newFiles.url = imgurl;
        newFiles.createdate = new Date();
        newFiles.type = 'general';
        newFiles.companyId = this.account.companyId;

        this.newFilesArray.push(newFiles);

      });
    };
  }

  // set constiable and upload + save reference in Publications
  setupload(name, size): void {
    // set upload url
    let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    this.uploader.setOptions({ url: urluse });

    // set download url or actual url for publishing
    let imgurl = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + name
    imgurl = imgurl.replace(/ /g, '-'),
      // imgurl = encodeURI(imgurl);
      // define the file settings
      this.newFiles.size = size,
      this.newFiles.name = name,
      this.newFiles.url = imgurl,
      this.newFiles.createdate = new Date(),
      this.newFiles.type = 'threedmaterial',
      this.newFiles.companyId = this.account.companyId,
      // check if container exists and create
      this.ContainerApi.findById(this.option.id)
        .subscribe(res => this.uploadFile(),
          error =>
            this.ContainerApi.createContainer({ name: this.option.id })
              .subscribe(res => this.uploadFile()));
  }

  uploadFile(): void {
    this.uploader.uploadAll();
    this.newFilesArray.forEach((elementFile, index) => {
      this.relationsApi.createFiles(this.option.id, elementFile)
        .subscribe(res => {
          if (index === 0) {
            console.log(res),
              setTimeout(() => {
                this.setimage(res.url);
              }, 1000);
          }
        });
    });
  }

  onWhenAddingFileFailed(item, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        console.log(item)
        this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }

  // file upload 1
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  // file upload 2
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  setimage(url) {
    this.showdropbox = false;
    this.showgallery = false;
    this.imgurl.emit(url);
  }

  onOpenGallery() {
    this.images = [];
    this.relationsApi.getFiles(this.option.id,
      { where: { "name": { "regexp": '.mtl' + '/i' } } }
    ).subscribe((files: Files[]) => {
      this.Files = files,
        this.Files.forEach((file, index) => {
          let ext = file.name.split('.').pop();
          if (ext === 'mtl') {
            const modalImage = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + file.name;
            this.images.push({ url: modalImage, name: file.name })
          }
        })

      //console.log(this.stored)
      const dialogRef = this.dialog.open(dialogthreedmaterialgallerycomponent, {
        width: '600px',
        data: { selected: this.selectedthreedmaterial, fromfile: this.images }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.animal = result;
        console.log(result)
        if (result) {
          this.setimage(result.url)
        }
      });
    });
  }

}

@Component({
  selector: 'dialog-threedmaterialgallery',
  templateUrl: 'dialog-threedmaterialgallery.html',
  styleUrls: ['./threedfile.component.scss']
})

export class dialogthreedmaterialgallerycomponent implements OnInit {

  //public threedmaterialsSet = materialthreedmaterials;
  public materialthreed = [];
  public materialthreedArray = [];
  public materialthreedSlice = 12;
  public materialthreedSliceMin = 1;

  public storedset = []
  public stored = [];
  public storedArray = [];
  public storedSlice = 12;
  public storedSliceMin = 1;


  public URL = 'http://localhost:3000/api/containers/tmp/upload';

  constructor(
    public dialogRef: MatDialogRef<dialogthreedmaterialgallerycomponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

    this.storedset.forEach((element, index) => {
      // !!! using the entire file object
      if (index < 12) { this.stored.push(element) }
      this.storedArray.push(element);
    });
    this.data.fromfile.forEach((element, index) => {
      if (index < 12) { this.stored.push(element) }
      this.storedArray.push(element);
    });
  }

  next() {

    if (this.materialthreedSlice < this.materialthreedArray.length - 1) {
      this.materialthreedSlice = this.materialthreedSlice + 13;
      this.materialthreedSliceMin = this.materialthreedSliceMin + 13
      this.materialthreed = this.materialthreedArray.slice(this.materialthreedSliceMin-1, this.materialthreedSlice)
    }

    if (this.storedSlice < this.storedArray.length - 1) {
      this.storedSlice = this.storedSlice + 13;
      this.storedSliceMin = this.storedSliceMin + 13
      this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
    }

  }

  before() {
    if (this.materialthreedSliceMin > 1) {
      this.materialthreedSlice = this.materialthreedSlice - 13;
      this.materialthreedSliceMin = this.materialthreedSliceMin - 13
      this.materialthreed = this.materialthreedArray.slice(this.materialthreedSliceMin-1, this.materialthreedSlice)
    }

    if (this.storedSliceMin > 1) {
      this.storedSlice = this.storedSlice - 13;
      this.storedSliceMin = this.storedSliceMin - 13
      this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
    }
  }


  onNoClick(): void {
    this.data.selected = '';
    this.dialogRef.close();
  }

  selectedimage(img): void {
    this.data.selected = img;
    console.log(img)
  }

}