<mat-tab-group [(selectedIndex)]="selectedTab">
  <mat-tab label="Relations Account">
    <ng-template mat-tab-label>
      <mat-icon>settings</mat-icon>
      <label i18n>Account</label>
    </ng-template>
    <div fxLayout="row">
      <div fxFlex="15%">
        <div style="font-size: small; color: grey; position: fixed; margin-left: 10px; margin-top: 50px;"
          fxLayout="column" fxLayoutAlign="space-around">
          <label *ngIf="Account.companyadmin" (click)="navigateInPage(0)" i18n>Usage</label><br>
          <label *ngIf="Account.companyadmin" (click)="navigateInPage(1)" i18n>Teammembers</label><br>
          <label (click)="navigateInPage(2)" i18n>Account info</label><br>
          <label (click)="navigateInPage(3)" i18n>Fonts and colors</label><br>
          <label (click)="navigateInPage(4)" i18n>Company information</label><br>
          <label (click)="navigateInPage(5)" i18n>Notifications</label><br>
          <label (click)="navigateInPage(6)" i18n>Email Info</label><br>
          <label (click)="navigateInPage(7)" i18n>Set BCC track mail</label><br>
          <label (click)="navigateInPage(8)" i18n>Office 365</label><br>
          <!-- <label (click)="navigateInPage(9)" i18n>Domain settings</label><br> -->
          <label (click)="navigateInPage(10)" i18n>Set Opportunity status</label><br>
          <label (click)="navigateInPage(11)" i18n>Set Opportunity type</label><br>
          <label (click)="navigateInPage(12)" i18n>set Relation status Types</label><br>
          <label (click)="navigateInPage(13)" i18n>Set Types</label><br>
          <label (click)="navigateInPage(14)" i18n>Free Selection 1</label><br>
          <label (click)="navigateInPage(15)" i18n>Free Selection 2</label><br>
          <label (click)="navigateInPage(16)" i18n>Free Selection 3</label><br>
          <label (click)="navigateInPage(17)" i18n>Free Selection 4</label><br>
        </div>
      </div>
      <div fxFlex="90%" (onblur)="saveCompany()">
        <div fxLayout="column">

          <div fxLayout="column">
            <div class="companyinfo" *ngIf="Account.two_factor_enabled">
              <h2 i18n>Two-factor Authentication activated</h2>
              <button mat-raised-button (click)="resetTwoFactor()" i18n>Reset or deactivated two-factor authentication</button>
            </div>
            <div class="companyinfo" *ngIf="!Account.two_factor_enabled">
              <div fxLayout="column">
                <h2 i18n>Activate Two Factor Authentication</h2>
                <br>
                <label i18n>Add two factor authentication to your application by using the Google, Microsoft or any other 3rd
                  party Authenticator app. Use QR code scanner in the app to add account.</label>
                <br>
                <button *ngIf="!twofactorqrcode" mat-raised-button (click)="getQRcode()">Get QR-code</button>

                <div *ngIf="twofactorqrcode" >
                  <img src={{twofactorqrcode}}>
                  <br>
                  <mat-form-field appearance="outline" style="width: 300px;">
                    <mat-label i18n>After adding fill in the code</mat-label>
                    <input matInput i18n-placeholder placeholder="Add code from app" value="value" [(ngModel)]="twofactortokencode">
                  </mat-form-field>

                 
                </div>
                <button mat-raised-button *ngIf="twofactorqrcode" (click)="checkTwoFactor()" i18n>Check</button>
              </div>
            </div>
          </div>

          <div class="companyinfo" *ngIf="Account.companyadmin && Team && subscription">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <h2 i18n>Usage this periode</h2>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Terms:</div>
                <div fxFlex>{{subscription.terms}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Plan:</div>
                <div fxFlex>{{subscription.plan}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Maximum users:</div>
                <div fxFlex>{{Team.length + 1}} / {{subscription.additionalusers}}</div>

              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Amount:</div>
                <div fxFlex>{{subscription.amount}} {{subscription.currency}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Email count:</div>
                <div fxFlex>{{subscription.emailcount}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Email usage:</div>
                <div fxFlex>{{subscription.emailusage}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Translation usage:</div>
                <div fxFlex>{{subscription.translationusage}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Marketing Accounts:</div>
                <div fxFlex>{{marketingrelationcount}} / {{subscription.marketingrelationaccounts}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Text generation AI Tool:</div>
                <div fxFlex>{{subscription.aiusage}}</div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px">
                <div fxFlex i18n>Start Date</div>
                <div fxFlex>{{subscription.date | date}}</div>
              </div>
            </div>
          </div>

          <div class="companyinfo" *ngIf="Account.companyadmin && Team && subscription">
            <div class="teammembers" class="team">
              <h2 i18n>Teammembers {{Account.companyname}}</h2>
              <br>
              <div *ngFor="let Team of Team; let i = index">
                <div fxLayout="row" fxLayout.xs="column" style="width: 100%;" fxLayoutGap="20px">
                  <label fxFlex="200px">{{ Team.username }}</label>
                  <label fxFlex="200px">{{ Team.teamName }}</label>
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteTeamMember(Team, i)" i18n>delete</button>
                    <button mat-menu-item (click)="resetTeamMemberPassword(Team, i)" i18n>Reset Account</button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <br>

            <form *ngIf="Account.companyadmin && Team.length + 1 < subscription.additionalusers">
              <label i18n>Create Teammember (send invite) {{Account.companyname}}</label>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput autocomplete="off" name="email" type="email" [(ngModel)]="newAccount.email"
                      i18n-placeholder placeholder="email" />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput name="password" type="password" [(ngModel)]="newAccount.password"
                      i18n-placeholder placeholder="password" autocomplete="new-password" />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput name="username" type="username" [(ngModel)]="newAccount.username"
                      i18n-placeholder placeholder="username" />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <button (click)="createTeamMember()" a mat-raised-button class="button" i18n>Create Member</button>
              </div>
              <div>
                <label i18n>Make sure to share the created password with your new team member, he can change the password
                  after
                  the first login.</label>
              </div>
            </form>
          </div>

          <div class="companyinfo">
            <button mat-raised-button (click)="resetPasswordToggle = true;" i18n>Reset Password</button>
            <br>
            <div *ngIf="resetPasswordToggle" class="registercontainer" fxLayout="column">
              <section class="mat-typography">
                <label i18n>Fill in your email and we will send you an email to reset your password, check your spambox if
                  you have not received it.</label>
              </section>
              <br>
              <mat-form-field appearance="outline">
                <input matInput name="Email" type="email" [(ngModel)]="resetEmailAddress" i18n-placeholder placeholder="email" />
              </mat-form-field>
              <br>
              <button (click)="resetPassword()" a mat-raised-button i18n>Reset</button>

            </div>
          </div>

          <div class="companyinfo" *ngIf="toggleedituser">
            <div fxLayout="column">
              <h2 i18n>Account info</h2>
              <form fxLayout="column">
                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Account.email i18n-placeholder placeholder="Email" class="InputField" name="email">
                </mat-form-field>
                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Account.username i18n-placeholder placeholder="Username" class="InputField"
                    name="username">
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent.color">
                  <mat-label i18n>Password</mat-label>
                  <mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
                  <input autocomplete="new-password" matInput #password [type]="toggleVisbility.type"
                    i18n-placeholder placeholder="Password" name="password" [(ngModel)]="Account.password" autocomplete="off">
                  <mat-hint align="end" aria-live="polite">
                    {{password.value.length}} / {{passwordComponent.max}}
                  </mat-hint>
                </mat-form-field>
                <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                  [password]="password.value">
                </mat-password-strength>
                <mat-password-strength-info [passwordComponent]="passwordComponent" [enableScoreInfo]="true">
                </mat-password-strength-info>

                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Account.companyname i18n-placeholder placeholder="Company Name" class="InputField"
                    name="companyname">
                </mat-form-field>
                <button (click)="saveAccount()" a mat-raised-button class="button" i18n>Save Changes</button>
                <button mat-mini-fab color="primary" (click)="toggleEditUser()">
                  <mat-icon>edit</mat-icon>
                </button>
                <div fxFlex>
                  <button (click)="openDialogDeleteAccount()" a mat-raised-button class="button" i18n>Delete My
                    Account</button>
                </div>
              </form>
            </div>
          </div>

          <div class="companyinfo" *ngIf="!toggleedituser">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <h2 fxFlex i18n>Account settings</h2>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <label fxFlex i18n>Email:</label>
                <label fxFlex>{{Account.email}}</label>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <label fxFlex i18n>Username:</label>
                <label fxFlex>{{Account.username}}</label>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <label fxFlex i18n>Company name:</label>
                <label fxFlex>{{Account.companyname}}</label>
              </div>

              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <label fxFlex i18n>Role:</label>
                <label fxFlex *ngIf="Account.companyadmin" i18n>Admin</label>
                <label fxFlex *ngIf="!Account.companyadmin" i18n>Team member</label>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="25px" *ngIf="Account && option">
                <label fxFlex i18n>Profile picture:</label>
                <img src={{Account.avatarurl}} class="avatar" *ngIf="Account.avatarurl">
                <mat-icon fxFlex (click)="deleteAvatar()" class="iconsize-12" *ngIf="Account.avatarurl">delete
                </mat-icon>
                <app-fileupload fxFlex *ngIf="!Account.avatarurl" [account]="Account" [option]="option"
                  (imgurl)="setAvatar($event)">
                </app-fileupload>
              </div>
            </div>

            <button mat-mini-fab color="primary" (click)="toggleEditUser()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <div class="companyinfo" on-focusout="saveCompany()">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <h2 i18n>Set Standard Font and Colors</h2>
                </div>
              </div>
              <div>
                <mat-form-field>
                  <input matInput i18n-placeholder placeholder="size" [(ngModel)]="Company.companyfontsize" type="number">
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field>
                    <mat-label i18n>Select font</mat-label>
                    <mat-select [(ngModel)]="Company.companyfont" (selectionChange)="saveCompany()">
                      <mat-option *ngFor="let font of Fonts" [value]="font.value">
                        {{font.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Primairy Color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <span (change)="saveCompany()" [style.background]="Company.companyprimairycolor" [cpToggle]="true"
                      [cpDialogDisplay]="'inline'" [cpCancelButton]="true"
                      [(colorPicker)]="Company.companyprimairycolor"></span>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Secondary Color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br>
                    <span (change)="saveCompany()" [style.background]="Company.companysecondarycolor" [cpToggle]="true"
                      [cpDialogDisplay]="'inline'" [cpCancelButton]="true"
                      [(colorPicker)]="Company.companysecondarycolor"></span>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title i18n>
                      Signature
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayout.xs="column">
                    <div [innerHTML]="Account.signature | safeHtml"></div>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <button a mat-raised-button color="primary" (click)="openeditorsignature()" i18n>
                      Edit Signature
                    </button>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex>
                      <button (click)="saveCompany()" a mat-raised-button class="button" i18n>Save Changes</button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <br>
              <div *ngIf="option && Account && Company" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                <label fxFlex i18n>Company logo:</label>
                <app-fileupload fxFlex *ngIf="!Company.companylogo" [account]="Account" [option]="option"
                  (imgurl)="setCompanyLogo($event)">
                </app-fileupload>


                <div fxFlex *ngIf="Company.companylogo" fxLayout="row">
                  <img src={{Company.companylogo}} class="companylogo">
                  <mat-icon (click)="Company.companylogo = ''" class="iconsize-12 i18n">delete</mat-icon>
                </div>
              </div>
              <div *ngIf="!option">
                <label i18n>Set standard Relation to set company logo</label>
              </div>
            </div>
          </div>
          <!-- subscription && Team -->


          <div class="companyinfo">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <h2 i18n>Company information</h2>
                </div>
              </div>

              <div fxFlex>
                <!-- <mat-form-field fxFlex="90" class="form-full-width">
                <input matInput [(ngModel)]=Company.companyname i18n-placeholder placeholder="Company Name" class="InputField">
              </mat-form-field> -->
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput google-place (onSelect)="setCompanyAddress($event)" [(ngModel)]="Company.companyname"
                    i18n-placeholder placeholder="Company Name" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.address i18n-placeholder placeholder="Address" class="InputField">
                </mat-form-field>
              </div>

              <div fxFlex>
                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Company.city i18n-placeholder placeholder="City" class="InputField">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Company.zipcode i18n-placeholder placeholder="Zipcode" class="InputField">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field class="form-full-width">
                  <input matInput [(ngModel)]=Company.stateprov i18n-placeholder placeholder="State/Province" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.country i18n-placeholder placeholder="Country" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <button fxFlex="90" (click)="billingCompanyCopy()" a mat-raised-button class="button" i18n>Billing address is
                  the same</button>
              </div>

              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingaddress i18n-placeholder placeholder="Billing Address" class="InputField">
                </mat-form-field>
              </div>



              <div fxFlex>
                <mat-form-field fxFlex="30" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingcity i18n-placeholder placeholder="Billing City" class="InputField">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field fxFlex="30" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingzipcode i18n-placeholder placeholder="Billing zipcode" class="InputField">
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field fxFlex="30" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingstateprov i18n-placeholder placeholder="Billing State/Province"
                    class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingcountry i18n-placeholder placeholder="Billing Country" class="InputField">
                </mat-form-field>
              </div>

              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingcontact i18n-placeholder placeholder="Billing Contact" class="InputField">
                </mat-form-field>
              </div>

              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.billingemail i18n-placeholder placeholder="Billing Email" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]=Company.phonenumber i18n-placeholder placeholder="Phonenumber" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]="Company.swift" i18n-placeholder placeholder="SWIFT" class="InputField">
                </mat-form-field>
              </div>


              <div fxFlex>
                <mat-form-field fxFlex="90" class="form-full-width">
                  <input matInput [(ngModel)]="Company.iban" i18n-placeholder placeholder="IBAN/Bank account" class="InputField">
                </mat-form-field>
              </div>

              <div fxFlex>
                <button (click)="saveCompany()" a mat-raised-button class="button" i18n>Save Changes</button>
              </div>
            </div>

          </div>


          <div class="companyinfo" *ngIf="Account">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column">

                <h2>Notifications</h2>

              </div>
              <button mat-raised-button (click)="subscribeToNotification()" i18n>Send me notifications</button>
              <p *ngIf="isGranted" class="success" i18n>
                Notifications were granted by the user
              </p>
              <p *ngIf="!isEnabled" class="error" i18n>
                Notifications are not available in this browser or not enabled in the application
              </p>
              <button *ngIf="isGranted" mat-raised-button (click)="turnOffNotification()" i18n>Turn off notifications for
                this device</button>
              <br>
              <div fxLayout="row" fxLayoutGap="20px">
                <label fxFlex="300px" i18n>Chat, on new Chat message</label>
                <mat-checkbox fxFlex="200px" (click)="saveAccount()" [(ngModel)]="Account.notificationchat" i18n>Email
                </mat-checkbox>
                <mat-checkbox fxFlex="200px" (click)="saveAccount()" [(ngModel)]="Account.pushchat" i18n>Push Message
                </mat-checkbox>
                <!-- <mat-checkbox (click)="saveAccount()" [(ngModel)]="Account.logchat">Log</mat-checkbox> -->
              </div>
              <div fxLayout="row" fxLayoutGap="20px">
                <label fxFlex="300px" i18n>Forms, on new Form entry</label>
                <mat-checkbox fxFlex="200px" (click)="saveAccount()" [(ngModel)]="Account.notificationform" i18n>Email
                </mat-checkbox>
                <mat-checkbox fxFlex="200px" (click)="saveAccount()" [(ngModel)]="Account.pushform" i18n>Push Message
                </mat-checkbox>
                <!-- <mat-checkbox (click)="saveAccount()" [(ngModel)]="Account.logform">Log</mat-checkbox> -->
              </div>

            </div>
          </div>

          <div class="companyinfo">
            <form fxLayout="column" class="form-login" cellspacing="0">
              <div fxLayout="row" fxLayout.xs="column">
                <h2 i18n>Email Info</h2>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field class="form-full-width">
                  <input matInput name="emailusername" type="email" [(ngModel)]="Emailhandler.username"
                    i18n-placeholder placeholder="username" name="emailusername" autocomplete="off" />
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput name="emailpassword" type="password" [(ngModel)]="Emailhandler.password"
                      i18n-placeholder placeholder="password" name="emailpassword" autocomplete="off" />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput name="Team name" type="text" [(ngModel)]="Emailhandler.host"
                      i18n-placeholder placeholder="Mail Server" name="teamname" />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput name="Teamname" type="text" [(ngModel)]="Emailhandler.port" i18n-placeholder placeholder="port"
                      name="port" />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-checkbox name="tls" [(ngModel)]="Emailhandler.tls">TLS</mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-form-field class="form-full-width">
                    <input matInput type="value" [(ngModel)]="Emailhandler.mailbox" i18n-placeholder placeholder="mailbox"
                      name="emailbox" />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <mat-select [(ngModel)]="Emailhandler.searchfilter" name="flagged">
                      <mat-option value="FLAGGED">FLAGGED</mat-option>
                      <mat-option value="UNSEEN">UNSEEN</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <mat-checkbox [(ngModel)]="Emailhandler.markseen" name="markseen" i18n>Mark Seen</mat-checkbox>
                </div>
                <div fxFlex>
                  <mat-checkbox [(ngModel)]="Emailhandler.attachments" name="attachmnets" i18n>Save Attachments
                  </mat-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex>
                  <button (click)="createEmailHandler()" a mat-raised-button class="button" i18n>Save</button>
                </div>
                <div fxFlex>
                  <button (click)="deleteEmailhandler()" a mat-raised-button class="button" i18n>Delete</button>
                </div>
              </div>
            </form>
          </div>

          <div class="companyinfo">
            <div fxLayout="column">
              <div fxLayout="column">
                <h2>Set BCC track mail</h2>
                <div fxLayout="column" fxLayoutGap="25px">
                  <button *ngIf="!Account.bccid" a mat-raised-button (click)="setBCCTracker()" i18n>Set</button>
                  <button *ngIf="Account.bccid" a mat-raised-button (click)="removeBCCTracker()" i18n>Delete</button>
                  <br>
                  <label i18n>Use this email address in you bcc to automatically load emails into enydea</label>
                  <br>
                  <label>{{Account.bccemail}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column">
              <h2 i18n>Office 365</h2>
              <div fxLayout="column" fxLayoutGap="25px">
                <label i18n>Login to MS office 365 to retrieve or send emails</label>
                <button *ngIf="!user && !Account['365account']" a mat-raised-button (click)="signIn365()" i18n>Sign in
                </button>
                <button *ngIf="user || Account['365account']" a mat-raised-button (click)="signOut365()" i18n>Sign
                  out</button>

                <mat-checkbox [(ngModel)]="Account.autoassign" (click)="saveAccount()" i18n>Auto assign</mat-checkbox>

                <div *ngIf="user" fxLayout="column">
                  <label i18n>Current logged in User</label>
                  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                    <label *ngIf="user">{{user.displayName}}</label>
                    <label *ngIf="user">{{user.email}}</label>
                  </div>

                </div>
                <div *ngIf="Account" fxLayout="column">
                  <label i18n>User Email</label>
                  <label *ngIf="Account">{{Account["365account"]}}</label>
                </div>
                <label i18n>Select Folders to check</label>
                <div *ngIf="officefolders" style="margin: 10px;">
                  <div *ngFor="let folder of officefolders; let i = index" fxLayoutGap="10px">
                    <mat-checkbox [(ngModel)]="folder.checked"></mat-checkbox>
                    <label>{{folder.displayName}}</label>
                  </div>
                </div>
                <br>
                <button mat-button (click)="saveFolderTracked()" i18n>save</button>

              </div>
            </div>
          </div>
<!-- 
          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Domain Settings</h2>
              <label i18n>setup new domain</label>
              <br>
              <mat-form-field fxFlex class="form-full-width">
                <input matInput [(ngModel)]=Company.companywebsite i18n-placeholder placeholder="Domain">
              </mat-form-field>
              <br>
              <div fxLayout="column" fxLayoutAlign="space-around">
                <div fxLayout="row">
                  <h3 fxFlex i18n>Type</h3>
                  <h3 fxFlex>Hostname</h3>
                  <h3 fxFlex i18n>Value to enter</h3>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                  <label fxFlex>TXT</label>
                  <label fxFlex>{{Company.companywebsite}}</label>
                  <label fxFlex>v=spf1 include:mailgun.org ~all</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                  <label fxFlex>CNAME</label>
                  <label fxFlex>email.{{Company.companywebsite}}</label>
                  <label fxFlex>mailgun.org</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                  <label fxFlex>MX</label>
                  <label fxFlex i18n>priority: 10</label>
                  <label fxFlex>mxa.mailgun.org</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">
                  <label fxFlex>MX</label>
                  <label fxFlex i18n>priority: 10</label>
                  <label fxFlex>mxb.mailgun.org</label>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around">

                </div>
              </div>
              <button (click)="saveCompanyDomain()" a mat-raised-button class="button" i18n>Save domain settings</button>
              <button (click)="checkDomainSettings()" a mat-raised-button class="button" i18n>Check Status</button>
              <div *ngIf="domainresponse" fxFlex>
                <label style="font-size: 8pt; overflow-wrap: break-word;">{{domainresponse}}</label>
              </div>
            </div>
          </div> -->

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Set Opportunity status</h2>
              <br>
              <div *ngFor="let type of Company.Opportunitystatus; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="type" (change)="saveOpportunitystatus($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteOpportunitystatus(i)" class="iconsize-10">delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.Opportunitystatus.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.Opportunitystatus = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Set Opportunity types</h2>
              <br>
              <div *ngFor="let type of Company.Opportunitytypes; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="type" (change)="saveOpportunitytypes($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteOpportunitytypes(i)" class="iconsize-10" i18n>delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.Opportunitytypes.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.Opportunitytypes = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Set Relations Status types</h2>
              <br>
              <div *ngFor="let typerel of Company.relationstatus; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="typerel" (change)="saveRelType($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteRelType(i)" class="iconsize-10" i18n>delete</mat-icon>
                  <!-- <mat-icon (click)="saveRelType(typerel, i)" class="iconsize-10">save</mat-icon> -->
                </div>
              </div>
              <button a mat-raised-button (click)="Company.relationstatus.push('new')" mat-button i18n>Add type</button>
              <!-- <button a mat-raised-button (click)="saveRelType(Company.relationstatus, i)" mat-button>Save</button> -->
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Set Types</h2>
              <br>
              <div *ngFor="let type of Company.types; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="type" (change)="saveType($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteType(i)" class="iconsize-10">delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.types.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.types = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Free Selection 1</h2>
              <br>
              <mat-form-field class="form-full-width">
                <input matInput [(ngModel)]=Company.const1name i18n-placeholder placeholder="Name">
              </mat-form-field>
              <br>
              <div *ngFor="let const1 of Company.const1; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="const1" (change)="saveConst1($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteConst1(i)" class="iconsize-10">delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.const1.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.const1 = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Free Selection 2</h2>
              <br>
              <mat-form-field class="form-full-width">
                <input matInput [(ngModel)]=Company.const2name i18n-placeholder placeholder="Name">
              </mat-form-field>
              <br>
              <div *ngFor="let const2 of Company.const2; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="const2" (change)="saveConst2($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteConst2(i)" class="iconsize-10">delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.const2.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.const2 = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Free Selection 3</h2>
              <br>
              <mat-form-field class="form-full-width">
                <input matInput [(ngModel)]=Company.const3name i18n-placeholder placeholder="Name">
              </mat-form-field>
              <br>
              <div *ngFor="let const3 of Company.const3; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="const3" (change)="saveConst3($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteConst3(i)" class="iconsize-10">delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.const3.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.const3 = []" mat-button i18n>Reset</button>
            </div>
          </div>

          <div class="companyinfo">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <h2 i18n>Free Selection 4</h2>
              <br>
              <mat-form-field class="form-full-width">
                <input matInput [(ngModel)]=Company.const4name i18n-placeholder placeholder="Name">
              </mat-form-field>
              <br>
              <div *ngFor="let const4 of Company.const4; let i = index;">
                <div fxLayout="row">
                  <mat-form-field>
                    <input matInput [value]="const4" (change)="saveConst4($event, i)">
                  </mat-form-field>
                  <mat-icon (click)="deleteConst4(i)" class="iconsize-10" i18n>delete</mat-icon>
                </div>
              </div>
              <button a mat-raised-button (click)="Company.const4.push('new')" mat-button i18n>Add type</button>
              <button a mat-raised-button (click)="Company.const4 = []" mat-button i18n>Reset</button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Unsortedcalls">
    <ng-template mat-tab-label>
      <mat-icon>mail</mat-icon>
      <label i18n>Unsorted Emails</label>
    </ng-template>

    <div class="listshowcontainer" fxShow fxHide.gt-xs>
      <mat-icon *ngIf="!listviewxsshow" class="listshowbutton" (click)="swiperight()">keyboard_arrow_right</mat-icon>
      <mat-icon *ngIf="listviewxsshow" class="listshowbutton" (click)="swipeleft()">keyboard_arrow_left</mat-icon>
    </div>


    <mat-tab-group>
      <mat-tab label="Email">
        <div class="tabcontainer" fxLayout="row" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
          <div fxLayout="column" ngClass.gt-xs="listview-normal" ngClass.lt-sm="listview-noshow"
            [class.listview-xs-show]="listviewxsshow" class="card-left-menu">
            <div>
              <h4 i18n>Search</h4>
              <mat-form-field>
                <input matInput #searchTerm id="seach-box1" (keyup.enter)="searchCallGo(searchTerm.value)"
                  i18n-placeholder placeholder="Call title/content" />
              </mat-form-field>
              <div fxLayout="column">
                <button a mat-raised-button (click)="searchCallGo(searchTerm.value)" i18n>Search</button>
                <br>
                <div fxLayout="column">
                  <div fxLayout="row" class="unsortedsearch">
                    <mat-checkbox fxFlex="50%" [(ngModel)]="officetoggle">365</mat-checkbox>
                    <button fxFlex="50%" a mat-raised-button (click)="toggleOfficeMail()" i18n>Show All</button>
                  </div>
                  <br>
                  <div fxLayout="column" class="unsortedsearch">
                    <div fxLayout="row">
                      <mat-checkbox fxFlex="50%" [(ngModel)]="checkallmails"
                        i18n-matTooltip matTooltip="If unchecked only check most recent emails" i18n>Check all</mat-checkbox>
                      <button fxFlex="50%" mat-raised-button (click)="autoAssign()" i18n>Auto Assign</button>
                    </div>
                    <br>
                    <div *ngIf="autoAssignPending" style="margin: 10px;">
                      <mat-progress-bar mode="indeterminate">
                      </mat-progress-bar>
                      <label i18n>This can take while..</label>
                    </div>
                    <br>
                    <label i18n>Select Folders to check</label>
                    <br>
                    <div *ngIf="officefolders" style="margin: 10px;">
                      <div *ngFor="let folder of officefolders; let i = index" fxLayoutGap="10px">
                        <mat-checkbox [(ngModel)]="folder.import"></mat-checkbox>
                        <label>{{folder.displayName}}</label>
                      </div>
                    </div>
                  </div>
                  <br>


                </div>
                <br>

              </div>
              <div class="listview" fxLayout="column" *ngIf="!officetoggle">
                <div *ngFor="let Unsortedcalls of Unsortedcalls; let i = index" (click)="onSelectCall(Unsortedcalls, i)"
                  [class.selected]="Unsortedcalls === selectedCall">
                  <span class="listbox">{{Unsortedcalls.title | slice:0:20 }} | {{Unsortedcalls.date | date}}</span>
                </div>
              </div>
              <div class="listview" fxLayout="column" *ngIf="officetoggle">
                <div *ngFor="let mail of OfficeMail; let i = index" (click)="onSelectOfficeMail(mail, i)"
                  [class.selected]="mail === selectedOfficeMail">
                  <span class="listbox">{{mail.subject | slice:0:20 }} | {{mail.receivedDateTime | date}}</span>
                </div>
              </div>
              <div fxLayout="column" style="width: 100%">
                <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
                  <div fxFlexstyle="width: 50%">
                    <mat-icon (click)="getCallsbackpage()">navigate_before</mat-icon>
                  </div>
                  <div fxFlexstyle="width: 50%">
                    <mat-icon (click)="getCallsnextpage()">navigate_next</mat-icon>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                  <label>{{skip}} / {{limit}}</label>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxFlex><label i18n i18n>Total</label></div>
                  <div fxFlex><label>{{Unsortedcallslength}}</label></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedCall || selectedOfficeMail" ngClass.gt-xs="container-normal"
            ngClass.lt-sm="container-noshow" [class.container-xs-show]="listviewxsshow">
            <mat-card>
              <mat-card-content>
                <mat-accordion *ngIf="selectedCall">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Follow Up
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                      <mat-checkbox [(ngModel)]="selectedCall.followup" i18n>
                        Follow Up
                      </mat-checkbox>
                      <div>
                        <mat-datepicker #picker></mat-datepicker>
                        <input [matDatepicker]="myDatepicker" [(ngModel)]=selectedCall.callbackdate
                          i18n-placeholder placeholder="Callback Date">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion *ngIf="officetoggle">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title i18n>
                        Follow Up
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                      <mat-checkbox [(ngModel)]="selectedOfficeMail.followup" i18n>
                        Follow Up
                      </mat-checkbox>
                      <div>
                        <mat-datepicker #picker></mat-datepicker>
                        <input [matDatepicker]="myDatepicker" [(ngModel)]="selectedOfficeMail.callbackdate"
                          i18n-placeholder placeholder="Callback Date">
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <br>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                  <mat-select [(ngModel)]=newRelation.status i18n-placeholder placeholder="Status">
                    <mat-option *ngFor="let statustype of Company.relationstatus" [value]="statustype">
                      {{ statustype }}
                    </mat-option>
                  </mat-select>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                  <mat-form-field fxFlex="50%">
                    <input matInput google-place (onSelect)="setAddress($event)" [(ngModel)]="newRelation.relationname"
                      i18n-placeholder placeholder="New Relation Name" class="InputField" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                  <button a mat-raised-button (click)="createNewAssign()" i18n>Create New</button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                  <mat-form-field fxFlex="50%">
                    <mat-label i18n>Assign to relation</mat-label>
                    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="option"
                      name="assignrelation">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option.relationname }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button a mat-raised-button (click)="assignCall()" i18n>Assign To</button>
                  <br>
                </div>
                <br>
                <mat-icon *ngIf="selectedStored" color="primary" i18n-matTooltip matTooltip="Email is already stored" i18n>folder
                </mat-icon>
                <button mat-raised-button (click)="autoAssignOne()" i18n i18n>Auto Assign</button>
                <br>
                <div fxLayout="row" fxLayout.xs="column" *ngIf="!officetoggle">
                  <mat-toolbar color="primary" class="titlebox">
                    <h2 *ngIf="selectedCall.title">{{selectedCall.title | uppercase | slice:0:30 }}</h2>
                    <button mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menucall="matMenu">
                      <button mat-menu-item (click)="openDialogDeleteCall()">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Delete</span>
                      </button>
                    </mat-menu>
                  </mat-toolbar>
                </div>
                <div fxLayout="row" fxLayout.xs="column" *ngIf="officetoggle">
                  <mat-toolbar color="primary" class="titlebox">
                    <h2>{{selectedOfficeMail.subject | uppercase | slice:0:30 }}</h2>
                    <button mat-icon-button [matMenuTriggerFor]="menucall" class="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menucall="matMenu">
                      <button mat-menu-item (click)="openDialogDeleteOfficeMail()">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Delete</span>
                      </button>
                    </mat-menu>
                  </mat-toolbar>
                </div>
                <div *ngIf="!officetoggle">
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field width="100%">
                      <input matInput [(ngModel)]=selectedCall.title i18n-placeholder placeholder="Title">
                    </mat-form-field>
                  </div>
                  <div>
                    {{selectedCall.email}}
                  </div>
                  <div>
                    <mat-datepicker #picker1></mat-datepicker>
                    <input [matDatepicker]="myDatepicker1" [(ngModel)]=selectedCall.date i18n-placeholder placeholder="Call Date">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker1></mat-datepicker>
                  </div>
                  <br>
                  <label i18n>Attachments</label>
                  <div *ngFor="let file of Files">
                    <label (click)="download(file)">{{file.name}}</label>
                  </div>
                  <br>
                  <div *ngIf="!selectedCall.html" class="emailtext">
                    <div> {{selectedCall.content}} </div>
                  </div>
                  <div *ngIf="selectedCall.html" class="emailtext">
                    <div [innerHTML]="selectedCall.html | safeHtml"></div>
                  </div>
                </div>
                <!-- <div *ngIf="!officetoggle">
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field width="100%">
                      <input matInput [(ngModel)]=selectedCall.title i18n-placeholder placeholder="Title" >
                    </mat-form-field>

                  </div>
                  <div>
                    {{selectedCall.email}}
                  </div>
                  <div>
                    <mat-datepicker #picker1></mat-datepicker>
                    <input [matDatepicker]="myDatepicker1" [(ngModel)]=selectedCall.date i18n-placeholder placeholder="Call Date">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker1></mat-datepicker>
                  </div>
                  <br>
                  <label>Attachments</label>
                  <div *ngFor="let file of Files">
                    <label (click)="download(file)">{{file.name}}</label>
                  </div>
                  <br>
                  <div *ngIf="!selectedCall.html" class="emailtext">
                    <div> {{selectedCall.content}} </div>
                  </div>
                  <div *ngIf="selectedCall.html" class="emailtext">
                    <div [innerHTML]="selectedCall.html | safeHtml"></div>
                  </div>
                </div> -->
                <div *ngIf="officetoggle">
                  <div fxLayout="row" style="width: 100%;">
                    <mat-form-field style="width: 100%;">
                      <input matInput [(ngModel)]=selectedOfficeMail.subject i18n-placeholder placeholder="Title" style="width: 100%;">
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" *ngIf="selectedOfficeMail.sender.emailAddress" fxLayoutGap="20px">
                    <label
                      *ngIf="selectedOfficeMail.sender.emailAddress.name">{{selectedOfficeMail.sender.emailAddress.name}}</label>
                    <label
                      *ngIf="selectedOfficeMail.sender.emailAddress.address">{{selectedOfficeMail.sender.emailAddress.address}}</label>
                  </div>
                  <div>
                    <mat-datepicker #picker1></mat-datepicker>
                    <input [matDatepicker]="myDatepicker2" [(ngModel)]=selectedOfficeMail.receivedDateTime
                      i18n-placeholder placeholder="Call Date">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker2></mat-datepicker>
                  </div>
                  <br>
                  <label i18n>Attachments</label>
                  <!-- <div *ngFor="let file of Files">
                      <label (click)="download(file)">{{file.name}}</label>
                    </div> -->
                  <br>
                  <div *ngIf="selectedOfficeMail.body.contentType !== 'html'" class="emailtext">
                    <div> {{selectedOfficeMail.body.content}} </div>
                  </div>
                  <div *ngIf="selectedOfficeMail.body.contentType === 'html'" class="emailtext">
                    <iframe style="width: 100%; height: 100vh;" srcdoc={{selectedOfficeMail.body.content}}
                      title="description">
                      <!-- <div [innerHTML]="selectedOfficeMail.body.content | safeHtml"></div> -->
                    </iframe>

                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Companies">

        <mat-card style="margin: 10px;">
          <mat-card-content>
            <h3 i18n>Result companies found</h3>
            <br>
            <button mat-raised-button (click)="findCompanies()">
              <mat-icon>search</mat-icon>
              <label i18n>Find companies</label>
            </button>
            <br>
            <div fxLayout="row">
              <mat-spinner *ngIf="searchunsortedcompanies" mode="indeterminate" style="margin: 20px">
              </mat-spinner>
              <label i18n>Searching Companies this might take a while..</label>
            </div>

            <br>
            <div *ngIf="unsortedCompanies">
              <div fxLayout="column" *ngFor="let com of unsortedCompanies; let i = index">
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="left center" style="width: 100%;">
                  <mat-checkbox fxFlex="5%" [(ngModel)]="com.import"></mat-checkbox>
                  <!-- <mat-checkbox fxFlex="5%" [(ngModel)]="com.import">import related</mat-checkbox> -->
                  <label fxFlex="30%">{{com.company}}</label>
                  <label fxFlex="20%">{{com.address}}</label>
                  <label fxFlex="10%">{{com.city}}</label>
                  <label fxFlex="5%">{{com.stateprovince}}</label>
                  <label fxFlex="10%">{{com.country}}</label>
                  <label fxFlex="10%">{{com.email}}</label>
                  <label fxFlex="10%">{{com.website}}</label>
                </div>
              </div>
              <br>
              <button mat-raised-button (click)="importUnsortedCompanies()">
                <mat-icon>publish</mat-icon>
                <label i18n>Import selection</label>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </mat-tab>

  <mat-tab label="Upload to CRM">
    <ng-template mat-tab-label>
      <mat-icon>cloud_upload</mat-icon>
      <label i18n>Upload to CRM</label>
    </ng-template>

    <app-uploadcrm [Account]="Account" [Company]="Company">
    </app-uploadcrm>
  </mat-tab>

  <mat-tab label="Invoices" (click)="getInvoices()">
    <ng-template mat-tab-label>
      <mat-icon>money</mat-icon>
      <label i18n>Invoices</label>
    </ng-template>
    <div>
      <button mat-button (click)="getInvoices()" i18n>Load Invoices</button>
      <div *ngFor="let file of files; let i = index;" class="invoicelabel">
        <mat-icon>money</mat-icon>
        <label class="invoicelabel">{{file.Key}}</label>
        <mat-icon (click)="getFile(i)">cloud_download</mat-icon>
      </div>
    </div>
    <br>
    
  </mat-tab>

  <mat-tab label="System">
    <ng-template mat-tab-label>
      <mat-icon>system</mat-icon>
      <label i18n>System Logs</label>
    </ng-template>
    <button (click)="deleteAllLogs()" mat-raised-button i18n>Delete All</button>
    <mat-list style="min-height: 400px; width: 100%; margin: 10px">
      <h3 mat-subheader i18n>Log Messages</h3>
      <mat-list-item *ngFor="let log of logs">
        <div style="margin: 0px 5px 0px 5px" fxFlex="15%">{{log.date | date: "medium" }}</div>
        <div style="margin: 0px 5px 0px 5px" fxFlex="35%">{{log.description}}</div>
        <div style="margin: 0px 5px 0px 5px" fxFlex="25%">{{log.item}}</div>
        <div style="margin: 0px 5px 0px 5px" fxFlex="25%">{{log.code}}</div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-tab>

</mat-tab-group>