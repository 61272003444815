
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ContainerApi, Files, Relations, RelationsApi, Company, Account, FilesApi } from '../sdk';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { StockBackgrounds } from './stockbackgrounds'
import { templatescreenshots, templates } from './stocktemplates';
import { BASE_URL, API_VERSION } from '../base.api'

export interface DialogData {
  img;
  selected;
  storedtemplate;
  fromfile;
}

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  public Files: Files[];
  public newFiles: Files = new Files();
  public showdropbox = true;
  public showgallery = false;
  public selectedbackground;
  public backgrounds = [];
  public images = [];
  public stored = [];

  @Input('option') option: Relations; //get id for image gallery
  @Input('account') account: Account;
  @Output() seteditvideo = new EventEmitter();
  @Output() imgurl = new EventEmitter(); //send url img back
  // @Input('stored') stored = [];
  @Input('files') files: Files;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public ContainerApi: ContainerApi,
    public relationsApi: RelationsApi,
    public fileApi: FilesApi
  ) { }

  ngOnInit() {

  }

  onOpenGallery() {
    this.backgrounds = [];
    this.images = [];
    this.stored = [];
    // , {fields: {name: true, id: true, url: true, screenshot: true,}}
    this.relationsApi.getFiles(this.option.id, {
      fields: { template: false }
      //{id: true, name: true, relationsId: true, companyId: true, url: true, type: true}
    }).subscribe((files: Files[]) => {
      this.Files = files,
        this.Files.forEach((file, index) => {
          // console.log(file, index);
          let ext = file.name.split('.').pop();
          if (file.type === 'video-scene') {
            // const modalImage = file.url;
            // const thumb = file.thumb
            // this.fromfile.push({image: modalImage, thumb: thumb})
            this.stored.push(file)
          } else if (ext === 'gif' || ext === "jpeg" || ext === "jpg" || ext === "bmp" || ext === "png") {
            const modalImage = BASE_URL + '/api/Containers/' + this.option.id + '/download/' + file.name;
            this.images.push({ url: modalImage })
          }

          // if (file.counter && file.type === 'video'){
          //   this.stored.push(file)
          // }
        })

      //console.log(this.stored)
      const dialogRef = this.dialog.open(dialogbackgroundgallerycomponent, {
        width: '600px',
        data: { img: this.backgrounds, selected: this.selectedbackground, storedtemplate: this.stored, fromfile: this.images }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.animal = result;

        if (result) {
          if (result.id) {
            this.setVideo(result.id)
          }
          else {
            this.setimage(result.url)
          }
        }
      });
    });
  }

  setVideo(id: string) {
    console.log(id);
    this.showdropbox = false;
    this.showgallery = false;
    this.seteditvideo.emit(id);
  }

  setimage(url) {
    this.showdropbox = false;
    this.showgallery = false;
    this.imgurl.emit(url);
  }
}

@Component({
  selector: 'dialog-backgroundgallery',
  templateUrl: 'dialog-backgroundgallery.html',
  styleUrls: ['./background.component.scss']
})

export class dialogbackgroundgallerycomponent implements OnInit {

  //public fileVideo = StockVideo;
  //public existingIcons = [];
  public templatesSet = templatescreenshots;
  public templates = [];
  public templatesArray = [];
  public templatesSlice = 12;
  public templatesSliceMin = 1;

  public backgroundSet = StockBackgrounds;
  public stockbg = [];
  public stockbgArray = [];
  public stockbgSlice = 12;
  public stockbgSliceMin = 1;

  public storedset = this.data.storedtemplate;
  public stored = [];
  public storedArray = [];
  public storedSlice = 12;
  public storedSliceMin = 1;

  //public fromfileset = this.data.fromfile;
  public fromfile = [];
  public fromfileArray = [];
  public fromfileSlice = 12;
  public fromfileSliceMin = 1;

  public URL = 'http://localhost:3000/api/containers/tmp/upload';

  constructor(
    public dialogRef: MatDialogRef<dialogbackgroundgallerycomponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.storedArray = [];
    this.templatesArray = [];
    this.stockbgArray = [];

    this.backgroundSet.forEach((element, index) => {
      const url = element;
      var filename = url.replace(/^.*[\\\/]/, '')
    

      const exten = filename.split('.').pop();
      let thumbname = url.replace('.' + exten, '') + '-thumb.' + exten;
      if (index < 12) { this.stockbg.push({ url: url, name: filename, thumb: thumbname  }); }
      this.stockbgArray.push({ url: url, name: filename, thumb: thumbname });
    });

    this.templatesSet.forEach((element, index) => {
      const url = element;
      var filename = url.replace(/^.*[\\\/]/, '')
      if (index < 12) { this.templates.push({ url: url, name: filename }); }
      this.templatesArray.push({ url: url, name: filename });
    });

    this.storedset.forEach((element, index) => {
      // !!! using the entire file object
      if (index < 12) { this.stored.push(element) }
      this.storedArray.push(element);
    });
    // this.data.fromfile.forEach((element, index) => {
    //   const url = element.screenshot;
    //   if(index < 12){this.stored.push({ url: url, name: element.name, id: element.id });}
    //   this.storedArray.push({ url: url, name: element.name, id: element.id });
    // });
    // console.log(this.storedArray,  this.storedset, this.data.fromfile)
  }

  next(galtype) {
    switch (galtype) {
      case 'templates': {
        if (this.templatesSlice < this.templatesArray.length - 1) {
          this.templatesSlice = this.templatesSlice + 13;
          this.templatesSliceMin = this.templatesSliceMin + 13;
          this.templates = this.templatesArray.slice(this.templatesSliceMin-1, this.templatesSlice)
        }
      }
      case 'standardbackgrounds': {
        if (this.stockbgSlice < this.stockbgArray.length - 1) {
          this.stockbgSlice = this.stockbgSlice + 13;
          this.stockbgSliceMin = this.stockbgSliceMin + 13;
          this.stockbg = this.stockbgArray.slice(this.stockbgSliceMin-1, this.stockbgSlice)
        }
      }
      case 'stored': {
        if (this.storedSlice < this.storedArray.length - 1) {
          this.storedSlice = this.storedSlice + 13;
          this.storedSliceMin = this.storedSliceMin + 13;
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }
      case 'fromfile': {
        if (this.fromfileSlice < this.fromfileArray.length - 1) {
          this.fromfileSlice = this.fromfileSlice + 13;
          this.fromfileSliceMin = this.fromfileSliceMin + 13
          this.fromfile = this.fromfileArray.slice(this.fromfileSliceMin-1, this.fromfileSlice)
        }
      }
    }
  }

  before(galtype) {
    switch (galtype) {
      case 'templates': {
        if (this.templatesSliceMin > 1) {
          this.templatesSlice = this.templatesSlice - 13;
          this.templatesSliceMin = this.templatesSliceMin - 13;
          this.templates = this.templatesArray.slice(this.templatesSliceMin-1, this.templatesSlice)

        }
      }
      case 'standardbackgrounds': {
        if (this.stockbgSliceMin > 1) {
          this.stockbgSlice = this.stockbgSlice - 13;
          this.stockbgSliceMin = this.stockbgSliceMin - 13;
          this.stockbg = this.stockbgArray.slice(this.stockbgSliceMin-1, this.stockbgSlice)

        }
      }
      case 'stored': {
        if (this.storedSliceMin > 1) {
          this.storedSlice = this.storedSlice - 13;
          this.storedSliceMin = this.storedSliceMin - 13;
          this.stored = this.storedArray.slice(this.storedSliceMin-1, this.storedSlice)
        }
      }
      case 'fromfile': {
        if (this.fromfileSliceMin > 1) {
          this.fromfileSlice = this.fromfileSlice - 12;
          this.fromfileSliceMin = this.fromfileSliceMin - 13;
          this.fromfile = this.fromfileArray.slice(this.fromfileSliceMin-1, this.fromfileSlice)
        }
      }
    }
  }


  onNoClick(): void {
    this.data.selected = '';
    this.dialogRef.close();
  }

  selectedimage(img): void {
    this.data.selected = img;
    //console.log(img)
  }

}