import { Injectable, ViewChild } from '@angular/core';
import { GuidedTour, GuidedTourService, OrientationConfiguration, TourStep } from 'ngx-guided-tour';
import { AppComponent } from '../../app.component';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor() { }

  public toursteps: TourStep[] = [{
    title: 'Start Tour',
    content: 'Start Tour',
    scrollAdjustment: 100
    // orientation: this.orientationConfiguration,
  },
  {
    selector: '#mainmenubutton',
    title: 'Check your account',
    content: 'find all info',
    orientation: 'right',
  }
  ]

  // @ViewChild(AppComponent, { static: false })
  // private appComponent:AppComponent;
  public Tour: GuidedTour = {
    /** Identifier for tour */
    tourId: 'tour1',
    /** Use orb to start tour */
    useOrb: false,
    /** Steps for the tour */
    steps: this.toursteps,
    /** Function will be called when tour is skipped */
    skipCallback: (stepSkippedOn: number) => { 
      //this.appComponent.noWalkthrough();
    },
    /** Function will be called when tour is completed */
    completeCallback: () => {

     },
    /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
    // minimumScreenSize: 400,
    /** Dialog shown if the window width is smaller than the defined minimum screen size. */
    resizeDialog: {
      /** Resize dialog title text */
      title: 'Screen to small ',
      /** Resize dialog text */
      content: 'content'
    }
  }

}
