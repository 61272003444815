import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AccountApi,
  Account,
  Company,
  CompanyApi
} from '../shared/sdk';
import { DialogsService } from './../dialogsservice/dialogs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TwofactorwindowComponent } from './twofactorwindow/twofactorwindow.component';
import { Plansubscription } from './register/register.component';
import { BASE_URL } from '../shared/base.api';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AppserviceService } from '../appservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  languages = [
    { code: 'en', label: 'English' },
    { code: 'nl', label: 'Nederlands' },
    { code: 'fr', label: 'Française' },
    { code: 'de', label: 'Deutsch' }
  ];
  selectedlanguage: string;
  public Company: Company = new Company();
  public Account: Account = new Account();
  public rememberMe: boolean = true;
  public errorMessage = '';
  public responsemessage = '';
  public terms = 'monthly';
  error = false;
  response = false;

  logininfo;
  responsecount: number;
  public selectedOption = false;
  public resetEmailAddress: string;
  public showconfirmation = false;
  public urlparameter: string;
  passwordstrenght = 0;
  registerbutton = false;
  detectchange = true;
  idres: any;
  formstate: string = 'login';
  showpasswordinfo = false;

  plan: Plansubscription;
  public company: Company = new Company();
  uid: string;
  paymentrecover: string;

  constructor(
    public appservice: AppserviceService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public companyApi: CompanyApi,
    public dialogsService: DialogsService,
    public route: ActivatedRoute,
    public router: Router,
    public accountApi: AccountApi) {
  }

  ngOnInit(): void {
    this.urlparameter = this.route.snapshot.queryParamMap.get("id");
    if (this.urlparameter) {
      this.showconfirmation = true;
    }

    this.uid = this.route.snapshot.queryParamMap.get("id");
    let token = this.route.snapshot.queryParamMap.get("token");
    const selectplan = this.route.snapshot.queryParamMap.get("selectplan");
    this.paymentrecover = this.route.snapshot.queryParamMap.get("paymentrecover");
    // /login?id=646395470c402bf266eb0229&paymentrecover=true

    if (selectplan) {
      this.formstate = 'selectplan';
      this.error = false;
      this.response = false;
      // this.flipchange();
    }

    if (this.uid && token) {
      this.accountApi.confirm(this.uid, token).subscribe(res => {
        console.log(res);
      })
    }

   
    if (this.paymentrecover && this.uid){
      console.log(this.paymentrecover, this.uid);
      this.registertoggleform();
      this.recoverAccount();

    }
  }

  snackbarMessage(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 4000,
      panelClass: 'snackbar-class'
    });
  }

  recoverAccount(){
    this.accountApi.resumePayment(this.uid).subscribe((account: Account) => {
      this.registerbutton = true;
      this.Account = account;
      this.company.companyname = this.Account.companyname;
      console.log(this.Account);
    })
  }

  resetPassword() {
    this.accountApi.resetPassword({ email: this.resetEmailAddress }).subscribe();
    this.formstate = 'resetpassword';
    // this.logintoggle = true;

    this.snackbarMessage('Reset Email send');
  }

  onStrengthChanged(e) {
    this.passwordstrenght = e;
    this.checkall();
  }x

  checkall() {
    if (this.passwordstrenght > 60) {
      this.registerbutton = true;
    } else { this.registerbutton = false }
  }

  confirmationHook(id) {
    console.log(id)
    this.accountApi.confirmationhook(id, this.Account).subscribe(id => {
      this.Account.companyId = id;
    });
  }

  createAccount() {
    this.accountApi.create(this.Account)
      .subscribe((account: Account) => {
        //console.log(account)
      },
        error => {
          this.errorMessage = error.message;
          this.error = true;
          this.formstate = 'login';
        }
      );
  }


  login(): void {
    this.recaptchaV3Service.execute('sendForm')
      .subscribe((token: string) => {

              this.accountApi.login(this.Account, token, this.rememberMe)
                .subscribe(res => {
                  this.finalLogin(res)
                }, error => {
                  console.log(error.message);
                  if (error.message.indexOf('Two factor authentication required for ') !== -1) {
                    console.log('Two factor authentication required for ', '');
                    this.idres = error.message.replace()
                    this.open2F(this.idres);
                  } else if (error.message.indexOf('Wrong two factor authentication code') !== -1) {
                    this.snackbarMessage('Wrong two factor authentication code please try again');
                    this.open2F(this.idres);
                  } else if (error.message.indexOf('Payment Required') !== -1){
                    this.snackbarMessage('Payment required please presume registration!');
                    this.registertoggleform();
                    this.uid = error.message.replace('Payment Required:', '');
                    this.recoverAccount();
                    console.log(this.uid);
                    
                    } else {
                    this.errorMessage = error.message,
                      this.snackbarMessage(this.errorMessage);
                  }

                });
        })
  }

  open2F(id) {
    const dialogRef = this.dialog.open(TwofactorwindowComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
      if (result) {
        this.Account.two_factor_temp_secret = result;
        this.login();
      }
    });


  }

  finalLogin(res) {
    this.appservice.getCurrentUserInfo(); // trigger again as appservice is triggered right after app start
    this.logininfo = res.user;
    this.router.navigate(['/dashboard']);
  }

  // //dialog for confirming create new company
  // checkregistercompany(): void {
  //   this.dialogsService
  //     .confirm('A Company has not yet been created', 'Do you want to create a new Company?')
  //     .subscribe(res => {
  //       this.selectedOption = res, this.registercompany(this.selectedOption);
  //     });
  // };

  // //register new company and add id to account - delete moved to server
  // registercompany(selectedOption): void {
  //   if (selectedOption === true) {
  //     console.log(this.logininfo.id);
  //     this.accountApi.createCompany(this.logininfo.id, { companyname: this.logininfo.companyname })
  //       .subscribe((Company: Company) => {
  //         this.Company = Company,
  //           console.log(this.Company),
  //           //this.Account.companyId = this.Company.id,
  //           this.accountApi.patchAttributes(this.logininfo.id, { companyId: this.Company.id })
  //             .subscribe(res =>
  //               this.router.navigate(['/dashboard'])
  //             );
  //       });
  //   }
  // }

  logout(): void {
    this.accountApi.logout().subscribe();
    this.router.navigate(['/'])
  }

  registertoggleform(): void {
    this.formstate = 'selectplan';
  }

  backtoggle(): void {
    if (this.formstate === 'selectplan') {
      this.formstate = 'login'
    }
    if (this.formstate === 'register') {
      this.formstate = 'selectplan'
    }
  }

  setPlan(plan) {
    this.plan = plan;
    this.formstate = 'register'
  }


  setBack() {
    this.formstate = 'login';
  }

  async opendialogconfirmpayment() {
    //const amount = this.priceCalculator(),

    if (!this.Account) {
      console.log('missing Account!');
      this.snackbarMessage('missing Account!'); return;
    }

    let transsubid = Math.floor(Math.random() * 100) + 1,
      date = Math.round(new Date().getTime() / 1000),
      transid = 'IXP' + date + '-' + transsubid;


    this.dialogsService
      .confirm('Request Payment', 'Total Monthly : ' + this.plan.selectedcurrency + ' ' + this.plan.monthly +
        ' You will be redirected to the payment page, to approve payment')
      .subscribe(async res => {

        if (res) {
          let checkaccount;
          if(!this.Account.id){
            checkaccount = await this.register();
            console.log(checkaccount)
          } else {
            checkaccount = this.Account;
          }

          if (!checkaccount) { console.log('missing account info'); return }

          this.accountApi.getpayment(
            checkaccount,
            this.company,
            transid,
            this.plan.selectedcurrency,
            this.plan.selectedplan,
            this.plan.terms,
            this.plan.trainingsupport,
            this.plan.migrationsupport,
            this.plan.emailcount,
            this.plan.additionalusers,
            this.plan.additionalmarketingrelationaccounts,
            this.plan.discountcode
            //this.extramarketingrelationaccounts,
          )
            .subscribe((url: string) => {
              if (url) { window.open(url, '_self') }
              // returns to account confirm payment is receivd and activy account through email
            });
        }
      });
  }


  async register(): Promise<Account> {
    return new Promise(async (resolve, reject) => {
    
      if ( this.Account.username && this.company.companyname && this.Account.email && this.Account.password) {
        this.Account.companyname = this.company.companyname;
        this.accountApi.create(this.Account)
          .subscribe((account: Account) => {
            resolve(account);
          }, error => {
            this.snackbarMessage(error.message);
            resolve(null)
          });
      } else {
        this.snackbarMessage('Check fields, missing info');
        resolve(null);
      }
    });
  }

  // sendToken(token: string) {
  //   this.accountApi.tokenvalidate(null, token).subscribe(res => {
  //     return(res)
  //   })
  //   //return this.http.post<any>(BASE_URL + "/api/accounts/tokenvalidate", { recaptcha: token })
  // }

  selectOtherLanguage() {
    if (window.location.hostname !== 'localhost') { // for testing
      window.location.href = BASE_URL + '/' + this.selectedlanguage;
      window.localStorage.setItem(
        'enydea-language', this.selectedlanguage
      );
    }
  }

  setLanguage() {
    this.selectedlanguage = localStorage.getItem('enydea-language');
    if (window.location.hostname !== 'localhost') { // for testing
      if (this.selectedlanguage && this.selectedlanguage !== 'en') {
        let url = BASE_URL + '/' + this.selectedlanguage;
        window.location.href = url;
      }
    };

  }


  setAddress(addrObj) {
   // console.log(addrObj);
    if (addrObj.route !== undefined) {
      if (addrObj.street_number) {
        this.company.address = addrObj.route + " " + addrObj.street_number;
      } else {
        this.company.address = addrObj.route;
      }
      //this.company.address1 = addrObj.route + " " + addrObj.street_number;
    } else {
      this.company.address = '';
    }
    if (addrObj.phone !== undefined && !this.company.phonenumber) {
      this.company.phonenumber = addrObj.phone;
    } else {
      this.company.phonenumber = '';
    }
    if (addrObj.country !== undefined) {
      this.company.country = addrObj.country;
    } else {
      this.company.country = '';
    }
    if (addrObj.locality !== undefined) {
      this.company.city = addrObj.locality;
    } else {
      this.company.city = '';
    }
    if (addrObj.admin_area_l1 !== undefined) {
      this.company.stateprov = addrObj.admin_area_l1;
    } else {
      this.company.stateprov = '';
    }
    if (addrObj.postal_code !== undefined) {
      this.company.zipcode = addrObj.postal_code;
    } else {
      this.company.zipcode = '';
    }

   // this.showAddress();
  }

  setNameAddress(addrObj) {
    // console.log(addrObj);
     if (addrObj.name !== undefined) {
       this.company.companyname = addrObj.name;
     }
     if (addrObj.route !== undefined) {
 
       if (addrObj.street_number) {
         this.company.address = addrObj.route + " " + addrObj.street_number;
       } else {
         this.company.address = addrObj.route;
       }
       //this.company.address1 = addrObj.route + " " + addrObj.street_number;
     } else {
       this.company.address = '';
     }
     if (addrObj.phone !== undefined && !this.company.phonenumber) {
       this.company.phonenumber = addrObj.phone;
     } else {
       this.company.phonenumber = '';
     }
     if (addrObj.country !== undefined) {
       this.company.country = addrObj.country;
     } else {
       this.company.country = '';
     }
     if (addrObj.locality !== undefined) {
       this.company.city = addrObj.locality;
     } else {
       this.company.city = '';
     }
     if (addrObj.admin_area_l1 !== undefined) {
       this.company.stateprov = addrObj.admin_area_l1;
     } else {
       this.company.stateprov = '';
     }
     if (addrObj.postal_code !== undefined) {
       this.company.zipcode = addrObj.postal_code;
     } else {
       this.company.zipcode = '';
     }
 
    // this.showAddress();
   }

  setCity(addrObj) {
    if (addrObj.locality !== undefined) {
      this.company.city = addrObj.locality;
    }
  }

  setCountry(addrObj) {
    if (addrObj.country !== undefined) {
      this.company.country = addrObj.country;
    }
  }


}
