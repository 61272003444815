/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface PushsubscriptionsInterface {
  "endpoint"?: string;
  "expirationTime"?: number;
  "keys"?: any;
  "options"?: any;
  "browserinfo"?: string;
  "id"?: any;
  "accountId"?: any;
  account?: Account;
}

export class Pushsubscriptions implements PushsubscriptionsInterface {
  "endpoint": string;
  "expirationTime": number;
  "keys": any;
  "options": any;
  "browserinfo": string;
  "id": any;
  "accountId": any;
  account: Account;
  constructor(data?: PushsubscriptionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Pushsubscriptions`.
   */
  public static getModelName() {
    return "Pushsubscriptions";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Pushsubscriptions for dynamic purposes.
  **/
  public static factory(data: PushsubscriptionsInterface): Pushsubscriptions{
    return new Pushsubscriptions(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Pushsubscriptions',
      plural: 'Pushsubscriptions',
      path: 'Pushsubscriptions',
      idName: 'id',
      properties: {
        "endpoint": {
          name: 'endpoint',
          type: 'string'
        },
        "expirationTime": {
          name: 'expirationTime',
          type: 'number'
        },
        "keys": {
          name: 'keys',
          type: 'any'
        },
        "options": {
          name: 'options',
          type: 'any'
        },
        "browserinfo": {
          name: 'browserinfo',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
