/* tslint:disable */
import {
  Chats,
  Chatmessages
} from '../index';

declare var Object: any;
export interface ChatroomsInterface {
  "name"?: string;
  "screenname"?: string;
  "attendee"?: any;
  "date": Date;
  "IP"?: string;
  "url"?: string;
  "companyname"?: string;
  "hostname"?: string;
  "city"?: string;
  "region"?: string;
  "country"?: string;
  "loc"?: string;
  "org"?: string;
  "postal"?: string;
  "street"?: string;
  "lat"?: string;
  "lon"?: string;
  "ispname"?: string;
  "isp"?: boolean;
  "formcontent"?: any;
  "id"?: any;
  "chatId"?: any;
  "chatsId"?: any;
  chat?: Chats;
  chatmessages?: Chatmessages[];
}

export class Chatrooms implements ChatroomsInterface {
  "name": string;
  "screenname": string;
  "attendee": any;
  "date": Date;
  "IP": string;
  "url": string;
  "companyname": string;
  "hostname": string;
  "city": string;
  "region": string;
  "country": string;
  "loc": string;
  "org": string;
  "postal": string;
  "street": string;
  "lat": string;
  "lon": string;
  "ispname": string;
  "isp": boolean;
  "formcontent": any;
  "id": any;
  "chatId": any;
  "chatsId": any;
  chat: Chats;
  chatmessages: Chatmessages[];
  constructor(data?: ChatroomsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chatrooms`.
   */
  public static getModelName() {
    return "Chatrooms";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Chatrooms for dynamic purposes.
  **/
  public static factory(data: ChatroomsInterface): Chatrooms{
    return new Chatrooms(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Chatrooms',
      plural: 'Chatrooms',
      path: 'Chatrooms',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "screenname": {
          name: 'screenname',
          type: 'string'
        },
        "attendee": {
          name: 'attendee',
          type: 'any'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "IP": {
          name: 'IP',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "companyname": {
          name: 'companyname',
          type: 'string'
        },
        "hostname": {
          name: 'hostname',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "region": {
          name: 'region',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "loc": {
          name: 'loc',
          type: 'string'
        },
        "org": {
          name: 'org',
          type: 'string'
        },
        "postal": {
          name: 'postal',
          type: 'string'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "lat": {
          name: 'lat',
          type: 'string'
        },
        "lon": {
          name: 'lon',
          type: 'string'
        },
        "ispname": {
          name: 'ispname',
          type: 'string'
        },
        "isp": {
          name: 'isp',
          type: 'boolean',
          default: false
        },
        "formcontent": {
          name: 'formcontent',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "chatId": {
          name: 'chatId',
          type: 'any'
        },
        "chatsId": {
          name: 'chatsId',
          type: 'any'
        },
      },
      relations: {
        chat: {
          name: 'chat',
          type: 'Chats',
          model: 'Chats',
          relationType: 'belongsTo',
                  keyFrom: 'chatId',
          keyTo: 'id'
        },
        chatmessages: {
          name: 'chatmessages',
          type: 'Chatmessages[]',
          model: 'Chatmessages',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chatroomsId'
        },
      }
    }
  }
}
