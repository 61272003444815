
import { BASE_URL } from '../../shared/base.api';

export const Icons = [
    BASE_URL + '/api/Containers/standardpng/download/laptop-computer-mockup.png',
    BASE_URL + '/api/Containers/standardpng/download/laptop-computer-side-mockup.png',
    BASE_URL + '/api/Containers/standardpng/download/tablet-landscape-mockup.png',
    BASE_URL + '/api/Containers/standardpng/download/phone-portrait-mockup.png',

    BASE_URL + '/api/Containers/standardiconcolourcircle/download/paper.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/envelope.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/mail.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/talk.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/calender.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/information.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/folder-document.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/folder.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/man.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/paper-plane.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/location.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/phone.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/clock.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/woman.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/education.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/paper-clip.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/check.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/stop.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/tick.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/link.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/curser.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/power.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/zoom-in.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/zoom-out.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/download-from-cloud.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/upload.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/cloud.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/cross.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/refresh-2.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/upload-to-cloud.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/star.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/refresh.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/news.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/mail.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/lock.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/screw-driver.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/strategy.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/speed.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/tag.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/presentation.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/chart.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/cog.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/tools.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/guarentee.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/brief-case.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/unlock.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/award.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/spanner.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/email.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/arrow-increase.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/direction.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/book.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/broswer-star.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/bill-board.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/light-bulb.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/target.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/promote.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/search.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/heart.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/pulse.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/shopping-basket.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/mountain.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/shopping-bag.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/globe-network.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/eye.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/pencil.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/idea.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/compass.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/display-board.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/browser.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/converstion.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/camera.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/code.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/line-graph.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/arrow-graph.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/bar-chart.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/chart-browser.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/ie-chart-2.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/pie-chart.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/pulse.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/structure.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/visual-date.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/filter.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/bar-graph.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/analytics.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/adjust.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/experiment.png',
    BASE_URL + '/api/Containers/standardiconcolourcircle/download/network.png',
    BASE_URL + '/api/Containers/standardicondark/download/paper.png',
    BASE_URL + '/api/Containers/standardicondark/download/folder-paper.png',
    BASE_URL + '/api/Containers/standardicondark/download/envelope.png',
    BASE_URL + '/api/Containers/standardicondark/download/mail.png',
    BASE_URL + '/api/Containers/standardicondark/download/calender.png',
    BASE_URL + '/api/Containers/standardicondark/download/information.png',
    BASE_URL + '/api/Containers/standardicondark/download/folder.png',
    BASE_URL + '/api/Containers/standardicondark/download/man.png',
    BASE_URL + '/api/Containers/standardicondark/download/pjone.png',
    BASE_URL + '/api/Containers/standardicondark/download/quote.png',
    BASE_URL + '/api/Containers/standardicondark/download/paper-plane.png',
    BASE_URL + '/api/Containers/standardicondark/download/clock.png',
    BASE_URL + '/api/Containers/standardicondark/download/woman.png',
    BASE_URL + '/api/Containers/standardicondark/download/education.png',
    BASE_URL + '/api/Containers/standardicondark/download/location-pin.png',
    BASE_URL + '/api/Containers/standardicondark/download/paper-clip.png',
    BASE_URL + '/api/Containers/standardicondark/download/stop.png',
    BASE_URL + '/api/Containers/standardicondark/download/tick.png',
    BASE_URL + '/api/Containers/standardicondark/download/on-off.png',
    BASE_URL + '/api/Containers/standardicondark/download/zoom-in.png',
    BASE_URL + '/api/Containers/standardicondark/download/zoom-out.png',
    BASE_URL + '/api/Containers/standardicondark/download/tick-check.png',
    BASE_URL + '/api/Containers/standardicondark/download/download-from-cloud.png',
    BASE_URL + '/api/Containers/standardicondark/download/upload.png',
    BASE_URL + '/api/Containers/standardicondark/download/cloud.png',
    BASE_URL + '/api/Containers/standardicondark/download/cross.png',
    BASE_URL + '/api/Containers/standardicondark/download/refresh-2.png',
    BASE_URL + '/api/Containers/standardicondark/download/upload-to-cloud.png',
    BASE_URL + '/api/Containers/standardicondark/download/links.png',
    BASE_URL + '/api/Containers/standardicondark/download/star.png',
    BASE_URL + '/api/Containers/standardicondark/download/arrow-curser.png',
    BASE_URL + '/api/Containers/standardicondark/download/refresh.png',
    BASE_URL + '/api/Containers/standardicondark/download/news.png',
    BASE_URL + '/api/Containers/standardicondark/download/mail.png',
    BASE_URL + '/api/Containers/standardicondark/download/lock.png',
    BASE_URL + '/api/Containers/standardicondark/download/screw-driver.png',
    BASE_URL + '/api/Containers/standardicondark/download/strategy.png',
    BASE_URL + '/api/Containers/standardicondark/download/speed.png',
    BASE_URL + '/api/Containers/standardicondark/download/tag.png',
    BASE_URL + '/api/Containers/standardicondark/download/presentation.png',
    BASE_URL + '/api/Containers/standardicondark/download/chart.png',
    BASE_URL + '/api/Containers/standardicondark/download/cog.png',
    BASE_URL + '/api/Containers/standardicondark/download/tools.png',
    BASE_URL + '/api/Containers/standardicondark/download/guarentee.png',
    BASE_URL + '/api/Containers/standardicondark/download/brief-case.png',
    BASE_URL + '/api/Containers/standardicondark/download/unlock.png',
    BASE_URL + '/api/Containers/standardicondark/download/award.png',
    BASE_URL + '/api/Containers/standardicondark/download/spanner.png',
    BASE_URL + '/api/Containers/standardicondark/download/email.png',
    BASE_URL + '/api/Containers/standardicondark/download/arrow-increase.png',
    BASE_URL + '/api/Containers/standardicondark/download/book.png',
    BASE_URL + '/api/Containers/standardicondark/download/sign-post.png',
    BASE_URL + '/api/Containers/standardicondark/download/browser-star.png',
    BASE_URL + '/api/Containers/standardicondark/download/bill-board.png',
    BASE_URL + '/api/Containers/standardicondark/download/light-bulb.png',
    BASE_URL + '/api/Containers/standardicondark/download/target.png',
    BASE_URL + '/api/Containers/standardicondark/download/search.png',
    BASE_URL + '/api/Containers/standardicondark/download/heart.png',
    BASE_URL + '/api/Containers/standardicondark/download/pulse.png',
    BASE_URL + '/api/Containers/standardicondark/download/shopping-basket.png',
    BASE_URL + '/api/Containers/standardicondark/download/mountains.png',
    BASE_URL + '/api/Containers/standardicondark/download/globe.png',
    BASE_URL + '/api/Containers/standardicondark/download/shopping-bag.png',
    BASE_URL + '/api/Containers/standardicondark/download/display.png',
    BASE_URL + '/api/Containers/standardicondark/download/conversation.png',
    BASE_URL + '/api/Containers/standardicondark/download/eye.png',
    BASE_URL + '/api/Containers/standardicondark/download/pencil.png',
    BASE_URL + '/api/Containers/standardicondark/download/idea.png',
    BASE_URL + '/api/Containers/standardicondark/download/compass.png',
    BASE_URL + '/api/Containers/standardicondark/download/browser.png',
    BASE_URL + '/api/Containers/standardicondark/download/camera.png',
    BASE_URL + '/api/Containers/standardicondark/download/pie-chart-2.png',
    BASE_URL + '/api/Containers/standardicondark/download/code.png',
    BASE_URL + '/api/Containers/standardicondark/download/line-graph.png',
    BASE_URL + '/api/Containers/standardicondark/download/data-graph.png',
    BASE_URL + '/api/Containers/standardicondark/download/arrow-graph.png',
    BASE_URL + '/api/Containers/standardicondark/download/bar-chart.png',
    BASE_URL + '/api/Containers/standardicondark/download/pie-chart.png',
    BASE_URL + '/api/Containers/standardicondark/download/pulse.png',
    BASE_URL + '/api/Containers/standardicondark/download/visual-data.png',
    BASE_URL + '/api/Containers/standardicondark/download/structure.png',
    BASE_URL + '/api/Containers/standardicondark/download/filter.png',
    BASE_URL + '/api/Containers/standardicondark/download/browser-graph.png',
    BASE_URL + '/api/Containers/standardicondark/download/analytics.png',
    BASE_URL + '/api/Containers/standardicondark/download/adjust.png',
    BASE_URL + '/api/Containers/standardicondark/download/experiment.png',
    BASE_URL + '/api/Containers/standardicondark/download/network.png',
    BASE_URL + '/api/Containers//standardiconlight/download/paper.png',
    BASE_URL + '/api/Containers//standardiconlight/download/folder-paper.png',
    BASE_URL + '/api/Containers//standardiconlight/download/envelope.png',
    BASE_URL + '/api/Containers//standardiconlight/download/mail.png',
    BASE_URL + '/api/Containers//standardiconlight/download/talk.png',
    BASE_URL + '/api/Containers//standardiconlight/download/calender.png',
    BASE_URL + '/api/Containers//standardiconlight/download/information.png',
    BASE_URL + '/api/Containers//standardiconlight/download/folder.png',
    BASE_URL + '/api/Containers//standardiconlight/download/man.png',
    BASE_URL + '/api/Containers//standardiconlight/download/paper-plane.png',
    BASE_URL + '/api/Containers//standardiconlight/download/location.png',
    BASE_URL + '/api/Containers//standardiconlight/download/phone.png',
    BASE_URL + '/api/Containers//standardiconlight/download/clock.png',
    BASE_URL + '/api/Containers//standardiconlight/download/woman.png',
    BASE_URL + '/api/Containers//standardiconlight/download/education.png',
    BASE_URL + '/api/Containers//standardiconlight/download/paper-clip.png',
    BASE_URL + '/api/Containers//standardiconlight/download/check.png',
    BASE_URL + '/api/Containers//standardiconlight/download/stop.png',
    BASE_URL + '/api/Containers//standardiconlight/download/tick.png',
    BASE_URL + '/api/Containers//standardiconlight/download/link.png',
    BASE_URL + '/api/Containers//standardiconlight/download/on-off.png',
    BASE_URL + '/api/Containers//standardiconlight/download/zoom-in.png',
    BASE_URL + '/api/Containers//standardiconlight/download/arrow.png',
    BASE_URL + '/api/Containers//standardiconlight/download/zoom-out.png',
    BASE_URL + '/api/Containers//standardiconlight/download/download-from-cloud.png',
    BASE_URL + '/api/Containers//standardiconlight/download/upload.png',
    BASE_URL + '/api/Containers//standardiconlight/download/cloud.png',
    BASE_URL + '/api/Containers//standardiconlight/download/cross.png',
    BASE_URL + '/api/Containers//standardiconlight/download/refresh-2.png',
    BASE_URL + '/api/Containers//standardiconlight/download/upload-to-cloud.png',
    BASE_URL + '/api/Containers//standardiconlight/download/favorite.png',
    BASE_URL + '/api/Containers//standardiconlight/download/refresh.png',
    BASE_URL + '/api/Containers//standardiconlight/download/news.png',
    BASE_URL + '/api/Containers//standardiconlight/download/email.png',
    BASE_URL + '/api/Containers//standardiconlight/download/screw-driver.png',
    BASE_URL + '/api/Containers//standardiconlight/download/strategy.png',
    BASE_URL + '/api/Containers//standardiconlight/download/speed.png',
    BASE_URL + '/api/Containers//standardiconlight/download/presentation.png',
    BASE_URL + '/api/Containers//standardiconlight/download/chart.png',
    BASE_URL + '/api/Containers//standardiconlight/download/cog.png',
    BASE_URL + '/api/Containers//standardiconlight/download/tools.png',
    BASE_URL + '/api/Containers//standardiconlight/download/price-tag.png',
    BASE_URL + '/api/Containers//standardiconlight/download/locked.png',
    BASE_URL + '/api/Containers//standardiconlight/download/guarentee.png',
    BASE_URL + '/api/Containers//standardiconlight/download/brief-case.png',
    BASE_URL + '/api/Containers//standardiconlight/download/unlocked.png',
    BASE_URL + '/api/Containers//standardiconlight/download/award.png',
    BASE_URL + '/api/Containers//standardiconlight/download/spanner.png',
    BASE_URL + '/api/Containers//standardiconlight/download/email.png',
    BASE_URL + '/api/Containers//standardiconlight/download/arrow-up.png',
    BASE_URL + '/api/Containers//standardiconlight/download/book.png',
    BASE_URL + '/api/Containers//standardiconlight/download/sign-post.png',
    BASE_URL + '/api/Containers//standardiconlight/download/browser-star.png',
    BASE_URL + '/api/Containers//standardiconlight/download/speaking.png',
    BASE_URL + '/api/Containers//standardiconlight/download/bill-board.png',
    BASE_URL + '/api/Containers//standardiconlight/download/light-bulb.png',
    BASE_URL + '/api/Containers//standardiconlight/download/target.png',
    BASE_URL + '/api/Containers//standardiconlight/download/megaphone.png',
    BASE_URL + '/api/Containers//standardiconlight/download/search.png',
    BASE_URL + '/api/Containers//standardiconlight/download/heart.png',
    BASE_URL + '/api/Containers//standardiconlight/download/basket.png',
    BASE_URL + '/api/Containers//standardiconlight/download/pulse.png',
    BASE_URL + '/api/Containers//standardiconlight/download/mountain.png',
    BASE_URL + '/api/Containers//standardiconlight/download/globe.png',
    BASE_URL + '/api/Containers//standardiconlight/download/shopping-bag.png',
    BASE_URL + '/api/Containers//standardiconlight/download/display.png',
    BASE_URL + '/api/Containers//standardiconlight/download/eye.png',
    BASE_URL + '/api/Containers//standardiconlight/download/pencil.png',
    BASE_URL + '/api/Containers//standardiconlight/download/idea.png',
    BASE_URL + '/api/Containers//standardiconlight/download/compass.png',
    BASE_URL + '/api/Containers//standardiconlight/download/browser.png',
    BASE_URL + '/api/Containers//standardiconlight/download/camera.png',
    BASE_URL + '/api/Containers//standardiconlight/download/pie-chart-2.png',
    BASE_URL + '/api/Containers//standardiconlight/download/code.png',
    BASE_URL + '/api/Containers//standardiconlight/download/line-graph.png',
    BASE_URL + '/api/Containers//standardiconlight/download/data-graph.png',
    BASE_URL + '/api/Containers//standardiconlight/download/arrow-graph.png',
    BASE_URL + '/api/Containers//standardiconlight/download/bar-chart.png',
    BASE_URL + '/api/Containers//standardiconlight/download/eye-pie-chart.png',
    BASE_URL + '/api/Containers//standardiconlight/download/pie-chart.png',
    BASE_URL + '/api/Containers//standardiconlight/download/pulse.png',
    BASE_URL + '/api/Containers//standardiconlight/download/structure.png',
    BASE_URL + '/api/Containers//standardiconlight/download/filter.png',
    BASE_URL + '/api/Containers//standardiconlight/download/analytics.png',
    BASE_URL + '/api/Containers//standardiconlight/download/graph-browser.png',
    BASE_URL + '/api/Containers//standardiconlight/download/adjust.png',
    BASE_URL + '/api/Containers//standardiconlight/download/experiment.png',
    BASE_URL + '/api/Containers//standardiconlight/download/network.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/document.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/envelope.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/mail.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/calender.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/information.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/folder.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/folder-documnet.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/man.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/paper-plane.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/location.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/phone.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/clock.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/woman.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/education.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/speech.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/paper-clip.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/stop.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/tick.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/link.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/on-off.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/curser.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/zoom-in.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/zoom-out.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/tick-check.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/download-from-cloud.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/upload.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/cloud.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/cross.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/refresh-2.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/upload-to-cloud.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/star.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/refresh.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/news.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/mail.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/screw-driver.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/strategy.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/speed.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/tag.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/presentation.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/cog.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/tools.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/locked.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/guarentee.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/brief-case.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/unlocked.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/award.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/spanner.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/email.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/arrow-increase.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/book.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/sign-post.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/browser-star.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/bill-board.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/light-bulb.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/target.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/promote.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/mauntain.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/search.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/heart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/pulse.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/shopping-basket.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/shopping-bag.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/globe-network.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/eye.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/pencil.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/idea.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/compass.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/display-board.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/browser.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/converstion.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/camera.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/pie-chart-2.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/colour-accent-set-128x128-37.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/code.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/bar-chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/browser-chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/pie-chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/visual-data.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/structure.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/filter.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/analytics.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/adjust.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/line-chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/experiment.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/data-chart.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/network.png',
    BASE_URL + '/api/Containers/standardiconcolouraccent/download/arrow-chart.png',
    BASE_URL + '/api/Containers/standardsvg/download/facebook.png',
    BASE_URL + '/api/Containers/standardsvg/download/xing.png',
    BASE_URL + '/api/Containers/standardsvg/download/instagram.png',
    BASE_URL + '/api/Containers/standardsvg/download/tumblr.png',
    BASE_URL + '/api/Containers/standardsvg/download/linkedin.png',
    BASE_URL + '/api/Containers/standardsvg/download/twitter.png',
    BASE_URL + '/api/Containers/standardsvg/download/github.png',
    BASE_URL + '/api/Containers/standardsvg/download/instagram.png',
    BASE_URL + '/api/Containers/standardsvg/download/web.png',
    BASE_URL + '/api/Containers/standardsvg/download/snapchat.png',
    BASE_URL + '/api/Containers/standardsvg/download/youtube.png',
    BASE_URL + '/api/Containers/standardsvg/download/vimeo.png',
    BASE_URL + '/api/Containers/standardicons/download/A.png',
    BASE_URL + '/api/Containers/standardicons/download/B.png',
    BASE_URL + '/api/Containers/standardicons/download/C.png',
    BASE_URL + '/api/Containers/standardicons/download/D.png',
    BASE_URL + '/api/Containers/standardicons/download/E.png',
    BASE_URL + '/api/Containers/standardicons/download/F.png',
    BASE_URL + '/api/Containers/standardicons/download/G.png',
    BASE_URL + '/api/Containers/standardicons/download/H.png',
    BASE_URL + '/api/Containers/standardicons/download/I.png',
    BASE_URL + '/api/Containers/standardicons/download/J.png',
    BASE_URL + '/api/Containers/standardicons/download/K.png',
    BASE_URL + '/api/Containers/standardicons/download/L.png',
    BASE_URL + '/api/Containers/standardicons/download/M.png',
    BASE_URL + '/api/Containers/standardicons/download/N.png',
    BASE_URL + '/api/Containers/standardicons/download/O.png',
    BASE_URL + '/api/Containers/standardicons/download/P.png',
    BASE_URL + '/api/Containers/standardicons/download/Q.png',
    BASE_URL + '/api/Containers/standardicons/download/R.png',
    BASE_URL + '/api/Containers/standardicons/download/S.png',
    BASE_URL + '/api/Containers/standardicons/download/T.png',
    BASE_URL + '/api/Containers/standardicons/download/U.png',
    BASE_URL + '/api/Containers/standardicons/download/V.png',
    BASE_URL + '/api/Containers/standardicons/download/W.png',
    BASE_URL + '/api/Containers/standardicons/download/X.png',
    BASE_URL + '/api/Containers/standardicons/download/Y.png',
    BASE_URL + '/api/Containers/standardicons/download/Z.png',
    BASE_URL + '/api/Containers/standardicons/download/0.png',
]

