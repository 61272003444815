export const environment = {
  production: true,
  BASE_URL: 'https://app.enydea.com',
  //BASE_URL: 'http://localhost:3000',
  API_VERSION: 'api',
  recaptcha: {
    siteKey: '6LearRkmAAAAAAIGBZtegkFEVzBQE-7QR2XQiLc8'
  }
};

