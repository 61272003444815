import { Injectable } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BASE_URL } from 'src/app/shared/base.api';
import { Files, RelationsApi } from 'src/app/shared/sdk';
import { GLTFExporter, GLTFExporterOptions } from 'three/examples/jsm/exporters/GLTFExporter.js';
import {
  chart, animationtype, vectoranimationtype, vectoranimation, vectorcombinator, vectorelement,
  splittexttype, shapeanimation, imageanimation, textanimation, counteranimation, countertype, formanimation,
  threedanimation, factoryThreedanimation, factoryThreedanimationcontrol, imageanimationFactory, shapeanimationFactory, factoryChart, factoryVectoranimation, factoryVectorelement, factoryVectorAnimationLayer, factoryCanvas
} from '../videocreator.model';

@Injectable({
  providedIn: 'root'
})
export class UploadgltfService {

  constructor(
    private relationsApi: RelationsApi
  ) {

  }

  async upload(object, threedanimation: threedanimation, fileurl, companyId, relationsId) {

    let filename = fileurl.substring(fileurl.lastIndexOf('/') + 1);
    let newname = filename.replace(/\.[^.]*$/, '');

    newname = newname + '.gltf';
    // convert
    const exporter = new GLTFExporter();

    const options: GLTFExporterOptions = {
      trs: false,
      onlyVisible: true,
      truncateDrawRange: false,
      binary: false
    };
    

    // convert
    exporter.parseAsync(object, options).then((exObject) => {

      const output = JSON.stringify( exObject, null, 2 );
      //console.log( output );

      let objecturl = BASE_URL + '/api/Containers/' + relationsId + '/download/' + newname;

      //   // upload file
      this.uploadfile(output, newname, relationsId);

      //   // upload filel moddel
      this.createfilemodel(newname, companyId, relationsId, objecturl);

      // set threed url
      threedanimation.src = objecturl;
    });

  }

  uploadfile(gltf, filename, relationsId) {
    // upload file
    //console.log(gltf)
    const blob = new Blob([gltf], { type: 'text/plain' });
    let date: number = new Date().getTime();
    let file = new File([blob], filename, { type: 'application/octet-stream', lastModified: date });
    // let urluse = BASE_URL + '/api/Containers/' + this.option.id + '/upload';
    const urluse = BASE_URL + '/api/Containers/' + relationsId + "/upload";
    let uploader = new FileUploader({ url: urluse });
    let fileItem = new FileItem(uploader, file, {});
    uploader.queue.push(fileItem);
    fileItem.upload();

  }

  createfilemodel(name, companyId, relationsId, objecturl: string) {
    let newfile = new Files();
    newfile.type = 'threed';
    newfile.name = name;
    newfile.companyId = companyId;
    newfile.relationsId = relationsId;
    newfile.url = objecturl;

    this.relationsApi.createFiles(relationsId, newfile).subscribe(() => { }, error => console.error(error));
  }
}
