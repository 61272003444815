
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtml implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(html): any {
        if (!html){return}
        const regex1 = /((<style>)|(<style type=.+)|(<\/style>))/g;
        const regex2 = /((<head>)|(<head type=.+)|(<\/head>))/g;
        const regex3 = /((<script>)|(<script type=.+)|(<\/script>))/g;
        const subst = ``;

        // The substituted value will be contained in the result variable
        html = html.replace(regex1, subst);
        html = html.replace(regex2, subst);
        html = html.replace(regex3, subst);
        return this.sanitizer.bypassSecurityTrustHtml(html);
        // return this.sanitizer.bypassSecurityTrustStyle(style);
        // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
    }
}