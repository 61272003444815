import { BASE_URL } from '../base.api';
// do not forgot to url encode spaces etcc.. !!  

export const Stockthreedfiles = [
    BASE_URL + '/api/Containers/standardthreed/download/Macbook.glb',
    BASE_URL + '/api/Containers/standardthreed/download/browser-scenes.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Gift.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Snowman.glb',
    BASE_URL + '/api/Containers/standardthreed/download/pinetreechristmas.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Resize.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Cityscape.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Cityscape3.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Warning.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Coffee.glb',
    BASE_URL + '/api/Containers/standardthreed/download/earth.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Airplane.glb',
    BASE_URL + '/api/Containers/standardthreed/download/RandD.glb',
    BASE_URL + '/api/Containers/standardthreed/download/workflow.glb',
    BASE_URL + '/api/Containers/standardthreed/download/infographic.glb',
    BASE_URL + '/api/Containers/standardthreed/download/SandCastle.glb',
    BASE_URL + '/api/Containers/standardthreed/download/PaperPlane.glb',
    // BASE_URL + '/api/Containers/standardthreed/download/voltmeter2.obj',
    // BASE_URL + '/api/Containers/standardthreed/download/Eye.obj',
    // BASE_URL + '/api/Containers/standardthreed/download/Layer.obj',
    BASE_URL + '/api/Containers/standardthreed/download/ColorPicker.glb',
    //BASE_URL + '/api/Containers/standardthreed/download/BubblesChat.gltf',
    BASE_URL + '/api/Containers/standardthreed/download/PenTool.obj', //
    BASE_URL + '/api/Containers/standardthreed/download/teamwork.glb',
    BASE_URL + '/api/Containers/standardthreed/download/discord.glb',

    BASE_URL + '/api/Containers/standardthreed/download/Messenger.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Line.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Whatsapp.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Twitter.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Instagram.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Linkedin.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Telegram.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Wechat.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Snapchat.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Flicker.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Tiktok.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Facebook.glb',

    BASE_URL + '/api/Containers/standardthreed/download/penciles.glb',
    BASE_URL + '/api/Containers/standardthreed/download/magnifier.glb',
    BASE_URL + '/api/Containers/standardthreed/download/schedule.glb',
    BASE_URL + '/api/Containers/standardthreed/download/paint.glb',
    BASE_URL + '/api/Containers/standardthreed/download/certificate.glb',
    BASE_URL + '/api/Containers/standardthreed/download/readlight.glb',
    BASE_URL + '/api/Containers/standardthreed/download/books.glb',
    BASE_URL + '/api/Containers/standardthreed/download/laptop.glb',
    BASE_URL + '/api/Containers/standardthreed/download/calculator.glb',
    BASE_URL + '/api/Containers/standardthreed/download/chemistry.glb',
    BASE_URL + '/api/Containers/standardthreed/download/penciles.glb',


    BASE_URL + '/api/Containers/standardthreed/download/bakery.glb',
    BASE_URL + '/api/Containers/standardthreed/download/theater.glb',
    BASE_URL + '/api/Containers/standardthreed/download/shopping-mall.glb',
    BASE_URL + '/api/Containers/standardthreed/download/post-office.glb',
    BASE_URL + '/api/Containers/standardthreed/download/police.glb',
    BASE_URL + '/api/Containers/standardthreed/download/house.glb',
    BASE_URL + '/api/Containers/standardthreed/download/hotel.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bank.glb',


    BASE_URL + '/api/Containers/standardthreed/download/hammer1.glb',
    BASE_URL + '/api/Containers/standardthreed/download/gems.glb',
    // BASE_URL + '/api/Containers/standardthreed/download/firespell.glb',
    //BASE_URL + '/api/Containers/standardthreed/download/energispell.glb',
    //BASE_URL + '/api/Containers/standardthreed/download/energipotion.glb',
    BASE_URL + '/api/Containers/standardthreed/download/dreem.glb',
    BASE_URL + '/api/Containers/standardthreed/download/chest1.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bomb4.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bomb3.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bomb2.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bomb1.glb',
    // BASE_URL + '/api/Containers/standardthreed/download/blufirespeel.fbx',
    BASE_URL + '/api/Containers/standardthreed/download/axe4.glb',
    BASE_URL + '/api/Containers/standardthreed/download/axe3.glb',
    BASE_URL + '/api/Containers/standardthreed/download/axe2.glb',
    BASE_URL + '/api/Containers/standardthreed/download/axe1.glb',
    BASE_URL + '/api/Containers/standardthreed/download/armor2.glb',
    BASE_URL + '/api/Containers/standardthreed/download/armor1.glb',
    BASE_URL + '/api/Containers/standardthreed/download/hearth.glb',

    BASE_URL + '/api/Containers/standardthreed/download/EcologyFactory.glb',
    BASE_URL + '/api/Containers/standardthreed/download/ElectricCarCharging.glb',

    //BASE_URL + '/api/Containers/standardthreed/download/spacegun.glb',

    BASE_URL + '/api/Containers/standardthreed/download/pisa.glb',
    BASE_URL + '/api/Containers/standardthreed/download/arctriumpf.glb',
    BASE_URL + '/api/Containers/standardthreed/download/ladyliberty.glb',
    BASE_URL + '/api/Containers/standardthreed/download/towers.glb',
    BASE_URL + '/api/Containers/standardthreed/download/pyramids.glb',
    //BASE_URL + '/api/Containers/standardthreed/download/operahouse.glb',
    BASE_URL + '/api/Containers/standardthreed/download/towerbridge.glb',
    BASE_URL + '/api/Containers/standardthreed/download/japanarch.glb',
    BASE_URL + '/api/Containers/standardthreed/download/machine.glb',


    BASE_URL + '/api/Containers/standardthreed/download/machine.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Castle.glb',
    BASE_URL + '/api/Containers/standardthreed/download/WifiRouter.glb',
    BASE_URL + '/api/Containers/standardthreed/download/VideoProjector.glb',
    BASE_URL + '/api/Containers/standardthreed/download/SmartWatch.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Smartphone.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Satelite.glb',
    BASE_URL + '/api/Containers/standardthreed/download/RemoteControl.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Radio.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Radar.glb',
    BASE_URL + '/api/Containers/standardthreed/download/PhotoCamera.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Monitor.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Joystick.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Headphones.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Flashdisk.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Firewall.glb',
    BASE_URL + '/api/Containers/standardthreed/download/DataServer.glb',
    BASE_URL + '/api/Containers/standardthreed/download/VoltMeter.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Tester.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Soldering.glb',
    BASE_URL + '/api/Containers/standardthreed/download/PowerStrip.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Plug.glb',
    BASE_URL + '/api/Containers/standardthreed/download/GlovesElectric.glb',
    BASE_URL + '/api/Containers/standardthreed/download/ElectricSwitch.glb',
    BASE_URL + '/api/Containers/standardthreed/download/ElectricSign.glb',
    BASE_URL + '/api/Containers/standardthreed/download/ElectricMeter.glb',
    BASE_URL + '/api/Containers/standardthreed/download/Capasitor.glb',

    // BASE_URL + '/api/Containers/standardthreed/download/ArgentinaFlag.glb',
    BASE_URL + '/api/Containers/standardthreed/download/AustraliaFlag.glb',
    BASE_URL + '/api/Containers/standardthreed/download/NetherlandsFlag.glb',

    BASE_URL + '/api/Containers/standardthreed/download/flower1.glb',
    BASE_URL + '/api/Containers/standardthreed/download/boots.glb',
    BASE_URL + '/api/Containers/standardthreed/download/wateringcan.glb',
    BASE_URL + '/api/Containers/standardthreed/download/sandcart.glb',
    BASE_URL + '/api/Containers/standardthreed/download/bucker.glb',
    BASE_URL + '/api/Containers/standardthreed/download/lawnmower.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower15.glb',
    BASE_URL + '/api/Containers/standardthreed/download/fence.glb',
    BASE_URL + '/api/Containers/standardthreed/download/clouds.glb',
    BASE_URL + '/api/Containers/standardthreed/download/tools.glb',
    BASE_URL + '/api/Containers/standardthreed/download/sun.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower10.glb',
    BASE_URL + '/api/Containers/standardthreed/download/tree9.glb',
    BASE_URL + '/api/Containers/standardthreed/download/tree8.glb',
    BASE_URL + '/api/Containers/standardthreed/download/tree7.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower6.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower5.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower4.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower3.glb',
    BASE_URL + '/api/Containers/standardthreed/download/flower2.glb',
]