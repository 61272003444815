
<h2>Height Path</h2>
<label>Set the line heigtht to the flight path (option/alt click on the line to add more points)</label>
<div style="padding: 2px; height: 600px; width: 820px;">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"
        style="overflow: visible;">
        <path id="Ypathmotion" d="M0 400 200 400 400 400 600 400 800 400"
            style="fill: none;stroke: #000;stroke-miterlimit: 10" />
        <path id="baseline" d="M0 400 200 400 400 400 600 400 800 400"
            style="fill: none;stroke: #000;stroke-miterlimit: 10" />
    </svg>
</div>
<label style="font-size: 8pt;">Time scale</label>
<br>
<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
    <button mat-raised-button (click)="save()">Save</button>
    <button mat-raised-button (cancel)="cancel()">Cancel</button>
</div>
