<div style="height: 500px" fxLayout="column">
  <mat-button-toggle-group #group="matButtonToggleGroup" fxFlex="50px" style="width: 100%" fxFlexAlign="start"
    [vertical]="false">
    <mat-button-toggle matTooltip="Local stored images" style="width: 25%" value="fromaccount">
      <mat-icon>storage</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Standard icons" [checked]="true" style="width: 25%" value="standardicons">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Iso icons" style="width: 25%" value="isoicon">
      <mat-icon>iso</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle matTooltip="Background Images" style="width: 25%" value="bg">
      <mat-icon>insert_emoticon</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="group.value == 'fromaccount'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let image of accountimages; let i = index" fxFlex>
      <img *ngIf="image.thumb" src="{{ image.thumb }}" class="image" (click)="selectedimage(image)"
        [class.selected]="image === data.selected" />
      <img *ngIf="!image.thumb" src="{{ image.image }}" class="image" (click)="selectedimage(image)"
        [class.selected]="image === data.selected" />
    </div>
  </div>

  <div *ngIf="group.value == 'fromaccount'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{fromaccountsliceMin + 1}}</label>&nbsp;-&nbsp;
    <label>{{fromaccountslice + 1}}</label>&nbsp; of&nbsp;
    <label>{{fromaccountarray.length}}</label>
  </div>

  <div *ngIf="group.value == 'standardicons'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let icon of existingIcons; let i = index" fxFlex>
      <div>
        <img src={{icon}} class="icongallery" (click)="selectedimage(icon)" [class.selected]="icon === data.selected">
      </div>
    </div>
  </div>

  <div *ngIf="group.value == 'standardicons'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{standardiconssliceMin}}</label>&nbsp;-&nbsp;
    <label>{{standardiconsslice}}</label>&nbsp; of&nbsp;
    <label>{{standardiconsarray.length}}</label>

  </div>

  <div *ngIf="group.value == 'isoicon'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let isoicon of existingIsoIcons; let i = index" fxFlex>
      <img src="{{ isoicon }}" class="image" (click)="selectedimage(isoicon)"
        [class.selected]="isoicon === data.selected" />
    </div>
  </div>

  <div *ngIf="group.value == 'isoicon'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{isoiconsliceMin}}</label>&nbsp;-&nbsp;
    <label>{{isoiconslice}}</label>&nbsp; of&nbsp;
    <label>{{isoiconarray.length}}</label>

  </div>

  <div *ngIf="group.value == 'bg'" class="gallery" fxFlex="80%" fxLayout="row wrap">
    <div *ngFor="let bg of existingBg; let i = index" fxFlex>
      <img src="{{ bg }}" class="image" (click)="selectedimage(bg)" [class.selected]="bg === data.selected" />
    </div>
  </div>

  <div *ngIf="group.value == 'bg'" style="margin-top: 5px; margin-bottom: 5px;">
    <button a mat-raised-button (click)="before(group.value)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button a mat-raised-button (click)="next(group.value)">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <label>{{bgsliceMin}}</label>&nbsp;-&nbsp;
    <label>{{bgslice}}</label>&nbsp; of&nbsp;
    <label>{{bgarray.length}}</label>

  </div>


  <div class="gallery-bottom" fxFlex="10%">
    <button a mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button a mat-raised-button [mat-dialog-close]="data.selected">
      Import
    </button>
  </div>
</div>