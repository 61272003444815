import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Canvas } from '../../videocreator.model';


export interface context {
  name: string;
  counter: number;
  canvas: Canvas;
  selectedvideoformat: any;
  snaptogrid: boolean,
  snaptogridwidth: number,
  snaptogridheight: number
}

@Component({
  selector: 'app-generalsettings',
  templateUrl: './generalsettings.component.html',
  styleUrls: ['./generalsettings.component.scss']
})
export class GeneralsettingsComponent implements OnInit {
  counter: any;
  canvas: Canvas;
  name: string;
  selectedvideoformat: any;

  public snaptogrid: boolean;
  public snaptogridwidth: number;
  public snaptogridheight: number;
  //@ViewChild('slider')slider;

  public slider = new UntypedFormControl();

  constructor(

    public dialogRef: MatDialogRef<GeneralsettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public context: context
  ) { 
    this.counter = context.counter;
    this.name = context.name;
    this.canvas = context.canvas;
    this.selectedvideoformat = context.selectedvideoformat;
    this.snaptogrid = context.snaptogrid;
    this.snaptogridwidth = context.snaptogridwidth;
    this.snaptogridheight = context.snaptogridheight;
  }

  ngOnInit(): void {
   // this.slider.value = this.context.counter;
   //this.slider.setValue(this.counter);
  }

  changevideoformat() {
    let setvideo = this.selectedvideoformat.split(' x ');
    this.canvas.width = setvideo[0];
    this.canvas.height = setvideo[1];
  }

  onSliderChange(e) {
    this.counter = e.value;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(){
    console.log(this.counter)
    this.context.counter = this.counter;
    this.context.name = this.name;
    this.context.canvas = this.canvas;
    this.context.snaptogrid = this.snaptogrid;
    this.context.snaptogridheight = this.snaptogridheight;
    this.context.snaptogridwidth = this.snaptogridwidth;
    this.dialogRef.close(this.context);
  }

  formatLabel(value: number | null) {
    if (!value) {
      return this.counter;
    }
    if (value >= 60) {
      return Math.round(value / 60) + 'm';
    } else {
      return value + 's'
    }
  }


  async setGrid() {
    //await this.removeGrid();
    const myNode = document.getElementById("snapgrid");
    myNode.innerHTML = '';

    if (this.snaptogrid) {
      let gridHeight = this.snaptogridheight;
      let gridWidth = this.snaptogridwidth;
      let gridColumns = parseInt(this.canvas.width, 10) / gridWidth;
      let gridRows = parseInt(this.canvas.height, 10) / gridHeight;

      let docset = document.getElementById('snapgrid');
      //console.log('set grid', docset, gridRows, gridColumns)
      for (let i = 0; i < gridRows * gridColumns; i++) {
        let y = Math.floor(i / gridColumns) * gridHeight;
        let x = (i * gridWidth) % (gridColumns * gridWidth);
        let divi = document.createElement("div");
        divi.className = 'gridcells';
        divi.id = 'gridcell' + i;
        divi.style.width = (gridWidth - 1) + 'px';
        divi.style.height = (gridHeight - 1) + 'px';
        // divi.style.top = y + 'px';
        // divi.style.left = x + 'px';
        docset.appendChild(divi);
      }
    }
  }
}
