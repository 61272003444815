/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Files } from '../../models/Files';
import { SocketConnection } from '../../sockets/socket.connections';
import { Publications } from '../../models/Publications';
import { Relations } from '../../models/Relations';
import { Company } from '../../models/Company';


/**
 * Api services for the `Files` model.
 */
@Injectable()
export class FilesApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation publications.
   *
   * @param {any} id files id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public getPublications(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/:id/publications";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation relations.
   *
   * @param {any} id files id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public getRelations(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/:id/relations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation files.
   *
   * @param {any} id files id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public getFiles(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/:id/files";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id files id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} companyid 
   *
   * @param {string} name 
   *
   * @param {string} canvas 
   *
   * @param {string} images 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public createimage(id: any = {}, companyid: any = {}, name: any = {}, canvas: any = {}, images: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/createimage/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof canvas !== 'undefined' && canvas !== null) _urlParams.canvas = canvas;
    if (typeof images !== 'undefined' && images !== null) _urlParams.images = images;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} companyid 
   *
   * @param {string} name 
   *
   * @param {string} canvas 
   *
   * @param {number} duration 
   *
   * @param {object} data Request data.
   *
   *  - `images` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public createvideo(id: any = {}, companyid: any = {}, name: any = {}, canvas: any = {}, images: any = {}, duration: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/createvideo/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: {
        images: images
      }
    };
    let _urlParams: any = {};
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof canvas !== 'undefined' && canvas !== null) _urlParams.canvas = canvas;
    if (typeof duration !== 'undefined' && duration !== null) _urlParams.duration = duration;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} companyid 
   *
   * @param {string} name 
   *
   * @param {string} canvas 
   *
   * @param {number} duration 
   *
   * @param {boolean} optimize 
   *
   * @param {object} data Request data.
   *
   *  - `images` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public creategif(id: any = {}, companyid: any = {}, name: any = {}, canvas: any = {}, images: any = {}, duration: any = {}, optimize: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/creategif/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: {
        images: images
      }
    };
    let _urlParams: any = {};
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof canvas !== 'undefined' && canvas !== null) _urlParams.canvas = canvas;
    if (typeof duration !== 'undefined' && duration !== null) _urlParams.duration = duration;
    if (typeof optimize !== 'undefined' && optimize !== null) _urlParams.optimize = optimize;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} companyid 
   *
   * @param {string} imageurl 
   *
   * @param {string} name 
   *
   * @param {boolean} centerline 
   *
   * @param {number} color_count 
   *
   * @param {number} corner_always_threshold 
   *
   * @param {number} corner_surround 
   *
   * @param {number} corner_threshold 
   *
   * @param {number} despeckle_level 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public converteps2svg(id: any = {}, companyid: any = {}, imageurl: any = {}, name: any = {}, centerline: any = {}, color_count: any = {}, corner_always_threshold: any = {}, corner_surround: any = {}, corner_threshold: any = {}, despeckle_level: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/converteps2svg/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof imageurl !== 'undefined' && imageurl !== null) _urlParams.imageurl = imageurl;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof centerline !== 'undefined' && centerline !== null) _urlParams.centerline = centerline;
    if (typeof color_count !== 'undefined' && color_count !== null) _urlParams.color_count = color_count;
    if (typeof corner_always_threshold !== 'undefined' && corner_always_threshold !== null) _urlParams.corner_always_threshold = corner_always_threshold;
    if (typeof corner_surround !== 'undefined' && corner_surround !== null) _urlParams.corner_surround = corner_surround;
    if (typeof corner_threshold !== 'undefined' && corner_threshold !== null) _urlParams.corner_threshold = corner_threshold;
    if (typeof despeckle_level !== 'undefined' && despeckle_level !== null) _urlParams.despeckle_level = despeckle_level;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public renumberSvgIds(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/renumbersvgids";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public grabPaths(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/grabpaths";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} videourl 
   *
   * @param {string} compandyId 
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public convertVideo2mp4(id: any = {}, videourl: any = {}, compandyId: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/convertvideo2mp4/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof videourl !== 'undefined' && videourl !== null) _urlParams.videourl = videourl;
    if (typeof compandyId !== 'undefined' && compandyId !== null) _urlParams.compandyId = compandyId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   *
   * @param {string} filurl 
   *
   * @param {string} name 
   *
   * @param {string} ext 
   *
   * @param {string} companyid 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public convertthreed(id: any = {}, filurl: any = {}, name: any = {}, ext: any = {}, companyid: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/convertthreed/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filurl !== 'undefined' && filurl !== null) _urlParams.filurl = filurl;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    if (typeof ext !== 'undefined' && ext !== null) _urlParams.ext = ext;
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public convertTextToSVG(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/convertTextToSVG/";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} relationid 
   *
   * @param {string} companyid 
   *
   * @param {string} fileid 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public combineVideo(relationid: any = {}, companyid: any = {}, fileid: any = {}, videospec: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/combinevideo/";
    let _routeParams: any = {};
    let _postBody: any = {
      videospec: videospec
    };
    let _urlParams: any = {};
    if (typeof relationid !== 'undefined' && relationid !== null) _urlParams.relationid = relationid;
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof fileid !== 'undefined' && fileid !== null) _urlParams.fileid = fileid;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} relationsid 
   *
   * @param {string} companyid 
   *
   * @param {string} fileurl 
   *
   * @param {string} name 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Files` object.)
   * </em>
   */
  public createThumb(relationsid: any = {}, companyid: any = {}, fileurl: any = {}, name: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/files/createthumb/";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof relationsid !== 'undefined' && relationsid !== null) _urlParams.relationsid = relationsid;
    if (typeof companyid !== 'undefined' && companyid !== null) _urlParams.companyid = companyid;
    if (typeof fileurl !== 'undefined' && fileurl !== null) _urlParams.fileurl = fileurl;
    if (typeof name !== 'undefined' && name !== null) _urlParams.name = name;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Files`.
   */
  public getModelName() {
    return "Files";
  }
}
