import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Account,
  AccountApi,
  CallsApi,
  Calls,
  CompanyApi,
  LoopBackConfig,
  RelationsApi,
  Relations,
  PublicationsApi,
  Googleanalytics,
  GoogleanalyticsApi,
  MailingApi,
  Websitetracker,
  WebsitetrackerApi,
  MarketingplannereventsApi,
  Marketingplannerevents,
  TwitterApi,
  FacebookApi,
  LinkedinApi,
  PinterestApi,
  LoopBackAuth,

} from '../shared/sdk';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BASE_URL, API_VERSION } from '../shared/base.api';

@Component({
  selector: 'app-returnpage',
  templateUrl: './returnpage.component.html',
  styleUrls: ['./returnpage.component.scss']
})

export class ReturnpageComponent implements OnInit {
  passwordstrenght = 0;
  private id: any;
  private sub: any;
  private code: any;
  private source: any;
  private state: any;
  private oauth_token: any;
  private oauth_verifier: any;
  public access_token: any;
  response: any;
  public Account = new Account();

  constructor(
    private http: HttpClient,
    private auth: LoopBackAuth,
    private route: ActivatedRoute,
    public TwitterApi: TwitterApi,
    public PinterestApi: PinterestApi,
    public FacebookApi: FacebookApi,
    public LinkedinApi: LinkedinApi,
    public MarketingplannereventsApi: MarketingplannereventsApi,
    public WebsitetrackerApi: WebsitetrackerApi,
    public MailingApi: MailingApi,
    public CompanyApi: CompanyApi,
    public AccountApi: AccountApi,
    public RelationsApi: RelationsApi,
    public CallsApi: CallsApi,
    public PublicationsApi: PublicationsApi,
    public GoogleanalyticsApi: GoogleanalyticsApi,
    public router: Router
  ) {

  }

  //http://127.0.0.1:3000/api/twitters/sessioncallback?id=5bd9487c98bd933943310f1f&oauth_token=26FBNgAAAAAA3FwUAAABZsjDKCg&oauth_verifier=xCPmfOrB2iFPYVtrzku5BHsQu4cE7waX
  ngOnInit() {

    this.id = this.route.snapshot.queryParamMap.get("id");
    this.source = this.route.snapshot.queryParamMap.get("source");
    this.oauth_token = this.route.snapshot.queryParamMap.get('oauth_token');
    this.oauth_verifier = this.route.snapshot.queryParamMap.get('oauth_verifier');
    this.code = this.route.snapshot.queryParamMap.get('code');
    this.state = this.route.snapshot.queryParamMap.get('state');
    this.access_token = this.route.snapshot.queryParamMap.get('access_token');

    // console.log(this.id, this.source, this.oauth_token, this.oauth_verifier, this.code, this.state, this.access_token)
    this.checkAll();

  }

  checkAll() {
    if (this.access_token) {
      // console.log(this.access_token);
      // this.setAccessToken(this.access_token);
      this.auth.clear();
    }

    //https://app.enydea.com/returnpage?id=5f63081887db24517436e2d4&source=linkedin&code=AQRvVhzkIa6v-Kj3IRcD4wHBTcVbfnUrPS-peQAJDC-oDDvQBbU4QVgfC4k2dHHOL7xNOdiJsB8ZuW74mIFDep46desWd9ZKXgrm3ff8dpemFPIyNahPIqBqYddefe07PpVocY30tTEsRZeAw1qctaFGJmgnmYZqNj7Ni6sCy4Unri4pkudc-dP1LD3tzg&state=oiq34hjrq9rau8ajsdn
    //facebook returns string instead of parameter so convert this here:  typeof this.state === 'string'
    if (this.state) {
      console.log(this.state);
      console.log(this.isJsonString(this.state))
      if (this.isJsonString(this.state)) {
        let stateT = JSON.parse(this.state);
        console.log(stateT);
        this.source = stateT.source;
        this.id = stateT.id;
      }
    }

    let domain = location.protocol + '//' + location.host + location.pathname;
    console.log(this.code, this.id, this.source);
    if (this.source === 'twitter') {
      console.log("twitter")
      this.TwitterApi
        .sessioncallback(this.id, this.oauth_token, this.oauth_verifier, domain)
        .subscribe(res => this.response = res);
    }
    else if (this.source === 'facebook') {
      this.FacebookApi
        .sessioncallback(this.id, this.code, domain)
        .subscribe(res => this.response = res);
    }
    else if (this.source === 'linkedin') {
      if (this.id === null || this.code === null || this.state === null) {
        this.response = 'linkedin setup failed please try againg';
      }
      this.LinkedinApi.linkedinaccesstoken(this.id, this.code, this.state, domain)
        .subscribe(res => this.response = 'linkedin account connected');
    }
    else if (this.source === 'pinterest') {
      this.PinterestApi
        .sessioncallback(this.id, this.code, this.state)
        .subscribe(res => this.response = res.res);
    }
    else this.response = "Source undefined"

  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  returntopage() {
    this.router.navigate(['marketing'], { relativeTo: this.route });
  }

  setAccessToken(token) {
    this.auth.setToken(token);
  }

  getAccountInfo(): void {

    this.AccountApi.getCurrent().subscribe((Account: Account) => {
      this.Account = Account;
    });
  }

  saveAccount(): void {
    // while testing remove cookies!!
    const body = { 'newPassword': this.Account.password };
    const headers = {
      'Accept': 'application/json',
      'Authorization': this.access_token,
    };
    this.http.post(BASE_URL + '/api/accounts/reset-password', //http://localhost:3000
      body,
      { headers }
    ).subscribe(data => {
      console.log(data);
      this.router.navigateByUrl('/login');
    }, error => {
      this.response = 'Link expired or unauthorized';
      console.log(error)
    })
  }

  onStrengthChanged(e) {
    this.passwordstrenght = e;
    this.checkall();
  }

  checkall() {

  }





}
