import { BASE_URL } from '../../shared/base.api';

export const IconsIso = [
    BASE_URL  + '/api/Containers/standardisometric/download/1.png',
    BASE_URL  + '/api/Containers/standardisometric/download/10.png',
    BASE_URL  + '/api/Containers/standardisometric/download/100.png',
    BASE_URL  + '/api/Containers/standardisometric/download/101.png',
    BASE_URL  + '/api/Containers/standardisometric/download/102.png',
    BASE_URL  + '/api/Containers/standardisometric/download/103.png',
    BASE_URL  + '/api/Containers/standardisometric/download/104.png',
    BASE_URL  + '/api/Containers/standardisometric/download/105.png',
    BASE_URL  + '/api/Containers/standardisometric/download/106.png',
    BASE_URL  + '/api/Containers/standardisometric/download/107.png',
    BASE_URL  + '/api/Containers/standardisometric/download/108.png',
    BASE_URL  + '/api/Containers/standardisometric/download/109.png',
    BASE_URL  + '/api/Containers/standardisometric/download/11.png',
    BASE_URL  + '/api/Containers/standardisometric/download/110.png',
    BASE_URL  + '/api/Containers/standardisometric/download/111.png',
    BASE_URL  + '/api/Containers/standardisometric/download/112.png',
    BASE_URL  + '/api/Containers/standardisometric/download/113.png',
    BASE_URL  + '/api/Containers/standardisometric/download/114.png',
    BASE_URL  + '/api/Containers/standardisometric/download/115.png',
    BASE_URL  + '/api/Containers/standardisometric/download/116.png',
    BASE_URL  + '/api/Containers/standardisometric/download/117.png',
    BASE_URL  + '/api/Containers/standardisometric/download/118.png',
    BASE_URL  + '/api/Containers/standardisometric/download/119.png',
    BASE_URL  + '/api/Containers/standardisometric/download/12.png',
    BASE_URL  + '/api/Containers/standardisometric/download/120.png',
    BASE_URL  + '/api/Containers/standardisometric/download/121.png',
    BASE_URL  + '/api/Containers/standardisometric/download/122.png',
    BASE_URL  + '/api/Containers/standardisometric/download/123.png',
    BASE_URL  + '/api/Containers/standardisometric/download/124.png',
    BASE_URL  + '/api/Containers/standardisometric/download/125.png',
    BASE_URL  + '/api/Containers/standardisometric/download/126.png',
    BASE_URL  + '/api/Containers/standardisometric/download/127.png',
    BASE_URL  + '/api/Containers/standardisometric/download/128.png',
    BASE_URL  + '/api/Containers/standardisometric/download/129.png',
    BASE_URL  + '/api/Containers/standardisometric/download/13.png',
    BASE_URL  + '/api/Containers/standardisometric/download/130.png',
    BASE_URL  + '/api/Containers/standardisometric/download/131.png',
    BASE_URL  + '/api/Containers/standardisometric/download/132.png',
    BASE_URL  + '/api/Containers/standardisometric/download/133.png',
    BASE_URL  + '/api/Containers/standardisometric/download/134.png',
    BASE_URL  + '/api/Containers/standardisometric/download/135.png',
    BASE_URL  + '/api/Containers/standardisometric/download/136.png',
    BASE_URL  + '/api/Containers/standardisometric/download/137.png',
    BASE_URL  + '/api/Containers/standardisometric/download/138.png',
    BASE_URL  + '/api/Containers/standardisometric/download/139.png',
    BASE_URL  + '/api/Containers/standardisometric/download/14.png',
    BASE_URL  + '/api/Containers/standardisometric/download/140.png',
    BASE_URL  + '/api/Containers/standardisometric/download/141.png',
    BASE_URL  + '/api/Containers/standardisometric/download/142.png',
    BASE_URL  + '/api/Containers/standardisometric/download/143.png',
    BASE_URL  + '/api/Containers/standardisometric/download/144.png',
    BASE_URL  + '/api/Containers/standardisometric/download/145.png',
    BASE_URL  + '/api/Containers/standardisometric/download/146.png',
    BASE_URL  + '/api/Containers/standardisometric/download/147.png',
    BASE_URL  + '/api/Containers/standardisometric/download/148.png',
    BASE_URL  + '/api/Containers/standardisometric/download/149.png',
    BASE_URL  + '/api/Containers/standardisometric/download/15.png',
    BASE_URL  + '/api/Containers/standardisometric/download/150.png',
    BASE_URL  + '/api/Containers/standardisometric/download/151.png',
    BASE_URL  + '/api/Containers/standardisometric/download/152.png',
    BASE_URL  + '/api/Containers/standardisometric/download/153.png',
    BASE_URL  + '/api/Containers/standardisometric/download/154.png',
    BASE_URL  + '/api/Containers/standardisometric/download/155.png',
    BASE_URL  + '/api/Containers/standardisometric/download/156.png',
    BASE_URL  + '/api/Containers/standardisometric/download/157.png',
    BASE_URL  + '/api/Containers/standardisometric/download/158.png',
    BASE_URL  + '/api/Containers/standardisometric/download/159.png',
    BASE_URL  + '/api/Containers/standardisometric/download/16.png',
    BASE_URL  + '/api/Containers/standardisometric/download/160.png',
    BASE_URL  + '/api/Containers/standardisometric/download/161.png',
    BASE_URL  + '/api/Containers/standardisometric/download/162.png',
    BASE_URL  + '/api/Containers/standardisometric/download/163.png',
    BASE_URL  + '/api/Containers/standardisometric/download/164.png',
    BASE_URL  + '/api/Containers/standardisometric/download/165.png',
    BASE_URL  + '/api/Containers/standardisometric/download/166.png',
    BASE_URL  + '/api/Containers/standardisometric/download/167.png',
    BASE_URL  + '/api/Containers/standardisometric/download/168.png',
    BASE_URL  + '/api/Containers/standardisometric/download/169.png',
    BASE_URL  + '/api/Containers/standardisometric/download/17.png',
    BASE_URL  + '/api/Containers/standardisometric/download/170.png',
    BASE_URL  + '/api/Containers/standardisometric/download/171.png',
    BASE_URL  + '/api/Containers/standardisometric/download/172.png',
    BASE_URL  + '/api/Containers/standardisometric/download/173.png',
    BASE_URL  + '/api/Containers/standardisometric/download/174.png',
    BASE_URL  + '/api/Containers/standardisometric/download/175.png',
    BASE_URL  + '/api/Containers/standardisometric/download/176.png',
    BASE_URL  + '/api/Containers/standardisometric/download/177.png',
    BASE_URL  + '/api/Containers/standardisometric/download/178.png',
    BASE_URL  + '/api/Containers/standardisometric/download/179.png',
    BASE_URL  + '/api/Containers/standardisometric/download/18.png',
    BASE_URL  + '/api/Containers/standardisometric/download/180.png',
    BASE_URL  + '/api/Containers/standardisometric/download/181.png',
    BASE_URL  + '/api/Containers/standardisometric/download/182.png',
    BASE_URL  + '/api/Containers/standardisometric/download/183.png',
    BASE_URL  + '/api/Containers/standardisometric/download/184.png',
    BASE_URL  + '/api/Containers/standardisometric/download/185.png',
    BASE_URL  + '/api/Containers/standardisometric/download/186.png',
    BASE_URL  + '/api/Containers/standardisometric/download/187.png',
    BASE_URL  + '/api/Containers/standardisometric/download/188.png',
    BASE_URL  + '/api/Containers/standardisometric/download/189.png',
    BASE_URL  + '/api/Containers/standardisometric/download/19.png',
    BASE_URL  + '/api/Containers/standardisometric/download/190.png',
    BASE_URL  + '/api/Containers/standardisometric/download/191.png',
    BASE_URL  + '/api/Containers/standardisometric/download/192.png',
    BASE_URL  + '/api/Containers/standardisometric/download/193.png',
    BASE_URL  + '/api/Containers/standardisometric/download/194.png',
    BASE_URL  + '/api/Containers/standardisometric/download/195.png',
    BASE_URL  + '/api/Containers/standardisometric/download/196.png',
    BASE_URL  + '/api/Containers/standardisometric/download/197.png',
    BASE_URL  + '/api/Containers/standardisometric/download/198.png',
    BASE_URL  + '/api/Containers/standardisometric/download/199.png',
    BASE_URL  + '/api/Containers/standardisometric/download/2.png',
    BASE_URL  + '/api/Containers/standardisometric/download/20.png',
    BASE_URL  + '/api/Containers/standardisometric/download/200.png',
    BASE_URL  + '/api/Containers/standardisometric/download/21.png',
    BASE_URL  + '/api/Containers/standardisometric/download/22.png',
    BASE_URL  + '/api/Containers/standardisometric/download/23.png',
    BASE_URL  + '/api/Containers/standardisometric/download/24.png',
    BASE_URL  + '/api/Containers/standardisometric/download/25.png',
    BASE_URL  + '/api/Containers/standardisometric/download/26.png',
    BASE_URL  + '/api/Containers/standardisometric/download/27.png',
    BASE_URL  + '/api/Containers/standardisometric/download/28.png',
    BASE_URL  + '/api/Containers/standardisometric/download/29.png',
    BASE_URL  + '/api/Containers/standardisometric/download/3.png',
    BASE_URL  + '/api/Containers/standardisometric/download/30.png',
    BASE_URL  + '/api/Containers/standardisometric/download/31.png',
    BASE_URL  + '/api/Containers/standardisometric/download/32.png',
    BASE_URL  + '/api/Containers/standardisometric/download/33.png',
    BASE_URL  + '/api/Containers/standardisometric/download/34.png',
    BASE_URL  + '/api/Containers/standardisometric/download/35.png',
    BASE_URL  + '/api/Containers/standardisometric/download/36.png',
    BASE_URL  + '/api/Containers/standardisometric/download/37.png',
    BASE_URL  + '/api/Containers/standardisometric/download/38.png',
    BASE_URL  + '/api/Containers/standardisometric/download/39.png',
    BASE_URL  + '/api/Containers/standardisometric/download/4.png',
    BASE_URL  + '/api/Containers/standardisometric/download/40.png',
    BASE_URL  + '/api/Containers/standardisometric/download/41.png',
    BASE_URL  + '/api/Containers/standardisometric/download/42.png',
    BASE_URL  + '/api/Containers/standardisometric/download/43.png',
    BASE_URL  + '/api/Containers/standardisometric/download/44.png',
    BASE_URL  + '/api/Containers/standardisometric/download/45.png',
    BASE_URL  + '/api/Containers/standardisometric/download/46.png',
    BASE_URL  + '/api/Containers/standardisometric/download/47.png',
    BASE_URL  + '/api/Containers/standardisometric/download/48.png',
    BASE_URL  + '/api/Containers/standardisometric/download/49.png',
    BASE_URL  + '/api/Containers/standardisometric/download/5.png',
    BASE_URL  + '/api/Containers/standardisometric/download/50.png',
    BASE_URL  + '/api/Containers/standardisometric/download/51.png',
    BASE_URL  + '/api/Containers/standardisometric/download/52.png',
    BASE_URL  + '/api/Containers/standardisometric/download/53.png',
    BASE_URL  + '/api/Containers/standardisometric/download/54.png',
    BASE_URL  + '/api/Containers/standardisometric/download/55.png',
    BASE_URL  + '/api/Containers/standardisometric/download/56.png',
    BASE_URL  + '/api/Containers/standardisometric/download/57.png',
    BASE_URL  + '/api/Containers/standardisometric/download/58.png',
    BASE_URL  + '/api/Containers/standardisometric/download/59.png',
    BASE_URL  + '/api/Containers/standardisometric/download/6.png',
    BASE_URL  + '/api/Containers/standardisometric/download/60.png',
    BASE_URL  + '/api/Containers/standardisometric/download/61.png',
    BASE_URL  + '/api/Containers/standardisometric/download/62.png',
    BASE_URL  + '/api/Containers/standardisometric/download/63.png',
    BASE_URL  + '/api/Containers/standardisometric/download/64.png',
    BASE_URL  + '/api/Containers/standardisometric/download/65.png',
    BASE_URL  + '/api/Containers/standardisometric/download/66.png',
    BASE_URL  + '/api/Containers/standardisometric/download/67.png',
    BASE_URL  + '/api/Containers/standardisometric/download/68.png',
    BASE_URL  + '/api/Containers/standardisometric/download/69.png',
    BASE_URL  + '/api/Containers/standardisometric/download/7.png',
    BASE_URL  + '/api/Containers/standardisometric/download/70.png',
    BASE_URL  + '/api/Containers/standardisometric/download/71.png',
    BASE_URL  + '/api/Containers/standardisometric/download/72.png',
    BASE_URL  + '/api/Containers/standardisometric/download/73.png',
    BASE_URL  + '/api/Containers/standardisometric/download/74.png',
    BASE_URL  + '/api/Containers/standardisometric/download/75.png',
    BASE_URL  + '/api/Containers/standardisometric/download/76.png',
    BASE_URL  + '/api/Containers/standardisometric/download/77.png',
    BASE_URL  + '/api/Containers/standardisometric/download/78.png',
    BASE_URL  + '/api/Containers/standardisometric/download/79.png',
    BASE_URL  + '/api/Containers/standardisometric/download/8.png',
    BASE_URL  + '/api/Containers/standardisometric/download/80.png',
    BASE_URL  + '/api/Containers/standardisometric/download/81.png',
    BASE_URL  + '/api/Containers/standardisometric/download/82.png',
    BASE_URL  + '/api/Containers/standardisometric/download/83.png',
    BASE_URL  + '/api/Containers/standardisometric/download/84.png',
    BASE_URL  + '/api/Containers/standardisometric/download/85.png',
    BASE_URL  + '/api/Containers/standardisometric/download/86.png',
    BASE_URL  + '/api/Containers/standardisometric/download/87.png',
    BASE_URL  + '/api/Containers/standardisometric/download/88.png',
    BASE_URL  + '/api/Containers/standardisometric/download/89.png',
    BASE_URL  + '/api/Containers/standardisometric/download/9.png',
    BASE_URL  + '/api/Containers/standardisometric/download/90.png',
    BASE_URL  + '/api/Containers/standardisometric/download/91.png',
    BASE_URL  + '/api/Containers/standardisometric/download/92.png',
    BASE_URL  + '/api/Containers/standardisometric/download/93.png',
    BASE_URL  + '/api/Containers/standardisometric/download/94.png',
    BASE_URL  + '/api/Containers/standardisometric/download/95.png',
    BASE_URL  + '/api/Containers/standardisometric/download/96.png',
    BASE_URL  + '/api/Containers/standardisometric/download/97.png',
    BASE_URL  + '/api/Containers/standardisometric/download/98.png',
    BASE_URL  + '/api/Containers/standardisometric/download/99.png',
    ]
    
    