import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  Relations,
  RelationsApi,
  Account,
  AccountApi,
  Company,
  CompanyApi,
  Files,
} from '../shared/sdk';
import { VideocreatorComponent } from './videocreator/videocreator.component';
import { ImagecreatorComponent } from './imagecreator/imagecreator.component';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-imaging',
  templateUrl: './imaging.component.html',
  styleUrls: ['./imaging.component.scss']
})
export class ImagingComponent implements OnInit {
  @ViewChild(ImagecreatorComponent, { static: false })
  private imagecreatorComponent: ImagecreatorComponent;
  @ViewChild(VideocreatorComponent, { static: false })
  private videocreatorComponent: VideocreatorComponent;
  @Output() newSceneEvent = new EventEmitter<Files>();

  myControlRelations = new UntypedFormControl();

  newScene: Subject<Files> = new Subject<Files>();
  
  public company: Company;
  public selectedTab = 0;
  public Account: Account;
  public filteredRelations: Relations[];
  public SelectedRelation: Relations;
  option: any;
  Relations: Relations[];
  options: any[];

  constructor(
    public RelationsApi: RelationsApi,
    public AccountApi: AccountApi,
    public CompanyApi: CompanyApi,
  ) { }

  ngOnInit(): void {
    this.getCurrentUserInfo();
  }

  addItem(e: Files){
    this.newScene.next(e);
  }

  setScroll(){
    console.log('scroll')
    setTimeout(() => { 
      document.getElementById("scrollcontainer").scrollTo(1300, 1600);
      }, 500);
  }

  // select relation --> get info for all tabs
  onSelectRelation(option): void {
    this.option = option;
    this.SelectedRelation = option;
    this.myControlRelations.reset();
  }

  searchGoQuick(value): void {
    let searchterm = value.trim();
    this.CompanyApi.getRelations(this.Account.companyId,
      {
        where:
        {
          or: [{ "relationname": { "regexp": searchterm + '/i' } },
          { "address1": { "regexp": searchterm + '/i' } },
          { "city": { "regexp": searchterm + '/i' } }
          ],
          marketingrelation: true
        },
        order: 'relationname ASC'
      })
      .subscribe((Relations: Relations[]) => {
        this.filteredRelations = Relations
        //console.log(this.filteredRelations)
      });
  }


  //display name in searchbox
  displayFnRelation(relation?: Relations): string | undefined {
    return relation ? relation.relationname : undefined;
  }


  tabChange(e) {

    setTimeout(() => {
      //console.log(e);
      switch (e.index) {
        case 0:
          this.imagecreatorComponent.getEditFile();
          break;
        case 1:
          this.videocreatorComponent.getEditFile();
          break;


      }
    }, 500);
  }


  getCurrentUserInfo(): void {
    this.AccountApi.getCurrent().subscribe((account: Account) => {
      this.Account = account;
      this.CompanyApi.getRelations(this.Account.companyId,
        {
          where: { marketingrelation: true },
          fields: { id: true, relationname: true }
        }
      )
        .subscribe((relations: Relations[]) => {
          this.Relations = relations
          if (this.Account.standardrelation !== undefined) {

            this.RelationsApi.findById(this.Account.standardrelation)
              .subscribe(rel => {
                this.onSelectRelation(rel);
                this.CompanyApi.findById(this.Account.companyId)
                  .subscribe((company: Company) => {
                    this.company = company;
                  });
              })
          }
          this.getrelationsEntry()
        });
    });
  }

  // set Relations and quick selections
  getrelationsEntry(): void {
    this.options = []
    for (const relation of this.Relations) {
      this.options.push(relation);
    }
  }




}
