<div fxLayout="column">

  <mat-accordion *ngIf="Account">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title fxLayout="row">
          <div fxLayoutGap="10px" style="margin-left: 10%">
            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" style="margin-top: 10px">
              <mat-chip-list *ngIf="option" style="margin: 10px" fxFlex>
                <mat-chip>{{ option.relationname }}</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <br>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayout.xs="column">
        <div fxFlex fxLayout="column" fxLayoutGap="10px">

          <mat-form-field fxFlex appearance="outline">
            <mat-label color="accent" *ngIf="option">{{option.relationname}}</mat-label>
            <input type="text" i18n-placeholder placeholder="Select Relation" aria-label="relation" matInput
              (keyup)="searchGoQuick($event.srcElement.value)" [matAutocomplete]="relations"
              [formControl]="myControlRelations">
            <mat-autocomplete #relations="matAutocomplete" [displayWith]="displayFnRelation">
              <mat-option *ngFor="let option of filteredRelations; let i = index" [value]="option"
                (blur)="onSelectRelation(option, i)">
                {{ option.relationname }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
            <mat-form-field *ngIf="selectedanalytics" class="selectboxheader" fxFlex appearance="outline">
              <mat-label color="accent" *ngIf="selectedanalytics">{{selectedanalytics.name}}</mat-label>
              <mat-select [(ngModel)]="selectedanalytics" i18n-placeholder placeholder="GA Account" class="InputField"
                (selectionChange)="detectchange()">
                <mat-option *ngFor="let selectedanalytics of GoogleanalyticsModel" [value]="selectedanalytics">
                  {{ selectedanalytics.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="Account" class="selectboxheader" fxFlex appearance="outline">
              <mat-label color="accent" *ngIf="dashname">{{dashname.name}}</mat-label>
              <mat-select i18n-placeholder placeholder="Private Dashboard" class="InputField" [formControl]="myControlDash"
                (selectionChange)="loadDashboard($event, 'account')">
                <mat-option *ngFor="let dash of Account.dashboards; let i = index;" [value]="i">
                  <div fxLayout="row">
                    {{ dash.name }}
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="Account" class="selectboxheader" fxFlex appearance="outline">
              <mat-select i18n-placeholder placeholder="Team Dashboard" class="InputField"
                (selectionChange)="loadDashboard($event, 'company')">
                <mat-option *ngFor="let dash of Company.dashboards; let i = index;" [value]="i">
                  <div fxLayout="row">
                    {{ dash.name }}
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <br>
          <div fxLayout="row">
            <mat-checkbox [(ngModel)]="dashboardsavetocompany" fxFlex i18n>Make available in Team</mat-checkbox>

            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>settings</mat-icon></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="saveDashboard()"><mat-icon>save</mat-icon>
                <label i18n>Save as New</label>
              </button>
              <button mat-menu-item (click)="saveCurrentDashboard()"><mat-icon>save</mat-icon>
                <label i18n>Save current</label>
              </button>
              <button mat-menu-item (click)="deleteDashboard()"><mat-icon>delete</mat-icon>
                <label i18n>Delete</label>
              </button>
              <button mat-menu-item (click)="setStandardDashboard(stdDash)"><mat-icon>tune</mat-icon>
                <label i18n>Set as default</label>
              </button>
              <button mat-menu-item (click)="detectchange()"><mat-icon>restart_alt</mat-icon>
                <label i18n>Reload</label>
              </button>
            </mat-menu>
          </div>


          <div class="speed-dial-dashboard" fxLayoutAlign="end start" fxLayout="row" fxLayoutGap="10px">
            <speed-dial-fab i18n-matTooltip matTooltip="Settings and Components" [reverse-column-direction]="true" direction="column"
              (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons">
            </speed-dial-fab>
            <button mat-mini-fab i18n-matTooltip matTooltip="Toggle Edit mode" (click)="editmodeChange()" color="primary">
              <mat-icon aria-label="label" i18n>edit</mat-icon>
            </button>
            <button mat-mini-fab i18n-matTooltip matTooltip="Create new Dashboard" (click)="newDashboard()" color="primary">
              <mat-icon aria-label="label" i18n>add</mat-icon>
            </button>
          </div>
        </div>

      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!changenow">
    <mat-spinner class="waiting"></mat-spinner>
  </div>

  <div *ngIf="changenow" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="25px">


    <div *ngIf="dashboardsetup?.length === 0" style="height: 80vh; width: 100%;" fxLayoutAlign="center center">

      <div>
        <h1 color="primary" i18n >Welcome to Enydea</h1>
        <br>
        <label><strong i18n >Thing to do:</strong></label>
        <mat-list role="list" class="startlist">
          <mat-list-item i18n role="listitem"><mat-icon color="primary">dashboard_customize</mat-icon>Setup your Dashboard</mat-list-item>
          <mat-list-item i18n role="listitem"><mat-icon color="primary">settings</mat-icon>Setting up tracking</mat-list-item>
          <mat-list-item i18n role="listitem"><mat-icon color="primary">person_add</mat-icon>Add your first customers</mat-list-item>
          <mat-list-item i18n role="listitem"><mat-icon color="primary">play_circle</mat-icon>Create your first video</mat-list-item>
          <mat-list-item i18n role="listitem"><mat-icon color="primary">mark_email_unread</mat-icon>Create your first email campaign</mat-list-item>
          <mat-list-item i18n role="listitem"><mat-icon color="primary">palette</mat-icon>Play around with the image creation tool</mat-list-item>
          <mat-list-item i18n role="listitem" href="https://www.enydea.com/tutorials/"><mat-icon color="primary">school</mat-icon>Check out the tutorials</mat-list-item>
        </mat-list>
      </div>

    </div>

    <!-- [style.width]="dashitem.chartsize" -->
    <div *ngFor="let dashitem of dashboardsetup; let i1 = index;" fxFlex.xs="100%" [fxFlex]="dashitem.chartsize"
      fxLayout="column" class="dashitem">
      <mat-card fxLayout="column" [style.height]="'400px'">
        <h4 style="color: grey; text-align: center;"> {{dashitem.type}} </h4>

        <div *ngIf="dashitem.charttype !== 'table' && dashitem.charttype !== 'map' && dashitem.data_set.length > 0"
          class="chartdash">
          <canvas [id]="dashitem.id" baseChart height="90vh" width="100" [datasets]="dashitem.data_set"
            [labels]="dashitem.data_labels" [legend]="dashitem.showlegend" [chartType]="dashitem.charttype"
            (chartHover)="chartHovered($event)" [options]="chartOptions" (chartClick)="chartClicked($event)"
            [colors]="dashitem.colorscheme"></canvas>
        </div>

        <div *ngIf="dashitem.charttype === 'table' && dashitem.type === 'Website data'" class="tabledash">
          <div>
            <div class="websitedata" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="space-around">
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Source</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>City</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Country</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Visited URL</h4>
              </div>

            </div>
            <div *ngFor="let Websitetracker of dashitem.data_object; let i = index">
              <div class="websitedata" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="space-around"
                (click)="addCompany(Websitetracker)">
                <div fxFlex="25%">
                  <label class="listsfont">{{ Websitetracker.companyname }}</label>
                </div>
                <div fxFlex="25%">
                  <label class="listsfont">{{ Websitetracker.city }}</label>
                </div>
                <div fxFlex="25%">
                  <label class="listsfont">{{ Websitetracker.country }}</label>
                </div>
                <div fxFlex="25%">
                  <label class="listsfont">{{ Websitetracker.url | slice: 0:50 }}</label>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="dashitem.charttype === 'table' && dashitem.type === 'Website leads'" class="tabledash">
          <div>
            <div class="websitedata" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="space-around">
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Source</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>City</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Country</h4>
              </div>
              <div fxFlex="25%">
                <h4 class="mat-h4" i18n>Visited URL</h4>
              </div>

            </div>

            <mat-selection-list #trackerlist [multiple]="false">
              <mat-list-option *ngFor="let Websitetracker of dashitem.data_object; let i = index"
                (click)="checkCompanyLead(Websitetracker)">

                <div class="websitedata" fxLayout="row">

                  <label fxFlex="25%">{{ Websitetracker.companyname }}</label>
                  <label fxFlex="25%">{{ Websitetracker.city }}</label>
                  <label fxFlex="25%">{{ Websitetracker.country }}</label>
                  <label fxFlex="25%" matTooltip={{Websitetracker.url}}>{{ Websitetracker.url | slice: 0:40 }}</label>

                </div>

              </mat-list-option>
            </mat-selection-list>
            <!-- <mat-accordion>
              <mat-expansion-panel (opened)="findWebsiteTrackerByIP(Websitetracker)" class="websitedata"
                *ngFor="let Websitetracker of dashitem.data_object; let i = index">
                <mat-expansion-panel-header>
                  <mat-panel-title>
  
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngFor="let visit of WebsitetrackerFullList" class="websitedata" fxLayout="row" fxLayoutWrap="wrap"
                  fxLayoutAlign="space-around">
                  <div fxFlex="50%">
                    <label>{{ visit.url | slice: 0:50 }}</label>
                  </div>
                  <div fxFlex="50%">
                    <label>{{ visit.date | date: "medium" }}</label>
                  </div>
                </div>

              </mat-expansion-panel>
            </mat-accordion> -->
          </div>
        </div>

        <div *ngIf="dashitem.charttype === 'table' && dashitem.type === 'Followups'" class="tabledash">
          <div>
            <div class="websitedata" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="space-around">
              <div fxFlex="50%">
                <h4 class="mat-h4" i18n>Title</h4>
              </div>
              <div fxFlex="20%">
                <h4 class="mat-h4" i18n>Company</h4>
              </div>
              <div fxFlex="15%">
                <h4 class="mat-h4" i18n>Follow-up date</h4>
              </div>
              <div fxFlex="15%">
                <h4 class="mat-h4" i18n>Date</h4>
              </div>

            </div>
            <mat-accordion>
              <mat-expansion-panel class="websitedata" *ngFor="let item of dashitem.data_object; let i = index">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="websitedata" (click)="openFollowUp(item)" fxLayout="row" fxLayoutWrap="wrap"
                      fxLayoutAlign="space-around">
                      <div fxFlex="50%">
                        <label class="listsfont">{{ item.title }}</label>
                      </div>
                      <div fxFlex="20%">
                        <label *ngIf="item.relations" class="listsfont">{{ item.relations.relationname }}</label>
                      </div>
                      <div fxFlex="15%">
                        <label class="listsfont">{{ item.followupdate }}</label>
                      </div>
                      <div fxFlex="15%">
                        <label class="listsfont">{{ item.date | date}}</label>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="item.text">{{item.text}}</div>
                <div *ngIf="item.html" [innerHTML]="item.html  | safeHtml"></div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div *ngIf="dashitem.charttype === 'map'" style="height: 100%; width: 100%;">
          <!-- [label]="{color: 'black', fontFamily: 'Open Sans', fontSize: '10', text: Websitetracker.companyname}"  -->
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="height: 90%; width: 100%;"
            [styles]="mapStyle">
            <div *ngFor="let Websitetracker of dashitem.data_object" style="height: 100%; width: 100%;">
              <agm-marker [iconUrl]="{url: dashitem.currentlabel ===  Websitetracker ? 'assets/tick.png' : 'assets/target.png',
              scaledSize: { width: 20, height: 20 }}" [latitude]="Websitetracker.lat" [longitude]="Websitetracker.lon"
                (mouseOver)="mouseOverMapMarker(Websitetracker, i1)"></agm-marker>
            </div>
          </agm-map>
          <!-- https://app.enydea.com/assets/icons/colour-cirle-set/office/location.png -->
          <div fxLayoutGap="10px">
            <label *ngIf="dashitem.currentlabel.city" i18n>City:
              {{ dashitem.currentlabel.city }}</label>
            <label *ngIf="dashitem.type === 'Website leads' && dashitem.currentlabel" i18n>Companyname:
              {{ dashitem.currentlabel.companyname }}</label>
            <label *ngIf="dashitem.type === 'Google Analytics' && dashitem.currentlabel" i18n>Visits:
              {{ dashitem.currentlabel.data }}</label>
          </div>

        </div>

        <div *ngIf="dashitem.charttype === 'table' && dashitem.type !== 'Website data' && dashitem.type 
          !== 'Website leads' && dashitem.type !== 'Scheduled' && dashitem.data_set.length > 0
          && dashitem.type !== 'Followups'" class="tabledash">
          <app-table [tabledata]="dashitem.data_object" [tablelabels]="dashitem.data_labels">
          </app-table>
        </div>

        <div *ngIf="dashitem.charttype === 'table' && dashitem.type === 'Scheduled'" class="tabledash">
          <label class="mat-h4" i18n>Scheduled Mailings, Ads and Articles</label>
          <table mat-table [dataSource]="dashitem.data_object" matSort style="width: 100%;">
            <!-- Position Column -->
            <ng-container matColumnDef="marketingplannereventsIds">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Campaign </th>
              <td mat-cell class="tablefonts" *matCellDef="let element"> {{element.marketingplannereventsIds}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Subject </th>
              <td mat-cell class="tablefonts" *matCellDef="let element"> {{element.subject}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="from">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> From </th>
              <td mat-cell class="tablefonts" *matCellDef="let element"> {{element.from}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Title </th>
              <td mat-cell class="tablefonts" *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Date </th>
              <td mat-cell class="tablefonts" *matCellDef="let element"> {{element.date | date: "medium" }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dashitem.data_labels"></tr>
            <tr mat-row *matRowDef="let row; columns: dashitem.data_labels;"></tr>
          </table>

          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

        </div>

        <div fxFlex="50px" fxLayout="row" *ngIf="editmode" style="z-index: 10;">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Settings
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field>
                <mat-select [(ngModel)]="dashitem.chartsize" (selectionChange)="detectchange()" i18n-placeholder placeholder="size">
                  <mat-option value="100%">1</mat-option>
                  <mat-option value="47%">1/2</mat-option>
                  <mat-option value="30%">1/3</mat-option>
                  <mat-option value="22%">1/4</mat-option>
                  <mat-option value="60%">2/3</mat-option>
                  <mat-option value="66%">3/4</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select [(ngModel)]="dashitem.color" (selectionChange)="setColor(dashitem)" i18n-placeholder placeholder="color">
                  <mat-option value="auto" i18n>Auto</mat-option>
                  <mat-option value="grey" i18n>Grey</mat-option>
                  <mat-option value="red" i18n>Red</mat-option>
                  <mat-option value="blue" i18n>Blue</mat-option>
                  <mat-option value="green" i18n>Green</mat-option>
                  <mat-option value="pink" i18n>Pink</mat-option>
                  <mat-option value="yellow" i18n>Yellow</mat-option>
                  <mat-option value="azure" i18n>Azure</mat-option>
                  <mat-option value="purple" i18n>Purple</mat-option>
                  <mat-option value="darkblue" i18n>Dark blue</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select [(ngModel)]="dashitem.charttype" (selectionChange)="detectchange()">
                  <mat-option value="table" i18n>Table</mat-option>
                  <mat-option value="line" i18n>Line</mat-option>
                  <mat-option value="bar" i18n>Bar</mat-option>
                  <mat-option value="radar" i18n>Radar</mat-option>
                  <mat-option value="pie" i18n>Pie</mat-option>
                  <mat-option value="polarArea" i18n>Polararea</mat-option>
                  <mat-option value="doughnut" i18n>Doughnut</mat-option>
                  <mat-option value="map" i18n>Map</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="dashitem.type === 'Google Analytics'">
                <mat-select [(ngModel)]="dashitem.dimension" (selectionChange)="detectchange()">
                  <mat-option value="medium" i18n>Source</mat-option>
                  <mat-option value="date" i18n>Date</mat-option>
                  <mat-option value="region" i18n>Region</mat-option>
                  <mat-option value="city" i18n>City</mat-option>
                  <mat-option value="country" i18n>Country</mat-option>
                  <mat-option value="browser" i18n>Browser</mat-option>
                  <mat-option value="sessions" i18n>Sessions</mat-option>
                  <mat-option value="bounceRate" i18n>Bounces</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'Google Analytics'">
                <mat-select [(ngModel)]="dashitem.metric" (selectionChange)="detectchange()">
                  <mat-option value="conversions" i18n>Conversions</mat-option>
                  <mat-option value="sessions" i18n>Sessions</mat-option>
                  <mat-option value="bounceRate" i18n>Bounces</mat-option>
                  <mat-option value="activeUsers" i18n>Users</mat-option>
                </mat-select>
              </mat-form-field>



              <mat-form-field *ngIf="dashitem.type === 'Google Analytics' || dashitem.type === 'CRM statistics'">
                <mat-label i18n>Choose a start date</mat-label>
                <input matInput [min]="minDate" [max]="maxDate" (dateChange)="detectchange()" [matDatepicker]="picker1"
                  [(ngModel)]="dashitem.startdate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'Google Analytics' || dashitem.type === 'CRM statistics'">
                <mat-label i18n>Choose a end date</mat-label>
                <input matInput [min]="minDate" [max]="maxDate" (dateChange)="detectchange()" [matDatepicker]="picker2"
                  [(ngModel)]="dashitem.enddate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'Google Analytics'">
                <mat-select [(ngModel)]="dashitem.startdate" (selectionChange)="detectchange()"
                  i18n-placeholder placeholder="Date range">
                  <mat-option value="today" i18n>Today</mat-option>
                  <mat-option value="yesterday" i18n>Yesterday</mat-option>
                  <mat-option value="30daysAgo" i18n>Last 30 Days</mat-option>
                  <mat-option value="365daysAgo" i18n>Last year</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'CRM statistics'">
                <mat-select [(ngModel)]="dashitem.dyndate" (selectionChange)="detectchange()" i18n-placeholder placeholder="Date range">
                  <mat-option value="today" i18n>Today</mat-option>
                  <mat-option value="yesterday" i18n>Yesterday</mat-option>
                  <mat-option value="lastweek" i18n>Last week</mat-option>
                  <mat-option value="30daysAgo" i18n>Last 30 Days</mat-option>
                  <mat-option value="365daysAgo" i18n>Last year</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'CRM statistics'">
                <mat-select [(ngModel)]="dashitem.dimension" (selectionChange)="detectchange()">
                  <mat-option value="calls-date" i18n>Calls/Date</mat-option>
                  <mat-option value="relations-calls" i18n>Relations/Calls</mat-option>
                  <mat-option value="relations-status" i18n>Relations/Status</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="dashitem.type === 'Salespipe'">
                <mat-select [(ngModel)]="dashitem.dimension" (selectionChange)="detectchange()">
                  <mat-option value="salespipe-status" i18n>Status</mat-option>
                  <mat-option value="salespipe-type" i18n>Type</mat-option>
                  <mat-option value="salespipe-succesrate" i18n>Successrate</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox [(ngModel)]="dashitem.showlegend" i18n>Show legend</mat-checkbox>
              <!-- <mat-checkbox [(ngModel)]="dashitem.show">Show labels</mat-checkbox> -->

              <mat-select *ngIf="dashitem.type !== 'Google Analytics'" style="width: 200px; height: 50px;"
                i18n-placeholder placeholder="Select timeframe" [(ngModel)]="dashitem.startdate">
                <mat-option *ngFor="let mailStatsTime of mailStatsTime" [value]="mailStatsTime.value" (blur)="detectchange()">
                  {{ mailStatsTime.viewValue }}
                </mat-option>
              </mat-select>

              <mat-icon (click)="deleteDashItem(i1)">delete</mat-icon>

            </mat-expansion-panel>
          </mat-accordion>

          <mat-icon i18n-matTooltip matTooltip="up" (click)="moveSectionUp(i1)" class="iconsize-14 sectionlabelbuttons" matSuffix>
            arrow_drop_up</mat-icon>
          <mat-icon i18n-matTooltip matTooltip="down" (click)="moveSectionDown(i1)" class="iconsize-14 sectionlabelbuttons" matSuffix>
            arrow_drop_down</mat-icon>
        </div>
      </mat-card>

    </div>

  </div>

</div>