<mat-nav-list>
    <!-- 
    <a mat-mini-fab color="primary" style="float: right;" (click)="getLogs()">
        <mat-icon>refresh</mat-icon>
    </a> -->
    <label class="mat-h4">Messages</label>
    <mat-divider></mat-divider>
    <div fxLayout="column" class="websitedata">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap="wrap" fxLayoutAlign="space-around" >
            <mat-list-item fxFlex>
                <!-- <div fxFlex="20%">
                    <label style="font-size: 8pt">User</label>
                </div>
                <div fxFlex="20%">
                    <label style="font-size: 8pt">Subject</label>
                </div> -->
                <div fxFlex="45%" fxLayoutAlign="space-around">
                    <label style="font-size: 8pt">Description</label>
                </div>
                <div fxFlex="45%" fxLayoutAlign="space-around">
                    <label style="font-size: 8pt">Date</label>
                </div>
                <div fxFlex="10%" fxLayoutAlign="space-around">
                    <label style="font-size: 8pt">Action</label>
                </div>
            </mat-list-item>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap="wrap" fxLayoutAlign="space-around"
            *ngFor="let logs of logger; let i = index" (click)="navigateTo(logs, i)">
            <mat-list-item fxFlex (click)="navigateTo(logs, i)">
                <!-- <div fxFlex="20%">
                    <label style="font-size: 8pt">{{ logs.user }}</label>
                </div>
                <div fxFlex="20%">
                    <label style="font-size: 8pt">{{ logs.relation }}</label>
                </div> -->
                <div fxFlex="45%" fxLayoutAlign="space-around">
                    <label style="font-size: 8pt">{{logs.description | slice: 0:50}}</label>
                </div>
                <div fxFlex="45%" fxLayoutAlign="space-around">
                    <label style="font-size: 8pt">{{logs.date | date: "medium"}}</label>
                </div>
                <div fxFlex="10%" fxLayoutAlign="space-around">
                    <mat-icon (click)="deleteLog(i)" matTooltip="Delete message">delete</mat-icon>
                    <mat-icon (click)="markRead(i)" matTooltip="Mark seen">done</mat-icon>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </div>
    </div>

</mat-nav-list>