import { Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from '@microsoft/microsoft-graph-client';
import { filter } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { GraphService } from './graph365/graph.service';

export class User {
  displayName: string;
  email: string;
  avatar: string;
}


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  //public authenticated: boolean;
  public user: User;
  //authenticated: boolean;

  constructor(
    //private graphService: GraphService,
    private msalBroadcastService: MsalBroadcastService,
    public snackBar: MatSnackBar,
    private msalService: MsalService) {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.error(result);
      });
    //this.authenticated = this.msalService.initialize(); // getAccount() != null;
    //this.getUser().then((user) => { this.user = user });
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 6000,
      panelClass: "snackbar-class"
    });
  }



  // async loginRedirect() {
  //   this.graphService.signIn();
  //   //this.msalService.loginRedirect();
  // }

  setLoginDisplay() {
    let loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    console.log(loginDisplay)
  }






}