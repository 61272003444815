import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mailing, Relations } from 'src/app/shared/sdk';

@Component({
  selector: 'app-mailtemplate',
  templateUrl: './mailtemplate.component.html',
  styleUrls: ['./mailtemplate.component.scss']
})
export class MailtemplateComponent implements OnInit {
  @Input('CampaignMailing') CampaignMailing: Mailing;
  @Output() DeleteEvent = new EventEmitter();
  @Output() EditEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }

  openDialogDeleteMailingCampaign(): void {
    this.DeleteEvent.emit(this.CampaignMailing);
  }

  openEdit(){
    this.EditEvent.emit(this.CampaignMailing);
  }


}
